import React from 'react';
import styled from 'styled-components';

const StyledSeparator = styled.div`
    margin: 4px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.separator};
`;

export default function SelectSeparator() {
    return (
        <StyledSeparator />
    );
}
