import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import KPIContent from './KPIContent';
import { SUCCESS } from 'Common/data/RequestStatus';
import * as TrendGoodness from 'Common/components/TrendDot/TrendGoodness';
import useKpiController from './internal/useKpiController';
import FormattedDatum from 'Common/components/FormattedDatum';
import KPIWidgetLiveControls from './KPIWidgetLiveControls';
import WidgetFrame from 'Common/components/WidgetFrame/WidgetFrame';
import WidgetValidationErrorMessage from 'Common/components/WidgetFrame/WidgetValidationErrorMessage';
import WidgetLoadingIndicator from 'Common/components/WidgetFrame/WidgetLoadingIndicator';
import FrameHeader from 'Common/components/WidgetFrame/FrameHeader';
import { useTranslator } from 'Common/hooks/useTranslation';
import { generateFilterText, HUMAN_READABLE } from 'Common/components/FilterBuilderText/FilterBuilderUtils';
import usePersistWidgetHeight from 'Common/hooks/usePersistWidgetHeight';
import RequestState from 'Common/types/RequestState/RequestState';

const emptyArray = [];

const LoadingContainer = styled.div.attrs(({ $minHeight }) => {
    if ($minHeight) {
        return {
            style: { minHeight: `${$minHeight}px` }
        };
    }
})``;

const KPIContainer = styled.div`
    padding-bottom: ${({ theme }) => theme.layout.widget.verticalPadding};
    padding-left: ${({ theme }) => theme.layout.widget.horizontalPadding};
    padding-right: ${({ theme }) => theme.layout.widget.horizontalPadding};
    padding-top: ${({ theme }) => theme.layout.widget.verticalPadding};
`;
 
export default function KPIWidget({
    alwaysShowAsset,
    widgetStf,
    widgetState,
    areLiveControlsVisible,
    actions,
    timeRange,
    reportHierarchyNode,
    explorationPath,
    mutable,
    dragHandleProps,
    isDragEnabled,
    isLayoutModeActive,
    dataService,
    isPrint,
    hideTrend,
    printConfig,
    suppressedControls,
    suppressDelete,
}) {
    const translator = useTranslator();
    const controller = useKpiController({
        widgetStf,
        setWidgetStf: actions.setWidgetStf,
        widgetState,
        setWidgetState: actions.setWidgetState,
        setWidgetPrintStatus: actions.setWidgetPrintStatus,
        setWidgetLiveControlsVisibility: actions.setWidgetLiveControlsVisibility,
        areLiveControlsVisible: areLiveControlsVisible,
        dataService: dataService,
        pageTimeRange: timeRange,
        pageExplorationPath: explorationPath,
        reportHierarchyNode,
        isPrint,
        mutable,
        isLayoutModeActive,
        duplicateWidget: actions.duplicateWidget
    });

    const {
        isInitialized,
        title,
        defaultTitle,
        setTitle,
        dataRequest: kpiWidgetDataRequest,
        timeRangeDescription,
        tooltipContent,
        formattedFilters,
        followAppBarHierarchyNode,
        hierarchyNodeOptions,
        hierarchyNode,
        showLiveControls,
        toggleShowLiveControls,
        supportsLiveControls,
        shouldShowFilterText,
    } = controller;

    const handleTitleChange = useCallback((event) => {
        setTitle(event.target.value);
    }, [ setTitle ]);

    const noColor = isPrint && printConfig.noColor;

    const {
        widgetContainerRef,
        lastWidgetHeight
    } = usePersistWidgetHeight(kpiWidgetDataRequest?.status);

    const contentElement = useMemo(() => {
        if (!RequestState.isRequestCompleted(kpiWidgetDataRequest)) {
            return (
                <LoadingContainer $minHeight={lastWidgetHeight}>
                    <WidgetLoadingIndicator />
                </LoadingContainer>
            );
        }
        else if (kpiWidgetDataRequest?.status === SUCCESS) {
            const trend = kpiWidgetDataRequest.response.trendData;
            const kpiData = (
                <FormattedDatum
                    data={kpiWidgetDataRequest.response.metricData}
                    error={kpiWidgetDataRequest.response.error}
                    showHeatBlob={false}
                    noColor={noColor}
                />
            );
            return (
                <KPIContainer ref={widgetContainerRef}>
                    <KPIContent
                        isSelectable={false}
                        noColor={noColor}
                        kpiData={kpiData}
                        sparkLineData={trend?.sparkLineData || emptyArray}
                        trendGoodness={trend?.trendGoodness || TrendGoodness.NONE}
                        trendValue={trend?.value || null}
                        hideTrend={hideTrend}
                        isPrint={isPrint}
                    />
                </KPIContainer>
            );
        }
        else {
            const erroMessage = kpiWidgetDataRequest?.error || translator('error_literal');
            return (
                <WidgetValidationErrorMessage error={erroMessage}/>
            );
        }
    }, [ kpiWidgetDataRequest, noColor, isPrint, hideTrend, translator, lastWidgetHeight, widgetContainerRef ]);

    let finalTooltipContent = (
        <React.Fragment>
            <div>
                <b>{tooltipContent.displayName}</b>
            </div>
            <div>{tooltipContent.description}</div>
            <hr />
            {tooltipContent.trendDescription}
        </React.Fragment>
    );

    const filtersLabelLiteral = translator('filters_label');

    const filtersText = useMemo(() => {
        return generateFilterText(formattedFilters, HUMAN_READABLE, translator);
    }, [formattedFilters, translator]);

    if (filtersText && shouldShowFilterText) {
        finalTooltipContent = (
            <React.Fragment>
                {!!finalTooltipContent && (
                    <React.Fragment>
                        {finalTooltipContent}
                        <hr />
                    </React.Fragment>
                )}
                <div><strong>{filtersLabelLiteral}</strong></div>
                {filtersText}
            </React.Fragment>
        );
    }

    return (
        <WidgetFrame
            isPrint={isPrint}
            frameHeader={(
                <FrameHeader
                    hierarchyNodes={hierarchyNodeOptions}
                    hierarchyNodeValue={hierarchyNode || reportHierarchyNode?.name}
                    defaultTitleText={defaultTitle}
                    isPrint={isPrint}
                    showAsset={alwaysShowAsset || !followAppBarHierarchyNode}
                    showDelete={isLayoutModeActive && !isPrint && !suppressDelete}
                    shouldShowLiveControls={showLiveControls}
                    canEditTitle={showLiveControls && !isPrint && mutable}
                    showLiveControlsOnClick={toggleShowLiveControls}
                    showLiveControlsButton={supportsLiveControls}
                    titleValue={title}
                    titleOnChange={handleTitleChange}
                    infoTooltipContent={finalTooltipContent}
                    deleteOnClick={actions.deleteWidget}
                    timeRangeDescription={timeRangeDescription}
                    isSectionFrameTitle={false}
                    formattedFilters={shouldShowFilterText ? formattedFilters : emptyArray}
                    isDragEnabled={isDragEnabled}
                    dragHandleProps={dragHandleProps}
                />
            )}
            liveControls={isInitialized && mutable && showLiveControls && (
                <KPIWidgetLiveControls
                    controller={controller}
                    suppressedControls={suppressedControls}
                />
            )}
        >
            {contentElement}
        </WidgetFrame>
    );
}

KPIWidget.defaultProps = {
    hideTrend: false,
};

KPIWidget.propTypes = {
    widgetStf: PropTypes.object.isRequired,
    widgetState: PropTypes.object,
    areLiveControlsVisible: PropTypes.bool.isRequired,

    actions: PropTypes.shape({
        setWidgetStf: PropTypes.func.isRequired,
        setWidgetState: PropTypes.func.isRequired,
        setWidgetPrintStatus: PropTypes.func.isRequired,
        deleteWidget: PropTypes.func.isRequired,
        duplicateWidget: PropTypes.func.isRequired,
        setWidgetLiveControlsVisibility: PropTypes.func.isRequired,
    }).isRequired,

    timeRange: PropTypes.object,
    explorationPath: PropTypes.object,
    reportHierarchyNode: PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string
    }).isRequired,

    alwaysShowAsset: PropTypes.bool,
    mutable: PropTypes.bool.isRequired,
    parentTitle: PropTypes.string,
    dragHandleProps: PropTypes.object,
    isDragEnabled: PropTypes.bool.isRequired,
    isLayoutModeActive: PropTypes.bool.isRequired,
    isPrint: PropTypes.bool.isRequired,
    hideTrend: PropTypes.bool.isRequired,
    printConfig: PropTypes.object,
    suppressedControls: PropTypes.object,
    suppressDelete: PropTypes.bool,

    dataService: PropTypes.shape({
        getKpiWidgetData: PropTypes.func.isRequired,
        getColumnConfig: PropTypes.func.isRequired,
        getColumnHelpText: PropTypes.func.isRequired,
        getTimeRangePrimaryDetails: PropTypes.func.isRequired,
        getTimeRangeSecondaryDetails: PropTypes.func.isRequired,
    }).isRequired,
};
