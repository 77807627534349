import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Body5 } from 'Common/components/typography';
import Icon from 'Common/components/Icon';
import { TouchableTooltipTarget } from 'Common/components/Tooltip';
import { generateFilterText, HUMAN_READABLE } from 'Common/components/FilterBuilderText/FilterBuilderUtils';
import { useTranslator } from 'Common/hooks/useTranslation';
import FilterBuilderPrettyFormat from './FilterBuilderPrettyFormat';

const StyledText = styled(Body5)`
    color: ${({theme}) => theme.colors.palette.grey.manatee };
`;

const StyledIcon = styled(Icon)`
    font-size: 9px;
    margin-right: 6px;
`;

const HelpText = styled.span`
    border-bottom: 1px dashed ${({theme}) => theme.colors.palette.heatMap.green};
    cursor: pointer;
`;

const FilterBuilderTextContainer = styled.div`
    padding: 0 ${({theme}) => theme.layout.widget.horizontalPadding };
    min-height: 19px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;


function FilterBuilderText({
    formattedFilters
}) {
    const translator = useTranslator();

    const tooltipContent = (<FilterBuilderPrettyFormat formattedFilters={formattedFilters} />);

    const helpText = useMemo(() => {
        return generateFilterText(formattedFilters, HUMAN_READABLE, translator);
    }, [formattedFilters, translator]);

    return (
        <TouchableTooltipTarget content={tooltipContent}>
            <FilterBuilderTextContainer>
                <StyledText>
                    <StyledIcon
                        type="vorne"
                        iconName="filter"
                    />
                    <HelpText>{helpText}</HelpText>
                </StyledText>
            </FilterBuilderTextContainer>
        </TouchableTooltipTarget>
    );
}

FilterBuilderText.propTypes = {
    formattedFilters: PropTypes.array.isRequired
};

export default FilterBuilderText;
