import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { elementsOfType } from 'Common/util/propTypeHelpers';
import EventDataCell from './EventDataCell';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    min-height: 24px;

    font-size: 11px;
    border-radius: 4px;

    ${({ striped }) => striped && css`
        background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    `}
`;

export default function EventDataRow(props) {
    const { striped, columnOrder, columnConfigs, children } = props;

    const cellMap = useMemo(() => {
        const childrenArray = React.Children.toArray(children);

        const map = {};

        childrenArray.forEach((child, i) => {
            let columnName = child.props.column;
    
            if (columnName === undefined) {
                columnName = i;
            }
    
            if (typeof columnName === 'number') {
                columnName = columnOrder[columnName];
            }
    
            if (map[columnName]) {
                console.warn(`Detected cell with duplicate column: ${child.props.column}`);
            }
    
            map[columnName] = child;
        });

        return map;
    }, [ columnOrder, children ]);

    const cells = useMemo(() => {
        const totalRowSize = columnOrder.reduce((sum, columnName) => {
            return sum + columnConfigs[columnName].size;
        }, 0);

        return columnOrder.map((columnName, columnIndex) => {
            let child = cellMap[columnName];

            if (!child) {
                child = (
                    <EventDataCell column={columnName}>{''}</EventDataCell>
                );
            }

            const colspan = child.props.colspan;
            let cellSize = 0;

            for (let i = columnIndex; i < Math.min(columnIndex + colspan, columnOrder.length); i++) {
                cellSize += columnConfigs[columnOrder[i]].size;
            }

            const percentWidth = Math.floor((cellSize / totalRowSize) * 100);

            return React.cloneElement(child, {
                key: String(columnName),
                style: {
                    ...(child.props.style || {}),
                    flex: `0 0 ${percentWidth}%`,
                },
            });
        });
    }, [ cellMap, columnOrder, columnConfigs ]);

    return (
        <Root striped={striped}>
            {cells}
        </Root>
    );
}

EventDataRow.propTypes = {
    striped: PropTypes.bool,
    columnOrder: PropTypes.arrayOf(PropTypes.string),
    columnConfigs: PropTypes.objectOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
    })),
    children: elementsOfType([ EventDataCell ]),
};

EventDataRow.defaultProps = {
    striped: false,
};
