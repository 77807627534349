import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormElement from 'Components/form-element/FormElement';
import Table from 'Components/table/Table';
import TableHeader from 'Components/table/TableHeader';
import TableHeaderRow from 'Components/table/TableHeaderRow';
import TableHeaderColumn from 'Components/table/TableHeaderColumn';
import TableBody from 'Components/table/TableBody';
import TableRow from 'Components/table/TableRow';
import TableColumn from 'Components/table/TableColumn';
import TableErrorRow from 'Components/table/TableErrorRow';
import TableErrorColumn from 'Components/table/TableErrorColumn';
import TimeInput from 'Components/time-input/TimeInput';
import ThemedCheckboxInput from 'Components/checkbox/ThemedCheckboxInput';

function parseChildName(fullName) {
    // The fullName is in the form of "DAY-THE_NAME", we want to split
    // the day key and the name apart, dropping the hyphen.
    //
    const day = fullName.split('-', 1)[0];
    const name = fullName.substring(day.length + 1);

    return {
        key: day,
        name,
    };
}

class AlertWindowEditor extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        validation: PropTypes.objectOf(
            PropTypes.shape({
                start: PropTypes.string,
                end: PropTypes.string,
            }),
        ),
        allowEdit: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.objectOf(
            PropTypes.shape({
                enabled: PropTypes.bool.isRequired,
                start: PropTypes.string.isRequired,
                end: PropTypes.string.isRequired,
            }).isRequired,
        ),
    };

    static defaultProps = {
        allowEdit: true,
        onChange: () => { },
    };

    constructor(props) {
        super(props);
    }

    _handleChildChange = (event) => {
        const {
            name: fullChildName,
            value: childValue,
        } = event.target;

        const {
            name: thisName,
            onChange,
        } = this.props;

        const child = parseChildName(fullChildName);

        onChange({
            target: {
                name: thisName,
                value: {
                    ...this.props.value,
                    [child.key]: {
                        ...this.props.value[child.key],
                        [child.name]: childValue,
                    },
                },
            },
        });
    };

    _renderRows() {
        const {
            value,
            validation,
            allowEdit,
        } = this.props;

        const dayNames = moment.weekdays(false);
        const dayKeys = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        let rows = [];

        dayKeys.forEach((dayKey, i) => {
            const dayValue = value[dayKey];
            const dayError = validation[dayKey] || {};

            if (dayValue) {
                const {
                    enabled,
                    start,
                    end,
                } = dayValue;

                const {
                    start: startError,
                    end: endError,
                } = dayError;

                rows.push(
                    <TableRow key={`${i}`}>
                        <TableColumn>
                            {dayNames[i]}
                        </TableColumn>
                        <TableColumn>
                            <FormElement error={false} disabled={!allowEdit}>
                                <ThemedCheckboxInput
                                    name={`${dayKey}-enabled`}
                                    value={enabled}
                                    onChange={this._handleChildChange}
                                />
                            </FormElement>
                        </TableColumn>
                        <TableColumn>
                            <FormElement error={!!startError} disabled={!enabled || !allowEdit}>
                                <TimeInput
                                    name={`${dayKey}-start`}
                                    value={start}
                                    onChange={this._handleChildChange}
                                />
                            </FormElement>
                        </TableColumn>
                        <TableColumn>
                            <FormElement error={!!endError} disabled={!enabled || !allowEdit}>
                                <TimeInput
                                    name={`${dayKey}-end`}
                                    value={end}
                                    onChange={this._handleChildChange}
                                />
                            </FormElement>
                        </TableColumn>
                    </TableRow>
                );

                if (startError || endError) {
                    rows.push(
                        <TableErrorRow key={`${dayKey}-error`}>
                            <TableErrorColumn colSpan={2} />
                            <TableErrorColumn colSpan={2}>
                                {startError || endError}
                            </TableErrorColumn>
                        </TableErrorRow>
                    );
                }
            }
        });

        return rows;
    }

    render() {
        const {
            validation,
            onChange,
            value,
            ...otherProps
        } = this.props;

        return (
            <Table {...otherProps}>
                <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderColumn>
                            Day
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Receive Alerts
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Start Time
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            End Time
                        </TableHeaderColumn>
                    </TableHeaderRow>
                </TableHeader>
                <TableBody>
                    {this._renderRows()}
                </TableBody>
            </Table>
        );
    }
}

export default AlertWindowEditor;
