import React from 'react';
import PropTypes from 'prop-types';

function defaultLocale() {
    throw new Error('a locale must be provided in order to format text');
}

export const LocaleContext = React.createContext(defaultLocale);

export const LocaleConsumer = LocaleContext.Consumer;

/**
 * A provider for the LocaleContext which allows child elements to access the
 * device's locale via a LocaleConsumer or the useLocale hook.
 *
 * @param {Function} getLocale A function which returns the locale of the device
 * @param {Node} children
 *
 * @see useLocale
 */
function LocaleProvider({
    getLocale,
    children,
}) {
    if (!children) {
        return null;
    }

    if (!getLocale) {
        getLocale = defaultLocale;
    }

    return (
        <LocaleContext.Provider value={getLocale}>
            {children}
        </LocaleContext.Provider>
    );
}

LocaleProvider.propTypes = {
    getLocale: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default LocaleProvider;
