import React from 'react';
import PropTypes from 'prop-types';
import Metric from 'Common/components/FormattedDatum/Metric/Metric';
import { DIMENSION_VISUALIZATION_FORMAT_TYPES } from 'Common/components/SparkVisualization/SparkDimensionConstants';
import TextSparkVisualization from 'Common/components/SparkVisualization/TextSparkVisualization';
import ChronogramSparkVisualization from 'Common/components/SparkVisualization/ChronogramSparkVisualization';
import BarSparkVisualization from 'Common/components/SparkVisualization/BarSparkVisualization';
import ColumnSparkVisualization from 'Common/components/SparkVisualization/ColumnSparkVisualization';
import { useTranslator } from 'Common/hooks/useTranslation';
import InfoCircle from 'Common/components/InfoCircle';

export default function KPIGroupFormattedDatum({
    error,
    validationError,
    data,
    width,
    height,
    maxElements,
    noColor,
}) {
    const translator = useTranslator();

    let content = null;
    const formatType = data?.formatType;

    if (error?.length) {
        content = (
            <span title={error[0]}>
                <span> {translator('null_metric_literal')} </span>
            </span>
        );
    }
    else if (validationError) {
        content = (
            <div>
                <InfoCircle fontSize={14}>
                    {validationError}
                </InfoCircle>
            </div>
        );
    }
    else if (formatType === DIMENSION_VISUALIZATION_FORMAT_TYPES.BAR_FORMAT_TYPE) {
        content = (
            <BarSparkVisualization
                data={data}
                width={width}
                height={height}
                maxElements={maxElements}
                noColor={noColor}
            />
        );
    }
    else if (formatType === DIMENSION_VISUALIZATION_FORMAT_TYPES.CHRONOGRAM_FORMAT_TYPE) {
        content = (
            <ChronogramSparkVisualization
                data={data}
                width={width}
                height={height}
                noColor={noColor}
            />
        );
    }
    else if (formatType === DIMENSION_VISUALIZATION_FORMAT_TYPES.COLUMN_FORMAT_TYPE) {
        content = (
            <ColumnSparkVisualization
                data={data}
                width={width}
                height={height}
                maxElements={maxElements}
                noColor={noColor}
            />
        );
    }
    else if (formatType === DIMENSION_VISUALIZATION_FORMAT_TYPES.TEXT_FORMAT_TYPE) {
        content = (
            <TextSparkVisualization
                data={data}
                width={width}
                height={height}
                noColor={noColor}
                alignRight
            />
        );
    }
    else {
        content = (
            <Metric
                data={data}
                error={error}
                noColor={noColor}
            />
        );
    }

    return content;
}

KPIGroupFormattedDatum.propTypes = {
    error: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    maxElements: PropTypes.number,
    noColor: PropTypes.bool,
    validationError: PropTypes.string,
};

KPIGroupFormattedDatum.defaultProps = {
    noColor: false,
};
