import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import { filterFields, filterGroupedFields } from 'Common/components/FieldBuilder/FieldBuilder';
import FieldBuilderTypeSelect, { flattenEntries, SEPARATOR_TYPE } from 'Common/components/FieldBuilderTypeSelect/FieldBuilderTypeSelect';
import MetricAndMetricSetOption from 'Common/components/ColumnControl/MetricAndMetricSetOption';
import mergeMetricAndMetricSet, { filterCombinedMetricAndMetricSets } from 'Common/components/ColumnControl/mergeMetricAndMetricSet';
import CommentListColumnSelector from './CommentListColumnSelector';
import MetricListColumnEditor from './MetricListColumnEditor';
import AttentionListColumnSelector from './AttentionListColumnSelector';
import { METRIC_LIST_TYPE } from "Common/util/MetricListColumnConstants";
import { useTranslator } from 'Common/hooks/useTranslation';
import { ATTENTION_LIST_TYPE, COMMENT_LIST_TYPE } from '../tableWidgetConstants';
import { BAR_VISUALIZATION_TYPE, COLUMN_VISUALIZATION_TYPE, TEXT_VISUALIZATION_TYPE } from 'Common/components/SparkVisualization/SparkDimensionConstants';
import SparkDimensionColumnSelector from 'Common/components/ColumnControl/SparkDimensionColumnSelector';

const METRIC = 'metric';
const CATEGORY_VALUE = 'category_value';
const METRIC_SET = 'metric_set';

/**
 * This generates the list of available types and searchable fields.
 *
 * @returns {Object} Shape: {
 *     primary {Array}: The primary menu items for the ADD_COLUMN menu,
 *     searchable {Array}: The menu items when searching in the the ADD_COLUMN menu,
 * }
*/
function generateMenuOptions(translator, fieldOptions = {}, selectedFields = []) {
    let primaryMenuOptions = [];
    let searchableMenuOptions = [];

    const generateOptions = ({fields, searchFilter = filterFields, name, displayName}) => {
        const filteredFields = searchFilter(fields, selectedFields);

        searchableMenuOptions = searchableMenuOptions
            .concat(flattenEntries(filteredFields))
            .map((option) => {
                let displayType = '';
                if (option.fieldType === METRIC) {
                    displayType = translator('metric_literal');
                }
                else if (option.fieldType === CATEGORY_VALUE) {
                    displayType = translator('category_literal');
                }
                else if (option.fieldType === ATTENTION_LIST_TYPE) {
                    displayType = translator('attention_list');
                }
                else if (option.fieldType === METRIC_LIST_TYPE) {
                    displayType = translator('metric_list');
                }
                else if (option.fieldType === METRIC_SET) {
                    displayType = translator('metric_set');
                }

                return { ...option, displayType };
            });

        primaryMenuOptions.push({
            name,
            displayName,
            disabled: filteredFields.length === 0,
            hasChildren: true,
        });
    };

    const generateSeparator = () => {
        const numberOfOptions = primaryMenuOptions.length;

        // Never start with a separator and never put two separators in a row
        if (numberOfOptions !== 0
            && primaryMenuOptions[numberOfOptions - 1].type !== SEPARATOR_TYPE)
        {
            primaryMenuOptions.push({
                type: SEPARATOR_TYPE,
            });
        }
    };

    generateOptions({
        fields: mergeMetricAndMetricSet(fieldOptions.metric || [], fieldOptions.metric_set || []),
        searchFilter: filterCombinedMetricAndMetricSets,
        name: METRIC,
        displayName: translator('metric_literal'),
    });

    if (fieldOptions.category_value) {
        generateOptions({
            fields: fieldOptions.category_value,
            searchFilter: filterGroupedFields,
            name: CATEGORY_VALUE,
            displayName: translator('category_literal'),
        });
    }

    generateSeparator();

    if (fieldOptions.attention_list) {
        generateOptions({
            fields: fieldOptions.attention_list,
            name: ATTENTION_LIST_TYPE,
            displayName: translator('attention_list'),
        });
    }

    if (fieldOptions.metric_list) {
        generateOptions({
            fields: fieldOptions.metric_list,
            searchFilter: (fields) => { return fields; },
            name: METRIC_LIST_TYPE,
            displayName: translator('metric_list')
        });
    }

    generateSeparator();

    if (fieldOptions.comment_list) {
        generateOptions({
            fields: fieldOptions.comment_list,
            name: COMMENT_LIST_TYPE,
            displayName: translator('comment')
        });
    }

    return {
        primary: primaryMenuOptions,
        searchable: searchableMenuOptions,
    };
}

const categoryValueMetricOptionsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        name: PropTypes.string.isRequired
    })
);

const TableFieldsOption = ({
    fieldOptions,
    selectedColumns,
    onChange,
    transitionForward,
    initialMenuSelection
}) => {
    const translator = useTranslator();

    // the different types of fields available
    // for column selection and their respective lists
    const menuOptions = useMemo(() => {
        return generateMenuOptions(translator, fieldOptions, selectedColumns);
    }, [fieldOptions, selectedColumns, translator]);

    const handleOnChange = useCallback((e) => {
        if (e.target.type === 'main' 
            || e.target.value.fieldType === ATTENTION_LIST_TYPE 
            || e.target.value.fieldType === METRIC_LIST_TYPE 
            || e.target.value.fieldType === COMMENT_LIST_TYPE 
            || e.target.value.fieldType === CATEGORY_VALUE) {
            // we keep the users selection highlighted if they end up traveling back here
            let menuSelection = e.target.value.name;
            let newControlOptions = null;

            if (menuSelection === METRIC) {
                newControlOptions = { control: MetricAndMetricSetOption, props: {}, title: translator('add_field_template', { field: translator('metric_literal')}) };
            }
            else if (e.target.type === 'search' && e.target.value.fieldType === CATEGORY_VALUE) {
                newControlOptions = { control: SparkDimensionColumnSelector, props: { startingValue: { metric: 'duration', name: e.target.value.name} }, title: translator('add_field_template', { field: translator('category_literal')}) };
            }
            else if (menuSelection === CATEGORY_VALUE) {
                newControlOptions = { control: SparkDimensionColumnSelector, props: { startingValue: { metric: 'duration' } }, title: translator('add_field_template', { field: translator('category_literal')}) };
            }
            else {
                // displayType was added so lets remove it
                const column = e.target.type === 'main' ? null : omit(e.target.value, 'displayType');

                if (menuSelection === ATTENTION_LIST_TYPE || column?.fieldType === ATTENTION_LIST_TYPE) {
                    newControlOptions = { control: AttentionListColumnSelector, props: column ? { startingValue: column } : {}, title: translator('add_field_template', { field: translator('attention_list')}) };
                }
                else if (menuSelection === METRIC_LIST_TYPE || column?.fieldType === METRIC_LIST_TYPE) {
                    newControlOptions = { control: MetricListColumnEditor, props: column ? { startingValue: { initialValue: omit(column, 'name') } } : {}, title: translator('add_field_template', { field: translator('metric_list')}) };
                }
                else if (menuSelection === COMMENT_LIST_TYPE || column?.fieldType === COMMENT_LIST_TYPE) {
                    newControlOptions = { control: CommentListColumnSelector, props: column ? { startingValue: column } : {}, title: translator('add_field_template', { field: translator('comment')}) };
                }
            }

            if (newControlOptions) {
                transitionForward(
                    newControlOptions,
                    { control: TableFieldsOption, props: { initialMenuSelection: menuSelection }, title: translator('add_column_literal') }
                );
            }
        }
        else {
            const column = e.target.value;
            if (column.fieldType === METRIC_SET) {
                const newColumns = column.columns;
                let updatedColumns = [ ...selectedColumns ];

                let selectedColumnsByName = selectedColumns.reduce((byName, col) => {
                    byName[col.name] = col;
                    return byName;
                }, []);

                // for every column in the new metric set, check if it already exists in the table.
                // If not, concat the new column onto the array

                newColumns.forEach(newColumn => {
                    if (!selectedColumnsByName[newColumn.name]) {
                        updatedColumns.push(newColumn);
                    }
                });

                onChange({
                    target: {
                        value: updatedColumns,
                    }
                });
            }
            else {
                // displayType was added so lets remove it
                const modifiedColumn = omit(column, 'displayType');
                onChange({
                    target: {
                        value: selectedColumns.concat([modifiedColumn]),
                    }
                });
            }
        }
    }, [onChange, transitionForward, selectedColumns, translator]);

    return (
        <FieldBuilderTypeSelect
            options={menuOptions.primary}
            searchOptions={menuOptions.searchable}
            instruction={translator('control.table.column_primary_instruction')}
            value={initialMenuSelection || null}
            onChange={handleOnChange}
        />);
};

TableFieldsOption.propTypes = {
    fieldOptions: PropTypes.shape({
        metric: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        fieldType: PropTypes.string,
                    }),
                ).isRequired,
            }),
        ).isRequired,
        metric_set: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        fieldType: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ).isRequired,
        category_value: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        fieldType: PropTypes.string,
                    }),
                ).isRequired,
            }),
        ),
        category_value_metrics: PropTypes.exact({
            [BAR_VISUALIZATION_TYPE]: categoryValueMetricOptionsPropType.isRequired,
            [COLUMN_VISUALIZATION_TYPE]: categoryValueMetricOptionsPropType.isRequired,
            [TEXT_VISUALIZATION_TYPE]: categoryValueMetricOptionsPropType.isRequired,
        }),
        attention_list: PropTypes.arrayOf(
            PropTypes.shape({
                fieldType: PropTypes.string,
            }),
        ),
        metric_list: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        fieldType: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ),
        comment_list: PropTypes.arrayOf(
            PropTypes.shape({
                fieldType: PropTypes.string,
            }),
        ),
    }).isRequired,
    initialMenuSelection: PropTypes.string,
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            channel: PropTypes.string.isRequired,
            fieldType: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    transitionForward: PropTypes.func.isRequired,
};

export default TableFieldsOption;
