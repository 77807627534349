import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Box = styled.div``;

const Root = styled.div`
    margin-left: auto;
    margin-right: auto;

    font-size: ${({variant}) => {
        switch(variant) {
            case 'small': return '5px';
            case 'medium': return '8px';
            case 'large': return '12px';
        }
    }};

    ${Box} {
        display: inline-block;
        width: 1.2em;
        height: 2em;
        background-color: ${({theme}) => theme.colors.palette.blue.awesome};

        margin-left: 0.2em;
        margin-right: 0.2em;

        animation-name: box-grow;
        animation-duration: 1.6s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: both;

        :nth-child(2) {
            animation-delay: 0.2s;
        }

        :nth-child(3) {
            animation-delay: 0.4s;
        }
    }

    @keyframes box-grow {
        0%, 90%, 100% {
            transform: scale(0.4);
        }
        50% {
            transform: scale(1.0);
        }
    }
`;

function LoadingIndicator({
    variant,
    ...otherProps
}) {
    return (
        <Root variant={variant} {...otherProps}>
            <Box/>
            <Box/>
            <Box/>
        </Root>
    );
}

LoadingIndicator.propTypes = {
    variant: PropTypes.oneOf([
        'small',
        'medium',
        'large',
    ]),
};

LoadingIndicator.defaultProps = {
    variant: 'medium',
};

export default LoadingIndicator;
