import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PopupMask from './PopupMask';

const RENDER_PHASE = {
    MEASURING: 'MEASURING',
    POSITIONING: 'POSITIONING',
    DONE: 'DONE',
};

const Mask = styled(PopupMask)`
    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity 300ms;

    opacity: 0;
    &.visible {
        opacity: 1;
    }
`;

const ContentWrapper = styled.div`
    display: inline-block;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    opacity: 1.0;
`;

export default class Popup extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        onMaskClick: PropTypes.func,
        // If width and height are not provided, the popup will automatically
        // fit to the size of the content within.
        width: PropTypes.number.isRequired,
        height: PropTypes.number,
        darkenMask: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        onMaskClick: () => {},
        height: null,
        darkenMask: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            measured: false,
            phase: RENDER_PHASE.MEASURING,
            maskWidth: 0,
            maskHeight: 0,
            contentWidth: 0,
        };
        this._animationFrameRequest = null;
        this._maskRef = React.createRef();
        this._contentMeasureRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this._handleWindowResize);

        this._animationFrameRequest = requestAnimationFrame(() => {
            this._afterRender();
            this._animationFrameRequest = null;
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Cancel any pending animation frame request
        if (this._animationFrameRequest !== null) {
            cancelAnimationFrame(this._animationFrameRequest);
        }

        this._animationFrameRequest = requestAnimationFrame(() => {
            this._afterRender();
            this._animationFrameRequest = null;
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleWindowResize);

        if (this._animationFrameRequest !== null) {
            cancelAnimationFrame(this._animationFrameRequest);
        }
    }

    _afterRender() {
        this.setState((state) => {
            const { phase } = this.state;

            switch (phase) {
                case RENDER_PHASE.MEASURING:
                    return {
                        ...this._measureMask(),
                        ...this._measureContent(),
                        phase: RENDER_PHASE.POSITIONING,
                    };
                case RENDER_PHASE.POSITIONING:
                    return {
                        phase: RENDER_PHASE.DONE,
                    };
                case RENDER_PHASE.DONE:
                default:
                    break;
            }
        });
    }

    _measureMask() {
        const mask = this._maskRef.current;
        const maskRect = mask.getBoundingClientRect();

        return {
            maskWidth: maskRect.width,
            maskHeight: maskRect.height,
        };
    }

    _measureContent() {
        const content = this._contentMeasureRef.current;
        const contentRect = content.getBoundingClientRect();

        return {
            contentWidth: contentRect.width,
        };
    }

    _handleWindowResize = () => {
        this.setState({
            ...this._measureMask(),
        });
    };

    _handleContentWrapperClick = (event) => {
        // This stops click events from bubbling up to the mask
        event.stopPropagation();
    };

    render() {
        const {
            children,
            onMaskClick,
            darkenMask,
            width: widthProp,
            height: heightProp,
        } = this.props;

        const {
            phase,
            maskWidth,
            maskHeight,
            contentWidth,
        } = this.state;

        let contentWrapperStyle = {};
        let contentStyle = {};

        switch (phase) {
            case RENDER_PHASE.MEASURING:
                contentWrapperStyle = {
                    opacity: '1',
                    position: 'fixed',
                    overflow: 'visible',
                    display: 'inline-block',
                    maxWidth: 'unset',
                    maxHeight: 'unset',
                    width: `${widthProp}px`,
                    height: Number.isFinite(heightProp) ? `${heightProp}px` : undefined,
                };
                break;
            case RENDER_PHASE.POSITIONING:
            case RENDER_PHASE.DONE: {
                // If there is not enough space horizontally to
                // display the content, put it to fullscreen

                const fullscreen = maskWidth <= contentWidth;

                if (fullscreen) {
                    contentWrapperStyle = {
                        width: '100%',
                        height: '100%',
                    };
                }
                else {
                    contentWrapperStyle = {
                        maxWidth: `${maskWidth}px`,
                        maxHeight: `${maskHeight}px`,
                        width: `${widthProp}px`,
                        // If a fixed height was provided, use it. Otherwise,
                        // allow the height to be dynamic.
                        height: Number.isFinite(heightProp) ? `${heightProp}px` : undefined,
                    };
                    contentStyle = {
                        maxHeight: `${maskHeight}px`,
                    };
                }
                break;
            }
        }

        return (
            <Mask
                maskRef={this._maskRef}
                className={classNames({ visible: phase === RENDER_PHASE.DONE })}
                dark={darkenMask}
                onClick={onMaskClick}
            >
                <ContentWrapper
                    ref={this._contentMeasureRef}
                    style={contentWrapperStyle}
                    onClick={this._handleContentWrapperClick}
                >
                    {React.cloneElement(React.Children.only(children), {
                        style: contentStyle,
                    })}
                </ContentWrapper>
            </Mask>
        );
    }
}
