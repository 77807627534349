import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { T1 } from 'Components/typography/typography';
import { Helmet } from 'react-helmet-async';

const Root = styled(T1)`
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1.7em;
    color: ${props => props.theme.colors.palette.black};
`;

export default function PageTitle(props) {
    const { children, preface, ...otherProps } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>{children}</title>
            </Helmet>
            <Root {...otherProps}>
                <T1>{preface} {children}</T1>
            </Root>
        </React.Fragment>
    );
}

PageTitle.propTypes = {
    children: PropTypes.string,
    preface: PropTypes.node,
};
