
import React, {
    useReducer,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';
import LoadingPage from 'Components/pages/loading/LoadingPage';
import InvalidUrlPage from 'Components/pages/invalid-url/InvalidUrlPage';
import EmailTableWidget from './EmailTableWidget';
import KPIWidget from 'Common/components/widgets/KPIWidget/KPIWidget';
import KPIGroupWidget from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidget';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    cloneDeep,
} from 'lodash';

import useNavigationPrevention from 'Common/hooks/useNavigationPrevention';
import { useTranslator } from 'Common/hooks/useTranslation';
import { widgetSchema } from './WidgetSchema';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import DashboardLayoutEditor from 'Common/components/Dashboard/DashboardLayout/DashboardLayoutEditor';
import { Heading4 } from 'Common/components/typography';
import Form from 'Components/form/Form';
import Icon from 'Common/components/Icon';
import InfoCircle from 'Common/components/InfoCircle';
import MenuDropdown from 'Common/components/MenuToolbar/MenuDropdown';
import PageSelectField from 'Common/components/Dashboard/DashboardLayout/PageSelectField';
import MenuItem from 'Common/components/MenuToolbar/MenuItem';
import MenuToolbar from 'Common/components/MenuToolbar/MenuToolbar';
import ModalWindow from 'Common/components/ModalWindow/ModalWindow';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentWide';
import PageDescription from 'Components/page/PageDescription';
import TextInput from 'Components/text-input/TextInput';
import { WidgetCacheProvider, DashboardCacheProvider } from 'Common/components/Dashboard/DashboardCache';
import { v4 as uuid } from 'uuid';
import { bindActionCreators } from 'redux';
import getDataService from './getDataService';

import {
    postEmailTemplate,
    patchEmailTemplate,
    getEmailTemplates,
    deleteEmailTemplate,
    getMetricThresholds,
} from 'Redux/actions';
import { connect } from 'react-redux';
import {
    useHistory,
    useLocation,
    useParams,
    withRouter,
} from 'react-router-dom';

import {
    addSection,
    addWidget,
    dashboardLayoutReducer,
    deleteSection,
    deleteWidget,
    initPage,
    modifySection,
    moveSection,
    moveWidget,
    selectIsPageDirty,
    selectPageStf,
    selectWidgetState,
    selectWidgetStf,
    setWidgetExportItem,
    setWidgetLiveControlsVisibility,
    setWidgetPrintStatus,
    setWidgetState,
    setWidgetStf,
} from 'Common/components/Dashboard/DashboardLayout/dashboardLayoutReducer';
import { getEmailTemplatesList } from 'Redux/reducers/emailTemplates';
import Button from 'Common/components/Button';
import { selectMetricThresholdsByColumnName } from 'Redux/reducers/metricThresholds';

const leftPadding = 20;

const buttonStyle = {
    minHeight: '35px'
};

function getNextWidgetId() {
    const id = `widget-${uuid()}`;
    return id;
}

const NewTitleTextInput = styled(TextInput)`
    width: 100%
`;

const ToolbarContainer = styled.div`
    padding-left: ${leftPadding}px;
    background-color: ${props => props.theme.colors.palette.grey.cloud};
`;

// style copied from ContentArea system to keep things looking the same
const PageHeaderContainer = styled.div`
    padding-left: ${leftPadding}px;
`;

// style copied from ContentArea system to keep things looking the same
const PageLiveControlBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`;

// style copied from ContentArea system to keep things looking the same
const PageSelectContainer = styled.div`
    padding: 5px 15px 5px 0px;
    min-width: 150px;
`;

// style copied from ContentArea system to keep things looking the same
const PageSelectDisplayContainer = styled.div`
    display: flex;
    align-items: baseline;
    flex-direction: row;
    justify-content: space-between;
`;

// style copied from ContentArea system to keep things looking the same
const DropdownDisplay = styled(Heading4).attrs({ as: 'div' })`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

// style copied from ContentArea system to keep things looking the same
const PageName = styled(DropdownDisplay)`
    flex: 1 1 auto;
`;

const NewTitleDialogState = {
    HIDE: 'HIDE',
    NEW: 'NEW',
    SAVE_AS: 'SAVE_AS',
    DUPLICATE: 'DUPLICATE',
};

const DEFAULT_NEW_PAGE_STF = {
    sections: [
        {
            'type': 'full',
            'columns': [
                {
                    'widgets': []
                }
            ]
        }
    ]
};

function renderWidget(widgetId, widgetProps, contentAreaProps) {
    const {
        isLayoutModeActive,
        dragHandleProps,
        sectionIndex,
        columnIndex,
        widgetIndex,
    } = widgetProps;

    const {
        reducerState,
        dataService,

        mutable,
        originName,
        parentTitle,
        onSavePage,
        onExportReadyChange,
        exportWidgetData,

        timeRange,
        explorationPath,

        actions,

        isPrint,
        printConfig,

        // Legacy stuff needed only for the top losses page
        selectedLossCount,
        collapseHierarchy,
        onLossCountSelectorChange,

        // Legacy stuff needed only for the time schedule
        scheduleTab,
        onScheduleTabSelectorChange,
        timeSelector,
        onTimeSelectorChange,
        metricThresholds,
    } = contentAreaProps;

    const widgetStf = selectWidgetStf(reducerState, widgetId);
    const widgetState = selectWidgetState(reducerState, widgetId);

    const areLiveControlsVisible = true;

    /**
     * render a specific type of widget based on the type as defined in the stf
     */
    const renderWidgetByType = () => {
        if (widgetStf.type === 'kpi_widget') {
            return (
                <KPIWidget
                    widgetId={widgetId}
                    sectionIndex={sectionIndex}
                    columnIndex={columnIndex}
                    widgetIndex={widgetIndex}

                    dataService={dataService}

                    widgetStf={widgetStf}
                    widgetState={widgetState}
                    areLiveControlsVisible={areLiveControlsVisible}

                    timeRange={timeRange}
                    explorationPath={explorationPath}

                    frame={widgetStf.frame !== false}
                    mutable={Boolean(mutable)}
                    originName={originName}
                    parentTitle={parentTitle}
                    dragHandleProps={dragHandleProps}
                    isDragEnabled={false}
                    isLayoutModeActive={isLayoutModeActive}
                    isPrint={Boolean(isPrint)}
                    printConfig={printConfig}
                    hideTrend={true}
                    reportHierarchyNode={{}}

                    onExportReadyChange={onExportReadyChange}
                    exportWidgetData={exportWidgetData}
                    onSavePage={onSavePage}

                    actions={actions}

                    suppressedControls={{
                        hierarchyNode: true,
                        timeRange: true,
                        filter: true,
                        duplicateWidget: true,
                    }}

                    // Legacy stuff needed only for the top losses page
                    selectedLossCount={selectedLossCount}
                    collapseHierarchy={collapseHierarchy}
                    onLossCountSelectorChange={onLossCountSelectorChange}

                    // Legacy stuff needed only for the time schedule
                    timeSelector={timeSelector}
                    onTimeSelectorChange={onTimeSelectorChange}
                    scheduleTab={scheduleTab}
                    onScheduleTabSelectorChange={onScheduleTabSelectorChange}
                />
            );
        }
        else if (widgetStf.type === 'kpi_group_widget') {
            return (
                <KPIGroupWidget
                    widgetId={widgetId}
                    sectionIndex={sectionIndex}
                    columnIndex={columnIndex}
                    widgetIndex={widgetIndex}

                    dataService={dataService}

                    widgetStf={widgetStf}
                    widgetState={widgetState}
                    areLiveControlsVisible={areLiveControlsVisible}

                    timeRange={timeRange}
                    explorationPath={explorationPath}

                    frame={widgetStf.frame !== false}
                    mutable={Boolean(mutable)}
                    originName={originName}
                    parentTitle={parentTitle}
                    dragHandleProps={dragHandleProps}
                    isDragEnabled={false}
                    isLayoutModeActive={isLayoutModeActive}
                    suppressDelete={true}
                    isPrint={Boolean(isPrint)}
                    printConfig={printConfig}

                    onExportReadyChange={onExportReadyChange}
                    exportWidgetData={exportWidgetData}
                    onSavePage={onSavePage}

                    actions={actions}

                    suppressedControls={{
                        hierarchyNode: true,
                        timeRange: true,
                        filter: true,
                        duplicateWidget: true,
                    }}

                    // Legacy stuff needed only for the top losses page
                    selectedLossCount={selectedLossCount}
                    collapseHierarchy={collapseHierarchy}
                    onLossCountSelectorChange={onLossCountSelectorChange}

                    // Legacy stuff needed only for the time schedule
                    timeSelector={timeSelector}
                    onTimeSelectorChange={onTimeSelectorChange}
                    scheduleTab={scheduleTab}
                    onScheduleTabSelectorChange={onScheduleTabSelectorChange}
                />
            );
        }
        else if (widgetStf.type === 'email_table_widget') {
            return (
                <EmailTableWidget
                    metricThresholds={metricThresholds}
                    widgetId={widgetId}
                    sectionIndex={sectionIndex}
                    columnIndex={columnIndex}
                    widgetIndex={widgetIndex}

                    dataService={dataService}

                    widgetStf={widgetStf}
                    widgetState={widgetState}
                    areLiveControlsVisible={areLiveControlsVisible}

                    timeRange={timeRange}
                    explorationPath={explorationPath}

                    frame={widgetStf.frame !== false}
                    mutable={Boolean(mutable)}
                    originName={originName}
                    parentTitle={parentTitle}
                    dragHandleProps={dragHandleProps}
                    isDragEnabled={false}
                    isLayoutModeActive={isLayoutModeActive}
                    isPrint={Boolean(isPrint)}
                    printConfig={printConfig}
                    hideTrend={true}

                    onExportReadyChange={onExportReadyChange}
                    exportWidgetData={exportWidgetData}
                    onSavePage={onSavePage}

                    actions={actions}

                    // Legacy stuff needed only for the top losses page
                    selectedLossCount={selectedLossCount}
                    collapseHierarchy={collapseHierarchy}
                    onLossCountSelectorChange={onLossCountSelectorChange}

                    // Legacy stuff needed only for the time schedule
                    timeSelector={timeSelector}
                    onTimeSelectorChange={onTimeSelectorChange}
                    scheduleTab={scheduleTab}
                    onScheduleTabSelectorChange={onScheduleTabSelectorChange}
                />
            );
        }
    };

    return (
        <DashboardCacheProvider>
            <WidgetCacheProvider
                widgetId={widgetId}
            >
                {renderWidgetByType()}

            </WidgetCacheProvider>
        </DashboardCacheProvider>
    );
}

function getRenderWidget(contentAreaProps) {
    const duplicateWidget = (source, newStf) => {
        // Copy the widget STF, but with a new widget ID.
        let widgetStf = cloneDeep(newStf);
        const newWidgetId = getNextWidgetId();
        widgetStf.widgetId = newWidgetId;

        addWidget(
            newWidgetId,
            {
                sectionIndex: source.sectionIndex,
                columnIndex: source.columnIndex,
                widgetIndex: source.widgetIndex,
            },
            widgetStf,
        );
    };

    const exportWidgetData = () => {
        console.error('unsupported');
    };

    return (widgetId, widgetOpts) => {
        return renderWidget(widgetId, widgetOpts, {
            ...contentAreaProps,
            actions: {
                deleteWidget,
                duplicateWidget,
                exportWidgetData,
                setWidgetExportItem,
                setWidgetLiveControlsVisibility,
                setWidgetPrintStatus: (printStatus) => {
                    setWidgetPrintStatus(widgetId, printStatus);
                },
                setWidgetState,
                setWidgetStf: (func) => {
                    const widgetStf = selectWidgetStf(contentAreaProps.reducerState, widgetId);
                    const newStf = func(widgetStf);
                    const reducer = setWidgetStf(widgetId, newStf);
                    contentAreaProps.dispatch(reducer);
                },
            },
        });
    };
}

function sortTemplates(lhs, rhs) {
    lhs = lhs.data;
    rhs = rhs.data;

    if (lhs.built_in && !rhs.built_in) {
        return -1;
    }
    if (rhs.built_in && !lhs.built_in) {
        return 1;
    }

    return lhs.title.localeCompare(rhs.title);
}

function EmailTemplatesPage(props) {

    const {
        actions,
        emailTemplates,
        metricThresholds,
        requests,
    } = props;

    const [showNewTitleDialog, setShowNewTitleDialog] = useState(NewTitleDialogState.HIDE);
    const [newTitleValue, setNewTitleValue] = useState('');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const { pageId } = useParams();
    const pageIdNumber = pageId ? parseInt(pageId, 10) : pageId;

    useEffect(() => {
        const load = async () => {
            await Promise.all([
                actions.getEmailTemplates(),
                actions.getMetricThresholds(),
            ]);
        };

        load();
    }, [actions]);

    // triggered pageId changes, only impacts the page when pageId to non falsey to redirect the
    // browser to an actual url as opposed to non-id url. Basically implements the default template
    useEffect(() => {
        if (! pageId || pageId === '0') {
            const defaultId = emailTemplates.length > 0 ? emailTemplates[0].data.id : 0;
            history.push(`/config/plant-floor/email-templates/${defaultId}`);
        }
    }, [pageId, history, emailTemplates]);

    // savedPageData contains the data for the page as it is stored in enterprise redux, essentially
    // cloning what is on the server
    const savedPageData = useMemo(() => {
        const rv = emailTemplates.find((template) => {
            return template.data.id === pageIdNumber;
        });
        if (rv?.data) {
            // Add widget IDs since they are no longer stored in stf as of commit: 1e79ee2f741
            rv.data.content.sections = (rv.data.content.sections || []).map(section => {
                const columns = (section.columns || []).map(column => {
                    const widgets = (column.widgets || [])
                        .map((widgetStf) => {
                            const widgetId = getNextWidgetId();

                            return {
                                ...widgetStf,
                                widgetId: widgetId,
                            };
                        });
                    return { ...column, widgets };
                });
                return { ...section, columns };
            });
        }
        return rv;
    }, [pageIdNumber, emailTemplates]);

    // maps the other report templates available to a data structure that <PageSelectField> likes
    // for the dropdown
    const allEmailTemplatesView = useMemo(() => emailTemplates.sort(sortTemplates).map((template) => {
        const { data } = template;
        return {
            displayName: data.title,
            groupName: data.built_in ? 'Built-In Views' : 'Shared Views',
            id: data.id,
            isPlaceHolder: false,
            isSeparator: false,
            path: `/config/plant-floor/email-templates/${data.id}`,
        };
    }), [ emailTemplates ]);

    // state and reducer system for the current state of the page, this is not part of the
    // enterprise redux state store
    // it is initialized with the saved page from the server or a default initial state
    //TODO this should be populated from the API with the current state of the page.
    const [pageState, dispatch] = useReducer(
        dashboardLayoutReducer,
        {
            pageStf: DEFAULT_NEW_PAGE_STF,
            isPageDirty: false,
            printStatus: {
                isReady: false,
            },
        }
    );

    // reinitialize the page when savedPageData is first computed or it has changed
    useEffect(() => {
        // make sure not to reload the save page data while the patch email request is pending
        if (savedPageData && savedPageData.requests.patchEmailTemplate.status !== 'PENDING') {
            dispatch(initPage({ ...savedPageData?.data.content }));
        }
    }, [savedPageData]);

    const isPageDirty = selectIsPageDirty(pageState);
    const {
        component: navigationPrevention,
        cancel: cancelNav,
        reset: resetNav,
    }  = useNavigationPrevention(isPageDirty);

    const submitNewReport = (newTitle, newContent) => {
        actions.postEmailTemplate({
            title: newTitle,
            content: newContent,
        }, (newReportList) => {
            // find the template id that was added so the page can redirect there
            const origTemplateIds = emailTemplates.map((et) => {
                return et.data.id;
            });
            const newTemplateIds = newReportList.map((et) => {
                return et.id;
            });
            const added = newTemplateIds.filter(x => !origTemplateIds.includes(x));
            // when navigating away after saving a new page assume navigation prevention is unnecessary
            // the user probably just did a save as so the original page can be abandoned without worry
            cancelNav();
            history.push(`/config/plant-floor/email-templates/${added}`);
            resetNav();
        });
    };

    const onDeleteReport = useCallback(async () => {
        let newPage = 0;

        if (emailTemplates.length > 0) {
            emailTemplates.forEach(report => {
                if (report.data.id !== pageIdNumber && newPage !== 0)  {
                    newPage = report.data.id;
                }
            });
        }

        await actions.deleteEmailTemplate(pageIdNumber);
        history.push(`/config/plant-floor/email-templates/${newPage}`);
    }, [actions, history, pageIdNumber, emailTemplates]);

    const onSaveReport = () => {
        actions.patchEmailTemplate(pageIdNumber, {
            'content': selectPageStf(pageState),
            'title': savedPageData.data.title,
        });
    };

    const handleWidgetMove = (event) => {
        dispatch(moveWidget(event.source, event.destination));
    };

    const dispatchNewWidget = (sectionIndex, columnIndex, widgetIndex, defaultStf) => {
        const newWidgetId = getNextWidgetId();
        let widgetStf = cloneDeep(defaultStf);
        widgetStf.widgetId = newWidgetId;

        dispatch(addWidget(
            newWidgetId,
            {
                sectionIndex,
                columnIndex,
                widgetIndex,
            },
            widgetStf,
        ));
    };

    const handleAddWidget = (event) => {
        const { widgetType, destination } = event;
        const schema = widgetSchema[widgetType];
        const defaultStf = schema.defaults;

        let sectionType;
        if (widgetType === 'kpi_widget') {
            sectionType = 'thirds';
        }
        else if (widgetType === 'kpi_group_widget') {
            sectionType = 'halves';
        }
        else if (widgetType === 'email_table_widget') {
            sectionType = 'full';
        }

        dispatch(modifySection(destination.sectionIndex, sectionType, undefined));

        if (widgetType === 'kpi_widget') {
            dispatchNewWidget(destination.sectionIndex, 0, 0, defaultStf);
            dispatchNewWidget(destination.sectionIndex, 1, 0, defaultStf);
            dispatchNewWidget(destination.sectionIndex, 2, 0, defaultStf);
        }
        else if (widgetType === 'kpi_group_widget') {
            dispatchNewWidget(destination.sectionIndex, 0, 0, defaultStf);
            dispatchNewWidget(destination.sectionIndex, 1, 0, defaultStf);
        }
        else if (widgetType === 'email_table_widget') {
            dispatchNewWidget(destination.sectionIndex, 0, 0, defaultStf);
        }
    };

    const getScrollParent = () => {
        console.warn('getScrollParent');
    };

    const handleSectionAdd = (event) => {
        dispatch(addSection(event.sectionType, event.sectionIndex));
    };

    const handleSectionDelete = (event) => {
        dispatch(deleteSection(event.sectionIndex));
    };

    const handleSectionModify = (event) => {
        dispatch(modifySection(event.sectionIndex, event.sectionType, event.sectionHeading));
    };

    const handleSectionMove = (event) => {
        dispatch(moveSection(event.sectionSrcIndex, event.sectionDestIndex));
    };

    const translator = useTranslator();

    const dataService = getDataService(translator, metricThresholds);

    const timeRange = {
        units: 'production_day',
        start: 0,
        end: 0,
        dayBasis: 'day',
    };

    const explorationPath = {
    };

    const renderWidgetFn = getRenderWidget({
        dispatch,
        reducerState: pageState,
        dataService: dataService,
        mutable: true,
        originName: 'originName',
        parentTitle: 'parentTitle',
        timeRange: timeRange,
        explorationPath: explorationPath,
        metricThresholds,
    });

    const viewPath = location.pathname;
    const viewDisplayName = savedPageData?.data.title || 'Unknown';

    // built-in pages can not be saved to or deleted
    // users can still make modifications that they can Save As or Duplicate
    const canModifyCurrentPage = ! savedPageData?.data?.built_in;

    const onPageChange = (evt) => {
        // navigate to newly selected page
        history.push(evt.target.value);
    };

    if (! pageId ) {
        return (
            <div>
                Redirecting to default page
            </div>
        );
    }

    const renderNewTitleInput = () => {
        const newTitleIsValid = (title) => {
            const exists = emailTemplates.some(
                (emailTemplate) => emailTemplate.data.title === title
            );
            return (!exists && title.length > 0);
        };

        let newHeading = 'New Report';
        if (showNewTitleDialog === NewTitleDialogState.SAVE_AS) {
            newHeading = 'Save As…';
        }
        else if (showNewTitleDialog === NewTitleDialogState.DUPLICATE) {
            newHeading = 'Duplicate Report';
        }

        return (
            <ModalWindow title={newHeading}>
                <Form
                    onSubmit={() => {
                        if (showNewTitleDialog === NewTitleDialogState.NEW) {
                            submitNewReport(newTitleValue, DEFAULT_NEW_PAGE_STF);
                        }
                        else {
                            submitNewReport(newTitleValue, selectPageStf(pageState));
                        }
                        setShowNewTitleDialog(NewTitleDialogState.HIDE);
                        setNewTitleValue('');
                    }}
                    onReset={() => {
                        setShowNewTitleDialog(NewTitleDialogState.HIDE);
                        setNewTitleValue('');
                    }}
                    allowSubmit={newTitleIsValid(newTitleValue)}
                    allowCancel={true}
                >
                    <NewTitleTextInput
                        label="New Report Name"
                        value={newTitleValue}
                        disabled={false}
                        name="newtitle"
                        onChange={(event) => {
                            setNewTitleValue(event.target.value);
                        }}
                    />
                </Form>
            </ModalWindow>
        );
    };

    const renderPage = () => {
        if (requests.getEmailTemplates.status === 'PENDING') {
            return <LoadingPage />;
        }
        if (! savedPageData) {
            return <InvalidUrlPage />;
        }
        return (
            <Page>
                <ConfigPageTitle>Email Report Templates</ConfigPageTitle>
                <PageDescription>
                    Use this page to structure and organize email templates.
                    Templates are shown with mock data.
                    <InfoCircle fontSize={16}>
                            <p>Each email template can include any number of sections. Each section can
                            hold one widget row. A widget row contains either three KPI widgets, two
                            KPI Group widgets, or one Table widget.</p>

                            <p>Email template widgets are very simple so they can function well across a
                            broad range of email clients, as email clients are much less standardized
                            than web browsers.</p>

                            <p>Click anywhere within a section to select it. A floating toolbar under
                            the section enables you to add a new section, as well as move or delete the
                            selected section.</p>
                    </InfoCircle>
                </PageDescription>
                <PageContent>
                {showDeleteDialog ? <ModalWindow
                    title={translator('report_templates.confirm_delete')}
                    bodyWidth={550}
                    buttons={[
                        <Button
                            key="keepReport"
                            type="secondary"
                            onClick={() => setShowDeleteDialog(false)}
                        >
                            {translator('report_templates.keep_report')}
                        </Button>,
                        <Button
                            key="deleteReport"
                            type="primary"
                            onClick={() => {
                                onDeleteReport();
                                setShowDeleteDialog(false);
                            }}
                        >
                           {translator('report_templates.delete_report')}
                        </Button>
                    ]}>
                        <div>
                            {translator('report_templates.delete_report_msg')}
                        </div>
                    </ModalWindow> : null}
                    {(showNewTitleDialog !== NewTitleDialogState.HIDE) && renderNewTitleInput()}
                    <DashboardLayoutEditor
                        sections={pageState.pageStf.sections}
                        onWidgetMove={handleWidgetMove}
                        onAddWidget={handleAddWidget}
                        getScrollParent={getScrollParent}
                        addSection={handleSectionAdd}
                        deleteSection={handleSectionDelete}
                        modifySection={handleSectionModify}
                        moveSection={handleSectionMove}
                        renderWidget={renderWidgetFn}
                        widgetSchema={widgetSchema}
                        sectionTypeOptions={[]}
                        maxWidgetsPerColumn={1}
                        sectionAsSelection={true}
                    />
                </PageContent>
            </Page>
        );
    };

    return (
        <React.Fragment>
        {navigationPrevention}
        <ToolbarContainer>
            <MenuToolbar
                buttonStyle={buttonStyle}
            >
                <MenuDropdown
                    key='report'
                    icon={(<Icon type="fontAwesome" iconName="file-o"/>)}
                    text={'Reports'}
                    buttonStyle={buttonStyle}
                >
                    <MenuItem
                        key='newReport'
                        onClick={()=>{setShowNewTitleDialog(NewTitleDialogState.NEW);}}
                        disabled={false}
                        icon={(<Icon type="fontAwesome" iconName="plus" />)}
                        text={'New Report...'}
                    />
                    <MenuItem
                        key='delete'
                        onClick={() => setShowDeleteDialog(true)}
                        disabled={! canModifyCurrentPage}
                        icon={(<Icon type="fontAwesome" iconName="times" />)}
                        text={'Delete'}
                    />
                    <MenuItem
                        key='duplicate'
                        onClick={()=>{setShowNewTitleDialog(NewTitleDialogState.DUPLICATE);}}
                        disabled={false}
                        icon={(<Icon type="fontAwesome" iconName="upload" />)}
                        text={'Duplicate...'}
                    />
                </MenuDropdown>
                <MenuDropdown
                    key='save'
                    icon={(<Icon type="vorne" iconName="save"/>)}
                    text={'Save'}
                    buttonStyle={buttonStyle}
                >
                    <MenuItem
                        key='savePage'
                        onClick={onSaveReport}
                        disabled={!isPageDirty || !canModifyCurrentPage}
                        icon={(<Icon type="fontAwesome" iconName="upload" />)}
                        text={'Save'}
                    />
                    <MenuItem
                        key='saveAs'
                        onClick={()=>{setShowNewTitleDialog(NewTitleDialogState.SAVE_AS);}}
                        disabled={false}
                        icon={(<Icon type="fontAwesome" iconName="upload" />)}
                        text={'Save As…'}
                    />
                </MenuDropdown>
            </MenuToolbar>
        </ToolbarContainer>
        <PageHeaderContainer>
            <PageLiveControlBarContainer>
                <PageSelectContainer>
                    <PageSelectField
                        value={viewPath}
                        options={allEmailTemplatesView}
                        name={'joe'}
                        hideSearch={false}
                        onChange={onPageChange}
                    >
                        <PageSelectDisplayContainer>
                            <PageName>{viewDisplayName}</PageName>
                        </PageSelectDisplayContainer>
                    </PageSelectField>
                </PageSelectContainer>
            </PageLiveControlBarContainer>
        </PageHeaderContainer>
        {renderPage()}
        </React.Fragment>
    );
}

EmailTemplatesPage.propTypes = {
    actions: PropTypes.shape({
        getEmailTemplates: PropTypes.func.isRequired,
        postEmailTemplate: PropTypes.func.isRequired,
        patchEmailTemplate: PropTypes.func.isRequired,
        deleteEmailTemplate: PropTypes.func.isRequired,
        getMetricThresholds: PropTypes.func.isRequired,
    }).isRequired,
    requests: PropTypes.object.isRequired,
    emailTemplates: PropTypes.arrayOf(
        PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.number),
            data: PropTypes.shape({
                built_in: PropTypes.bool.isRequired,
                content: PropTypes.object.isRequired,
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
            }),
            resources: PropTypes.array
        })
    ).isRequired,
    metricThresholds: PropTypes.any, // [TODO] Complete this proptype
};

const mapStateToProps = state => {
    const {
        emailTemplates,
        metricThresholds,
    } = state;

    return {
        emailTemplates: getEmailTemplatesList(emailTemplates),
        metricThresholds: selectMetricThresholdsByColumnName(metricThresholds),
        requests: {
            getEmailTemplates: emailTemplates.requests.getEmailTemplates,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getEmailTemplates,
            postEmailTemplate,
            patchEmailTemplate,
            deleteEmailTemplate,
            getMetricThresholds,
        }, dispatch)
    };
};

// connect the component to Redux
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailTemplatesPage));
