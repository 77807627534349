import React from 'react';
import PropTypes from 'prop-types';

import PageTitle from './PageTitle';
import FaIcon from 'Components/icon/FaIcon';

export default function ConfigPageTitle(props) {
    const { children, ...otherProps } = props;

    return (
        <PageTitle preface={(<FaIcon i="cog" />)} {...otherProps}>
            {children}
        </PageTitle>
    );
}

ConfigPageTitle.propTypes = {
    children: PropTypes.node,
};
