
import { sendPatchRequest } from 'Http/request';
import {
    patchResource,
} from 'Common/util/ResourceCollection';

export function patchEmailTemplate(emailTemplateId, patch) {
    return patchResource({
        requestId: 'patchEmailTemplate',
        resourceId: emailTemplateId,
        resourceType: 'emailTemplates',
        sendRequest: () => sendPatchRequest(`/api/v1/reports/${emailTemplateId}`, { body: patch } ),
    });
}

export default patchEmailTemplate;
