import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { T1, Caption } from 'Components/typography/typography';
import VorneIcon from 'Components/vorne-icon/VorneIcon';
import LoginFormContainer from 'Components/login-form/LoginFormContainer';
import FaIcon from 'Components/icon/FaIcon';
import MainMenuIconLink from './internal/main-menu/MainMenuIconLink';
import MainMenuMobileIconLink from './internal/main-menu/MainMenuMobileIconLink';
import MainMenu from './internal/main-menu/MainMenu';
import { selectIsLoggedIn, selectIsSupport } from 'Redux/reducers/session';
import ConfigMenu from './internal/config-menu/ConfigMenu';
import SupportMenu from './internal/support-menu/SupportMenu';
import HeaderLogoIcon from './internal/HeaderLogoIcon';
import IconLink from './internal/IconLink';
import MobileNavMenuNode from './internal/navigation-menu/MobileNavMenuNode';
import Dropdown from 'Components/dropdown/Dropdown';
import DropdownContent from 'Components/dropdown/DropdownContent';
import FixedDropdownContent from 'Components/dropdown/FixedDropdownContent';
import { DisplayMode, withDisplayMode } from 'Common/util/DisplayMode';

const HeaderBar = styled.div`
    min-height: 50px;
    background-color: ${props => props.theme.colors.palette.white};
    position: relative;
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    z-index: 150;
`;

const LeftItems = styled.div`
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
`;

const RightItems = styled.div`
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
`;

const VerticalDivider = styled.div`
    width: 1px;
    height: 30px;
    display: inline-block;
    background-color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

const Details = styled.div`
    display: inline-block;
    font-size: 12px;
    line-height: 1.2em;
    padding: 0 16px;

    ${Caption}.primary {
        text-transform: uppercase;
        font-weight: 900;
        padding-bottom: 2px;
    }
`;

const DesktopDropdown = styled(Dropdown)`
    display: inline-block;
`;

const MobileDropdown = styled(Dropdown)`
    display: inline-block;
`;

const MobileDropdownContent = styled(FixedDropdownContent)`
    display: block;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    max-width: 100vw;
    max-height: calc(100vh - 50px);
    z-index: 2000;
`;

const LoginWrapper = styled.div`
    box-sizing: border-box;
    min-width: 300px;
    padding: 10px;
`;

const DropdownTitle = styled(T1)`
    display: block;
    border-bottom: 1px solid #D5D5D5;
    color: ${props => props.theme.colors.palette.blue.awesome};
    margin: 0;
    padding: 10px;
`;

const UnstyledLink = styled(Link)`
    text-decoration: none;
    color: none;
`;

class Header extends React.Component {
    static propTypes = {
        displayMode: PropTypes.string.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        session: PropTypes.object.isRequired,
        history: PropTypes.shape({
            listen: PropTypes.func.isRequired,
        }).isRequired,
        onOpenMenuChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeDropdown: null,
        };

        this._toggleActiveDropdownHandlers = {};
    }

    componentDidMount() {
        // listen for changes to history for location changes
        this.unlistenHistory = this.props.history.listen((location, action) => {
            const { location: { pathname: currentPathname } } = this.props;
            if (currentPathname === location.pathname) {
                // if the location was "changed" to the current location close the menu
                this.closeAllMenus();
            }
        });
    }

    componentWillUnmount() {
        this.unlistenHistory();
    }

    _getDropdownToggleHandler(name) {
        const handlerKey = name === null ? 'none' : name;

        if (!this._toggleActiveDropdownHandlers[handlerKey]) {
            this._toggleActiveDropdownHandlers[handlerKey] = this._toggleActiveDropdown.bind(this, name);
        }

        return this._toggleActiveDropdownHandlers[handlerKey];
    }

    _toggleActiveDropdown(name) {
        this.setState((state) => {
            let activeDropdown = state.activeDropdown;

            if (activeDropdown === name) {
                // already active; 'toggle' to null
                activeDropdown = null;
            }
            else {
                activeDropdown = name;
            }

            return {
                activeDropdown,
            };
        });
    }

    _renderDesktopDropdown(name, trigger, content) {
        const { displayMode } = this.props;
        const { activeDropdown } = this.state;

        if (displayMode === DisplayMode.MOBILE) {
            return null;
        }
        else {
            return (
                <DesktopDropdown
                    open={activeDropdown === name}
                    onBlurDeep={this._getDropdownToggleHandler(null)}
                >
                    {trigger}
                    <DropdownContent>
                        {content}
                    </DropdownContent>
                </DesktopDropdown>
            );
        }
    }

    _renderMobileDropdown(name, trigger, content) {
        const { displayMode } = this.props;
        const { activeDropdown } = this.state;

        if (displayMode === DisplayMode.MOBILE) {
            return (
                <MobileDropdown
                    open={activeDropdown === name}
                    onBlurDeep={this._getDropdownToggleHandler(null)}
                >
                    {trigger}
                    <MobileDropdownContent>
                        {content}
                    </MobileDropdownContent>

                </MobileDropdown>
            );
        }
        else {
            return null;
        }
    }

    _renderOverflowMenu() {
        const isLoggedIn = selectIsLoggedIn(this.props.session);

        return (
            <React.Fragment>
                <MobileNavMenuNode
                    label="Log In"
                    icon={(
                        <VorneIcon i="login" />
                    )}
                >
                    <LoginWrapper>
                        <LoginFormContainer />
                    </LoginWrapper>
                </MobileNavMenuNode>
                {isLoggedIn && (
                    <MobileNavMenuNode
                        label="Settings"
                        icon={(
                            <FaIcon i="cog" />
                        )}
                    >
                        <ConfigMenu mobile />
                    </MobileNavMenuNode>
                )}
            </React.Fragment>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            displayMode: currentDisplayMode,
            location: { pathname: currentPathname },
        } = this.props;
        const {
            displayMode: prevDisplayMode,
            location: { pathname: previousPathname },
        } = prevProps;

        // The browser location changed, close all of the menus.
        if (currentPathname !== previousPathname) {
            this.closeAllMenus();
        }

        // If the displayMode changed AND either the current or previous
        // displayMode is mobile, then we crossed the mobile breakpoint.
        const crossedMobileBreakpoint = (
            currentDisplayMode !== prevDisplayMode &&
            (
                prevDisplayMode === DisplayMode.MOBILE ||
                currentDisplayMode === DisplayMode.MOBILE
            )
        );

        // Close all menus when the window resizes past the mobile breakpoint
        if (crossedMobileBreakpoint) {
            this.closeAllMenus();
        }

        if (prevState.activeDropdown !== this.state.activeDropdown) {
            this.props.onOpenMenuChange(this.state.activeDropdown);
        }
    }

    closeAllMenus() {
        this.setState({
            activeDropdown: null,
        });
    }

    render() {
        const { displayMode } = this.props;
        const { activeDropdown } = this.state;
        const isLoggedIn = selectIsLoggedIn(this.props.session);
        const isSupport = selectIsSupport(this.props.session);

        return (
            <HeaderBar>
                <LeftItems>
                    <UnstyledLink to="/">
                        <HeaderLogoIcon
                            title="Home"
                            displayMode={displayMode}
                        />
                    </UnstyledLink>
                    <VerticalDivider />
                    <Details>
                        <Caption className="primary">XL Enterprise</Caption><br />
                    </Details>
                    {isLoggedIn && (
                        <React.Fragment>
                            <VerticalDivider />
                            {this._renderDesktopDropdown(
                                'main',
                                (
                                    <MainMenuIconLink
                                        title="Main Menu"
                                        className={classNames({ active: activeDropdown === 'main' })}
                                        onClick={this._getDropdownToggleHandler('main')}
                                    />
                                ),
                                <MainMenu />
                            )}
                            {this._renderMobileDropdown(
                                'mainMobile',
                                (
                                    <MainMenuMobileIconLink
                                        title="Main Menu"
                                        className={classNames({ active: activeDropdown === 'mainMobile' })}
                                        onClick={this._getDropdownToggleHandler('mainMobile')}
                                    />
                                ),
                                <MainMenu mobile />
                            )}
                        </React.Fragment>
                    )}
                </LeftItems>

                <RightItems>
                    {this._renderMobileDropdown(
                        'overflow',
                        <IconLink
                            title=""
                            className={classNames({ active: activeDropdown === 'overflow' })}
                            onClick={this._getDropdownToggleHandler('overflow')}
                        >
                            <FaIcon i="ellipsis-v" />
                        </IconLink>,
                        this._renderOverflowMenu(),
                    )}

                    {this._renderDesktopDropdown(
                        'login',
                        (
                            <IconLink
                                title="Log In"
                                className={classNames({ active: activeDropdown === 'login' })}
                                onClick={this._getDropdownToggleHandler('login')}
                                role="button"
                            >
                                <VorneIcon i="login" />
                            </IconLink>
                        ),
                        (
                            <LoginWrapper>
                                <DropdownTitle>Log In</DropdownTitle>
                                <LoginFormContainer />
                            </LoginWrapper>
                        )
                    )}

                    {isLoggedIn && isSupport && (
                        this._renderDesktopDropdown(
                            'support',
                            <IconLink
                                title="Vorne Support Console"
                                className={classNames({ active: activeDropdown === 'support' })}
                                onClick={this._getDropdownToggleHandler('support')}
                            >
                                <FaIcon i="headphones" />
                            </IconLink>,
                            <SupportMenu />
                        )
                    )}

                    {isLoggedIn && (
                        this._renderDesktopDropdown(
                            'config',
                            <IconLink
                                title="Management Console"
                                className={classNames({ active: activeDropdown === 'config' })}
                                onClick={this._getDropdownToggleHandler('config')}
                                role="button"
                            >
                                <FaIcon i="cog" />
                            </IconLink>,
                            <ConfigMenu />
                        )
                    )}
                </RightItems>
            </HeaderBar>
        );
    }
}

const mapStateToProps = state => {
    const { session } = state;
    return { session };
};

export default withDisplayMode(withRouter(connect(mapStateToProps)(Header)));
