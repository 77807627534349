import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Roles } from 'Components/app/roles';
import StaticNavMenu from 'Components/header/internal/navigation-menu/StaticNavMenu';
import MobileNavMenu from 'Components/header/internal/navigation-menu/MobileNavMenu';
import filterNavItems from '../navigation-menu/filterNavItems';

const configMenuContent = [
    {
        menuTitle: 'USER',
        menuItems: [
            {
                type: 'link',
                to: '/config/user/profile',
                text: 'Account',
                minRole: Roles.MEMBER,
            },
        ],
    },
    {
        menuTitle: 'PLANT FLOOR',
        menuItems: [
            {
                type: 'link',
                to: '/config/plant-floor/alert-definitions',
                text: 'Alert Definitions',
                minRole: Roles.ADMIN,
            },
            {
                type: 'link',
                to: '/config/plant-floor/email-templates',
                text: 'Email Report Templates',
                minRole: Roles.ADMIN,
            },
            {
                type: 'link',
                to: '/config/plant-floor/metric-thresholds',
                text: 'Metric Thresholds',
                minRole: Roles.ADMIN,
            },
        ],
    },
    {
        menuTitle: 'DEVICES',
        menuItems: [
            {
                type: 'link',
                to: '/config/devices/all-assets',
                text: 'Enterprise Hierarchy',
                minRole: Roles.MEMBER,
            },
            /*{
                type: 'link',
                to: '/config/devices/software-updates',
                text: 'Software Updates',
                minRole: Roles.MEMBER,
            },*/
        ],
    },
    {
        menuTitle: 'ORGANIZATION',
        menuItems: [
            {
                type: 'link',
                to: '/config/organization/settings',
                text: 'Organization Name',
                minRole: Roles.ADMIN,
            },
            {
                type: 'link',
                to: '/config/organization/users',
                text: 'All Users',
                minRole: Roles.ADMIN,
            },
        ],
    },
];

class ConfigMenu extends React.PureComponent {
    static propTypes = {
        mobile: PropTypes.bool,
        role: PropTypes.string,
    };

    static defaultProps = {
        mobile: false,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { mobile, role } = this.props;

        const subMenuContents = filterNavItems(configMenuContent, role);

        if (mobile) {
            return (
                <MobileNavMenu
                    subMenuContents={subMenuContents}
                    indentLevel={1}
                />
            );
        }
        else {
            return (
                <StaticNavMenu
                    subMenuContents={subMenuContents}
                    indentLevel={1}
                />
            );
        }
    }
}

const mapStateToProps = state => {
    const {
        session: {
            sessionData: {
                role
            }
        }
    } = state;

    return { role };
};

export default connect(mapStateToProps)(ConfigMenu);
