import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DndContext } from '@dnd-kit/core';

const WorkaroundDndMonitorContext = createContext({
    addListener: () => {},
    removeListener: () => {},
});

const emptyFn = () => {};

/**
 * A workaround for a bug in dnd-kit where useDndMonitor onDragStart will
 * sometimes not be called. This wraps around the DndContext with another
 * context to provide an equivalent to useDndMonitor that actually works.
 * 
 * When using this context, the useWorkaroundDndMonitor can be used instead of
 * useDndMonitor.
 * 
 * A GitHub issue has been opened here:
 *      https://github.com/clauderic/dnd-kit/issues/369
 * 
 * Once the issue is resolved, this code can be deleted.
 *
 * @param {*} props
 * @returns {React.ReactElement}
 */
export function WorkaroundDndContext(props) {
    const {
        onDragStart,
        onDragMove,
        onDragOver,
        onDragEnd,
        onDragCancel,
        ...otherProps
    } = props;

    const listeners = useRef({
        onDragStart: [],
        onDragMove: [],
        onDragOver: [],
        onDragEnd: [],
        onDragCancel: [],
    });

    const addListener = useRef((eventName, fn) => {
        listeners.current[eventName].push(fn);
    });

    const removeListener = useRef((eventName, fn) => {
        listeners.current[eventName] = listeners.current[eventName].filter((l) => {
            return l !== fn;
        });
    });

    const callListeners = useRef((eventName, ...args) => {
        listeners.current[eventName].forEach((l) => {
            l(...args);
        });
    });

    const handleDragStart = useCallback((...args) => {
        onDragStart(...args);
        callListeners.current('onDragStart', ...args);
    }, [ onDragStart ]);
    const handleDragMove = useCallback((...args) => {
        onDragMove(...args);
        callListeners.current('onDragMove', ...args);
    }, [ onDragMove ]);
    const handleDragOver = useCallback((...args) => {
        onDragOver(...args);
        callListeners.current('onDragOver', ...args);
    }, [ onDragOver ]);
    const handleDragEnd = useCallback((...args) => {
        onDragEnd(...args);
        callListeners.current('onDragEnd', ...args);
    }, [ onDragEnd ]);
    const handleDragCancel = useCallback((...args) => {
        onDragCancel(...args);
        callListeners.current('onDragCancel', ...args);
    }, [ onDragCancel ]);

    const valueRef = useRef({
        addListener: addListener.current,
        removeListener: removeListener.current,
    });

    return (
        <WorkaroundDndMonitorContext.Provider value={valueRef.current}>
            <DndContext
                {...otherProps}
                onDragStart={handleDragStart}
                onDragMove={handleDragMove}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            />
        </WorkaroundDndMonitorContext.Provider>
    );
}

WorkaroundDndContext.propTypes = {
    onDragStart: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragCancel: PropTypes.func,
};

WorkaroundDndContext.defaultProps = {
    onDragStart: emptyFn,
    onDragMove: emptyFn,
    onDragOver: emptyFn,
    onDragEnd: emptyFn,
    onDragCancel: emptyFn,
};

export function useWorkaroundDndMonitor({
    onDragStart = emptyFn,
    onDragMove = emptyFn,
    onDragOver = emptyFn,
    onDragEnd = emptyFn,
    onDragCancel = emptyFn,
}) {
    const monitorContext = useContext(WorkaroundDndMonitorContext);

    useEffect(() => {
        monitorContext.addListener('onDragStart', onDragStart);
        return () => {
            monitorContext.removeListener('onDragStart', onDragStart);
        };
    }, [ onDragStart, monitorContext ]);
    useEffect(() => {
        monitorContext.addListener('onDragMove', onDragMove);
        return () => {
            monitorContext.removeListener('onDragMove', onDragMove);
        };
    }, [ onDragMove, monitorContext ]);
    useEffect(() => {
        monitorContext.addListener('onDragOver', onDragOver);
        return () => {
            monitorContext.removeListener('onDragOver', onDragOver);
        };
    }, [ onDragOver, monitorContext ]);
    useEffect(() => {
        monitorContext.addListener('onDragEnd', onDragEnd);
        return () => {
            monitorContext.removeListener('onDragEnd', onDragEnd);
        };
    }, [ onDragEnd, monitorContext ]);
    useEffect(() => {
        monitorContext.addListener('onDragCancel', onDragCancel);
        return () => {
            monitorContext.removeListener('onDragCancel', onDragCancel);
        };
    }, [ onDragCancel, monitorContext ]);
}
