
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import MetricThresholdsTable from './MetricThresholdsTable';
import Form from 'Components/form/Form';
import * as RequestStatus from 'Common/data/RequestStatus';

function useErrorList(thresholds) {
    return useMemo(() => {
        let hasError = false;
        const errorList = thresholds.map((thresh) => {
            const rangeMap = thresh.ranges.reduce((acc, field) => {
                acc[field.name] = field;
                return acc;
            }, {});

            let error = [];
            if (thresh.directionality === 'higher_better') {
                if (rangeMap.critical.max >= rangeMap.warning.max) {
                    hasError = true;
                    error.push('warning');
                }
                if (rangeMap.warning.max >= rangeMap.caution.max) {
                    hasError = true;
                    error.push('caution');
                }
                if (rangeMap.caution.max >= rangeMap.good.max) {
                    hasError = true;
                    error.push('good');
                }
            }
            else {
                if (rangeMap.critical.min <= rangeMap.warning.min) {
                    hasError = true;
                    error.push('warning');
                }
                else if (rangeMap.warning.min <= rangeMap.caution.min) {
                    hasError = true;
                    error.push('caution');
                }
                else if (rangeMap.caution.min <= rangeMap.good.min) {
                    hasError = true;
                    error.push('good');
                }
            }
            return error;
        });
        return {
            hasError,
            errorList,
        };
    }, [thresholds]);
}

export default function MetricThresholdsForm(props) {
    const {
        metricThresholds,
        onSubmit,
        requests,
    } = props;

    const [ modifiedThresholds, setModifiedThresholds ] = useState(metricThresholds);
    const [ isDirty, setIsDirty ] = useState(false);

    const [ lastPatchStatus, setLastPatchStatus ] = useState(requests?.putMetricThresholds?.status);

    useEffect(() => {
        const nextPatchStatus = requests?.putMetricThresholds?.status;

        if (lastPatchStatus === RequestStatus.PENDING &&
            nextPatchStatus === RequestStatus.SUCCESS
        ) {
            setIsDirty(false);
        }

        if (lastPatchStatus !== nextPatchStatus) {
            setLastPatchStatus(nextPatchStatus);
        }
    }, [lastPatchStatus, requests?.putMetricThresholds?.status]);

    const {
        errorList,
        hasError,
    } = useErrorList(modifiedThresholds);

    // name of the metric being updated
    // rangeName critical, warning, caution, good
    // rangeSide (updating the min or the max)
    // value the string percentage to update
    const onChange = (name, rangeName, adjacentRange, rangeSide, adjacentRangeSide, value) => {
        const newThresholds = modifiedThresholds.map((thresh) => {
            if (name === thresh.name) {
                const newRange = thresh.ranges.map((range) => {
                    if (range.name === rangeName) {
                        return {
                            ...range,
                            [rangeSide]: value,
                        };
                    }
                    if (range.name === adjacentRange) {
                        return {
                            ...range,
                            [adjacentRangeSide]: value,
                        };
                    }
                    return range;
                });
                return {
                    ...thresh,
                    ranges: newRange,
                };
            }
            return thresh;
        });
        setIsDirty(true);
        setModifiedThresholds(newThresholds);
    };

    const isLoading = requests.putMetricThresholds.status === RequestStatus.PENDING;

    const allowSubmit = !isLoading && isDirty && !hasError;
    const allowCancel = !isLoading && isDirty;

    const handleReset = () => {
        setModifiedThresholds(metricThresholds);
        setIsDirty(false);
    };

    const handleSubmit = (a) => {
        onSubmit(modifiedThresholds);
    };

    return (
        <React.Fragment>
            <Form
                error={requests?.putMetricThresholds?.error}
                onSubmit={handleSubmit}
                onReset={handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <MetricThresholdsTable
                    metricThresholds={modifiedThresholds}
                    errorList={errorList}
                    onChange={onChange}
                />
            </Form>
        </React.Fragment>
    );
}

MetricThresholdsForm.propTypes = {
    metricThresholds: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    requests: PropTypes.object.isRequired,
};
