import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const DisplayMode = {
    LARGE_DESKTOP: 'LARGE_DESKTOP',
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE',
};

export const DisplayModeBreakpoints = {
    LARGE_DESKTOP: 1200,
    DESKTOP: 992,
    TABLET: 768,
    MOBILE: 576,
};

const DisplayModeContext = createContext(DisplayMode.DESKTOP);

function getDisplayModeFromScreenWidth(width) {
    if (width <= DisplayModeBreakpoints.MOBILE) {
        return DisplayMode.MOBILE;
    }
    else if (width <= DisplayModeBreakpoints.TABLET) {
        return DisplayMode.TABLET;
    }
    else if (width <= DisplayModeBreakpoints.DESKTOP) {
        return DisplayMode.DESKTOP;
    }
    else {
        return DisplayMode.LARGE_DESKTOP;
    }
}

function useScreenSize() {
    const [ size, setSize ] = useState(() => ({
        width: window.innerWidth,
        height: window.innerHeight,
    }));

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return function cleanup() {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return size;
}

function InternalDisplayModeProvider({ width, children }) {
    const displayMode = useMemo(() => {
        return getDisplayModeFromScreenWidth(width);
    }, [ width ]);

    return (
        <DisplayModeContext.Provider value={displayMode}>
            {children}
        </DisplayModeContext.Provider>
    );
}

InternalDisplayModeProvider.propTypes = {
    width: PropTypes.number.isRequired,
    children: PropTypes.any,
};

// eslint-disable-next-line react/no-multi-comp
export function DisplayModeProvider({ children }) {
    const { width } = useScreenSize();

    return (
        <InternalDisplayModeProvider width={width}>
            {children}
        </InternalDisplayModeProvider>
    );
}

DisplayModeProvider.propTypes = {
    children: PropTypes.any,
};

/**
 * A mocked alternative to `DisplayModeProvider` for testing.
 *
 * Instead of getting the actual screen width, it is passed as a prop to make
 * testing responsive components easy.
 */
// eslint-disable-next-line react/no-multi-comp
export function MockDisplayModeProvider({ width, children }) {
    return (
        <InternalDisplayModeProvider width={width}>
            {children}
        </InternalDisplayModeProvider>
    );
}

MockDisplayModeProvider.propTypes = {
    width: PropTypes.number.isRequired,
    children: PropTypes.any,
};

/**
 * A react hook to get the current display mode.
 *
 * @returns {String} A DisplayMode value @see {DisplayMode}
 */
export function useDisplayMode() {
    return useContext(DisplayModeContext);
}

/**
 * Note: Only use this if necessary. Use the `useDisplayMode` hook instead.
 *
 * A react higher-order component that can be used to get the current display
 * mode as a prop named `displayMode`.
 *
 * `props.displayMode` is a String. @see {DisplayMode} for possible values.
 *
 * Usage:
 * ```
 * const MyReactComponentClassWithDisplayMode = withDisplayMode(MyReactComponentClass);
 * ```
 *
 * @returns {Function} A wrapped React component.
 */
export function withDisplayMode(WrappedComponent) {
    // eslint-disable-next-line react/no-multi-comp
    function WithDisplayMode(props) {
        // Quick check to warn if there's a displayMode prop being overridden.
        // eslint-disable-next-line react/prop-types
        if (props.displayMode !== undefined) {
            console.warn('Component wrapped by `withDisplayMode` has a `displayMode` prop that will be overridden');
        }

        const displayMode = useContext(DisplayModeContext);

        return (
            <WrappedComponent
                {...props}
                displayMode={displayMode}
            />
        );
    }

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithDisplayMode.displayName = `WithDisplayMode${displayName}`;

    return WithDisplayMode;
}

/**
 * Note: Only use this if necessary. Use the `useDisplayMode` hook instead.
 *
 * A react context consumer that can be used to get the current display
 * mode.
 *
 * As with any regular context consumer, this component should have a function
 * as its only child. The child function will be called with `displayMode`
 * as the only argument.
 *
 * `displayMode` is a String. @see {DisplayMode} for possible values.
 *
 * @returns {React.Consumer} React Consumer
 */
export const DisplayModeConsumer = DisplayModeContext.Consumer;
