import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TocItem = styled.div`
    display: inline-block;
    margin-right: 0.5em;
`;

export default function AnchorTocItem(props) {
    const { id, name } = props;

    return (
        <TocItem>
            [ <a href={`#${id}`}>{name}</a> ]
        </TocItem>
    );
}

AnchorTocItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
