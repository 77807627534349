/**
 * Wraps a test function with the structure needed to be a paramType checker.
 *
 * @param {Function} test Tester function which returns an array of failures.
 *
 * @returns {Object} The paramType tester object. Only "public" member is
 *     "isRequired", which is used to mark the paramType as a required
 *     field (value cannot be null or undefined).
 */
export default function paramTypeWrapper(test) {
    return {
        isRequired: {
            _required: true,
            _test: test,
        },
        _required: false,
        _test: test,
    };
}
