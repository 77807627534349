import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormElement from 'Components/form-element/FormElement';
import TextInputField from './TextInputField';
import TextInputLabel from './TextInputLabel';
import FormElementHelperText from 'Components/form-element/FormElementHelperText';
import uniqueId from 'Common/util/uniqueId';

const Root = styled.div`
    display: inline-block;
`;

const TextInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const RightButtonWrapper = styled.div`
    flex: 0 0 auto;
    margin-left: 10px;
`;

export default class TextInput extends React.Component {

    constructor() {
        super();
        this._uniqueId = uniqueId();
    }

    render() {
        const {
            label,
            helperText,
            type,
            placeholder,
            value,
            name,
            autoComplete,
            autoFocus,
            rightButton,
            onFocus,
            onBlur,
            onChange,
            readOnly,
            ...otherProps
        } = this.props;

        const id = 'text-input-' + this._uniqueId;

        let helperTextElem = null;
        if (typeof helperText !== 'undefined' && helperText !== null) {
            helperTextElem = (
                <FormElementHelperText>
                    {helperText}
                </FormElementHelperText>
            );
        }

        return (
            <FormElement Component={Root} {...otherProps}>
                <TextInputLabel htmlFor={id}>
                    {label}
                </TextInputLabel>
                <TextInputWrapper>
                    <TextInputField
                        id={id}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        name={name}
                        readOnly={readOnly}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                    {rightButton && (
                        <RightButtonWrapper>
                            {rightButton}
                        </RightButtonWrapper>
                    )}
                </TextInputWrapper>
                {helperTextElem}
            </FormElement>
        );
    }
}

TextInput.defaultProps = {
    label: '',

    type: 'text',
    placeholder: '',
    readOnly: false,

    onFocus: () => {},
    onBlur: () => {},
};

TextInput.propTypes = {
    label: PropTypes.string,
    helperText: PropTypes.string,
    rightButton: PropTypes.node,

    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};
