import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SearchableSelectList from 'Common/components/SelectList/SearchableSelectList';
import styled from 'styled-components';
import Checkbox from 'Common/components/Checkbox';
import { useOptionElements, getFullyQualifiedName, findOptionByFqn, getOptionsPropType } from './FieldSelectListUtil';
import useTranslation from 'Common/hooks/useTranslation';

function useFlattenedGroups({ optionGroups, disabled, sortFn }) {
    return useMemo(() => {
        // Disables the flattening of the groups
        if (disabled) {
            return optionGroups;
        }

        let entries = optionGroups.reduce((curr, group) => {
            return curr.concat(group.entries);
        }, []);

        // Flattened groups don't have separators
        entries = entries.filter((entry) => {
            return !entry.isSeparator;
        });

        entries.sort(sortFn);

        return [
            {
                isDummyGroup: true,
                name: '',
                displayName: '',
                entries: entries,
            },
        ];
    }, [ optionGroups, disabled, sortFn ]);
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const FlattenCheckboxContainer = styled.div`
    padding: 0 14px 0 14px;
`;

const HorizontalSeparator = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 6px;
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver};
`;

function FieldSelectControl(props) {
    const {
        name,
        options: optionsProp,
        collapsibleGroups,
        showFlattenCheckbox,
        entriesSortFn,
        onChange,
        value: selected
    } = props;

    const [ flattenGroups, setFlattenGroups ] = useState(false);

    const options = useFlattenedGroups({
        disabled: !showFlattenCheckbox || !flattenGroups,
        optionGroups: optionsProp,
        sortFn: entriesSortFn,
    });

    const handleChange = (event) => {
        const { value: nextSelectedOptionFqn } = event.target;

        // Use the value to find the actual option selected
        const nextSelected = Array.isArray(nextSelectedOptionFqn)
            ? nextSelectedOptionFqn.map((x) => (findOptionByFqn(options, x)))
            : findOptionByFqn(options, nextSelectedOptionFqn);

        onChange({
            target: {
                name: name,
                value: nextSelected,
            },
        });
    };

    const selectedOptionFqn = Array.isArray(selected)
        ? selected.map(getFullyQualifiedName)
        : getFullyQualifiedName(selected);

    const optionElements = useOptionElements({
        optionGroups: options,
        collapsibleGroups: collapsibleGroups,
    });


    const typeToSearchLiteral = useTranslation('type_to_search_literal');
    const filterboxNoSelectionsCopyLiteral = useTranslation('filterbox_no_selections_copy');
    const alphabeticalListLiteral = useTranslation('display_as_alphabetical_list_literal');

    return (
        <Root>
            <SearchableSelectList
                onChange={handleChange}
                searchBoxPlaceholder={typeToSearchLiteral}
                noSelectionText={filterboxNoSelectionsCopyLiteral}
                value={selectedOptionFqn}
            >
                {optionElements}
            </SearchableSelectList>
            {showFlattenCheckbox && (
                <FlattenCheckboxContainer>
                    <HorizontalSeparator />
                    <Checkbox
                        label={alphabeticalListLiteral}
                        value={flattenGroups}
                        onChange={(event) => {
                            setFlattenGroups(event.target.value);
                        }}
                    />
                </FlattenCheckboxContainer>
            )}
        </Root>
    );
}
const valueProp = PropTypes.shape({
    name: PropTypes.string.isRequired,
});

FieldSelectControl.propTypes = {
    name: PropTypes.string,
    options: getOptionsPropType().isRequired,
    value: PropTypes.oneOfType([
        valueProp,
        PropTypes.arrayOf(valueProp)
    ]),
    collapsibleGroups: PropTypes.bool,
    showFlattenCheckbox: PropTypes.bool,
    entriesSortFn: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};

FieldSelectControl.defaultProps = {
    collapsibleGroups: false,
    showFlattenCheckbox: false,
    entriesSortFn: (entryA, entryB) => {
        return entryA.displayName.localeCompare(entryB.displayName);
    },
};

export default React.memo(FieldSelectControl);
