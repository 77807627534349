import React from 'react';
import { getDisplayName } from 'Components/util/reactUtils';

const defaultContext = {
    isDefault: true,
    open: false,
    getAnchorNode: () => null,
};

const DropdownContext = React.createContext(defaultContext);

export const DropdownContextProvider = DropdownContext.Provider;

export function withDropdownContext(WrappedComponent) {
    const WithDropdownContext = React.forwardRef((props, ref) => (
        <DropdownContext.Consumer>
            {(context) => {
                return (
                    <WrappedComponent
                        ref={ref}
                        {...props}
                        dropdownContext={context}
                    />
                );
            }}
        </DropdownContext.Consumer>
    ));

    // Wrap the display name for easy debugging
    WithDropdownContext.displayName = `WithDropdownContext(${getDisplayName(WrappedComponent)})`;

    return WithDropdownContext;
}
