import { getEnvironment } from './getEnvironment';
import { getSessionUser } from './getSessionUser';

export const INIT_APP_COMPLETE = 'INIT_APP_COMPLETE';

export function initApp() {
    return async (dispatch) => {
        await Promise.all([
            getEnvironment()(dispatch),
            getSessionUser()(dispatch)
        ]);

        dispatch({
            type: INIT_APP_COMPLETE,
        });
    };
}

export default initApp;
