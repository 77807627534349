import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDraggable } from '@dnd-kit/core';
import { useWorkaroundDndMonitor } from './WorkaroundDndContext';

function getScrollPosition(scrollParent) {
    if (scrollParent === window) {
        return {
            x: window.scrollX,
            y: window.scrollY,
        };
    }
    else {
        if (typeof scrollParent.scrollTop !== 'number' || typeof scrollParent.scrollLeft !== 'number') {
            throw new Error('Invalid scroll parent');
        }
        else {
            return {
                x: scrollParent.scrollLeft,
                y: scrollParent.scrollTop,
            };
        }
    }
}

const Root = styled.div`
    /*
        Add some vertical space between the widgets by adding a margin-top to
        each widget, excluding the first/top one.
    */
    margin-top: ${({ $isFirstWidget }) => $isFirstWidget ? '0' : '10px'};
`;

export default function DraggableWrapper(props) {
    const {
        isLayoutModeActive,
        sectionIndex,
        columnIndex,
        widgetIndex,
        widgetId,
        renderWidget,
        disabled,
        getScrollParent,
    } = props;

    const [isDragInProgress, setIsDragInProgress] = useState(false);

    const [initialWindowScroll, setInitialWindowScroll] = useState({
        x: 0,
        y: 0,
    });

    const [translate, setTranslate] = useState({ x: 0, y: 0 });

    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: widgetId,
        data: {
            widgetIndex,
            columnIndex,
            sectionIndex,
            widgetId,
        },
        disabled: disabled,
    });

    useWorkaroundDndMonitor({
        onDragStart: useCallback(
            (event) => {
                setIsDragInProgress(true);

                if (event.active.id !== widgetId) {
                    return;
                }

                const scrollPosition = getScrollPosition(getScrollParent());

                setInitialWindowScroll({
                    x: scrollPosition.x,
                    y: scrollPosition.y,
                });
                setTranslate({ x: 0, y: 0 });
            },
            [ widgetId, getScrollParent ],
        ),
        onDragMove: useCallback(
            (event) => {
                if (event.active.id !== widgetId) {
                    return;
                }

                setTranslate({
                    x: event.delta.x - initialWindowScroll.x,
                    y: event.delta.y - initialWindowScroll.y,
                });
            },
            [ widgetId, initialWindowScroll ],
        ),
        onDragEnd: () => {
            setIsDragInProgress(false);
            setInitialWindowScroll({ x: 0, y: 0 });
            setTranslate({ x: 0, y: 0 });
        },
        onDragCancel: () => {
            setIsDragInProgress(false);
            setInitialWindowScroll({ x: 0, y: 0 });
            setTranslate({ x: 0, y: 0 });
        },
    });

    const style = {
        transition: 'opacity 300ms',
        opacity: '1',
        // Add some vertical space between the widgets by adding a margin-top to
        // each widget, excluding the first/top one.
        marginTop: widgetIndex === 0 ? '0' : '10px',
        zIndex: '0',
    };

    // Only set the position and zIndex when there is a drag in progress.
    // Otherwise, you risk messing with the visibility/overlapping of widgets
    // during normal operation.
    if (isDragInProgress) {
        style.position = 'relative';
        style.zIndex = '0';
    }

    if (isDragging) {
        style.transform = `translate3d(${translate.x}px, ${translate.y}px, 0)`;
        style.opacity = '0.4';
        style.zIndex = '500000';
    }

    const memoizedWidget = useMemo(() => {
        return renderWidget(
            widgetId,
            {
                dragHandleProps: {
                    ...listeners,
                    ...attributes,
                    style: {
                        cursor: disabled
                            ? 'default'
                            : 'move',
                        // Make drag handles touch friendly
                        touchAction: 'none',
                    },
                },
                isLayoutModeActive,
                isDragging: isDragging,
                isDragEnabled: !disabled,
                sectionIndex: sectionIndex,
                columnIndex: columnIndex,
                widgetIndex: widgetIndex,
            },
        );
    }, [
        isLayoutModeActive,
        renderWidget,
        widgetId,
        listeners,
        attributes,
        isDragging,
        disabled,
        sectionIndex,
        columnIndex,
        widgetIndex,
    ]);

    return (
        <Root
            ref={setNodeRef}
            style={style}
        >
            {memoizedWidget}
        </Root>
    );
}

DraggableWrapper.propTypes = {
    isLayoutModeActive: PropTypes.bool.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    widgetIndex: PropTypes.number.isRequired,
    widgetId: PropTypes.string.isRequired,
    renderWidget: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    getScrollParent: PropTypes.func.isRequired,
};

DraggableWrapper.defaultProps = {
    disabled: false,
};
