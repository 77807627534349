/**
 * Wraps around a reducer and filters out actions based on the supplied filter
 * function.
 * 
 * @param {Function} filterFn Function that accepts an action and returns a boolean
 * @param {Function} reducer Redux reducer
 * @returns {Function} Filtered redux reducer
 */
export default function actionFilter(filterFn, reducer) {
    return function filtered(state, action) {
        // Init the state if needed
        state = state || reducer(state, {});

        if (filterFn(action)) {
            return reducer(state, action);
        }
        else {
            return state;
        }
    };
}
