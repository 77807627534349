import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body7 } from 'Common/components/typography';
import LiveControlIcon from '../LiveControlIcon';

const ButtonContentWrapper = styled.div`
    ${LiveControlIcon} {
        font-size: 22px;
        width: 44px;
        height: 44px;
    }
`;
const ButtonBadge = styled(Body7)``;

const getColor = (theme, name) => {
    const {
        darkText,
        palette: { white, grey, blue },
    } = theme.colors;

    return {
        controlIcon: darkText.mediumEmphasis,
        controlIconBadge: grey.manatee,
        controlIconBackground: white,
        disabledControlIcon: darkText.disabled,
        hoverControlIconBackground: grey.cloud,
        selectedControlIcon: blue.awesome,
        selectedControlIconBackground: blue.ice,
        selectedHoverControlIcon: blue.deep,
        selectedHoverControlIconBackground: blue.pool,
    }[name];
};

const RootButton = styled.button`
    position: relative;
    display: inline-flex;
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    text-align: center;
    flex: 1 0 auto;
    align-content: center;

    > ${ButtonContentWrapper} {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;

        transition: color 0.25s, background-color 0.25s, border-color 0.25s, outline-color 0.25s;

        color: ${({theme, selected}) => (
            selected
                ? getColor(theme, 'selectedControlIcon')
                : getColor(theme, 'controlIcon')
        )};
        background-color: ${({theme, selected}) => (
            selected
                ? getColor(theme, 'selectedControlIconBackground')
                : getColor(theme, 'controlIconBackground')
        )};

        border-radius: 4px;
    }

    > ${ButtonBadge} {
        position: absolute;
        top: 8px;
        right: 4px;
        display: inline-block;
        box-sizing: border-box;
        line-height: 1.25em;
        height: 1.25em;
        min-width: 1.25em;
        padding: 0 0.25em;
        border-radius: 1em;

        transition: color 0.25s, background-color 0.25s;

        color: ${({theme, selected}) => ( theme.colors.palette.white )};
        border-color: ${({theme, selected}) => (
            selected
                ? getColor(theme, 'selectedControlIconBackground')
                : getColor(theme, 'controlIconBackground')
        )};
        background-color: ${({theme, selected}) => (
            selected
                ? getColor(theme, 'selectedControlIcon')
                : getColor(theme, 'controlIconBadge')
        )};

        /* Give a little border around the badge so that you can
        see the edges against the button color */
        box-shadow: ${({theme, selected}) => {
            const boxShadowColor = selected
                ? getColor(theme, 'selectedControlIconBackground')
                : getColor(theme, 'controlIconBackground');
            return `0 0 0 1.5px ${boxShadowColor};`;}
        };
    }

    &:hover:not(:disabled) {
        cursor: pointer;

        > ${ButtonContentWrapper} {
            color: ${({theme, selected}) => (
                selected
                    ? getColor(theme, 'selectedHoverControlIcon')
                    : getColor(theme, 'hoverControlIcon')
            )};
            background-color: ${({theme, selected}) => (
                selected
                    ? getColor(theme, 'selectedHoverControlIconBackground')
                    : getColor(theme, 'hoverControlIconBackground')
            )};
        }

        > ${ButtonBadge} {
            background-color: ${({theme, selected}) => (
                selected
                    ? getColor(theme, 'selectedHoverControlIcon')
                    : getColor(theme, 'hoverControlIcon')
            )};
            box-shadow: ${({theme, selected}) => {
                const boxShadowColor = selected
                ? getColor(theme, 'selectedHoverControlIconBackground')
                : getColor(theme, 'hoverControlIconBackground');
                return `0 0 0 1.5px ${boxShadowColor};`;}
            };
        }
    }

    &:disabled {
        & > ${ButtonContentWrapper} {
            color: ${({theme}) => getColor(theme, 'disabledControlIcon')};
            background-color: ${({theme}) => getColor(theme, 'controlIconBackground')};
        }

        > ${ButtonBadge} {
            background-color: ${({theme}) => getColor(theme, 'disabledControlIcon')};
        }
    }
`;

export default function LiveControlOverflowMenuItem({
    children,
    onClick,
    disabled,
    selected,
    badge,
    buttonRef,
    ...otherProps
}) {
    const showBadge = Number.isFinite(badge) && badge > 0;

    return (
        <RootButton
            onClick={onClick}
            disabled={disabled}
            selected={selected}
            ref={buttonRef}
            {...otherProps}
        >
            <ButtonContentWrapper>
                {children}
            </ButtonContentWrapper>
            {showBadge && (
                <ButtonBadge selected={selected}>{badge}</ButtonBadge>
            )}
        </RootButton>
    );
}

LiveControlOverflowMenuItem.propTypes = {
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    badge: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    buttonRef: PropTypes.object,
    children: PropTypes.node,
};

LiveControlOverflowMenuItem.defaultProps = {
    disabled: false,
    selected: false,
    badge: null,
};
