import { isAtLeastMinimumRole } from 'Components/app/roles';

export default function filterNavItems(subMenus, currentRole) {
    return subMenus
        .map((subMenu) => {
            return {
                ...subMenu,
                menuItems: subMenu.menuItems.filter((item) => {
                    if (item.minRole) {
                        return isAtLeastMinimumRole(item.minRole, currentRole);
                    }
                    else {
                        return true;
                    }
                }),
            };
        })
        .filter((subMenu) => {
            return subMenu.menuItems.length > 0;
        });
}
