import { useEffect, useState } from 'react';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';

const paramTypes = ParamTypes.shape({
    dataService: ParamTypes.shape({
        getHierarchyOptions: ParamTypes.func.isRequired,
        getSliceFilterOptions: ParamTypes.func.isRequired,
        getKPIGroupWidgetFieldOptions: ParamTypes.func.isRequired,
    }).isRequired,
});

export default function useKPIGroupWidgetInitialization(parameters) {
    paramTest(parameters, paramTypes, 'useKPIGroupWidgetInitialization');

    const { dataService } = parameters;

    const [ isInitialized, setIsInitialized ] = useState(false);
    const [ liveControlOptions, setLiveControlOptions ] = useState({
        hierarchyNodeOptions: [],
        filterOptions: [],
        columnOptions: {},
    });

    useEffect(() => {
        let aborted = false;

        async function run() {
            const [
                hierarchyNodeOptions,
                filterOptions,
                columnOptions,
            ] = await Promise.all([
                dataService.getHierarchyOptions(),
                dataService.getSliceFilterOptions(),
                dataService.getKPIGroupWidgetFieldOptions(),
            ]);

            if (!aborted) {
                setLiveControlOptions({
                    hierarchyNodeOptions,
                    filterOptions,
                    columnOptions,
                });

                setIsInitialized(true);
            }
        }

        run();

        return () => {
            aborted = true;
        };
    }, [ dataService ]);

    return {
        isInitialized,
        liveControlOptions,
    };
}
