import { addMinutes } from 'Common/util/language/date';
import moment from 'moment';

function configFromString(iso8601) {
    var date = new Date(Date.parse(iso8601)),
        offsetMinutes,
        m = /\d+-\d+-\d+T\d+:\d+:\d+(?:\.\d+)?(?:([+-]\d{2}):?(\d{2})?|Z)/.exec(iso8601);

    if (!m) {
        throw 'Could not parse date string ' + iso8601;
    }

    offsetMinutes = 0;
    if (m[1]) {
        offsetMinutes = parseInt(m[1], 10) * 60;
    }
    if (m[2]) {
        // if initial offset is less than zero than we must subtract here
        if (offsetMinutes < 0) {
            offsetMinutes -= parseInt(m[2], 10);
        }
        else {
            offsetMinutes += parseInt(m[2], 10);
        }
    }

    return {
        date: date,
        offsetMinutes: offsetMinutes
    };
}

function configFromDate(dt, offsetMinutes) {
    return { date: dt, offsetMinutes: offsetMinutes || 0 };
}

function configFromOffsetDate(src) {
    return configFromDate(new Date(src.getDate()), src.getOffsetMinutes());
}

/**
 * maintains a javascript Date object plus the offset that was used to create that date. Note the
 * javascript date object will always be in terms of the browser's time zone. The offset stored is
 * used to convert back to the device time zone.
 */
export class OffsetDate {
    static fromDate = function(dt, offsetMinutes) {
        return new OffsetDate(dt, offsetMinutes);
    };
    static fromOffsetDate = function(src) {
        return new OffsetDate(src);
    };
    static fromString = function(str) {
        return new OffsetDate(str);
    };

    constructor(config, offsetMinutes) {
        if (typeof config === 'string') {
            config = configFromString(config);
        }
        else if (config instanceof Date) {
            config = configFromDate(config, offsetMinutes);
        }
        else if (config instanceof OffsetDate) {
            config = configFromOffsetDate(config);
        }

        this._date = config.date;
        this._offsetMinutes = config.offsetMinutes;
    }

    /**
     * get the date, this date will be in the browser's time zone
     */
    getDate() {
        return this._date;
    }

    /**
     * get the number of minutes date was offset with when created
     */
    getOffsetMinutes() {
        return this._offsetMinutes;
    }

    /**
     * creates a java script date object adjusting the time to match the set offset minutes
     *
     * @returns {Date}
     */
    getOffsetDate() {
        return this.convertDateToOffset(this._date);
    }

    /**
     * creates a javascript date from a javascript date offset to this object's offset
     */
    convertDateToOffset(dt) {
        return addMinutes(dt, this.getOffsetMinutes() + dt.getTimezoneOffset());
    }

    /**
     * returns a string of form [+-]HHMM representing the hours and minutes offset
     */
    getOffsetString(forceMinutes) {
        var rv = '';
        if (this._offsetMinutes !== (this._date.getTimezoneOffset() * -1)) {
            var hours = Math.floor(Math.abs(this._offsetMinutes) / 60);
            if (this._offsetMinutes >= 0) {
                rv += '+';
            }
            else {
                rv += '-';
            }

            if (Math.abs(hours) < 10) {
                rv += '0';
            }

            rv += hours;

            var minutes = Math.abs(this._offsetMinutes) % 60;
            if (minutes > 0 || forceMinutes) {
                if (minutes < 10) {
                    rv += '0';
                }
                rv += minutes;
            }
        }
        return rv;
    }
    /**
     * Convert the OffsetDate into a string in the format returned by the API,
     * which contains fractional seconds and a timezone offset.
     *
     *      Exampe: 2022-09-16T09:07:45.757825-05:00
     *
     * @returns {String}
     */
    toRawString() {
        // Format the date string (in the timezone of the offset)
        const dateStr = moment(this.getOffsetDate()).format('YYYY-MM-DD[T]HH:mm:ss.SSSSSS');

        const offsetSign = this.getOffsetMinutes() > 0 ? '+' : '-';

        const offsetMinutesAbs = Math.abs(this.getOffsetMinutes());

        let offsetHoursStr = String(Math.floor(offsetMinutesAbs / 60));
        // Pad the hours with an extra 0, if needed.
        if (offsetHoursStr.length === 1) {
            offsetHoursStr = '0' + offsetHoursStr;
        }

        let offsetMinutesStr = String(offsetMinutesAbs % 60);
        // Pad the minutes with an extra 0, if needed.
        if (offsetMinutesStr.length === 1) {
            offsetMinutesStr = '0' + offsetMinutesStr;
        }

        return `${dateStr}${offsetSign}${offsetHoursStr}:${offsetMinutesStr}`;
    }
}
