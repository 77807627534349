import {
    object,
    array,
    string,
    number,
    boolean,
    func,
    any,
    paramType,
} from './internal/basicTypes';
import shape from './internal/shape';
import oneOf from './internal/oneOf';
import exactly from './internal/exactly';
import oneOfType from './internal/oneOfType';
import arrayOf from './internal/arrayOf';
import objectOf from './internal/objectOf';
import internalTestType from './internal/testType';

/**
 * Set of ParamType checkers for use with paramTest().
 *
 * @see paramTest
 */
const ParamTypes = {
    object,
    array,
    string,
    number,
    boolean,
    bool: boolean,
    func,
    any,

    paramType,

    shape,
    oneOf,
    exactly,
    exact: exactly,
    oneOfType,
    arrayOf,
    objectOf,
};

export default ParamTypes;

/**
 * 
 * @param {Any} value The value to test
 * @param {ParamType} paramTypeArg A param type tester from the ParamTypes object.
 * @param {String} [name='argument'] An optional name to help make the error easier to read / find.
 * @param {Object} opts
 * @param {boolean} [opts.throwError=!RELEASE] Throw an error instead of just logging a failure. This
 *     is turned on by default when using a debug build, but off by default for release builds.
 * @param {boolean} [opts.noLog=false] Do not log a failed paramTest, should only be used in test
 *     environments.
 *
 * @returns {boolean} True if no type failures, false if type failures and opts.throwError is falsy.
 *
 * @see ParamTypes
 */
export function paramTest(
    value,
    paramTypeArg,
    name = 'argument',
    { throwError, noLog } = { throwError: !RELEASE, noLog: false }
) {
    let failures = internalTestType(ParamTypes.paramType.isRequired, paramTypeArg, 'paramTypeArg');
    failures = failures.concat(internalTestType(ParamTypes.string.isRequired, name, 'name'));

    if (failures.length > 0) {
        throw new Error(failures[0]);
    }

    failures = internalTestType(paramTypeArg, value, name);

    failures = failures.map((failure) => {
        return `ParamType Error: ${failure}`;
    });
    
    if (throwError && failures.length > 0) {
        throw new Error(failures[0]);
    }
    else if (!noLog) {
        failures.forEach((failure) => {
            console.error(failure);
        });
    }

    return failures.length === 0;
}

/**
 * Convert a ParamTypes validator into a React PropTypes validator.
 * 
 * Useful for when you already have a ParamTypes definition for an object and
 * don't want to duplicate it in PropTypes.
 * 
 * @param {ParamType} paramTypeArg A param type tester from the ParamTypes object.
 * @returns {function} A React PropTypes validator.
 */
export function paramTypeToPropType(paramTypeArg) {
    const propTypeValidator = function(props, propName, componentName) {
        try {
            paramTest(props[propName], paramTypeArg, propName, { throwError: true, noLog: true });
        }
        catch (err) {
            let errorMessage = `Invalid prop \`${propName}\` supplied to \`${componentName}\`.`;

            if (err?.message) {
                errorMessage += ` ${err.message}`;
            }

            return new Error(errorMessage);
        }

        return null; // No errors
    };

    propTypeValidator.isRequired = function(props, propName, componentName) {
        if (props[propName] === null || props[propName] === undefined) {
            return new Error(
                `Invalid prop \`${propName}\` supplied to \`${componentName}\`. \`${propName}\` is required.`
            );
        }
        else {
            return propTypeValidator(props, propName, componentName);
        }
    };

    return propTypeValidator;
}
