import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ListButton from 'Common/components/ListButton';
import DraggableFieldItem from './DraggableFieldItem';
import DraggableListWithTrashCan from 'Common/components/DraggableList/DraggableListWithTrashCan';
import Button from 'Common/components/Button';

import TableFieldsOption from './TableFieldsOption';
import MetricAndMetricSetOption from 'Common/components/ColumnControl/MetricAndMetricSetOption';
import CommentListColumnSelector from './CommentListColumnSelector';
import MetricListColumnEditor from './MetricListColumnEditor';
import AttentionListColumnSelector from './AttentionListColumnSelector';
import SparkDimensionColumnSelector from 'Common/components/ColumnControl/SparkDimensionColumnSelector';
import { METRIC_LIST_TYPE, SHOW_AS_BAR_CHART, SHOW_WITH_HEAT_MAP_INDICATORS } from "Common/util/MetricListColumnConstants";
import { useTranslator } from 'Common/hooks/useTranslation';
import { Body5 } from 'Common/components/typography';
import { ATTENTION_LIST_TYPE, COMMENT_LIST_TYPE } from '../tableWidgetConstants';
import {
    BAR_VISUALIZATION_TYPE,
    COLUMN_VISUALIZATION_TYPE,
    DIMENSION_ORDER,
    TEXT_VISUALIZATION_TYPE,
    VALUE_ORDER
} from 'Common/components/SparkVisualization/SparkDimensionConstants';

const METRIC = 'metric';
const CATEGORY_VALUE = 'category_value';

const AddButtonContainer = styled.div`
    padding: 0 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
`;

const ButtonContainer = styled.div`
    box-sizing: border-box;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding-right: 11px;
`;

const ApplyContainer = styled(ButtonContainer)`
    justify-content: flex-end;
    flex: 0 0 auto;
`;

const ValidationContainer = styled.div`
    justify-content: flex-end;
    flex: 0 0 auto;
    padding: 0 10px;
`;

const ValidationText = styled(Body5)`
    color: ${({theme}) => theme.colors.palette.error.red };
`;

// Used to handle reordering the drag/drop values
function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

// Create secondary text from a field based on its type and or configuration
function getSecondaryTextFromField(translator, item, fieldOptions) {
    if (item.fieldType === METRIC) {
        return translator('metric_literal');
    }
    else if (item.fieldType === CATEGORY_VALUE) {
        const fieldTypeDisplayName = translator('category_literal');

        let visualizationDisplayName;
        if (item.dimensionVisualization.visualization === BAR_VISUALIZATION_TYPE) {
            visualizationDisplayName = translator('widget.spark_visualization.dimension_visualization_options.visualization.bar');
        }
        else if (item.dimensionVisualization.visualization === COLUMN_VISUALIZATION_TYPE) {
            visualizationDisplayName = translator('widget.spark_visualization.dimension_visualization_options.visualization.column');
        }
        else if (item.dimensionVisualization.visualization === TEXT_VISUALIZATION_TYPE) {
            visualizationDisplayName = translator('widget.spark_visualization.dimension_visualization_options.visualization.text');
        }

        let orderDisplayName;
        if (item.dimensionVisualization.orderBy === VALUE_ORDER) {
            orderDisplayName = translator('widget.spark_visualization.dimension_visualization_options.order_by.by_value');
        }
        else if (item.dimensionVisualization.orderBy === DIMENSION_ORDER) {
            orderDisplayName = translator('widget.spark_visualization.dimension_visualization_options.order_by.by_dimension');
        }

        let metricDisplayName = translator('unknown_field_literal');
        fieldOptions.category_value_metrics[item.dimensionVisualization.visualization].find(({ entries }) => {
            const foundEntry = entries.find(col => col.name === item.dimensionVisualization.metric);
            if (foundEntry) {
                metricDisplayName = foundEntry.displayName;
            }
            return foundEntry;
        });

        let secondaryText = `${fieldTypeDisplayName}; ${visualizationDisplayName}`;

        if (item.dimensionVisualization.metric) {
            secondaryText += `; ${metricDisplayName}; ${orderDisplayName}`;
        }

        if (item.dimensionVisualization.maxElementsShown) {
            secondaryText += `; ${item.dimensionVisualization.maxElementsShown} ${translator('items_literal')}`;
        }
        
        return secondaryText;
    }
    else if (item.fieldType === ATTENTION_LIST_TYPE) {
        return `${translator('attention_list')}; ${item?.attentionList?.limit || 0} ${translator('items_literal')}`;
    }
    else if (item.fieldType === METRIC_LIST_TYPE) {
        const metrics = item.columns.map(({ displayName }) => (displayName)).join(', ') || '';
        
        let displayAs = translator('widget.table.column_control.as_text');
        if (item.showAs === SHOW_AS_BAR_CHART) {
            displayAs = translator('widget.table.column_control.as_bar_chart');
        }
        else if (item.showAs === SHOW_WITH_HEAT_MAP_INDICATORS) {
            displayAs = translator('widget.table.column_control.as_heat_map_indicator');
        }

        return `${translator('metric_list')}; ${metrics}; ${displayAs}`;
    }
    else if (item.fieldType === COMMENT_LIST_TYPE) {
        const displayAs = item.commentList.includeAllComments
            ? translator('widget.table.column_control.all_for_time_peroid')
            : translator('widget.table.column_control.only_for_directly_linked');
        return `${displayAs}`;
    }
    else {
        return '';
    }
}

/**
 * This component is one of the options traveled to by TableColumnControl.
 * It's the start, allowing the user to add/update/remove fields.
 */

const TableSelectedFieldsOption = ({
    selectedColumns,
    fieldOptions,
    isValid,
    validation,
    isDirty,
    onChange,
    onApply,
    transitionForward,
}) => {
    const translator = useTranslator();

    const handleAddField = useCallback((e) => {
        transitionForward(
            { control: TableFieldsOption, props: {}, title: translator('add_column_literal') }
        );
    }, [transitionForward, translator]);

    const onDragEnd = useCallback((result) => {
        const newValue = reorder(
            selectedColumns,
            result.source.index,
            result.destination.index
        );

        onChange({
            target: {
                value: newValue
            }
        });
    }, [selectedColumns, onChange]);

    const onDelete = useCallback((result) => {
        const newValue = selectedColumns.filter((item) => {
            return item.name !== result.id;
        });

        onChange({
            target: {
                value: newValue
            }
        });
    }, [selectedColumns, onChange]);

    const handleUpdateFields = useCallback((e) => {
        const columnIndex = selectedColumns.findIndex((col) => (col.name === e.target.value));

        if (columnIndex < 0) {
            console.warn(`Can not find column ${e.target.value}`);
            return;
        }

        const columnFieldType = selectedColumns[columnIndex].fieldType;

        if (columnFieldType === METRIC) {
            transitionForward(
                { control: MetricAndMetricSetOption, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('metric_literal')}) }
            );
        }
        else if (columnFieldType === CATEGORY_VALUE) {
            transitionForward(
                { control: SparkDimensionColumnSelector, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('category_literal')}) }
            );
        }
        else if (columnFieldType === ATTENTION_LIST_TYPE) {
            transitionForward(
                { control: AttentionListColumnSelector, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('attention_list')}) }
            );
        }
        else if (columnFieldType === METRIC_LIST_TYPE) {
            transitionForward(
                { control: MetricListColumnEditor, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('metric_list')}) }
            );
        }
        else if (columnFieldType === COMMENT_LIST_TYPE) {
            transitionForward(
                { control: CommentListColumnSelector, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('comment')}) }
            );
        }

    }, [selectedColumns, transitionForward, translator]);

    return (
        <Fragment>
            <AddButtonContainer>
                <ListButton
                    onClick={handleAddField}
                >
                    {translator('add_column_literal')}
                </ListButton>
            </AddButtonContainer>
            <DraggableListWithTrashCan
                onDragEnd={onDragEnd}
                onDelete={onDelete}
                deleteHelperText={translator('widget.table.column_control.drop_here_to_delete_column')}
            >
                {selectedColumns.map((item) => {
                    return (
                        <DraggableFieldItem
                            key={item.name}
                            name={item.name}
                            primaryText={item.displayName}
                            secondaryText={getSecondaryTextFromField(translator, item, fieldOptions)}
                            onEdit={handleUpdateFields}
                        />
                    );
                })}
            </DraggableListWithTrashCan>
            {validation && (
                <ValidationContainer>
                    <ValidationText>
                        {validation}
                    </ValidationText>
                </ValidationContainer>
            )}
            <ApplyContainer>
                <Button
                    onClick={onApply}
                    disabled={!isDirty || !isValid}
                >
                    {translator('apply_literal')}
                </Button>
            </ApplyContainer>
        </Fragment>
    );
};

TableSelectedFieldsOption.propTypes = {
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            channel: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            fieldType: PropTypes.string.isRequired,
            showAs: PropTypes.string,
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    channel: PropTypes.string.isRequired,
                    displayName: PropTypes.string.isRequired,
                })
            ),
            attentionList: PropTypes.shape({
                limit: PropTypes.number,
            }),
            commentList: PropTypes.shape({
                includeAllComments: PropTypes.bool,
            })
        }),
    ).isRequired,
    fieldOptions: PropTypes.shape({
        metric_set: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        metric_set: PropTypes.string.isRequired,
                        columns: PropTypes.arrayOf(
                            PropTypes.shape({
                                displayName: PropTypes.string.isRequired,
                            }).isRequired
                        ).isRequired
                    }).isRequired
                ).isRequired,
            }),
        ).isRequired,
    }).isRequired,
    isValid: PropTypes.bool.isRequired,
    validation: PropTypes.string,
    isDirty: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    transitionForward: PropTypes.func.isRequired,
};

export default TableSelectedFieldsOption;
