import { sendPatchRequest } from 'Http/request';
import { patchResources } from 'Common/util/ResourceCollection';
import isEqual from 'lodash/isEqual';

const PATCH_KEYS = [ 'id', 'metric', 'process_state', 'comparison', 'threshold' ];

function extractPatchKeys(alert) {
    return PATCH_KEYS.reduce((rv, key) => {
        if (alert[key] !== undefined) {
            rv[key] = alert[key];
        }
        return rv;
    }, {});
}

function withoutId(alert) {
    const { id, ...other } = alert;
    return other;
}

export function generateAlertsPatch(initialData, modifiedData) {
    const patchData = {
        add: {},
        replace: {},
        remove: {},
    };

    Object.keys(initialData).forEach((alertType) => {
        // Remove all fields that shouldn't be present in the request
        const initialAlerts = initialData[alertType].map(extractPatchKeys);
        const modifiedAlerts = modifiedData[alertType].map(extractPatchKeys);

        let patch = { add: [], replace: [], remove: [] };

        // Map of initial alerts, key is the alert id
        let initial = {};

        initialAlerts.forEach((alert) => {
            initial[alert.id] = alert;
        });

        // Map of modified or unchnaged alerts, key is the alert id
        let modified = {};

        modifiedAlerts.forEach((alert) => {
            const alertId = alert.id;

            // If the alert isn't in the the initial set, it must be new
            if (!Object.prototype.hasOwnProperty.call(initial, alertId)) {
                // Remove alert field and push
                patch.add.push(withoutId(alert));
            }
            else {
                // Alert is either modified or unchanged, store it
                modified[alertId] = alert;

                // Test to see if the alert was modified at all
                if (!isEqual(alert, initial[alertId])) {
                    patch.replace.push(alert);
                }
            }
        });

        // Find alerts from the initial set that no longer exist, those
        // are ones that will be removed
        Object.keys(initial).forEach((alertId) => {
            if (!Object.prototype.hasOwnProperty.call(modified, alertId)) {
                patch.remove.push(Number(alertId));
            }
        });

        patchData.add[alertType] = patch.add;
        patchData.replace[alertType] = patch.replace;
        patchData.remove[alertType] = patch.remove;
    });

    return patchData;
}

export function patchAlerts(data) {
    return patchResources({
        resourceType: 'alerts',
        requestId: 'patchAlerts',
        sendRequest: async (changes) => {
            return sendPatchRequest('/api/v1/alerts', {
                body: { ...data },
            });
        },
    });
}
