const processStates = [
    {
        value: 'running',
        name: 'Running',
        hasTargetTime: false,
    },
    {
        value: 'running_slow',
        name: 'Running Slow',
        hasTargetTime: false,
    },
    {
        value: 'running_poor_quality',
        name: 'Running Poor Quality',
        hasTargetTime: false,
    },
    {
        value: 'running_poorly',
        name: 'Running Poorly',
        hasTargetTime: false,
    },
    {
        value: 'down',
        name: 'Down',
        hasTargetTime: false,
    },
    {
        value: 'meal_break',
        name: 'Meal/Break',
        hasTargetTime: true,
    },
    {
        value: 'meeting',
        name: 'Meeting',
        hasTargetTime: true,
    },
    {
        value: 'maintenance',
        name: 'Maintenance',
        hasTargetTime: true,
    },
    {
        value: 'no_production',
        name: 'No Production',
        hasTargetTime: false,
    },
    {
        value: 'changeover',
        name: 'Changeover',
        hasTargetTime: true,
    },
    {
        value: 'not_monitored',
        name: 'Not Monitored',
        hasTargetTime: false,
    },
];

export default processStates;

export function getProcessStatesWithTargetTimes() {
    return processStates.filter(ps => ps.hasTargetTime);
}

export function getProcessStatesMap() {
    return processStates.reduce((map, processState) => {
        map[processState.value] = processState;
        return map;
    }, {});
}
