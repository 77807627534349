import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import WidgetCacheContext from './internal/InternalWidgetCacheContext';
import useDashboardCache from './useDashboardCache';

/**
 * A React context provider that enables the use of `useWidgetCache`.
 *
 * This provider requires that `DashboardCacheProvider` exist higher in the
 * React tree.
 */
export default function WidgetCacheProvider({ widgetId, children }) {
    const dashboardCache = useDashboardCache();

    const value = useMemo(() => {
        return {
            getValueCache: function(cacheId) {
                return dashboardCache.getWidgetValueCache(widgetId, cacheId);
            },
            clearValueCache: function(cacheId) {
                return dashboardCache.clearWidgetValueCache(widgetId, cacheId);
            },
        };
    }, [ dashboardCache, widgetId ]);

    return (
        <WidgetCacheContext.Provider value={value}>
            {children}
        </WidgetCacheContext.Provider>
    );
}

WidgetCacheProvider.propTypes = {
    widgetId: PropTypes.string.isRequired,
    children: PropTypes.node,
};
