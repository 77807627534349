import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'Common/components/Icon';

import { Heading3, Body4, FontWeight, FontSize } from 'Common/components/typography';

const StyledPrimary = styled.span`
    text-align: right;
    min-width: 250px;
`;

const StyledPrimaryTitle = styled(Heading3)``;

const StyledPrimaryDetails = styled(Heading3)`
    font-weight: ${FontWeight.medium}
`;


const StyledSubtitle = styled(Body4)`
    text-align: right;
    display: block;
    color: ${({theme}) => theme.colors.darkText.mediumEmphasis };
`;

const PaddedIcon = styled(Icon)`
    font-weight: ${FontWeight.medium};
    font-size: ${FontSize.size3}px;
    padding: 0 6px;
`;

const Root = styled.span`
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    cursor: ${props => props.isDropdownTrigger ? "pointer" : "inherit"};
    user-select: none;

    &:hover {
        background-color: ${({isDropdownTrigger, theme}) => isDropdownTrigger ? theme.colors.palette.grey.cloud : "inherit"};
    }
`;

export default function TimeRangeDisplay(props) {
    const {
        description,
        isDropdownTrigger,
        onClick,
        } = props;

    const { primary, secondary } = description;

    return(
        <Root isDropdownTrigger={isDropdownTrigger} onClick={onClick}>
            <StyledPrimary>
                <StyledPrimaryTitle>
                    {primary.title}
                </StyledPrimaryTitle>
                {
                    primary.detail &&
                    <StyledPrimaryDetails> {primary.detail}</StyledPrimaryDetails>
                }
            </StyledPrimary>
            {
                isDropdownTrigger &&
                <PaddedIcon type="fontAwesome" iconName="angle-down" />
            }
            <StyledSubtitle>{secondary.title}</StyledSubtitle>
        </Root>
    );
}

TimeRangeDisplay.propTypes = {
    description: PropTypes.shape({
        primary: PropTypes.shape({
            title: PropTypes.string.isRequired,
            detail: PropTypes.string.isRequired,
        }).isRequired,
        secondary: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
    }),
    isDropdownTrigger: PropTypes.bool,
    onClick: PropTypes.func,
};

TimeRangeDisplay.defaultProps = {
    isDropdownTrigger: false,
    onClick: () => { },
};
