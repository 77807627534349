import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import styled from 'styled-components';
import { useTranslator } from 'Common/hooks/useTranslation';
import useLocale from 'Common/hooks/useLocale';
import { getDefaultFormatter } from 'Common/data/defaultFormatters';
import { getTextColorForBackground } from 'Common/util/color/util';
import ErrorIndicator from '../ErrorIndicator';

const Root = styled.div`
    display: inline-block;
`;

const MetricContent = styled.div`
    display: inline-block;
`;

const MetricText = styled.div`
    display: inline-block;
`;

const ErrorContainer = styled.div`
    display: inline-block;
    margin-right: 4px;
`;

export const HEAT_BLOB_WIDTH = 8;
export const HEAT_BLOB_MARGIN = 4;
const BLOB_HEIGHT = 16;

const HeatBlobElement = styled.div.attrs(props => ({
    style: {
        backgroundColor: props.color
    }
}))`
    display: inline-block;
    height: ${BLOB_HEIGHT}px;
    width: ${HEAT_BLOB_WIDTH}px;
    margin-left: ${HEAT_BLOB_MARGIN}px;
    border-radius: 0.2em;
`;


export function getBlobStyleForHeat(heatColor) {
    if (heatColor) {
        return {
            backgroundColor: heatColor,
            color: getTextColorForBackground(heatColor),
        };
    }
}

/**
 * @param {String} severity the severity based on where it fit in metric alerts
 *          (best, good, warning, caution, critical)
 * @param {Object} blobStyle the style that was already created for the blob will be re-used for very-bad
 * @param {boolean} heatColor Should it the color of the metric value for non-very-bad_metrics match heat color
 */
export function getMetricStyleFromBlob(severity, blobStyle, heatColor) {
    if (severity === 'critical') {
        return {
            ...blobStyle,
            borderRadius: '0.3em',
            fontWeight: 500,
            paddingLeft: '0.3em',
            paddingRight: '0.3em',
        };
    }
    else if (blobStyle && heatColor) {
        return {
            color: blobStyle.backgroundColor,
            paddingLeft: '0.3em',
            paddingRight: '0.3em',
        };
    }
    else {
        return {};
    }
}

export function isNullValue(rawValue) {
    return rawValue === null || rawValue === undefined || Number.isNaN(rawValue);
}

/**
 * Gets the text value for this metric.
 * @param {Object} formatType The formatType that describes the metric value.
 * @param {Object} rawValue The rawValue that makes up the metric value.
 * @param {Function} translator Translates keys to correct text
 * @param {String} locale Client locale
 * 
 * @return {String}
 */
 export function getMetricText(parameters) {
        paramTest(parameters, ParamTypes.shape({
            formatType: ParamTypes.string,
            rawValue: ParamTypes.any,
            locale: ParamTypes.string.isRequired,
            translator: ParamTypes.func.isRequired,
        }), 'getMetricText');

        const {
            formatType,
            rawValue,
            locale,
            translator,
        } = parameters;

        const isNull = isNullValue(rawValue);
        let newRawValue = rawValue;
        // id_array be an empty array if it is null.
        // This sets the proper null value according to Type.js
        if (isNull && formatType !== 'invalid') {
            if (formatType === 'id_array') {
                newRawValue = [];
            }
            else {
                return translator('null_metric_literal');
            }
        }

        const valueFormatter = getDefaultFormatter({
            type: formatType,
            locale,
            translator,
        });

        let t = valueFormatter ? valueFormatter(newRawValue, {react: true}) : newRawValue;
        return t;
}

/**
 * A React component that renders a visual representation of a metric.
 * Takes in at minimum a format type and a rawValue which will be
 * formatted and rendered out.
 *
 * @param {Object} data The data that makes up the metric value.
 * @param {String[]} [error] List of any errors to be displayed in
 *     place of the metric.
 * @param {boolean} [showHeatBlob=true] When true we show a heat colored
 *     blob next to the value. Only applies to heat mappable metrics.
 * @param {boolean} [noColor=false] When true, don't render the metric
 *     with any non-grey-scale colors. Mostly used for printing.
 */
function Metric({
    data,
    error,
    noColor,
    showHeatBlob,

    // Used to forward style information
    className,
    style,
}) {
    const {
        rawValue,
        formatType,
        heatConfig,
    } = (data || {});

    const translator = useTranslator();
    const locale = useLocale();

    const formattedValue = useMemo(() => {
        return getMetricText({ formatType, locale, translator, rawValue });
    }, [formatType, locale, translator, rawValue]);

    let content;

    if (heatConfig && !noColor) {
        const getReactHeatBlob = (blobStyle) => {
            if (blobStyle && showHeatBlob) {
                return (
                    <HeatBlobElement color={blobStyle.backgroundColor}>&nbsp;</HeatBlobElement>
                );
            }
        };

        const blobStyle = getBlobStyleForHeat(heatConfig.color);
        const metricStyle = getMetricStyleFromBlob(heatConfig.severity, blobStyle, !showHeatBlob);

        content = (
            <MetricContent title={formattedValue}>
                <MetricText style={metricStyle}>{formattedValue}</MetricText>
                {getReactHeatBlob(blobStyle)}
            </MetricContent>
        );
    }
    else {
        content = <MetricContent title={formattedValue}>{formattedValue}</MetricContent>;
    }

    return (
        <Root className={className} style={style}>
            {error && <ErrorContainer><ErrorIndicator error={error} /></ErrorContainer>}
            {content}
        </Root>
    );
}

Metric.propTypes = {
    error: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.shape({
        name: PropTypes.string,
        formatType: PropTypes.string.isRequired,
        rawValue: PropTypes.any,

        heatConfig: PropTypes.shape({
            severity: PropTypes.oneOf([
                'best',
                'good',
                'warning',
                'caution',
                'critical',
                'unknown',
            ]).isRequired,
            color: PropTypes.string,
        }),
    }),
    noColor: PropTypes.bool,
    showHeatBlob: PropTypes.bool,

    className: PropTypes.string,
    style: PropTypes.object,
};

Metric.defaultProps = {
    noColor: false,
    showHeatBlob: true,
};

export default Metric;
