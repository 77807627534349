import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { elementsOfType } from 'Components/util/propTypesUtils';
import PageTitle from './PageTitle';
import ConfigPageTitle from './ConfigPageTitle';
import PageDescription from './PageDescription';
import PageContentStandard from './PageContentStandard';
import PageContentWide from './PageContentWide';
import PageContentFull from './PageContentFull';
import PageFrame from './PageFrame';

const Root = styled.div`
    position: relative;
    padding: 10px 20px;
    background-color: ${(props) => props.theme.colors.palette.white};
    color: ${(props) => props.theme.colors.palette.black};
`;

export default class Page extends React.Component {
    constructor() {
        super();
    }

    render() {
        const { className, children } = this.props;

        return (
            <Root className={className}>
                {children}
            </Root>
        );
    }
}

Page.propTypes = {
    className: PropTypes.string,
    children: elementsOfType([
        PageTitle,
        ConfigPageTitle,
        PageDescription,
        PageContentStandard,
        PageContentWide,
        PageContentFull,
        PageFrame,
    ]),
};
