import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'Common/components/Icon';
import { Body6 } from 'Common/components/typography';

const StyledEntryLayout = styled.div.attrs(({ indentLevel }) => ({
    style: {
        paddingLeft: `${indentLevel * 20}px`,
    },
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 250px;
`;

const StyledIcon = styled(Icon)`
    height: 100%;
    color: ${({ theme }) => theme.colors.palette.grey.manatee};
    font-size: 16px;
`;

const StyledEntry = styled(Body6)`
    height: 100%;
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    padding-left: 6px;
    white-space: nowrap;
`;

function HierarchyListOption(props) {
    const { value, searchValue, asset, showSelectedIcon } = props;

    const displayName = asset.displayName;

    return (
        <StyledEntryLayout
            value={value}
            searchValue={searchValue}
            indentLevel={asset.level}
        >
            <StyledIcon
                type='vorne'
                iconName={asset.iconClass}
            />
            <StyledEntry>{displayName}</StyledEntry>
            {showSelectedIcon && (
                <StyledIcon
                    type='fontAwesome'
                    iconName='file-text-o'
                />
            )}
        </StyledEntryLayout>
    );
}

HierarchyListOption.propTypes = {
    value: PropTypes.string.isRequired,
    searchValue: PropTypes.string,
    asset: PropTypes.shape({
        level: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        iconClass: PropTypes.string.isRequired,
    }).isRequired,
    showSelectedIcon: PropTypes.bool,
};

HierarchyListOption.defaultProps = {
    showSelectedIcon: true,
};

export default React.memo(HierarchyListOption);
