import every from 'lodash/every';

function validateMetric(value) {
    if (value.length === 0) {
        return 'required';
    }
    else {
        return null;
    }
}

function validateComparison(value) {
    if (value.length === 0) {
        return 'required';
    }
    else if (value !== 'greater_than' && value !== 'less_than') {
        return 'invalid comparison';
    }
    else {
        return null;
    }
}

function validateThreshold(value) {
    if (value.length === 0) {
        return 'required';
    }
    else if (!Number.isFinite(Number(value))) {
        return 'not a number';
    }
    else {
        return null;
    }
}

export default function validate(metricAlert) {
    const { metric, comparison, threshold } = metricAlert;

    const validation = {
        metric: validateMetric(metric),
        comparison: validateComparison(comparison),
        threshold: validateThreshold(threshold),
    };

    if (every(validation, v => !v)) {
        return null;
    }
    else {
        return validation;
    }
}
