/**
 * The RadioButton component displays a radio button with optional text to the
 * right of the button. An enabled ({!disabled}) button displays a pointer when
 * the mouse is hovering over the radio button or adjacent text. The required value
 * is a string that will be used to identify the button. Clicking on an enabled button
 * or its adjacent text will invoke the provided {onChange} callback and pass it
 * the following object:
 * 
 *  target {
 *      value: {value}      // String used to identify the button
 *  }
 * 
 * Sample usage:
 * 
 *  <RadioButton value="option_a">Option A</RadioButton>
 *  <RadioButton value="option_b">Option B</RadioButton>
 *  <RadioButton value="option_c">Option C</RadioButton>
 * 
 * Use the RadioButtonGroup component to manage a group of RadioButton's.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Hard-coded constants
const charSelected = "\\e946";
const charUnselected = "\\e940";
const fontSize = 10;
const leftPadding = 10;
const standardPadding = 5;
const touchTargetPadding = 10;

// Styled <div> that contains the radio button and optional text
const StyledDiv = styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    position: relative;
`;

// Styled <div> that contains just the radio button
const StyledButton = styled.div`
    &::before {
        content: "${({selected}) => selected ? charSelected : charUnselected}";
    }

    color: ${
        ({disabled,selected,theme}) =>
            disabled
                ? theme.colors.palette.grey.silver
                : selected
                    ? theme.colors.palette.blue.awesome
                    : theme.colors.palette.grey.manatee
    };

    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    display: flex;
    font-family: "vorne-icon";
    font-size: ${fontSize}px;
    padding-bottom: ${standardPadding}px;
    padding-top: ${standardPadding}px;
    /* Give mobile users a nice big touch target*/
    @media (hover: none) and (pointer: coarse) {
        padding-bottom: ${touchTargetPadding}px;
        padding-top: ${touchTargetPadding}px;
    }
`;

// Styled <div> that contains just the optional text
const StyledLabel = styled.div`
    color: ${
        ({disabled,theme}) =>
            disabled ? theme.colors.darkText.disabled : theme.colors.darkText.highEmphasis
        };

    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    padding-left: ${leftPadding}px;
`;

// The actual control that displays the radio button and optional text
export default function RadioButton({
    children,   // Text to the right of the radio button
    className,
    disabled,   // Is the radio button disabled?
    selected,   // Is the radio button selected?
    value,      // Key identifying this radio button
    onChange    // Callback when control is clicked
}) {
    function handleClick() {
        if (!disabled) {
            onChange({ target: { value } });
        }
    }

    return (
        <StyledDiv disabled={disabled} className={className}>
            <StyledButton selected={selected} disabled={disabled} onClick={handleClick} role='button' />
            {
                disabled ?
                (
                    <StyledLabel disabled={disabled}>{children}</StyledLabel>
                )
                :
                (
                    <StyledLabel disabled={disabled} onClick={handleClick}>{children}</StyledLabel>
                )
            }
        </StyledDiv>
    );
}

RadioButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func
};

RadioButton.defaultProps = {
    disabled: false,
    selected: false,
    onChange: () => {}
};
