import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SelectList from './SelectList';
import useSearchBox from 'Common/hooks/useSearchBox';

const StyledSearchFieldContainer = styled.div`
    margin: 10px 10px 0 10px;
`;

export default function SearchableSelectList({
    disabled,
    searchBoxPlaceholder,
    ...selectListProps
}) {
    const {
        searchValue,
        renderSearchBox,
    } = useSearchBox({
        autoFocus: true,
        disabled,
        placeholder: searchBoxPlaceholder,
    });

    return (
        <React.Fragment>
            <StyledSearchFieldContainer>
                {renderSearchBox()}
            </StyledSearchFieldContainer>
            <SelectList {...selectListProps} disabled={disabled} searchValue={searchValue} />
        </React.Fragment>
    );
}

SearchableSelectList.propTypes = {
    disabled: PropTypes.bool,
    searchBoxPlaceholder: PropTypes.string,
};

SearchableSelectList.defaultProps = {
    disabled: false,
    searchBoxPlaceholder: '',
};
