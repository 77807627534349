import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'Common/hooks/useTranslation';
import SearchableSelectList from 'Common/components/SelectList/SearchableSelectList.js';
import SelectList from 'Common/components/SelectList/SelectList.js';
import BasicSelectOption from 'Common/components/SelectList/BasicSelectOption';
import SelectDropdownButton from 'Common/components/SelectList/SelectDropdownButton.js';
import SelectSeparator from 'Common/components/SelectList/SelectSeparator';
import SelectOptionGroup from 'Common/components/SelectList/SelectOptionGroup';
import Dropdown from 'Common/components/Dropdown/Dropdown.js';


function generateViewsList(views, translator) {
    let list = [];
    // group options into their groups based on their parents if they have them
    let groupsOrder = [];
    let viewsByGroup = views.reduce((grouped, view) => {
        if (view.groupName) {
            if (grouped[view.groupName]) {
                grouped[view.groupName].push(view);
            }
            else {
                groupsOrder.push(view.groupName);
                grouped[view.groupName] = [view];
            }
        }
        return grouped;
    }, {});

    // sort the views alphabetically
    Object.keys(viewsByGroup).forEach(groupName => {
        // if we have a separator we want to keep the defined order
        if (!viewsByGroup[groupName].find(view => view.isSeparator)) {
            viewsByGroup[groupName].sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
    });

    function createListNode (view, index) {
        if (view.isSeparator) {
            return (<SelectSeparator key={index}/>);
        }
        else if (view.isPlaceHolder) {
            const noneDisplayName = translator('navigation_dropdown_empty_group_literal');
            return (
                <BasicSelectOption
                    key={index}
                    value={noneDisplayName}
                    searchValue={noneDisplayName}
                    disabled={true}
                >
                    {noneDisplayName}
                </BasicSelectOption>
            );
        }
        else {
            return (
                <BasicSelectOption
                    key={index}
                    value={view.path}
                    searchValue={view.displayName}
                >
                    {view.displayName}
                </BasicSelectOption>
            );
        }
    }

    // if we have groups create appropriate select elements
    if (groupsOrder.length) {
        groupsOrder.forEach((groupDisplayName, groupIndex) => {
            const entries = viewsByGroup[groupDisplayName].map((view, viewIndex) => createListNode(view, viewIndex, translator));
            list.push(
                <SelectOptionGroup
                    key={groupIndex}
                    label={groupDisplayName}
                >
                    {entries}
                </SelectOptionGroup>
            );
        });
    }
    // no grouping so standard select elements
    else {
        let sortedViews = [].concat(views);
        // sort the views alphabetically
        // if we have a separator we want to keep the defined order
        if (!sortedViews.find(view => view.isSeparator)) {
            sortedViews.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
        list = sortedViews.map((view, viewIndex) => createListNode(view, viewIndex));
    }

    return list;
}

/**
 * A component that displays the current view name and allows a user to select different views under the same page.
 */
const PageSelectField = ({
    children,
    disabled,
    hideSearch,
    name,
    options,
    value,
    onChange,
}) => {
    const translator = useTranslator();

    const [isOpen, setIsOpen] = useState(false);

    const optionElements = useMemo(() => generateViewsList(options, translator), [options, translator]);

    const openHandler = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closeHandler = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onChangeHandler = useCallback((event) => {
        closeHandler();
        onChange(event);
    }, [closeHandler, onChange]);

    const renderContent = useCallback(() => {
        if (hideSearch) {
            return (
                <SelectList
                    onChange={onChangeHandler}
                    value={value}
                >
                    {optionElements}
                </SelectList>
            );
        }
        else {
            return (
                <SearchableSelectList
                    onChange={onChangeHandler}
                    searchBoxPlaceholder={translator('type_to_search_literal')}
                    noSelectionText={translator('filterbox_no_selections_copy')}
                    value={value}
                >
                    {optionElements}
                </SearchableSelectList>
            );
        }
    }, [onChangeHandler, optionElements, value, hideSearch, translator]);

    return options.length > 1
        ? (
            <Dropdown
                isOpen={isOpen}
                onMaskClick={closeHandler}
                renderContent={renderContent}>
                <SelectDropdownButton
                    style={{width: '100%'}}
                    size={'large'}
                    disabled={disabled}
                    onClick={openHandler}>
                    {children}
                </SelectDropdownButton>
            </Dropdown>
        )
        : null;
};

PageSelectField.propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    hideSearch: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        groupName: PropTypes.string,
        displayName: PropTypes.string,
        path: PropTypes.string,
        isSeparator: PropTypes.bool.isRequired,
        isPlaceHolder: PropTypes.bool.isRequired,
    })).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

PageSelectField.defaultProps = {
    disabled: false,
    hideSearch: true,
};

export default PageSelectField;
