import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Roles } from 'Components/app/roles';
import StaticNavMenu from 'Components/header/internal/navigation-menu/StaticNavMenu';
import MobileNavMenu from '../navigation-menu/MobileNavMenu';
import filterNavItems from '../navigation-menu/filterNavItems';

const mainMenuContent = [
    {
        menuTitle: 'SUBSCRIPTIONS',
        menuItems: [
            {
                type: 'link',
                to: '/subscriptions/alerts',
                text: 'Alert Subscriptions',
                minRole: Roles.MEMBER,
            },
            {
                type: 'link',
                to: '/subscriptions/reports',
                text: 'Email Report Subscriptions',
                minRole: Roles.MEMBER,
            },
        ],
    },
];

class MainMenu extends React.PureComponent {
    static propTypes = {
        mobile: PropTypes.bool,
        role: PropTypes.string,
    };

    static defaultProps = {
        mobile: false,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { mobile, role } = this.props;

        const subMenuContents = filterNavItems(mainMenuContent, role);

        if (mobile) {
            return (
                <MobileNavMenu
                    subMenuContents={subMenuContents}
                />
            );
        }
        else {
            return (
                <StaticNavMenu
                    subMenuContents={subMenuContents}
                />
            );
        }
    }
}

const mapStateToProps = state => {
    const {
        session: {
            sessionData: {
                role
            }
        }
    } = state;

    return { role };
};

export default connect(mapStateToProps)(MainMenu);
