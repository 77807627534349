
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import EmailTableLiveControls from './EmailTableLiveControls';
import WidgetFrame from 'Common/components/WidgetFrame/WidgetFrame';
import Table from 'Common/components/Table/WidthAdjustableTable';
import { useTranslator } from 'Common/hooks/useTranslation';
import {
    getMockTableData,
    getMockTableHeaderColumns,
    getEmailTableMetricListMetricOptions,
} from './MockWidgetData';

const CONFIG_BY_TABLE_TYPE = {
    'availability_events': {
        hardCodedColumns: [
            'start_time',
            'process_state_reason',
            'duration',
        ],
        maxNumUserSelectedColumns: 0,
    },
    'availability_reasons': {
        hardCodedColumns: [
            'process_state_reason',
            'duration',
            'count',
        ],
        maxNumUserSelectedColumns: 0,
    },
    'reject_reasons': {
        hardCodedColumns: [
            { channel: 'quality', name: 'reject_reason' },
            { channel: 'quality', name: 'count' },
        ],
        maxNumUserSelectedColumns: 0,
    },
    'part_run': {
        hardCodedColumns: [
            'start_time',
            'duration',
            'part',
        ],
        maxNumUserSelectedColumns: 3,
    },
    'work_center': {
        hardCodedColumns: [
            'asset',
        ],
        maxNumUserSelectedColumns: 5,
    },
};

const getHardCodedColumnsForTableType = (tableType) => {
    if (!CONFIG_BY_TABLE_TYPE[tableType]) {
        throw new Error(`Invalid tableType: ${tableType}`);
    }

    return CONFIG_BY_TABLE_TYPE[tableType].hardCodedColumns;
};

const getMaxNumUserSelectedColumnsForTableType = (tableType) => {
    if (!CONFIG_BY_TABLE_TYPE[tableType]) {
        throw new Error(`Invalid tableType: ${tableType}`);
    }

    return CONFIG_BY_TABLE_TYPE[tableType].maxNumUserSelectedColumns;
};

export default function EmailTableWidget({
    metricThresholds,
    widgetStf,
    widgetState,
    areLiveControlsVisible,
    actions,
    timeRange,
    explorationPath,
    mutable,
    dragHandleProps,
    isDragEnabled,
    isLayoutModeActive,
    dataService,
    isPrint,
    hideTrend,
    printConfig,
    suppressedControls,
    suppressDelete,
}) {
    const showLiveControls = areLiveControlsVisible;

    // function to update state with new columns
    const updateColumnsInFieldSet = (origState, newColumns)  => {
        const newFieldSet = {
            ...origState.exploration_path.field_set,
            fields: newColumns
        };

        const newExplorationPath = {
            ...origState.exploration_path,
            field_set: newFieldSet
        };

        return {
            ...origState,
            exploration_path: newExplorationPath,
        };
    };

    const [dirtyUserSelectedColumns, setDirtyUserSelectedColumns] = useState(false);

    const onDimensionOptionsChange = (tableType, maxRowCount) => {
        actions.setWidgetStf((stf) => {
            // update tableType in stf
            let newStf = {
                ...stf,
                tableType: tableType,
                row_limit: {
                    limit: maxRowCount,
                },
            };

            const maxNumUserSelectedColumns = getMaxNumUserSelectedColumnsForTableType(tableType);

            // depending on what the new table type and the current state update the selected columns
            if (maxNumUserSelectedColumns === 0) {
                // when selecting these table types the user selected columns are completely wiped,
                // which means other table types will revert back to defaults (thus clean)
                setDirtyUserSelectedColumns(false);
                newStf = updateColumnsInFieldSet(newStf, []);
            }
            else if (dirtyUserSelectedColumns) {
                // The user had made some changes to the selected columns.
                // Instead of replacing them with defaults, just trim them so
                // they don't exceed the max number of columns for the new table
                // type.
                const userSelectedColumns = (stf.exploration_path.field_set.fields || []).slice(0, maxNumUserSelectedColumns);
                newStf = updateColumnsInFieldSet(newStf, userSelectedColumns);
            }
            else if (tableType === 'part_run') {
                // if the user is coming from a blank column set then add the default columns for this table type
                newStf = updateColumnsInFieldSet(newStf, [
                    getEmailTableMetricListMetricOptions('good_count'),
                    getEmailTableMetricListMetricOptions('efficiency'),
                    getEmailTableMetricListMetricOptions('oee'),
                ]);
            }
            else if (tableType === 'work_center') {
                // if the user is coming from a blank column set then add the default columns for this table type
                newStf = updateColumnsInFieldSet(newStf, [
                    getEmailTableMetricListMetricOptions('efficiency'),
                    getEmailTableMetricListMetricOptions('good_count'),
                    getEmailTableMetricListMetricOptions('reject_count'),
                    getEmailTableMetricListMetricOptions('down_time'),
                    getEmailTableMetricListMetricOptions('oee'),
                ]);
            }

            return newStf;
        });
    };

    const onColumnsChange = (newColumns) => {
        setDirtyUserSelectedColumns(true);
        actions.setWidgetStf((stf) => {
            return updateColumnsInFieldSet(stf, newColumns);
        });
    };

    const translator = useTranslator();

    let maxRowCount = 5;
    if (widgetStf.row_limit) {
        maxRowCount = widgetStf.row_limit.limit;
    }

    const tableType = widgetStf.tableType;
    // this is the user selected columns
    const selectedColumns = widgetStf.exploration_path.field_set.fields;
    const maxNumUserSelectedColumns = getMaxNumUserSelectedColumnsForTableType(tableType);

    const tableColumns = useMemo(() => [
        ...getHardCodedColumnsForTableType(tableType),
        ...selectedColumns,
    ], [ tableType, selectedColumns ]);

    const columns = getMockTableHeaderColumns(tableColumns, translator, metricThresholds);

    return (
        <WidgetFrame
            isPrint={isPrint}
            liveControls={mutable && showLiveControls && (
                <EmailTableLiveControls
                    suppressedControls={suppressedControls}
                    onDimensionOptionsChange={onDimensionOptionsChange}
                    onColumnsChange={onColumnsChange}
                    selectedColumns={selectedColumns}
                    tableType={tableType}
                    maxRowCount={maxRowCount}
                    maxNumUserSelectedColumns={maxNumUserSelectedColumns}
                />
            )}
        >
            <Table
                columns={columns}
                data={getMockTableData(tableColumns, maxRowCount)}
            />
        </WidgetFrame>
    );
}

EmailTableWidget.propTypes = {
    metricThresholds: PropTypes.object,
    widgetStf: PropTypes.object.isRequired,
    widgetState: PropTypes.object,
    areLiveControlsVisible: PropTypes.bool.isRequired,

    actions: PropTypes.exact({
        setWidgetStf: PropTypes.func.isRequired,
        setWidgetState: PropTypes.func.isRequired,
        setWidgetPrintStatus: PropTypes.func.isRequired,
        setWidgetExportItem: PropTypes.func.isRequired,
        exportWidgetData: PropTypes.func.isRequired,
        deleteWidget: PropTypes.func.isRequired,
        duplicateWidget: PropTypes.func.isRequired,
        setWidgetLiveControlsVisibility: PropTypes.func.isRequired,
    }).isRequired,

    timeRange: PropTypes.object,
    explorationPath: PropTypes.object,

    mutable: PropTypes.bool.isRequired,
    parentTitle: PropTypes.string,
    dragHandleProps: PropTypes.object,
    isDragEnabled: PropTypes.bool.isRequired,
    isLayoutModeActive: PropTypes.bool.isRequired,
    isPrint: PropTypes.bool.isRequired,
    hideTrend: PropTypes.bool.isRequired,
    printConfig: PropTypes.object,
    suppressedControls: PropTypes.object,
    suppressDelete: PropTypes.bool,

    dataService: PropTypes.shape({
        getKpiWidgetData: PropTypes.func.isRequired,
        getWorkCenterCount: PropTypes.func.isRequired,
        getColumnConfig: PropTypes.func.isRequired,
        getColumnHelpText: PropTypes.func.isRequired,
        getTimeRangePrimaryDetails: PropTypes.func.isRequired,
        getTimeRangeSecondaryDetails: PropTypes.func.isRequired,
        getHierarchyNodeDisplayName: PropTypes.func.isRequired,
    }).isRequired,
};
