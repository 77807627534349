import styled from 'styled-components';
import { Body1 } from 'Components/typography/typography';

const SelectGroupLabel = styled(Body1)`
    display: block;
    padding: 6px;
    font-weight: bold;
    color: ${props => props.theme.colors.palette.blue.awesome};
`;

export default SelectGroupLabel;
