import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Root = styled.div`
	break-inside: avoid;
	background-color: #fff;

    ${({$showBorder}) => $showBorder && css`
        border: 1px solid #a0a0a0;
	    border-radius: 6px;
    `}
`;

function WidgetFrame({
    isPrint,
    frameHeader,
    liveControls,
    showBorder,
    
    children,
}) {
    return (
        <Root $showBorder={!!frameHeader || showBorder}>
            {frameHeader}
            {!isPrint && (
                <div>
                    {liveControls}
                </div>
            )}
            <div>
                {children}
            </div>
        </Root>
    );
}

WidgetFrame.propTypes = {
    isPrint: PropTypes.bool,
    frameHeader: PropTypes.node,
    liveControls: PropTypes.node,
    showBorder: PropTypes.bool,
    children: PropTypes.node,
};

WidgetFrame.defaultProps = {
    showBorder: false,
    isPrint: false,
};

export default WidgetFrame;
