import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { HEADING_TYPE, BLANK_ROW_TYPE } from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidgetConstants';

const Root = styled.span`
    ${({theme, type}) => {
        if (type === HEADING_TYPE) {
            return css`
                font-weight: 500;
                color: ${theme.colors.palette.blue.awesome};
            `;
        }
        else if (type === BLANK_ROW_TYPE) {
            return css`
                color: ${theme.colors.darkText.disabled};
            `;
        }
    }}
`;

function KPIGroupFieldChipText({
    type,
    children,
}) {
    return (
        <Root type={type}>
            {children}
        </Root>
    );
}

KPIGroupFieldChipText.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

KPIGroupFieldChipText.defaultProps = { };

export default KPIGroupFieldChipText;
