import {
    GET_VERSION_REQUEST,
    GET_VERSION_SUCCESS,
    GET_VERSION_FAILURE,
} from 'Redux/actions';
import { requestReducer } from 'Common/util/ResourceCollection';
import { REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';

const initialState = {
    versionData: null,
    requests: {
        getVersion: requestReducer(undefined, {}),
    },
};

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_VERSION_REQUEST:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    getVersion: requestReducer(state.requests.getVersion, {
                        ...action,
                        type: REQUEST_PENDING,
                    }),
                },
            };
        case GET_VERSION_SUCCESS:
            return {
                ...state,
                versionData: {
                    ...payload,
                },
                requests: {
                    ...state.requests,
                    getVersion: requestReducer(state.requests.getVersion, {
                        ...action,
                        type: REQUEST_SUCCESS,
                    }),
                },
            };
        case GET_VERSION_FAILURE:
            return {
                ...state,
                versionData: null,
                requests: {
                    ...state.requests,
                    getVersion: requestReducer(state.requests.getVersion, {
                        ...action,
                        type: REQUEST_FAILURE,
                    }),
                },
            };
        default:
            return state;
    }
}
