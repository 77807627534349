import ParamTypes from 'Common/util/ParamTypes';

const fieldParamType = ParamTypes.shape({
    name: ParamTypes.string.isRequired,
    displayKey: ParamTypes.string,
    channel: ParamTypes.string,
    alternate_display: ParamTypes.string,
    type: ParamTypes.oneOf([
        'string',
        'integer',
        'double',
        'time_span',
        'date_time',
        'id',
        'id_array',
        'percent',
        'other',
        'custom',
    ]),
});

export default fieldParamType;
