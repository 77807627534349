import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import Notice from './Notice';

function ErrorNotice({ children, theme, ...otherProps }) {
    return (
        <Notice
            textColor={theme.colors.palette.white}
            backgroundColor={theme.colors.palette.error.red}
            {...otherProps}
        >
            {children}
        </Notice>
    );
}

ErrorNotice.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object.isRequired,
};

export default withTheme(ErrorNotice);
