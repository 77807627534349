import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavLink = styled(Link)`
    line-height: 32px;
    color: #505050;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default NavLink;
