import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectOptionGroup from 'Common/components/SelectList/SelectOptionGroup';
import BasicSelectOption from 'Common/components/SelectList/BasicSelectOption';
import CollapsibleSelectOptionGroup from 'Common/components/SelectList/CollapsibleSelectOptionGroup';
import SelectSeparator from 'Common/components/SelectList/SelectSeparator';
import styled from 'styled-components';
import { Body5, Heading5 } from 'Common/components/typography';
import Icon from 'Common/components/Icon';
import InfoCircle from 'Common/components/InfoCircle';
import { useTranslator } from 'Common/hooks/useTranslation';

export const getFullyQualifiedName = function(option) {
    return option ? `${option.name}` : '';
};

const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 16px;
    align-items: center;
`;

const OptionText = styled(Body5)`
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;

    color: ${({ theme }) => theme.colors.darkText.highEmphasis};
`;

const OptionDescription = styled(Body5)`
    height: 100%;
    width: 100%;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
    margin-left: 20px;

    color: ${({ theme }) => theme.colors.darkText.disabled};
`;

const MetricSetIcon = styled(Icon)`
    padding-left : 5px;
    opacity : 0.5;
`;

const TooltipHeaderText = styled(Heading5)`
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
`;


export function getEntriesPropType() {
    return PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
            }),
            PropTypes.shape({
                isSeparator: PropTypes.bool.isRequired,
            }),
        ]),
    );
}

export function getOptionsPropType() {
    return PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            isDummyGroup: PropTypes.bool.isRequired,
            entries: getEntriesPropType().isRequired,
        }).isRequired
    );
}

/**
 * Given an array of groups with entries return option elements for use in a SelectList
 *
 * @param {Array} optionGroups groups of options, should contain an entries array with actual options
 * @returns react elements representing those options for use in a SelectList
 */
export function useOptionElements({ optionGroups, collapsibleGroups }) {
    const translator = useTranslator();
    
    return useMemo(() => {
        const renderDescription = (entry) => {
            if (entry.short_description_key) {
                const literal = translator(entry.short_description_key);
                return (
                    <OptionDescription>
                        {literal}
                    </OptionDescription>
                );
            }
        };

        const renderIcons = (entry) => {
            if (entry.fieldType === 'metric_set') {
                return (
                    <MetricSetIcon 
                        iconName="list-ul"
                        type="fontAwesome"
                    />
                );
            }
        };

        const tooltipTextLiteral = translator('widget.table.column_control.metric_set_tooltip_column_text');

        const renderTooltip = (entry) => {
            if (entry.fieldType === 'metric_set') {
                const metrics = entry.columns.map((metric) => (<li key={metric.name}>{metric.displayName}</li>));
                return (
                    <InfoCircle>
                        <TooltipHeaderText>{tooltipTextLiteral}</TooltipHeaderText>
                        <ul>
                            {metrics}
                        </ul>
                    </InfoCircle>
                );
            }
        };

        return optionGroups.reduce((current, group) => {
            let lastGroup = '';
            const groupChildren = group.entries.map((entry) => {
                const rv = [];

                if (lastGroup !== entry.groupName) {
                    if (lastGroup !== '') {
                        rv.push(<SelectSeparator />);
                    }
                    lastGroup = entry.groupName;
                }

                const optionFqn = getFullyQualifiedName(entry);

                if (entry.isSeparator) {
                    rv.push(<SelectSeparator />);
                }
                else {
                    rv.push(
                        <BasicSelectOption
                            key={`option-${optionFqn}`}
                            value={optionFqn}
                            searchValue={entry.displayName}
                            disabled={!!entry.disabled}
                        >
                            <OptionContainer>
                                <OptionText>
                                    {entry.displayName}
                                    {renderIcons(entry)}
                                </OptionText>
                                {renderTooltip(entry)}
                                {renderDescription(entry)}
                            </OptionContainer>
                        </BasicSelectOption>
                    );
                }

                return rv;
            });

            // If the group is a "dummy group", we want to flatten the options and
            // display them as ungrouped.
            if (group.isDummyGroup) {
                current = current.concat(groupChildren);
            }
            else if (collapsibleGroups) {
                current.push(
                    <CollapsibleSelectOptionGroup
                        key={`group-${group.name}`}
                        groupName={group.name}
                        label={group.displayName}
                    >
                        {groupChildren}
                    </CollapsibleSelectOptionGroup>
                );
            }
            else {
                current.push(
                    <SelectOptionGroup
                        key={`group-${group.name}`}
                        label={group.displayName}
                    >
                        {groupChildren}
                    </SelectOptionGroup>
                );
            }

            return current;
        }, []);
    }, [ optionGroups, collapsibleGroups, translator ]);
}

export function findOptionByFqn (options, fullyQualifiedName) {
    let selectedOption = null;

    options.find((group) => {
        const foundEntry = group.entries.find((categoryValue) => {
            return getFullyQualifiedName(categoryValue) === fullyQualifiedName;
        });

        if (foundEntry) {
            selectedOption = foundEntry;
        }

        return selectedOption !== null;
    });

    return selectedOption;
}
