import PropTypes from 'prop-types';
import { getProcessStatesMap } from 'System/processStates';

const processStatesMap = getProcessStatesMap();

export default function ProcessStateDurationAlertText(props) {
    const { process_state, threshold } = props;

    const thresholdMin = Math.floor(threshold / 60);

    return (
        `${processStatesMap[process_state].name} > ${thresholdMin} min.`
    );
}

ProcessStateDurationAlertText.propTypes = {
    process_state: PropTypes.oneOf(Object.keys(processStatesMap)).isRequired,
    threshold: PropTypes.number.isRequired,
};
