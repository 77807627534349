import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingWrapper from 'Components/loading-indicator/LoadingWrapper';
import LoginForm from './LoginForm';
import LogoutForm from './LogoutForm';
import { selectIsInitialized, selectIsLoggedIn, selectSessionRequest, selectSessionUser } from 'Redux/reducers/session';

const Base = styled.div`
    padding: 0;
    line-height: normal;
    width: 100%;
`;

export default class LoginLogoutForm extends React.Component {
    static propTypes = {
        session: PropTypes.object.isRequired,
        onLoginSubmit: PropTypes.func,
        onLogoutSubmit: PropTypes.func,
    };

    static defaultProps = {
        onLoginSubmit: () => {},
        onLogoutSubmit: () => {},
    };

    constructor(props) {
        super(props);
    }

    _handleLoginSubmit = ({ email, password }) => {
        this.props.onLoginSubmit({
            email: email,
            password: password,
        });
    };

    _handleLogoutSubmit = () => {
        this.props.onLogoutSubmit();
    };

    render() {
        const { session } = this.props;

        const isInitialized = selectIsInitialized(session);
        const isLoggedIn = selectIsLoggedIn(session);
        const sessionData = selectSessionUser(session);

        return (
            <LoadingWrapper
                loading={!isInitialized}
                render={() => (
                    <Base>
                        {isLoggedIn && (
                            <LogoutForm
                                key='logout'
                                sessionData={sessionData}
                                request={selectSessionRequest(session, 'logout')}
                                onSubmit={this._handleLogoutSubmit}
                            />
                        )}
                        {!isLoggedIn && (
                            <LoginForm
                                key='login'
                                request={selectSessionRequest(session, 'login')}
                                onSubmit={this._handleLoginSubmit}
                            />
                        )}
                    </Base>
                )}
            />
        );
    }
}
