import {
    useMemo,
} from 'react';

/**
 * Hook to handle expanded rows. We don't use the one included with react-table
 * because the widgets are allowed to change the expanded rows as well.
 * 
 * @param {Object} expanded the expanded state
 * @param {Object[]} rows the data shown as rows in the table
 * @param {Function} setExpandedRows update expanded state
 */
export default function ({ expanded, rows, setExpandedRows }) {
    return useMemo(() => {
        let expandedRows = [];
        const handleExpandRow = (row) => {
            const isExpanded = expanded[row.id];
            expandedRows.push({
                ...row,
                isExpanded,
                handleExpandToggle: (id) => {
                    let updatedExpandedState = {
                        ...expanded
                    };
                    if (updatedExpandedState[id]) {
                        delete updatedExpandedState[id];
                    }
                    else {
                        updatedExpandedState[id] = true;
                    }
                    setExpandedRows(updatedExpandedState);
                }
            });

            if (row.subRows?.length && isExpanded) {
                row.subRows.forEach(handleExpandRow);
            }
        };

        rows.forEach(handleExpandRow);

        return expandedRows;
    }, [rows, expanded, setExpandedRows]);
}
