import styled from 'styled-components';

import TableColumn from './TableColumn';

const TableErrorColumn = styled(TableColumn)`
    color: ${ p => p.theme.colors.error };

    :not(:last-child) {
        border-right: 0;
    }

    :not(:first-child) {
        border-left: 0;
    }
`;

export default TableErrorColumn;
