import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import UnmaskablePasswordInput from 'Components/password/UnmaskablePasswordInput';
import Page from 'Components/page/Page';
import { Body1 } from 'Components/typography/typography';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentStandard';
import LoginFormContainer from 'Components/login-form/LoginFormContainer.js';
import { sendPostRequest } from 'Http/request';
import validate from 'Components/password/PasswordRulesValidation';
import Form from 'Components/form/Form';
import ErrorNotice from 'Components/notice/ErrorNotice';

export default class ResetPasswordPage extends React.Component {
    constructor() {
        super();

        this.state = {
            password: {
                dirty: false,
                invalidReason: null,
                value: null
            },
            submit: {
                isLoading: false,
                error: null,
                isDone: false,
            }
        };
    }

    _handlePasswordChange = (event) => {
        const password = event.target.value;
        const error = validate(password);

        this.setState(() => {
            return {
                password: {
                    dirty: true,
                    invalidReason: error,
                    value: password
                }
            };
        });
    };

    _handleResponse(response) {
        if (response.ok) {
            this.setState((prevState) => {
                return {
                    submit: {
                        ...prevState.submit,
                        isLoading: false,
                        isDone: true,
                        error: null,
                    }
                };
            });
        }
        else {
            this._handleResponseError(response.data);
        }
    }

    _handleResponseError(err) {
        this.setState((prevState) => {
            return {
                submit: {
                    ...prevState.submit,
                    isLoading: false,
                    isDone: false,
                    error: err || { message: 'Network error' },
                },
            };
        });
    }

    _handleSubmit = (event) => {
        let submit = Object.assign({}, this.state.submit);
        submit.isLoading = true;

        this.setState(prevState => ({ submit }), () => {
            let urlParams = queryString.parse(this.props.location.search);
            sendPostRequest('/api/v1/accounts/update-password', {
                body: {
                    new_password: this.state.password.value,
                    token: urlParams.token
                }
            })
            .then((response) => {
                this._handleResponse(response);
            })
            .catch((err) => {
                this._handleResponseError(err);
            });
        });
    };

    _validUrl(params) {
        // linking token is the only required url parameter
        return !!params.token;
    }

    render() {
        const { password, submit } = this.state;
        const allowSubmit = password.dirty && ! password.invalidReason && ! submit.isLoading;

        let urlParams = queryString.parse(this.props.location.search);

        if (! this._validUrl(urlParams)) {
            return (
                <Page>
                    <PageTitle>Invalid Reset Password Link</PageTitle>
                    <PageContent>
                        <ErrorNotice>
                            <Body1 as="div"> The link used to come to this page is invalid </Body1>
                        </ErrorNotice>
                    </PageContent>
                </Page>
            );
        }

        if (submit.isDone) {
            return (
                <Page>
                    <PageTitle>Reset Password</PageTitle>
                    <PageContent>
                        <Body1 as="div"> You have successfully reset your password </Body1>
                        <div>
                            <LoginFormContainer />
                        </div>
                    </PageContent>
                </Page>
            );
        }

        return (
            <Page>
                <PageTitle>Reset Password</PageTitle>
                <PageContent>
                    <Form
                        onSubmit={this._handleSubmit}
                        allowSubmit={allowSubmit}
                        submitButtonText="Set Password"
                        showCancel={false}
                        error={submit.error}
                    >
                        <UnmaskablePasswordInput
                            label='New Password'
                            onChange={this._handlePasswordChange}
                            error={!!password.invalidReason}
                            helperText={password.invalidReason || ''}
                            value={password.value}
                        />
                    </Form>
                </PageContent>
            </Page>
        );
    }
}

ResetPasswordPage.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string
    }).isRequired,
};
