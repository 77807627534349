import React from 'react';
import LiveControl from 'Common/components/LiveControlBar/LiveControl';
import LiveControlIcon from 'Common/components/LiveControlBar/LiveControlIcon';

/**
 * Simple function to render a <LiveControl> element for duplicating a widget.
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.key React `key`
 * @param {function} props.action Called when the live control is clicked.
 * @param {function} props.translator
 *
 * @returns {React.ElementType}
 */
export default function renderDuplicateLiveControl({
    name,
    key,
    action,
    translator,
}) {
    return (
        <LiveControl
            name={name}
            key={key}
            displayName={translator('duplicate_literal')}
            icon={(
                <LiveControlIcon type="vorne" iconName="duplicate" />
            )}
            action={action}
        />
    );
}
