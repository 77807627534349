import {
    SET_RESOURCE,
    REQUEST_RESET,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from './actions';
import { requestReducer } from './requestReducer';
import actionFilter from 'Common/util/reducerUtils/actionFilter';
import actionMap from 'Common/util/reducerUtils/actionMap';

function createRequestsReducer(requestIds) {
    return actionMap(
        (action) => action?.requestId,
        requestIds.reduce((reducers, requestId) => {
            reducers[requestId] = requestReducer;
            return reducers;
        }, {}),
    );
}

/**
 * A reducer for a collection of resources
 * 
 * @param {string} resourceType Unique name of resource collection
 * @param {Object} options
 * @param {function} options.resourceReducer Reducer for each individual resource
 * @param {function(Object):number} options.getResourceId Given a resource, returns the `resourceId`
 * @param {string[]} options.requests `requestId`s handled by the resource
 * @param {function} [options.sort] Optional sort function
 * @param {function} [options.additionalReducers] Optional function to allow additional reducers to implemented for resource
 * @param {boolean} [options.numericResourceIdsOnly=true] Should the collection only allow numeric values for resource IDs?
 *      Defaults to true. If false, string IDs are allowed.
 */
export function resourceCollectionReducer(resourceType, options) {
    const {
        resourceReducer,
        getResourceId,
        requests: requestIds,
        sort: sortFunction,
        additionalReducers,
        numericResourceIdsOnly = true,
    } = options;

    const requestsReducer = createRequestsReducer(requestIds);

    function _isIndividualResourceAction(action) {
        // If `resourceId` is provided, we know this action is targeting a
        // single resource, not the entire collection.
        //
        if (numericResourceIdsOnly) {
            // Allow numbers only
            return typeof action.resourceId === 'number';
        }
        else {
            // Allow numbers or strings
            return (
                typeof action.resourceId === 'number' ||
                typeof action.resourceId === 'string'
            );
        }
    }

    function _handleGetResources(state, action) {
        const {
            type,
            payload,
        } = action;

        switch (type) {
            case REQUEST_PENDING: {
                return {
                    ...state,
                    requests: requestsReducer(state.requests, action),
                };
            }
            case REQUEST_SUCCESS: {
                let list = [ ...payload ];

                if (sortFunction) {
                    list.sort(sortFunction);
                }

                return {
                    ...state,
                    list: list.map((resource) => {
                        return getResourceId(resource);
                    }),
                    resources: payload.reduce((resources, resource) => {
                        resources[getResourceId(resource)] = resourceReducer(undefined, {
                            type: SET_RESOURCE,
                            payload: resource,
                        });
                        return resources;
                    }, {}),
                    requests: requestsReducer(state.requests, action),
                };
            }
            case REQUEST_FAILURE: {
                return {
                    ...state,
                    list: [],
                    resources: {},
                    requests: requestsReducer(state.requests, action),
                };
            }
            default: {
                return state;
            }
        }
    }

    function _handlePostResource(state, action) {
        const {
            type,
            payload,
        } = action;

        switch (type) {
            case REQUEST_PENDING: {
                return {
                    ...state,
                    requests: requestsReducer(state.requests, action),
                };
            }
            case REQUEST_SUCCESS: {
                let list = [ ...payload ];

                if (sortFunction) {
                    list.sort(sortFunction);
                }

                return {
                    ...state,
                    list: list.map((resource) => {
                        return getResourceId(resource);
                    }),
                    resources: payload.reduce((resources, resource) => {
                        resources[getResourceId(resource)] = resourceReducer(undefined, {
                            type: SET_RESOURCE,
                            payload: resource,
                        });
                        return resources;
                    }, {}),
                    requests: requestsReducer(state.requests, action),
                };
            }
            case REQUEST_FAILURE: {
                return {
                    ...state,
                    requests: requestsReducer(state.requests, action),
                };
            }
            default: {
                return state;
            }
        }
    }

    function _handlePatchResource(state, action) {
        const {
            type,
            payload,
        } = action;

        switch (type) {
            case REQUEST_PENDING: {
                return {
                    ...state,
                    requests: requestsReducer(state.requests, action),
                };
            }
            case REQUEST_SUCCESS: {
                let list = [ ...payload ];

                if (sortFunction) {
                    list.sort(sortFunction);
                }

                return {
                    ...state,
                    list: list.map((resource) => {
                        return getResourceId(resource);
                    }),
                    resources: payload.reduce((resources, resource) => {
                        resources[getResourceId(resource)] = resourceReducer(undefined, {
                            type: SET_RESOURCE,
                            payload: resource,
                        });
                        return resources;
                    }, {}),
                    requests: requestsReducer(state.requests, action),
                };
            }
            case REQUEST_FAILURE: {
                return {
                    ...state,
                    requests: requestsReducer(state.requests, action),
                };
            }
            default: {
                return state;
            }
        }
    }

    const initialState = {
        list: [],
        resources: {},
        requests: requestsReducer(undefined, {}),
        // Use this resource initial state so that there is something to return
        // when a missing resource is selected
        _missingResourceState: resourceReducer(undefined, {}),
    };

    return actionFilter(
        // Only process actions targeted directly at this collection
        (action) => action?.resourceType === resourceType,
        (state = initialState, action) => {
            const {
                type,
                requestId,
                requestMethod,
                resourceId,
            } = action;
    
            // If the action is targeting a single resource, pass it along to that
            // reducer
            if (_isIndividualResourceAction(action)) {
                const resourceState = state.resources[resourceId];
                const nextResourceState = resourceReducer(resourceState, action);

                // If the resource is deleted, make sure we remove it from the
                // list
                let nextList = state.list;
                if (requestMethod === 'DELETE' && type === REQUEST_SUCCESS) {
                    nextList = nextList.filter((id) => id !== resourceId);
                }

                return {
                    ...state,
                    list: nextList,
                    resources: {
                        ...state.resources,
                        [resourceId]: nextResourceState,
                    },
                };
            }
    
            // If `requestId` is set on the action, only process requests that were
            // specified in the constructor
            if (requestId && !state.requests[requestId]) {
                return state; // Unknown `requestId`, ignore action
            }
    
            switch (type) {
                case REQUEST_RESET: {
                    return {
                        ...state,
                        requests: requestsReducer(state.requests, action),
                    };
                }
                default: {
                    if (requestMethod === 'GET') {
                        return _handleGetResources(state, action);
                    }

                    else if (requestMethod === 'POST') {
                        return _handlePostResource(state, action);
                    }

                    else if (requestMethod === 'PATCH') {
                        return _handlePatchResource(state, action);
                    }

                    else {
                        if (additionalReducers) {
                            return additionalReducers(state, action);
                        }
                        return state;
                    }
                }
            }
        }
    );
}

export function selectResource(state, id) {
    return state.resources[id] || state._missingResourceState;
}

export function selectResources(state) {
    return state.list.map((id) => {
        return selectResource(state, id);
    });
}

export function selectResourcesRequest(state, requestId) {
    const request = state.requests[requestId];

    if (!request) {
        throw new Error(`Unknown resources request with ID "${requestId}"`);
    }

    return request;
}
