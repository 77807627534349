import React from 'react';
import PropTypes from 'prop-types';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageDescription from 'Components/page/PageDescription';

export default class CreateAccountSuccessPage extends React.Component {
    static propTypes = {
        email: PropTypes.string.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
    }

    render() {
        const { email } = this.props;

        return (
            <Page>
                <PageTitle>Check your inbox!</PageTitle>
                <PageDescription>
                    A verification link has been sent to <i>{email}</i>.
                    You must follow the instructions in the email to confirm your
                    email address before you will be able to log in.
                </PageDescription>
            </Page>
        );
    }
}
