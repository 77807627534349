import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from 'Common/components/Checkbox';
import EnterpriseHierarchySelectList from 'Common/components/EnterpriseHierarchySelectList';
import { useTranslator } from 'Common/hooks/useTranslation';

const StyledCheckbox = styled(Checkbox)`
    padding-left: 10px;
`;

function EnterpriseHierarchyControl({
    hierarchySelection,
    onHierarchySelectionChange,

    hierarchyList,
    onlyAssets,

    collapseHierarchyValue,
    showCollapseHierarchyCheckbox,
    onCollapseHierarchyChange,

    followAppBar,
    showFollowAppBarCheckbox,
    onFollowAppBar,
}) {
    const translator = useTranslator();

    const collapseHierarchyLiteral = translator('control.common.collapse_hierarchy');
    const searchAssetLiteral = translator('control.common.search_asset_hierarchy');
    const loadingLiteral = translator('loading_literal');
    const filterboxNoSelectionsCopyLiteral = translator('filterbox_no_selections_copy');
    const followAppBarCheckboxText = translator('control.common.follow_app_bar');
    
    return (
        <React.Fragment>
            {showFollowAppBarCheckbox && (
                <StyledCheckbox
                    value={followAppBar}
                    label={followAppBarCheckboxText}
                    onChange={onFollowAppBar}
                />
            )}
            {showCollapseHierarchyCheckbox && (
                <StyledCheckbox
                    value={collapseHierarchyValue}
                    label={collapseHierarchyLiteral}
                    onChange={onCollapseHierarchyChange}
                />
            )}
            <EnterpriseHierarchySelectList
                disabled={followAppBar}
                searchBoxPlaceholder={searchAssetLiteral}
                noSelectionText={!hierarchyList ? loadingLiteral : filterboxNoSelectionsCopyLiteral}
                hierarchyList={hierarchyList}
                value={hierarchySelection}
                onChange={onHierarchySelectionChange}
                onlyAssets={onlyAssets}
            />
        </React.Fragment>
    );
}

EnterpriseHierarchyControl.propTypes = {
    hierarchySelection: PropTypes.string,
    onHierarchySelectionChange: PropTypes.func.isRequired,

    hierarchyList: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            iconClass: PropTypes.string.isRequired,
            level: PropTypes.number.isRequired,
        })
    ),
    onlyAssets: PropTypes.bool,

    
    collapseHierarchyValue: PropTypes.bool,
    showCollapseHierarchyCheckbox: PropTypes.bool,
    onCollapseHierarchyChange: PropTypes.func,

    followAppBar: PropTypes.bool,
    showFollowAppBarCheckbox: PropTypes.bool,
    onFollowAppBar: PropTypes.func,
};

EnterpriseHierarchyControl.defaultProps = {
    onlyAssets: false,
    showCollapseHierarchyCheckbox: false,
    showFollowAppBarCheckbox: false,
};

export default EnterpriseHierarchyControl;
