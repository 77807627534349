import ParamTypes from '../ParamTypes';
import paramTypeWrapper from './paramTypeWrapper';
import testType from './testType';

/**
 * ParamType checker which checks if the value is an object and has the given shape.
 *
 * @param {Object} shapeObj An object where each key is a ParamType checker that is
 *     in the shape of the expected value.
 *
 * @example ParamTypes.shape({
 *     foo: ParamTypes.number,
 *     bar: ParamTypes.string.isRequired,
 *     deepFoo: ParamTypes.shape({
 *         baz: ParamTypes.arrayOf(ParamTypes.number),
 *         buz: ParamTypes.boolean.isRequired,
 *     }),
 * })
 *
 * @returns {Function} ParamType checker which returns an array of failures.
 */
export default function shape(shapeObj) {
    if (typeof shapeObj !== 'object' || shapeObj === null) {
        throw new Error(
            `shape argument must be of type "object" not "${typeof shapeObj}"`
        );
    }

    function shapeTest(arg, parentKey) {
        let failures = [];

        failures = failures.concat(testType(ParamTypes.object.isRequired, arg, parentKey));

        if (failures.length > 0) {
            return failures;
        }

        Object.keys(shapeObj).forEach((key) => {
            const shapeMember = shapeObj[key];
            const argsMember = arg[key];

            const nextKey = `${parentKey}.${key}`;

            failures = failures.concat(testType(shapeMember, argsMember, nextKey));
        });

        return failures;
    }

    return paramTypeWrapper(shapeTest);
}
