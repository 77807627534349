import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuHeader = styled.div`
    display: flex;
    align-content: stretch;
    flex-direction: column;
    color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
`;

const MenuSeparator = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
    margin-bottom: 4px;
`;

/**
  * This component is used to layout how the header and body are displayed in the LiveControl Dropdown window.
  */
 export default function LiveControlDropdownLayout({
    body,
    header,
}) {
    return (
        <React.Fragment>
            <MenuHeader>
                {header}
                <MenuSeparator />
            </MenuHeader>
            {body}
        </React.Fragment>
    );
}

LiveControlDropdownLayout.propTypes = {
    body: PropTypes.node.isRequired,
    header: PropTypes.node,
};
