import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListItemSecondaryAction from 'Components/list/ListItemSecondaryAction';
import ListItem from 'Components/list/ListItem';
import { Body1 } from 'Components/typography/typography';
import AlertItemDeleteButton from './AlertItemDeleteButton';
import AlertItemSubscriberList from './AlertItemSubscriberList';
import didPropsChange from 'Components/util/didPropsChange';

const StyledListItem = styled(ListItem)`
    padding: 8px 52px 8px 8px;
    background-color: ${props => props.theme.colors.palette.white};
    color: ${props => props.theme.colors.palette.black};
`;

const EditorWrapper = styled.div``;
const ListWrapper = styled(Body1)``;

const Content = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;

    ${EditorWrapper} {
        display: block;
    }

    ${ListWrapper} {
        display: block;
        padding-left: 8px;
        text-align: left;
        color: ${props => props.theme.colors.darkText.mediumEmphasis};
    }

    ${'' /* When screen is large enough, display editor and list */}
    @media only screen and (min-width: 1280px) {
        display: flex;

        ${EditorWrapper} {
            flex: 0 0 auto;
        }

        ${ListWrapper} {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            justify-content: center;
            text-align: right;

            ${'' /* For some reason, IE11 needs this min-width to be set for
            the text to wrap properly. All I know is it has to do with nested
            flexboxes.
            See: https://github.com/philipwalton/flexbugs/issues/164 */}
            min-width: 1px;
        }
    }
`;

export default class AlertItem extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        subscribers: PropTypes.array.isRequired,
        allowDelete: PropTypes.bool.isRequired,
        onDeleteClick: PropTypes.func.isRequired,
        Editor: PropTypes.func.isRequired,
        EditorProps: PropTypes.object.isRequired,
    };

    static defaultProps = {
        className: '',
    };

    constructor(props) {
        super(props);
    }

    _handleDeleteClick = (event) => {
        this.props.onDeleteClick({
            ...event,
            target: {
                name: this.props.name,
            },
        });
    };

    shouldComponentUpdate(nextProps) {
        return didPropsChange(this.props, nextProps, [
            'subscribers',
            'EditorProps',
        ]);
    }

    render() {
        const { className, subscribers, allowDelete, Editor, EditorProps } = this.props;

        return (
            <StyledListItem className={className} clickable={false}>
                <Content>
                    <EditorWrapper>
                        <Editor {...EditorProps} />
                    </EditorWrapper>
                    <ListWrapper as="div">
                        <AlertItemSubscriberList subscribers={subscribers} />
                    </ListWrapper>
                </Content>
                <ListItemSecondaryAction>
                    <AlertItemDeleteButton
                        disabled={!allowDelete}
                        onClick={this._handleDeleteClick}
                    />
                </ListItemSecondaryAction>
            </StyledListItem>
        );
    }
}
