import React from 'react';
import styled,{ css } from 'styled-components';
import PropTypes from 'prop-types';

import { Heading2, FontWeight } from 'Common/components/typography';
import isEmpty from 'lodash/isEmpty';

import AutoResizeTitle from "Common/components/AutoResizeTitle";
import Icon from 'Common/components/Icon';
import InfoCircle from 'Common/components/InfoCircle';

const Root = styled.div.attrs(props => {
    let style = {};

    style.paddingLeft = props.$isPrint
        ? '8px'
        : props.$isDragEnabled // Leave room for drag handle
        ? '0'
        : props.theme.layout.widget.horizontalPadding;

    style.paddingRight = props.$isPrint
        ? '8px'
        : props.theme.layout.widget.horizontalPadding;

    if (props.$isPrint) {
        style.paddingTop = '6px';
    }
    else {
        style.minHeight = 52;
    }

    return isEmpty(style) ? {} : {style};
})`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
`;

const AssetTitleActionContainer = styled.div.attrs(props => {
    return props.isPrint ? {} : { style: { height: '44' } };
})`
    min-width: 1px;
    flex: 1 1 auto;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
`;

const DragHandle = styled.div`
    flex: 0 0 auto;
    width: ${({theme}) => theme.layout.widget.horizontalPadding};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
`;

const TextContainer = styled(Heading2).attrs(props => {
    let style = {};

    if (props.containerMinWidth) {
        style.minWidth = props.containerMinWidth;
    }

    if (props.isPrint) {
        style.fontSize = 12;
    }

    return { style };
})`
    flex: 0 1 auto;
    display: inline-flex;
    align-items: center;
`;

const TitleContainer = styled(TextContainer)`
    flex: 0 1 auto;
`;

const AssetDisplay = styled.div.attrs(props => ({
    title: props.title ? props.title : undefined,
}))`
    border: 1px solid transparent;
    color: ${props => props.hasError ? props.theme.colors.palette.error.red : props.theme.colors.palette.black };
    font-weight: ${FontWeight.regular};
    min-width: 1px;
    width: 100%;
    overflow: hidden;
    padding-left: .2em;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const frameTitleIconButtonWidth = 44;
const FrameTitleIconButton = styled.button`
    background: none;
    border: none;
    margin: 0;
    outline: none;
    padding: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: ${frameTitleIconButtonWidth}px;
    height: 44px;

    transition: background-color 0.25s;
    color: ${({theme}) => theme.colors.palette.grey.manatee};
    background-color: transparent;
    border-radius: 4px;

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.palette.grey.cloud};
    }
`;

const DeleteIconButton = styled(FrameTitleIconButton)`
    position: relative;
    right: -15px;
`;

const ShowHideIcon = styled(Icon)`
    font-size: 14px;

    transition: transform 0.25s;

    ${({inverted}) => inverted && css`
        transform: rotate(-180deg);
    `}
`;

const DeleteIcon = styled(Icon)`
    font-size: 14px;
`;

const TimeRangeDisplayContainer = styled.div`
    padding-top: 9px;
`;

const InfoCircleContainer = styled.div`
    height: 44px;
    padding-left: 9px;
    display: flex;
    align-items: center;
`;

const assetMinWidth = 40;

export default function FrameTitle({
    assetError,
    assetValue,
    defaultTitleText,
    isPrint,
    showAsset,
    showDelete,
    shouldShowLiveControls,
    canEditTitle,
    showLiveControlsOnClick,
    showLiveControlsButton,
    titleValue,
    titleOnChange,
    infoTooltipContent,
    deleteOnClick,
    timeRangeDisplay,
    isDragEnabled,
    dragHandleProps,
}) {
    const hasInfoCircle = !!infoTooltipContent;
    const minTitleWidth = isPrint ? 1 : 44;

    return (
        <Root
            $isPrint={isPrint}
            $isDragEnabled={isDragEnabled}
        >
            <AssetTitleActionContainer
                isPrint={isPrint}
            >
                {isDragEnabled && (
                    <DragHandle {...dragHandleProps}>
                        <Icon type="vorne" iconName="drag-handles" />
                    </DragHandle>
                )}
                <TitleContainer
                    containerMinWidth={isPrint ? 0 : minTitleWidth}
                    isPrint={isPrint}
                >
                    <AutoResizeTitle
                        canEdit={canEditTitle}
                        defaultTitleText={defaultTitleText}
                        minWidth={isPrint ? 0 : minTitleWidth}
                        type='text'
                        value={titleValue}
                        onChange={titleOnChange}
                    />
                </TitleContainer>
                {showAsset && <TextContainer
                    containerMinWidth={assetMinWidth}
                    isPrint={isPrint}
                >
                    <AssetDisplay
                        hasError={assetError}
                        title={assetValue}
                    >
                        {assetValue}
                    </AssetDisplay>
                </TextContainer>}
                {showLiveControlsButton && !isPrint && (
                    <FrameTitleIconButton
                        onClick={showLiveControlsOnClick}
                    >
                        <ShowHideIcon
                            type='vorne'
                            iconName='double-chevron'
                            inverted={shouldShowLiveControls}
                        />
                    </FrameTitleIconButton>
                )}
            </AssetTitleActionContainer>
            {!!timeRangeDisplay && (
                <TimeRangeDisplayContainer>
                    {timeRangeDisplay}
                </TimeRangeDisplayContainer>
            )}
            {hasInfoCircle && !isPrint && !showDelete && (
                <InfoCircleContainer>
                    <InfoCircle fontSize={16}>
                        {infoTooltipContent}
                    </InfoCircle>
                </InfoCircleContainer>
            )}
            {showDelete && (
                <DeleteIconButton onClick={deleteOnClick}>
                    <DeleteIcon type='fontAwesome' iconName='times-circle' />
                </DeleteIconButton>
            )}
        </Root>
    );
}

FrameTitle.propTypes = {
    assetValue: PropTypes.string,
    assetError: PropTypes.string,
    defaultTitleText: PropTypes.string,
    isPrint: PropTypes.bool.isRequired,
    shouldShowLiveControls: PropTypes.bool.isRequired,
    canEditTitle: PropTypes.bool.isRequired,
    showAsset: PropTypes.bool.isRequired,
    showDelete: PropTypes.bool.isRequired,
    showLiveControlsButton: PropTypes.bool.isRequired,
    titleValue: PropTypes.string,
    infoTooltipContent: PropTypes.node,

    showLiveControlsOnClick: PropTypes.func.isRequired,
    titleOnChange: PropTypes.func.isRequired,
    deleteOnClick: PropTypes.func.isRequired,

    assets: PropTypes.array,
    timeRangeDisplay: PropTypes.node,
    isDragEnabled: PropTypes.bool,
    dragHandleProps: PropTypes.object,
};

FrameTitle.defaultProps = {
    assetValue: '',
    assetError: '',
    defaultTitleText: '',
    showAsset: false,
    infoTooltipContent: '',
    isDragEnabled: false,
    dragHandleProps: {},

    deleteOnClick: () => {},
    showLiveControlsOnClick: () => {},
    titleOnChange: () => {},
};
