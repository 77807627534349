import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';
import ButtonBase from '../Button/ButtonBase';
import { FontWeight } from 'Common/components/typography';

const StyledButton = styled(ButtonBase)`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    color: ${({theme}) => theme.colors.palette.grey.emperor };
    font-size: 12px;

    padding: 4px 8px;
    border-radius: 4px;

    :disabled {
        opacity: 0.5;
    }

    :hover:enabled {
        background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    }
`;

const StyledIcon = styled(Icon)`
    font-size: 9px;
`;

const StyledOption = styled.span`
    font-weight: ${FontWeight.medium};
`;

function ListButton({
    children,
    ...buttonProps
}) {
    return (
        <StyledButton {...buttonProps} >
            <StyledOption>{children}</StyledOption>
            <StyledIcon
                type="vorne"
                iconName="collapse-arrow-closed"
            />
        </StyledButton>
    );
}

ListButton.propTypes = {
    children: PropTypes.node,
};

ListButton.defaultProps = {

};

export default ListButton;
