import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import BasicSelectList from 'Common/components/SelectList/BasicSelectList';
import SelectSeparator from 'Common/components/SelectList/SelectSeparator';
import PropTypes from 'prop-types';
import LiveControlBar from 'Common/components/LiveControlBar';
import LiveControl from 'Common/components/LiveControlBar/LiveControl';
import BasicSelectField from 'Common/components/BasicSelectField/BasicSelectField';
import LiveControlIcon from 'Common/components/LiveControlBar/LiveControlIcon';
import styled from 'styled-components';
import {
    Body2,
    Subtitle2,
} from 'Components/typography/typography';

import TableColumnControl from 'Common/components/widgets/TableWidget/TableColumnControl';

import {
    emailWidgetFieldOptions,
} from './MockWidgetData';
import { useTranslator } from 'Common/hooks/useTranslation';
import Button from 'Common/components/Button';

const MAX_ROWS_OPTIONS = [ {
    displayName: '5',
    value: '5',
}, {
    displayName: '10',
    value: '10',
}, {
    displayName: '15',
    value: '15',
}, {
    displayName: '20',
    value: '20',
}, {
    displayName: '25',
    value: '25',
} ];

const TABLE_TYPES_WITH_MAX_ROW_ENABLED = new Set([
    'availability_events',
    'availability_reasons',
    'reject_reasons',
]);

const RowOptionsContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
`;

const MaxRowLabel = styled(Body2)`
    color: ${props => props.theme.colors.darkText.highEmphasis };
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
`;

const MaxRowLabelDisabled = styled(MaxRowLabel)`
    color: ${props => props.theme.colors.darkText.disabled };
`;

const RowLimitContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const RowOptionsHeading = styled(Subtitle2)`
    color: ${props => props.theme.colors.palette.blue.awesome};
`;

const ApplyContainer = styled.div`
    box-sizing: border-box;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding-right: 11px;
    justify-content: flex-end;
`;

export default function EmailTableLiveControls(props) {
    const {
        onDimensionOptionsChange,
        maxRowCount,
        onColumnsChange,
        selectedColumns,
        tableType,
        maxNumUserSelectedColumns,
        children,
    } = props;

    const translator = useTranslator();
    const editable = true;
    const columnControlRef = useRef();

    const [selectedControl, setSelectedControl] = useState(null);
    const [tableTypeState, setTableTypeState] = useState(tableType);
    const [maxRowCountState, setMaxRowCountState] = useState(maxRowCount);

    const handleSelectionChange = useCallback((newSelection) => {
        let blockSelectionChange = false;

        if (selectedControl === 'columns' && newSelection !== 'columns') {
            blockSelectionChange = !columnControlRef.current.onClose();
        }

        if (!blockSelectionChange) {
            setSelectedControl(newSelection);
        }
    }, [ selectedControl ]);

    // setup for column control
    const columnControlIcon = ( <LiveControlIcon type="vorne" iconName="column-control" /> );
    const filterValue = {};
    const handleColumnsChange = useCallback((evt) => {
        onColumnsChange(evt.target.value);
        setSelectedControl(null);
    }, [ onColumnsChange, setSelectedControl ]);

    // setup for row control
    const columnControlDisplayName = translator('control.common.column');

    const rowControlIcon = ( <LiveControlIcon type="vorne" iconName="row-control" /> );

    const groupOptionsWithIcons = [
        'availability_events',          // start time, reason, duration
        'availability_reasons',         // reason, duration, count
        'reject_reasons',               // reason, count
        'part_run',                     // start time, duration, part id, good count*, efficiency*, oee*
        'work_center',                  // work center, efficiency*, good count*, reject count*, down time*, oee*
    ].map((groupId) => ({
        value: groupId,
        displayName: translator(`email_table.group.${groupId}.display_name`),
        description: translator(`email_table.group.${groupId}.description`),
    }));

    const enableMaxRowControl = TABLE_TYPES_WITH_MAX_ROW_ENABLED.has(tableTypeState);

    const handleDimensionApplyButton = (evt) => {
        handleSelectionChange(null);
        onDimensionOptionsChange(tableTypeState, maxRowCountState);
    };

    const handleTableTypeChange = (evt) => {
        setTableTypeState(evt.target.value);
    };

    const handleMaxRowCountChange = (evt) => {
        setMaxRowCountState(parseInt(evt.target.value.value, 10));
    };

    const isDirty = maxRowCount !== maxRowCountState || tableType !== tableTypeState;

    return (
        <LiveControlBar
            selection={selectedControl}
            onSelectionChange={handleSelectionChange}
        >
            {editable && (
                <LiveControl
                    name='group'
                    key='group'
                    displayName={translator('widget.table.dimension_options')}
                    icon={rowControlIcon}
                    controlRender={() => (
                        <React.Fragment>
                            <BasicSelectList
                                options={groupOptionsWithIcons}
                                value={tableTypeState}
                                onChange={handleTableTypeChange}
                                verticalPadding={0}
                            />
                            <RowOptionsContainer>
                                <SelectSeparator />
                                <RowOptionsHeading>
                                    Row Options
                                </RowOptionsHeading>
                                <RowLimitContainer>
                                    {enableMaxRowControl
                                        ? (<MaxRowLabel>Max Rows</MaxRowLabel>)
                                        : (<MaxRowLabelDisabled>Max Rows</MaxRowLabelDisabled>)
                                    }
                                    <BasicSelectField
                                        name='maxRowCount'
                                        disabled={!enableMaxRowControl}
                                        onChange={handleMaxRowCountChange}
                                        options={MAX_ROWS_OPTIONS}
                                        selectedValue={maxRowCountState.toString()}
                                        selectedDisplayName={maxRowCountState.toString()}
                                    />
                                </RowLimitContainer>
                            </RowOptionsContainer>
                            <ApplyContainer>
                                <Button
                                    onClick={handleDimensionApplyButton}
                                    disabled={!isDirty}
                                >
                                    {translator('apply_literal')}
                                </Button>
                            </ApplyContainer>

                        </React.Fragment>
                    )}
                />
            )}
            {editable && (
                <LiveControl
                    name="columns"
                    displayName={columnControlDisplayName}
                    disabled={maxNumUserSelectedColumns === 0}
                    icon={columnControlIcon}
                    controlRender={(columnProps) => {
                        return (
                            <TableColumnControl
                                ref={columnControlRef}
                                defaultTitle={columnControlDisplayName}
                                icon={columnControlIcon}
                                selectedColumns={selectedColumns}
                                onChange={handleColumnsChange}
                                goBack={columnProps?.headerGoBack || null}
                                fieldOptions={emailWidgetFieldOptions}
                                filterValue={filterValue}
                                maxNumColumns={maxNumUserSelectedColumns}
                            />
                        );
                    }}
                    handleContentLayout={true}
                />
            )}
            {children}
        </LiveControlBar>
    );
}

EmailTableLiveControls.propTypes = {
    onDimensionOptionsChange: PropTypes.func.isRequired,
    onColumnsChange: PropTypes.func.isRequired,
    selectedColumns: PropTypes.array.isRequired,
    tableType: PropTypes.string.isRequired,
    maxNumUserSelectedColumns: PropTypes.number.isRequired,
    maxRowCount: PropTypes.number.isRequired,
    children: PropTypes.node,
};

EmailTableLiveControls.defaultProps = {};
