
import { resourceCollectionReducer, resourceReducer } from 'Common/util/ResourceCollection';
import { selectResources, } from 'Common/util/ResourceCollection/resourceCollectionReducer';

export default resourceCollectionReducer('completedSoftwareUpdates', {
    getResourceId: (resource) => resource.id,
    requests: [
        'getCompletedSoftwareUpdates',
    ],
    resourceReducer: resourceReducer({
        requests: [
            'getCompletedSoftwareUpdates',
        ],
    }),
});

export function getCompletedSoftwareUpdatesData(state) {
    return selectResources(state);
}
