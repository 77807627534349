const METRIC = 'metric';
const METRIC_SET = 'metric_set';

/*
    Filters out value from the combined metric and metrics list.
*/
export function filterCombinedMetricAndMetricSets(options, value) {

    let selectedColumnsByName = value.reduce((byName, col) => {
        byName[col.name] = col;
        return byName;
    }, []);

    const filteredOptions = options.reduce((acc, option) => {
        const { entries } = option;
        const filteredEntries = entries.filter((entry) => {
            const { name, fieldType } = entry;
                if (fieldType === METRIC) { // Only show metrics that do not exist in our selected set
                   return !selectedColumnsByName[name];
                } else if (fieldType === METRIC_SET) {
                    const { columns } = entry;
                    const showSet = columns.some((column) => {
                        // if we come across a column that does not exist in our selected set show the current metric set
                        return !selectedColumnsByName[column.name];
                    }, false);
                    return showSet;
                }       
    });
    if (filteredEntries.length > 0) {
        acc.push({...option, entries: filteredEntries});
    }
    return acc;
}, []);

    return filteredOptions;
}

/**
 * Given a grouped list of metrics list and grouped list of metric sets list. Combine
 * them so metric sets and metrics in the same group are grouped together.
 * @param {Array} metrics 
 * @param {Array} metricSets 
 * @returns {Array} merged metrics
 */
export default function mergeMetricAndMetricSet(metrics, metricSets) {
    let mergedMetrics = [];

    const metricSetGroupByName = metricSets.reduce((byName, metricSetGroup) => {
        byName[metricSetGroup.name] = metricSetGroup;
        return byName;
    }, {});
    let combinedMetricSetGroups = {};

    metrics.forEach((metricGroup) => {
        const newMetricGroup = { ...metricGroup };
        if (metricSetGroupByName[metricGroup.name]) {
            newMetricGroup.entries = metricSetGroupByName[metricGroup.name].entries.concat(metricGroup.entries);
            newMetricGroup.entries.sort((a,b) => {
                return a.displayName.localeCompare(b.displayName);
            });
            combinedMetricSetGroups[metricGroup.name] = true;
        } 

        mergedMetrics.push(newMetricGroup);
    });

    Object.keys(metricSetGroupByName).forEach(metricSetGroupName => {
        if (!combinedMetricSetGroups[metricSetGroupName]) {
            mergedMetrics.push(metricSetGroupByName[metricSetGroupName]);
        }
    });

    mergedMetrics.sort((a,b) => {
        return a.displayName.localeCompare(b.displayName);
    });

    return mergedMetrics;
}
