import { combineReducers } from 'redux';
import actionFilter from 'Common/util/reducerUtils/actionFilter';
import actionMap from 'Common/util/reducerUtils/actionMap';

import { requestReducer } from 'Common/util/ResourceCollection';
import { REQUEST_PENDING, REQUEST_FAILURE, REQUEST_SUCCESS } from 'Common/util/ResourceCollection/actions';

const initialDataState = {};

const dataReducer = (state = initialDataState, action) => {
    const {
        type,
        requestMethod,
        payload,
    } = action;

    if (requestMethod === 'GET') {
        switch (type) {
            case REQUEST_PENDING:
                return initialDataState;
            case REQUEST_FAILURE:
                return initialDataState;
            case REQUEST_SUCCESS: {
                return payload;
            }
            default:
                return state;
        }
    }
    else if (requestMethod === 'PATCH') {
        switch (type) {
            case REQUEST_SUCCESS: {
                return payload;
            }
            default:
                return state;
        }
    }
    else {
        return state;
    }
};

const alertWindowReducer = actionFilter(
    (action) => action?.resourceType === 'alertWindow',
    combineReducers({
        data: dataReducer,
        requests: actionMap(
            (action) => action?.requestId,
            {
                getAlertWindow: requestReducer,
                patchAlertWindow: requestReducer,
            }
        ),
    })
);

export default alertWindowReducer;

export function selectAlertWindow(state) {
    return state.data;
}

export function selectAlertWindowRequest(state, requestId) {
    const request = state.requests[requestId];

    if (!request) {
        throw new Error(`Unknown alert window request with ID "${requestId}"`);
    }

    return request;
}
