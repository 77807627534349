import React from 'react';
import PropTypes from 'prop-types';
import { generateUserPatch } from 'Redux/actions';
import UserEditor from './UserEditor';
import validate from './UserEditorValidation';
import { FormController } from '../../../form/FormController';
import Form from 'Components/form/Form';
import { REQUEST_FAILURE, REQUEST_SUCCESS } from 'Common/util/ResourceCollection/actions';

export class EditUserForm extends React.Component {
    static propTypes = {
        sessionUserId: PropTypes.number,
        initialValue: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            patchUserReset: PropTypes.func.isRequired,
            patchUser: PropTypes.func.isRequired,
        }).isRequired,
        enterpriseHierarchy: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const userId = this.props.initialValue.id;
        this.props.actions.patchUserReset(userId);
    }

    _handleSubmit = async (modifiedValue, formController) => {
        const {
            initialValue,
            setSubmitError,
            resetValue,
        } = formController;

        const patchData = generateUserPatch(initialValue, modifiedValue);

        // Send the request
        const response = await this.props.actions.patchUser(modifiedValue.id, patchData);

        if (response.type === REQUEST_SUCCESS) {
            resetValue();
        }
        else if (response.type === REQUEST_FAILURE) {
            setSubmitError(response.payload?.error);
        }
    };

    _handleReset = () => {
        const userId = this.props.initialValue.id;
        this.props.actions.patchUserReset(userId);
    };

    render() {
        const { sessionUserId, initialValue, enterpriseHierarchy } = this.props;

        const allowDeactivate = sessionUserId !== initialValue.id;

        return (
            <FormController
                initialValue={initialValue}
                validate={(value) => validate(value)}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                render={(innerProps) => {
                    const {
                        value,
                        validation,
                        hasChanges,
                        isSubmitting,
                        submitError,

                        handleChange,
                        handleSubmit,
                        handleReset,
                    } = innerProps;

                    return (
                        <Form
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                            allowSubmit={hasChanges && !isSubmitting && !validation}
                            allowCancel={hasChanges && !isSubmitting}
                            error={submitError}
                        >
                            <UserEditor
                                name=''
                                value={value}
                                validation={validation}
                                allowEdit={!isSubmitting}
                                allowDeactivate={allowDeactivate}
                                onChange={handleChange}
                                enterpriseHierarchy={enterpriseHierarchy}
                            />
                        </Form>
                    );
                }}
            />
        );
    }
}
