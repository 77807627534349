import React, {
    useState,
    useCallback,
    useMemo,
    useImperativeHandle,
    forwardRef,
    createElement,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LiveControlDropdownHeader from 'Common/components/LiveControlBar/LiveControlDropdownHeader';
import LiveControlDropdownLayout from 'Common/components/LiveControlBar/LiveControlDropdownLayout';
import KPIGroupSelectedFieldsOption from './KPIGroupSelectedFieldsOption';
import useStackHistory from 'Common/hooks/useStackHistory';
import { KPI_GROUP_COLUMNS_PROPTYPE, FIELD_TYPE, CATEGORY_VALUE } from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidgetConstants';
import isEqual from 'lodash/isEqual';

const Root = styled.div`
    box-sizing: border-box;
    width: 275px;
    display: flex;
    flex-direction: column;
    min-height: 1px;
`;

// compares columns to see if they are the same
function hasChanges (initialColumns, updatedColumns) {
    if (initialColumns.length !== updatedColumns.length) {
        return true;
    }

    return !initialColumns.every((column, index) => {
        const updatedColumn = updatedColumns[index];
        return isEqual(column, updatedColumn);
    });
}

/**
 * This component is used by KPIGroupWidget to edit/add/remove fields. It is in the style
 * of a stack so as the user travels through the interface it will replace the current
 * control with the next one.
 *
 */
const KPIGroupColumnControl = forwardRef((props, ref) => {
    const {
        defaultTitle,
        fieldOptions,
        icon,
        selectedColumns: initialSelectedColumns,
        goBack,
        onChange,
    } = props;

    const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns);

    const isDirty = useMemo(() => {
        return hasChanges(initialSelectedColumns, selectedColumns);
    }, [selectedColumns, initialSelectedColumns]);

    const handleOuterClose = useCallback(() => {
        if (!isDirty) {
            return true;
        }

        onChange({
            target: {
                value: selectedColumns,
            },
        });

        return true;
    }, [ isDirty, selectedColumns, onChange ]);

    useImperativeHandle(ref, () => ({
        onClose: () => {
            return handleOuterClose();
        },
    }), [ handleOuterClose ]);

    // handles navigation between different controls
    const { 
        current: currentProps,
        canPop: internalCanGoBack,
        push: transitionForward,
        pop: internalGoBack,
        clear
    } = useStackHistory({ control: KPIGroupSelectedFieldsOption, props: {}, title: defaultTitle });

    const handleOnColumnsChange = useCallback((event) => {
        // in certain cases we just add the full column object
        // so now we will modify it to allign it with what
        // KPIGroupWidget expects
        const columns = event.target.value.map((col) => {
            if (col.fieldType === CATEGORY_VALUE && col.type !== FIELD_TYPE) {
                return {
                    type: FIELD_TYPE,
                    name: col.name,
                    channel: col.channel,
                    displayName: col.displayName,
                    fieldType: col.fieldType,
                    dimensionVisualization: col.dimensionVisualization
                };
            }
            else if (col.fieldType && col.type !== FIELD_TYPE) {
                return {
                    type: FIELD_TYPE,
                    name: col.name,
                    channel: col.channel,
                    displayName: col.displayName,
                    fieldType: col.fieldType,
                };
            }
            else {
                return col;
            }
        });
        setSelectedColumns(columns);
        clear();
    }, [clear]);

    const title = currentProps.title || defaultTitle;

    // a go back link can also come from
    // parent not just the history stack
    const canGoBack = !!goBack;

    const headerGoBack = internalCanGoBack
        ? internalGoBack
        : canGoBack ? goBack : null;

    // this handles what happens when the user selects
    // the go back link
    const handleHeaderGoBack = useCallback(() => {
        if (headerGoBack) {
            headerGoBack();
        }
    }, [headerGoBack]);

    const header = (
        <LiveControlDropdownHeader
            displayName={title}
            icon={icon}
            goBack={headerGoBack ? handleHeaderGoBack : null}
        />
    );

    const body = (
        <Root>
            {createElement(currentProps.control, {
                ...(currentProps.props || {}),
                fieldOptions,
                selectedColumns,
                isDirty,
                transitionForward,
                onChange: handleOnColumnsChange,
                onApply: handleOuterClose
            })}
        </Root>
    );

    return (
        <LiveControlDropdownLayout
            header={header}
            body={body}
        />
    );
});

KPIGroupColumnControl.displayName = 'KPIGroupColumnControl';

KPIGroupColumnControl.propTypes = {
    fieldOptions: PropTypes.shape({
        metric: PropTypes.array,
        category_value: PropTypes.array,
        metric_set: PropTypes.array,
    }).isRequired,
    selectedColumns: KPI_GROUP_COLUMNS_PROPTYPE.isRequired,
    icon: PropTypes.node.isRequired,
    defaultTitle: PropTypes.string.isRequired,

    onChange: PropTypes.func.isRequired,
    goBack: PropTypes.func,
};

export default KPIGroupColumnControl;
