import {  resourceReducer } from 'Common/util/ResourceCollection';
import { selectResourceData } from 'Common/util/ResourceCollection/resourceReducer';

export default resourceReducer({
    requests: [
        'getAvailableSoftwareUpdates',
    ],
});

export function getAvailableSoftwareUpdatesData(state) {
    return selectResourceData(state) ?? [];
}
