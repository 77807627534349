import React from 'react';
import PropTypes from 'prop-types';
import CategoryValue from './CategoryValue';
import { categoryValueFormatTypes } from './CategoryValue/CategoryValue';
import Metric from './Metric';

function FormattedDatum({
    data,
    noColor,
    error,

    // Forward other props to inner elements
    ...otherProps
}) {
    let content = null;
    const formatType = data?.formatType;

    if (categoryValueFormatTypes.includes(formatType)) {
        content = (
            <CategoryValue
                data={data}
                error={error}
                noColor={noColor}

                {...otherProps}
            />
        );
    }
    else  {
        content = (
            <Metric
                data={data}
                error={error}
                noColor={noColor}

                {...otherProps}
            />
        );
    }

    return content;
}

FormattedDatum.propTypes = {
    data: PropTypes.shape({
        // These are common to all data types
        name: PropTypes.string,
        formatType: PropTypes.string.isRequired,
        rawValue: PropTypes.any,

        // Additional fields that are not common are still
        // forwarded to the child components.
        //
    }),
    error: PropTypes.arrayOf(PropTypes.string),
    noColor: PropTypes.bool,
};

FormattedDatum.defaultProps = {
    noColor: false,
};

export default FormattedDatum;
