import { trunc } from 'Common/util/language/math';
import { exformat } from 'Common/util/language/string';
import { dateToJSON, fromEpochSeconds } from 'Common/util/language/date';
import { cloneDeep, extend, keys, pick } from 'lodash';
import moment from 'moment';
import { OffsetDate } from 'Common/data/date/OffsetDate';
import { ProductionDay } from 'Common/data/date/ProductionDay';

/**
 * Get the decimal separator for the current or specified locale.
 *
 * @param {String} [locale] Defaults to global locale setting
 */
function getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;

    // NOTE: While Intl.NumberFormat.prototype.formatToParts would be a much
    //       nicer way to do this IE11 does not support it. This workaround
    //       still does the trick though as all locales use a one character
    //       decimal separator.
    //
    return numberWithDecimalSeparator
        .toLocaleString(locale)
        .substring(1, 2);
}

function getFormatString(s) {
    if (s && /\{:.*\}/.test(s)) {
        s = s.slice(2, -1);
    }

    return s;
}

export function preprocessOptions(opts) {
    opts = opts ? cloneDeep(opts) : {};
    opts.pattern = getFormatString(opts.pattern);
    opts.datePattern = getFormatString(opts.datePattern);
    opts.timePattern = getFormatString(opts.timePattern);

    // This en-DE locale is funny... Coerce all its formatting to German.
    if (opts.locale === 'en-DE') {
        opts.locale = 'de-DE';
    }

    return opts;
}

/**
 * IE11's Intl likes to add lots of extraneous left-to-right markers,
 * which interferes with a lot of things like string comparison.
 */
export function stripDirectionalityMarkers(string) {
    return string.replace(/\u200e/g, '');
}

/**
 * Similarly, IE11 loves adding spaces to percent values.
 * When we expect "14.56%", IE11 will give us "14.56 %".
 */
function stripSpaceFromPercent(percentString) {
    return percentString.replace(/\s%/, '%');
}

function parseNumberPattern(pattern) {
    var pieces = pattern.split('.'),
        wholeText = pieces[0],
        fractText = pieces[1],
        hasComma = wholeText.indexOf(',') !== -1,
        whole = {
            required: 0,
            extra: wholeText.replace(/,/g, '').match('#*(0*)(%)?(.*)?')
        },
        fract = {
            required: 0,
            optional: 0
        },
        hasPercent = false,
        extraText = '',
        opts = {};

    if (whole.extra) {
        opts.minDigits = whole.extra[1].length;
        hasPercent = whole.extra[2];
        extraText = whole.extra[3];
    }

    if (fractText) {
        fract.extra = fractText.match(/(0*)(#*)(%)?(.*)$/);
        fract.required = fract.extra[1].length;
        fract.optional = fract.extra[2].length;
        hasPercent = fract.extra[3];
        extraText = fract.extra[4];
        hasComma = wholeText.indexOf(',') !== -1;
    }

    opts.useGrouping = !!hasComma;

    if (hasPercent) {
        opts.style = 'percent';
    }
    if (extraText) {
        opts.format = '{value}' + extraText;
    }

    opts.minimumFractionDigits = fract.required;
    opts.maximumFractionDigits = fract.required + fract.optional;

    return opts;
}

function preprocessNumberOptions(opts) {
    opts = preprocessOptions(opts);

    opts.style = opts.type;

    if (opts.places || opts.places === 0) {
        opts.minimumFractionDigits = opts.places;
        opts.maximumFractionDigits = opts.places;
    }

    if (opts.pattern) {
        opts = extend(opts, parseNumberPattern(opts.pattern));
    }

    return opts;
}

/**
 * Format a JavaScript number.
 *
 * @param {Number} n the number to format
 * @param {Object} [opts] (optional) various controls over the formatting output.
 *
 * @param {Boolean} [opts.round = false] If true round to the nearest whole number
 * @param {Number} [opts.minimumFractionDigits] Min number of decimal places to show.
 * @param {Number} [opts.maximumFractionDigits] Max number of decimal places to show.
 * @param {Number} [opts.places] Fixed number of decimal places to show, replaces both
 *     minimumFractionDigits and maximumFractionDigits opts.
 * @param {Number} [opts.minDigits] Min digits to show for the left side of the decimal, pad with 0's
 *     if needed.
 * @param {String} [opts.type = 'decimal'] choose a format type based on the locale from the following:
 *     decimal, percent, currency.
 * @param {String} [opts.locale] The locale to use, defaults to global locale setting.
 * @param {String} [opts.currency] (required if type is currency, ignored otherwise)
 *     an ISO4217 currency code, a three letter sequence like "USD"
 * 
 * @param {String} [opts.pattern] (Deprecated) Legacy regex-like pattern for number formatting, not
 *     recommended for use.
 *
 */
export function formatNumber(n, opts) {
    let fmt;
    let truncPlaces;
    let value;

    opts = preprocessNumberOptions(opts);

    if (opts.round === false) {
        // If we're formatting a percent, we up the truncation places by 2, since the number
        // formatter will (I guess depending on locale... sigh) multiply by 100.
        //
        truncPlaces = (opts.maximumFractionDigits || 0) + (opts.style === 'percent' ? 2 : 0);
        n = trunc(n, truncPlaces);
    }

    try {
        fmt = new Intl.NumberFormat(opts.locale, opts);
    }
    catch (e) {
        fmt = new Intl.NumberFormat('en', opts);
    }

    value = fmt.format(n);
    value = stripDirectionalityMarkers(value);

    if (opts.style === 'percent') {
        value = stripSpaceFromPercent(value);
    }

    if (opts.minDigits) {
        const isNegative = value.startsWith('-');

        if (isNegative) {
            // Remove the negative sign for now and add it back at the end
            // once the padding has been added.
            //
            value = value.replace(/^-/, '');
        }

        const whole = value.split(getDecimalSeparator(opts.locale))[0];

        let padding = opts.minDigits - whole.length;

        while (padding > 0) {
            value = '0' + value;
            --padding;
        }

        value = isNegative ? `-${value}` : value;
    }

    if (opts.format) {
        value = exformat(opts.format, { value: value });
    }
    if (opts.template) {
        value = exformat(opts.template, { value: value });
    }

    return value;
}

const formatItems = {
    selectors: {
        time: ['hour', 'minute', 'second', 'timeZoneName'],
        date: ['weekday', 'era', 'year', 'month', 'day']
    },
    dateFormatItems: {
        MMMd: {
            month: 'short',
            day: 'numeric'
        },
        'MMM yyyy': {
            month: 'short',
            year: 'numeric'
        },
        'MMMd yyyy': {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        },
    },
    timeFormatItems: {
        ha: {
            hour: 'numeric',
            minute: 'numeric'
        },
        hms: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    },
    canned: {
        short: {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        medium: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        },
        full: {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    }
};

function preprocessDateOptions(opts) {
    var rv = {},
        selectorItems = formatItems.selectors[opts.selector],
        timeKey = opts.timeFormatItem || opts.timePattern,
        dateKey = opts.dateFormatItem || opts.datePattern,
        timeFormatItem = formatItems.timeFormatItems[timeKey] || {},
        dateFormatItem = formatItems.dateFormatItems[dateKey] || {};

    if (opts.formatLength) {
        rv = extend({}, formatItems.canned[opts.formatLength]);
    }

    rv = extend(rv, timeFormatItem);
    rv = extend(rv, dateFormatItem);

    // Default to "short"
    //
    if (keys(rv).length === 0) {
        rv = extend({}, formatItems.canned.short);
    }

    rv = extend(rv, opts);

    // If a selector was specified, only use items belonging to that selector.
    //
    if (selectorItems) {
        rv = pick(rv, selectorItems);
    }

    return rv;
}

///---------------------------------------------------------------------------------------------
///
/// Format a JavaScript Date object.
///
/// PARAMETERS:
///    [Date] date: the date/time to format
///    [Object] opts: (optional) various controls over the formatting output. 'fooPattern' options
///                   will be used if present, then 'fooFormatItem' options, then 'formatLength'
///
///        [String] datePattern: (optional) override the pattern for the time format with this string
///        [String] timePattern: (optional) override the pattern for the date format with this string
///        [String] dateFormatItem: (optional) A CLDR dateFormatItem string (See notes for a list)
///        [String] timeFormatItem: (optional) override the pattern for the date format with this string
///        [String] formatLength: (optional: 'short') choice of long, short, medium or full.
///        [String] selector: (optional: both used) choice of 'time','date'
///
/// --------------------------------------------------------------------------------------------

const defaultLocal = 'en';

export function formatDate(d, opts) {
    opts = preprocessOptions(opts);

    var formatOpts = preprocessDateOptions(opts),
        fmt,
        rv;

    if (opts.locale) {
        try {
            fmt = new Intl.DateTimeFormat(opts.locale, formatOpts);
        }
        catch (e) {
            fmt = new Intl.DateTimeFormat(defaultLocal, formatOpts);
        }
    }
    else {
        fmt = new Intl.DateTimeFormat(defaultLocal, formatOpts);
    }

    if (typeof d === 'string') {
        // Use moment.js instead of Date.parse here. It'll try some ISO 8601
        // matching first, and if that fails, it'll fall back to Date.parse.
        d = moment(d).toDate();
    }
    else if (typeof d === 'number') {
        d = fromEpochSeconds(d);
    }
    else if (moment.isMoment(d)) {
        d = d.toDate();
    }
    else if (d instanceof OffsetDate) {
        d = d.getOffsetDate();
    }
    else if (d instanceof ProductionDay) {
        d = d.toDate();
    }
    else if (!(d instanceof Date)) {
        throw new TypeError(d + ' is not convertible to Date');
    }

    if (opts.iso) {
        rv = dateToJSON(d, extend({}, opts, { relative: true }));
    }
    else {
        if (fmt.formatToParts) {
            const formattedParts = fmt.formatToParts(d);

            rv = formattedParts.map(({type, value}) => {
                if (type === 'dayPeriod') {
                    // We want to lowercase the AM or PM bit by default
                    value = value.toLocaleLowerCase();
                }

                return value;
            }).join('');
        }
        else {
            rv = fmt.format(d);
            rv = stripDirectionalityMarkers(rv);
        }
    }

    if (('commaSeparator' in opts) && !opts.commaSeparator) {
        rv = rv.replace(/,/, '');
    }

    return rv;
}

/**
 * Convert a string in the format 'YYYY-MM-DD' to a Date object.
 *
 * @param  {String} dateString
 * @return {Date}
 */
export function fromISODateString(str) {
    // Use moment to ensure JavaScript doesn't try and apply the
    // locale timezone to the date after parsing.
    //
    return moment(str).toDate();
}
