import React from 'react';
import PropTypes from 'prop-types';
import SearchableSelectList from 'Common/components/SelectList/SearchableSelectList';
import { useOptionElements, getFullyQualifiedName, findOptionByFqn } from 'Common/components/LiveControls/FieldSelectControl/FieldSelectListUtil';
import { useTranslator } from 'Common/hooks/useTranslation';
function CategorySelectList(props) {
    const { name, options, onChange } = props;
    let { value: selectedOption } = props;

    const translator = useTranslator();

    const handleChange = (event) => {
        const { value: nextSelectedOptionFqn } = event.target;

        // Use the value to find the actual option selected
        const nextSelectedOption = findOptionByFqn(options, nextSelectedOptionFqn);

        onChange({
            target: {
                name: name,
                value: nextSelectedOption,
            },
        });
    };

    const selectedOptionFqn = getFullyQualifiedName(selectedOption);

    const optionElements = useOptionElements({
        optionGroups: options,
        collapsibleGroups: false,
    });

    return (
        <SearchableSelectList
            onChange={handleChange}
            searchBoxPlaceholder={translator('type_to_search_literal')}
            noSelectionText={translator('filterbox_no_selections_copy')}
            value={selectedOptionFqn}
        >
            {optionElements}
        </SearchableSelectList>
    );
}

const optionPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
}).isRequired;

const groupPropType = PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    entries: PropTypes.arrayOf(optionPropType).isRequired,
    name: PropTypes.string.isRequired,
}).isRequired;

CategorySelectList.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(groupPropType).isRequired,
    value: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
};

export default React.memo(CategorySelectList);
