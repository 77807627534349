import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonLike from 'Components/button/ButtonLike';
import ThemedCheckboxInput from 'Components/checkbox/ThemedCheckboxInput';

const StyledButtonLike = styled(ButtonLike)`
    position: relative;
    display: block;
    box-sizing: border-box;
    width: auto;
`;

const OptionContent = styled.div`
    display: inline-block;
`;

class SelectOptionWrapper extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        depth: PropTypes.number.isRequired,
        multiple: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    _handleClick = (event) => {
        event.target = {
            value: this.props.value,
        };

        this.props.onClick(event);
    };

    render() {
        const {
            value,
            selected,
            depth,
            multiple,
            onClick,
            children,
            ...otherProps
        } = this.props;

        let paddingLeft = 16 * depth;

        // Give a little extra padding for the checkbox, if needed
        if (multiple) {
            paddingLeft += 6;
        }

        const style = {
            paddingLeft: `${paddingLeft}px`,
        };

        return (
            <StyledButtonLike
                {...otherProps}
                Component="div"
                style={style}
                selected={!multiple && selected}
                onClick={this._handleClick}
            >
                {multiple && (
                    <ThemedCheckboxInput readOnly value={selected} />
                )}
                <OptionContent>
                    {children}
                </OptionContent>
            </StyledButtonLike>
        );
    }
}

export default SelectOptionWrapper;
