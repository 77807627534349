import { sendGetRequest } from 'Http/request';
import { getResources, resetResourcesRequest } from 'Common/util/ResourceCollection';

export function getEmailTemplates() {
    return getResources({
        resourceType: 'emailTemplates',
        requestId: 'getEmailTemplates',
        sendRequest: () => sendGetRequest('/api/v1/reports'),
    });
}

export function getEmailTemplateReset() {
    return resetResourcesRequest({
        resourceType: 'emailTemplates',
        requestId: 'getEmailTemplates',
    });
}

export default getEmailTemplates;
