import React from 'react';
import styled from 'styled-components';

import { elementsOfType } from 'Components/util/propTypesUtils';
import TableHeader from 'Components/table/TableHeader';
import TableBody from 'Components/table/TableBody';

const roundingRadius = '4px';

const ThemedTable = styled.table`
    border-collapse: collapse;
    border-spacing: 0px;
    font-size: 12px;
    font-weight: 400;

    input + div {
        border: 0;
    }

    button {
        border: 0;
    }

    tr td div div {
        font-size: 12px;
    }

    thead tr th:not(:first-child) {
        border-left: solid 1px white;
    }

    thead tr:last-child th:first-child {
        border-top-left-radius: ${roundingRadius};
    }

    thead tr:last-child th:last-child {
        border-top-right-radius: ${roundingRadius};
    }

    tfoot tr:last-child td {
        height: 12px;
        background-color: ${({ theme }) => theme.colors.palette.grey.manatee};
        border-bottom-right-radius: ${roundingRadius};
        border-bottom-left-radius: ${roundingRadius};
    }
`;

const Row = styled.tr`
        height: 12px;
        background-color: ${({ theme }) => theme.colors.palette.grey.manatee};

`;

function Table({ children, ...otherProps }) {
    const {
        noFooter,
        ...passedProps
    } = otherProps;

    const printFooter = () => {
        if (! noFooter) {
            return (
                <tfoot>
                    <Row>
                        <td colSpan="999" > </td>
                    </Row>
                </tfoot>
            );
        }
    };

    return (
        <ThemedTable {...passedProps}>
            {children}
            {printFooter()}
        </ThemedTable>
    );
}

Table.propTypes = {
    children: elementsOfType([TableHeader, TableBody]),
};

export default Table;
