import {
    useState,
    useEffect,
    useRef
} from 'react';
import useHover from './useHover';

const DEFAULT_HOVER_DELAY = 200;

/**
 * Given a target element and a delay, return whether the element is currently hovered
 * by the mouse and has been hovered for the number of milliseconds of delay.
 *
 * @param {Element} target The DOM element to detect hover for.
 * @param {Number} [delay=DEFAULT_HOVER_DELAY] Number of milliseconds of hover required before useHover
 *     return true.
 *
 * @returns {Booleans} True if the target element has been hovered for delay milliseconds.
 */
export default function useDelayedHover({
    target,
    delay = DEFAULT_HOVER_DELAY,
}) {
    const isHovered = useHover(target);

    const hoverTimeoutRef = useRef(null);
    const [sustainedHover, setSustainedHover] = useState(false);

    useEffect(() => {
        if (isHovered) {
            if (delay > 0) {
                if (!hoverTimeoutRef.current) {
                    hoverTimeoutRef.current = setTimeout(() => setSustainedHover(true), delay);
                }
            }
            else {
                setSustainedHover(true);
            }
        }
        else {
            if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
                hoverTimeoutRef.current = null;
            }

            setSustainedHover(false);
        }
    }, [isHovered, delay]);

    useEffect(() => {
        // On component unmount be sure we don't have a lingering hover timeout
        return () => {
            if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
            }
        };
    }, []);
    
    return sustainedHover;
}
