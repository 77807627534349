import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import uniqueId from 'lodash/uniqueId';
import CheckboxInput from './CheckboxInput';
import { Primary5 } from 'Common/components/typography';

const touchTargetPadding = '10px';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`;

const CheckboxWithTouchTarget = styled(CheckboxInput)`
    padding-top: ${touchTargetPadding};
    padding-bottom: ${touchTargetPadding};

    input[type="checkbox"] {
        padding-top: ${touchTargetPadding};
        padding-bottom: ${touchTargetPadding};
    }
`;

const Label = styled(Primary5).attrs({ as:'label' })`
    margin: 0;
    border: none;

    padding-left: 10px;
    padding-top: ${touchTargetPadding};
    padding-bottom: ${touchTargetPadding};

    user-select: none;

    color: ${({theme}) => theme.colors.darkText.highEmphasis};
    cursor: pointer;

    ${({disabled}) => disabled && css`
        color: ${({theme}) => theme.colors.darkText.disabled};
        cursor: default;
    `}
`;

export default function Checkbox(props) {
    const {
        id: idProp,
        label,
        className,
        name,
        value,
        disabled,
        onChange,
        onFocus,
        onBlur,
        ...otherProps
    } = props;

    const fallbackId = useRef(uniqueId('Checkbox_'));
    const id = idProp || fallbackId.current;
    const inputId = `${id}_input`;

    return (
        <Root
            {...otherProps}
            id={id}
            className={className}
        >
            <CheckboxWithTouchTarget
                id={inputId}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <Label htmlFor={inputId} disabled={disabled}>
                {label || ''}
            </Label>
        </Root>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

Checkbox.defaultProps = {
    id: '',
    className: '',
    name: '',
    value: false,
    disabled: false,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
};
