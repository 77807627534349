import { sendGetRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';

export const GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_FAILURE = 'GET_VERSION_FAILURE';

export const getVersion = () => requestActionCreator(
    {
        request: () => ({
            type: GET_VERSION_REQUEST,
        }),
        success: (payload) => ({
            type: GET_VERSION_SUCCESS,
            payload,
        }),
        failure: (error) => ({
            type: GET_VERSION_FAILURE,
            payload: { error },
        }),
    },
    () => sendGetRequest('/api/service/version')
);

export default getVersion;
