import { exformat } from 'Common/util/language/string';
import EnterpriseTranslationTable from './EnterpriseTranslationTable.json';

function flattenTable(nestedTable) {
    const flattenedTable = {};

    Object.entries(nestedTable).forEach(([ key, value ]) => {
        if (typeof value === 'object') {
            Object.entries(flattenTable(value)).forEach(([ subKey, subValue ]) => {
                flattenedTable[`${key}.${subKey}`] = subValue;
            });
        }
        else {
            flattenedTable[key] = value;
        }
    });

    return flattenedTable;
}

function createTranslationTable() {
    const flatTable = flattenTable(EnterpriseTranslationTable);
    const substitutionOverrides = {};

    function get(key) {
        if (typeof substitutionOverrides[key] !== 'undefined') {
            return substitutionOverrides[key];
        }
        else if (typeof flatTable[key] !== 'undefined') {
            return flatTable[key];
        }
        else {
            console.error(`Missing translation for ${key}`);
            return '[ ' + key + ' ]';
        }
    }

    // Go through the flat table and find any strings with substitutions
    // (e.g., "Example {{subtitute.key.name}} here") and put them into a map
    // with the keys properly substitued. This makes it so we don't need to do
    // this matching every time we want to get a translation value.
    Object.entries(flatTable)
        .filter(([ key, value ]) => {
            return value && value.indexOf('{{') !== -1;
        })
        .forEach(([ key, value ]) => {
            try {
                const replaceSubstitutionKey = (match, lookupKey) => {
                    const substituteValue = flatTable[lookupKey];

                    if (substituteValue) {
                        return substituteValue;
                    }
                    else {
                        throw new Error('Substitution key ' + lookupKey
                            + ' (within key ' + key + ') was not found.');
                    }
                };

                while (value.match(/{{([\s\S]+?)}}/)) {
                    value = value.replace(/{{([\s\S]+?)}}/, replaceSubstitutionKey);
                }
            }
            catch (e) {
                console.error(e);
            }

            substitutionOverrides[key] = value;
        });

    return {
        get,
    };
}

const translationTable = createTranslationTable();

export default function entTranslator(key, templateParams = null) {
    if (typeof key === 'object') {
        key = key.key;
    }

    let value = translationTable.get(key);

    if (typeof value === 'object') {
        value = value.value;
    }

    if (templateParams !== null) {
        if (typeof templateParams !== 'object') {
            console.warn(
                `Tranlation template params must be an object. Got a ${typeof templateParams} instead.\n` +
                `Key: ${key}\n`
            );
        }

        return exformat(value, templateParams);
    }
    else {
        return value;
    }
}
