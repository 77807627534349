import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import Notice from './Notice';

function SuccessNotice({ children, theme, ...otherProps }) {
    return (
        <Notice textColor={theme.colors.onSuccess} backgroundColor={theme.colors.success} {...otherProps}>
            {children}
        </Notice>
    );
}

SuccessNotice.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object.isRequired,
};

export default withTheme(SuccessNotice);
