import { sendPatchRequest } from 'Http/request';
import { patchResource, resetResourceRequest } from 'Common/util/ResourceCollection';

export function generateUserPatch(initialData, modifiedData) {
    const patchData = {};

    // Start with some cleanup
    modifiedData = {
        ...modifiedData,
        name: modifiedData.name.trim(),
    };

    // These are the basic fields that the user can modify
    const fields = [
        'name',
        'role',
        'active',
        'hierarchy_visibility',
    ];

    fields.forEach((key) => {
        if (initialData[key] !== modifiedData[key]) {
            patchData[key] = modifiedData[key];
        }
    });

    return patchData;
}

export function patchUser(userId, changes) {
    return patchResource({
        resourceType: 'users',
        requestId: 'patchUser',
        resourceId: userId,
        sendRequest: () => sendPatchRequest(`/api/v1/accounts/organization/users/${userId}`, {
            body: { ...changes }
        }),
    });
}

export function patchUserReset(userId) {
    return resetResourceRequest({
        resourceType: 'users',
        requestId: 'patchUser',
        resourceId: userId,
    });
}

export default patchUser;
