import { useCallback, useMemo, useState } from 'react';
import * as RequestStatus from 'Common/data/RequestStatus';
import isPlainObject from 'lodash/isPlainObject';

/**
 * Check the response of the request and let the developer know if they might
 * have messed something up.
 *
 * Note: This function only logs; it does not throw. It is intended to be used
 * only as a help to developers, not as legitimate validation.
 *
 * @param {*} result
 */
function checkRequestResult(result) {
    const codingMistakeMsg = 'This indicates a possible mistake in the request function.';

    if (!isPlainObject(result)) {
        console.error(
            `useRequestWithStatus: Request returned something that isn't a`
            + ` plain object.`
            + ` ${codingMistakeMsg}`
        );
    }
    else if (result?.error && result?.response) {
        // Let the developer know they might have messed something up.
        console.error(
            `useRequestWithStatus: Request returned values for both`
            + ` \`error\` and \`response\` properties.`
            + ` Because \`error\` is truthy, this response is`
            + ` interpreted as a failure and \`response\` is ignored.`
            + ` ${codingMistakeMsg}`
        );
    }
}

/**
 * A hook for managing the state of an async request in a consistent format.
 *
 * @param {function({ abortSignal: AbortSignal }): Promise<{ error?: *, response?: * }>} sendFn
 *      An async function that sends a request.
 * @returns {{
 *      send: function({ abortSignal: AbortSignal }): Promise,
 *      resend: function({ abortSignal: AbortSignal }): Promise,
 *      requestState: {
 *          status: RequestStatus,
 *          error?: *,
 *          response?: *,
 *      },
 * }}
 */
export default function useRequestWithStatus(sendFn) {
    const [ requestState, setRequestState ] = useState({
        status: RequestStatus.IDLE,
        error: null,
        response: null,
    });

    /**
     * Internal function with common request sending logic.
     */
    const _sendInternal = useCallback(async ({ isInitialRequest, abortSignal }) => {
        try {
            // Set as pending before sending the request.
            if (isInitialRequest ) {
                setRequestState({
                    status: RequestStatus.PENDING,
                    error: null,
                    response: null,
                });
            }

            const result = await sendFn({ abortSignal, isInitialRequest });

            if (abortSignal.aborted) {
                // Request was aborted, do nothing.
                return;
            }

            // Let the developer know if they might have messed something up.
            checkRequestResult(result);

            if (result?.error) {
                // Set the state to failed and include the error.
                setRequestState({
                    status: RequestStatus.FAILURE,
                    error: result?.error,
                    response: null,
                });
            }
            else {
                // Set the state to success.
                setRequestState({
                    status: RequestStatus.SUCCESS,
                    error: null,
                    response: result?.response,
                });
            }
        }
        catch (error) {
            console.error(error);
            console.error(
                'useRequestWithStatus: The above error was caught when sending'
                + ' the request.'
                + ' Generally, these errors should be caught within the function'
                + ' that sends the request so it can do proper error handling.'
            );
            // Set the state to failed and include the error.
            setRequestState({
                status: RequestStatus.FAILURE,
                error: error?.message || error,
                response: null,
            });
        }
    }, [ sendFn, setRequestState ]);
    
    const send = useCallback(async ({ abortSignal }) => {
        return _sendInternal({
            isInitialRequest: true,
            abortSignal,
        });
    }, [ _sendInternal ]);

    const resend = useCallback(async ({ abortSignal }) => {
        return _sendInternal({
            isInitialRequest: false, // Don't reset the state when pending
            abortSignal,
        });
    }, [ _sendInternal ]);

    return useMemo(() => ({
        send,
        resend,
        requestState,
    }), [ send, resend, requestState]);
}
