import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heading4 } from 'Common/components/typography';
import { generateFilterExpressionText, HUMAN_READABLE } from 'Common/components/FilterBuilderText/FilterBuilderUtils';
import { useTranslator } from 'Common/hooks/useTranslation';

const expressionIndent = 10;

/**
 * Take the formatted filters and expand them to a pretty format.
 * ie
 *    ( a = 1 )
 *       AND
 *    (
 *       ( b = 2 )
 *          OR
 *       ( c = 3 )
 *    )
 */

function FilterBuilderPrettyFormat({
    formattedFilters,
}) {
    const translator = useTranslator();

    const prettyFormattedText = useMemo(() => {
        const numberOfFilters = formattedFilters.length;
        const hasMultipleFilters = numberOfFilters > 1;

        return formattedFilters
            .map(({ expressions, combine_type }, filterIndex) => {
                const combineType = combine_type || 'or';

                // [FUTURUEHACK] combine_type:custom does not yet exist
                if (combineType === 'custom') {
                    throw 'This functionality has not yet been implemented';
                }

                const hasMultipleExpressions = expressions.length > 1;
                const expandedExpressions = expressions.reduce((expandedExps, exp, expIndex) => {
                    let expressionText = generateFilterExpressionText(exp, HUMAN_READABLE, translator);
                    const paddingLeft = hasMultipleFilters ? expressionIndent : 0;

                    if (hasMultipleExpressions) {
                        if (expIndex > 0) {
                            expandedExps.push((
                                <div key={`exp-combiner-${expIndex}`} style={{ paddingLeft, textAlign: "center" }}>{translator(`filter.${combineType}`).toUpperCase()}</div>
                            ));
                        }
                        expressionText = `( ${expressionText} )`;
                    }

                    expandedExps.push((
                        <div key={`exp-${expIndex}`} style={{ paddingLeft }}>{expressionText}</div>
                    ));

                    return expandedExps;
                }, []);

                if (hasMultipleFilters) {
                    return (
                        <div key={`filter-${filterIndex}`} >
                            {'( '}
                            {expandedExpressions}
                            {' )'}
                            {numberOfFilters !== (filterIndex + 1)
                                ? <div key={`filter-combiner-${filterIndex}`} style={{ textAlign: "center" }}>{translator(`filter.and`).toUpperCase()}</div>
                                : null
                            }
                        </div>
                    );
                }
                else {
                    return expandedExpressions;
                }
            });
    }, [formattedFilters, translator]);

    return (
        <div>
            <Heading4>{translator('filter.title')}</Heading4>
            <hr style={{ margin: '5px 0px 5px 0px'}}/>
            {prettyFormattedText}
        </div>
    );
}

FilterBuilderPrettyFormat.propTypes = {
    formattedFilters: PropTypes.arrayOf(
        PropTypes.shape({
            expressions: PropTypes.arrayOf(
                PropTypes.shape({
                    lhs: PropTypes.any.isRequired,
                    op: PropTypes.any.isRequired,
                    rhs: PropTypes.any.isRequired,
                })
            ).isRequired,
            combine_type: PropTypes.oneOf(['and', 'or'])
        })
    ).isRequired,
};

export default FilterBuilderPrettyFormat;
