import validateDuration from 'Common/data/validateDuration';
import validateNumber from 'Common/data/validateNumber';

// Seconds is the underlying value so
// we can't allow it to be greater than
// what's safe.
const maxHours = Math.trunc(Number.MAX_SAFE_INTEGER / 3600);

export default function validateFilters(filters, translator) {
    let errors = {};

    const validateExpression = (exp) => {
        const { fieldType, type, subType } = exp.lhs;
        let rhsError;

        if (fieldType === 'metric') {
            if (type === 'time_span') {
                rhsError = validateDuration(exp.rhs, {
                    integral: subType !== 'cycle_time',
                    maxHours,
                    translator: translator,
                });
            }
            else {
                const integral = type == 'uint64' || type == 'id' || subType === 'count';

                rhsError = validateNumber(exp.rhs, {
                    integral,
                    percentage: subType === 'percent',
                    allowEmpty: true,
                    translator: translator,
                });
            }
        }

        return rhsError ? {
            rhs: rhsError,
        } : undefined;
    };

    const sliceErrors = filters.slice.expressions.map(validateExpression);
    const groupErrors = filters.group.expressions.map(validateExpression);

    if (sliceErrors?.find(error => (error))) {
        errors.slice = sliceErrors;
    }

    if (groupErrors?.find(error => (error))) {
        errors.group = groupErrors;
    }

    return errors.slice || errors.group ? errors : undefined;
}
