import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Body5 } from 'Common/components/typography';
import themeColors from 'Common/theme/default';

const LayoutWrapper = styled.div`
    box-sizing: border-box;
    display: inline-flex;
    max-width: 100%;
    position: relative;
    padding: auto;
    min-height: 1.3em;

    ${({ $canEdit }) => $canEdit && css`
        cursor: text;
    `}
`;

const BaseTitle = styled(Body5)`
    color: ${props => props.textColor};
    border: none;
    border-bottom: 1px solid transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    overflow: hidden;
`;

const StyledInputTitle = styled(BaseTitle).attrs(props => (
    {
        as: 'input',
        title: props.value,
    }))`
    border-bottom-color: ${({theme}) => theme.colors.palette.grey.ash};
    outline: none;
    padding: 0;
    position: absolute;
    width: 100%;
    text-overflow: ellipsis;
    transition: border-bottom-color .250s ease-in-out;

    :focus {
        border-bottom-color: ${({theme}) => theme.colors.palette.black};
    }
    :not(:focus) {
        ::placeholder {
            color: ${props => props.textColor};
        }
    }
`;

const HiddenTitle = styled(BaseTitle).attrs(props => {
    let style = { minWidth: props.inputMinWidth };
    style.maxWidth = props.inputMaxWidth >= 0 ? props.inputMaxWidth : '100%';
    return {style};
})`
    box-sizing: border-box;
    opacity: 0;
    white-space: pre;

    /* Be sure to pass through pointer events to not steal clicks from the input */
    pointer-events: none;
`;

const ShownTitle = styled(BaseTitle).attrs(props => {
    let style = { minWidth: props.inputMinWidth };
    style.maxWidth = props.inputMaxWidth >= 0 ? props.inputMaxWidth : '100%';
    let attributes = {
        title: props.title ? props.title : undefined,
        style
    };
    return {attributes};
})`
    color: ${props => props.textColor};
    position: absolute;
    text-overflow: ellipsis;
    white-space: pre;
    width: 100%;
`;

/**
  * This component horizontally resizes an input or span based on the given value
  * and the width limitations. Its attempts to inherit its typography from its parent
  * so create a wrapper component around it to define those properties.
  */
export default class AutoResizeTitle extends React.PureComponent {

    static propTypes = {
        canEdit: PropTypes.bool.isRequired,
        defaultTitleText: PropTypes.string,
        minWidth: PropTypes.number,
        maxWidth: PropTypes.number, /** if no maxWidth assume its 100% width */
        textColor: PropTypes.string,
        type: PropTypes.string.isRequired,
        value: PropTypes.string,

        onChange: PropTypes.func,
    };

    static defaultProps = {
        defaultTitleText: '',
        minWidth: 150,
        textColor: themeColors.colors.palette.black,
        value: '',

        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.inputEl = React.createRef();
    }

    _handleBlur = (event) => {

        if (this.inputEl.current) {
            this.inputEl.current.scrollLeft = 0;
        }

        // if we blur when the current value of the input is the empty string,
        // we want to change the value to match the placeholder so we don't
        // save the empty string value as the title
        if (!this.inputEl.current?.value && this.props.onChange)
        {
            this.props.onChange({
                target: {
                    value: this._getPlaceholder(this.inputEl.current?.value, this.props.defaultTitleText)
                }
            });
        }
    };

    _handleClick = (event) => {
        // We are taking complete control over this click
        event.preventDefault();
        event.stopPropagation();

        if (this.props.canEdit && this.inputEl.current) {
            this.inputEl.current.focus();
        }
    };

    _getTitleValue = (value, defaultValue) => {
        // We want to preserve the empty string as a valid value
        // for the title if the user is actively editing it, so we
        // will not use the default value when actively editing the title
        return document.activeElement === this.inputEl.current
            ? value
            : value || defaultValue;
    };

    _getPlaceholder = (value, defaultValue) => {
        return value ? '' : defaultValue;
    };

    render() {
        const {
            canEdit,
            defaultTitleText,
            maxWidth,
            minWidth,
            onChange,
            textColor,
            value
        } = this.props;

        const placeholder = this._getPlaceholder(value, defaultTitleText);

        return (
            <LayoutWrapper
                $canEdit={canEdit}
                onClick={this._handleClick}
                onBlur={this._handleBlur}
            >
                {canEdit
                    ? <StyledInputTitle
                        textColor={textColor}
                        placeholder={placeholder}
                        value={this._getTitleValue(value, defaultTitleText)}
                        ref={this.inputEl}

                        onChange={onChange}
                        role='textbox'
                    />
                    : <ShownTitle
                        inputMaxWidth={maxWidth}
                        inputMinWidth={minWidth}
                        textColor={textColor}
                        title={value}
                        role='heading'
                    >
                        {value || defaultTitleText}
                    </ShownTitle>
                }
                <HiddenTitle
                    inputMaxWidth={maxWidth}
                    inputMinWidth={minWidth}
                >
                    {this._getTitleValue(value, defaultTitleText)}
                </HiddenTitle>
            </LayoutWrapper>
        );
    }
}
