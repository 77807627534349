import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Root = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 300ms 0ms;

    &.dark {
        background-color: rgba(0, 0, 0, 0.2);
    }
`;

export default class PopupMask extends React.Component {
    static propTypes = {
        maskRef: PropTypes.any,
        className: PropTypes.string,
        dark: PropTypes.bool,
        onClick: PropTypes.func,
        children: PropTypes.node,
    };

    static defaultProps = {
        dark: false,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            maskRef,
            className,
            onClick,
            dark,
            children,
        } = this.props;

        return (
            <Root
                ref={maskRef}
                className={classNames(className, { dark: dark })}
                onClick={onClick}
            >
                {children}
            </Root>
        );
    }
}
