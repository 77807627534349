import React from 'react';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentStandard';
import PageContentWide from 'Components/page/PageContentWide';
import PageTitle from 'Components/page/PageTitle';
import PageDescription from 'Components/page/PageDescription';
import CreateAccountForm from './internal/CreateAccountForm';
import DatauseDescription from 'Components/data-use-description/DataUseDescription';
import CreateAccountSuccessPage from './internal/CreateAccountSuccessPage';
import queryString from 'query-string';

export default class CreateAccountPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDone: false,
            email: '',
        };
    }

    _handleCreateAccountSuccess = ({ email }) => {
        this.setState({
            isDone: true,
            email: email,
        });
    };

    _getPendingLinkInformation(props) {
        const qs = props.location?.state?.from?.search;
        if (qs) {
            const params = queryString.parse(qs);
            if (params.asset_name && params.linking_token) {
                return {
                    asset_name: params.asset_name,
                    linking_token: params.linking_token,
                };
            }
        }

        return null;
    }

    render() {
        const { isDone, email } = this.state;
        const pendingLinkInfo = this._getPendingLinkInformation(this.props);

        if (isDone) {
            return (
                <CreateAccountSuccessPage
                    email={email}
                />
            );
        }
        else {
            return (
                <Page>
                    <PageTitle>Create Organization</PageTitle>
                    <PageDescription>
                        This will create a new XL Enterprise organization, with you as the administrator. A
                        company should have only one organization. <strong>Before creating an organization,
                        check with your IT and management to verify that there is NOT already an XL Enterprise
                        organization for your company.</strong>
                    </PageDescription>
                    <PageContent>
                        <CreateAccountForm
                            onSuccess={this._handleCreateAccountSuccess}
                            pendingLinkingToken={pendingLinkInfo?.linking_token}
                            pendingAssetName={pendingLinkInfo?.asset_name}
                        />
                    </PageContent>
                    <PageContentWide>
                        <DatauseDescription/>
                    </PageContentWide>
                </Page>
            );
        }
    }
}
