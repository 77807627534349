import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVersion } from 'Redux/actions';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentStandard';
import PageTitle from 'Components/page/PageTitle';
import RequestLoadingWrapper from 'Components/loading-indicator/RequestLoadingWrapper';
import styled from 'styled-components';
import { Body1 } from 'Components/typography/typography';

const Body = styled(Body1)``;

export class VersionPagePresentation extends React.Component {
    constructor(props) {
        super(props);
        this._renderVersions = this._renderVersions.bind(this);
    }

    componentDidMount() {
        this.props.onDidMount();
    }

    _renderVersions() {
        const { versionData } = this.props;
        return (
            <Body>
                Container: {versionData.container_tag} <br />
                Schema: {versionData.db_schema_version} <br />
                Deployment: {versionData.source_version} <br />
            </Body>
        );
    }

    render() {
        return (
            <Page>
                <PageTitle>About XL Enterprise</PageTitle>
                <PageContent>
                    <RequestLoadingWrapper
                        request={this.props.requests.getVersion}
                        render={this._renderVersions}
                    />
                </PageContent>
            </Page>
        );
    }
}

VersionPagePresentation.propTypes = {
    onDidMount: PropTypes.func.isRequired,
    versionData: PropTypes.shape({
        source_version: PropTypes.string.isRequired,
        container_tag: PropTypes.string.isRequired,
        db_schema_version: PropTypes.string.isRequired,
    }),
    requests: PropTypes.shape({
        getVersion: PropTypes.shape({
            status: PropTypes.string.isRequired,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
};

VersionPagePresentation.defaultProps = {
    versionData: null,
};

const mapStateToProps = (state, ownProps) => {
    const { version } = state;

    return {
        versionData: version.versionData || null,
        requests: version.requests,
    };
};

const mapDispatchToProps = {
    onDidMount: getVersion,
};

const VersionPage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VersionPagePresentation));

export default VersionPage;
