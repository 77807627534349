import {
    useState,
    useEffect,
    useCallback,
} from 'react';

/**
 * Given a target element, return whether the element is currently hovered
 * by the mouse by tracking mouseenter and mouseleave events.
 *
 * @param {Element} target A DOM element, if null useHover will always return false.
 *
 * @returns {Booleans} True if the target element is currently hovered.
 */
export default function useHover(target) {
    const [hovered, setHovered] = useState(false);

    const onMouseEnter = useCallback(() => {
        setHovered(true);
    }, []);

    const onMouseLeave = useCallback(() => {
        setHovered(false);
    }, []);

    useEffect(() => {
        if (target) {
            target.addEventListener('mouseenter', onMouseEnter);
            target.addEventListener('mouseleave', onMouseLeave);
        }

        return () => {
            if (target) {
                target.removeEventListener('mouseenter', onMouseEnter);
                target.removeEventListener('mouseleave', onMouseLeave);
            }
        };
    }, [target, onMouseEnter, onMouseLeave]);

    return hovered;
}
