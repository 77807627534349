// Color Palette - Standard colors to be used across the XL WPI.
// See: https://vornewiki.atlassian.net/wiki/spaces/XSD/pages/1287422332/WPI+Styles+-+Colors
//
const palette = {
    black: '#000000',
    white: '#FFFFFF',
    grey: {
        emperor: '#464646',
        manatee: '#6A6A6A',
        ash: '#909090',
        silver: '#DDDDDD',
        cloud: '#F2F2F2',
    },
    greyOpacity: {
        // These are generated "black opacity" versions of the straight colors for using as a
        // darkener on a transparent background.
        // https://aclarembeau.github.io/grayscale-to-opacity/
        emperor: 'rgba(0, 0, 0, 0.73)',
        manatee: 'rgba(0, 0, 0, 0.58)',
        ash: 'rgba(0, 0, 0, 0.44)',
        silver: 'rgba(0, 0, 0, 0.13)',
        cloud: 'rgba(0, 0, 0, 0.05)',
    },
    blue: {
        deep: '#075391',
        awesome: '#1070C0',
        summer: '#4D92CA',
        cruise: '#A5C8E4',
        pool: '#DCE9F4',
        ice: '#F0F5FA',
    },
    error: {
        red: '#D91111',
        helios: '#FDF2F2',
    },
    heatMap: {
        deepGreen: '#006400',
        green: '#228B22',
        amber: '#FFBF00',
        red: '#D91111',
        deepRed: '#8B0000',
    },
};

const transparent = 'rgba(255, 255, 255, 0)';
const green = '#4f9d47';

export default {
    colors: {
        separator: palette.grey.silver,

        // [FUTUREHACK] Color names still used by enterprise
        warning: 'rgba(255, 200, 0, 1)',
        warningSurface: 'rgba(255, 200, 0, 0.3)',
        onWarning: 'rgba(0, 0, 0, 0.87)',

        success: green,
        onSuccess: palette.white,
        editorGreen: green,

        primary: palette.blue.awesome,
        onPrimary: palette.white,
        // [FUTUREHACK END] Color names still used by enterprise

        trend: {
            good: palette.heatMap.green,
            bad: palette.heatMap.red,
            neutral: palette.grey.silver,
            none: transparent,
        },

        palette: palette,

        darkText: {
            highEmphasis: 'rgba(0, 0, 0, 0.82)',
            mediumEmphasis: 'rgba(0, 0, 0, 0.58)',
            disabled: 'rgba(0, 0, 0, 0.34)',
        },
    },
    layout: {
        widget: {
            horizontalPadding: '15px',
            verticalPadding: '10px',
        },
    },
};
