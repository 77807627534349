import styled from 'styled-components';

import TableRow from './TableRow';
import TableErrorColumn from './TableErrorColumn';
import { elementsOfType } from 'Components/util/propTypesUtils';

const TableErrorRow = styled(TableRow)`
    color: ${ p => p.theme.colors.error };
`;

TableErrorRow.propTypes = {
    children: elementsOfType([
        TableErrorColumn
    ]),
};

export default TableErrorRow;
