import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import PropTypes from 'prop-types';
import * as RequestStatus from 'Common/data/RequestStatus';

/**
 * An object representing the state of a request.
 *
 * @typedef {{ status: RequestStatus, error?: *, response?: * }} RequestStateType
 */

/**
 * A set of utility functions using the standard request state object.
 */
export default class RequestState {
    //
    // ParamTypes
    //

    static requestStatusParamType = ParamTypes.oneOf([
        RequestStatus.IDLE,
        RequestStatus.PENDING,
        RequestStatus.SUCCESS,
        RequestStatus.FAILURE,
    ]);

    /**
     * A ParamTypes definition for a RequestState object, but with a ParamType of
     * your choosing for the `response` field.
     *
     * @param {*} responseParamType 
     * @returns ParamTypes validatior.
     */
    static paramTypeWithResponseOf(responseParamType) {
        return ParamTypes.shape({
            status: RequestState.requestStatusParamType.isRequired,
            response: responseParamType,
            error: ParamTypes.any,
        });
    }

    static paramType = RequestState.paramTypeWithResponseOf(ParamTypes.any);

    //
    // PropTypes
    //

    static requestStatusPropType = PropTypes.oneOf([
        RequestStatus.IDLE,
        RequestStatus.PENDING,
        RequestStatus.SUCCESS,
        RequestStatus.FAILURE,
    ]);

    /**
     * A PropTypes definition for a RequestState object, but with a PropType of
     * your choosing for the `response` field.
     *
     * @param {*} responsePropType 
     * @returns PropTypes validatior.
     */
    static propTypeWithResponseOf(responsePropType) {
        return PropTypes.shape({
            status: RequestState.requestStatusPropType.isRequired,
            response: responsePropType,
            error: PropTypes.any,
        });
    }

    static propType = RequestState.propTypeWithResponseOf(PropTypes.any);

    //
    // Methods
    //

    static validate(requestState) {
        paramTest(requestState, RequestState.paramType, 'RequestState');
    }

    /**
     * Is the request completed (i.e., not IDLE or PENDING)?
     * @param {RequestStateType} requestState
     * @returns {boolean}
     */
    static isRequestCompleted(requestState) {
        RequestState.validate(requestState);

        return requestState.status !== RequestStatus.IDLE &&
            requestState.status !== RequestStatus.PENDING;
    }

    /**
     * Is the request pending?
     *
     * @param {RequestStateType} requestState
     * @returns {boolean}
     */
    static isRequestPending(requestState) {
        RequestState.validate(requestState);
        return requestState.status === RequestStatus.PENDING;
    }

    /**
     * Was the request completed and successful?
     *
     * @param {RequestStateType} requestState 
     * @returns {boolean}
     */
    static isRequestSuccessful(requestState) {
        RequestState.validate(requestState);
        return requestState.status === RequestStatus.SUCCESS;
    }

    /**
     * Was the request completed and failed?
     *
     * @param {RequestStateType} requestState 
     * @returns {boolean}
     */
    static isRequestFailure(requestState) {
        RequestState.validate(requestState);
        return requestState.status === RequestStatus.FAILURE;
    }

    /**
     * Are all of the requests completed (i.e., not IDLE or PENDING)?
     *
     * @param {Array<RequestStateType>} requestStates
     * @returns {boolean}
     */
    static areAllRequestsCompleted(requestStates) {
        if (!Array.isArray(requestStates)) {
            throw new Error('RequestState.areAllRequestsCompleted: Argument must be an array');
        }

        return requestStates.every((requestState) => RequestState.isRequestCompleted(requestState));
    }
}
