import NetworkError from './errors/NetworkError';
import ResponseFormatError from './errors/ResponseFormatError';

const defaultRequestConfig = {
    mode: 'cors',
    headers: {},
};

export default function Request(url, config) {
    if (typeof url !== 'string') {
        throw new Error('URL must be a string');
    }
    else if (url.length === 0 || url[0] !== '/') {
        throw new Error('Relative paths are not allowed. URL must begin with `/`');
    }

    async function send() {
        let response = null;
        let statusCode = null;
        let responseJson = null;

        // fetch should only throw an error if it's unable to make the request
        // It will NOT throw if the response is non-200
        try {
            response = await fetch(url, config);
        }
        catch (err) {
            throw new NetworkError('Network Error');
        }

        // request complete, get and parse the results
        try {
            statusCode = response.status;
            responseJson = await response.json();
        }
        catch (err) {
            throw new ResponseFormatError(`Server Error - ${err?.message || 'unknown'}`);
        }

        // 200 range is OK
        let ok = statusCode >= 200 && statusCode < 300;

        return {
            ok,
            statusCode,
            data: responseJson,
        };
    }

    function abort() {
        // TODO
    }

    return { send, abort };
}

/**
 * Merges two request config objects into one.
 *
 * @param {Object} defaultConfig default properties
 * @param {Object} config override properties
 * @returns {Object}
 */
function mergeTwoRequestConfigs(defaultConfig, config) {
    const mergedConfig = {
        ...defaultConfig,
        ...config
    };

    // merge headers
    mergedConfig.headers = {
        ...(defaultConfig.headers || {}),
        ...(config.headers || {}),
    };

    return mergedConfig;
}

/**
 * Merges multiple request config objects into one. Using the first argument as
 * the default config, each config object is merged in order.
 *
 * @param  {...Object} configs request configuration objects
 * @returns {Object} merged request configuration
 */

function mergeRequestConfigs(...configs) {
    if (configs.length === 0) {
        return {};
    }
    else if (configs.length === 1) {
        return configs;
    }
    else {
        let merged = mergeTwoRequestConfigs(configs[0], configs[1]);

        for (let i = 2; i < configs.length; i++) {
            merged = mergeTwoRequestConfigs(merged, configs[i]);
        }

        return merged;
    }
}

export function GetRequest(url, config) {
    const mergedConfig = mergeRequestConfigs(
        defaultRequestConfig,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        },
        config || {},
    );

    return new Request(url, mergedConfig);
}

export function PostRequest(url, config) {
    const mergedConfig = mergeRequestConfigs(
        defaultRequestConfig,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        },
        config || {},
    );

    // stringify JSON body
    if (mergedConfig.body && typeof mergedConfig.body === 'object') {
        mergedConfig.body = JSON.stringify(mergedConfig.body);
    }

    return new Request(url, mergedConfig);
}

export function PatchRequest(url, config) {
    const mergedConfig = mergeRequestConfigs(
        defaultRequestConfig,
        {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        },
        config || {},
    );

    // stringify JSON body
    if (mergedConfig.body && typeof mergedConfig.body === 'object') {
        mergedConfig.body = JSON.stringify(mergedConfig.body);
    }

    return new Request(url, mergedConfig);
}

export function PutRequest(url, config) {
    const mergedConfig = mergeRequestConfigs(
        defaultRequestConfig,
        {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        },
        config || {},
    );

    // stringify JSON body
    if (mergedConfig.body && typeof mergedConfig.body === 'object') {
        mergedConfig.body = JSON.stringify(mergedConfig.body);
    }

    return new Request(url, mergedConfig);
}

export function DeleteRequest(url, config) {
    const mergedConfig = mergeRequestConfigs(
        defaultRequestConfig,
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
            },
        },
        config || {},
    );

    return new Request(url, mergedConfig);
}

/**
 * Immediately construct and send a GET request.
 *
 * @param {string} url URL of the request
 * @param {Object} config Request configuration
 * @returns {Promise} Promise that resolves with the response
 */
export async function sendGetRequest(url, config) {
    let request = new GetRequest(url, config);
    return request.send();
}

/**
 * Immediately construct and send a POST request.
 *
 * @param {string} url URL of the request
 * @param {Object} config Request configuration
 * @returns {Promise} Promise that resolves with the response
 */
export async function sendPostRequest(url, config) {
    let request = new PostRequest(url, config);
    return request.send();
}

/**
 * Immediately construct and send a PATCH request.
 *
 * @param {string} url URL of the request
 * @param {Object} config Request configuration
 * @returns {Promise} Promise that resolves with the response
 */
export async function sendPatchRequest(url, config) {
    let request = new PatchRequest(url, config);
    return request.send();
}

/**
 * Immediately construct and send a PUT request.
 *
 * @param {string} url URL of the request
 * @param {Object} config Request configuration
 * @returns {Promise} Promise that resolves with the response
 */
export async function sendPutRequest(url, config) {
    let request = new PutRequest(url, config);
    return request.send();
}

/**
 * Immediately construct and send a DELETE request.
 *
 * @param {string} url URL of the request
 * @param {Object} config Request configuration
 * @returns {Promise} Promise that resolves with the response
 */
export async function sendDeleteRequest(url, config) {
    let request = new DeleteRequest(url, config);
    return request.send();
}
