import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Body5 } from '../typography';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    ${({ $hasDescription }) => $hasDescription && css`
        align-items: flex-start;
    `}
`;

const IconWrapper = styled.div`
    display: flex;
    font-size: 24px;
    padding-right: 12px;

    ${({ $hasDescription }) => $hasDescription && css`
        padding-top: 4px;
    `}
`;

const TextAndDescriptionWrapper = styled.div`
    width: 100%;
`;

const OptionText = styled(Body5).attrs({ as: 'div' })`
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.darkText.highEmphasis};
`;

const OptionDescription = styled(Body5).attrs({ as: 'div' })`
    margin-top: 2px;
    margin-bottom: 4px;
    max-width: 300px;
    white-space: normal;
    color: ${({ theme }) => theme.colors.darkText.mediumEmphasis};
`;

export default function BasicSelectOption(props) {
    const { value, searchValue, icon, description, children, ...otherProps } = props;

    const hasDescription = Boolean(description);

    return (
        <Root
            {...otherProps}
            $hasDescription={hasDescription}
        >
            {icon && (
                <IconWrapper
                    $hasDescription={hasDescription}
                >
                    {icon}
                </IconWrapper>
            )}
            <TextAndDescriptionWrapper>
                <OptionText>
                    {children}
                </OptionText>
                {description && (
                    <OptionDescription>
                        {description}
                    </OptionDescription>
                )}
            </TextAndDescriptionWrapper>
        </Root>
    );
}

BasicSelectOption.propTypes = {
    value: PropTypes.string.isRequired,
    searchValue: PropTypes.string,
    icon: PropTypes.node,
    description: PropTypes.node,
    children: PropTypes.node.isRequired,
};
