import { COLUMN_PROPERTIES } from 'Common/components/Dashboard/DashboardLayout/constants';

const ActionTypes = {
    INIT_PAGE: 'INIT_PAGE',
    ADD_WIDGET: 'ADD_WIDGET',
    DELETE_WIDGET: 'DELETE_WIDGET',
    SET_WIDGET_STF: 'SET_WIDGET_STF',
    SET_WIDGET_STATE: 'SET_WIDGET_STATE',
    SET_WIDGET_EXPORT_ITEM: 'SET_WIDGET_EXPORT_ITEM',
    SET_WIDGET_PRINT_STATUS: 'SET_WIDGET_PRINT_STATUS',
    SET_WIDGET_LIVE_CONTROLS_VISIBILITY: 'SET_WIDGET_LIVE_CONTROLS_VISIBILITY',
    MOVE_WIDGET: 'MOVE_WIDGET',
    ADD_SECTION: 'ADD_SECTION',
    DELETE_SECTION: 'DELETE_SECTION',
    MOVE_SECTION: 'MOVE_SECTION',
    MODIFY_SECTION: 'MODIFY_SECTION',
    SET_PAGE_CLEAN: 'SET_PAGE_CLEAN',
    SET_ALL_LIVE_CONTROL_VISIBILITIES: 'SET_ALL_LIVE_CONTROL_VISIBILITIES',
    SET_EXPLORATION_PATH: 'SET_EXPLORATION_PATH',
    SET_HIERARCHY_NODE: 'SET_PAGE_HIERARCHY_NODE',
    SET_SLICE_FILTERS: 'SET_SLICE_FILTERS',
    SET_GROUPED_FIELDS: 'SET_GROUPED_FIELDS',
    SET_FIELDS: 'SET_FIELDS',
    SET_SORT_FIELDS: 'SET_SORT_FIELDS',
    SET_TABLE_STRUCTURE: 'SET_TABLE_STRUCTURE',
    SET_TABLE_LEVEL: 'SET_TABLE_LEVEL',
    SET_TIME_RANGE: 'SET_TIME_RANGE',
};

function forEachWidgetsInLayout(state, func) {
    (state.pageStf?.sections || []).forEach(section => {
        (section.columns || []).forEach(column => {
            (column.widgets || []).forEach((widgetId) => {
                func(widgetId);
            });
        });
    });
}

function mapWidgetsInLayout(state, mapFunc) {
    return (state.pageStf.sections || []).map(section => {
        return {
            ...section,
            columns: (section.columns || []).map(column => {
                return {
                    ...column,
                    widgets: (column.widgets || []).map((widgetId) => {
                        return mapFunc(widgetId);
                    }),
                };
            }),
        };
    });
}

function isWithinInclusive(num, min, max) {
    return (num >= min && num <= max);
}

function validateSourceLocation(state, source) {
    if (!isWithinInclusive(source.sectionIndex, 0, state.pageStf.sections.length - 1)) {
        return `Source section index '${source.sectionIndex}' is out of bounds`;
    }

    const section = state.pageStf.sections[source.sectionIndex];

    if (!isWithinInclusive(source.columnIndex, 0, section.columns.length - 1)) {
        return `Source column index '${source.columnIndex}' is out of bounds`;
    }

    const column = section.columns[source.columnIndex];

    if (!isWithinInclusive(source.widgetIndex, 0, column.widgets.length - 1)) {
        return `Source widget index '${source.widgetIndex}' is out of bounds`;
    }

    return null;
}

function validateDestinationLocation(state, destination) {
    if (!isWithinInclusive(destination.sectionIndex, 0, state.pageStf.sections.length - 1)) {
        return `Destination section index '${destination.sectionIndex}' is out of bounds`;
    }

    const section = state.pageStf.sections[destination.sectionIndex];

    if (!isWithinInclusive(destination.columnIndex, 0, section.columns.length - 1)) {
        return `Destination column index '${destination.columnIndex}' is out of bounds`;
    }

    const column = section.columns[destination.columnIndex];

    // A destination widget index is allowed to be 1 higher than the highest
    // index (i.e., the length of the array) to allow appending the widget to
    // the end of the array.
    if (!isWithinInclusive(destination.widgetIndex, 0, column.widgets.length)) {
        return `Destination widget index '${destination.widgetIndex}' is out of bounds`;
    }

    return null;
}

const widgetInitialState = {
    widgetStf: null,
    widgetState: undefined,
    exportItem: null,
    dataAssets: null,
    printStatus: false,
};

function widgetReducer(state = widgetInitialState, action) {
    switch (action?.type) {
        case ActionTypes.ADD_WIDGET: {
            const { widgetStf } = action.payload;

            return {
                ...state,
                widgetStf: widgetStf,
                areLiveControlsVisible: true
            };
        }
        case ActionTypes.SET_WIDGET_STF: {
            const { widgetStf } = action.payload;

            return {
                ...state,
                widgetStf: widgetStf,
            };
        }
        case ActionTypes.SET_WIDGET_STATE: {
            const { widgetState } = action.payload;

            return {
                ...state,
                widgetState: widgetState,
            };
        }
        case ActionTypes.SET_WIDGET_EXPORT_ITEM: {
            const { exportItem } = action.payload;

            return {
                ...state,
                exportItem: exportItem,
            };
        }
        case ActionTypes.SET_WIDGET_PRINT_STATUS: {
            const { printStatus } = action.payload;

            return {
                ...state,
                printStatus: printStatus,
            };
        }
        case ActionTypes.SET_WIDGET_LIVE_CONTROLS_VISIBILITY: {
            const { areLiveControlsVisible } = action.payload;

            return {
                ...state,
                areLiveControlsVisible: areLiveControlsVisible,
            };
        }
        default: {
            return state;
        }
    }
}

const initialState = {
    pageStf: {
        sections: [],
        exploration_path: null,
        rangeSelection: null,
    },
    widgetsById: {},
    isPageDirty: false,
};

/**
 * Selector to get all widgets in section as a single array.
 *
 * @param {Object} state
 * @param {Number} sectionIndex
 */
export function selectSectionWidgets(state, sectionIndex) {
    return state.pageStf.sections[sectionIndex].columns.reduce(
        (widgets, column) => [...widgets, ...(column.widgets || [])],
        []
    );
}


export function dashboardLayoutReducer(state = initialState, action) {
    switch (action?.type) {
        case ActionTypes.INIT_PAGE: {
            const { pageStf } = action.payload;

            // Reset the state to the starting value.
            state = initialState;

            // Init time range if there is one.
            //
            let rangeSelection;

            if (pageStf.rangeSelection) {
                // Default the time range, if needed.
                if (pageStf.rangeSelection) {
                    rangeSelection = { ...pageStf.rangeSelection };
                }
                else {
                    rangeSelection = {
                        units: 'shift',
                        start: 0,
                        end: 0,
                    };
                }

            }
            else {
                rangeSelection = null;
            }

            // Init exploration_path if there is one.
            //
            let exploration_path;

            if (pageStf.exploration_path) {
                exploration_path = pageStf.exploration_path;
            }
            else {
                exploration_path = null;
            }

            const widgetsById = {};

            // Convert sections into a layout that holds just the widget IDs,
            // not the entire STF. Collect the widget STFs into `widgetsById`.
            //
            const sections = (pageStf.sections || []).map(section => {
                const columns = (section.columns || []).map(column => {
                    const widgets = (column.widgets || []).map((widgetStf) => {
                        const widgetId = widgetStf.widgetId;

                        // Add widget to `widgetsById`
                        widgetsById[widgetId] = {
                            ...widgetReducer(undefined, {}),
                            widgetStf: widgetStf,
                        };

                        return widgetId;
                    });
                    return { ...column, widgets };
                });
                return { ...section, columns };
            });

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    ...pageStf,
                    sections: sections,
                    rangeSelection: rangeSelection,
                    exploration_path: exploration_path,
                },
                widgetsById: widgetsById,
                isPageDirty: false,
            };
        }
        case ActionTypes.ADD_WIDGET: {
            const { widgetId, destination } = action.payload;

            // If there are no sections, a full-width one will be created by
            // default.
            let newSections = (
                (state.pageStf.sections && state.pageStf.sections?.length > 0)
                ? state.pageStf.sections
                : [
                    { type: 'full', columns: [{ widgets: [] }] }
                ]
            );

            // Use the modified state in case a section was added by default.
            const destinationValidation = validateDestinationLocation(
                {
                    ...state,
                    pageStf: {
                        ...state.pageStf,
                        sections: newSections,
                    },
                },
                destination
            );

            if (destinationValidation) {
                throw new Error(`Unable to add widget: ${destinationValidation}`);
            }

            newSections = newSections.map((section, sectionIndex) => {
                const isDestinationSection = destination.sectionIndex === sectionIndex;

                // If this section is not involved at all, leave it fully
                // unmodified.
                if (!isDestinationSection) {
                    return section;
                }

                let columns = section.columns;

                columns = columns.map((column, columnIndex) => {
                    const isDestinationColumn = (
                        isDestinationSection &&
                        destination.columnIndex === columnIndex
                    );

                    // If this column is not involved at all, leave it fully
                    // unmodified.
                    if (!isDestinationColumn) {
                        return column;
                    }

                    let widgets = column.widgets;

                    if (isDestinationColumn) {
                        // Add the widget to its new location
                        widgets = [...widgets];
                        widgets.splice(
                            destination.widgetIndex,
                            0,
                            widgetId,
                        );
                    }

                    return {
                        ...column,
                        widgets,
                    };
                });

                return {
                    ...section,
                    columns,
                };
            });

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    sections: newSections,
                },
                widgetsById: {
                    ...state.widgetsById,
                    [widgetId]: widgetReducer(undefined, action),
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.MOVE_WIDGET: {
            const { source, destination } = action.payload;

            const sourceValidation = validateSourceLocation(state, source);
            if (sourceValidation) {
                throw new Error(`Unable to move widget: ${sourceValidation}`);
            }

            const destinationValidation = validateDestinationLocation(state, destination);
            if (destinationValidation) {
                throw new Error(`Unable to move widget: ${destinationValidation}`);
            }

            // Get the ID of the widget being moved
            const widgetId = (
                state.pageStf.sections[source.sectionIndex]
                    .columns[source.columnIndex]
                    .widgets[source.widgetIndex]
            );

            // Special Case: Same starting and ending location
            if (
                source.sectionIndex === destination.sectionIndex &&
                source.columnIndex === destination.columnIndex &&
                source.widgetIndex === destination.widgetIndex
            ) {
                return state; // noop
            }

            const newSections = state.pageStf.sections.map((section, sectionIndex) => {
                const isSourceSection = source.sectionIndex === sectionIndex;
                const isDestinationSection = destination.sectionIndex === sectionIndex;

                // If this section is not involved at all, leave it fully
                // unmodified.
                if (!(isSourceSection || isDestinationSection)) {
                    return section;
                }

                let columns = section.columns;
    
                columns = columns.map((column, columnIndex) => {
                    const isSourceColumn = (
                        isSourceSection &&
                        source.columnIndex === columnIndex
                    );

                    const isDestinationColumn = (
                        isDestinationSection &&
                        destination.columnIndex === columnIndex
                    );

                    // If this column is not involved at all, leave it fully
                    // unmodified.
                    if (!(isSourceColumn || isDestinationColumn)) {
                        return column;
                    }

                    let widgets = column.widgets;
    
                    if (isSourceColumn) {
                        // Remove the widget from its starting location
                        widgets = [...widgets];
                        widgets.splice(
                            source.widgetIndex,
                            1
                        );
                    }
    
                    if (isDestinationColumn) {
                        // Add the widget to its new location
                        widgets = [...widgets];
                        widgets.splice(
                            destination.widgetIndex,
                            0,
                            widgetId,
                        );
                    }
    
                    return {
                        ...column,
                        widgets,
                    };
                });
    
                return {
                    ...section,
                    columns,
                };
            });

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    sections: newSections,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.DELETE_WIDGET: {
            const { source } = action.payload;

            const sourceValidation = validateSourceLocation(state, source);
            if (sourceValidation) {
                throw new Error(`Unable to delete widget: ${sourceValidation}`);
            }

            let deletedWidgetId = null;

            const newSections = state.pageStf.sections.map((section, sectionIndex) => {
                const isSourceSection = source.sectionIndex === sectionIndex;

                // If this section is not involved at all, leave it fully
                // unmodified.
                if (!isSourceSection) {
                    return section;
                }

                let columns = section.columns;

                columns = columns.map((column, columnIndex) => {
                    const isSourceColumn = (
                        isSourceSection &&
                        source.columnIndex === columnIndex
                    );

                    // If this column is not involved at all, leave it fully
                    // unmodified.
                    if (!isSourceColumn) {
                        return column;
                    }
    
                    // Remove the widget
                    let widgets = [...column.widgets];
                    [ deletedWidgetId ] = widgets.splice(
                        source.widgetIndex,
                        1
                    );
    
                    return {
                        ...column,
                        widgets,
                    };
                });
    
                return {
                    ...section,
                    columns,
                };
            });

            // Remove the widget from `widgetsById`
            const newWidgetsById = { ...state.widgetsById };
            delete newWidgetsById[deletedWidgetId];

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    sections: newSections,
                },
                widgetsById: newWidgetsById,
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_WIDGET_STF: {
            const { widgetId } = action.payload;

            if (!state.widgetsById[widgetId]) {
                throw new Error(`Unable to set widget STF: Widget with ID '${widgetId}' not found.`);
            }

            return {
                ...state,
                widgetsById: {
                    ...state.widgetsById,
                    [widgetId]: widgetReducer(state.widgetsById[widgetId], action),
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_WIDGET_STATE: {
            const { widgetId } = action.payload;

            if (!state.widgetsById[widgetId]) {
                throw new Error(`Unable to set widget state: Widget with ID '${widgetId}' not found.`);
            }

            return {
                ...state,
                widgetsById: {
                    ...state.widgetsById,
                    [widgetId]: widgetReducer(state.widgetsById[widgetId], action),
                },
            };
        }
        case ActionTypes.SET_WIDGET_EXPORT_ITEM: {
            const { widgetId } = action.payload;

            if (!state.widgetsById[widgetId]) {
                throw new Error(`Unable to set widget export item: Widget with ID '${widgetId}' not found.`);
            }

            return {
                ...state,
                widgetsById: {
                    ...state.widgetsById,
                    [widgetId]: widgetReducer(state.widgetsById[widgetId], action),
                },
            };
        }
        case ActionTypes.SET_WIDGET_PRINT_STATUS: {
            const { widgetId } = action.payload;

            if (!state.widgetsById[widgetId]) {
                throw new Error(`Unable to set widget print status: Widget with ID '${widgetId}' not found.`);
            }

            return {
                ...state,
                widgetsById: {
                    ...state.widgetsById,
                    [widgetId]: widgetReducer(state.widgetsById[widgetId], action),
                },
            };
        }
        case ActionTypes.SET_WIDGET_LIVE_CONTROLS_VISIBILITY: {
            const { widgetId } = action.payload;

            if (!state.widgetsById[widgetId]) {
                throw new Error(`Unable to set widget live control visibility: Widget with ID '${widgetId}' not found.`);
            }

            return {
                ...state,
                widgetsById: {
                    ...state.widgetsById,
                    [widgetId]: widgetReducer(state.widgetsById[widgetId], action),
                },
            };
        }
        case ActionTypes.ADD_SECTION: {
            const { type, sectionIndex } = action.payload;

            let newSections = [ ...state.pageStf.sections ];
            newSections.splice((sectionIndex + 1), 0, { type, columns: [{ widgets: [] }] });

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    sections: newSections,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.DELETE_SECTION: {
            const { sectionIndex } = action.payload;

            if (!state.pageStf.sections || !state.pageStf.sections[sectionIndex]) {
                throw 'Unable to delete section: invalid index';
            }

            if (state.pageStf.sections.length < 2) {
                throw 'Can not delete last section';
            }

            // Get IDs of all the widgets in the section
            const deletedWidgetIds = [];
            state.pageStf.sections[sectionIndex].columns.forEach((column) => {
                const widgets = column.widgets;
                widgets.forEach((widgetId) => {
                    deletedWidgetIds.push(widgetId);
                });
            });

            const newSections = state.pageStf.sections.filter((_, index) => index !== sectionIndex);

            const newWidgetsById = { ...state.widgetsById };

            deletedWidgetIds.forEach((widgetId) => {
                delete newWidgetsById[widgetId];
            });

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    sections: newSections,
                },
                widgetsById: newWidgetsById,
                isPageDirty: true,
            };
        }
        case ActionTypes.MOVE_SECTION: {
            const { sectionSrcIndex, sectionDestIndex } = action.payload;

            if (!state.pageStf.sections || !state.pageStf.sections[sectionSrcIndex]) {
                throw 'Unable to move section: invalid src index';
            }
            if (!state.pageStf.sections || !state.pageStf.sections[sectionDestIndex]) {
                throw 'Unable to move section: invalid dest index';
            }

            const sectionToMove = state.pageStf.sections[sectionSrcIndex];
            const newSections = state.pageStf.sections.filter((_, index) => sectionSrcIndex !== index);
            newSections.splice(sectionDestIndex, 0, sectionToMove);

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    sections: newSections,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.MODIFY_SECTION: {
            const { sectionIndex, sectionType, sectionHeading } = action.payload;

            if (!state.pageStf.sections || !state.pageStf.sections[sectionIndex]) {
                throw 'Unable to modify section: invalid section index';
            }

            const newSections = [...state.pageStf.sections];

            const newSection = {
                type: state.pageStf.sections[sectionIndex].type,
                heading: sectionHeading === undefined ? state.pageStf.sections[sectionIndex].heading : sectionHeading,
                columns: state.pageStf.sections[sectionIndex].columns,
            };

            if (sectionType && newSection.type !== sectionType) {
                const widgets = selectSectionWidgets(state, sectionIndex);
                const numColumns = COLUMN_PROPERTIES[sectionType].num;
                const numWidgetsPerColumn = Math.ceil(widgets.length / numColumns);

                // Make the empty columns and fill them in with widgets.
                const newColumns = new Array(numColumns).fill(null).map((_, columnIndex) => {
                    const sliceStart = columnIndex * numWidgetsPerColumn;

                    const columnWidgets = widgets.slice(
                        sliceStart,
                        sliceStart + numWidgetsPerColumn
                    );

                    return {
                        widgets: columnWidgets,
                    };
                });

                newSection.type = sectionType;
                newSection.columns = newColumns;
            }

            newSections[sectionIndex] = newSection;
            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    sections: newSections,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_PAGE_CLEAN: {
            return {
                ...state,
                isPageDirty: false,
            };
        }
        case ActionTypes.SET_ALL_LIVE_CONTROL_VISIBILITIES: {
            const areLiveControlsVisible = action.payload.areLiveControlsVisible;

            const widgetAction = {
                type: ActionTypes.SET_WIDGET_LIVE_CONTROLS_VISIBILITY,
                payload: {
                    areLiveControlsVisible: areLiveControlsVisible,
                }
            };
            let newWidgets = {};

            forEachWidgetsInLayout(state, (widgetId) => {
                newWidgets[widgetId] = widgetReducer(state.widgetsById[widgetId], widgetAction);
            });

            return {
                ...state,
                widgetsById: {
                    ...newWidgets,
                },
            };
        }
        case ActionTypes.SET_EXPLORATION_PATH: {
            const { explorationPath } = action.payload;

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: explorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_HIERARCHY_NODE: {
            const { hierarchyNode } = action.payload;
            let newAssetControl = {
                ...state.pageStf.exploration_path.asset_control,
                root: hierarchyNode,
            };

            if (!hierarchyNode) {
                delete newAssetControl.root;
            }
            // [FUTUREHACK]
            // unset level if it assets, this is only for all production
            // as it is the only page that uses page level
            if (Number.isFinite(newAssetControl?.level) && newAssetControl?.layout !== 'list') {
                newAssetControl.level = '';
            }

            const newExplorationPath = {
                ...state.pageStf.exploration_path,
                asset_control: newAssetControl,
            };

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: newExplorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_SLICE_FILTERS: {
            const { sliceFilters } = action.payload;
            const newExplorationPath = {
                ...state.pageStf.exploration_path,
                slice_filter: sliceFilters,
            };

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: newExplorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_GROUPED_FIELDS: {
            const { groupedFields } = action.payload;
            const newFieldSet = {
                ...state.pageStf.exploration_path.field_set,
                grouped_fields: groupedFields
            };
            const newExplorationPath = {
                ...state.pageStf.exploration_path,
                field_set: newFieldSet
            };

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: newExplorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_FIELDS: {
            const { fields } = action.payload;
            // lets keep the sort columns up-to-date
            const fieldsByName = fields.reduce((acc, columnField) => {
                acc[columnField.name] = columnField;
                return acc;
            }, {});
            const newFieldSet = {
                ...state.pageStf.exploration_path.field_set,
                fields,
                sort: (state.pageStf.exploration_path.field_set.sort || [])
                    .filter(sortRule => (sortRule.isCategoriesAll || fieldsByName[sortRule.column] ))
            };
            const newExplorationPath = {
                ...state.pageStf.exploration_path,
                field_set: newFieldSet
            };

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: newExplorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_SORT_FIELDS: {
            const { fields } = action.payload;
            const newFieldSet = {
                ...state.pageStf.exploration_path.field_set,
                sort: fields
            };
            const newExplorationPath = {
                ...state.pageStf.exploration_path,
                field_set: newFieldSet
            };

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: newExplorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_TABLE_STRUCTURE: {
            const { tableStructure } = action.payload;
            const newExplorationPath = {
                ...state.pageStf.exploration_path,
                asset_control: {
                    ...state.pageStf.exploration_path.asset_control,
                    layout: tableStructure,
                },
            };

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: newExplorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_TABLE_LEVEL: {
            const { tableLevel } = action.payload;
            const newExplorationPath = {
                ...state.pageStf.exploration_path,
                asset_control: {
                    ...state.pageStf.exploration_path.asset_control,
                    level: tableLevel,
                },
            };

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    exploration_path: newExplorationPath,
                },
                isPageDirty: true,
            };
        }
        case ActionTypes.SET_TIME_RANGE: {
            const { timeRange } = action.payload;

            return {
                ...state,
                pageStf: {
                    ...state.pageStf,
                    rangeSelection: timeRange,
                },
                isPageDirty: true,
            };
        }
        default: {
            return state;
        }
    }
}

/**
 * Selector to get the sections/columns/widgets of the page.
 * 
 * NOTE: This function returns widgets only as widget IDs.
 * 
 * @param {Object} state
 * @returns {Object}
 */
export function selectSections(state) {
    return state.pageStf.sections;
}

/**
 * Select the widget ID at a location in the layout
 *
 * @param {Object} state
 * @param {Object} location
 * @param {Number} location.sectionIndex
 * @param {Number} location.columnIndex
 * @param {Number} location.widgetIndex
 *
 * @returns {String} widget ID
 */
 export function selectWidgetIdAtLocation(state, location) {
    const {
        sectionIndex,
        columnIndex,
        widgetIndex,
    } = location;

    return state.pageStf.sections[sectionIndex]?.columns?.[columnIndex]?.widgets?.[widgetIndex];
}

/**
 * Selector to get the STF for a widget.
 *
 * @param {Object} state
 * @param {String} widgetId
 * @returns {Object}
 */
export function selectWidgetStf(state, widgetId) {
    return state.widgetsById[widgetId]?.widgetStf;
}

/**
 * Selector to get the state for a widget.
 *
 * @param {Object} state
 * @param {String} widgetId
 * @returns {Object}
 */
export function selectWidgetState(state, widgetId) {
    return state.widgetsById[widgetId]?.widgetState;
}

/**
 * Selector to get the export item for a widget.
 *
 * @param {Object} state
 * @param {String} widgetId
 * @returns {Object}
 */
export function selectWidgetExportItem(state, widgetId) {
    return state.widgetsById[widgetId]?.exportItem;
}

/**
 * Selector to get the page's export items.
 *
 * @param {Object} state
 * @returns {Object[]}
 */
export function selectWidgetExportItems(state) {
    const items = [];

    forEachWidgetsInLayout(state, (widgetId) => {
        const exportItem = selectWidgetExportItem(state, widgetId);
        if (exportItem) {
            items.push({ ...exportItem, widgetId });
        }
    });

    return items;
}

/**
 * Selector to get a widget's data assets.
 *
 * @param {Object} state
 * @returns {[]|null}
 */
export function selectWidgetDataAssets(state, widgetId) {
    return state.widgetsById[widgetId]?.dataAssets;
}

/**
 * Get all of the page's data assets, with ability to filter on widget stf.
 *
 * @param {Object} state
 * @param {Function} shouldInclude based on the widget's stf
 *                                 if we include this widget's data assets
 * @param {Object} reportHierarchyNode
 * @param {String} pageType
 * @returns {String[]}
 */
function getWidgetsHierarchyNodeNames(state, shouldInclude, reportHierarchyNode, pageType) {
    let hierarchyNodeMap = {};
    let hierarchyNodesArray = [];
    if (pageType === 'all-production') {
        hierarchyNodesArray.push(state.pageStf.exploration_path?.asset_control?.root || reportHierarchyNode?.name || 'self');
    }
    else {
        forEachWidgetsInLayout(state, (widgetId) => {
            // [FUTUREHACK] enforce self even if there are no specific hierarchy nodes
            const widgetStf = selectWidgetStf(state, widgetId);
            const hiearchyNode = widgetStf?.exploration_path?.asset_control?.root || widgetStf?.asset_control?.root || reportHierarchyNode?.name || 'self';

            if (hiearchyNode && !hierarchyNodeMap[hiearchyNode] && shouldInclude(widgetStf)) {
                hierarchyNodeMap[hiearchyNode] = true;
                hierarchyNodesArray.push(hiearchyNode);
            }
        });
    }

    // If there are no widgets which report a hierarchy node default to self
    if (hierarchyNodesArray.length === 0) {
        hierarchyNodesArray.push('self');
    }
    
    return hierarchyNodesArray;
}

/**
 * Selector to get all of the page's data assets.
 *
 * @param {Object} state
 * @param {Object} reportHierarchyNode
 * @param {String} pageType
 * @returns {String[]}
 */
export function selectPageWidgetsHierarchyNodeNames(state, reportHierarchyNode, pageType) {
    return getWidgetsHierarchyNodeNames(state, (stf) => true, reportHierarchyNode, pageType);
}

/**
 * Selector to get the page's data assets which affect the page time range.
 *
 * @param {Object} state
 * @param {Object} reportHierarchyNode
 * @param {String} pageType
 * @returns {String[]}
 */
 export function selectPageWidgetsHierarchyNodeNamesForPageTimeRange(state, reportHierarchyNode, pageType) {
    return getWidgetsHierarchyNodeNames(state, (stf) => (!stf?.rangeSelection), reportHierarchyNode, pageType);
}

/**
 * Selector to get a widget's print status.
 *
 * @param {Object} state
 * @returns {Boolean}
 */
 export function selectWidgetPrintStatus(state, widgetId) {
    return state.widgetsById[widgetId]?.printStatus;
}

/**
 * Selector to get a widget's live control visibility.
 *
 * @param {Object} state
 * @returns {Bool}
 */
 export function selectWidgetLiveControlsVisibility(state, widgetId) {
    return state.widgetsById[widgetId]?.areLiveControlsVisible;
}

/**
 * Selector to get the page's print status.
 *
 * @param {Object} state
 * @returns {Boolean}
 */
 export function selectPagePrintStatus(state) {
    let allWidgetsReady = true;

    forEachWidgetsInLayout(state, (widgetId) => {
        const widgetPrintStatus = selectWidgetPrintStatus(state, widgetId);

        if (!widgetPrintStatus) {
            allWidgetsReady = false;
        }
    });

    return allWidgetsReady;
}

/**
 * Selector to get the page's STF. Usually for saving.
 *
 * @param {Object} state
 * @returns {object}
 */
export function selectPageStf(state) {
    const stfObject = {
        ...state.pageStf,
        sections: mapWidgetsInLayout(state, (widgetId) => {
            let widgetStf = { ...selectWidgetStf(state, widgetId) };
            delete widgetStf.widgetId;
            return widgetStf;
        }),
    };

    // Delete exploration_path from STF if it's not set
    if (!stfObject.exploration_path) {
        delete stfObject.exploration_path;
    }

    // Delete rangeSelection from STF if it's not set
    if (!stfObject.rangeSelection) {
        delete stfObject.rangeSelection;
    }

    return stfObject;
}

/**
 * Selector to return true if any widgets have visible live controls
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export function selectAnyLiveControlsVisible(state) {
    let anyVisible = false;

    forEachWidgetsInLayout(state, (widgetId) => {
        anyVisible = selectWidgetLiveControlsVisibility(state, widgetId) || anyVisible;
    });

    return anyVisible;
}

/**
 * Selector to return true if any widgets have hidden live controls
 *
 * @param {Object} state
 * @returns {Boolean}
 */
 export function selectAnyLiveControlsHidden(state) {
    let anyHidden = false;

    forEachWidgetsInLayout(state, (widgetId) => {
        anyHidden = !selectWidgetLiveControlsVisibility(state, widgetId) || anyHidden;
    });

    return anyHidden;
}

/**
 * Selector for the page-level exploration path.
 *
 * @param {Object} state
 * @returns {Object|null} Exploration path STF
 */
export function selectExplorationPath(state) {
    return state.pageStf.exploration_path;
}

/**
 * Selector for the page-level fields.
 *
 * @param {Object} state
 * @returns {Array|null} Exploration path STF fields
 */
 export function selectFields(state) {
    return state.pageStf.exploration_path?.field_set?.fields;
}

/**
 * Selector for the page-level filters.
 *
 * @param {Object} state
 * @returns {Object|null} Exploration path STF filters
 */
 export function selectSliceFilters(state) {
    return state.pageStf.exploration_path?.slice_filter;
}

/**
 * Selector for the page-level grouped fields.
 *
 * @param {Object} state
 * @returns {Object|null} Exploration path STF grouped fields
 */
 export function selectGroupedFields(state) {
    return state.pageStf.exploration_path?.field_set?.grouped_fields;
}

/**
 * Selector for the page-level hierarchy node.
 *
 * @param {Object} state
 * @returns {Object|null} Exploration path STF hierarchy node
 */
 export function selectHierarchyNode(state) {
    return state.pageStf.exploration_path?.asset_control?.root;
}

/**
 * Selector for the page-level table structure.
 *
 * @param {Object} state
 * @returns {Object|null} Exploration path STF table sturcture
 */
 export function selectTableStructure(state) {
    return state.pageStf.exploration_path?.asset_control?.layout;
}

/**
 * Selector for the page-level time range.
 *
 * @param {Object} state
 * @returns {Object|null} Time range STF
 */
export function selectTimeRange(state) {
    return state.pageStf.rangeSelection;
}

/**
 * Selector for if the page is dirty (i.e., unsaved changes).
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export function selectIsPageDirty(state) {
    return state.isPageDirty;
}

/**
 * Action creator to initialize a page
 *
 * @param {Object} pageStf
 * @returns {Object} Init page action
 */
export function initPage(pageStf) {
    return {
        type: ActionTypes.INIT_PAGE,
        payload: {
            pageStf,
        },
    };
}

/**
 * Action creator to move a widget within the layout
 *
 * @param {Object} source
 * @param {Number} source.sectionIndex
 * @param {Number} source.columnIndex
 * @param {Number} source.widgetIndex
 * @param {Object} destination
 * @param {Number} destination.sectionIndex
 * @param {Number} destination.columnIndex
 * @param {Number} destination.widgetIndex
 *
 * @returns {Object} Move widget action
 */
export function moveWidget(source, destination) {
    return {
        type: ActionTypes.MOVE_WIDGET,
        payload: {
            source,
            destination,
        },
    };
}

/**
 * Action creator to add a widget to the layout
 *
 * @param {String} widgetId
 * @param {Object} destination
 * @param {Number} destination.sectionIndex
 * @param {Number} destination.columnIndex
 * @param {Number} destination.widgetIndex
 * @param {Object} widgetStf
 *
 * @returns {Object} Add widget action
 */
export function addWidget(widgetId, destination, widgetStf) {
    return {
        type: ActionTypes.ADD_WIDGET,
        payload: {
            widgetId,
            destination,
            widgetStf,
        },
    };
}

/**
 * Action creator to delete a widget within the layout
 *
 * @param {Object} source
 * @param {Number} source.sectionIndex
 * @param {Number} source.columnIndex
 * @param {Number} source.widgetIndex
 *
 * @returns {Object} Delete widget action
 */
export function deleteWidget(source) {
    return {
        type: ActionTypes.DELETE_WIDGET,
        payload: {
            source,
        },
    };
}

/**
 * Action creator to set a widget's STF within the layout
 *
 * @param {String} widgetId
 * @param {Object} widgetStf
 *
 * @returns {Object} Set widget STF action
 */
export function setWidgetStf(widgetId, widgetStf) {
    return {
        type: ActionTypes.SET_WIDGET_STF,
        payload: {
            widgetId,
            widgetStf,
        },
    };
}

/**
 * Async action creator to set/update a widget's STF based on the current STF.
 *
 * @param {String} widgetId
 * @param {Object} updater
 *
 * @returns {Object} Set widget STF async action
 */
export function setWidgetStfAsync(widgetId, widgetStf) {
    return (dispatch, getState) => {
        const currentWidgetStf = selectWidgetStf(getState(), widgetId);
        const newWidgetStf = widgetStf(currentWidgetStf);

        return dispatch(
            setWidgetStf(widgetId, newWidgetStf)
        );
    };
}

/**
 * Action creator to set a widget's state within the layout
 *
 * @param {String} widgetId
 * @param {Object} widgetState
 *
 * @returns {Object} Set widget state action
 */
export function setWidgetState(widgetId, widgetState) {
    return {
        type: ActionTypes.SET_WIDGET_STATE,
        payload: {
            widgetId,
            widgetState,
        },
    };
}

/**
 * Async action creator to set/update a widget's state based on the current state.
 *
 * @param {String} widgetId
 * @param {Object} updater
 *
 * @returns {Object} Set widget state async action
 */
export function setWidgetStateAsync(widgetId, updater) {
    return (dispatch, getState) => {
        const currentWidgetState = selectWidgetState(getState(), widgetId);
        const newWidgetState = updater(currentWidgetState);

        return dispatch(
            setWidgetState(widgetId, newWidgetState)
        );
    };
}

/**
 * Action creator to set a widget's export item
 *
 * @param {String} widgetId
 * @param {Object} exportItem
 * @param {String} exportItem.title
 * @param {Boolean} exportItem.isExportReady
 * @param {Function} exportItem.exportData
 *
 * @returns {Object} Set widget export item action
 */
export function setWidgetExportItem(widgetId, { title, isExportReady, exportData, getDataToExport, fileName, worksheetTitle, }) {
    let exportItem = { title, isExportReady };
    if (exportData) {
        exportItem.exportData = exportData;
    }
    else {
        exportItem.fileName = fileName;
        exportItem.worksheetTitle = worksheetTitle;
        exportItem.getDataToExport = getDataToExport;
    }

    return {
        type: ActionTypes.SET_WIDGET_EXPORT_ITEM,
        payload: {
            widgetId,
            exportItem,
        },
    };
}

/**
 * Action creator to set a widget's print status
 * @param {String} widgetId
 * @param {Boolean} printStatus
 *
 * @returns {Object} Set widget print status action
 */
export function setWidgetPrintStatus(widgetId, printStatus) {
    return {
        type: ActionTypes.SET_WIDGET_PRINT_STATUS,
        payload: {
            widgetId: widgetId,
            printStatus,
        },
    };
}

export function setWidgetLiveControlsVisibility(widgetId, visible) {
    const areLiveControlsVisible = Boolean(visible);

    return {
        type: ActionTypes.SET_WIDGET_LIVE_CONTROLS_VISIBILITY,
        payload: {
            widgetId: widgetId,
            areLiveControlsVisible: areLiveControlsVisible,
        },
    };
}

/**
 * Action creator to add a section to the layout
 *
 * @param {String} sectionType
 *
 * @returns {Object} Add section action
 */
export function addSection(sectionType, sectionIndex) {
    return {
        type: ActionTypes.ADD_SECTION,
        payload: {
            type: sectionType,
            sectionIndex,
        },
    };
}

/**
 * Action creator to delete a section within the layout
 *
 * @param {Number} sectionIndex
 *
 * @returns {Object} Delete section action
 */
export function deleteSection(sectionIndex) {
    return {
        type: ActionTypes.DELETE_SECTION,
        payload: {
            sectionIndex,
        },
    };
}

/**
 * Action creator to move a section within the layout
 *
 * @param {Number} sectionSrcIndex
 * @param {Number} sectionDestIndex
 *
 * @returns {Object} Move section action
 */
export function moveSection(sectionSrcIndex, sectionDestIndex) {
    return {
        type: ActionTypes.MOVE_SECTION,
        payload: {
            sectionSrcIndex,
            sectionDestIndex
        },
    };
}

/**
 * Action creator to modify a section within the layout
 *
 * @param {Number} sectionIndex
 * @param {String} sectionType
 * @param {String} sectionHeading
 *
 * @returns {Object} Modify section action
 */
export function modifySection(sectionIndex, sectionType, sectionHeading) {
    return {
        type: ActionTypes.MODIFY_SECTION,
        payload: {
            sectionIndex,
            sectionType,
            sectionHeading
        },
    };
}

/**
 * Action creator to set a page as clean
 *
 * @returns {Object} Set page clean action
 */
export function setPageClean() {
    return {
        type: ActionTypes.SET_PAGE_CLEAN,
    };
}

export function setAllLiveControlVisibilities(visible) {
    return {
        type: ActionTypes.SET_ALL_LIVE_CONTROL_VISIBILITIES,
        payload: {
            areLiveControlsVisible: visible,
        }
    };
}

/**
 * Action creator to set the page-level fields
 *
 * @returns {Object[]} Set fields action
 */
export function setFields(fields) {
    return {
        type: ActionTypes.SET_FIELDS,
        payload: { fields },
    };
}

/**
 * Action creator to set the page-level sort
 *
 * @returns {Object} Set sort fields action
 */
 export function setSortFields(fields) {
    return {
        type: ActionTypes.SET_SORT_FIELDS,
        payload: { fields },
    };
}

/**
 * Action creator to set the page-level slice filters
 *
 * @returns {Object} Set slice filters action
 */
 export function setSliceFilters(sliceFilters) {
    return {
        type: ActionTypes.SET_SLICE_FILTERS,
        payload: { sliceFilters },
    };
}

/**
 * Action creator to set the page-level grouped fields
 *
 * @returns {Object[]} Set grouped fields action
 */
 export function setGroupedFields(groupedFields) {
    return {
        type: ActionTypes.SET_GROUPED_FIELDS,
        payload: { groupedFields },
    };
}

/**
 * Action creator to set the page-level hierarchy node
 *
 * @returns {String} Set hierarchy node action
 */
 export function setHierarchyNode(hierarchyNode) {
    return {
        type: ActionTypes.SET_HIERARCHY_NODE,
        payload: { hierarchyNode },
    };
}

/**
 * Action creator to set the page-level table structure
 *
 * @returns {String} Set table structure action
 */
 export function setTableStructure(tableStructure) {
    return {
        type: ActionTypes.SET_TABLE_STRUCTURE,
        payload: { tableStructure },
    };
}

/**
 * Action creator to set the page-level table level
 *
 * @returns {String} Set table leve action
 */
 export function setTableLevel(tableLevel) {
    return {
        type: ActionTypes.SET_TABLE_LEVEL,
        payload: { tableLevel },
    };
}

/**
 * Action creator to set the page-level time range
 *
 * @returns {Object} Set time range action
 */
export function setTimeRange(timeRange) {
    return {
        type: ActionTypes.SET_TIME_RANGE,
        payload: { timeRange },
    };
}
