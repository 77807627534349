import 'whatwg-fetch'; // polyfill for the `fetch()` API

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Helmet, HelmetProvider }  from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './components/app/App';
import store from './redux/store';

const rootElement = document.getElementById('root');

// Render the UI
ReactDOM.render(
    <BrowserRouter>
        <HelmetProvider>
            <Helmet
                defaultTitle="XL Enterprise"
                titleTemplate="%s - XL Enterprise"
            />
            <Provider store={store}>
                <App/>
            </Provider>
        </HelmetProvider>
    </BrowserRouter>,
    rootElement
);
