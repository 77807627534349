import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

function objectShallowEquality(a, b, skipKeys = {}) {
    if (a === b) {
        return true;
    }

    const firstChangedKey = Object.keys(Object.assign({}, a, b))
        .find((key) => {
            if (skipKeys[key]) {
                return false;
            }

            return a[key] !== b[key];
        });

    return firstChangedKey === undefined;
}

function arrayShallowEquality(a, b) {
    if (a === b) {
        return true;
    }
    else if (a.length !== b.length) {
        return false;
    }
    else {
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
    }

    return true;
}

function shallowEquality(a, b) {
    if (typeof a !== typeof b) {
        return false;
    }
    else if (!a || !b) {
        return a === b;
    }
    else if (isPlainObject(a) && isPlainObject(b)) {
        return objectShallowEquality(a, b);
    }
    else if (isArray(a) && isArray(b)) {
        return arrayShallowEquality(a, b);
    }
    else {
        return a === b;
    }
}

/**
 * Compare two versions of a component's props to detect changes. Designed for
 * use in `shouldComponentUpdate`. Performs a shallow compare of all the props
 * with an option to check certain props one level deeper.
 *
 * @param {Object} prevProps Current/last props, usually `this.props`
 * @param {Object} nextProps Next/updated props, usually the first arg to `shouldComponentUpdate`
 * @param {string[]} [nestedProps] A list of prop names that should be compared
 *      one level deeper than the usual shallow compare. In other words, each
 *      of the props listed here will get its own shallow compare.
 * @returns true if a change was detected, false otherwise
 */
export default function didPropsChange(prevProps, nextProps, nestedProps = []) {
    if (!isPlainObject(prevProps) || !isPlainObject(nextProps)) {
        throw new Error(`Props must be plain objects. Received ${typeof prevProps}, ${typeof nextProps}`);
    }

    const skipKeys = nestedProps.reduce((map, key) => {
        map[key] = true;
        return map;
    }, {});

    if (!objectShallowEquality(prevProps, nextProps, skipKeys)) {
        return true;
    }

    const firstChangedKey = nestedProps.find((key) => {
        return !shallowEquality(prevProps[key], nextProps[key]);
    });

    return firstChangedKey !== undefined;
}
