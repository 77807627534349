import React from 'react';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentWide';
import { Body1 } from 'Components/typography/typography';
import styled from 'styled-components';

const Body = styled(Body1)`
    white-space: pre-wrap;
    display: block;
`;

export default function PrivacyPage() {
    return (
        <Page>
            <PageTitle>Privacy Policy</PageTitle>
            <PageContent>
                <Body>
                    XL Enterprise is currently in preview release and this page will be updated soon.
                    Please contact us if you have any questions.
                </Body>
            </PageContent>
        </Page>
    );
}
