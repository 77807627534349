import styled from 'styled-components';

const DropdownContentBox = styled.div`
    box-sizing: border-box;
    overflow: auto;

    outline: none;

    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-color: ${props => props.theme.colors.palette.white};
    color: ${props => props.theme.colors.palette.black};
`;

export default DropdownContentBox;
