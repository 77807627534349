import React from 'react';
import moment from 'moment-timezone';

import SelectInput from 'Components/select/SelectInput';
import SelectOption from 'Components/select/SelectOption';
import SelectOptionContent from 'Components/select/SelectOptionContent';

function getTimeZoneChunks(timeZoneName) {
    const regex = /([^/]+)\/(.+)/;
    const matched = timeZoneName.match(regex);

    return {
        continent: matched[1],
        zone: matched[2].replace(/_/g, ' '),
        offset: moment.tz(timeZoneName).format('Z'),
    };
}

function renderTimeZones() {
    const filtered = moment.tz.names().filter((name) => {
        const parts = name.match(/(.*?)\/(.*)/);

        return parts && parts[1] !== 'Etc';
    });

    const options = filtered.map((timeZoneName, i) => {
        const tzChunks = getTimeZoneChunks(timeZoneName);

        return (
            <SelectOption key={i} value={timeZoneName}>
                <SelectOptionContent>
                    {`${tzChunks.continent} – ${tzChunks.zone} (UTC${tzChunks.offset})`}
                </SelectOptionContent>
            </SelectOption>
        );
    });

    // Special case for UTC, which doesn't match the "Vague/Specific" name format.
    options.push((
        <SelectOption key="UTC" value="UTC">
            <SelectOptionContent>UTC</SelectOptionContent>
        </SelectOption>
    ));

    return options;
}

function TimezoneInput(props) {
    return (
        <SelectInput
            {...props}
        >
            {renderTimeZones()}
        </SelectInput>
    );
}

export default TimezoneInput;
