import moment from 'moment';

const acceptedTimeFormats = [
    // locale time format
    'LT',
    // AM/PM with space
    'h:mm a',
    'h:mm:ss a',
    // AM/PM no space
    'h:mma',
    'h:mm:ssa',
    // 24 hour time
    'H:mm',
    'H:mm:ss'
];

export function parseTime(timeString, { useStrictParsing = true } = {}) {
    let parsedTime;

    timeString = String(timeString).trim();

    acceptedTimeFormats.some((timeFormat) => {
        parsedTime = moment.utc(timeString, timeFormat, useStrictParsing);
        return parsedTime.isValid();
    });

    return parsedTime;
}

export function format24HourTime(time) {
    if (!moment.isMoment(time)) {
        // Just parse it as a string
        time = parseTime(time);
    }

    return time.isValid() ? time.format('HH:mm') : '';
}

export function formatLocaleTime(time) {
    if (!moment.isMoment(time)) {
        // Just parse it as a string
        time = parseTime(time);
    }

    return time.isValid() ? time.format('LT') : '';
}
