import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContainedButton from 'Components/button/ContainedButton';
import { Spacing } from 'Components/common/common';
import OutlinedButton from 'Components/button/OutlinedButton';
import useNavigationPrevention from 'Common/hooks/useNavigationPrevention';
import FormError from './FormError';

const SubmitButton = styled(ContainedButton)`
    margin-top: ${Spacing.formButtonMargin};
    margin-right: ${Spacing.inlineButtonMargin};
`;

const CancelButton = styled(OutlinedButton)`
    margin-top: ${Spacing.formButtonMargin};
    margin-right: ${Spacing.inlineButtonMargin};
`;

export default function Form(props) {

    const _handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(event);
    };

    const _handleReset = (event) => {
        event.preventDefault();
        props.onReset(event);
    };

    const {
        error, allowSubmit,
        allowCancel,
        disableNavigationPrevention,
        showCancel,
        showSubmit,
        submitButtonText,
        cancelButtonText,
        children,
        ...otherProps
     } = props;

    // prevent navigation if the allowCancel or allowSubmit is enabled indicating there have been some changes
    // do not prevent navigation if this component has been configured to disable navigation
    // prevention, probably because the user is handling navigation prevention themselves
    const preventNav = !disableNavigationPrevention && (allowCancel || allowSubmit);

    const {
        component: navigationPrevention,
    } = useNavigationPrevention(preventNav);

    return (
        <React.Fragment>
            {navigationPrevention}
            <form
                {...otherProps}
                onSubmit={_handleSubmit}
                onReset={_handleReset}
                // Disable the default browser form validation so we can control
                // the look and behavior of validation
                noValidate
            >
                {children}

                {showCancel && (
                    <CancelButton
                        disabled={!allowCancel}
                        type="reset"
                        color="primary"
                    >
                        {cancelButtonText}
                    </CancelButton>
                )}
                {showSubmit && (<SubmitButton
                    disabled={!allowSubmit}
                    type="submit"
                    color="primary"
                >
                    {submitButtonText}
                </SubmitButton>)}
                {error && (
                    <FormError error={error} />
                )}
            </form>
        </React.Fragment>
    );
}

Form.propTypes = {
    allowSubmit: PropTypes.bool,
    allowCancel: PropTypes.bool,
    disableNavigationPrevention: PropTypes.bool,
    showSubmit: PropTypes.bool,
    showCancel: PropTypes.bool,
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        status_code: PropTypes.number,
        payload: PropTypes.objectOf(
            PropTypes.arrayOf(PropTypes.string),
        ),
    }),
    onReset: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

Form.defaultProps = {
    showSubmit: true,
    showCancel: true,
    allowSubmit: true,
    allowCancel: true,
    submitButtonText: 'Save',
    cancelButtonText: 'Cancel',
    onReset: () => { }
};
