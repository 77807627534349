import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatNumber } from 'Common/util/format.js';
import { limitTrendPercent } from 'Common/data/trend';
import { Body5, Body6 } from 'Common/components/typography';
import * as TrendGoodness from './TrendGoodness';
import TrendNotAvailableIcon from './TrendNotAvailableIcon';
import useTranslation from 'Common/hooks/useTranslation';
import useLocale from 'Common/hooks/useLocale';


const TrendIndicator = styled.div`
    color: ${props => props.theme.colors.trend[props.trendGoodness]};
    display: inline-block;
    padding-right: 0.3em;

    &::before {
        font-family: 'FontAwesome';
        content: '\f111';
    }
`;

const TrendValue = styled.span`
    display: inline-block;
`;

function TrendDot({
    trendGoodness,
    value,
    notAvailable,
    condensed,
    isPrint,
    noColor,
    showTrendLabel,
    showPositiveTrendQualifier,
    ...otherProps
}) {
    const naSymbol = useTranslation('na_symbol');
    const nullMetricLiteral = useTranslation('null_metric_literal');
    const trendLiteral = useTranslation('trend_literal');
    const locale = useLocale();
    
    let trendValue = naSymbol;
    let qualifier = '';

    if (notAvailable) {
        trendValue = (
            <React.Fragment>
                {naSymbol}
                <TrendNotAvailableIcon />
            </React.Fragment>
        );
    }
    else if (typeof value === 'number') {
        const trendConfig = limitTrendPercent(value);

        trendValue = formatNumber(trendConfig.value, { type: 'percent', places: 1, locale: locale || 'en' });

        const hasPositiveTrendQualifier = (value > 0 && showPositiveTrendQualifier);
        qualifier = trendConfig.qualifier || (hasPositiveTrendQualifier ? '+' : '');
    }
    else if (!value) {
        trendValue = nullMetricLiteral;
    }

    const TrendContainer = (condensed || isPrint) ? Body6 : Body5;
    
    const trendLabel = showTrendLabel ? `${trendLiteral} ` : '';

    const trendGoodnessColor = noColor ? "none" : trendGoodness;
    
    return (
        <TrendContainer as="div" {...otherProps}>
            <TrendValue>
                {trendLabel}{qualifier}{trendValue}
                &nbsp;
                <TrendIndicator trendGoodness={trendGoodnessColor} />
            </TrendValue>
        </TrendContainer>
    );
}

TrendDot.propTypes = {
    trendGoodness: PropTypes.oneOf(Object.values(TrendGoodness)),
    value: PropTypes.number,
    notAvailable: PropTypes.bool,
    condensed: PropTypes.bool,
    isPrint: PropTypes.bool,
    noColor: PropTypes.bool,
    showTrendLabel: PropTypes.bool,
    showPositiveTrendQualifier: PropTypes.bool,
};

TrendDot.defaultProps = {
    trendGoodness: 'none',
    notAvailable: false,
    condensed: false,
    isPrint: false,
    noColor: false,
    showTrendLabel: false,
    showPositiveTrendQualifier: false,
};

export default React.memo(TrendDot);
