import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContainedButton from 'Components/button/ContainedButton';
import { Body1 } from 'Components/typography/typography';
import { sendPostRequest } from '../../../../http/request';

const Root = styled.div`
`;

const ActionButton = styled(ContainedButton)`
    display: block;
    margin-bottom: 18px;
`;

const StatusMessage = styled(Body1)`
    display: block;
    margin-bottom: 18px;
`;

const RequestState = {
    Idle: 'RequestState.Idle',
    InProgress: 'RequestState.InProgress',
    Success: 'RequestState.Success',
    Error: 'RequestState.Error',
};

export default class ResendWelcomeButton extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        userId: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor() {
        super();
        this._handleClick = this._handleClick.bind(this);
        this.state = {
            requestStatus: RequestState.Idle,
        };
    }

    async _handleClick(event) {
        let userId = this.props.userId;
        this.setState({ requestStatus: RequestState.InProgress });

        try {
            let response = await sendPostRequest(`/api/v1/accounts/organization/users/${userId}/resend-welcome`);

            if (response.ok) {
                this.setState({ requestStatus: RequestState.Success });
            }
            else {
                this.setState({ requestStatus: RequestState.Error });
            }
        }
        catch (err) {
            this.setState({ requestStatus: RequestState.Error });
        }
    }

    render() {
        const {
            email,
            disabled,
            ...otherProps
        } = this.props;

        const {
            requestStatus,
        } = this.state;

        let resendControl;
        switch (requestStatus) {
            case RequestState.Idle:
            case RequestState.InProgress:
                resendControl = (
                    <ActionButton
                        color="primary"
                        disabled={requestStatus !== RequestState.Idle || disabled}
                        onClick={this._handleClick}
                    >
                        Resend Setup Email
                    </ActionButton>
                );
                break;

            case RequestState.Success:
                resendControl = (
                    <StatusMessage>
                        An email has been sent to <i>{email}</i> with instructions on activating their account.
                    </StatusMessage>
                );
                break;

            case RequestState.Error:
                resendControl = (
                    <StatusMessage>
                        An error occurred sending the instructions.
                    </StatusMessage>
                );
                break;
        }

        return (
            <Root {...otherProps}>
                {resendControl}
            </Root>
        );
    }
}
