import { rgbToCss, interpolateColor } from 'Common/util/color/util';
import keyBy from 'lodash/keyBy';
import isNumber from 'lodash/isNumber';

const DEFAULT_COLOR = 'rgba(170,170,170,0.32)';

const BASE_HEAT_MAP_CONFIG = [
    {
        name: 'critical',
        baseColor: { r: 139, g: 0, b: 0 },
        baseMonochrome: { r: 51, g: 51, b: 51 },
    },
    {
        name: 'warning',
        baseColor: { r: 217, g: 17, b: 17 },
        baseMonochrome: { r: 102, g: 102, b: 102 },
    },
    {
        name: 'caution',
        baseColor: { r: 255, g: 191, b: 0 },
        baseMonochrome: { r: 153, g: 153, b: 153 },
    },
    {
        name: 'good',
        baseColor: { r: 34, g: 139, b: 34 },
        baseMonochrome: { r: 204, g: 204, b: 204 },
    },
    {
        name: 'best',
        baseColor: { r: 0, g: 100, b: 0 },
        baseMonochrome: { r: 238, g: 238, b: 238 },
    },
];

const METRIC_LIMITS = {
    efficiency: {
        min: 0,
        max: 1.2,
    },
    default: {
        min: 0,
        max: 1,
    },
};

function getHeatMapConfig(columnName, metricAlertConfig) {
    const directionality = metricAlertConfig.directionality;
    const rangesBySeverity = keyBy(metricAlertConfig.ranges, (item) => item.name);

    let heatMapConfigBySeverity = BASE_HEAT_MAP_CONFIG
        .reduce((map, heatMapConfigItem) => {
            const severity = heatMapConfigItem.name;
            const { min, max } = rangesBySeverity[severity] || {};

            map[severity] = {
                ...heatMapConfigItem,
                min: min,
                max: max,
            };

            return map;
        }, {});

    const metricLimits = METRIC_LIMITS[columnName] || METRIC_LIMITS.default;

    const critical = { ...heatMapConfigBySeverity.critical };
    const good = { ...heatMapConfigBySeverity.good };
    const best = { ...heatMapConfigBySeverity.best };

    if (directionality === 'lower_better') {
        if (critical.min >= metricLimits.max) {
            critical.min = undefined;
        }
        else {
            critical.max = metricLimits.max;
        }

        if (good.max <= metricLimits.min) {
            good.min = good.max;
        }
        else {
            good.min = metricLimits.min;
            best.max = metricLimits.min;
        }
    }
    else {
        if (critical.max <= metricLimits.min) {
            critical.max = undefined;
        }
        else {
            critical.min = metricLimits.min;
        }

        if (good.min >= metricLimits.max) {
            good.max = good.min;
        }
        else {
            good.max = metricLimits.max;
            best.min = metricLimits.max;
        }
    }

    heatMapConfigBySeverity = {
        ...heatMapConfigBySeverity,
        critical: critical,
        good: good,
        best: best,
    };

    let severityOrder = BASE_HEAT_MAP_CONFIG.map(item => item.name);

    if (directionality === 'lower_better') {
        severityOrder = severityOrder.reverse();
    }

    return severityOrder
        .map((severity) => {
            return heatMapConfigBySeverity[severity];
        })
        // We don't want to include unused thresholds
        .filter((heatMapConfigItem) => {
            return isNumber(heatMapConfigItem.min) || isNumber(heatMapConfigItem.max);
        });
}

function getHeatMapColor(columnName, value, metricAlertConfig, { noColor = false } = {}) {
    const heatMapConfig = getHeatMapConfig(columnName, metricAlertConfig);

    let rgbColor;

    if (value) {
        const first = heatMapConfig[0];
        const last = heatMapConfig[heatMapConfig.length - 1];

        const firstMin = isNumber(first.min) ? first.min : first.max;
        const firstMax = isNumber(last.max) ? last.max : last.min;

        const colorKey = noColor ? 'baseMonochrome' : 'baseColor';

        if (value <= firstMin) {
            rgbColor = first[colorKey];
        }
        else if (value >= firstMax) {
            rgbColor = last[colorKey];
        }
        else if (!Number.isNaN(value)) {
            const thresholdIndex = heatMapConfig.findIndex((configItem) => {
                if (!configItem) {
                    return false;
                }

                const { min, max } = configItem;

                return (value >= min) && (value < max);
            });

            let { min, max, [colorKey]: baseColor } = heatMapConfig[thresholdIndex];

            let nextIndex;

            if (metricAlertConfig.directionality === 'lower_better') {
                nextIndex = thresholdIndex - 1;

                const oldMin = min;

                min = max;
                max = oldMin;
            }
            else {
                nextIndex = thresholdIndex + 1;
            }

            let { [colorKey]: nextColor } = heatMapConfig[nextIndex];

            // Interpolate the color between its surrounding severity colors.
            {
                const maxDiff = max - min;
                const actualDiff = value - min;
                const factor = actualDiff / maxDiff;

                rgbColor = interpolateColor(baseColor, nextColor, factor);
            }
        }
    }

    return rgbColor ? rgbToCss(rgbColor) : DEFAULT_COLOR;
}

function getHeatSeverity(rawValue, metricAlertConfig) {
    const stateSet = metricAlertConfig && metricAlertConfig.ranges;
    let severity = 'unknown';

    if (stateSet && stateSet.some) {
        stateSet.some((state) => {
            const {
                min = -Infinity,
                max = Infinity,
                name,
            } = state;

            if ((rawValue > min) && (rawValue <= max)) {
                severity = name;
                return true; // stop looking
            }
        });
    }

    return severity;
}

// [TODO] Document this
export function getHeatConfig(columnName, rawValue, metricAlertConfig) {
    const severity = getHeatSeverity(rawValue, metricAlertConfig);
    let color;

    if (metricAlertConfig) {
        color = getHeatMapColor(columnName, rawValue, metricAlertConfig);
    }

    return {
        severity,
        color,
    };
}
