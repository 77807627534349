import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Portal from 'Common/components/Portal/Portal';
import Mask from 'Common/components/Portal/Mask';
import { Body5, Heading2 } from 'Common/components/typography';
import ButtonBase from 'Common/components/Button/ButtonBase';
import Icon from 'Common/components/Icon';
import useUniqueId from 'Common/hooks/useUniqueId';

const StyledWindowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const borderRadius = '6px';

const StyledWindow = styled.div`
    box-sizing: border-box;
    background-color: ${({theme}) => theme.colors.palette.white};
    border-radius: ${borderRadius};
    box-shadow: 2px 2px 20px ${({theme}) => theme.colors.palette.grey.emperor};
    z-index: 300;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
`;

const StyledBar = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    min-height: 44px;
`;

const StyledTopBar = styled(StyledBar)`
    ${({hasTitle}) => hasTitle && css`
        background-color: ${({theme}) => theme.colors.palette.grey.cloud};
        border-top-left-radius: ${borderRadius};
        border-top-right-radius: ${borderRadius};
    `}
`;

const StyledBottomBar = styled(StyledBar)`
    border-top: 1px solid ${({theme}) => theme.colors.palette.grey.cloud};
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;

    > button {
        margin-left: 8px;
    }
`;

const TitleText = styled(Heading2)`
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    white-space: nowrap;
`;

const WindowBody = styled(Body5)`
    overflow: auto;
    padding: ${({hasTitle}) => hasTitle ? 20 : 0}px 15px 20px 15px;
`;


const StyledMask = styled(Mask)`
    background-color: rgba(0, 0, 0, 0.5);

    animation: mask-fade-in 0.3s;

    @keyframes mask-fade-in {
        from {
            background-color: rgba(0, 0, 0, 0);
        }

        to {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
`;

const CloseButton = styled(ButtonBase)`
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 36px;
    /* Touch Target */
    :before {
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 300ms;
        min-width: 44px;
        min-height: 44px;
    }
    :hover {
        :before {
            background-color: rgba(0,0,0,0.05);
        }
    }
    :active {
        :before {
            background-color: rgba(0,0,0,0.1);
        }
    }
    :not(:hover):focus {
        :before {
            background-color: rgba(0,0,0,0.05);
        }
    }
`;

const CloseButtonIcon = styled(Icon)`
    font-size: 18px;

    /* We rotate it to look like an 'X', we need to add a bit of translation
       too to keep it in the perfect center.
    */
    transform: translateX(1.5px) rotate(45deg);

    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

/**
 * A custom Modal window. Interrupts the current layout by being placed on top and preventing page interaction until closed.
 * Used as either a dialog requiring user input/confirmation, or a way to allow the user to complete multiple tasks.
 *
 */
export default function ModalWindow(props) {
    const {
        children,
        buttons,
        title,
        bodyHeight,
        bodyWidth,
        bodyMaxHeight,
        bodyMaxWidth,

        onClose,
        onMaskClick,
    } = props;

    const hasTitle = title.length > 0;

    const windowTitle = title || onClose
        ? (
            <StyledTopBar hasTitle={hasTitle}>
                <TitleText>{title}</TitleText>
                {onClose && (
                    <CloseButton onClick={onClose} aria-label='Close'>
                        <CloseButtonIcon
                            type='vorne'
                            iconName='add'
                        />
                    </CloseButton>
                )}
            </StyledTopBar>
        )
        : null;

    const actions = buttons.length
        ? (
            <StyledBottomBar>
                {buttons}
            </StyledBottomBar>
        )
        : null;

    const bodyStyle = {
        width: bodyWidth,
        height: bodyHeight,
        maxWidth: bodyMaxWidth,
        maxHeight: bodyMaxHeight,
    };

    const labelId = useUniqueId('window-label-');

    return (
        <Portal>
            <StyledWindowContainer>
                <StyledMask onClick={onMaskClick}/>
                <StyledWindow aria-labelledby={labelId} role='dialog'>
                    {windowTitle}
                    <WindowBody style={bodyStyle} hasTitle={hasTitle} id={labelId}>
                        {children}
                    </WindowBody>
                    {actions}
                </StyledWindow>
            </StyledWindowContainer>
        </Portal>
    );
}

const cssUnitType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
]);

ModalWindow.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.node),
    children: PropTypes.node,
    title: PropTypes.string,
    bodyHeight: cssUnitType,
    bodyWidth: cssUnitType,
    bodyMaxHeight: cssUnitType,
    bodyMaxWidth: cssUnitType,

    onClose: PropTypes.func,
    onMaskClick: PropTypes.func,
};

ModalWindow.defaultProps = {
    title: '',
    buttons: [],

    bodyMaxHeight: '100%',
    bodyMaxWidth: '100%',

    onMaskClick: () => {},
};
