import React from 'react';

/**
 * Attempts to get a display name for a component class
 * @param {function|string} Component
 */
export function getDisplayName(Component) {
    return typeof Component === 'string' ? Component :
        (Component.displayName || Component.name || 'Component');
}

/**
 * A higher-order component that blocks intercepts props so they are not passed
 * into the component it wraps
 * @param {function|string} Component React component class
 * @param {string[]} propNames props that should be intercepted/removed
 */
export function interceptProps(Component, propNames) {
    const WrappedComponent = React.forwardRef((props, ref) => {
        const otherProps = { ...props };

        propNames.forEach((propName) => {
            delete otherProps[propName];
        });

        return <Component {...otherProps} ref={ref} />;
    });

    WrappedComponent.displayName = `InterceptProps(${getDisplayName(Component)})`;

    return WrappedComponent;
}
