import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TrashIcon from 'Components/icon/TrashIcon';
import IconButton from 'Components/button/IconButton';

const TrashIconButton = styled(IconButton)`
    font-size: 24px;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

export default class AlertItemDeleteButton extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
    };

    render() {
        const { disabled, onClick } = this.props;

        return (
            <TrashIconButton
                disabled={disabled}
                onClick={onClick}
                title="Delete Alert"
            >
                <TrashIcon />
            </TrashIconButton>
        );
    }
}
