// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./woff/Roboto-Light-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./woff/Roboto-LightItalic-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"Roboto";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");font-weight:300;font-style:normal}@font-face{font-family:"Roboto";src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");font-weight:300;font-style:italic}`, "",{"version":3,"sources":["webpack://./common/font/roboto/light.scss"],"names":[],"mappings":"AAAA,WACI,oBAAA,CACA,0DAAA,CACA,eAAA,CACA,iBAAA,CAGJ,WACI,oBAAA,CACA,0DAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":["@font-face {\n    font-family: 'Roboto';\n    src: url('./woff/Roboto-Light-webfont.woff') format('woff');\n    font-weight: 300;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Roboto';\n    src: url('./woff/Roboto-LightItalic-webfont.woff') format('woff');\n    font-weight: 300;\n    font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
