import { sendGetRequest } from 'Http/request';
import { getResources } from 'Common/util/ResourceCollection';

export function getAlertWindow() {
    return getResources({
        resourceType: 'alertWindow',
        requestId: 'getAlertWindow',
        sendRequest: async () => {
            return sendGetRequest('/api/v1/accounts/user/alert-window');
        },
    });
}

export default getAlertWindow;
