import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonBase from 'Common/components/Button/ButtonBase';
import Icon from 'Common/components/Icon';
import {
    Secondary5,
    Primary4,
} from 'Common/components/typography';

const backButtonWidth = 30;

const MenuTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: ${(props) => props.hasBackButton ? backButtonWidth : 0 }px;
`;

const GuideText = styled(Secondary5)`
    max-width: 300px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 4px;
`;

const MenuTitle = styled(Primary4)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    padding: 0 8px;
`;

const MenuIconContainer = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: ${({theme}) => theme.colors.palette.manatee };

    width: 26px;
    height: 30px;
`;

const MenuHeaderLabel = styled.div`
    display: inline-block;
    white-space: nowrap;
    text-align: left;

    padding-right: 12px;
`;

// [FUTUREHACK] should this be a defined common button?
const BackButton = styled(ButtonBase)`
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${backButtonWidth}px;

    color: ${({theme}) => theme.colors.palette.manatee };
    font-size: 20px;

    :disabled {
        opacity: 0.5;
    }

    :hover:enabled {
        background-color: ${props => props.theme.colors.palette.grey.cloud};
    }
`;

/**
  * This component is the header shown in the LiveControl Dropdown Window.
  */
 export default function LiveControlDropdownHeader({
    displayName,
    icon,
    goBack,
    guideText,
}) {
    const renderGuideText = () => {
        if (guideText) {
            return (
                <GuideText>
                    {guideText}
                </GuideText>
            );
        }
    };

    return (
        <React.Fragment>
            <MenuTitleContainer hasBackButton={!!goBack}>
                {!!goBack && (
                        <BackButton
                            aria-label="Back"
                            onClick={goBack}
                        >
                            <Icon type="fontAwesome" iconName="angle-left" />
                        </BackButton>)
                }
                <MenuTitle>
                    <MenuIconContainer>
                        {icon}
                    </MenuIconContainer>
                    <MenuHeaderLabel>
                        {displayName}
                    </MenuHeaderLabel>
                </MenuTitle>
            </MenuTitleContainer>
            {renderGuideText()}
        </React.Fragment>
    );
}

LiveControlDropdownHeader.propTypes = {
    displayName: PropTypes.string,
    icon: PropTypes.node,
    goBack: PropTypes.func,
    guideText: PropTypes.string,
};
