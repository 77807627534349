import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withFormElementContext from 'Components/form-element/withFormElementContext';
import PopupLayer from 'Components/popup/PopupLayer';
import SelectOptionsPopup from './internal/SelectOptionsPopup';
import SelectOption from './SelectOption';
import SelectGroup from './SelectGroup';
import SelectOptionWrapper from './internal/SelectOptionWrapper';
import SelectOpenButton from './internal/SelectOpenButton';
import SelectOptionContent from './SelectOptionContent';

const Root = styled.div`
    position: relative;
    display: inline-block;
    box-sizing: border-box;
`;

const HiddenInput = styled.input`
    display: none;
`;

const OptionsContainer = styled.div`
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 50px;
    background-color: ${(props) => props.theme.colors.palette.white};
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border: 1px solid rgb(0,0,0);
    overflow: auto;
`;

class SelectInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue,
            open: false,
            popupOriginTop: 0,
            popupOriginLeft: 0,
        };

        this._preventBlur = false;
        this._openButtonRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        let value;

        if (typeof props.value !== 'undefined') {
            // Controlled

            // Value in state should always be an array
            if (props.multiple) {
                if (!Array.isArray(props.value)) {
                    throw new Error("SelectInput 'value' prop must be an array when 'multiple' === true");
                }

                value = props.value;
            }
            else {
                if (typeof props.value !== 'string') {
                    throw new Error("SelectInput 'value' prop must be a string when 'multiple' === false");
                }

                value = [ props.value ];
            }
        }
        else {
            // Uncontrolled
            value = state.value;

            // Init state value if needed
            if (value === undefined) {
                let initialValue;

                if (props.multiple) {
                    initialValue = props.initialValue || [];

                    if (!Array.isArray(initialValue)) {
                        throw new Error("SelectInput 'initialValue' prop must be an array when 'multiple' === true");
                    }

                    value = initialValue;
                }
                else {
                    initialValue = props.initialValue || '';

                    if (typeof initialValue !== 'string') {
                        throw new Error("SelectInput 'initialValue' prop must be a string when 'multiple' === false");
                    }

                    value = [ initialValue ];
                }
            }
        }

        return {
            value: value,
        };
    }

    _getValue() {
        return this.state.value;
    }

    _handleHiddenInputClick = (event) => {
        const openButton = this._openButtonRef.current;
        openButton.focus();
        openButton.click();
    };

    _handleFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
        this.props.formElementContext.onFocus(event);
    };

    _handleBlur = (event) => {
        if (this._preventBlur === true) {
            event.stopPropagation();
            this._preventBlur = false;
            return;
        }

        const { name } = this.props;
        const { value } = this.state;
        if (this.props.onBlur) {
            event.target = {
                name: name,
                value: value,
            };
            this.props.onBlur(event);
        }
        this.props.formElementContext.onBlur(event);
    };

    _handleOpen = (event) => {
        // Don't open menu if disabled
        if (this.props.formElementContext.disabled) {
            return;
        }

        // Prevents the next blur so it can be re-focused after the dropdown
        // is closed
        this._preventBlur = true;

        const node = this._openButtonRef.current;
        const rect = node.getBoundingClientRect();

        this.setState({
            open: true,
            popupOriginTop: Math.round(rect.bottom - 1),
            popupOriginLeft: rect.left,
        });
    };

    _handleClose = (event) => {
        this.setState({
            open: false,
        });

        if (this.props.onClose) {
            const { value } = this.state;
            const { name } = this.props;
            event.target = {
                name: name,
                value: value,
            };
            this.props.onClose(event);
        }

        // Re-focus the button on close
        this._openButtonRef.current.focus();
    };

    _handleOptionSelect = (event) => {
        const { name, multiple, onChange } = this.props;
        const { value } = event.target;
        const prevValue = this._getValue();

        const isSelected = prevValue.indexOf(value) !== -1;

        if (multiple) {
            let nextValue = [];

            if (isSelected) {
                nextValue = prevValue.filter(selectedVal => selectedVal !== value);
            }
            else {
                nextValue = [ ...prevValue, value ];
            }

            this.setState({
                value: nextValue,
                open: true, // stay open
            });

            event.target = {
                name: name,
                value: nextValue,
            };

            if (onChange) {
                onChange(event);
            }
        }
        else {
            const didChange = !isSelected;

            this.setState({
                value: [ value ],
                open: false,
            });

            if (didChange && onChange) {
                event.target = {
                    name: name,
                    value: value,
                };
                onChange(event);
            }

            // Re-focus the button on close
            this._openButtonRef.current.focus();
        }
    };

    _renderOptions(options) {
        const {
            maxOptionsHeight,
            optionsWidth,
        } = this.props;

        const {
            open,
            popupOriginTop,
            popupOriginLeft,
        } = this.state;

        if (open) {
            return (
                <PopupLayer>
                    <SelectOptionsPopup
                        originTop={popupOriginTop}
                        originLeft={popupOriginLeft}
                        onOverlayClick={this._handleClose}
                        maxHeight={maxOptionsHeight}
                        width={optionsWidth}
                    >
                        <OptionsContainer>
                            {options}
                        </OptionsContainer>
                    </SelectOptionsPopup>
                </PopupLayer>
            );
        }
        else {
            return null;
        }
    }

    _mapChildOptionsRecursive(children, mapFn, indices = []) {
        return React.Children.map(children, (child, i) => {
            if (!React.isValidElement(child)) {
                return null;
            }
            else if (child.type === SelectOption) {
                return mapFn(child, [ ...indices, i ]);
            }
            else if (child.type === SelectGroup) {
                return React.cloneElement(child, {
                    children: this._mapChildOptionsRecursive(child.props.children, mapFn, [ ...indices, i ]),
                });
            }
            else {
                throw new Error(`Unknown Select child of type ${child.type}`);
            }
        });
    }

    render() {
        const {
            className: classNameProp,
            id,
            name,
            formElementContext,
            onChange,
            children,
            multiple,
            selectionDisplay,
            maxOptionsHeight,
            optionsWidth,
            ...otherProps
        } = this.props;

        const {
            focused,
            disabled,
            required,
        } = formElementContext;

        const {
            open,
        } = this.state;

        const value = this._getValue();

        // Build map of selected values for quick lookup
        const valueMap = value.reduce((map, selectedValue) => {
            map[selectedValue] = true;
            return map;
        }, {});

        let selectedChild = null;

        const options = this._mapChildOptionsRecursive(children, (child, indices) => {
            const selected = Boolean(valueMap[child.props.value]);

            if (selected) {
                selectedChild = child;
            }

            if (open) {
                return (
                    <SelectOptionWrapper
                        value={child.props.value}
                        depth={indices.length - 1}
                        selected={selected}
                        multiple={multiple}
                        onClick={this._handleOptionSelect}
                    >
                        {child}
                    </SelectOptionWrapper>
                );
            }

            return null;
        });

        // User may pass in a custom component for inside the open button
        if (selectionDisplay) {
            selectedChild = selectionDisplay;
        }

        if (selectedChild === null) {
            selectedChild = (
                <SelectOptionContent>
                    <i>None</i>
                </SelectOptionContent>
            );
        }

        const selectedChildDiv = React.cloneElement(selectedChild, { as: 'div' });

        return (
            <Root className={classNameProp}>
                <HiddenInput
                    id={id}
                    name={name}
                    value={value.join(',')}
                    onClick={this._handleHiddenInputClick}
                    onChange={() => {}}
                />
                <SelectOpenButton
                    {...otherProps}
                    Component="div"
                    forwardedRef={this._openButtonRef}
                    focused={focused}
                    disabled={disabled}
                    required={required}
                    onFocus={this._handleFocus}
                    onBlur={this._handleBlur}
                    onClick={this._handleOpen}
                >
                    {selectedChildDiv}
                </SelectOpenButton>
                {this._renderOptions(options)}
            </Root>
        );
    }
}

SelectInput.propTypes = {
    formElementContext: PropTypes.object.isRequired,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    initialValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    children: PropTypes.node,
    maxOptionsHeight: PropTypes.number,
    optionsWidth: PropTypes.number,
    multiple: PropTypes.bool,
    selectionDisplay: PropTypes.element,
};

SelectInput.defaultProps = {
    className: '',
    onChange: () => {},
    multiple: false,
};

export default withFormElementContext(SelectInput);
