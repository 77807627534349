import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DropdownWindow from './DropdownWindow';

const DropdownAnchor = styled.div`
    position: relative;
`;

export default function Dropdown({
    className,
    isOpen,
    renderContent,
    onMaskClick,
    children,
    shadow,
    ...otherProps
}) {
    const [ anchorElement, setAnchorElement ] = useState(null);

    const setAnchorRef = useCallback((el) => {
        setAnchorElement(el);
    }, []);

    return (
        <DropdownAnchor ref={setAnchorRef} className={className}>
            {children}
            {isOpen && (
                <DropdownWindow
                    anchorElement={anchorElement}
                    onMaskClick={onMaskClick}
                    shadow={shadow}
                >
                    {renderContent()}
                </DropdownWindow>
            )}
        </DropdownAnchor>
    );
}

Dropdown.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    renderContent: PropTypes.func.isRequired,
    onMaskClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    shadow: PropTypes.bool,
};

Dropdown.defaultProps = {
    onMaskClick: () => { },
    shadow: false,
};
