export default function formatFiltersForRequest(filters) {
    let formatted = [];
    if (filters.slice) {
        formatted.push({ ...filters.slice, type: 'slice' });
    }
    if (filters.group) {
        formatted.push({ ...filters.group, type: 'group' });
    }
    return formatted;
}
