import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Layout = styled.div`
    position: relative;
    display: inline-block;

    /* custom checkbox */
    &::before {
        box-sizing: border-box;
        width: 8px;
        height: 8px;
        content: "\\e93b";
        font-family: "vorne-icon";
        font-size: 6px;
        line-height: 8px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-51%);

        border-radius: 2px;

        transition: color 0.3s, background-color 0.3s, border-color 0.3s;

        /* Checked */
        ${({ checked }) => checked && css`
            color: ${({ theme }) => theme.colors.palette.white};
            border: 1px solid ${({ theme }) => theme.colors.palette.blue.awesome};
            background-color: ${({ theme }) => theme.colors.palette.blue.awesome};

            ${({ disabled }) => disabled && css`
                border: 1px solid ${({ theme }) => theme.colors.darkText.disabled};
                background-color: ${({ theme }) => theme.colors.darkText.disabled};
            `}
        `}

        /* Unchecked */
        ${({ checked }) => !checked && css`
            color: transparent;
            border: 1px solid ${({ theme }) => theme.colors.darkText.mediumEmphasis};
            background-color: transparent;

            ${({ disabled }) => disabled && css`
                border: 1px solid ${({ theme }) => theme.colors.darkText.disabled};
            `}
        `}
    }

    ${({ focused, theme }) => focused && css`
        &::before {
            border-color: ${theme.colors.darkText.mediumEmphasis};
        }
    `}

    /* hide real checkbox */
    input[type="checkbox"] {
        opacity: 0;
        margin: 0;
        padding: 0;
        width:8px;

        cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    }
`;

/**
 * A custom checkbox input. This hides the real functional checkbox
 * and displays a custom one. For more cases prefer using the full
 * Checkbox component as it adds additional features to the
 * CheckboxInput such as labels and touch targets.
 *
 * @see Checkbox
 */
function CheckboxInput({
    name,
    readonly,
    disabled,
    value,
    onChange,
    className,
    style,
    ...otherProps
}) {
    const [focused, setFocused] = useState(false);

    // Disabled implies readonly
    const isReadonly = readonly || disabled;

    const handleOnFocus = useCallback(() => {
        setFocused(true);
    }, []);

    const handleOnBlur = useCallback(() => {
        setFocused(false);
    }, []);

    const handleOnChange = useCallback((event) => {
        if (!isReadonly) {
            onChange({
                target: {
                    name: event.target.name,
                    value: event.target.checked,
                },
            });
        }
    }, [isReadonly, onChange]);

    return (
        <Layout
            checked={value}
            disabled={disabled}
            focused={focused}
            className={className}
            style={style}
        >
            <input
                {...otherProps}
                name={name}
                checked={value}
                disabled={disabled}
                readOnly={isReadonly}
                type='checkbox'
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
            />
        </Layout>
    );
}

CheckboxInput.propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.bool.isRequired,

    onChange: PropTypes.func,

    className: PropTypes.string,
    style: PropTypes.object,
};

CheckboxInput.defaultProps = {
    disabled: false,
    readonly: false,
    name: '',

    onChange: () => {},
};

export default CheckboxInput;
