import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import LoadingIndicator from 'Components/loading-indicator/LoadingIndicator';
import ErrorLoadingMessage from 'Components/loading-indicator/ErrorLoadingMessage';

const Root = styled.div``;

const LoadingIndicatorWrapper = styled.div`
    padding: 16px;
    text-align: center;
`;

function LoadingWrapper({ loading, children, error, render, ...otherProps }) {
    let internalJsx;

    if (render && children) {
        console.error('Cannot supply both children and a render prop to LoadingWrapper.');
        return null;
    }

    if (loading) {
        internalJsx = (
            <LoadingIndicatorWrapper>
                <LoadingIndicator />
            </LoadingIndicatorWrapper>
        );
    }
    else if (error) {
        internalJsx = (
            <ErrorLoadingMessage>
                {error}
            </ErrorLoadingMessage>
        );
    }
    else {
        internalJsx = children || (render ? render() : null);
    }

    return (
        <Root {...otherProps}>
            {internalJsx}
        </Root>
    );
}

LoadingWrapper.defaultProps = {
    loading: true,
};

LoadingWrapper.propTypes = {
    children: PropTypes.node,
    render: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.string,
};

export default LoadingWrapper;
