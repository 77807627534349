import styled from 'styled-components';

export const FontWeight = {
    light: 200,
    regular: 400,
    medium: 700,
    bold: 800,
};

export const FontFamily = {
    primary: 'Roboto, Helvetica, sans-serif',
    secondary: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
    serif: 'Georgia, "Times New Roman", Times, serif',
};

// TITLES

export const T1 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 28px;
    font-weight: ${FontWeight.medium};
`;

export const T2 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 21px;
    font-weight: ${FontWeight.medium};
`;

export const T3 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 18px;
    font-weight: ${FontWeight.medium};
`;

export const T4 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 18px;
    font-weight: ${FontWeight.medium};
`;

export const T5 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 18px;
    font-weight: ${FontWeight.regular};
`;

export const T6 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 14px;
    font-weight: ${FontWeight.medium};
`;

// SUBTITLES

export const Subtitle1 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 14px;
    font-weight: ${FontWeight.regular};
`;

export const Subtitle2 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 12px;
    font-weight: ${FontWeight.medium};
`;

// BODY

export const Body1 = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 14px;
    font-weight: ${FontWeight.regular};
`;

export const Body2 = styled.span`
    font-family: ${FontFamily.secondary};
    font-size: 12px;
    font-weight: ${FontWeight.regular};
`;

// OTHER

export const Button = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 14px;
    font-weight: ${FontWeight.medium};
`;

export const Caption = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 12px;
    font-weight: ${FontWeight.medium};
`;

export const Overline = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 14px;
    font-weight: ${FontWeight.medium};
    text-transform: uppercase;
`;
