import { sendPostRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';
import { REQUEST_RESET } from 'Common/util/ResourceCollection';
import { REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';

export function verifyEmailChangeReset() {
    return {
        type: REQUEST_RESET,
        requestId: 'verifyEmailChange',
    };
}

export const verifyEmailChange = ({ token }) => requestActionCreator(
    {
        request: () => ({
            type: REQUEST_PENDING,
            requestId: 'verifyEmailChange',
        }),
        success: (payload) => ({
            type: REQUEST_SUCCESS,
            requestId: 'verifyEmailChange',
            payload,
        }),
        failure: (error) => ({
            type: REQUEST_FAILURE,
            requestId: 'verifyEmailChange',
            payload: { error },
        }),
    },
    () => sendPostRequest('/api/v1/accounts/verify-email-change', {
        body: { token },
    })
);

export default verifyEmailChange;
