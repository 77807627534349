import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getUsers, getHierarchy, getUsersReset, getHierarchyReset } from 'Redux/actions';

import List from 'Components/list/List';
import Page from 'Components/page/Page';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import PageContent from 'Components/page/PageContentStandard';
import UserListItem from 'Components/user-list/UserListItem';
import MultiRequestLoadingWrapper from 'Components/loading-indicator/MultiRequestLoadingWrapper';
import ContainedButton from 'Components/button/ContainedButton';
import { Spacing } from 'Components/common/common';
import { getUsersList, selectUsersRequest } from 'Redux/reducers/users';
import { selectHierarchyRequest, selectHierarchyNode } from 'Redux/reducers/hierarchy';
import PageDescription from 'Components/page/PageDescription';
import { bindActionCreators } from 'redux';
import { NodeType } from 'Components/hierarchy-editor/HierarchyNodeType';

const UserList = styled(List)``;

const AddUserButton = styled(ContainedButton)`
    margin-bottom: ${Spacing.formButtonMargin};
    text-decoration: none;
`;

export class UsersPagePresentation extends React.Component {
    static propTypes = {
        actions: PropTypes.shape({
            getUsers: PropTypes.func.isRequired,
            getUsersReset: PropTypes.func.isRequired,
            getHierarchy: PropTypes.func.isRequired,
            getHierarchyReset: PropTypes.func.isRequired,
        }).isRequired,
        requests: PropTypes.shape({
            getUsers: PropTypes.shape({
                status: PropTypes.string.isRequired,
                error: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }).isRequired,
            getHierarchy: PropTypes.shape({
                status: PropTypes.string.isRequired,
                error: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }).isRequired,
        }).isRequired,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string,
                    email_address: PropTypes.object.isRequired,
                    role: PropTypes.string.isRequired,
                    active: PropTypes.bool.isRequired,
                }),
            })
        ).isRequired,
        hierarchyStore: PropTypes.object.isRequired,
    };

    constructor() {
        super();
    }

    componentDidMount() {
        this.props.actions.getUsers();
        this.props.actions.getHierarchy();
    }

    componentWillUnmount() {
        this.props.actions.getUsersReset();
        this.props.actions.getHierarchyReset();
    }

    _renderUserList = () => {
        return (
            <React.Fragment>
                <AddUserButton Component={Link} to="/config/organization/add-user" color="primary">
                    Add User
                </AddUserButton>
                <UserList>
                    {this._renderUsers()}
                </UserList>
            </React.Fragment>
        );
    };

    _renderUsers() {
        const { hierarchyStore } = this.props;

        return this.props.users.map((userobj) => {
            let visibility = null;

            if (userobj.data.hierarchy_visibility !== null) {
                const hierarchyNode = selectHierarchyNode(
                    hierarchyStore,
                    userobj.data.hierarchy_visibility
                );

                visibility = (hierarchyNode.node_type === NodeType.DEVICE) ?
                    hierarchyNode.device.asset_name : hierarchyNode.node_name;
            }

            return (
                <UserListItem
                    key={`user-${userobj.data.id}`}
                    id={userobj.data.id}
                    name={userobj.data.name}
                    role={userobj.data.role}
                    email_address={userobj.data.email_address}
                    active={userobj.data.active}
                    visibility={visibility}
                />
            );
        });
    }

    render() {
        const {
            requests,
        } = this.props;
        return (
            <Page>
                <ConfigPageTitle>All Users</ConfigPageTitle>
                <PageDescription>
                    Use this page to manage the users in your organization.
                </PageDescription>
                <PageContent>
                    <MultiRequestLoadingWrapper
                        requests={[
                            requests.getUsers,
                            requests.getHierarchy,
                        ]}
                        render={this._renderUserList}
                    />
                </PageContent>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    const { users, hierarchy } = state;

    return {
        users: getUsersList(users),
        hierarchyStore: hierarchy,
        requests: {
            getUsers: selectUsersRequest(users, 'getUsers'),
            getHierarchy: selectHierarchyRequest(hierarchy, 'getHierarchy'),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getUsers: getUsers,
            getUsersReset: getUsersReset,
            getHierarchy: getHierarchy,
            getHierarchyReset: getHierarchyReset,
        }, dispatch)
    };
};

// connect the component to Redux
const UsersPage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersPagePresentation));

export default UsersPage;
