import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonLike from '../button/ButtonLike';
import { interceptProps } from 'Components/util/reactUtils';

const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    color: ${(p) => p.theme.colors.palette.black};
    background-color: ${(p) => p.theme.colors.palette.white};
`;

const PrimaryContent = styled(interceptProps(ButtonLike, ['paddingRight']))`
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: ${p => p.paddingRight}px;
`;

const isListItemSecondaryAction = function(child) {
    return React.isValidElement(child) &&
        typeof child.type.isListItemSecondaryAction === 'function' &&
        child.type.isListItemSecondaryAction();
};

function ListItem(props) {
    const {
        Component,
        children: childrenProp,
        selected,
        disabled,
        clickable,
        onClick,
        ...otherProps
    } = props;

    const children = React.Children.toArray(childrenProp);

    let secondaryAction = null;
    if (children.length > 0 && isListItemSecondaryAction(children[children.length - 1])) {
        secondaryAction = children.pop();
    }

    return (
        <Root>
            <PrimaryContent
                {...otherProps}
                Component={Component}
                paddingRight={secondaryAction ? 24 : 0}
                selected={selected}
                disabled={disabled}
                // onClick goes on primary so secondary action doesn't trigger it
                onClick={onClick}
                as={clickable ? undefined : 'div'} // `undefined` preserves original
            >
                {children}
            </PrimaryContent>
            {secondaryAction}
        </Root>
    );
}

ListItem.defaultProps = {
    Component: 'div',
    selected: false,
    disabled: false,
    clickable: true,
    onClick: () => {},
};

ListItem.propTypes = {
    Component: PropTypes.any,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    clickable: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
};

export default ListItem;
