import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormElementLabel from 'Components/form-element/FormElementLabel';
import PlainCheckboxInput from 'Components/checkbox/PlainCheckboxInput';
import { Link } from 'react-router-dom';

const TermsCheckboxContainer = styled.div`
    display: flex;
`;

const TermsCheckbox = styled(PlainCheckboxInput)`
`;

const TermsCheckboxLabel = styled(FormElementLabel)`
    display: inline-block;
    width: auto;
`;

export default class TermsAcceptanceInput extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { name, value, onChange, onBlur } = this.props;
        const termsCheckboxId = 'terms_accepted_checkbox';

        return (
            <TermsCheckboxContainer>
                <TermsCheckbox
                    id={termsCheckboxId}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <TermsCheckboxLabel htmlFor={termsCheckboxId}>
                    I have read and accept the <Link to='/about/terms-of-use'>Terms of Use</Link>.
                </TermsCheckboxLabel>
            </TermsCheckboxContainer>
        );
    }
}
