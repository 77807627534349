import React from 'react';
import styled from 'styled-components';

import { elementsOfType } from 'Components/util/propTypesUtils';
import TableColumn from 'Components/table/TableColumn';
import TableErrorColumn from './TableErrorColumn';
import TableHeaderColumn from './TableHeaderColumn';

const ThemedTableRow = styled.tr`

`;

function TableRow({ children, ...otherProps }) {
    return (
        <ThemedTableRow {...otherProps} >
            {children}
        </ThemedTableRow>
    );
}

TableRow.propTypes = {
    children: elementsOfType([
        TableColumn,
        TableHeaderColumn,
        TableErrorColumn,
    ]),
};

export default TableRow;
