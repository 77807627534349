import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageDescription from 'Components/page/PageDescription';
import PageContent from 'Components/page/PageContentStandard';
import ForgotPasswordForm from './ForgotPasswordForm';

const StyledForm = styled(ForgotPasswordForm)`
    display: block;
    width: 100%;
`;

class ForgotPasswordPage extends React.Component {

    constructor() {
        super();

        this.state = {
            success: false,
            successEmail: '',
        };

        this._handleFormSuccess = this._handleFormSuccess.bind(this);
    }

    _handleFormSuccess({ email }) {
        this.setState({
            success: true,
            successEmail: email,
        });
    }

    _renderPageTitle() {
        const { success } = this.state;
        return success ? 'Check your inbox!' : 'Reset your password';
    }

    _renderPageDescription() {
        const { success, successEmail } = this.state;

        if (success) {
            return (
                <React.Fragment>
                    If there is an account associated with the email address, we will send
                    you a link to <i>{successEmail}</i> to reset your password.
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    Enter your email address. If there is an account associated with the
                    email address, we will send you a link to reset your password.
                </React.Fragment>
            );
        }
    }

    _renderPageContent() {
        const { location } = this.props;
        const { success } = this.state;
        const initialEmail = location.state && location.state.email || '';

        if (success) {
            return null;
        }
        else {
            return (
                <StyledForm initialEmail={initialEmail} onSuccess={this._handleFormSuccess} />
            );
        }
    }

    render() {
        return (
            <Page>
                <PageTitle>{this._renderPageTitle()}</PageTitle>
                <PageDescription>{this._renderPageDescription()}</PageDescription>
                <PageContent>{this._renderPageContent()}</PageContent>
            </Page>
        );
    }
}

ForgotPasswordPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            email: PropTypes.string
        })
    }).isRequired,
};

export default withRouter(ForgotPasswordPage);
