import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DraggableList from 'Common/components/DraggableList/DraggableList';
import Button from 'Common/components/Button';
import DraggableChip from 'Common/components/Chip/DraggableChip';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import FieldBuilderSelect from './FieldBuilderSelect';

const Root = styled.div``;

const TouchTargetSpacing = styled.div`
    box-sizing: border-box;
    height: 44px;
    /* Center the button within the touch target size */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    /* Magic number to align button with chip drag handles. Sorry */
    padding-left: 12px;
`;

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 *
 * Filter out list of fields from a given list of fields.
 * 
 * @param {Array} fields array of fields
 * @param {Array} value array of fields to filter out
 */
export function filterFields (fields, value) {
    let valueFieldsByName = value.reduce((byName, col) => {
        byName[col.name] = col;
        return byName;
    }, {});

    return fields.filter(field => {
        return !valueFieldsByName[field.name];
    });
}

/**
 *
 * Filter out list of fields from a given list of fields that have been grouped.
 * 
 * @param {Array} fields array of fields
 * @param {Array} value array of fields to filter out
 */
export function filterGroupedFields (fields, value) {
    let filteredFields = [];
    let valueFieldsByName = value.reduce((byName, col) => {
        byName[col.name] = col;
        return byName;
    }, {});


    fields.forEach((group) => {
        let filteredGroup = { ...group };
        filteredGroup.entries = filteredGroup.entries.filter((field) => {
            return !valueFieldsByName[field.name];
        });

        if (filteredGroup.entries.length > 0) {
            filteredFields.push(filteredGroup);
        }
    });

    return filteredFields;
}
/**
 * A control used to add and arrange fields (with drag and drop chips). Used for various
 * widgets and pages.
 *
 * @param {Array} value An array of the currently selected fields.
 * @param {Array} metricFields An array of available metric fields.
 * @param {Array} [dimensionFields] An array of available metric fields.
 * @param {Function} onChange An event handler for field changes, takes an event with the
 *     new value as an array of the fields that are now selected.
 * @param {String} [name] Optional name field to include in the onChange event.
 * @param {Boolean} [showAddButton=false] True to show a button for adding another field.
 * @param {String} [addButtonText] Text to be shown on the button which adds a new field.
 * @param {Number} [maxFields=Infinity] Max number of fields that can be added.
 * @param {Function} [onFieldDoubleClick] Called when a fields chip is double-clicked.
 * @param {Function} [renderFieldText] Called with a field from the value array to allow
 *     custom rendering of field text.
 */
export default class FieldBuilder extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isPickerOpen: false,
        };
    }

    _onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newValue = reorder(
            this.props.value,
            result.source.index,
            result.destination.index
        );

        this._triggerChangeEvent(newValue);
    };

    _triggerChangeEvent = (newValue) => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: newValue,
            },
        });
    };

    _handleDeleteButtonClick = (event) => {
        const newValue = this.props.value.filter((item) => {
            return item.name !== event.target.name;
        });

        this._triggerChangeEvent(newValue);
    };


    _setIsPickerOpen(isPickerOpen) {
        this.setState({ isPickerOpen });
    }

    _closePicker = () => {
        this._setIsPickerOpen(false);
    };

    _openPicker = () => {
        this._setIsPickerOpen(true);
    };

    _pickField = (event) => {
        const { target } =  event;

        this._closePicker();

        this._triggerChangeEvent([
            ...this.props.value,
            target.value,
        ]);
    };

    _renderPicker = () => {
        const {
            metricFields,
            dimensionFields,
            value,
        } = this.props;

        return (
            <FieldBuilderSelect
                metricFields={filterGroupedFields(metricFields, value)}
                categoryFields={filterFields(dimensionFields, value)}
                value={null} // No current value; always selecting something new.
                onChange={this._pickField}
            />
        );
    };

    render() {
        const {
            showAddButton,
            addButtonText,
            value,
            maxFields,
            minFields,
            onFieldDoubleClick,
        } = this.props;

        let {
            renderFieldText,
        } = this.props;

        const {
            isPickerOpen,
        } = this.state;

        const disabled = value.length >= maxFields;
        const canDelete = value.length >= minFields;

        // The user of this component can supply a custom renderer for the field text
        // which takes in the field and returns a string or React component for us to
        // render. If this custom render function is not supplied default to using
        // the displayName of the field.
        //
        if (!renderFieldText) {
            renderFieldText = (field) => {
                return field.displayName;
            };
        }

        return (
            <Root>
                {showAddButton && (<TouchTargetSpacing>
                    <Dropdown
                        isOpen={!disabled && isPickerOpen}
                        onMaskClick={this._closePicker}
                        renderContent={this._renderPicker}
                    >
                        <Button
                            onClick={this._openPicker}
                            disabled={disabled}
                        >
                            {addButtonText}
                        </Button>
                    </Dropdown>
                </TouchTargetSpacing>)}
                <DraggableList onDragEnd={this._onDragEnd}>
                    {value.map((item) => {
                        const text = renderFieldText(item);

                        return (
                            <DraggableChip
                                key={item.name}
                                name={item.name}
                                text={text}
                                canDelete={canDelete}
                                onDeleteClick={this._handleDeleteButtonClick}
                                onDoubleClick={() => (onFieldDoubleClick({ target: { value: item.name } }))}
                            />
                        );
                    })}
                </DraggableList>
            </Root>
        );
    }
}

FieldBuilder.propTypes = {
    name: PropTypes.string,
    addButtonText: PropTypes.string,
    showAddButton: PropTypes.bool,
    dimensionFields: PropTypes.arrayOf(PropTypes.object.isRequired),
    metricFields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            isDummyGroup: PropTypes.bool.isRequired,
            entries: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }).isRequired
            ).isRequired,
        }).isRequired
    ).isRequired,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string,
            type: PropTypes.string,
        }).isRequired,
    ).isRequired,
    maxFields: PropTypes.number,
    minFields: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onFieldDoubleClick: PropTypes.func,
    renderFieldText: PropTypes.func,
};

FieldBuilder.defaultProps = {
    addButtonText: '',
    showAddButton: false,
    maxFields: Infinity,
    minFields: 0,
    metricFields: [],
    dimensionFields: [],
    onFieldDoubleClick: () => {},
};
