import { getQualitativeColor } from 'Common/util/color/util';

/**
 * Given an array of values for a dimension visualization, fill in any dimensions
 * that do not have colors (at `dimension.color`) with colors chosen from the
 * qualitative color palette.
 *
 * If maxElements is provided, then any dimensions that would be merged in the
 * visualization (when values.length > maxElements) will be given the color
 * specified by `otherColor`.
 *
 * @param {Object} opts
 * @param {Object[]} opts.values The values to have colors added.
 * @param {Number} [opts.maxElements] Max elements show in the visualization.
 * @param {String} [opts.otherColor] A CSS color string to be used for any values
 *      grouped into the "other" value due to maxElements. 
 * @returns {Object[]} Values with colors for every dimension.
 */
export default function addDimensionColors({
    values,
    maxElements = null,
    otherColor = null,
}) {
    if (maxElements && (!Number.isInteger(maxElements) || maxElements <= 0)) {
        throw new Error(`maxElements must be a positive integer or null. Got '${maxElements}'`);
    }

    if (maxElements && typeof otherColor !== 'string') {
        throw new Error(`otherColor is required if maxElements is set.`);
    }

    // Only enable 'other' coloring when maxElements is set and the number
    // of values exceeds maxElements.
    const enableOtherColoring = maxElements && values.length > maxElements;

    return values.map((value, index) => {
        const isOther = enableOtherColoring && index >= maxElements - 1;

        let color;

        if (isOther) {
            color = otherColor;
        }
        else if (value.dimension?.rawValue?.color) {
            color = value.dimension.rawValue.color;
        }
        else {
            color = getQualitativeColor(index);
        }

        return {
            ...value,
            dimension: {
                ...value.dimension,
                rawValue: {
                    ...value.dimension.rawValue,
                    color
                }
            },
        };
    });
}
