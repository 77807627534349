import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uniqueId from 'lodash/uniqueId';

import TextField from 'Common/components/TextField';
import Button from 'Common/components/Button';
import { KPI_GROUP_COLUMNS_PROPTYPE, HEADING_TYPE } from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidgetConstants';
import { useTranslator } from 'Common/hooks/useTranslation';

const Root = styled.div`
    padding: 5px 10px;
`;

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 150px;
`;

const HeadingLabel = styled.div`
    flex: 0 0 auto;
    padding-right: 10px;
    color: ${({theme}) => theme.colors.darkText.highEmphasis};
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ApplyContainer = styled.div`
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
`;

/**
 * A component to edit the heading text in a column in Kpi Group.
 *
 */
const KPIGroupHeadingOption = ({
    selectedColumnIndex,
    selectedColumns,
    onChange,
}) => {
    const translator = useTranslator();
    const initialHeadingColumn = Number.isFinite(selectedColumnIndex) ? selectedColumns[selectedColumnIndex] : null;
    const [heading, setHeading] = useState(initialHeadingColumn?.displayName || '');
    const [headingHasChanged, setHeadingHasChanged] = useState(false);
    const canApplyChanges = heading !== initialHeadingColumn && !!heading.trim();

    const onHeadingChange = useCallback((e) => {
        setHeading(e.target.value || '');
        setHeadingHasChanged(true);
    }, [setHeading, setHeadingHasChanged]);

    const handleApply = () => {
        const column = {
            type: HEADING_TYPE,
            name: uniqueId('kpigroupheading_'),
            displayName: heading.trim(),
        };
        let newSelectedColumns = selectedColumns;
        if (!Number.isFinite(selectedColumnIndex)) {
            newSelectedColumns = selectedColumns.concat([column]);
        }
        else {
            if (selectedColumns.length < selectedColumnIndex) {
                console.warn(`Can not update column at index ${selectedColumnIndex} there are only ${selectedColumns.length} columns.`);
            }
            else {
                newSelectedColumns = [].concat(selectedColumns);
                newSelectedColumns.splice(selectedColumnIndex, 1, column);
            }
        }
        onChange({
            target: {
                value: newSelectedColumns,
            }
        });
    };

    return (
        <Root>
            <HeadingContainer>
                <HeadingLabel>{translator('widget.kpi_group.heading')}</HeadingLabel>
                <TextField
                    name='heading'
                    onChange={onHeadingChange}
                    value={heading}
                    placeholder={translator('widget.kpi_group.enter_text_literal')}
                    error={!heading && headingHasChanged ? translator('value_cannot_be_blank_error') : ''}
                />
            </HeadingContainer>
            <ApplyContainer>
                <Button
                    onClick={handleApply}
                    disabled={!canApplyChanges}
                >
                    {translator('apply_literal')}
                </Button>
            </ApplyContainer>
        </Root>
    );
};

KPIGroupHeadingOption.propTypes = {
    selectedColumnIndex: PropTypes.number,
    selectedColumns: KPI_GROUP_COLUMNS_PROPTYPE.isRequired,

    onChange: PropTypes.func.isRequired,
};

export default KPIGroupHeadingOption;
