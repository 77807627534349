import isString from 'lodash/isString';
import size from 'lodash/size';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import isFinite from 'lodash/isFinite';

/**
 * Defines the Qualitative Palette, colors that look good together but are not
 * related.
 */
 export const Q_PALETTE = Object.freeze([
    '#5cbae6',
    '#b6d957',
    '#fac364',
    '#d998cb',
    '#ccc5a8',
    '#8cd3ff',
    '#f2d249',
    '#93b9c6',
    '#52bacc',
    '#dbdb46',
    '#98aafb'
]);

/**
 * Get a qualitative color for an index; will automatically repeat colors when
 * the index goes beyond the number of colors in the palette.
 *
 * @see Q_PALETTE
 *
 * @param {Number} index Integer index into the color palette.
 * @returns {String} Qalitative color in #FFFFFF format.
 */
 export function getQualitativeColor(index) {
    const colorIndex = index % Q_PALETTE.length;
    return Q_PALETTE[colorIndex];
}

/**
 * Convert CSS color to an RGB object. Includes the alpha channel if present.
 * Inspired by: https://stackoverflow.com/a/21966100
 *
 * @param {String} input CSS color string.
 *
 * @returns {Object} RGB object of the form { r: \d+, g: \d+, b: \d+, [a]: \d+ }
 */
export function cssToRGB(input) {
    if (!isString(input)) {
        throw new Error('cssToRGB requires a String for input!');
    }

    let inputLength = size(input),
        rv = null;

    if (input.startsWith('#') && (inputLength === 10 || inputLength === 7 || inputLength === 4)) {
        // #xxx or #xxxxxx or #xxxxxxxxx ?
        let colLength = (input.length - 1) / 3,
            factor = [17, 1, 0.062272][colLength - 1];

        rv = {
            r: Math.round(Number.parseInt(input.substr(1, colLength), 16) * factor),
            g: Math.round(Number.parseInt(input.substr(1 + colLength, colLength), 16) * factor),
            b: Math.round(Number.parseInt(input.substr(1 + (2 * colLength), colLength), 16) * factor)
        };
    }
    else if (input.startsWith('rgb')) {
        let rgbArray = input.split('(')[1].split(')')[0].split(',').map(Math.round);

        rv = {
            r: rgbArray[0],
            g: rgbArray[1],
            b: rgbArray[2],
        };

        // Are we rgba? If so include the alpha value
        if (input.startsWith('rgba')) {
            rv.a = rgbArray[3];
        }
    }
    else {
        throw new Error(input + ' is an invalid CSS color!');
    }

    return rv;
}

export function interpolateColor(rgb1, rgb2, factor) {
    let result = { ...rgb1 };

    ['r', 'g', 'b'].forEach((comp) => {
        result[comp] = Math.round(
            result[comp] + factor * (rgb2[comp] - rgb1[comp])
        );
    });

    return result;
}

/**
 * Given an RGB object, return a CSS hex color string.
 *
 * @param {Object} rgb RGB object with an r, g, and b component.
 *
 * @returns {String} CSS color of the form #RRGGBB
 */
export function rgbToCss(rgb) {
    if (!isObject(rgb)) {
        throw new Error('RGB must be an object!');
    }

    let decimalToHexString = function(decimal) {
        let hexStr = decimal.toString(16);

        // Ensure leading 0
        return hexStr.length === 1 ? ('0' + hexStr) : hexStr;
    };

    let rr = decimalToHexString(rgb.r || 0),
        gg = decimalToHexString(rgb.g || 0),
        bb = decimalToHexString(rgb.b || 0);

    return '#' + rr + gg + bb;
}

/**
 * A simple function to brighten or darken a color a certain amount.
 *
 * @param {String/Object} color CSS color or RGB object to shade.
 * @param {Number} amount Amount in which to brighten/darken the color. Use a positive
 *     value for brightening and a negative value for darkening.
 *
 * @returns {String/Object} Either a CSS color or RGB object depending on the type of the
 *     given color.
 */
export function adjustBrightness(color, amount) {
    let rgb,
        returnCss = false;

    if (isString(color)) {
        rgb = cssToRGB(color);
        returnCss = true;
    }
    else if (isObject(color)) {
        rgb = color;
    }
    else {
        throw new Error('Provided color is not a color or RGB object!');
    }

    if (!isNumber(amount) || !isFinite(amount)) {
        throw new Error('Amount must be a finite number!');
    }

    let shade = function(component) {
        let newComponent = component + amount;

        if (newComponent > 255) { newComponent = 255; }
        else if (newComponent < 0) { newComponent = 0; }

        return newComponent;
    };

    let newRgb = {
        r: shade(rgb.r),
        g: shade(rgb.g),
        b: shade(rgb.b)
    };

    return returnCss ? rgbToCss(newRgb) : newRgb;
}

export function getTextColorForBackground(backgroundColor) {
    // Based on https://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
    const {r, g, b} = cssToRGB(backgroundColor);
    const brightness = Math.sqrt(
        (r * r * 0.241) +
        (g * g * 0.691) +
        (b * b * 0.068)
    );

    return brightness < 130 ? '#ffffff' : '#000000';
}

/**
 * Takes in a CSS color (hex or rgb) and an alpha (opacity) value and outputs a CSS-friendly
 * rgba color.
 *
 * @param {String} cssColor Hex or rgb CSS color string.
 * @param {Number} [alpha=1.0] Alpha channel value from 0.0 -> 1.0
 *
 * @returns {String} CSS-friendly rgba(r,g,b,a) color string.
 */
export function addAlphaToCssColor(cssColor, alpha = 1.0) {
    let rgba = cssToRGB(cssColor);

    rgba.a = alpha;

    return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
}
