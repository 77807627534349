import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ButtonBase from '../Button/ButtonBase';
import SelectDisplay from './SelectDisplay';

const StyledSelectDisplay = styled(SelectDisplay)``;

const RootButton = styled(ButtonBase)`
    box-sizing: border-box;

    transition: background-color 0.3s;

    border-radius: 4px;

    /* For touch targets */
    min-width: 40px;

    :enabled {
        /* Regular, non-error state */
        ${({hasError}) => !hasError && css`
            background-color: ${props => props.theme.colors.palette.grey.cloud};

            :hover {
                background-color: ${({theme}) => theme.colors.palette.grey.silver};
            }
        `}

        /* Error state */
        ${({hasError}) => hasError && css`
            background-color: ${({theme}) => theme.colors.palette.error.helios};
        `}
    }

    :disabled {
        background-color: ${props => props.theme.colors.palette.grey.cloud};
    }
`;

function SelectDropdownButton({
    disabled,
    children,
    error,
    isPlaceholderText,
    size,
    ...otherProps
}) {
    const hasError = Boolean(error);

    return (
        <RootButton
            hasError={hasError}
            disabled={disabled}
            {...otherProps}
        >
            <StyledSelectDisplay
                disabled={disabled}
                hasError={hasError}
                isPlaceholderText={isPlaceholderText}
                size={size}
            >
                {children}
            </StyledSelectDisplay>
        </RootButton>
    );
}

SelectDropdownButton.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    error: PropTypes.node,
    isPlaceholderText: PropTypes.bool,
    size: PropTypes.oneOf([
        'medium',
        'large',
    ]),
};

SelectDropdownButton.defaultProps = {
    disabled: false,
    isPlaceholderText: false,
    size: 'medium'
};

export default SelectDropdownButton;
