import React from 'react';
import styled from 'styled-components';
import { elementOfType } from 'Components/util/propTypesUtils';
import NavSeparator from './NavSeparator';
import NavLink from './NavLink';

const NavListItem = styled.li`
    margin: 0;
    padding: 0;
    list-style: none;
`;

function NavItem({ children, ...otherProps }) {
    return (
         <NavListItem {...otherProps}>
             {children}
         </NavListItem>
    );
}

NavItem.propTypes = {
    children: elementOfType([
        NavSeparator,
        NavLink,
    ]),
};

export default NavItem;
