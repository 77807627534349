import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ErrorNotice from 'Components/notice/ErrorNotice';

const StyledErrorNotice = styled(ErrorNotice)`
    margin-top: 10px;
`;

const ErrorList = styled.ul`
    padding-inline-start: 30px;
`;

const ErrorListItem = styled.li`
`;

function renderPayload(payload) {
    const keys = Object.keys(payload);

    if (keys.length) {
        return (
            <ErrorList>
                {keys.map((key) => {
                    return (
                        <ErrorListItem key={key}>
                            {/* Just use the first error for the key */}
                            {payload[key][0]}
                        </ErrorListItem>
                    );
                })}
            </ErrorList>
        );
    }
    else {
        return null;
    }
}

function FormError({ error, ...otherProps }) {
    if (error) {
        const { message, payload } = error;

        return (
            <StyledErrorNotice {...otherProps}>
                {message}
                {payload && renderPayload(payload)}
            </StyledErrorNotice>
        );
    }
    else {
        return null;
    }
}

FormError.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        status_code: PropTypes.number,
        payload: PropTypes.objectOf(
            PropTypes.arrayOf(PropTypes.string),
        ),
    }),
};

export default FormError;
