import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { childrenAreOneOf } from 'Common/util/propTypeHelpers';

import MenuItem from './MenuItem';
import MenuList from './MenuList';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import MenuButton from './MenuButton';
import Icon from 'Common/components/Icon';

/**
 * MenuToolbarOverflow
 *
 * Handles the items that get overflowed in MenuToolbar.
 *
 */

const StyledDropdown = styled(Dropdown)`
    display: inline-block;
`;

export default function MenuToolbarOverflow(props) {
    const { children, buttonStyle } = props;

    const [isOpen, setIsOpen] = useState(false);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const renderContent = useCallback(() => {
        return React.Children.map(children, (child) => {
            let childProps = { ...child.props, onClose };
            const isDropdown = !!child.props.children;
            if (isDropdown) {
                return (<MenuList {...childProps}/>);
            }
            else {
                return (<MenuItem {...childProps}/>);
            }
        });
    }, [children, onClose]);

    return (
        <StyledDropdown
            isOpen={isOpen}
            onMaskClick={onClose}
            renderContent={renderContent}
        >
            <MenuButton
                onClick={onOpen}
                icon={(<Icon type="fontAwesome" iconName="ellipsis-v"/>)}
                text=""
                style={buttonStyle}
            />
        </StyledDropdown>
    );
}


MenuToolbarOverflow.propTypes = {
    buttonStyle: PropTypes.object,
    children: childrenAreOneOf({
        elementTypes: [],
        propShapes: [
            // dropdown:
            [
                'text',
                'children',
            ],
            // item:
            [
                'onClick',
                'text',
            ],
        ],
    }),
};

MenuToolbarOverflow.defaultProps = {
    buttonStyle: {},
};
