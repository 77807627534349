import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import omit from 'lodash/omit';

import { filterFields, filterGroupedFields } from 'Common/components/FieldBuilder/FieldBuilder';
import FieldBuilderTypeSelect, { flattenEntries, SEPARATOR_TYPE } from 'Common/components/FieldBuilderTypeSelect/FieldBuilderTypeSelect';
import mergeMetricAndMetricSet, { filterCombinedMetricAndMetricSets } from 'Common/components/ColumnControl/mergeMetricAndMetricSet';
import MetricAndMetricSetOption from 'Common/components/ColumnControl/MetricAndMetricSetOption';
import KPIGroupHeadingOption from './KPIGroupHeadingOption';
import { KPI_GROUP_COLUMNS_PROPTYPE, HEADING_TYPE, BLANK_ROW_TYPE, FIELD_TYPE } from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidgetConstants';
import { useTranslator } from 'Common/hooks/useTranslation';
import SparkDimensionColumnSelector from './SparkDimensionColumnSelector';

const METRIC = 'metric';
const CATEGORY_VALUE = 'category_value';
const METRIC_SET = 'metric_set';

function createKeyFromColumn(column) {
    return `${column.channel}_${column.name}`;
}

/**
 * This generates the list of available types and searchable fields.
 *
 * @returns {Object} Shape: {
 *     primary {Array}: The primary menu items for the main menu,
 *     searchable {Array}: The menu items when searching in the the searchable menu,
 * }
*/
function generateMenuOptions(fieldOptions = {}, selectedColumns = [], translator) {
    let primaryMenuOptions = [];
    let searchableMenuOptions = [];

    const generateOptions = ({fields, searchFilter = filterFields, name, displayName, hasChildren}) => {
        const filteredFields = searchFilter(fields, selectedColumns);

        searchableMenuOptions = searchableMenuOptions
            .concat(flattenEntries(filteredFields))
            .map((option) => {
                let displayType = '';
                if (option.fieldType === 'metric') {
                    displayType = translator('metric_literal');
                }
                else if (option.fieldType === 'category_value') {
                    displayType = translator('category_literal');
                }
                else if (option.fieldType === 'metric_set') {
                    displayType = translator('metric_set');
                }

                return { ...option, displayType };
            });

        primaryMenuOptions.push({
            name,
            displayName,
            disabled: filteredFields.length === 0,
            hasChildren: hasChildren
        });
    };

    const generateSeparator = () => {
        const numberOfOptions = primaryMenuOptions.length;

        // Never start with a separator and never put two separators in a row
        if (numberOfOptions !== 0
            && primaryMenuOptions[numberOfOptions - 1].type !== SEPARATOR_TYPE)
        {
            primaryMenuOptions.push({
                type: SEPARATOR_TYPE,
            });
        }
    };

    generateOptions({
        name: METRIC,
        fields: mergeMetricAndMetricSet(fieldOptions.metric || [], fieldOptions.metric_set || []),
        searchFilter: filterCombinedMetricAndMetricSets,
        displayName: translator('metric_literal'),
        hasChildren: true,
    });

    if (fieldOptions.category_value) {
        generateOptions({
            name: CATEGORY_VALUE,
            fields: fieldOptions.category_value,
            searchFilter: filterGroupedFields,
            displayName: translator('category_literal'),
            hasChildren: true,
        });
    }

    generateSeparator();

    generateOptions({
        name: BLANK_ROW_TYPE,
        fields: [{
            name: BLANK_ROW_TYPE,
            displayName: translator('widget.kpi_group.blank_row'),
        }],
        displayName: translator('widget.kpi_group.blank_row'),
        hasChildren: false,
    });

    generateOptions({
        name: HEADING_TYPE,
        fields: [{
            name: HEADING_TYPE,
            displayName: translator('widget.kpi_group.heading'),
        }],
        displayName: translator('widget.kpi_group.heading'),
        hasChildren: true,
    });

    return {
        primary: primaryMenuOptions,
        searchable: searchableMenuOptions,
    };
}

const KPIGroupFieldsOption = ({
    fieldOptions,
    selectedColumns,
    onChange,
    transitionForward,
    initialMenuSelection
}) => {
    const translator = useTranslator();

    // the different types of fields available
    // for column selection and their respective lists
    const menuOptions = useMemo(() => {
        return generateMenuOptions(fieldOptions, selectedColumns, translator);
    }, [fieldOptions, selectedColumns, translator]);

    const handleOnChange = useCallback((e) => {
        // three options user selected:
        // add blank row
        // further explore that column type
        // add specific field
        if (e.target.type === 'main' && e.target.value.name === BLANK_ROW_TYPE) {
            onChange({
                target: {
                    value: selectedColumns.concat([{
                        type: BLANK_ROW_TYPE,
                        name: uniqueId('kpigroupseparator_'),
                        displayName: translator('widget.kpi_group.blank_row'),
                    }]),
                }
            });
        }
        else if (e.target.type === 'main' || e.target.value.fieldType === CATEGORY_VALUE) {
            // we keep the users selection highlighted if they end up traveling back here
            let menuSelection = e.target.value.name;
            let newControlOptions = null;

            if (menuSelection === METRIC) {
                newControlOptions = { control: MetricAndMetricSetOption, props: {}, title: translator('add_field_template', { field: translator('metric_literal')}) };
            }
            else if (menuSelection === CATEGORY_VALUE) {
                newControlOptions = { control: SparkDimensionColumnSelector, props: { startingValue: { metric: 'duration' } }, title: translator('add_field_template', { field: translator('category_literal')}) };
            }
            else if (menuSelection === HEADING_TYPE) {
                newControlOptions = { control: KPIGroupHeadingOption, props: {}, title: translator('add_field_template', { field: translator('widget.kpi_group.heading')}) };
            }
            else {
                // displayType was added so lets remove it
                const column = e.target.type === 'main' ? null : omit(e.target.value, 'displayType');
                newControlOptions = { control: SparkDimensionColumnSelector, props: column ? { startingValue: { name: column.name, metric: 'duration' } } : {}, title: translator('add_field_template', { field: translator('category_literal')}) };
            }

            if (newControlOptions) {
                transitionForward(
                    newControlOptions,
                    { control: KPIGroupFieldsOption, props: { initialMenuSelection: menuSelection }, title: translator('add_row_literal') }
                );
            }
        }
        else {
            const column = e.target.value;
            if (column.fieldType === METRIC_SET) {
                const newColumns = column.columns;
                let updatedColumns = [ ...selectedColumns ];

                let selectedColumnsByName = selectedColumns.reduce((byName, col) => {
                    const key = createKeyFromColumn(col);
                    byName[key] = col;
                    return byName;
                }, []);

                // for every column in the new metric set, check if it already exists in the table.
                // If not, concat the new column onto the array

                newColumns.forEach(newColumn => {
                    if (!selectedColumnsByName[createKeyFromColumn(newColumn)]) {
                        updatedColumns.push({
                            type: FIELD_TYPE,
                            name: newColumn.name,
                            channel: newColumn.channel,
                            displayName: newColumn.displayName,
                            fieldType: newColumn.fieldType
                        });
                    }
                });

                onChange({
                    target: {
                        value: updatedColumns,
                    }
                });
            }
            else {
                const modifiedColumn = {
                    type: FIELD_TYPE,
                    name: column.name,
                    channel: column.channel,
                    displayName: column.displayName,
                    fieldType: column.fieldType
                };
                onChange({
                    target: {
                        value: selectedColumns.concat([modifiedColumn]),
                    }
                });
            }
        }
    }, [onChange, transitionForward, selectedColumns, translator]);

    return (
        <FieldBuilderTypeSelect
            options={menuOptions.primary}
            searchOptions={menuOptions.searchable}
            instruction={translator('control.table.column_primary_instruction')}
            value={initialMenuSelection || null}
            onChange={handleOnChange}
        />);
};

KPIGroupFieldsOption.propTypes = {
    fieldOptions: PropTypes.shape({
        metric: PropTypes.array,
        category_value: PropTypes.array,
        metric_set: PropTypes.array,
    }).isRequired,
    initialMenuSelection: PropTypes.string,
    selectedColumns: KPI_GROUP_COLUMNS_PROPTYPE.isRequired,

    onChange: PropTypes.func.isRequired,
    transitionForward: PropTypes.func.isRequired,
};

export default KPIGroupFieldsOption;
