import styled from 'styled-components';
import { Body1 } from 'Components/typography/typography';

const SectionDescription = styled(Body1)`
    display: block;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    margin-bottom: 16px;

    > p:first-of-type {
        margin-block-start: 0;
    }
    > p:last-of-type {
        margin-block-end: 0;
    }
`;

export default SectionDescription;
