import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import { Subtitle1, Caption } from 'Components/typography/typography';
import ListItem from 'Components/list/ListItem';
import ListItemText from 'Components/list/ListItemText';
import ListItemRightComponent from 'Components/list/ListItemRightComponent';
import FaIcon from 'Components/icon/FaIcon';
import { getRoleDisplayText } from 'Components/app/roles';

const FirstLine = styled(Subtitle1)``;
const SecondLine = styled(Caption)``;
const RoleText = styled.span``;
const AccessText = styled.span``;
const Spacer = styled.div``;
const EmailPendingText = styled.span``;

const StyledListItem = styled(ListItem)`
    text-decoration: none;
    color: ${props => props.theme.colors.darkText.highEmphasis};

    ${FirstLine} {
        color: ${props => props.theme.colors.darkText.highEmphasis};

        ${EmailPendingText} {
            color: ${props => props.theme.colors.darkText.disabled};
        }
    }

    ${SecondLine} {
        color: ${props => props.theme.colors.darkText.highEmphasis};

        ${RoleText} {
            color: ${props => props.theme.colors.darkText.mediumEmphasis};
        }

        ${AccessText} {
            color: ${props => props.theme.colors.darkText.mediumEmphasis};
        }

        ${Spacer} {
            display: inline-block;
            padding-left: 2px;
        }

    }



    &.disabled {
        color: ${props => props.theme.colors.darkText.disabled};

        ${FirstLine} {
            color: ${props => props.theme.colors.darkText.disabled};
        }

        ${SecondLine} {
            color: ${props => props.theme.colors.darkText.disabled};

            ${RoleText} {
                color: ${props => props.theme.colors.darkText.disabled};
            }
        }
    }
`;

const StyledIcon = styled.span`
    font-size: 24px;
    color: ${(props) => props.theme.colors.editorGreen};
    text-decoration: none;
`;

function UserListItem(props) {
    const {
        className: classNameProp,
        id,
        email_address,
        name,
        role,
        active,
        visibility,
        ...otherProps
    } = props;

    let roleString = '';

    try {
        roleString = getRoleDisplayText(role);
    }
    catch (err) {
        console.error(err);
        roleString = role;
    }

    let visibilityText;

    if (visibility === null) {
        visibilityText = <i>None</i>;
    }
    else {
        visibilityText = visibility;
    }

    const className = classNames(classNameProp, { disabled: !active });

    return (
        <StyledListItem className={className} {...otherProps} Component={Link} to={`/config/organization/users/${id}`}>
            <ListItemText>
                <FirstLine>
                    {email_address.email}
                    {!email_address.verified && (
                        <EmailPendingText>
                            {' (email address not verified)'}
                        </EmailPendingText>
                    )}
                </FirstLine>
                <SecondLine>
                    {name}
                    <RoleText>
                        {` — ${roleString}`}
                        {!active && ' (disabled)'}
                    </RoleText>
                    <Spacer />
                    <AccessText>(Visibility: {visibilityText})</AccessText>
                </SecondLine>
            </ListItemText>
            <ListItemRightComponent>
                <StyledIcon>
                    <FaIcon i="pencil-square-o" />
                </StyledIcon>
            </ListItemRightComponent>
        </StyledListItem>
    );
}

UserListItem.defaultProps = {
    name: '',
};

UserListItem.propTypes = {
    id: PropTypes.number.isRequired,
    email_address: PropTypes.shape({
        email: PropTypes.string.isRequired,
        verified: PropTypes.bool.isRequired,
    }).isRequired,
    name: PropTypes.string,
    visibility: PropTypes.string,
    role: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    className: PropTypes.string
};

export default UserListItem;
