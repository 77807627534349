import { useEffect } from 'react';
import RequestState from 'Common/types/RequestState/RequestState';

export default function useKpiWidgetPrintStatus({
    disabled = false,
    setWidgetPrintStatus,
    kpiWidgetDataRequest,
    timeRangePrimaryRequest,
    timeRangeSecondaryRequest,
}) {
    // Effect to keep the widget's print status up to date.
    useEffect(() => {
        if (disabled) {
            return;
        }

        setWidgetPrintStatus(RequestState.areAllRequestsCompleted([
            kpiWidgetDataRequest,
            timeRangePrimaryRequest,
            timeRangeSecondaryRequest,
        ]));
    }, [ disabled, kpiWidgetDataRequest, timeRangePrimaryRequest, timeRangeSecondaryRequest, setWidgetPrintStatus ]);
}
