import React from 'react';
import styled from 'styled-components';
import Icon from 'Common/components/Icon';

const StyledIcon = styled(Icon)`
    display: inline-block;
    padding-left: 4px;
    cursor: pointer;

    color: ${({theme}) => theme.colors.palette.grey.ash};
`;

export default function TrendNotAvailableIcon(props) {
    return (
        <StyledIcon {...props} type="fontAwesome" iconName="info-circle" />
    );
}
