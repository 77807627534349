import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from 'Components/text-input/TextInput';

const StyledTextInput = styled(TextInput)`
    display: block;
`;

export default class PrimaryEmailEditor extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.shape({
            email: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { name, value } = this.props;

        return (
            <StyledTextInput
                label="Email"
                value={value.email}
                type="email"
                name={`${name}.email`}
                helperText={''}
                disabled
            />
        );
    }
}
