import React from 'react';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentWide';
import styled from 'styled-components';
import { T2, Body2 } from 'Components/typography/typography';

const Heading = styled(T2)`
    display: block;
    color: ${props => props.theme.colors.palette.blue.awesome};
    text-transform: uppercase;
    margin-bottom: 10px;
`;

const Body = styled(Body2)`
    white-space: pre-wrap;
    display: block;
`;

const Section = styled(Body)`
    display: block;
    margin-bottom: 20px;
`;

const ListItem = styled.li``;

export default function CookiePolicyPage() {
    return (
        <Page>
            <PageTitle>Cookie Policy</PageTitle>
            <PageContent>
                <Section>
                    <Heading>Introduction</Heading>
                    <Body>
                        As with many companies, our websites and emails use standard technologies referred to as “cookies” and “beacons”.
                    </Body>
                    <ul>
                        <ListItem>A cookie is a small data file that is transferred to your computer (when you allow your browser to accept cookies). Cookies are used to identify your web browser whenever you visit a site and may be necessary to providing a better browsing experience and/or to analyze site traffic as a means of improving page content.</ListItem>
                        <ListItem>A beacon is an electronic image placed in a web page or email. Beacons are generally used to provide a notification when web pages are loaded and when emails are opened.</ListItem>
                    </ul>
                </Section>
                <Section>
                    <Heading>Managing Cookies</Heading>
                    <Body>
                        If you do not want information collected through the use of cookies, you can opt-out of providing this information by adjusting cookie settings in your browser. Your browser controls allow you to refuse some or all cookies, as well as delete existing cookies from your machine. Please refer to your browser&apos;s privacy or help documentation for more information about how to block or disable cookies in your browser.
                    </Body>
                    <Body>
                        Users located in the European Union will also have the option of disabling cookies through the onsite banner that will appear when originally visiting our websites.
                    </Body>
                </Section>
                <Section>
                    <Heading>Type of Cookies and Information Collected</Heading>
                    <Body>
                        Our websites uses the following types of cookies:
                    </Body>
                    <ul>
                        <ListItem><strong>Strictly Necessary: </strong>These cookies are required for the operation of our sites. They are not used to track your activity or collect information about you. We recommend that you do not turn these cookies off as this may limit your ability to view and navigate our sites.</ListItem>
                        <ListItem><strong>Performance: </strong>These cookies are used to collect overall information about how visitors use our websites. They recognize and count website visitors and help us see how visitors reach and move around our websites. This helps us ensure that users are able to easily find what they are looking for, and to identify errors. Information collected by performance cookies is anonymous or pseudonymized and cannot be used to identify you. Some performance cookies are managed for us by third parties. For more information see Key Third Party Providers below.</ListItem>
                        <ListItem><strong>Functionality: </strong>These cookies are used to provide content such as videos. We do not use Functionality Cookies to target advertising content. You can block these cookies using your browser settings, but this may mean you will not be able to view certain content.</ListItem>
                        <ListItem><strong>Targeting: </strong>These cookies are used to store your contact details once entered on a web form. This information allows us to track and record the pages you have visited and the links you have clicked. This helps us to identify which pages of our website are most relevant to people who visit our sites, so that we can develop and improve our content.</ListItem>
                    </ul>
                    <Body>
                        If you choose to receive marketing emails or newsletters from Vorne we use web beacons and personalized URLs to track that you’ve opened the message, and to identify any clicked links within those messages. This allows Vorne to optimize future communications and limit these communications to subjects that are of interest to you. You will not receive these communications if you have opted-out of receiving marketing materials from us (every email includes an opt-out option in the email footer).
                    </Body>
                    <Body>
                        You can turn off Targeting Cookies. However, if you block them, we may not be able to offer you certain functionality.
                    </Body>
                </Section>
                <Section>
                    <Heading>Cookie Duration</Heading>
                    <Body>
                        The length of time that a cookie remains on your machine will depend on whether they are session or persistent cookies.
                    </Body>
                    <ul>
                        <ListItem><strong>Session Cookies: </strong>Remain in place only for the duration of your visit. They are deleted at the end of your browsing session.</ListItem>
                        <ListItem><strong>Persistent Cookies: </strong>Remain in place for a period of time after you leave our website. How long the cookie remains on your machine will depend on the “lifetime” of the specific cookie as well as your browser settings. You can also manually delete these cookies.</ListItem>
                    </ul>
                </Section>
            </PageContent>
        </Page>
    );
}
