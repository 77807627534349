import styled from 'styled-components';
import { Body1 } from 'Components/typography/typography';

const SelectOptionContent = styled(Body1).attrs({ as: 'div' })`
    position: relative;
    display: block;
    box-sizing: border-box;
    width: auto;
    padding: 6px 6px;
    border: none;
    outline: none;
    word-break: break-all;
`;

export default SelectOptionContent;
