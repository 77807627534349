import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Heading5, Body5 } from 'Common/components/typography';

const Container = styled.div`
    border-radius: 3px;
    background-color: ${props => props.theme.colors.palette.grey.cloud};
    color: ${props => props.theme.colors.palette.grey.manatee};
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

/**
 * A default for showing no data/error message in widget/table.
 *
 */
function NoDataMessage({ title, message }) {

    return (
        <Container>
            <Heading5>{title}</Heading5>
            <Body5>{message}</Body5>
        </Container>
    );
}

NoDataMessage.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
};

NoDataMessage.defaultProps = {
    title: '',
    message: '',
};

export default NoDataMessage;
