import React, {
    useMemo,
    useState,
    useCallback,
    useImperativeHandle,
    forwardRef,
} from 'react';
import styled from 'styled-components';
import Button from 'Common/components/Button';
import PropTypes from 'prop-types';
import { useTranslator } from 'Common/hooks/useTranslation';

function useValidationState({
    initialValue,
    validate,
    detectChanges,
    translator,
}) {
    const [ modifiedValue, setModifiedValue ] = useState(null);
    
    // Let the internal value follow initialValue until the user makes changes.
    const value = modifiedValue === null ? initialValue : modifiedValue;

    const isDirty = useMemo(() => {
        return detectChanges(initialValue, value);
    }, [ detectChanges, initialValue, value ]);

    const handleReset = useCallback(() => {
        setModifiedValue(null);
    }, []);

    const validationErrors = useMemo(() => {
        return validate(value, translator);
    }, [ validate, value, translator ]);

    const isValid = !validationErrors;

    return {
        initialValue: initialValue,
        value: value,
        setValue: setModifiedValue,
        isDirty: isDirty,
        isValid: isValid,
        reset: handleReset,
        validationErrors: validationErrors,
    };
}

const Root = styled.div`
    display:flex;
    flex-direction: column;
    min-height: 1px;
`;

const ButtonContainer = styled.div`
    box-sizing: border-box;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-right: 12px;
`;

const ApplyContainer = styled(ButtonContainer)`
    justify-content: flex-end;
    flex: 0 0 auto;
`;

/**
 * Utility component for controls with an Apply button that can optionally have
 * invalid configuration.
 *
 * Primary Responsibilities:
 *
 * - Starting with an initial value, stores the modified (i.e. "draft") value
 *   of an editor.
 * - Performs validation (if provided) on the current value of an editor.
 * - Provides an "Apply" button for the user to submit valid changes.
 * - Allows a parent component to notify (via `ref`) about an attempt to close
 *   the control. If the value is invalid, a dialog will be displayed allowing
 *   the user to cancel changes or continue editing.
 *
 * @param {Object} props
 * @param {Object} props.initialValue The starting value for the editor.
 * @param {Function} props.onApply Called when changes are to be applied, always
 *     supplied with a valid value.
 * @param {Function} props.onCancel Called when changes are dismissed and the
 *      control should be closed.
 * @param {Function} [props.validate] Validate the current editor value and
 *      return any validation errors. Returning `null` from this function
 *      indicates the value is valid.
 * @param {Function} [props.detectChanges] Provided with the initialValue and
 *      the current value. Return true if there are changes. Defaults to doing
 *      a strict equality (`===`) check between `initialValue` and `value`.
 * @param {Function} props.renderControl Renders the editor.
 *      { value, validationErrors, onChange } are provided.
 * @param {Function} [props.renderInvalidDialog] Renders a dialog informing the
 *      user of an invalid value.
 *      { value, validationErrors, onContinue, onCancel } are provided.
 *
 * @param {Object} ref An attempt to close the live control can be triggered
 *      by a parent component using `ref.onClose()`. This function will return
 *      true if the value is valid and can be closed. Otherwise, the invalid
 *      dialog is displayed using `renderInvalidDialog`.
 */
const ValidatedLiveControl = forwardRef((props, ref) => {
    const {
        initialValue,
        onApply,
        onCancel,
        validate,
        detectChanges,
        renderControl,
        renderInvalidDialog,
    } = props;

    const translator = useTranslator();

    const [ isDialogVisible, setIsDialogVisible ] = useState(false);

    const showDialog = useCallback(() => {
        setIsDialogVisible(true);
    }, []);

    const hideDialog = useCallback(() => {
        setIsDialogVisible(false);
    }, []);

    const {
        value,
        setValue,
        isDirty,
        isValid,
        reset,
        validationErrors,
    } = useValidationState({
        initialValue: initialValue,
        validate: validate,
        detectChanges: detectChanges,
        translator: translator,
    });

    const handleChange = useCallback((event) => {
        setValue(event.target.value);
    }, [ setValue ]);

    const handleApply = useCallback(() => {
        reset();
        hideDialog();
        onApply({
            target: {
                value: value,
            },
        });
    }, [ reset, hideDialog, onApply, value ]);

    const handleCancel = useCallback(() => {
        reset();
        hideDialog();
        onCancel();
    }, [ reset, hideDialog, onCancel ]);

    const handleOuterClose = useCallback(() => {
        if (!isValid) {
            showDialog();
            return false;
        }

        if (!isDirty) {
            return true;
        }

        onApply({
            target: {
                value: value,
            },
        });

        reset();

        return true;
    }, [ isValid, showDialog, isDirty, value, onApply, reset ]);

    useImperativeHandle(ref, () => ({
        onClose: () => {
            return handleOuterClose();
        },
    }), [ handleOuterClose ]);

    return (
        <Root>
            {(isDialogVisible && renderInvalidDialog) ?
                (
                    renderInvalidDialog({
                        value,
                        validationErrors,
                        onContinue: hideDialog,
                        onCancel: handleCancel,
                    })
                )
                : null
            }
            {renderControl({
                value,
                validationErrors,
                onChange: handleChange
            })}
            <ApplyContainer>
                <Button
                    onClick={handleApply}
                    disabled={!isDirty || !isValid}
                >
                    {translator('apply_literal')}
                </Button>
            </ApplyContainer>
        </Root>
    );
});

ValidatedLiveControl.displayName = 'ValidatedLiveControl';

ValidatedLiveControl.propTypes = {
    initialValue: PropTypes.any.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    validate: PropTypes.func,
    detectChanges: PropTypes.func,
    renderControl: PropTypes.func.isRequired,
    renderInvalidDialog: PropTypes.func,
};

ValidatedLiveControl.defaultProps = {
    validate: (value) => null,
    detectChanges: (initialValue, value) => initialValue !== value,
};

export default ValidatedLiveControl;
