import { resourceReducer } from 'Common/util/ResourceCollection';
import { selectResourceData } from 'Common/util/ResourceCollection/resourceReducer';

export default resourceReducer({
    requests: [ 'getMetricThresholds', 'putMetricThresholds' ],
    setResource: (data) => {
        return (data?.metrics || []);
    }
});

/**
 * Select the metric threshold configs (as an array).
 *
 * @param {Object} state The state of the redux store.
 * @returns {Array} Metric thresholds.
 */
export function selectMetricThresholds(state) {
    const rv = selectResourceData(state);
    return rv;
}

/**
 * Select the metric threshold configs as a map keyed by column name.
 *
 * @param {Object} state The state of the redux store.
 * @returns {Object} The map of metric thresholds.
 */
export function selectMetricThresholdsByColumnName(state) {
    const metricThresholds = selectResourceData(state);
    return metricThresholds.reduce((map, item) => {
        map[item.name] = item;
        return map;
    }, {});
}
