/**
 * Converts from seconds to an hms (hours, minutes, seconds) object.
 *
 * Example:
 *
 * secondsToHms(65)
 * returns:
 * {
 *   h: 0,
 *   m: 1,
 *   s: 5,
 * }
 *
 * @param {Number} seconds
 *
 * @returns {Object} with h,m,s properties representing hours, minutes, and seconds.
 */
export function secondsToHms(seconds) {
    let h = null;
    let m = null;
    let s = null;

    if (Number.isFinite(seconds)) {
        s = seconds % 60;
        m = ((seconds - s) / 60) % 60;
        h = ((seconds - (m * 60) - s)) / (60 * 60);
    }

    return {
        h,
        m,
        s,
    };
}

export function hmsToSeconds({
    h,
    m,
    s,
}) {
    if (Number.isFinite(h) || Number.isFinite(m) || Number.isFinite(s)) {
        return ((h || 0) * 60 * 60) + ((m || 0) * 60) + (s || 0);
    }
    else {
        return null;
    }
}
