import { sendPatchRequest } from 'Http/request';
import { patchResources } from 'Common/util/ResourceCollection';
import isEqual from 'lodash/isEqual';

export function generateEmailSubscriptionsPatch(initialData, modifiedData) {
    const generateMap = ids => ids.reduce((map, subscription) => {
        map[subscription.data.id] = subscription;
        return map;
    }, {});

    const initialDataMap = generateMap(initialData);
    const modifiedDataMap = generateMap(modifiedData);

    let patch = { add: [], remove: [], replace: [] };

    // IDs in initial but not modified have been removed
    Object.keys(initialDataMap).forEach((id) => {
        if (!modifiedDataMap[id]) {
            patch.remove.push(Number(id));
        }
    });

    Object.keys(modifiedDataMap).forEach((id) => {
        if (!initialDataMap[id]) {
            const mod = modifiedDataMap[id].data;
            // remove the id property before saving, this is temporary to make front-end work, the
            // backend will assign the official key when saved
            let { id: xx, ...val } = mod;
            patch.add.push(val);
        }
    });

    // IDs in modified and initial then see if they are different
    Object.keys(modifiedDataMap).forEach((id) => {
        if (initialDataMap[id]) {
            const orig = initialDataMap[id].data;
            const mod = modifiedDataMap[id].data;

            if (!isEqual(orig, mod)) {
                patch.replace.push(mod);
            }

        }
    });

    return patch;
}

export function patchEmailSubscriptions(data) {
    return patchResources({
        resourceType: 'emailSubscriptions',
        requestId: 'patchEmailSubscriptions',
        requestData: data,
        sendRequest: async () => {
            return sendPatchRequest('/api/v1/accounts/user/report-subscriptions', {
                body: { ...data },
            });
        },
    });
}
