import React from 'react';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentWide';
import PageDescription from 'Components/page/PageDescription';
import PageFrame from 'Components/page/PageFrame';
import Table from 'Components/table/Table';
import TableHeader from 'Components/table/TableHeader';
import TableHeaderColumn from 'Components/table/TableHeaderColumn';
import TableBody from 'Components/table/TableBody';
import TableColumn from 'Components/table/TableColumn';
import TableRow from 'Components/table/TableRow';
import TableHeaderRow from 'Components/table/TableHeaderRow';
import AnchorToc from 'Components/anchor-toc/AnchorToc';
import AnchorTocItem from 'Components/anchor-toc/AnchorTocItem';

export default class MobileGatewaysHelpPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            gateways: null,
        };
    }

    componentDidMount() {
        import(/* webpackChunkName: "mobileGateways" */ './mobileGateways')
            .then(({ getMobileGateways }) => {
                let gateways = getMobileGateways();

                // Sort the carriers alphabetically
                gateways = gateways.map((country) => {
                    country.gateways.sort((gatewayA, gatewayB) => {
                        return gatewayA.carrier.localeCompare(gatewayB.carrier);
                    });

                    // Add an id value to a country, used for anchor links
                    country.id = country.country.toLowerCase().replace(/\s/g, '-');

                    return country;
                });

                this.setState({
                    gateways,
                });
            })
            .catch(error => 'An error occurred while loading the mobile gateways');
    }

    renderToc() {
        const { gateways } = this.state;

        if (!gateways) {
            return null;
        }

        return (
            <AnchorToc>
                {gateways.map(({ country, id }) => (
                    <AnchorTocItem id={id} key={id} name={country} />
                ))}
            </AnchorToc>
        );
    }

    renderGatewayRow({ carrier, format, type, activationRequired }, index) {
        return (
            <TableRow key={index}>
                <TableColumn>
                    {carrier}
                </TableColumn>
                <TableColumn>
                    {format}
                </TableColumn>
                <TableColumn>
                    {activationRequired ? 'Yes' : 'No' }
                </TableColumn>
            </TableRow>
        );
    }

    renderCountryMessage(country) {
        if (country.message) {
            return (
                <PageContent>
                    {country.message}
                </PageContent>
            );
        }
    }

    renderSmsGateways() {
        const { gateways } = this.state;

        if (!gateways) {
            return null;
        }

        return gateways.map((country) => {
            const { country: countryName, id } = country;

            return (
                <PageFrame id={id} title={countryName} key={id}>
                    {this.renderCountryMessage(country)}
                    <Table>
                        <TableHeader>
                            <TableHeaderRow>
                                <TableHeaderColumn>
                                    Carrier
                                </TableHeaderColumn>
                                <TableHeaderColumn>
                                    Gateway Address Format
                                </TableHeaderColumn>
                                <TableHeaderColumn>
                                    Activation Required by Carrier
                                </TableHeaderColumn>
                            </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            {country.gateways.map(this.renderGatewayRow)}
                        </TableBody>
                    </Table>
                </PageFrame>
            );
        });
    }

    render() {
        return (
            <Page>
                <PageTitle>Mobile Gateways</PageTitle>
                <PageDescription>
                    <p>
                        XL&nbsp;Enterprise can send you alerts as emails or text messages. Email is
                        preferred as it includes the most information. Text messages can be useful
                        if you do not have a mobile-accessible company email address AND your mobile
                        carrier supports email to text message gateways. Please note that message and
                        data rates from your mobile carrier may apply to text messages sent to you by
                        XL&nbsp;Enterprise.
                    </p>
                    <p>
                        XL uses email gateways provided by mobile carriers to send text messages. Not
                        all carriers provide this service and some carriers only provide it as a premium
                        service with additional charges. SMS gateways are preferred as the messages are
                        often delivered faster. Locate your country and carrier below, and use the email
                        address format to construct an email address for alerts (using your phone number
                        in place of the # symbols).
                    </p>
                </PageDescription>
                <PageContent>
                    {this.renderToc()}
                    {this.renderSmsGateways()}
                </PageContent>
            </Page>
        );
    }
}
