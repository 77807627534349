import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
`;

export default class List extends React.Component {
    render() {
        return (
            <Container className={this.props.className}>
                {this.props.children}
            </Container>
        );
    }
}

List.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
