import React, {
    useCallback,
    useImperativeHandle,
    forwardRef,
    createElement,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableSelectedFieldsOption from './TableSelectedFieldsOption';
import LiveControlDropdownHeader from 'Common/components/LiveControlBar/LiveControlDropdownHeader';
import LiveControlDropdownLayout from 'Common/components/LiveControlBar/LiveControlDropdownLayout';
import useStackHistory from 'Common/hooks/useStackHistory';
import { METRIC_LIST_TYPE } from 'Common/util/MetricListColumnConstants';
import Filters from 'Common/types/Filters/Filters';
import useValidationState from 'Common/components/LiveControlBar/useValidationState';
import InvalidLiveControlDialog from 'Common/components/LiveControlBar/InvalidLiveControlDialog';
import useTranslation, { useTranslator } from 'Common/hooks/useTranslation';
import { ATTENTION_LIST_TYPE, COMMENT_LIST_TYPE, DIMENSION_TYPE } from '../tableWidgetConstants';
import isEqual from 'lodash/isEqual';

const Root = styled.div`
    box-sizing: border-box;
    width: 275px;
    display: flex;
    flex-direction: column;
    min-height: 1px;
`;

// compares columns to see if they are the same
function detectChanges (initialColumns, updatedColumns) {
    if (initialColumns.length !== updatedColumns.length) {
        return true;
    }

    return !initialColumns.every((column, index) => {
        const updatedColumn = updatedColumns[index];
        let isSameColumn = column.name === updatedColumn?.name &&
            column.channel === updatedColumn?.channel;

        if (column.fieldType === ATTENTION_LIST_TYPE && isSameColumn) {
            return column.attentionList.limit === updatedColumn.attentionList.limit;
        }
        else if (column.fieldType === COMMENT_LIST_TYPE && isSameColumn) {
            return column.commentList.includeAllComments === updatedColumn.commentList.includeAllComments;
        }
        else if (column.fieldType === METRIC_LIST_TYPE && isSameColumn) {
            return (column.showAs === updatedColumn.showAs)
                && (column.displayName === updatedColumn.displayName)
                && (!detectChanges(column.columns, updatedColumn.columns));
        }
        else if (column.fieldType === DIMENSION_TYPE && isSameColumn) {
            return isEqual(column.dimensionVisualization, updatedColumn.dimensionVisualization);
        }
        else {
            return isSameColumn;
        }
    });
}

/**
 * This component is used by table to select which columns will be shown in the table.
 *
 */
const TableColumnControl = forwardRef((props, ref) => {
    const {
        defaultTitle,
        fieldOptions,
        icon,
        selectedColumns: initialSelectedColumns,
        goBack,
        onChange,
        filterValue,
        maxNumColumns,
    } = props;

    const translator = useTranslator();

    const validate = useCallback((value) => {
        if (value.length > maxNumColumns) {
            return translator('control.column.too_many_columns_template', { max: maxNumColumns });
        }
        else {
            return null;
        }
    }, [ translator, maxNumColumns ]);

    const handleChange = useCallback((value) => {
        onChange({
            target: {
                value: value,
            },
        });
    }, [ onChange ]);

    const {
        value,
        setValue,
        isDirty,
        isValid,
        validationErrors,
        apply,
        reset,
        isInvalidDialogVisible,
        hideInvalidDialog,
    } = useValidationState({
        initialValue: initialSelectedColumns,
        validate,
        detectChanges: detectChanges,
        onChange: handleChange,
    });

    useImperativeHandle(ref, () => ({
        onClose: () => {
            const isApplySuccess = apply();
            return isApplySuccess;
        },
    }), [ apply ]);

    // handles navigation between different controls
    const {
        current: currentProps,
        canPop: internalCanGoBack,
        push: transitionForward,
        pop: internalGoBack,
        clear,
    } = useStackHistory({ control: TableSelectedFieldsOption, props: {}, title: defaultTitle });

    const handleOnColumnsChange = useCallback((event) => {
        setValue(event.target.value);
        clear();
    }, [ clear, setValue ]);

    const title = currentProps.title || defaultTitle;

    // a go back link can also come from
    // parent not just the history stack
    const canGoBack = !!goBack;

    const headerGoBack = internalCanGoBack
        ? internalGoBack
        : canGoBack ? goBack : null;

    // this handles what happens when the user selects
    // the go back link
    const handleHeaderGoBack = useCallback(() => {
        if (headerGoBack) {
            headerGoBack();
        }
    }, [headerGoBack]);

    const header = (
        <LiveControlDropdownHeader
            displayName={title}
            icon={icon}
            goBack={headerGoBack ? handleHeaderGoBack : null}
        />
    );

    const invalidChangesPopupTitleLiteral = useTranslation('control.column.invalid_changes_popup.title');
    const invalidChangesPopupMessageLiteral = useTranslation('control.column.invalid_changes_popup.message');

    const handleInvalidDialogCancel = useCallback(() => {
        // Reset the validation state.
        reset();
        // Trigger a change with the initial value to indicate the user has
        // completed completed making changes to this control.
        handleChange(initialSelectedColumns);
    }, [ reset, handleChange, initialSelectedColumns ]);

    const body = (
        <Root>
            {createElement(currentProps.control, {
                ...(currentProps.props || {}),
                maxNumColumns,
                fieldOptions,
                filterValue,
                selectedColumns: value,
                isValid,
                validation: validationErrors,
                isDirty,
                title,
                transitionForward,
                transitionBackward: internalGoBack,
                onChange: handleOnColumnsChange,
                onApply: apply,
            })}
            {isInvalidDialogVisible && (
                <InvalidLiveControlDialog
                    title={invalidChangesPopupTitleLiteral}
                    content={invalidChangesPopupMessageLiteral}
                    onContinue={hideInvalidDialog}
                    onCancel={handleInvalidDialogCancel}
                />
            )}
        </Root>
    );

    return (
        <LiveControlDropdownLayout
            header={header}
            body={body}
        />
    );
});

TableColumnControl.propTypes = {
    fieldOptions: PropTypes.object.isRequired,
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            channel: PropTypes.string.isRequired,
            fieldType: PropTypes.string.isRequired,
            attentionList: PropTypes.shape({
                limit: PropTypes.number.isRequired
            }),
            commentList: PropTypes.shape({
                includeAllComments: PropTypes.bool.isRequired
            }),
            displayName: PropTypes.string,
            showAs: PropTypes.string,
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    channel: PropTypes.string.isRequired,
                })
            )
        })
    ).isRequired,
    maxNumColumns: PropTypes.number,
    icon: PropTypes.node.isRequired,
    defaultTitle: PropTypes.string.isRequired,

    filterValue: Filters.propType,

    onChange: PropTypes.func.isRequired,
    goBack: PropTypes.func,

};

TableColumnControl.displayName = 'TableColumnControl';

TableColumnControl.defaultProps = {
    maxNumColumns: Infinity,
};

export default TableColumnControl;
