const metrics = [
    { value: 'availability', name: 'Availability' },
    { value: 'efficiency', name: 'Efficiency' },
    { value: 'oee', name: 'OEE' },
    { value: 'performance', name: 'Performance' },
    { value: 'quality', name: 'Quality' },
];

export default metrics;

export function getMetricsMap() {
    return metrics.reduce((map, metric) => {
        map[metric.value] = metric;
        return map;
    }, {});
}
