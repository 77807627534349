import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ButtonBase from '../Button/ButtonBase';
import { Body7 } from '../typography';
import IconSizeProp from './IconSizeProp';

const getColor = (theme, name) => {
    const {
        darkText,
        palette: { grey, greyOpacity, blue },
    } = theme.colors;

    return {
        controlIcon: darkText.mediumEmphasis,
        badgeBackground: grey.manatee,
        background: 'transparent',
        disabledIconColor: darkText.disabled,
        // Hover
        hoverIconColor: darkText.mediumEmphasis,
        hoverBackground: greyOpacity.cloud,
        // Selected
        selectedIconColor: blue.awesome,
        selectedBackground: blue.ice,
        // Selected + Hover
        selectedHoverIconColor: blue.deep,
        selectedHoverBackground: blue.pool,
    }[name];
};

const ButtonContentWrapper = styled.div``;
const ButtonBadge = styled(Body7)``;

const Root = styled(ButtonBase)`
    position: relative;
    display: inline-flex;
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    text-align: center;
    flex: 1 0 auto;
    align-content: center;
    cursor: pointer;

    > ${ButtonContentWrapper} {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;

        ${({ $size }) => css`
            ${$size === 'small' && css`
                width: 24px;
                height: 24px;
                font-size: 16px;
            `}
            ${$size === 'medium' && css`
                width: 44px;
                height: 44px;
                font-size: 22px;
            `}
            ${$size && typeof $size === 'object' && css`
                width: ${$size.width}px;
                height: ${$size.height}px;
                font-size: 22px;
            `}
        `}

        transition: color 0.25s, background-color 0.25s, border-color 0.25s, outline-color 0.25s;

        color: ${({theme, $selected}) => (
            $selected
                ? getColor(theme, 'selectedIconColor')
                : getColor(theme, 'controlIcon')
        )};
        background-color: ${({theme, $selected}) => (
            $selected
                ? getColor(theme, 'selectedBackground')
                : getColor(theme, 'background')
        )};

        border-radius: 4px;
    }

    > ${ButtonBadge} {
        position: absolute;
        top: 8px;
        right: 4px;
        display: inline-block;
        box-sizing: border-box;
        line-height: 1.25em;
        height: 1.25em;
        min-width: 1.25em;
        padding: 0 0.25em;
        border-radius: 1em;

        transition: color 0.25s, background-color 0.25s;

        color: ${({theme}) => ( theme.colors.palette.white )};
        border-color: ${({theme, $selected}) => (
            $selected
                ? getColor(theme, 'selectedBackground')
                : getColor(theme, 'background')
        )};
        background-color: ${({theme, $selected}) => (
            $selected
                ? getColor(theme, 'selectedIconColor')
                : getColor(theme, 'badgeBackground')
        )};

        /* Give a little border around the badge so that you can
        see the edges against the button color */
        box-shadow: ${({theme, $selected}) => {
            const boxShadowColor = $selected
                ? getColor(theme, 'selectedBackground')
                : getColor(theme, 'background');
            return `0 0 0 1.5px ${boxShadowColor};`;}
        };
    }



    &:enabled {
        &:hover, &:focus {
            > ${ButtonContentWrapper} {
                color: ${({theme, $selected}) => (
                    $selected
                        ? getColor(theme, 'selectedHoverIconColor')
                        : getColor(theme, 'hoverControlIcon')
                )};
                background-color: ${({theme, $selected}) => (
                    $selected
                        ? getColor(theme, 'selectedHoverBackground')
                        : getColor(theme, 'hoverBackground')
                )};
            }

            > ${ButtonBadge} {
                background-color: ${({theme, $selected}) => (
                    $selected
                        ? getColor(theme, 'selectedHoverIconColor')
                        : getColor(theme, 'hoverControlIcon')
                )};
                box-shadow: ${({theme, $selected}) => {
                    const boxShadowColor = $selected
                    ? getColor(theme, 'selectedHoverBackground')
                    : getColor(theme, 'hoverBackground');
                    return `0 0 0 1.5px ${boxShadowColor};`;}
                };
            }
        }
    }

    &:disabled {
        & > ${ButtonContentWrapper} {
            color: ${({theme}) => getColor(theme, 'disabledIconColor')};
            background-color: ${({theme}) => getColor(theme, 'background')};
        }

        > ${ButtonBadge} {
            background-color: ${({theme}) => getColor(theme, 'disabledIconColor')};
        }
    }
`;

const IconButton = forwardRef((props, ref) => {
    const {
        badge,
        children,
        disabled,
        onClick,
        selected,
        size,
        ...otherProps
    } = props;

    const showBadge = Number.isFinite(badge) && badge > 0;

    return (
        <Root
            {...otherProps}
            $selected={selected}
            $size={size}
            disabled={disabled}
            onClick={onClick}
            ref={ref}
        >
            <ButtonContentWrapper>
                {children}
            </ButtonContentWrapper>
            {showBadge && (
                <ButtonBadge selected={selected}>{badge}</ButtonBadge>
            )}
        </Root>
    );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
    badge: PropTypes.number,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    size: IconSizeProp,
};

IconButton.defaultProps = {
    disabled: false,
    selected: false,
    size: 'medium',
};

export default IconButton;
