import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DraggableListItem from './DraggableListItem';
import { DndContext, DragOverlay, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

const Root = styled.div`
    box-sizing: border-box;
`;

/**
 * DraggableList displays a vertical list of provided nodes, 
 * allowing them to be reordered via drag/drop, keyboard, and touch.
 */

export default function DraggableList({
    className,
    children,
    onDragEnd,
}) {
    const [ activeId, setActiveId ] = useState(null);

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragEnd = useCallback((event) => {
        setActiveId(null);
        const { active, over } = event;

        // dropped outside the list if no over
        if (over) {
            onDragEnd({
                source: {
                    id: active.id,
                    index: active.data.current.index,
                },
                destination: {
                    id: over.id,
                    index: over.data.current.index,
                },
            });
        }
    }, [ onDragEnd ]);

    const handleDragCancel = useCallback((event) => {
        setActiveId(null);
    }, []);

    const childrenArray = useMemo(() => (
        React.Children.toArray(children)
    ), [ children ]);

    const childNames = useMemo(() => (
        childrenArray.map(child => child.props.name)
    ), [ childrenArray ]);

    const childrenByName = useMemo(() => (
        childrenArray.reduce((acc, child) => {
            acc[child.props.name] = child;
            return acc;
        }, {})
    ), [ childrenArray ]);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
              distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Require a delay (aka long press)
            activationConstraint: {
              delay: 1000,
              tolerance: 10,
            },
        }),
        useSensor(KeyboardSensor),
    );

    return (
        <Root
            className={className}
        >
            <DndContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
                sensors={sensors}
            >
                <SortableContext
                    items={childNames}
                >
                    {childrenArray.map((child, i) => {
                        const childName = child.props.name;

                        return (
                            <DraggableListItem
                                key={childName}
                                index={i}
                                name={childName}
                            >
                                {child}
                            </DraggableListItem>
                        );
                    })}
                    <DragOverlay>
                        {activeId ? React.cloneElement(childrenByName[activeId], { isDragging: true }) : null}
                    </DragOverlay>
                </SortableContext>
            </DndContext>
        </Root>
    );
}

DraggableList.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onDragEnd: PropTypes.func.isRequired,
};

DraggableList.defaultProps = {
};
