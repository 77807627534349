import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextButton from 'Components/button/TextButton';
import ContainedButton from 'Components/button/ContainedButton';
import ErrorNotice from 'Components/notice/ErrorNotice';
import { Body1 } from 'Components/typography/typography';
import SuccessNotice from 'Components/notice/SuccessNotice';
import WarningNotice from 'Components/notice/WarningNotice';

const PendingEmailChange = styled(WarningNotice)``;

const PendingEmailChangeText = styled(Body1)`
    display: block;
`;

const PendingEmailChangeButtons = styled.div`
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;

    & > * {
        margin-left: 10px;
    }
`;

const PendingEmailChangeError = styled.div`
    margin-top: 16px;
`;

const PendingEmailChangeSuccess = styled.div`
    margin-top: 16px;
`;

export default class VerificationNotice extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
        disabled: PropTypes.bool,
        error: PropTypes.node,
        success: PropTypes.node,
        onCancelVerificationClick: PropTypes.func.isRequired,
        onResendVerificationClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            className,
            children,
            disabled,
            error,
            success,
            onCancelVerificationClick,
            onResendVerificationClick,
        } = this.props;

        return (
            <PendingEmailChange className={className}>
                <PendingEmailChangeText>
                    {children}
                </PendingEmailChangeText>
                <PendingEmailChangeButtons>
                    <TextButton
                        color="secondary"
                        onClick={onCancelVerificationClick}
                        disabled={disabled}
                    >
                        Cancel Request
                    </TextButton>
                    <ContainedButton
                        color="primary"
                        onClick={onResendVerificationClick}
                        disabled={disabled}
                    >
                        Resend Verification
                    </ContainedButton>
                </PendingEmailChangeButtons>
                {error && (
                    <PendingEmailChangeError>
                        <ErrorNotice>
                            {error}
                        </ErrorNotice>
                    </PendingEmailChangeError>
                )}
                {success && (
                    <PendingEmailChangeSuccess>
                        <SuccessNotice>
                            {success}
                        </SuccessNotice>
                    </PendingEmailChangeSuccess>
                )}
            </PendingEmailChange>
        );
    }
}
