
import { sendPatchRequest } from 'Http/request';
import {
    patchResource,
} from 'Common/util/ResourceCollection';

export function patchSupportUser(userId, patch) {
    return patchResource({
        requestId: 'patchSupportUser',
        resourceId: userId,
        resourceType: 'supportUsers',
        sendRequest: () => sendPatchRequest(`/api/v1/vornesupport/user/${userId}`, { body: patch } ),
    });
}

export default patchSupportUser;
