import { NodeType } from './HierarchyNodeType';
import { combineValidators } from 'Components/util/validationUtils';

export const visibilityMustContainDeviceText =
    'Visibility for a work center must contain the work center itself.';

export const visibilityNodeMustBeSavedText =
    'Hierarchy must be saved before new node can be used for visibility.';

const validateName = (name) => {
    if (name === null) {
        return 'Name cannot be empty.';
    }
    else if (typeof name === 'string') {
        const trimmed = name.trim();

        if (trimmed.length === 0) {
            return 'Name cannot be empty.';
        }
        else {
            return null;
        }
    }
    else {
        throw new Error(`Unable to validate name of type ${typeof name}`);
    }
};

const validateDeviceNode = (deviceNode, nodes) => {
    let validation = null;

    const addDeviceError = (key, error) => {
        if (!validation) {
            validation = {};
        }

        if (!validation.device) {
            validation.device = {};
        }

        validation.device[key] = error;
    };

    const assetNameValidation = validateName(deviceNode.device.asset_name);

    if (assetNameValidation) {
        addDeviceError('asset_name', assetNameValidation);
    }

    const isDeviceInVisibility = deviceNode.id === String(deviceNode.device.hierarchy_visibility) ||
        deviceNode.parents.indexOf(String(deviceNode.device.hierarchy_visibility)) !== -1;

    const isVisibilityNodeNew = nodes[deviceNode.device.hierarchy_visibility].isNew;

    if (!isDeviceInVisibility) {
        addDeviceError('hierarchy_visibility', visibilityMustContainDeviceText);
    }
    else if (isVisibilityNodeNew) {
        addDeviceError('hierarchy_visibility', visibilityNodeMustBeSavedText);
    }

    return validation;
};

const validateNonDeviceNode = combineValidators({
    node_name: validateName,
});

function validateNodes(nodes) {
    let errors = {};

    Object.keys(nodes).forEach((ii) => {
        const node = nodes[ii];
        let nodeValidation;
        if (node.node_type === NodeType.DEVICE) {
            nodeValidation = validateDeviceNode(node, nodes);
        }
        else {
            nodeValidation = validateNonDeviceNode(node);
        }

        if (nodeValidation) {
            errors[ii] = nodeValidation;
        }
    });

    return Object.keys(errors).length !== 0
           ? errors
           : null;
}

export default function validateEnterpriseHierarchy(value) {
    const nodesValidation = validateNodes(value.nodes);

    if (nodesValidation) {
        return { nodes: nodesValidation };
    }
    else {
        return null;
    }
}
