
import React, {
    useState,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SelectOptionContent from 'Components/select/SelectOptionContent';

import FormElement from 'Components/form-element/FormElement';
import SelectOption from 'Components/select/SelectOption';
import Table from 'Components/table/Table';
import TableHeader from 'Components/table/TableHeader';
import TableHeaderRow from 'Components/table/TableHeaderRow';
import TableHeaderColumn from 'Components/table/TableHeaderColumn';
import TableBody from 'Components/table/TableBody';
import TableRow from 'Components/table/TableRow';
import TableColumn from 'Components/table/TableColumn';
import EnterpriseHierarchySelect from 'Components/enterprise-hierarchy-select/EnterpriseHierarchySelect';
import VorneIcon from 'Components/vorne-icon/VorneIcon';
import ButtonBase from 'Common/components/Button/ButtonBase';
import InfoCircle from 'Common/components/InfoCircle';

import SelectInput from 'Components/select/SelectInput';

const DeleteButtonIcon = styled(VorneIcon).attrs({
    i: 'chip-delete',
})`
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

const StyledSelectInput = styled(SelectInput)`
    min-width: 225px;
`;

const CreateNewSubscriptionText = styled(SelectOptionContent)`
    color: ${props => props.theme.colors.palette.grey.manatee};
`;

// should have no padding around drop down and other controls in this particular table,
// additionally remove the padding that will show up around the enterprise drop down control or the
// select work center doesn't line up with the rest
const SubTable = styled(Table)`
    tbody tr td {
        padding: 0;
    }

    tbody tr td button div {
        padding: 0;
    }
`;

const ReportingPeriodOptions = [ {
    id: 'every_shift',
    displayName: 'Every Shift',
}, {
    id: 'ordinal_shift_1',
    displayName: '1st Shift of Day',
}, {
    id: 'ordinal_shift_2',
    displayName: '2nd Shift of Day',
}, {
    id: 'ordinal_shift_3',
    displayName: '3rd Shift of Day',
}, {
    id: 'ordinal_shift_4',
    displayName: '4th Shift of Day',
}, {
    id: 'day',
    displayName: 'Production Day',
}, {
    id: 'week',
    displayName: 'Production Week',
}, {
    id: 'month',
    displayName: 'Month',
} ];

/**
 * returns a string that is a combination of the report template and selected hierarchy node
 */
function getCombinedSubscriptionPseudoId(subscription) {
    return subscription.data.report_template_id + '-' + subscription.data.hierarchy_node_id;
}

/**
 * Special Handling of reporting periods
 * This explanation may help explain some of the less straight-forward code in this component
 * The front end allows users to choose a report template and a work center, and select 1 of many reporting periods for that combo
 * The back-end sees each unique report tempalte, work center, reporting period as its own unique subscription
 * The code here looks through all reporting periods for a given report template and work center and combines them into an array.
 */
export default function EmailsSubscriptionTable(props) {
    const {
        emailSubscriptions,
        emailTemplates,
        flattenedHierarchy,
        onAddRemove,
        onChange,
        onDeleteRow,
        partialRowStatus,
    } = props;

    // this object stores a mapping of pseudo id (report template id + hierarchy node id) pointing
    // to an array of reporting periods
    // the user can have multiple reporting periods selected for each report template + enterprise node
    // the back-end sees each reporting periods combination as a separate subscription
    const combinedEmailSubscriptions = emailSubscriptions.reduce((acc, subscription) => {
        const subscriptionPseudoId = getCombinedSubscriptionPseudoId(subscription);
        const obj = {
            reporting_period: subscription.data.reporting_period,
            subscription_id: subscription.data.id,
        };
        if (acc[subscriptionPseudoId]) {
            acc[subscriptionPseudoId].push(obj);
        }
        else {
            acc[subscriptionPseudoId] = [obj];
        }
        return acc;
    }, {});

    // renders the email templates in the dropdown menu
    const renderEmailTemplateOptions = () => {
        return emailTemplates.map((report) => {
            return (
                <SelectOption
                    key={report.data.id}
                    value={report.data.id.toString()}
                >
                    <SelectOptionContent>
                        {report.data.title}
                    </SelectOptionContent>
                </SelectOption>
            );
        });
    };

    const renderReportingPeriodOptions = () => {
        return ReportingPeriodOptions.map((opt) => {
            return (
                <SelectOption
                    key={opt.id}
                    value={opt.id}
                >
                    <SelectOptionContent>
                        {opt.displayName}
                    </SelectOptionContent>
                </SelectOption>
            );
        });
    };

    const renderRows = () => {
        const allowEdit = true;

        const onTemplateChange = (subscription, evt) => {
            onChange(subscription.data.id, {
                ...subscription.data,
                report_template_id: parseInt(evt.value, 10),
            });
        };

        const onReportingPeriodClose = (subscription, evt) => {
            const newPeriods = evt.value;
            const subscriptionPseudoId = getCombinedSubscriptionPseudoId(subscription);
            const previousReportingPeriods = combinedEmailSubscriptions[subscriptionPseudoId].map((x) => x.reporting_period);

            // some fancy js to find differences between two arrays of reporting periods so we can
            // know what was actually added or removed
            const removed = previousReportingPeriods.filter(x => !newPeriods.includes(x));
            const added = newPeriods.filter(x => !previousReportingPeriods.includes(x));

            const subscriptionsWithCombo = combinedEmailSubscriptions[subscriptionPseudoId];
            const removedSubscriptions = subscriptionsWithCombo.filter((ss) => {
                return removed.includes(ss.reporting_period);
            });

            onAddRemove({
                hierarchy_node_id: subscription.data.hierarchy_node_id,
                reporting_period: added,
                report_template_id: subscription.data.report_template_id,
            },
                removedSubscriptions,
            );
        };

        const onHierarchyChange = (subscription, evt) => {
            const newHierarchy = evt.value;
            if (newHierarchy !== subscription.data.hierarchy_node_id.toString()) {
                onChange(subscription.data.id, {
                    ...subscription.data,
                    hierarchy_node_id: parseInt(evt.value, 10),
                });
            }
        };

        const onDeleteClick = (subscription) => {
            onDeleteRow(subscription);
        };

        // track if a particular template / work center subscription has already been rendered
        // prevents us from rendering multiple rows for a template/work center for each reporting period
        // only want to see one row with multiple reporting periods in a drop down
        let displayedCombinedSubscription = new Set();

        return emailSubscriptions.filter((subscription) => {
            const subscriptionPseudoId = getCombinedSubscriptionPseudoId(subscription);
            const rv = ! displayedCombinedSubscription.has(subscriptionPseudoId);
            displayedCombinedSubscription.add(subscriptionPseudoId);
            return rv;
        }).map((subscription) => {
            const subscriptionPseudoId = getCombinedSubscriptionPseudoId(subscription);

            const {
                hierarchy_node_id,
                report_template_id,
            } = subscription.data;

            const selectedReportingPeriods = combinedEmailSubscriptions[subscriptionPseudoId].map((ss) => ss.reporting_period);

            const getReportPeriodDisplay = () => {
                return (
                    <span> Select Reporting Period ({selectedReportingPeriods.length}) </span>
                );
            };

            return (
                <TableRow key={`${subscription.data.id}`}>
                    <TableColumn>
                        <FormElement error={false} disabled={!allowEdit}>
                            <StyledSelectInput
                                name="email-template"
                                value={report_template_id.toString()}
                                onChange={(e) => onTemplateChange(subscription, e.target)}
                            >
                                {renderEmailTemplateOptions()}
                            </StyledSelectInput>
                        </FormElement>
                    </TableColumn>
                    <TableColumn>
                        <FormElement error={false} disabled={!allowEdit}>
                            <EnterpriseHierarchySelect
                                value={hierarchy_node_id?.toString()}
                                onChange={(e) => onHierarchyChange(subscription, e.target)}
                                enterpriseHierarchy={flattenedHierarchy}
                            />
                        </FormElement>
                    </TableColumn>
                    <TableColumn>
                        <FormElement error={false} disabled={!allowEdit}>
                            <StyledSelectInput
                                name="reporting-period"
                                initialValue={selectedReportingPeriods}
                                multiple
                                onClose={(e) => onReportingPeriodClose(subscription, e.target)}
                                selectionDisplay={getReportPeriodDisplay()}
                            >
                                {renderReportingPeriodOptions()}
                            </StyledSelectInput>
                        </FormElement>
                    </TableColumn>
                    <TableColumn>
                        <FormElement error={false} disabled={!allowEdit}>
                            <ButtonBase onClick={(e) => onDeleteClick(subscription)} >
                                <DeleteButtonIcon />
                            </ButtonBase>
                        </FormElement>
                    </TableColumn>
                </TableRow>
            );
        });
    };

    // manages state of last row where user configures a brand new subscription
    const [ newSubscription, setNewSubscription ] = useState({
        report_template_id: '',
        hierarchy_node_id: '',
        reporting_period: [],
    });

    // renders the bottom row where a user adds a new subscription
    const renderAddNewRow = () => {
        const allowEdit = true;

        // check if a new subscription will be complete (all data items will be filled out) with
        // the addition of the addedPiece
        const willBeComplete = (addedPiece) => {
            // copy new subscription state and add a true value for the to be added piece
            const testSub = {
                ...newSubscription,
                [addedPiece]: true,
            };
            let rv = true;
            // check if any keys in the copied new subscription are falsey.
            for (const key in testSub) {
                if (! testSub[key] || (Array.isArray(testSub[key]) && testSub[key].length === 0) ) {
                    // there is a falsey piece in the subscription so is will not be considered
                    // complete and ready to be saved
                    rv = false;
                    break;
                }
            }
            return rv;
        };

        const clearNewSubscriptionState = () => {
            setNewSubscription({
                report_template_id: '',
                hierarchy_node_id: '',
                reporting_period: [],
            });
        };

        const onNewTemplateChange = (evt) => {
            if (willBeComplete('report_template_id')) {
                onAddRemove({
                    hierarchy_node_id: parseInt(newSubscription.hierarchy_node_id, 10),
                    report_template_id: parseInt(evt.target.value, 10),
                    reporting_period: newSubscription.reporting_period,
                }, []);

                clearNewSubscriptionState();
                partialRowStatus(false);
            }
            else {
                setNewSubscription((orig) => {
                    return {
                        ...orig,
                        report_template_id: evt.target.value,
                    };
                });
                partialRowStatus(true);
            }
        };

        const onNewHierarchyChange = (evt) => {
            if (willBeComplete('hierarchy_node_id')) {
                onAddRemove({
                    report_template_id: parseInt(newSubscription.report_template_id, 10),
                    hierarchy_node_id: parseInt(evt.target.value, 10),
                    reporting_period: newSubscription.reporting_period,
                }, []);

                clearNewSubscriptionState();
                partialRowStatus(false);
            }
            else {
                setNewSubscription((orig) => {
                    return {
                        ...orig,
                        hierarchy_node_id: evt.target.value,
                    };
                });
                partialRowStatus(true);
            }
        };

        const onNewReportingPeriodChange = (evt) => {
            setNewSubscription((orig) => {
                return {
                    ...orig,
                    reporting_period: evt.target.value,
                };
            });
        };

        const onNewReportPeriodClose = (evt) => {
            if (willBeComplete('reporting_period')) {
                onAddRemove({
                    hierarchy_node_id: parseInt(newSubscription.hierarchy_node_id, 10),
                    report_template_id: parseInt(newSubscription.report_template_id, 10),
                    reporting_period: evt.target.value,
                }, []);

                clearNewSubscriptionState();
                partialRowStatus(false);
            }
            else {
                partialRowStatus(true);
            }
        };

        // gets the element that displays the value in the drop downs when nothing is selected
        const getSelectionDisplay = (value, text) => {
            if (! value)  {
                return (
                    <CreateNewSubscriptionText>
                        {text}
                    </CreateNewSubscriptionText>
                );
            }
        };

        const getReportPeriodSelectionDisplay = (value) => {
            const text = value.length === 0 ? 'Select reporting period…' : `Select Reporting Period (${value.length})`;
            return (
                <CreateNewSubscriptionText>
                    {text}
                </CreateNewSubscriptionText>
            );
        };

        const error = !!newSubscription.report_template_id && !newSubscription.hierarchy_node_id ||
                      !newSubscription.report_template_id && !!newSubscription.hierarchy_node_id;

        return (
            <TableRow >
                <TableColumn>
                    <FormElement error={error} disabled={!allowEdit}>
                        <StyledSelectInput
                            name='add-new-email-template'
                            onChange={onNewTemplateChange}
                            selectionDisplay={getSelectionDisplay(newSubscription.report_template_id, 'Select report template…')}
                            value={newSubscription.report_template_id}
                        >
                            {renderEmailTemplateOptions()}
                        </StyledSelectInput>
                    </FormElement>
                </TableColumn>
                <TableColumn>
                    <FormElement error={error} disabled={!allowEdit}>
                        <EnterpriseHierarchySelect
                            enterpriseHierarchy={flattenedHierarchy}
                            name='add-new-hierarchy-node'
                            onChange={onNewHierarchyChange}
                            selectionDisplay={getSelectionDisplay(newSubscription.hierarchy_node_id, 'Select work center…')}
                            value={newSubscription.hierarchy_node_id}
                        />
                    </FormElement>
                </TableColumn>
                <TableColumn>
                    <FormElement error={error} disabled={!allowEdit}>
                        <StyledSelectInput
                            name='add-new-reporting-period'
                            onChange={onNewReportingPeriodChange}
                            onClose={onNewReportPeriodClose}
                            selectionDisplay={getReportPeriodSelectionDisplay(newSubscription.reporting_period)}
                            multiple
                            value={newSubscription.reporting_period}
                        >
                            {renderReportingPeriodOptions()}
                        </StyledSelectInput>
                    </FormElement>
                </TableColumn>
                <TableColumn />
            </TableRow>
        );
    };
    return (
        <SubTable>
            <TableHeader>
                <TableHeaderRow>
                    <TableHeaderColumn>
                        Report Template
                        <InfoCircle circleColor="#ffffff">
                            Select a predefined email template using the dropdown. Email templates are
                            organized under two headings: Built-In (templates created by Vorne) and
                            Custom (templates created by your XL Enterprise administrator).
                        </InfoCircle>
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                        Work Centers
                        <InfoCircle circleColor="#ffffff">
                            Select a node in the Enterprise hierarchy using the dropdown. The dropdown
                            shows all work centers to which access has been granted to you by your XL
                            Enterprise administrator. All work centers at or under the selected node will
                            be included in the report.
                        </InfoCircle>
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                        Reporting Period
                        <InfoCircle circleColor="#ffffff">
                            Select the reporting period covered by the subscription using the dropdown.
                            You can select Every Shift (you will receive end of shift templates for
                            every shift), or individual shifts of First Shift of Day, Second Shift
                            of Day, Third Shift of Day, or Fourth Shift of Day. Individual shifts
                            are ordered by when they appear within the production day.
                        </InfoCircle>
                    </TableHeaderColumn>
                    <TableHeaderColumn />
                </TableHeaderRow>
            </TableHeader>
            <TableBody>
                {renderRows()}
                {renderAddNewRow()}
            </TableBody>
        </SubTable>
    );
}

EmailsSubscriptionTable.propTypes = {
    emailSubscriptions: PropTypes.array.isRequired,
    emailTemplates: PropTypes.array.isRequired,
    flattenedHierarchy: PropTypes.object,
    onAddRemove: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
    partialRowStatus: PropTypes.func.isRequired,
};
