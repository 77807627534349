import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import DeviceItem from 'Components/pages/devices/DeviceItem';
import TextInput from 'Components/text-input/TextInput';
import VorneIcon from 'Components/vorne-icon/VorneIcon';
import IconButton from 'Components/button/IconButton';
import TrashIcon from 'Components/icon/TrashIcon';
import { createPath } from 'Common/util/FormUtils';
import { NodeType, getNodeTypeIconName, getNodeTypeIconColor, getNodeTypePlaceholderText } from './HierarchyNodeType';
import { Spacing } from 'Components/common/common';
import { Subtitle1 } from 'Components/typography/typography';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 400px;
    min-height: 52px;
    padding: 5px;

    background-color: ${props => props.theme.colors.palette.white};
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.25);
    min-height: 40px;

    &.dragging {
        opacity: 0.5;
    }
`;

const StyledIcon = styled(VorneIcon)`
    padding-right: ${Spacing.inlineButtonMargin};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    color: ${props => props.color || 'inherit'}
`;

const ReadOnlyName = styled(Subtitle1)`
    display: block;
    color: ${props => props.theme.colors.darkText.highEmphasis};
`;

const StyledTextInput = styled(TextInput)`
    padding-right: ${Spacing.inlineButtonMargin};
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 10000 0 auto;
`;

const StyledDeviceItemContainer = styled.div`
    flex: 1 1 auto;
    padding-right: ${Spacing.inlineButtonMargin};
    min-width: 1px; /* Flexbox needs this so it doesn't overflow */
`;

const StyledDeviceItem = styled(DeviceItem)`
    padding: 0;
`;

const StyledIconButtonContainer = styled.div`
    padding-right: ${Spacing.inlineButtonMargin};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledIconButton = styled(IconButton)`
    font-size: 16px;
`;

const StyledTrashIcon = styled(TrashIcon)`
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

export default class HierarchyNodeEditor extends React.Component {
    static propTypes = {
        innerRef: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.shape({
            // In the store, the `id` of a node is a number. But when the values
            // are prepared for editing they are converted to strings. So it
            // will expect a string here.
            id: PropTypes.string.isRequired,
            node_type: PropTypes.string.isRequired,
            node_name: PropTypes.string,
            children: PropTypes.arrayOf(PropTypes.string).isRequired,
            isNew: PropTypes.bool,
            device: PropTypes.object,
        }).isRequired,
        enterpriseHierarchy: PropTypes.object.isRequired,
        validation: PropTypes.object,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func,
        dragHandleProps: PropTypes.object,
        isDragging: PropTypes.bool,
    };

    static defaultProps = {
        name: '',
        disabled: false,
        isDragging: false,
        readOnly: false,
    };

    constructor(props) {
        super(props);
    }

    _handleDeleteClick = (event) => {
        this.props.onDelete(this.props.value.id, event);
    };

    _renderDeviceNode() {
        const {
            name,
            value,
            onChange,
            disabled,
            validation,
            enterpriseHierarchy,
            readOnly,
        } = this.props;

        const { device } = value;

        return (
            <StyledDeviceItemContainer>
                {device && (
                    <StyledDeviceItem
                        name={createPath(name, 'device')}
                        value={value.device}
                        nodeId={value.id}
                        onChange={onChange}
                        disabled={disabled}
                        readOnly={readOnly}
                        validation={validation?.device}
                        enterpriseHierarchy={enterpriseHierarchy}
                    />
                )}
            </StyledDeviceItemContainer>
        );
    }

    _renderNonDeviceNode() {
        const {
            name,
            value,
            disabled,
            readOnly,
            onChange,
            validation,
        } = this.props;

        return (
            <React.Fragment>
                {readOnly && (
                    <ReadOnlyName>{value.node_name}</ReadOnlyName>
                )}
                {!readOnly && (
                    <StyledTextInput
                        value={value.node_name}
                        disabled={disabled}
                        readOnly={readOnly}
                        name={createPath(name, 'node_name')}
                        onChange={onChange}
                        placeholder={getNodeTypePlaceholderText(value.node_type)}
                        autoFocus={value.isNew}
                        error={!!validation?.node_name}
                        helperText={validation?.node_name}
                    />
                )}
            </React.Fragment>
        );
    }

    render() {
        const { innerRef, value, dragHandleProps, readOnly, isDragging } = this.props;

        const nodeBody = value.node_type === NodeType.DEVICE
            ? this._renderDeviceNode()
            : this._renderNonDeviceNode();

        const draggable = ! readOnly && value.node_type !== NodeType.ENTERPRISE;
        const deleteable = ! readOnly && value.node_type !== NodeType.ENTERPRISE;

        return (
            <Root
                ref={innerRef}
                className={classNames({ dragging: isDragging })}
                role="listitem"
            >
                {draggable && (
                    <StyledIcon
                        i="drag-handles"
                        {...dragHandleProps}
                    />
                )}
                <StyledIcon
                    i={getNodeTypeIconName(value.node_type)}
                    color={getNodeTypeIconColor(value.node_type)}
                />
                {nodeBody}
                {deleteable && (
                    <StyledIconButtonContainer>
                        <StyledIconButton title="Remove Node" onClick={this._handleDeleteClick}>
                            <StyledTrashIcon />
                        </StyledIconButton>
                    </StyledIconButtonContainer>
                )}
            </Root>
        );
    }
}
