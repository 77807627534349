/**
 * Wraps around a set of reducers and maps each action to a single reducer based
 * on the result of the supplied map function.
 * 
 * @param {Function} mapFn Function that given an action returns a key into the set of reducers
 * @param {Object} reducerMap Object of redux reducer functions
 * @returns {Function} Combined and mapped redux reducer function
 */
export default function actionMap(mapFn, reducerMap) {
    const initialState = {};

    for (let key in reducerMap) {
        initialState[key] = reducerMap[key](undefined, {});
    }

    return function mappedReducer(state = initialState, action) {
        const key = mapFn(action);

        if (reducerMap[key]) {
            return {
                ...state,
                [key]: reducerMap[key](state[key], action),
            };
        }
        else {
            return state;
        }
    };
}
