import ParamTypes from 'Common/util/ParamTypes';
import fieldParamType from './fieldParamType';

const sortRule = ParamTypes.shape({
    column: ParamTypes.string,
    channel: ParamTypes.string,
    group: ParamTypes.string,
    isCategoriesAll: ParamTypes.bool,
    order: ParamTypes.oneOf([
        'asc',
        'desc'
    ]).isRequired,
});

const fieldSetParamType = ParamTypes.oneOfType([
    ParamTypes.shape({
        default_channel: ParamTypes.string,
        grouped_fields: ParamTypes.arrayOf(
            ParamTypes.shape({
                alternate_display: ParamTypes.string,
                channel: ParamTypes.string.isRequired,
                name: ParamTypes.string.isRequired,
            }),
        ),
        fields: ParamTypes.arrayOf(
            ParamTypes.oneOfType([
                ParamTypes.string,
                fieldParamType,
            ]),
        ),
        metric_set: ParamTypes.string,
        sort: ParamTypes.oneOfType([
            ParamTypes.arrayOf(sortRule),
            sortRule
        ])
    }),
    ParamTypes.string,
]);

export default fieldSetParamType;
