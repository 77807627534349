/**
 * Map an array through a function, and then filter out the falsy values.
 */
export function filterMap(arr, fn, scope) {
    if (typeof fn !== "function") {
        throw new TypeError();
    }

    var rv = [];
    var i;
    var len = arr.length;
    for (i = 0; i < len; i++) {
        if (arr.hasOwnProperty(i)) {
            var r = fn.call(scope || null, arr[i], i, arr);
            if (r) { rv.push(r); }
        }
    }

    return rv;
}

/**
 * Convert the argument to an instance of Array
 *
 * @param {Any} arg The thing to be converted. If it has a length property, its members
 * will be added to the array and returned. If `arg` doesn't have a length property,
 * `[arg]` will be returned.
 *
 * @returns {Array}
 */
export function toArray(arg) {
    var rv = [];

    if (arg === undefined || arg === null) {
        // Do nothing, we'll return the empty array.
    }
    else if (arg.length === undefined) {
        rv.push(arg);
    }
    // NodeLists and such in IE8 are not JS Objects, and will fail this check
    else if (arg instanceof Object) {
        rv = Array.prototype.slice.call(arg);
    }
    else {
        var i;
        for (i = 0; i < arg.length; ++i) {
            rv.push(arg[i]);
        }
    }
    return rv;
}

/**
 * Similar to Array.prototype.join, arrayJoin intermixes joiner between each item in the
 * array, but unlike Array.prototype.join the output is a new array with those added items
 * instead of a string.
 *
 * Example: arrayJoin([1, 2, 3], '\n')
 * Returns: [1, '\n', 2, '\n', 3]
 *
 * @param {Array} array
 * @param {Any} joiner
 *
 * @returns {Array}
 */
export function arrayJoin(array, joiner) {
    if (array.length < 2) {
        return array;
    }
    else {
        const joinedArray = [];

        array.forEach((item, index) => {
            joinedArray.push(item);

            if (index !== array.length - 1) {
                joinedArray.push(joiner);
            }
        });

        return joinedArray;
    }
}
