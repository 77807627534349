import { formatNumber } from 'Common/util/format';
import { getFractionalPiece } from 'Common/util/language/math';
import { isFunction, isObject } from 'lodash';

function sortDurationFormatterItems(l, r) {
    const rv = l.getOrder() - r.getOrder();
    return isNaN(rv) ? Infinity : rv;
}

class DurationFormatterItem {
    constructor(config) {
        this.text = config.text;
        this.code = this.text.charAt(0).toLowerCase();
        this.locale = config.locale;
    }

    getOrder() {
        return Infinity;
    }

    format(units) {
        switch (this.code) {
            case '"': case "'":
                return this.text.slice(1, -1);
            case '.':
                // Figure out what our decimal separator is, and return it.
                return formatNumber(0, { places: 1, locale: this.locale })[1];
            default:
                return this.text;
        }
    }
}

class DurationFormatterUnitItem extends DurationFormatterItem {
    static orderMap = { d: 1, h: 2, m: 3, s: 4 };

    static handle = (code, text) => {
        const code2 = text.charAt(1).toLowerCase();
        return this.orderMap[code] || (code === ',' && this.orderMap[code2]);
    };

    constructor(config) {
        super(config);

        if (this.code === ',') {
            this.code = this.text.charAt(1).toLowerCase();
        }

        this.hasComma = /,/.test(this.text);
        this.text = this.text.toLowerCase();
        this.text = this.text.replace(/,/, '');
        this.fmt = '#' + this.text.replace(new RegExp(this.code, 'g'), '0');
    }

    getOrder() {
        return DurationFormatterUnitItem.orderMap[this.code];
    }

    format(units) {
        const value = units.getValue(this.code);
        const rv = this.hasComma
            ? formatNumber(value, { places: 0, locale: this.locale })
            : formatNumber(value, { pattern: this.fmt, locale: this.locale });

        return rv;
    }
}

export class DurationFormatterUnits {
    constructor(config) {
        const formatItems = config.items.filter(function(item) {
            return (item instanceof DurationFormatterUnitItem);
        }).sort(sortDurationFormatterItems);

        this.d = { scalar: 60 * 60 * 24 };
        this.h = { scalar: 60 * 60 };
        this.m = { scalar: 60 };
        this.s = { scalar: 1 };

        const largeUnit = this[formatItems[0].code];
        const smallUnit = this[formatItems[formatItems.length - 1].code];

        this._setDuration(config.duration, largeUnit, smallUnit);
    }

    _order = [ 'd', 'h', 'm', 's' ];

    _setDuration(duration, largeUnit, smallUnit) {
        this._duration = (isObject(duration) && isFunction(duration.getRawValue))
                       ? duration.getRawValue()
                       : duration;

        let fractional = getFractionalPiece(this._duration);
        let soak = 0;
        this._order.forEach(function(unit) {
            unit = this[unit];
            let value = Math.trunc(this._duration / unit.scalar);
            this._duration = this._duration % unit.scalar;

            if (unit.scalar > largeUnit.scalar) {
                soak += value * unit.scalar;
                value = 0;
            }
            else if (unit.scalar === largeUnit.scalar) {
                value += Math.trunc(soak / unit.scalar);
            }

            if (unit.scalar < smallUnit.scalar) {
                fractional += value * unit.scalar;
                value = 0;
            }

            unit.value = value;

            this._fractional = fractional / smallUnit.scalar;
        }, this);
    }

    getFractionalValue(unit) {
        return this._fractional;
    }

    getValue(unit) {
        unit = unit.charAt(0).toLowerCase();
        unit = this[unit];
        return unit.value;
    }

    isUnit(str) {
        return !!this[str.charAt(0).toLowerCase()];
    }
}

class FractionalItemFormatter extends DurationFormatterItem {
    static handle = (code, text) => {
        return code === '.';
    };

    format(units) {
        const value = units.getFractionalValue();
        const pattern = '0' + this.text;
        const rv = formatNumber(value, { pattern: pattern, round: false, locale: this.locale });

        return rv.slice(1);
    }
}

export function createItem(text, index, items, locale) {
    const code = text.charAt(0).toLowerCase();
    let rv = null;

    let formatters = [
        DurationFormatterUnitItem,
        FractionalItemFormatter
    ];

    formatters.some((item) => {
        if (item !== this && item.handle && item.handle(code, text)) {
            rv = new item({
                text: items[index],
                locale,
            });
            return true;
        }
    });

    return rv || new DurationFormatterItem({
        text: items[index],
        locale,
    });
}
