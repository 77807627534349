import React, { useState, useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import ButtonBase from 'Common/components/Button/ButtonBase';
import { Body1 } from 'Components/typography/typography';
import { NodeType, getNodeTypeDisplayText, getNodeTypeIconName } from '../hierarchy-editor/HierarchyNodeType';
import uniqueId from 'Common/util/uniqueId';
import useFormElementContext from 'Components/form-element/useFormElementContext';
import EnterpriseHierarchySelectList from 'Common/components/EnterpriseHierarchySelectList';
import { nullable } from 'Components/util/propTypesUtils';

function createListItem(hierarchy, nodeId) {
    const node = hierarchy.nodes[nodeId];

    const iconClass = `vorne-icon vorne-icon-${getNodeTypeIconName(node.node_type)}`;

    let displayName = node.node_name;

    if (node.node_type === NodeType.DEVICE) {
        displayName = node.device.asset_name;
    }

    // Use a default value for the display name, if needed
    if (!displayName) {
        displayName = getNodeTypeDisplayText(node.node_type);
    }

    return {
        displayName: displayName,
        iconClass: iconClass,
        level: node.parents.length,
        name: node.id,
    };
}

function createList(hierarchy, optionsFilter) {
    const list = [];

    const recurse = (nodeId) => {
        if (optionsFilter(nodeId)) {
            list.push(createListItem(hierarchy, nodeId));

            hierarchy.nodes[nodeId].children.forEach((childId) => {
                recurse(childId);
            });
        }
    };

    recurse(hierarchy.rootId);

    return list;
}

const OpenButton = styled(ButtonBase)`
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.25);
    outline: none;
    cursor: pointer;
    line-height: 2em;

    padding-left: 6px;
    /* Make room for arrow */
    padding-right: 32px;

    color: ${props => props.theme.colors.darkText.highEmphasis};

    /* Render an arrow after the content */
    &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        right: calc(16px - 4px);
        top: calc(50% - 4px);

        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid rgba(0,0,0,0.4);
        border-bottom: none;
    }

    /* Flip arrow when open */
    &.open::after {
        border-top: none;
        border-bottom: 8px solid rgba(0,0,0,0.4);
    }

    &:disabled {
        color: ${props => props.theme.colors.darkText.disabled};
        background-color: rgba(0,0,0,0.05);
    }

    &.focused, &.open {
        border: 1px solid rgba(0, 0, 0, 1.0);
    }

    &.error {
        border: 1px solid ${props => props.theme.colors.palette.error.red};
    }
`;

const TextContent = styled(Body1).attrs({ as: 'div' })`
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const NoVisibilityText = styled.span`
    font-style: italic;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

export default function EnterpriseHierarchySelectInput(props) {
    const {
        name,
        value,
        enterpriseHierarchy,
        optionsFilter,
        onChange,
        selectionDisplay,
    } = props;

    const preventNextBlur = useRef(false);

    const {
        focused,
        disabled,
        error,
        onFocus,
        onBlur,
    } = useFormElementContext();

    const openButtonRef = useRef(null);

    const [ id ] = useState(() => {
        return uniqueId();
    });

    const [ isOpen, setIsOpen ] = useState(false);

    const handleMaskClick = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleOpenButtonClick = useCallback(() => {
        setIsOpen(true);
        // Prevents the next blur so it can be re-focused after the dropdown
        // is closed
        preventNextBlur.current = true;
    }, []);

    const handleChange = useCallback((event) => {
        // Close the dropdown
        setIsOpen(false);

        // Emit change event
        onChange({
            target: {
                name: name,
                value: event.target.value,
            },
        });

        // Re-focus the open button
        openButtonRef.current.focus();
    }, [ name, onChange ]);

    const handleBlur = useCallback((event) => {
        if (preventNextBlur.current) {
            event.stopPropagation();
            preventNextBlur.current = false;
        }
        else {
            onBlur(event);
        }
    }, [ onBlur ]);

    const hierarchyList = useMemo(() => {
        // The hierarchy can be null if the user has no hierarchy visibility
        if (enterpriseHierarchy === null) {
            return [];
        }
        else {
            return createList(enterpriseHierarchy, optionsFilter);
        }
    }, [ enterpriseHierarchy, optionsFilter ]);

    const visibilityName = useMemo(() => {
        let rv = '';

        if (value && enterpriseHierarchy) {
            const visibilityNode = enterpriseHierarchy.nodes[value];

            if (visibilityNode.node_type === NodeType.DEVICE) {
                rv = visibilityNode.device.asset_name;
            }
            else {
                rv = visibilityNode.node_name;
            }

            // Use a default value for the display name, if needed
            if (!rv) {
                rv = getNodeTypeDisplayText(visibilityNode.node_type);
            }
        }
        else {
            if (selectionDisplay) {
                rv = selectionDisplay;
            }
            else {
                rv = (
                    <NoVisibilityText>None</NoVisibilityText>
                );
            }
        }

        return rv;
    }, [value, enterpriseHierarchy, selectionDisplay]);

    return (
        <Dropdown
            isOpen={isOpen}
            onMaskClick={handleMaskClick}
            renderContent={() => (
                <EnterpriseHierarchySelectList
                    canEdit={!disabled}
                    hierarchyList={hierarchyList}
                    searchBoxPlaceholder={'Search enterprise hierarchy'}
                    noSelectionText={!hierarchyList ? 'Loading...' : 'There are no selections that match your search.'}
                    value={value === null ? '' : value}
                    onChange={handleChange}
                />
            )}
        >
            <OpenButton
                id={id}
                ref={openButtonRef}
                type="button" // prevents button from submitting forms
                className={classNames({
                    open: isOpen,
                    error: error,
                    focused: focused,
                })}
                onFocus={onFocus}
                onBlur={handleBlur}
                onClick={handleOpenButtonClick}
                disabled={disabled || hierarchyList.length === 0}
            >
                <TextContent>
                    {visibilityName}
                </TextContent>
            </OpenButton>
        </Dropdown>
    );
}

EnterpriseHierarchySelectInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    enterpriseHierarchy: nullable(
        PropTypes.shape({
            nodes: PropTypes.object.isRequired,
        })
    ).isRequired,
    optionsFilter: PropTypes.func,
    selectionDisplay: PropTypes.element,
};

EnterpriseHierarchySelectInput.defaultProps = {
    disabled: false,
    error: false,
    optionsFilter: (nodeId) => true,
};
