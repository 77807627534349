import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body5 } from 'Common/components/typography';
import NumberField from 'Common/components/NumberField';
import Dropdown from '../Dropdown/Dropdown';
import SelectDropdownButton from '../SelectList/SelectDropdownButton';
import { formatNumber } from 'Common/util/format';
import DurationFieldError from './internal/DurationFieldError';

const DropdownErrorContainer = styled.div`
    /* Make sure it doesn't stretch out the container when an error
       is shown.
    */
    width: 150px;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const NumberFieldContainer = styled.div`
    width: ${({minFractionDigits}) => 2.5 + minFractionDigits}em;
`;

const DropdownContent = styled.div`
    padding: 8px;
`;

const FieldsRoot = styled.div`
    display: flex;
`;

const Label = styled(Body5)`
    padding-left: 1px;
    padding-right: 6px;
    color: ${({theme}) => theme.colors.palette.grey.emperor};
`;

function getPrecision(precision, type) {
    // The precision prop only applies to the seconds field
    return type === 's' ? precision : 0;
}

export default function DurationField({
    name,
    value,
    onChange,
    labels,
    precision,
    error,
    placeholderText,
}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleChange = (event) => {
        const {
            value: eventValue,
            name: eventName,
        } = event.target;

        const newValue = {
            ...value,
            [eventName]: eventValue,
        };

        onChange({
            target: {
                name,
                value: newValue,
            },
        });
    };

    const renderField = (type) => {
        const minFractionDigits = getPrecision(precision, type);

        const field = (
            <FieldContainer key={type}>
                <NumberFieldContainer minFractionDigits={minFractionDigits}>
                    <NumberField
                        name={type}
                        minLeftDigits={2}
                        value={value[type]}
                        onChange={handleChange}
                        minFractionDigits={minFractionDigits}
                        error={error?.[type]}
                        showErrorInline={false}
                    />
                </NumberFieldContainer>
                <Label>{labels[type]}</Label>
            </FieldContainer>
        );

        return field;
    };

    const formatHmsValue = (type) => {
        return formatNumber(value?.[type] || 0, {
            minDigits: 2,
            places: getPrecision(precision, type),
        }) + labels[type];
    };

    const hasValue = Number.isFinite(value.h) || Number.isFinite(value.m) || Number.isFinite(value.s);

    return (
        <React.Fragment>
            <Dropdown
                isOpen={isDropdownOpen}
                onMaskClick={() => {
                    setIsDropdownOpen(false);
                }}
                renderContent={() => (
                    <DropdownContent>
                        <FieldsRoot>
                            {['h', 'm', 's'].map(renderField)}
                        </FieldsRoot>
                        {error && (
                            <DropdownErrorContainer>
                                <DurationFieldError error={error} />
                            </DropdownErrorContainer>
                        )}
                    </DropdownContent>
                )}
            >
                {hasValue
                    ? (
                        <SelectDropdownButton
                            style={{width: '100%'}}
                            error={error ? 'error' : null}
                            onClick={() => setIsDropdownOpen(true)}
                        >
                            {formatHmsValue('h')}&nbsp;
                            {formatHmsValue('m')}&nbsp;
                            {formatHmsValue('s')}
                        </SelectDropdownButton> )
                    : (
                        <SelectDropdownButton
                            style={{width: '100%'}}
                            error={error ? 'error' : null}
                            onClick={() => setIsDropdownOpen(true)}
                            isPlaceholderText
                        >
                            {placeholderText}
                        </SelectDropdownButton> )}
            </Dropdown>
            {error && (
                <DurationFieldError error={error} />
            )}
        </React.Fragment>
    );
}

DurationField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.shape({
        h: PropTypes.number,
        m: PropTypes.number,
        s: PropTypes.number,
    }),
    onChange: PropTypes.func,
    labels: PropTypes.shape({
        h: PropTypes.string,
        m: PropTypes.string,
        s: PropTypes.string,
    }).isRequired,
    error: PropTypes.shape({
        h: PropTypes.node,
        m: PropTypes.node,
        s: PropTypes.node,
    }),
    precision: PropTypes.number,
    placeholderText: PropTypes.string,
};

DurationField.defaultProps = {
    value: {
        h: 0,
        m: 0,
        s: 0,
    },
    onChange: () => { },
    precision: 0,
    placeholderText: ''
};
