import { useContext } from 'react';
import DashboardCacheContext from './internal/InternalDashboardCacheContext';

/**
 * A React hook to get the dashboard cache from context. Requires
 * `DashboardCacheProvider` to be present in the React tree.
 *
 * @returns {DashboardCache}
 */
export default function useDashboardCache() {
    const dashboardCache = useContext(DashboardCacheContext);

    // The value of `DashboardCacheContext` defaults to null, which means there
    // is no `DashboardCacheContext` higher up in the React tree.
    if (dashboardCache === null) {
        throw new Error('useDashboardCache requires DashboardCacheProvider to be in the React tree.');
    }

    return dashboardCache;
}
