import { useMemo } from 'react';

import * as TableConstants from './tableConstants';
import { getScrollBarWidth } from 'Common/util/getScrollBarWidth';
import { getTextWidth, measureString } from 'Common/util/domUtils';


const scrollBarWidth = getScrollBarWidth();

function getIndentPerDepth(column) {
    if (column.expandable === true) {
        return TableConstants.EXPANDABLE_COLUMN_INDENT_PER_DEPTH;
    }
    else if (column.align === 'right') {
        return TableConstants.COLUMN_RIGHT_INDENT_PER_DEPTH;
    }
    else {
        return TableConstants.COLUMN_LEFT_INDENT_PER_DEPTH;
    }
}

function forEachDataRow(data, cb, depth = 0) {
    data.forEach((row, rowIndex) => {
        cb(row, rowIndex, depth);

        if (row.subRows) {
            forEachDataRow(row.subRows, cb, depth + 1);
        }
    });
}

// If a textOnlyHeader is available prefer that.
function getHeaderText(column) {
    const { Header, textOnlyHeader = null } = column;

    if (typeof textOnlyHeader === 'string') {
        return textOnlyHeader;
    }
    else if (typeof Header === 'string') {
        return Header;
    }
    else {
        // We don't know how to measure this.
        console.warn('Cannot get header text; assuming empty string');
        return '';
    }
}

function measureColumn({ column, data, maxWidth, minWidth, isLastColumn, headerTextWidthMap }) {
    // savedWidth trumps anything else
    if(column.savedWidth) {
        return {
            measuredWidth: column.savedWidth,
            optimalWidth: column.savedWidth,
        };
    }

    let maxMeasuredWidth = minWidth;
    let measureCell = column.measureCell || ((datum, measureFunction) => {
        let w = measureFunction(datum);
        return {measuredWidth: w, optimalWidth: w};
    });

    const accessor = column.accessor;
    const accessorType = (typeof accessor);
    let getCellData = null;

    if (accessorType === 'string') {
        getCellData = (row) => row[accessor];
    }
    else {
        getCellData = accessor;
    }

    const indentPerDepth = getIndentPerDepth(column);
    let maxOptimalWidth = 0;

    forEachDataRow(data, (row, rowIndex, depth) => {
        const { width, optimalWidth } = measureCell(getCellData(row), measureString);
        const approximateWidth = (w) => (
            w
            + (TableConstants.COLUMN_PADDING * 2) // left and right padding
            + (indentPerDepth * depth)
            + TableConstants.MAX_METRIC_PADDING
            + (TableConstants.COLUMN_BORDER_WIDTH * 2) // left and right border for some cells
            + (isLastColumn ? scrollBarWidth : 0)
        );

        maxMeasuredWidth = Math.max(approximateWidth(width), maxMeasuredWidth);
        maxOptimalWidth = Math.max(approximateWidth(optimalWidth), maxOptimalWidth);
    });

    // ensure room for sort icon by ensuring a certain width
    if (column.sortOrder) {
        //[FUTUREHACK] try to add just a little more wiggle room for the arrow + order
        maxMeasuredWidth = Math.max(maxMeasuredWidth, TableConstants.MIN_COLUMN_WIDTH + TableConstants.SORT_ARROW_WIDTH_ALLOWANCE);
        maxOptimalWidth = Math.max(maxOptimalWidth, TableConstants.MIN_COLUMN_WIDTH + TableConstants.SORT_ARROW_WIDTH_ALLOWANCE);
    }

    let measuredWidth = Math.min(Math.max(maxMeasuredWidth, minWidth), maxWidth);
    let optimalWidth = Math.min(Math.max(maxOptimalWidth, minWidth), maxWidth);

    const headerText = getHeaderText(column);
    const headerTextWidth = headerTextWidthMap[headerText] || (
        getTextWidth({fontName: "Roboto", fontSize: "15px", fontWeight: "bold"}, headerText) +
        (TableConstants.COLUMN_PADDING * 2) + 
        TableConstants.MAX_METRIC_PADDING + 
        TableConstants.COLUMN_BORDER_WIDTH +
        (column.sortable ? TableConstants.SORT_ARROW_WIDTH_ALLOWANCE : 0)
    );
    headerTextWidthMap[headerText] = headerTextWidth;

    if (headerTextWidth > optimalWidth) {
        optimalWidth = headerTextWidth;
    }

    return {
        measuredWidth: Math.floor(measuredWidth),
        optimalWidth: Math.floor(optimalWidth),
    };
}

function mapEachColumn(columns, cb, isLast=null) {
    return columns.map((column, columnIndex) => {
        const isLastColumn = columnIndex === (columns.length - 1);
        const newIsLast = isLast === null
            ? isLastColumn
            : isLast && isLastColumn;

        if (column.columns) {
            return {
                ...column,
                columns: mapEachColumn(column.columns, cb, newIsLast),
            };
        }
        else {
            return cb(column, columnIndex, newIsLast);
        }
    });
}

/**
 * Pass in the columns and total widths object you want updated.
 * This recursively goes through columns and aggregates the
 * totals based on of the leaf columns width information.
 * @param columns 
 * @param totals 
 */
function aggregateTotalsForLowestLevelColumns(columns, totals) {
    columns.forEach(column => {
        if (column.columns) {
            aggregateTotalsForLowestLevelColumns(column.columns, totals);
        }
        else {
            totals.measuredWidth += column.measuredWidth;
            totals.optimalWidth += column.optimalWidth;
            if (column.savedWidth > 0) {
                totals.fixedWidth += column.savedWidth;
            }
            else if (column.measuredWidth >= column.maxWidth) {
                totals.fixedWidth += column.measuredWidth;
            }
        }
    });
}

const PRIMARY_COLUMN_WIDTH = 200;

/**
 * A hook to size columns for a table.
 *
 * Columns will be sized based on their width property. Any columns with width set to
 * 'auto' will be measured and sized appropriately. This measurement is done by calling
 * measureCell (see columns param) on each piece of data. The header text is also
 * measured. By default this measurement is not precise and only provides a best guess
 * measurement while being very performant.
 *
 * If the isPrint property is supplied columns will be forced to fit in the given space
 * with little regard to the contents of the column.
 *
 * @param {Array} columns The columns parameter for useTable, these are the relevant
 *     fields for useSizedColumns:
 *         width {Number | String} - If a number, try and use that as the width, if
 *             isPrint is true a different width may need to be used. Any column with
 *             the width set to 'auto' will be measured via measureCell.
 *         [measureCell] {Function} - Columns can optionally have a member named
 *             measureCell to provide a method for data to be measured. measureCell is
 *             called with two args (data, measureFunction), data is the raw data object
 *             for that cell and measureFunction is a method which takes a string as
 *             input and outputs its best guess for that strings width in a table cell.
 *             Most column types should only need to call that with a textOnly value to
 *             calculate their width. If auto is used and measureCell is not provided one
 *             will be generated based on the accessor, note that if the accessor does
 *             not return a string the value cannot be measured in this case.
 *         accessor {String|Function} - A standard react-table column accessor method.
 *             when called on a data row it returns the raw table data for the cell in
 *             the current column.
 *         [textOnlyHeader] {String} - A plain-text version of the Header text meant to
 *              to provide a decent estimation for the header text width. If supplied
 *              it may be measured and factored in to the automatic width of a column if
 *              space allows.
 *         Header {Node|String} - If textOnlyHeader is not supplied and Header is a
 *             string it may be measured and factored in to the automatic width of a
 *             column if space allows.
 *         [isPrimary] {Boolean} - If set to true, the column will be given a
 *             larger width relative to other columns in print view.
 * @param {Array} data The data parameter for useTable.
 * @param {Number} containerWidth The width of the table container in pixels. Used for
 *     "auto" width column calculation and used as a maximum width for isPrint.
 * @param {Boolean} [isPrint] If supplied, constrain the width available for all the
 *     columns to containerWidth. With width supplied preset column widths are ignored
 *     and will all be treated like they are set to 'auto'.
 *
 * @returns {Array} A memoized columns array with all width members set to their new
 *     widths. The minWidth and maxWidth properties on columns may also be set to a
 *     new size.
 */
export default function useSizedColumns({ columns, data, containerWidth, isPrint }) {
    const newColumns = useMemo(() => {
            let headerTextWidthMap = {}; // used so we don't have to recalculate same header text widths
            let updatedColumns = mapEachColumn(columns, (column, columnIndex, isLastColumn) => {
                if (column.width === 'auto') {
                    const minWidth = column.minWidth === undefined
                        ? TableConstants.MIN_COLUMN_WIDTH
                        : column.minWidth;
                    const maxWidth = column.maxWidth === undefined
                        ? TableConstants.MAX_COLUMN_WIDTH
                        : column.maxWidth;

                    return {
                        ...column,
                        // measureColumn returns measuredWidth and optimalWidth
                        // measureColumn respects column.savedWidth and will not recalculate
                        // the width.
                        ...measureColumn({
                            column,
                            data,
                            minWidth,
                            maxWidth,
                            isLastColumn,
                            headerTextWidthMap,
                        }),
                        maxWidth: maxWidth,
                        isAuto: true,
                    };
                }
                else if (typeof column.width === 'number') {
                    // If we were given a hardcoded width, then use it!
                    // This will be used for columns that are not supposed to be resized.
                    return {
                        ...column,
                        optimalWidth: column.width,
                        measuredWidth: column.width,
                    };
                }
                else {
                    return column;
                }
            });

            // Accumulate the measured, optimal, and fixed widths.
            let totals = {
                measuredWidth: 0,
                optimalWidth: 0,
                fixedWidth: 0,
            };
            aggregateTotalsForLowestLevelColumns(updatedColumns, totals);

            // At this point, we have a measured, optimal, and maximum width for each column,
            // as well as know the total measured width, total optimal width, and total
            // fixed width.

            // Let's determine the final column widths
            if(totals.measuredWidth > containerWidth) {
                if (isPrint) {
                    const colIsPrimary = (col) => {
                        let rv = col.isPrimary;
                        if(col.columns) {
                            rv = rv || col.columns.some(colIsPrimary);
                        }
                        return rv;
                    };

                    const countColumns = (cols, primary) => {
                        return cols.reduce((acc, column) => {
                            if(column.columns) {
                                return acc + countColumns(column.columns, primary);
                            }
                            else {
                                let count = 1;
                                if (primary) {
                                    count = column.isPrimary ? 1 : 0;
                                }
                                return acc + count;
                            }
                        }, 0);
                    };

                    const numTopLevelColumns = columns.length;

                    const numPrimaryColumns = countColumns(columns, true);

                    const numRegularColumns = countColumns(columns, false) - numPrimaryColumns;

                    // If there's enough space to make primary columns a little
                    // bit bigger, do it.
                    const primaryColumnWidth = containerWidth > (PRIMARY_COLUMN_WIDTH * numPrimaryColumns * 1.5)
                        ? PRIMARY_COLUMN_WIDTH
                        : containerWidth / numTopLevelColumns;

                    const columnWidth = numRegularColumns > 0
                        ? (containerWidth - (primaryColumnWidth * numPrimaryColumns)) / numRegularColumns
                        : primaryColumnWidth;

                    // Map through the top level of columns separately, because
                    // only the top-level of columns supports the larger primary
                    // column.
                    return columns.map((column) => {
                        const rv = {
                            ...column,
                            width: colIsPrimary(column)
                                ? primaryColumnWidth
                                : columnWidth,
                            minWidth: 0,
                            maxWidth: TableConstants.MAX_COLUMN_WIDTH,
                        };

                        if (rv.columns) {
                            rv.columns = mapEachColumn(rv.columns, (subColumn) => {
                                return {
                                    ...subColumn,
                                    width: colIsPrimary(subColumn)
                                        ? primaryColumnWidth
                                        : columnWidth,
                                    minWidth: 0,
                                    maxWidth: TableConstants.MAX_COLUMN_WIDTH,
                                };
                            });
                        }

                        return rv;
                    });
                }
                else {
                // We are going to overflow, no matter what. Use measured width for each column.
                updatedColumns = mapEachColumn(updatedColumns, column => {
                    if (column.savedWidth) {
                        // Leave saved widths alone
                        column.width = column.savedWidth;
                    }
                    else {
                        column.width = column.measuredWidth;
                    }
                    return column;
                });
                }
            }
            else if (totals.optimalWidth > containerWidth) {
                // We can fit more than the measured width, but not all of the optimal width.
                // Use a width for each column proportionally between measured and optimal.

                // If we were to use only measured width, how much room do we still have to
                // expand columns closer to their optimal width?
                // To prevent overflow, we need less than the full container width
                const roomToExpand = (containerWidth * TableConstants.CONTAINER_FORCE_FULL_WIDTH_PROPORTION_ADJUSTMENT) - totals.measuredWidth;

                // What is the starting width for the columns that can be expanded (i.e., don't
                // have a saved width)?
                const totalStartingWidth = totals.measuredWidth - totals.fixedWidth;

                updatedColumns = mapEachColumn(updatedColumns, column => {
                    if (column.savedWidth) {
                        // Leave saved widths alone
                        column.width = column.savedWidth;
                    }
                    else {
                        // Give this column it's proportion of the room we have to expand
                        column.width = Math.floor(roomToExpand * (column.measuredWidth/totalStartingWidth) + column.measuredWidth);
                    }
                    return column;
                });
            }
            else if (totals.optimalWidth > Math.floor(containerWidth * TableConstants.FORCE_FULL_WIDTH_PROPORTION)) {
                // Our optimal width is close to the size of a container, so expand a little more to 
                // fill the space and not "look broken".

                // If we were to use only optimal width, how much room do we still have to
                // expand columns in order to fill the container?
                // To prevent overflow, we need less than the full container width
                const roomToExpand = (containerWidth * TableConstants.CONTAINER_FULL_WIDTH_ADJUSTMENT) - totals.optimalWidth;

                // What is the starting width for the columns that can be expanded (i.e., don't
                // have a saved width)?               
                const spreadableWidth = totals.optimalWidth - totals.fixedWidth;

                updatedColumns = mapEachColumn(updatedColumns, column => {
                    if (column.savedWidth) {
                        // Leave saved widths alone
                        column.width = column.savedWidth;
                    }
                    else {
                        // Give this column its proportion of the room we have to expand
                        column.width = Math.floor(roomToExpand * (column.optimalWidth/spreadableWidth) + column.optimalWidth);
                    }
                    return column;
                });
            }
            else {
                // We have lots of open room, just use optimal width for each column.
                updatedColumns = mapEachColumn(updatedColumns, column => {
                    if (column.savedWidth) {
                        // Leave saved widths alone
                        column.width = column.savedWidth;
                    }
                    else {
                        column.width = column.optimalWidth;
                    }
                    return column;
                });
            }

            return updatedColumns;
    }, [ columns, data, containerWidth, isPrint ]);

    return newColumns;
}
