// Length requirement from https://pages.nist.gov/800-63-3/sp800-63b.html
const MIN_PASSWORD_LENGTH = 8;

export default function validate(password) {
    let error = null;
    if (!password || password === '') {
        error = 'Password cannot be blank';
    }
    else if (password.length < MIN_PASSWORD_LENGTH) {
        error = `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`;
    }

    return error;
}
