import React from 'react';
import PropTypes from 'prop-types';
import MetricAlertEditor from './MetricAlertEditor';
import ProcessStateDurationAlertEditor from './ProcessStateDurationAlertEditor';
import ProcessStateTargetTimeAlertEditor from './ProcessStateTargetTimeAlertEditor';
import AlertsSection from './AlertsEditorSection';
import AlertItem from './AlertItem';

const DEFAULT_ALERTS = {
    metric: {
        metric: 'oee',
        comparison: 'less_than',
        threshold: 50,
        subscribers: [],
    },
    process_state_duration: {
        process_state: 'down',
        threshold: 15,
        subscribers: [],
    },
    process_state_target_time: {
        process_state: 'changeover',
        comparison: 'over_target',
        threshold: 15,
        subscribers: [],
    },
};

export default class AlertsEditor extends React.Component {
    constructor(props) {
        super(props);

        // Object to hold handler functions for the multiple alert lists
        this._listHandlers = {};
        this._nextTempId = 0;
    }

    _getNextTempId() {
        return this._nextTempId++;
    }

    _handleAlertChange = (listName, event) => {
        const { name, value } = event.target;

        let alerts = [ ...this.props.value[listName] ];
        alerts[name] = value;

        this.props.onChange({
            ...event,
            target: {
                name: this.props.name,
                value: {
                    ...this.props.value,
                    [listName]: alerts,
                },
            },
        });
    };

    _handleAddAlertClick = (listName, defaultAlert, event) => {
        const { name, value } = this.props;

        const alerts = [
            {
                ...defaultAlert,
                id: null,
                temp_id: this._getNextTempId(),
            },
            ...value[listName],
        ];

        this.props.onChange({
            ...event,
            target: {
                name: name,
                value: {
                    ...value,
                    [listName]: alerts,
                },
            },
        });
    };

    _handleDeleteAlertClick = (listName, event) => {
        const { name, value } = this.props;
        const { name: itemName } = event.target;

        const alerts = value[listName].filter((alert, i) => {
            return String(i) !== itemName;
        });

        this.props.onChange({
            ...event,
            target: {
                name: name,
                value: {
                    ...value,
                    [listName]: alerts,
                },
            },
        });
    };

    _getChangeAlertHandler(listName) {
        const key = `${listName}-change`;

        if (!this._listHandlers[key]) {
            this._listHandlers[key] = (event) => { this._handleAlertChange(listName, event); };
        }

        return this._listHandlers[key];
    }

    _getAddAlertHandler(listName) {
        const key = `${listName}-add`;

        if (!this._listHandlers[key]) {
            this._listHandlers[key] = (event) => { this._handleAddAlertClick(listName, DEFAULT_ALERTS[listName], event); };
        }

        return this._listHandlers[key];
    }

    _getDeleteAlertHandler(listName) {
        const key = `${listName}-delete`;

        if (!this._listHandlers[key]) {
            this._listHandlers[key] = (event) => { this._handleDeleteAlertClick(listName, event); };
        }

        return this._listHandlers[key];
    }

    _getValidationForItem(validation, listName, itemIndex) {
        return validation?.[listName]?.[itemIndex] || null;
    }

    _renderAlertItem(listName, alert, index) {
        const { validation, allowEdit } = this.props;
        const alertValidation = this._getValidationForItem(validation, listName, index);

        let Editor;

        switch (listName) {
            case 'metric':
                Editor = MetricAlertEditor;
                break;
            case 'process_state_duration':
                Editor = ProcessStateDurationAlertEditor;
                break;
            case 'process_state_target_time':
                Editor = ProcessStateTargetTimeAlertEditor;
                break;
            default:
                throw new Error(`No editor available for alert type "${listName}"`);
        }

        return (
            <AlertItem
                key={alert.id !== null ? `existing-${alert.id}` : `new-${alert.temp_id}`}
                name={`${index}`}
                allowDelete={allowEdit}
                onDeleteClick={this._getDeleteAlertHandler(listName)}
                subscribers={alert.subscribers}
                Editor={Editor}
                EditorProps={{
                    name: `${index}`,
                    value: alert,
                    validation: alertValidation,
                    allowEdit: allowEdit,
                    onChange: this._getChangeAlertHandler(listName),
                }}
            />
        );
    }

    render() {
        const { value, allowEdit } = this.props;

        return (
            <React.Fragment>
                <AlertsSection
                    title="Metric"
                    description={
                        <React.Fragment>
                            Monitor KPIs for the current shift. A typical application is to be alerted if your
                            team is not on track to win their shift (as measured by takt-based Efficiency).
                            <br />
                            <br />
                            Metric alerts are only sent after there has been at least 30 minutes of manufacturing
                            time (run time and down time) in the shift. This ensures there has been enough
                            production time for the alert to be meaningful and actionable. Each metric alert is
                            sent one time per shift. In other words, if a metric crosses an alert threshold
                            multiple times, the alert is sent only the first time.
                        </React.Fragment>
                    }
                    addButtonEnabled={allowEdit}
                    onAddClick={this._getAddAlertHandler('metric')}
                >
                    {value.metric.map((alert, i) => {
                        return this._renderAlertItem('metric', alert, i);
                    })}
                </AlertsSection>
                <AlertsSection
                    title="Production State Time"
                    description={
                        `Monitor duration of production state events. A typical
                        application is to be alerted to long down events.`
                    }
                    addButtonEnabled={allowEdit}
                    onAddClick={this._getAddAlertHandler('process_state_duration')}
                >
                    {value.process_state_duration.map((alert, i) => {
                        return this._renderAlertItem('process_state_duration', alert, i);
                    })}
                </AlertsSection>
                <AlertsSection
                    title="Production State Target"
                    description={
                        `Compare the duration of a production state event to its
                        target. A typical application is to be alerted to
                        changeovers that take longer than expected. This alert
                        type will only trigger if a target time is configured
                        for the event (e.g., a changeover target).`
                    }
                    addButtonEnabled={allowEdit}
                    onAddClick={this._getAddAlertHandler('process_state_target_time')}
                >
                    {value.process_state_target_time.map((alert, i) => {
                        return this._renderAlertItem('process_state_target_time', alert, i);
                    })}
                </AlertsSection>
            </React.Fragment>
        );
    }
}

AlertsEditor.propTypes = {
    name: PropTypes.string,
    value: PropTypes.shape({
        metric: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                temp_id: PropTypes.number,
            })
        ).isRequired,
        process_state_duration: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                temp_id: PropTypes.number,
            }).isRequired
        ).isRequired,
        process_state_target_time: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                temp_id: PropTypes.number,
            }).isRequired
        ).isRequired,
    }).isRequired,
    validation: PropTypes.shape({
        metric: PropTypes.array,
        process_state_duration: PropTypes.array,
        process_state_target_time: PropTypes.array,
    }),
    allowEdit: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

AlertsEditor.defaultProps = {
    name: '',
    validation: null,
};
