import React from 'react';
import PropTypes from 'prop-types';
import DeviceSubscriptionEditor from './DeviceSubscriptionsEditor';
import Form from 'Components/form/Form';
import * as RequestStatus from 'Common/data/RequestStatus';

export default class DeviceSubscriptionsForm extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        devices: PropTypes.array.isRequired,
        data: PropTypes.arrayOf(PropTypes.number).isRequired,
        requests: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            modifiedData: props.data,
            lastPatchStatus: props?.requests?.patchDeviceSubscriptions?.status,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const lastPatchStatus = state.lastPatchStatus;
        const nextPatchStatus = props?.requests?.patchDeviceSubscriptions?.status;

        if (lastPatchStatus !== nextPatchStatus) {
            let nextModifiedData = state.modifiedData;

            // Watch for when patchDeviceSubscriptions changes from PENDING to SUCCESS and
            // reset the data
            if (lastPatchStatus === RequestStatus.PENDING &&
                nextPatchStatus === RequestStatus.SUCCESS
            ) {
                nextModifiedData = props.data;
            }

            return {
                modifiedData: nextModifiedData,
                lastPatchStatus: nextPatchStatus,
            };
        }

        return null; // No changes
    }

    _handleChange = (event) => {
        this.setState({
            modifiedData: event.target.value,
        });
    };

    _handleReset = () => {
        this.setState({
            modifiedData: this.props.data,
        });
    };

    _handleSubmit = () => {
        this.props.onSubmit({
            target: {
                name: this.props.name,
                value: this.state.modifiedData,
            },
        });
    };

    render() {
        const { devices, data: initialData, requests } = this.props;
        const { modifiedData } = this.state;

        const hasChanges = modifiedData !== initialData;
        const isLoading = requests.patchDeviceSubscriptions.status === RequestStatus.PENDING;

        const allowEdit = !isLoading;
        const allowSubmit = !isLoading && hasChanges;
        const allowCancel = !isLoading && hasChanges;

        return (
            <Form
                error={requests?.patchDeviceSubscriptions?.error}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <DeviceSubscriptionEditor
                    name="DeviceSubscriptionEditor"
                    devices={devices}
                    value={modifiedData}
                    allowEdit={allowEdit}
                    onChange={this._handleChange}
                />
            </Form>
        );
    }
}
