
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import {
    getSupportUsers,
    getSupportUsersReset,
    deleteSupportUser,
    patchSupportUser,
    sortSupportUsers,
} from 'Redux/actions';
import Table from 'Common/components/Table/WidthAdjustableTable';

import Page from 'Components/page/Page';
import styled from 'styled-components';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import PageContent from 'Components/page/PageContentFull';
import { getSupportUsersList, } from 'Redux/reducers/supportUsers';
import { bindActionCreators } from 'redux';
import { Roles } from 'Components/app/roles';
import useSearchBox, { doesValueMatchSearch } from 'Common/hooks/useSearchBox';

const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

function SupportUserPage({
    actions,
    sessionData,
    users,
}) {

    const [deleteConfirm, setDeleteConfirm] = useState(0);

    const [sortColumn, setSortColumn] = useState(2);
    const [sortDirection, setSortDirection] = useState(1);

    const {
        searchValue,
        renderSearchBox,
    } = useSearchBox({
        placeholder: 'Search organization name, user name, email, or pending email',
    });

    useEffect(() => {
        actions.getSupportUsers();
        return actions.getSupportUsersReset();
    }, [actions]);

    const columns = useMemo(() => {
        const renderRole = (user) => {
            const changeRole = (evt) => {
                const newRole = evt.target.value;
                actions.patchSupportUser(user.data.id, {
                    role: newRole
                });
            };

            const renderOptions = () => {
                return Object.keys(Roles).map((kk) => {
                    return (
                        <option key={kk}> {Roles[kk]} </option>
                    );
                });
            };

            return (
                <select defaultValue={user.data.role} onChange={changeRole} >
                    {renderOptions()}
                </select>
            );
        };

        const renderDelete = (user) => {

            const deleteStepOne = () => {
                setDeleteConfirm(user.data.id);
            };

            const deleteStepTwo = () => {
                actions.deleteSupportUser(user.data.id);
                setDeleteConfirm(0);
            };

            if (user.data.id === deleteConfirm) {
                return (
                    <button onClick={deleteStepTwo}>
                        Confirm
                    </button>
                );
            }

            if (user.data.id !== sessionData.id) {
                return (
                    <button onClick={deleteStepOne}>
                        Delete
                    </button>
                );
            }

            return (
                <div> Can&apos;t Delete </div>
            );
        };

        return [{
            Header: 'Name',
            accessor: (user) => {
                return user.data.name;
            },
            sortable: true,
            width: 204,
        }, {
            Header: 'Organization',
            sortable: true,
            accessor: (user) => {
                return user.data.organization.name;
            },
            width: 204,
        }, {
            Header: 'Org. Id',
            sortable: true,
            accessor: (user) => {
                return user.data.organization_id;
            },
            width: 71,
        }, {
            Header: 'Email',
            sortable: true,
            accessor: (user) => {
                return user.data.email_address.email;
            },
            width: 204,
        }, {
            Header: 'Email Verified',
            accessor: (user) => {
                return user.data.email_address.verified ? 'true' : 'false';
            },
            width: 102,
        }, {
            Header: 'Pending Email',
            accessor: (user) => {
                return user.data.pending_email_address?.email || 'No Pending';
            },
            width: 204,
        }, {
            Header: 'Acct. Created',
            accessor: (user) => {
                return (new Date(user.data.created)).toLocaleString();
            },
            sortAccessor: (user) => {
                return new Date(user.data.created);
            },
            width: 150,
            sortable: true,
        }, {
            Header: 'Role',
            accessor: (user) => {
                return renderRole(user);
            },
            sortAccessor: (user) => {
                return user.data.role;
            },
            width: 96,
            sortable: true,
        }, {
            Header: 'Delete',
            accessor: (user) => {
                return renderDelete(user);
            },
            width: 84,
        }];
    }, [deleteConfirm, actions, sessionData]);

    const onSortByColumnChange = (headerColumnIndex, unsetOtherColumns) => {
        if (headerColumnIndex === sortColumn) {
            setSortDirection(sortDirection * -1);
        }
        else {
            setSortColumn(headerColumnIndex);
        }
    };

    useEffect(() => {
        const column = columns[sortColumn];
        let accessor = column.accessor;
        if (column.sortAccessor) {
            accessor = column.sortAccessor;
        }
        actions.sortSupportUsers(accessor, sortDirection);
    }, [actions, columns, sortColumn, sortDirection]);

    const data = useMemo(() => {
        return users.filter((dd) => {
            dd = dd.data;
            if (
                doesValueMatchSearch(dd?.organization?.name, searchValue) ||
                doesValueMatchSearch(dd?.name, searchValue) ||
                doesValueMatchSearch(dd?.email_address?.email, searchValue) ||
                doesValueMatchSearch(dd?.pending_email_address?.email, searchValue)
            ) {
                return true;
            }
            return false;
        });
    }, [searchValue, users]);

    return (
            <Page>
                <ConfigPageTitle>All Enterprise Users</ConfigPageTitle>
                <PageContent>
                    <TableContainer>
                        {renderSearchBox()}
                        <Table
                            columns={columns}
                            data={data}
                            showCellBorders={true}
                            onSortByColumnChange={onSortByColumnChange}
                            maxHeight={800}
                        />
                    </TableContainer>
                </PageContent>
            </Page>
    );
}

SupportUserPage.propTypes = {
    actions: PropTypes.shape({
        getSupportUsers: PropTypes.func.isRequired,
        getSupportUsersReset: PropTypes.func.isRequired,
        deleteSupportUser: PropTypes.func.isRequired,
        patchSupportUser: PropTypes.func.isRequired,
        sortSupportUsers: PropTypes.func.isRequired,
    }).isRequired,
    sessionData: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            data: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string,
                email_address: PropTypes.object.isRequired,
                role: PropTypes.string.isRequired,
                active: PropTypes.bool.isRequired,
            }),
        })
    ).isRequired,
};

const mapStateToProps = state => {
    const {
        session,
        supportUsers,
    } = state;

    return {
        users: getSupportUsersList(supportUsers),
        sessionData: session.sessionData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getSupportUsers: getSupportUsers,
            getSupportUsersReset: getSupportUsersReset,
            deleteSupportUser: deleteSupportUser,
            patchSupportUser: patchSupportUser,
            sortSupportUsers: sortSupportUsers,
        }, dispatch)
    };
};

// connect the component to Redux
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SupportUserPage));
