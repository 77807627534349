import React from 'react';
import { Link } from 'react-router-dom';
import SectionDescription from 'Components/text/SectionDescription';

export const primaryEmailDescription = (
    <SectionDescription>
        Each account has one primary email address that is used to log in and
        for account administration. The primary email address will also receive
        email alerts and email reports.
    </SectionDescription>
);

export const smsEmailDescription = (
    <SectionDescription>
        <p>
            Alerts can optionally be delivered as text messages through
            a <Link to="/help/mobile-gateways" target="_blank">mobile carrier gateway</Link>.
            To support this service, XL&nbsp;Enterprise will send short, text-only alerts to
            this email address.
        </p>

        <p>
            Please note that not all carriers support this service and message and data rates
            from your mobile carrier may apply to text messages sent to you by XL&nbsp;Enterprise.
        </p>
    </SectionDescription>
);

export const smsEmailShortDescription = (
    <p>
        Enter the email address to use for text messages. This email address
        will include your mobile phone number and a domain specific to your
        mobile carrier.
        See <Link to="/help/mobile-gateways" target="_blank">Mobile Gateways</Link> for more information.
    </p>
);
