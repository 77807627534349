import some from 'lodash/some';
import { getProcessStatesWithTargetTimes } from 'System/processStates';
import every from 'lodash/every';

const processStates = getProcessStatesWithTargetTimes();

function validateProcessState(value) {
    if (value.length === 0) {
        return 'required';
    }
    else if (!some(processStates, ps => ps.value === value)) {
        return 'invalid production state';
    }
    else {
        return null;
    }
}

function validateComparison(value) {
    if (value.length === 0) {
        return 'required';
    }
    else if (value !== 'over_target' && value !== 'before_target') {
        return 'invalid comparison';
    }
    else {
        return null;
    }
}

function validateThreshold(value) {
    if (value.length === 0) {
        return 'threshold is required';
    }
    else if (!Number.isFinite(Number(value))) {
        return 'threshold must be a number';
    }
    else if (Number(value) < 0) {
        return 'threshold must be positive';
    }
    else {
        return null;
    }
}

export default function validate(processStateDurationAlert) {
    const { process_state, comparison, threshold } = processStateDurationAlert;

    const validation = {
        process_state: validateProcessState(process_state),
        comparison: validateComparison(comparison),
        threshold: validateThreshold(threshold),
    };

    if (every(validation, v => !v)) {
        return null;
    }
    else {
        return validation;
    }
}
