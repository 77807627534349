import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Root = styled.div`
    touch-action: manipulation;
`;

const DropZone = styled.div`
    ${({$isDragging}) => $isDragging && css`
        background-color: ${({theme}) => theme.colors.palette.blue.ice };
    `}
`;

// The DraggableList uses a DragOverlay to show a duplicate of the item
// while it's being dragged. So hide the original during a drag.
const ItemContainer = styled.div`
    ${({$isDragging}) => $isDragging && css`
        opacity: 0;
    `}
`;

export default function DraggableListItem(props) {
    const { className, name, index, children, highlightDropZone, onDelete, ...otherProps } = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: name,
        data: {
            index: index,
        },
    });
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    // [FUTUREHACK]
    // allow for deleting with delete button
    const handleKeyDelete = useCallback((event) => {
        const { key } = event;
        if (key === 'Delete' && !isDragging) {
            onDelete({ id: name, index: index });
        }
    }, [onDelete, name, index, isDragging]);

    return (
        <div onKeyDown={handleKeyDelete}>
            <Root
                className={className}
                {...otherProps}
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
            >
                <DropZone $isDragging={isDragging && highlightDropZone}>
                    <ItemContainer $isDragging={isDragging}>
                        {children}
                    </ItemContainer>
                </DropZone>
            </Root>
        </div>
    );
}

DraggableListItem.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    children: PropTypes.node,
    highlightDropZone: PropTypes.bool,
    onDelete: PropTypes.func,
};

DraggableListItem.defaultProps = {
    highlightDropZone: false,
    onDelete: () => {}
};
