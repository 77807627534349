import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MobileNavMenuNode from './MobileNavMenuNode';

const Root = styled.div``;

export default class MobileNavMenu extends React.PureComponent {
    static propTypes = {
        subMenuContents: PropTypes.arrayOf(
            PropTypes.shape({
                menuTitle: PropTypes.string.isRequired,
                menuItems: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.string.isRequired,
                        to: PropTypes.string,
                        text: PropTypes.string,
                        minRole: PropTypes.string,
                    })
                ).isRequired,
            }).isRequired,
        ).isRequired,
        indentLevel: PropTypes.number,
    };

    static defaultProps = {
        indentLevel: 0,
    };

    constructor(props) {
        super(props);
    }

    _renderNavItem({ type, to, text }, i) {
        const { indentLevel } = this.props;

        if (type === 'link') {
            return (
                <MobileNavMenuNode
                    key={`nav-item=${i}`}
                    indentLevel={indentLevel + 1}
                    label={text}
                    link={to}
                />
            );
        }
        else if (type === 'separator') {
            return null;
        }
    }

    _renderMenuList(subMenuContents) {
        const { indentLevel } = this.props;

        return subMenuContents.map(({ menuTitle, menuItems }, i) => {
            if (menuItems.length === 0) {
                return null;
            }

            return (
                <MobileNavMenuNode
                    key={`static-sub-menu-${i}`}
                    label={menuTitle}
                    indentLevel={indentLevel}
                >
                    {menuItems.map((menuItem, menuItemIndex, itemIndex) => {
                        return this._renderNavItem(menuItem, menuItemIndex, itemIndex);
                    })}
                </MobileNavMenuNode>
            );
        });
    }

    render() {
        const { subMenuContents, indentLevel, ...otherProps } = this.props;

        return (
            <Root {...otherProps}>
                {this._renderMenuList(subMenuContents)}
            </Root>
        );
    }
}
