import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import upperFirst from 'lodash/upperFirst';

import { Caption, Subtitle1 } from 'Components/typography/typography';
import ListItem from 'Components/list/ListItem';
import ListItemText from 'Components/list/ListItemText';
import TextInput from 'Components/text-input/TextInput';
import { Spacing } from 'Components/common/common';
import { createPath } from 'Common/util/FormUtils';
import { isDeviceAssetNameChangeSupported, isDeviceVisibilityChangeSupported } from 'System/versions';
import { getNodeTypePlaceholderText, NodeType, getHierarchyNodeDisplayName } from 'Components/hierarchy-editor/HierarchyNodeType';
import EnterpriseHierarchySelect from 'Components/enterprise-hierarchy-select/EnterpriseHierarchySelect';

const StyledTextInput = styled(TextInput)`
    padding-right: ${Spacing.inlineButtonMargin};
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 10000 0 auto;
`;

const ReadOnlyName = styled(Subtitle1)`
    display: block;
    color: ${props => props.theme.colors.darkText.highEmphasis};
`;

const TextLine = styled(Caption)`
    display: block;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    padding-top: 4px;
`;

const Indicator = styled.span`
    border-radius: 50%;
    height: 14px;
    width: 14px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
`;

const RedIndicator = styled(Indicator)`
    background-color: #e52a20;
`;

const AmberIndicator = styled(Indicator)`
    background-color: #e68500;
`;

const GreenIndicator = styled(Indicator)`
    background-color: #4f9d47;
`;

export default class DeviceItem extends React.Component {
    static defaultProps = {
        disabled: false,
    };

    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.shape({
            id: PropTypes.number.isRequired,
            asset_name: PropTypes.string,
            created: PropTypes.string.isRequired,
            alert_data: PropTypes.shape({
                updated: PropTypes.string.isRequired,
                xl_firmware_version: PropTypes.string.isRequired,
            }),
            ip_address: PropTypes.string,
            hierarchy_visibility: PropTypes.string.isRequired,
        }).isRequired,
        nodeId: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool.isRequired,
        validation: PropTypes.object,
        enterpriseHierarchy: PropTypes.object.isRequired,
    };

    constructor() {
        super();
    }

    _formatAlertDataTimestamp(alertDataTimestamp) {
        if (!alertDataTimestamp) {
            return 'Never';
        }

        return upperFirst(moment(alertDataTimestamp).fromNow());
    }

    _renderLastDataReceived(timeStamp) {
        timeStamp = moment(timeStamp).toDate();
        const now = new Date();
        const renderIndicator = function() {
            if (timeStamp) {
                const elapsed = now - timeStamp.getTime();
                if (elapsed <= 5 * 60 * 1000) {
                    return (
                        <GreenIndicator />
                    );
                }
                else if (elapsed <= 60 * 60 * 1000) {
                    return (
                        <AmberIndicator />
                    );
                }
                else {
                    return (
                        <RedIndicator />
                    );
                }
            }
        };

        return (
            <span>
                {renderIndicator()} Last data received: {this._formatAlertDataTimestamp(timeStamp)}
            </span>
        );
    }

    _enterpriseVisibilityOptionsFilter = (optionNodeId) => {
        const { nodeId, enterpriseHierarchy } = this.props;

        const node = enterpriseHierarchy.nodes[nodeId];
        return nodeId === optionNodeId || node.parents.indexOf(optionNodeId) !== -1;
    };

    _renderEnterpriseVisibility() {
        const {
            name,
            value,
            enterpriseHierarchy,
            disabled,
            onChange,
            nodeId,
            validation,
        } = this.props;

        const firmwareVersion = value?.alert_data?.xl_firmware_version;
        const isVisibilityChangeSupported = firmwareVersion ? isDeviceVisibilityChangeSupported(firmwareVersion) : true;

        return (
            <EnterpriseHierarchySelect
                name={createPath(name, 'hierarchy_visibility')}
                value={value.hierarchy_visibility}
                onChange={onChange}
                disabled={disabled || !isVisibilityChangeSupported}
                nodeId={nodeId}
                label="Hierarchy Visibility:"
                error={!!validation?.hierarchy_visibility}
                helperText={validation?.hierarchy_visibility}
                enterpriseHierarchy={enterpriseHierarchy}
                optionsFilter={this._enterpriseVisibilityOptionsFilter}
            />
        );
    }

    render() {
        const {
            name,
            value,
            onChange,
            disabled,
            validation,
            enterpriseHierarchy,
            readOnly,
            ...otherProps
        } = this.props;

        const firmwareVersion = value?.alert_data?.xl_firmware_version;

        const isNameChangeSupported = firmwareVersion ? isDeviceAssetNameChangeSupported(firmwareVersion) : true;

        return (
            <ListItem {...otherProps} clickable={false}>
                <ListItemText>
                    {readOnly && (
                        <ReadOnlyName>
                            {value.asset_name}
                        </ReadOnlyName>
                    )}
                    {!readOnly && (
                        <StyledTextInput
                            value={value.asset_name}
                            disabled={disabled || !isNameChangeSupported}
                            name={createPath(name, 'asset_name')}
                            onChange={onChange}
                            placeholder={getNodeTypePlaceholderText(NodeType.DEVICE)}
                            error={!!validation?.asset_name}
                            helperText={validation?.asset_name}
                        />
                    )}
                    {value.ip_address && (
                        <TextLine>
                            IP Address: {value.ip_address}
                        </TextLine>
                    )}
                    <TextLine>
                        Linked: {(new Date(value.created)).toDateString()}
                    </TextLine>
                    <TextLine>
                        {this._renderLastDataReceived(value.alert_data?.updated)}
                    </TextLine>
                    {readOnly && (
                        <TextLine>
                            Hierarchy Visibility: {getHierarchyNodeDisplayName(
                                enterpriseHierarchy.nodes[value.hierarchy_visibility]
                            )}
                        </TextLine>
                    )}
                    {!readOnly && (
                        this._renderEnterpriseVisibility()
                    )}
                </ListItemText>
            </ListItem>
        );
    }
}
