import ParamTypes, { paramTest } from '../ParamTypes';
import paramTypeWrapper from './paramTypeWrapper';
import testType from './testType';

/**
 * ParamType checker which checks if all values in the object have the given type.
 *
 * @param {ParamType} type ParamType from ParamTypes object
 *
 * @returns {Function} ParamType checker which returns an array of failures.
 */
export default function objectOf(type) {
    paramTest(type, ParamTypes.paramType.isRequired, 'ParamTypes.objectOf type arg', { throwError: true, });
    
    return paramTypeWrapper((arg, key) => {
        let failures = [];

        failures = failures.concat(testType(ParamTypes.object, arg, key));

        const values = Object.values(arg);

        // If the objectOf type is marked 'isRequired' we will enforce the array must
        // be at least length 1 .
        //
        if (type._required && values.length === 0) {
            failures.push(
                `object must have at least one item as objectOf type is marked as 'isRequired'`
            );
        }

        if (failures.length === 0) {
            values.forEach((value, i) => {
                failures = failures.concat(testType(type, value, `${key}[${i}]`));
            });
        }

        return failures;
    });
}
