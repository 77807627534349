import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initApp } from 'Redux/actions';
import * as RequestStatus from 'Common/data/RequestStatus';
import LocaleProvider from 'Common/providers/LocaleProvider';
import styled, { ThemeProvider, createGlobalStyle, css } from 'styled-components';
import Header from 'Components/header/Header';
import Footer from 'Components/footer/Footer';
import theme from 'Common/theme/default';
import { withRouter } from 'react-router-dom';
import Main from './Main.js';
import { DisplayMode, DisplayModeConsumer, DisplayModeProvider } from 'Common/util/DisplayMode.js';
import entTranslator from 'Translation/entTranslator';
import TranslationProvider from 'Common/providers/TranslationProvider';
import { FontFamily } from 'Common/components/typography';
import 'Common/font/roboto';

// this is a helper component to apply styles globally based on the theme
const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        font-family: ${FontFamily.primary};
    }
`;

// AppContent is the root of all the content in the app. It should take up the
// entire screen and handle all top-level scrolling. This allows us to portal
// popups outside this box (see index.html for #popup-root), which means we can
// successfully mask clicks and scrolling on the content below the mask.
const AppContent = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: ${props => props.theme.colors.palette.white};

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ $noScroll }) => $noScroll && css`
        overflow: hidden;
    `}
`;

const StyledMain = styled(Main)`
    flex: 1 0 auto;
`;

const StyledFooter = styled(Footer)`
    flex: 0 0 auto;
`;

function isLoading(requests) {
    return requests.getEnvironment.status === RequestStatus.PENDING
           || requests.getEnvironment.status === RequestStatus.IDLE;
}

class App extends React.Component {
    constructor(props) {
        super(props);

        props.initApp();

        this.state = {
            openMenu: null,
        };

        this._rootRef = React.createRef();
    }

    _handleHeaderOpenMenuChange = (menuName) => {
        if (this._rootRef.current) {
            this._rootRef.current.scrollTop = 0;
        }

        this.setState({ openMenu: menuName });
    };

    render() {
        const getLocale = () => {
            return 'en-US';
        };
        const { openMenu } = this.state;
        const { environment } = this.props;

        const environmentLoading = isLoading(environment.requests);

        if (environmentLoading) {
            return null;
        }
        else {
            if (environment.isInitialized) {
                return (
                    <ThemeProvider theme={theme}>
                        <TranslationProvider translator={entTranslator}>
                            <LocaleProvider getLocale={getLocale}>
                                <DisplayModeProvider>
                                    <DisplayModeConsumer>
                                        {(displayMode) => (
                                            <React.Fragment>
                                                <GlobalStyle />
                                                <AppContent
                                                    ref={this._rootRef}
                                                    $noScroll={displayMode === DisplayMode.MOBILE && Boolean(openMenu)}
                                                >
                                                    <Header onOpenMenuChange={this._handleHeaderOpenMenuChange} />
                                                    <StyledMain />
                                                    <StyledFooter />
                                                </AppContent>
                                            </React.Fragment>
                                        )}
                                    </DisplayModeConsumer>
                                </DisplayModeProvider>
                            </LocaleProvider>
                        </TranslationProvider>
                    </ThemeProvider>
                );
            }
            else {
                throw new Error('environment failed to initialize properly!');
            }
        }
    }
}

App.propTypes = {
    initApp: PropTypes.func.isRequired,
    environment: PropTypes.shape({
        isInitialized: PropTypes.bool.isRequired,

        requests: PropTypes.shape({
            getEnvironment: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

const mapStateToProps = state => {
    const { environment } = state;
    return { environment };
};

export default withRouter(connect(mapStateToProps, { initApp })(App));
