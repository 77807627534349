import isEqual from 'lodash/isEqual';
import uniqueId from 'Common/util/uniqueId';

class AsyncValueCacheEntry {
    constructor({
        args,
        load,
        getTime,
    }) {
        this._getTime = getTime;

        this._loadFunction = load;
        this._loadArgs = args;
        this._loadAbortController = new AbortController();
        this._loadPromise = null;
        this._loadComplete = false;
        this._loadCompleteTime = null;

        this._getters = {};
    }

    getArgs() {
        return this._loadArgs;
    }

    isComplete() {
        return this._loadComplete;
    }

    isAborted() {
        return this._loadAbortController.signal.aborted;
    }

    getAge() {
        if (this._loadComplete) {
            return this._getTime() - this._loadCompleteTime;
        }
        else {
            return 0;
        }
    }

    get({ abortSignal }) {
        // If the getter has already aborted OR the load is already aborted,
        // do nothing.
        if (abortSignal.aborted || this._loadAbortController.signal.aborted) {
            return Promise.resolve();
        }

        const getter = {
            id: uniqueId(),
            abortSignal: abortSignal,
            resolve: null,
            reject: null,
            promise: null,
        };

        getter.promise = new Promise((resolve, reject) => {
            getter.resolve = resolve;
            getter.reject = reject;
        });

        this._getters[getter.id] = getter;

        /* eslint-disable no-use-before-define */
        const cleanupGetter = () => {
            // Delete the entry from active getters
            delete this._getters[getter.id];
            // Remove listeners
            getter.abortSignal.removeEventListener('abort', handleGetAbort);
        };
        /* eslint-enable no-use-before-define */

        const handleGetAbort = () => {
            cleanupGetter();
            // Immediately resolve the promise
            getter.resolve();
            // If this getter is aborted while the load is pending and there's
            // no getters left to get the response, abort the load itself.
            if (!this._loadComplete && Object.keys(this._getters).length === 0) {
                this._loadAbortController.abort();
            }
        };

        getter.abortSignal.addEventListener('abort', handleGetAbort);

        this._load()
            .then((response) => {
                cleanupGetter();
                if (!getter.abortSignal.aborted) {
                    getter.resolve(response);
                }
            })
            .catch((error) => {
                cleanupGetter();
                if (!getter.abortSignal.aborted) {
                    getter.reject(error);
                }
            });

        return getter.promise;
    }

    _load() {
        // Only ever create one load promise for this instance.
        if (!this._loadPromise) {
            const abortSignal = this._loadAbortController.signal;

            this._loadPromise =
                this._loadFunction(
                    { abortSignal: abortSignal },
                    ...this._loadArgs,
                )
                .then((response) => {
                    this._loadComplete = true;
                    this._loadCompleteTime = this._getTime();

                    if (abortSignal.aborted) {
                        return Promise.resolve();
                    }
                    else {
                        return Promise.resolve(response);
                    }
                })
                .catch((error) => {
                    this._loadComplete = true;
                    this._loadCompleteTime = this._getTime();

                    if (abortSignal.aborted) {
                        return Promise.resolve();
                    }
                    else {
                        return Promise.reject(error);
                    }
                });
        }

        return this._loadPromise;
    }
}

/**
 * A class for caching a single async value.
 */
export default class AsyncValueCache {
    constructor(opts = {}) {
        const {
            getTime = () => Date.now()
        } = opts;

        this._getTime = getTime;
        this._lastCompleted = null;
        this._pending = null;
    }

    /**
     * Get the value from the cache. If the value has not been loaded yet, the
     * provided load function will be called to get the value. If it has already
     * been loaded (or is pending) with the same args, then the cached value is
     * returned.
     *
     * @param {object} cacheOptions
     * @param {AbortSignal} [cacheOptions.abortSignal] An abort signal
     * @param {Number} [cacheOptions.maxAge = Infinity] The oldest a cached value
     *      can be before it will be reloaded.
     * @param {Function} cacheOptions.load A function that loads the value, if
     *      it is not cached.
     * @param {...any} args Args that are deep compared to determine if the
     *      value needs to be reloaded. They are also passed to the `load` function. 
     * @returns {Promise}
     */
    async get(cacheOptions, ...args) {
        let {
            abortSignal,
            maxAge = Infinity,
            load,
        } = cacheOptions;

        // Make an abort signal if one isn't provided.
        if (!abortSignal) {
            abortSignal = (new AbortController()).signal;
        }

        // Some bookeeping here:
        // 1. If there's a pending get that has since been aborted, clear it
        //      out; we don't want it anymore.
        // 2. If there's a pending get that has since completed, move it into
        // the completed slot.
        if (
            this._pending &&
            (this._pending.isAborted() || this._pending.isComplete())
        ) {
            // Only keep the latest result if it wasn't aborted.
            if (!this._pending.isAborted()) {
                this._lastCompleted = this._pending;
            }

            this._pending = null;
        }

        // If there is a last get entry, it's not too old, and its arguments
        // match, use it.
        if (
            this._lastCompleted &&
            this._lastCompleted.getAge() <= maxAge &&
            isEqual(args, this._lastCompleted.getArgs())
        ) {
            return this._lastCompleted.get({ abortSignal });
        }

        // If there's a pending get with matching arguments, use it.
        if (
            this._pending &&
            isEqual(args, this._pending.getArgs())
        ) {
            return this._pending.get({ abortSignal });
        }

        // Cache miss. Create a new entry and get from it.

        this._pending = new AsyncValueCacheEntry({
            args: args,
            load: load,
            getTime: this._getTime,
        });

        return this._pending.get({ abortSignal });
    }
}
