import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import FieldSelectControl from 'Common/components/LiveControls/FieldSelectControl';
import mergeMetricAndMetricSet, { filterCombinedMetricAndMetricSets } from './mergeMetricAndMetricSet';

const METRIC_SET = 'metric_set';

/**
 * Use this in a history stack style column control for when the user is editing/adding metrics and metric sets.
 *
 */
const MetricAndMetricSetOption = ({
    fieldOptions,
    selectedColumns,
    selectedColumnIndex,
    onChange
}) => {

    const initialCategoryColumn = Number.isFinite(selectedColumnIndex) ? selectedColumns[selectedColumnIndex] : null;
    const options = useMemo(() => {
        const unselectableFields = initialCategoryColumn
            ? selectedColumns.filter(col => col.name !== initialCategoryColumn.name)
            : selectedColumns;
        // on edit we only let you change it to another sinlge metric
        const metricSets = initialCategoryColumn ? [] : ( fieldOptions.metric_set || [] );
        const mergedOptions = mergeMetricAndMetricSet(fieldOptions.metric || [], metricSets);
        return filterCombinedMetricAndMetricSets(mergedOptions, unselectableFields);
    }, [fieldOptions, selectedColumns, initialCategoryColumn]);

    const handleOnChange = useCallback((e) => {
        const column = e.target.value;
        let newSelectedColumns = selectedColumns;
        if (column.fieldType === METRIC_SET){
            const newColumns = column.columns;
            newSelectedColumns = [ ...selectedColumns ];

            let selectedColumnsByName = selectedColumns.reduce((byName, col) => {
                byName[col.name] = col;
                return byName;
            }, []);

            // for every column in the new metric set, check if it already exists in the table.
            // If not, concat the new column onto the array

            newColumns.forEach(newColumn => {
                if (!selectedColumnsByName[newColumn.name]) {
                    newSelectedColumns.push(newColumn);
                }
            });
        }
        else if (!Number.isFinite(selectedColumnIndex)) {
            newSelectedColumns = selectedColumns.concat([column]);
        }
        else {
            if (selectedColumns.length < selectedColumnIndex) {
                console.warn(`Can not update column at index ${selectedColumnIndex} there are only ${selectedColumns.length} columns.`);
            }
            else {
                newSelectedColumns = [].concat(selectedColumns);
                newSelectedColumns.splice(selectedColumnIndex, 1, column);
            }
        }
        onChange({
            target: {
                value: newSelectedColumns,
            }
        });
    }, [selectedColumns, onChange, selectedColumnIndex]);

    return (
        <FieldSelectControl
            collapsibleGroups
            showFlattenCheckbox
            options={options}
            value={initialCategoryColumn}
            onChange={handleOnChange}
        />
    );
};

MetricAndMetricSetOption.propTypes = {
    fieldOptions: PropTypes.shape({
        metric: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                        fieldType: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ),
        metric_set: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                        fieldType: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ),
    }).isRequired,
    selectedColumnIndex: PropTypes.number,
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,

    onChange: PropTypes.func.isRequired,
};

export default MetricAndMetricSetOption;
