import { sendPutRequest } from 'Http/request';
import { putResource } from 'Common/util/ResourceCollection';

export function putHierarchy(newHierarchy) {
    return putResource({
        resourceType: 'hierarchy',
        requestId: 'putHierarchy',
        sendRequest: () => {
            return sendPutRequest('/api/v1/devices/hierarchy', {
                body: { ...newHierarchy },
            });
        },
    });
}

export default putHierarchy;
