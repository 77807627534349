import { useContext, useMemo } from 'react';
import { TranslationContext } from 'Common/providers/TranslationProvider';
import useMemoizedArgs from './useMemoizedArgs';

/**
 * Returns the translator function provided by TranslationContext.
 * 
 */
export function useTranslator() {
    return useContext(TranslationContext);
}

/**
 * 
 * @param {String} key The translation key to send to the translator
 * @param {Object} [substitutions] Any substitutions needed for a translation
 *     with dynamic components. For example, a key that translates to
 *     "First, pick a {category}" might have a substitution object like:
 *     { category: "Shift" }, in which case useTranslation would return
 *     "First, pick a Shift".
 */
 export default function useTranslation(key, substitutions) {
    const translator = useTranslator();

    const [memoKey, memoSubstitutions] = useMemoizedArgs([key, substitutions]);

    const translatedValue = useMemo(() => {
        return translator({
            key: memoKey,
            substitutions: memoSubstitutions,
        });
    }, [translator, memoKey, memoSubstitutions]);

    return translatedValue;
}
