import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FormElement from 'Components/form-element/FormElement';
import FormElementLabel from 'Components/form-element/FormElementLabel';
import FormElementHelperText from 'Components/form-element/FormElementHelperText';
import TimezoneInput from 'Components/timezone/TimezoneInput';

const StyledTimezoneInput = styled(TimezoneInput)`
    display: block;
    min-width: 200px;
`;

export default function TimezoneSelect(props)
{
    const {
        id,
        label,
        name,
        value,
        error,
        helperText,
        onFocus,
        onBlur,
        onChange,
        ...otherProps
    } = props;

    return (
        <FormElement error={error} {...otherProps}>
            <FormElementLabel htmlFor={id}>{label}</FormElementLabel>
            <StyledTimezoneInput
                name={name}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                maxOptionsHeight={400}
            />
            <FormElementHelperText>
                {helperText}
            </FormElementHelperText>
        </FormElement>
    );
}

TimezoneSelect.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.node,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};
