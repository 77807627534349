import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LiveControlSeparator from '../LiveControlSeparator';
import LiveControlDropdownHeader from '../LiveControlDropdownHeader';
import LiveControlDropdownLayout from '../LiveControlDropdownLayout';
import { childrenAreOneOf } from 'Common/util/propTypeHelpers';
import Icon from 'Common/components/Icon';
import { Body5 } from 'Common/components/typography';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    /* We rely on a flex parent to size ourselves correctly */
    flex: 1 1 auto;
    /* flex-shrink doesn't work correctly without a
       min-height to use as a basis
    */
    min-height: 1px;
`;

const MenuSelection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const MenuMoreIndicator = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
    font-size: 20px;
`;

const MenuSeparator = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
`;

const MenuItemLabel = styled(Body5).attrs({ as: 'div' })`
    display: inline-block;
    white-space: nowrap;
    text-align: left;
    color: ${({theme, disabled}) => disabled
        ? 'inherit'
        : theme.colors.darkText.mediumEmphasis
    };
    flex: 1 0 auto;
    padding-right: ${({hasControl}) => hasControl ? '0' : '12px'};
`;

export default function LiveControlOverflowMenu({
    onSelectionChange,
    children,
}) {
    children = React.Children.toArray(children);

    const openChild = children.find((child) => {
        return child.props.selected;
    });

    let body = null;
    const goBack = () => { onSelectionChange('overflowMenu'); };

    if (openChild?.props.controlRender) {
        if (!openChild.props.handleContentLayout) {
            body = (
                <LiveControlDropdownLayout
                    header={(<LiveControlDropdownHeader displayName={openChild.props.displayName} icon={openChild.props.icon} goBack={goBack}/>)}
                    body={openChild.props.controlRender()}
                />
            );
        }
        else {
            body = openChild.props.controlRender({ headerGoBack: goBack});
        }
    }
    else {
        body = children.map((child, index) => {
            if (child.props.icon) {
                const {
                    icon: childIcon,
                    controlRender: childControlRender,
                    displayName: childDisplayName,
                    disabled: childDisabled,
                 } = child.props;

                const childContent = (
                    <React.Fragment>
                        {childIcon}
                        <MenuItemLabel
                            hasControl={!!childControlRender}
                            disabled={childDisabled}
                        >
                            {childDisplayName}
                        </MenuItemLabel>
                        {childControlRender && (
                            <MenuMoreIndicator disabled={childDisabled}>
                                <Icon type="fontAwesome" iconName="angle-right" />
                            </MenuMoreIndicator>
                        )}
                    </React.Fragment>
                );

                child = React.cloneElement(child, {
                    contentRender: () => childContent,
                });

                return (
                    <MenuSelection key={index}>
                        {child}
                    </MenuSelection>
                );
            }
            else {
                // For now, assume fields without icons are separators
                return (
                    <MenuSeparator key={index} />
                );
            }
        });
    }

    return (
        <Root>
            {body}
        </Root>
    );
}

LiveControlOverflowMenu.propTypes = {
    children: childrenAreOneOf({
        elementTypes: [
            LiveControlSeparator,
        ],
        propShapes: [
            // dropdown:
            [
                'name',
                'displayName',
                'icon',
                'controlRender',
            ],
            // simple selection:
            [
                'name',
                'displayName',
                'icon',
            ],
        ],
    }),
    onSelectionChange: PropTypes.func.isRequired,
};
