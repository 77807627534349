import { sendGetRequest } from 'Http/request';
import { getResources } from 'Common/util/ResourceCollection';

export function getDeviceSubscriptions() {
    return getResources({
        resourceType: 'deviceSubscriptions',
        requestId: 'getDeviceSubscriptions',
        sendRequest: async () => {
            return sendGetRequest('/api/v1/accounts/user/device-subscriptions');
        },
    });
}

export default getDeviceSubscriptions;
