import isNumber from 'lodash/isNumber';

function log(val, base) {
    return Math.log(val) / Math.log(base);
}

export function magnitude(val) {
    var rv = Math.abs(log(Math.abs(val), 10));
    rv = val < 1 ? -Math.ceil(rv) : Math.floor(rv);
    return val === 0 ? 0 : rv;
}

export function constrain(number, a, b) {
    var min = Math.min(a, b);
    var max = Math.max(a, b);

    number = number < min ? min : number;
    number = number > max ? max : number;

    return number;
}

export function trunc(n, precision) {
    var rv,
        factor,
        scaledN,
        truncN;

    if (precision) {
        factor = Math.pow(10, precision);
        scaledN = n * factor;
        truncN = trunc(scaledN);
        rv = truncN / factor;
    }
    else {
        rv = n < 0 ? Math.ceil(n) : Math.floor(n);
    }

    return rv;
}

/**
 * Get the fractional part of `n` without floating-point error.
 *
 * @param  {Number} n The number to get
 * @return {Number} The portion of `n` after the decimal; 0 if `n` is integral.
 */
export function getFractionalPiece(n) {
    var str = n.toString(),
        point = str.indexOf('.'),
        fract = 0;

    if (point !== -1) {
        fract = parseFloat('0' + str.slice(point));
    }

    return fract;
}

/**
 * Returns an object that contains the slope and intercept of the linear regression of the
 * data. The calculation is based on the formula:
 *
 *     y = m*x + b
 *     m = (n*sum(xy) - sum(x)*sum(y))/(n*sum(x**2) - (sum(x))**2)
 *     b = (sum(y) - m*sum(x))/n
 *
 * Values not included in the data are not included in the calculation.
 *
 * @param {Number[][]} data An array of X,Y pairs.
 * @param {Function} getXYFunction function taking (value, index) as parameters, and returning
 *   {x: ..., y: ...} to be used in calculating the trend line.
 * @return {Object} Trend line definition
 * @return {Number} return.slope The slope of the trend line
 * @return {Number} return.intercept The Y at first data point. If 2-dimensional line is used,
 *   the intercept is not Y intercept, but intercept at X value of first point.
 * @private
 */
function trend2D(data, getXYFunction) {
    var rv = null;

    var getXY = getXYFunction || function(value, index) {
        return {
            x: index,
            y: value
        };
    };

    // At least two points are needed for linear regression analysis.
    if (data.length >= 2) {
        var sum_of_x = 0;
        var sum_of_y = 0;
        var sum_of_xy = 0;
        var sum_of_x2 = 0;

        var firstX = getXY(data[0][1], data[0][0]).x;

        data.forEach(function(value, index) {
            var xy = getXY(value[1], value[0]),
                x = xy.x - firstX,
                y = xy.y;

            sum_of_x += x;
            sum_of_y += y;
            sum_of_xy += x * y;
            sum_of_x2 += x * x;
        }, this);

        // Get the slope of the linear regression.
        var slope_divisor = data.length * sum_of_x2 - sum_of_x * sum_of_x;
        // Check the value of slope divisor to prevent "divide by zero" error.
        if (slope_divisor !== 0) {
            var slope_dividend = data.length * sum_of_xy - sum_of_x * sum_of_y;
            // Get the value of slope
            var m = slope_dividend / slope_divisor;

            // Continue the calculation to get the intercept.
            var b = (sum_of_y - m * sum_of_x) / data.length;

            rv = {
                slope: m,
                intercept: b
            };
        }
    }

    return rv;
}

/**
 * Returns an object that contains the slope and intercept of the linear regression of the
 * data. The calculation is based on the formula:
 *
 *     y = m*x + b
 *     m = (n*sum(xy) - sum(x)*sum(y))/(n*sum(x**2) - (sum(x))**2)
 *     b = (sum(y) - m*sum(x))/n
 *
 * Values not included in the data are not included in the calculation.
 *
 * @param {Number[]/Number[][]} data An array of numbers representing Y values for a set
 *    of continuous X values or an array of X,Y pairs.
 * @param {Function} getXYFunction function taking (value, index) as parameters, and returning
 *    {x: ..., y: ...} to be used in calculating the trend line.
 * @return {Object} Trend line definition
 * @return {Number} return.slope The slope of the trend line
 * @return {Number} return.intercept The Y at first data point. If 2-dimensional line is used,
 *    the intercept is not Y intercept, but intercept at X value of first point.
 */
export function trend(data, getXYFunction) {
    var rv = null;

    if (isNumber(data[0])) {
        var data2DArray = [];

        var index2D = 0;

        //Build 2-Dimensional Array from data
        data.forEach(function(value, index) {
            if (value || value === 0) {
                data2DArray[index2D++] = [index, value];
            }
        }, this);

        //Get trend based on 2-Dimensional Array
        rv = trend2D(data2DArray, getXYFunction);
    }
    else {
        rv = trend2D(data, getXYFunction);
    }

    return rv;
}

export function numericContains(value, low, high) {
    value = parseFloat(value);
    low = parseFloat(low);
    high = parseFloat(high);

    var isNumeric = !isNaN(value) && !isNaN(low) && !isNaN(high);

    return isNumeric && value >= low && value <= high;
}

export function trendPercent(data, getXYFunction) {
    // need at least 2 points of data to compute a trend
    if (data.length < 2) {
        return null;
    }

    // The data we're given here has been known to be out of order.
    // Make sure all the pairs are sorted chronologically.
    var sorted_data = data.sort(function(a, b) { return a[0] - b[0]; });

    var baseTrend = trend(sorted_data, getXYFunction),
        first,
        last,
        percentChange = 0,
        delta_x;

    if (baseTrend) {
        delta_x = sorted_data[sorted_data.length - 1][0] - sorted_data[0][0];
        first = baseTrend.intercept;

        last = (baseTrend.slope * delta_x) + baseTrend.intercept;
        if (baseTrend.slope === 0) {
            percentChange = 0;
        }
        else if (first !== 0) {
            percentChange = (last - first) / Math.abs(first);
        }
        else {
            percentChange = Number.MAX_VALUE;
        }
    }

    return percentChange;
}

export function getPlacesForNumericRange(range, defaultPlaces) {
    var places = defaultPlaces;
    if (range && (range.start || range.start === 0) && (range.end || range.end === 0)) {
        var diff = range.end - range.start,
            mag = magnitude(diff / 2);

        if (mag < 0) {
            places = Math.abs(mag) - 2;
        }
    }
    return places < 0 ? 0 : places;
}
