/**
 * All of this code was copied from xl-enterprise
 */

import setWith from 'lodash/setWith';
import clone from 'lodash/clone';
import get from 'lodash/get';
import unset from 'lodash/unset';
import toPath from 'lodash/toPath';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

function isValidArrayIndex(index) {
    return Number.isInteger(index) && index >= 0;
}

/**
 * Create an object property path from a variable number of components.
 * Falsey arguments are not allowed, with the exception of the first argument.
 * 
 * Example: Given 'a', 'b', 5, 'x', the resulting path would be 'a.b[5].x'.
 * 
 * @param  {...any} args Components of the path
 * @returns {String} path
 */
export function createPath(...args) {
    return args.reduce((path, keyOrIndex, i) => {
        // Falsey arguments are not allowed, with the exception of the first
        // argument.
        if (!keyOrIndex && keyOrIndex !== 0) {
            if (i === 0) {
                return path;
            }
            else {
                throw new Error(`Invalid path key or index \`${keyOrIndex}\` in path "${path}"`);
            }
        }

        if (isValidArrayIndex(keyOrIndex)) {
            // Array index: e.g. "thing[5]"
            return `${path}[${keyOrIndex}]`;
        }
        else {
            // Object key: e.g. "thing.xyz"
            if (path === '') {
                // First key in path
                return `${keyOrIndex}`;
            }
            else {
                return `${path}.${keyOrIndex}`;
            }
        }
    }, '');
}

/**
 * Returns the value from an object at the specified location. Useful for deep
 * access to values by a single path string.
 * 
 * If the path is empty, the object itself is returned.
 * If the path does not exist in the object, `undefined` is returned.
 * 
 * @param {Object|Array} obj The source object
 * @param {String} path The path to the value
 * @returns {any}
 */
export function getAtPath(obj, path) {
    if (isEmpty(path)) {
        return obj;
    }

    return get(obj, path);
}

/**
 * Sets the value in an object at the specified location. Useful for deep
 * modifications to objects by a single path string.
 * 
 * The source object is not modified and a new object is returned.
 * 
 * If the path is empty, the value itself is returned.
 * If the path does not exist in the object, new objects will be created along
 * the path as needed.
 * 
 * @param {Object|Array} obj The source object
 * @param {String} path The path to the value
 * @param {any} value The new value to be set
 * @returns {any} New object with modifications
 */
export function setAtPath(obj, path, value) {
    if (isEmpty(path)) {
        return value;
    }

    return setWith(clone(obj), path, value, clone);
}

/**
 * Updates the value in an object at the specified location. Useful for deep
 * modifications to objects by a single path string.
 * 
 * Unlike `setAtPath`, this accepts a function that can be used to update the
 * value, so you can use the existing value to construct the new one.
 * 
 * The source object is not modified and a new object is returned.
 * 
 * If the path is empty, the source object itself is updated.
 * If the path does not exist in the object, new objects will be created along
 * the path as needed.
 * 
 * @param {Object|Array} obj The source object
 * @param {String} path The path to the value
 * @param {Function} updater Function that is given the existing value and returns
 *      the new value
 * @returns {any} New object with modifications
 */
export function updateAtPath(obj, path, updater) {
    const currentValue = getAtPath(obj, path);
    return setAtPath(obj, path, updater(currentValue));
}

/**
 * Deletes the value in an object at the specified location. Useful for deep
 * deletion to objects by a single path string.
 * 
 * The source object is not modified and a new object is returned.
 * 
 * If the path is empty, an empty object is returned.
 * If the item deleted is an index into an array, the item will be `spliced` out
 * of the original array.
 * 
 * @param {Object|Array} obj The source object
 * @param {String} path The path to the value
 * @returns {any} New object with modifications
 */
export function deleteAtPath(obj, path) {
    if (isEmpty(path)) {
        return {};
    }

    // If the value doesn't exsit, return the original object
    if (!has(obj, path)) {
        return obj;
    }

    // Get the current value
    const currentValue = getAtPath(obj, path);
    // To keep the object immutable, set the path with the existing value, which
    // clones the object along the path
    const clonedObj = setAtPath(obj, path, currentValue);

    // Splits the path into an array of keys/indices
    const splitPath = toPath(path);
    const parentPath = splitPath.slice(0, splitPath.length - 1);
    const childKeyOrIndex = splitPath[splitPath.length - 1];
    // Get the parent value
    const parentValue = getAtPath(clonedObj, parentPath);

    // Remove the parent value
    if (isArray(parentValue)) {
        // Removing from an array, so splice the value out
        parentValue.splice(Number(childKeyOrIndex), 1);
    }
    else {
        // Removing from an object
        unset(clonedObj, path);
    }

    return clonedObj;
}
