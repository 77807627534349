import PropTypes from 'prop-types';

export const BLANK_ROW_TYPE = 'separator';
export const HEADING_TYPE = 'heading';
export const FIELD_TYPE = 'field';
export const CATEGORY_VALUE = 'category_value';

export const KPI_GROUP_COLUMNS_PROPTYPE = PropTypes.arrayOf(
    PropTypes.oneOfType([
        PropTypes.shape({ // separator or heading
            type: PropTypes.oneOf([BLANK_ROW_TYPE, HEADING_TYPE]),
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
        }),
        PropTypes.shape({ // actual field
            type: PropTypes.oneOf([FIELD_TYPE]),
            name: PropTypes.string.isRequired,
            channel: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            fieldType: PropTypes.string.isRequired,
        }),
        PropTypes.shape({ // spark dimension
            name: PropTypes.string.isRequired,
            type: PropTypes.oneOf([FIELD_TYPE]),
            displayName: PropTypes.string.isRequired,
            fieldType: PropTypes.oneOf(['category_value']).isRequired,
            dimensionVisualization: PropTypes.shape({
                visualization: PropTypes.string.isRequired,
                metric: PropTypes.string.isRequired,
                orderBy: PropTypes.string.isRequired,
                maxElementsShown: PropTypes.number,
            }),
        }),
    ]),
);

