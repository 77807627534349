import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentStandard';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import RoleSelect from 'Components/role-select/RoleSelect';
import TextInput from 'Components/text-input/TextInput';
import { sendPostRequest } from 'Http/request';
import PageDescription from 'Components/page/PageDescription';
import Form from 'Components/form/Form';
import { FormController } from '../../form/FormController';
import { combineValidators } from 'Components/util/validationUtils';
import { getAtPath } from 'Common/util/FormUtils';
import EnterpriseHierarchySelect from 'Components/enterprise-hierarchy-select/EnterpriseHierarchySelect';
import { bindActionCreators } from 'redux';
import { selectHierarchyRequest, selectFlattenedHierarchy } from 'Redux/reducers/hierarchy';
import { getHierarchy } from 'Redux/actions';
import { connect } from 'react-redux';
import RequestLoadingWrapper from 'Components/loading-indicator/RequestLoadingWrapper';
import { Roles } from 'Components/app/roles';

const StyledTextInput = styled(TextInput)`
    display: block;
    width: 100%;
`;

const validate = combineValidators({
    name: (name) => name.trim().length === 0 ? 'Required' : null,
    email: (email) => email.trim().length === 0 ? 'Required' : null,
    role: (role) => role === '' ? 'Required' : null,
    hierarchy_visibility: (hierarchy_visibility) => hierarchy_visibility === null ? 'Required' : null,
});

export class AddUserPagePresentation extends React.Component {
    static propTypes = {
        requests: PropTypes.shape({
            getHierarchy: PropTypes.object.isRequired,
        }),
        actions: PropTypes.shape({
            getHierarchy: PropTypes.func.isRequired,
        }),
        flattenedHierarchy: PropTypes.object,
    };

    constructor() {
        super();

        this.state = {
            isComplete: false,
        };
    }

    componentDidMount() {
        this.props.actions.getHierarchy();
    }

    _transformValue = (value) => {
        const { flattenedHierarchy } = this.props;

        // If the role is set to ADMIN, force the visibility to be the root
        if (
            value.role === Roles.ADMIN &&
            value.hierarchy_visibility !== flattenedHierarchy.rootId
        ) {
            return {
                ...value,
                hierarchy_visibility: flattenedHierarchy.rootId,
            };
        }
        else {
            return value;
        }
    };

    _handleSubmit = async (value, formController) => {
        const {
            setTouched,
            setSubmitError,
        } = formController;

        // Set everything as touched to expose all validation errors
        setTouched({
            name: true,
            email: true,
            role: true,
            hierarchy_visibility: true,
        });

        const validation = validate(value);

        if (validation) {
            setSubmitError({ message: 'Some fields are invalid.' });
            return;
        }

        try {
            const response = await sendPostRequest('/api/v1/accounts/organization/users', {
                body: {
                    email: value.email,
                    name: value.name,
                    role: value.role,
                    hierarchy_visibility: value.hierarchy_visibility === null ?
                        null : Number(value.hierarchy_visibility),
                },
            });

            if (response.ok) {
                this.setState({ isComplete: true });
            }
            else {
                setSubmitError(response.data || { message: 'Network error' });
            }
        }
        catch (err) {
            setSubmitError(err || { message: 'Network error' });
        }
    };

    render() {
        const { requests, flattenedHierarchy } = this.props;
        const { isComplete } = this.state;

        return (
            <Page>
                <ConfigPageTitle>Add User</ConfigPageTitle>
                <PageDescription>
                    When you add a user they will receive an invitation email with a link to set
                    their password. Admins manage the organization, other users, and alerts.
                </PageDescription>
                <PageContent>
                    <RequestLoadingWrapper
                        request={requests.getHierarchy}
                        render={() => (
                            <FormController
                                initialValue={{
                                    name: '',
                                    email: '',
                                    role: '',
                                    hierarchy_visibility: null,
                                }}
                                initialTouched={{
                                    name: false,
                                    email: false,
                                    role: false,
                                    hierarchy_visibility: false,
                                }}
                                validate={(value) => {
                                    return validate(value);
                                }}
                                transformValue={this._transformValue}
                                onSubmit={this._handleSubmit}
                                render={(innerProps) => {
                                    const {
                                        value,
                                        touched,
                                        validation,
                                        isSubmitting,
                                        submitError,

                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = innerProps;

                                    const inputError = (inputName) => {
                                        return getAtPath(touched, inputName) && getAtPath(validation, inputName);
                                    };

                                    const inputProps = (inputName, defaults = {}) => {
                                        return {
                                            name: inputName,
                                            value: getAtPath(value, inputName),
                                            error: !!inputError(inputName),
                                            helperText: inputError(inputName) || defaults.helperText || '',
                                            onChange: handleChange,
                                            onBlur: handleBlur,
                                            disabled: isSubmitting,
                                        };
                                    };

                                    return (
                                        <Form
                                            onSubmit={handleSubmit}
                                            allowSubmit={!isSubmitting}
                                            showCancel={false}
                                            error={submitError}
                                        >
                                            <StyledTextInput
                                                label="Name"
                                                placeholder="First Last"
                                                {...inputProps('name')}
                                            />
                                            <StyledTextInput
                                                label="Email"
                                                type="email"
                                                placeholder="email@example.com"
                                                {...inputProps('email', {
                                                    helperText: 'For log in and notifications',
                                                })}
                                            />
                                            <RoleSelect
                                                label="Role"
                                                {...inputProps('role')}
                                            />
                                            <EnterpriseHierarchySelect
                                                label="Hierarchy Visibility"
                                                enterpriseHierarchy={flattenedHierarchy}
                                                {...inputProps('hierarchy_visibility')}
                                                disabled={isSubmitting || value.role === Roles.ADMIN}
                                            />
                                            {!isSubmitting && isComplete && (
                                                <Redirect to={{ pathname: '/config/organization/users' }} />
                                            )}
                                        </Form>
                                    );
                                }}
                            />
                        )}
                    />
                </PageContent>
            </Page>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { hierarchy } = state;

    return {
        requests: {
            getHierarchy: selectHierarchyRequest(hierarchy, 'getHierarchy'),
        },
        flattenedHierarchy: selectFlattenedHierarchy(hierarchy),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getHierarchy: getHierarchy,
        }, dispatch),
    };
};

// connect the component to Redux
const AddUserPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUserPagePresentation);

export default AddUserPage;
