
import React, {
    useEffect,
} from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { selectFlattenedHierarchy } from 'Redux/reducers/hierarchy';
import { selectSessionUser } from 'Redux/reducers/session';
import {
    generateEmailSubscriptionsPatch,
    getEmailSubscriptions,
    getEmailTemplates,
    getHierarchy,
    getSessionUser,
    patchEmailSubscriptions,
    patchSessionUser,
} from 'Redux/actions';
import { getEmailTemplatesList } from 'Redux/reducers/emailTemplates';
import { getEmailSubscriptionsList } from 'Redux/reducers/emailSubscriptions';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentWide';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import EmailsSubscriptionForm from './EmailsSubscriptionForm';
import RequestLoadingWrapper from 'Components/loading-indicator/RequestLoadingWrapper';
import PageDescription from 'Components/page/PageDescription';
import PageFrame from 'Components/page/PageFrame';
import InfoCircle from 'Common/components/InfoCircle';
import DayOfWeekForm from './DayOfWeekForm';

function EmailsSubscribePage(props) {

    const {
        actions,
        emailSubscriptions,
        emailTemplates,
        flattenedHierarchy,
        requests,
        user,
    } = props;

    useEffect(() => {
        const load = async () => {
            await Promise.all([
                actions.getEmailTemplates(),
                actions.getEmailSubscriptions(),
                actions.getHierarchy(),
                actions.getSessionUser(),
            ]);
        };

        load();
    }, [actions]);

    const handleEmailSubscriptionPatch = (evt) => {
        const patchData = generateEmailSubscriptionsPatch(
            emailSubscriptions,
            evt,
        );
        actions.patchEmailSubscriptions(patchData);
    };

    const handleDOWPatch = (dow) => {
        actions.patchSessionUser({
            first_day_of_week: dow
        });
    };

    return (
        <Page>
            <ConfigPageTitle>Email Report Subscriptions</ConfigPageTitle>
            <PageDescription>
                Use this page to add, configure, and delete email report subscriptions.
                <span style={{ display: 'inline-block' }}>
                    <InfoCircle>
                        <p>
                            Subscribe to email reports to have them automatically sent to you.
                            Reports are created by administrators on the Email Report Templates page.
                        </p>
                        <p>
                            For each subscription you can configure which report you would like to receive,
                            which work center(s) you would like included, and the reporting period.
                        </p>
                        <p>
                            Reports are emailed as soon as all XL devices communicating to XL Enterprise
                            have completed the last shift for the reporting period. Keep in mind that
                            including work centers with different shift schedules or across multiple
                            time zones can significantly delay sending reports.
                        </p>
                    </InfoCircle>
                </span>
            </PageDescription>
            <PageContent>
                <PageFrame title="Email Report Subscriptions">
                    <RequestLoadingWrapper
                        request={requests.getEmailSubscriptions}
                        render={() => (
                            <EmailsSubscriptionForm
                                emailSubscriptions={emailSubscriptions}
                                emailTemplates={emailTemplates}
                                flattenedHierarchy={flattenedHierarchy}
                                requests={requests}
                                onSubmit={handleEmailSubscriptionPatch}
                            />
                        )}
                    />
                </PageFrame>
                <PageFrame title="Start of Production Week for Email Reports">
                    <RequestLoadingWrapper
                        request={requests.getSessionUser}
                        render={() => (
                            <DayOfWeekForm
                                user={user}
                                requests={requests}
                                onSubmit={handleDOWPatch}
                            />
                        )}
                    />
                </PageFrame>
            </PageContent>
        </Page>
    );
}

EmailsSubscribePage.propTypes = {
    actions: PropTypes.shape({
        getEmailSubscriptions: PropTypes.func.isRequired,
        getEmailTemplates: PropTypes.func.isRequired,
        getHierarchy: PropTypes.func.isRequired,
        getSessionUser: PropTypes.func.isRequired,
        patchEmailSubscriptions: PropTypes.func.isRequired,
        patchSessionUser: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired,
    emailSubscriptions: PropTypes.array.isRequired,
    emailTemplates: PropTypes.array.isRequired,
    flattenedHierarchy: PropTypes.object,
    requests: PropTypes.shape({
        getEmailSubscriptions: PropTypes.object.isRequired,
        patchEmailSubscriptions: PropTypes.object.isRequired,
        getSessionUser: PropTypes.object.isRequired,
    }),
};

const mapStateToProps = (state, ownProps) => {
    const {
        emailTemplates,
        emailSubscriptions,
        hierarchy,
        session,
    } = state;

    const user = selectSessionUser(session);
    return {
        emailSubscriptions: emailSubscriptions ? getEmailSubscriptionsList(emailSubscriptions) : [],
        emailTemplates: emailTemplates ? getEmailTemplatesList(emailTemplates) : [],
        flattenedHierarchy: selectFlattenedHierarchy(hierarchy),
        requests: {
            ...emailSubscriptions.requests,
            ...session.requests,
        },
        user: user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getEmailSubscriptions,
            getEmailTemplates,
            getHierarchy,
            getSessionUser,
            patchEmailSubscriptions,
            patchSessionUser,
        }, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailsSubscribePage);
