import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    display: block;
    flex: 1 1 auto;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 1px; /* Allows text to elide */
`;

const TextLine = styled.div`
    position: relative;
`;

function ListItemText(props) {
    const {
        children: childrenProp,
        ...otherProps
    } = props;

    const children = React.Children.toArray(childrenProp);

    return (
        <Root {...otherProps}>
            {children.map((child, i) => {
                return (
                    <TextLine key={`line-${i}`}>{child}</TextLine>
                );
            })}
        </Root>
    );
}

ListItemText.propTypes = {
    children: PropTypes.node,
};

export default ListItemText;
