
import { sendPostRequest } from 'Http/request';
import {
    postResource,
} from 'Common/util/ResourceCollection';

export function postEmailTemplate(post, onSuccess) {
    return postResource({
        requestId: 'postEmailTemplate',
        resourceType: 'emailTemplates',
        sendRequest: () => {
            const promise = sendPostRequest('/api/v1/reports', { body: post } );
            promise.then((response) => {
                if (onSuccess) {
                    onSuccess(response.data);
                }
            });
            return promise;
        },
    });
}

export default postEmailTemplate;
