import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Primary5 } from 'Common/components/typography';
import InfoCircle from 'Common/components/InfoCircle';
import Icon from 'Common/components/Icon';

const Root = styled.div`
    box-sizing: border-box;
    padding: 5px 11px;
    /* Assuming chip size is 22px, pad enough for 44px touch target
       Give mobile users a nice big touch target
    */
    @media (hover: none) and (pointer: coarse) {
        padding: 11px;
    }
`;

const RootLayout = styled.div`
    display: inline-flex;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    height: 22px;
    background-color: ${props => props.theme.colors.palette.grey.cloud};
    color: ${props => props.theme.colors.darkText.highEmphasis};
    border-radius: 15px;
`;

const LeftContentContainer = styled.div`
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border: 1px solid white;
    border-radius: 11px;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    background-color: ${props => props.theme.colors.palette.grey.cloud};
`;

const CenterContentContainer = styled(Primary5).attrs({ as: 'div' })`
    flex: 0 1 auto;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0 0 5px;
    min-width: 0; /* Allows text to elide */
    height: 100%;
`;

const DeleteButtonContainer = styled.button`
    flex: 0 0 auto;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 11px;
    cursor: pointer;
    background-color: transparent;

    /* Touch Target */
    :before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        /* Give mobile users a nice big touch target */
        @media (hover: none) and (pointer: coarse) {
            min-width: 44px;
            min-height: 44px;
        }
        border-radius: 50%;

        background-color: transparent;
        transition: background-color 300ms;
    }

    :hover {
        :before {
            background-color: rgba(0,0,0,0.05);
        }
    }

    :active {
        :before {
            background-color: rgba(0,0,0,0.1);
        }
    }

    :not(:hover):focus {
        :before {
            background-color: rgba(0,0,0,0.05);
        }
    }
`;

const DeleteButtonIcon = styled(Icon).attrs({
    type: 'vorne',
    iconName: 'chip-delete',
})`
    font-size: 14px;
    color: ${props => props.theme.colors.palette.grey.ash};
    line-height: 16px;
    width: 16px;
    height: 16px;
`;

export default function Chip({
    name,
    leftContent,
    children,
    canDelete,
    onDeleteClick,
    noDeleteMessage,
    ...otherProps
}) {
    const handleDeleteClick = useCallback(() => {
        onDeleteClick({
            target: { name: name },
        });
    }, [ onDeleteClick, name ]);

    let rightContent = null;

    if (canDelete) {
        rightContent = (
            <DeleteButtonContainer onClick={handleDeleteClick} aria-label="Delete">
                <DeleteButtonIcon />
            </DeleteButtonContainer>
        );
    }
    else if (noDeleteMessage) {
        rightContent = (
            <InfoCircle>
                {noDeleteMessage}
            </InfoCircle>
        );
    }

    return (
        <Root
            {...otherProps}
        >
            <RootLayout>
                {leftContent && <LeftContentContainer>
                    {leftContent}
                </LeftContentContainer>}
                <CenterContentContainer>
                    {children}
                </CenterContentContainer>
                {rightContent}
            </RootLayout>
        </Root>
    );
}

Chip.propTypes = {
    name: PropTypes.string,
    leftContent: PropTypes.node,
    children: PropTypes.any.isRequired,
    canDelete: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    noDeleteMessage: PropTypes.string,
};

Chip.defaultProps = {
    noDeleteMessage: '',
    canDelete: true,
    onDeleteClick: () => { },
};
