import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LoginFormContainer from 'Components/login-form/LoginFormContainer.js';
import { FontWeight } from 'Components/typography/typography';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentFull';
import heroUrl from './xl-enterprise-hero.svg';
import ContainedButton from 'Components/button/ContainedButton';

const StyledPage = styled(Page)`
    max-width: 100%;
    padding: 0;
`;

const DumboTron = styled.div`
    width: 100%;
    background-image: url(${heroUrl});
    background-position: center;
    background-size: cover;
    min-height: 600px;
    display: flex;
    align-items: center;
`;

const FlexCenter = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${'' /* [FUTUREHACK] Force a height for IE11 to properly center flex stuff */}
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 600px;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 900px) {
        height: 700px;
    }
`;

const WelcomeBox = styled.div`
    color: white;
    vertical-align: top;
    box-sizing: border-box;
    min-width: 280px;
    max-width: 460px;
    margin: 0 35px;
`;

const LoginBox = styled.div`
    background-color: #e5e5e5;
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 280px;
    max-width: 460px;
    margin: 0 35px;
    margin-bottom: 30px;
`;

const NewUserBox = styled.div`
    text-align: center;
    padding: 30px;
`;

const HomeText = styled.span`
    font-family: Arial, sans-serif;
    font-size: 24px;
    font-weight: ${FontWeight.light};
    margin-bottom: 30px;
`;

const WelcomeText = styled(HomeText)`
    color: #e5e5e5;
`;

const NewUserText = styled(HomeText)`
    color: #505050;
`;

const PrimaryHeading = styled.span`
    font-family: Arial, sans-serif;
    font-size: 50px;
    font-weight: ${FontWeight.medium};
    margin: 20px 0;
`;

const SecondaryHeading = styled.span`
    font-family: Arial, sans-serif;
    font-size: 36px;
    font-weight: ${FontWeight.medium};
`;

const CreateAccountLink = styled(ContainedButton)`
    text-decoration: none;
`;

function HomePage(props) {
    // we may have been redirected here from a private page
    // don't lose that state, persist so after account creation we can go back
    let createAccountLinkTo = {
        pathname: '/create-organization'
    };

    if (props?.location?.state) {
        createAccountLinkTo.state = props.location.state;
    }

    return (
        <StyledPage>
            <PageContent>
                <DumboTron>
                    <FlexCenter>
                        <WelcomeBox>
                            <PrimaryHeading as="h1">Welcome To XL&nbsp;Enterprise</PrimaryHeading>
                            <WelcomeText as="div">
                                XL&nbsp;Enterprise is your portal to cloud&#8209;based services for the XL Productivity
                                Appliance&trade;. It monitors key manufacturing productivity metrics across your
                                enterprise and sends automated email alerts when any manufacturing process needs
                                your attention.
                            </WelcomeText>
                        </WelcomeBox>
                        <LoginBox>
                            <LoginFormContainer />
                        </LoginBox>
                    </FlexCenter>
                </DumboTron>
                <NewUserBox>
                    <SecondaryHeading as="h1">New to XL Enterprise?</SecondaryHeading>
                    <NewUserText as="div">Create an organization to get started.</NewUserText>
                    <CreateAccountLink
                        Component={Link}
                        to={createAccountLinkTo}
                        color="primary"
                    >
                        Create Organization
                    </CreateAccountLink>
                </NewUserBox>
            </PageContent>
        </StyledPage>
    );
}

HomePage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.object,
    }),
};

export default HomePage;
