import React from 'react';
import PropTypes from 'prop-types';

function SelectGroup(props) {
    const { label, children } = props;

    return (
        <React.Fragment>
            {label}
            {children}
        </React.Fragment>
    );
}

SelectGroup.propTypes = {
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};

export default SelectGroup;
