import React from 'react';
import PropTypes from 'prop-types';
import FormElementContext from './FormElementContext';

export default class FormElement extends React.Component {
    constructor() {
        super();
        this.state = {
            focused: false,
        };
        this._handleFocus = this._handleFocus.bind(this);
        this._handleBlur = this._handleBlur.bind(this);
    }

    _handleFocus() {
        this.setState({ focused: true });
    }

    _handleBlur() {
        this.setState({ focused: false });
    }

    render() {
        const {
            Component,
            disabled,
            required,
            error,
            ...otherProps
        } = this.props;

        const contextValue = {
            ...this.state,
            disabled,
            required,
            error,
            onFocus: this._handleFocus,
            onBlur: this._handleBlur,
        };

        return (
            <FormElementContext.Provider value={contextValue}>
                <Component {...otherProps} />
            </FormElementContext.Provider>
        );
    }
}

FormElement.defaultProps = {
    Component: 'div',
    disabled: false,
    required: false,
    error: false,
};

FormElement.propTypes = {
    Component: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
};
