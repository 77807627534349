import { sendGetRequest } from 'Http/request';
import { getResources, resetResourcesRequest } from 'Common/util/ResourceCollection';

export function getSupportUsers() {
    return getResources({
        resourceType: 'supportUsers',
        requestId: 'getSupportUsers',
        sendRequest: () => sendGetRequest('/api/v1/vornesupport/user'),
    });
}

export function getSupportUsersReset() {
    return resetResourcesRequest({
        resourceType: 'supportUsers',
        requestId: 'getSupportUsers',
    });
}

export default getSupportUsers;
