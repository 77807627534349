import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import styled from 'styled-components';

const ErrorIcon = styled.div`
    display: inline-block;
    color: ${({theme}) => theme.colors.palette.error.red};
`;

/**
 * When error is provided render a red error indicator that displays
 * a tooltip with the error message when hovered or tapped.
 *
 * @param {String[]} error The array of errors from the data request.
 *     If empty or falsey render nothing.
 */
function ErrorIndicator({
    error,
}) {
    if (!error || error.length === 0) {
        return null;
    }

    // NOTE: We only display the first error message, even if there
    // are multiple errors.
    //
    let [errorMessage] = error;

    return (
        <Tooltip
            style={{display: 'inline-block'}}
            content={errorMessage}
            stickyOnClick
        >
            <ErrorIcon>
                <Icon type='fontAwesome' iconName='exclamation-circle' />
            </ErrorIcon>
        </Tooltip>
    );
}

ErrorIndicator.propTypes = {
    error: PropTypes.arrayOf(PropTypes.string),
};

ErrorIndicator.defaultProps = { };

export default ErrorIndicator;
