import React from 'react';
import PropTypes from 'prop-types';

import Form from 'Components/form/Form';
import { REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';
import { FormController } from 'Components/form/FormController';
import EnterpriseHierarchyEditor from 'Components/hierarchy-editor/EnterpriseHierarchyEditor';
import validate from 'Components/hierarchy-editor/EnterpriseHierarchyEditorValidation';
import { selectFlattenedHierarchy } from 'Redux/reducers/hierarchy';

function rebuildTree(value) {
    const { rootId, nodes } = value;

    let node = nodes[rootId];

    let nodeId = node.isNew ? null : Number(node.id);

    const children = node.children.map((childId) => {
        return rebuildTree({
            rootId: childId,
            nodes: nodes,
        });
    });

    let device = null;

    if (node.device) {
        device = {
            id: node.device.id,
            asset_name: typeof node.device.asset_name === 'string' ?
                node.device.asset_name.trim() : node.device.asset_name,
            hierarchy_visibility: Number(node.device.hierarchy_visibility),
        };
    }

    return {
        id: nodeId,
        node_name: node.node_name ? node.node_name.trim() : node.node_name,
        node_type: node.node_type,
        device: device,
        children: children,
    };
}

export class EnterpriseHierarchyForm extends React.Component {
    static propTypes = {
        hierarchyStore: PropTypes.object.isRequired,
        requests: PropTypes.shape({
            putHierarchy: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        readOnly: PropTypes.bool,
        actions: PropTypes.shape({
            setHierarchyReset: PropTypes.func.isRequired,
            putHierarchy: PropTypes.func.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        readOnly: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            flattenedHierarchy: null, // Derived in `getDerivedStateFromProps`
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            flattenedHierarchy: selectFlattenedHierarchy(props.hierarchyStore),
        };
    }

    componentDidMount() {
        this.props.actions.setHierarchyReset();
    }

    _handleSubmit = async (value, formController) => {
        const {
            resetValue,
            setSubmitError,
        } = formController;

        const payload = rebuildTree(value.hierarchy);

        // Send the request
        const responseAction = await this.props.actions.putHierarchy(payload);

        if (responseAction.type === REQUEST_SUCCESS) {
            resetValue();
        }
        else if (responseAction.type === REQUEST_FAILURE) {
            setSubmitError(responseAction.payload?.error);
        }
    };

    _handleReset = (event) => {
        this.props.actions.setHierarchyReset();
    };

    _handleKeyPress = (event) => {
        const key = event.charCode || event.keyCode || 0;

        // If ENTER/RETURN was pressed, do not submit the form.
        if (key === 13) {
            event.preventDefault();
        }
    };

    render() {
        const { readOnly } = this.props;
        return (
            <FormController
                initialValue={{
                    hierarchy: this.state.flattenedHierarchy,
                }}
                validate={(value) => {
                    return {
                        hierarchy: validate(value.hierarchy),
                    };
                }}
                updateValueIfNoChanges={true}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                render={(innerProps) => {
                    const {
                        value,
                        validation,
                        hasChanges,
                        isSubmitting,
                        submitError,

                        handleChange,
                        handleSubmit,
                        handleReset,
                    } = innerProps;

                    return (
                        <Form
                            showSubmit={! readOnly}
                            showCancel={! readOnly}
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                            onKeyPress={this._handleKeyPress}
                            allowSubmit={! readOnly && hasChanges && !isSubmitting && !validation?.hierarchy}
                            allowCancel={! readOnly && hasChanges && !isSubmitting}
                            error={submitError}
                        >
                            <EnterpriseHierarchyEditor
                                name="hierarchy"
                                value={value.hierarchy}
                                onChange={handleChange}
                                validation={validation?.hierarchy}
                                disabled={isSubmitting}
                                readOnly={readOnly}
                            />
                        </Form>
                    );
                }}
            />
        );
    }
}
