import React from 'react';
import PropTypes from 'prop-types';
import withFormElementContext from 'Components/form-element/withFormElementContext';

class BaseCheckboxInput extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        formElementContext: PropTypes.shape({
            disabled: PropTypes.bool.isRequired,
            onFocus: PropTypes.func.isRequired,
            onBlur: PropTypes.func.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        name: '',
        value: false,
        onChange: () => {},
    };

    constructor(props) {
        super(props);
    }

    _handleFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
        this.props.formElementContext.onFocus(event);
    };

    _handleBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
        this.props.formElementContext.onBlur(event);
    };

    _handleChange = (event) => {
        event.target = {
            name: event.target.name,
            value: event.target.checked,
        };
        this.props.onChange(event);
    };

    render() {
        const {
            value,
            formElementContext,
            ...otherProps
        } = this.props;

        return (
            <input
                {...otherProps}
                type="checkbox"
                onChange={this._handleChange}
                checked={value}
                disabled={formElementContext.disabled}
                onFocus={this._handleFocus}
                onBlur={this._handleBlur}
            />
        );
    }
}

export default withFormElementContext(BaseCheckboxInput);
