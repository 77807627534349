import escape from 'lodash/escape';
const variableRegEx = /\{([\w.]*)\}/g;

///-----------------------------------------------------------------------------------------
/// Applies named arguments to the given string.
/// Returns a formatted version of the string argument and does not modify the original.
///
/// PARAMETERS
///     [String] fmt: a string with embedded format-specifications
///     [Object] args: an object of strings with members named similarly to the
//                     embedded specifiers. Member values will be inserted into the string
//                     in place of the specifiers.
///     [Object] opts: (optional) Contains any of the following formatting options:
///         escapeHTML: if true, special HTML characters will be replaced with their proper
///                     escape sequence
///
/// EXAMPLE
/// > exformat("{foo} -> {bar}", { foo: 22, bar: 33 });
/// "{22 -> 33}"
///-----------------------------------------------------------------------------------------

export function exformat(fmt, args, opts) {
    if (typeof args !== 'object') {
        throw new Error('exformat: args must be an object');
    }

    opts = opts || { };

    return fmt.replace(variableRegEx, function(m, i) {
        var rv = args[i];

        // Undefined values are skipped over.
        if (typeof(rv) === 'undefined') {
            rv = '';
        }

        if (typeof(rv) !== 'string') {
            rv = String(rv);
        }

        if (opts.escapeHTML) {
            rv = escape(rv);
        }

        return rv;
    });
}

/**
 * Convenience alias to exformat, but ensures the escapeHTML option
 * is enabled so the values of the args may safely be used in HTML.
 */
export function exformatHTML(fmt, args, opts) {
    opts = opts || { };
    opts.escapeHTML = true;

    return exformat(fmt, args, opts);
}

export function countSymbols(str) {
    return Array.from(str).length;
}

export function toUtf8(str) {
    // [FUTUREHACK] may not be the most complete, efficient method.
    return unescape(encodeURI(str));
}

export function toTitleCase(s) {
    return s.charAt(0).toUpperCase() + s.substr(1);
}

export function getVariables(str) {
    var variables = str.match(variableRegEx) || [];
    // Get rid of the braces.
    return variables.map(function(v) {
        return v.slice(1, -1);
    });
}
