import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonLike from './ButtonLike';

const Root = styled(ButtonLike)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: none;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 50%;

    &[disabled] {
        opacity: 0.2;
    }
`;

function IconButton(props) {
    const {
        children: childrenProp,
        ...otherProps
    } = props;

    const icon = React.Children.only(childrenProp);

    return (
        <Root {...otherProps}>
            {icon}
        </Root>
    );
}

IconButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    type: PropTypes.string,
};

IconButton.defaultProps = {
    type: 'button',
};

export default IconButton;
