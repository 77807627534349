import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { elementsOfType } from 'Components/util/propTypesUtils';
import TableRow from 'Components/table/TableRow';
import TableErrorRow from './TableErrorRow';

const ThemedTableBody = styled.tbody`
    tr.even {
        background-color: ${({ theme }) => theme.colors.palette.blue.ice};
    }
`;

function TableBody({ children, ...otherProps }) {
    let rows = React.Children.toArray(children);

    let nonErrorRows = 0;

    rows = rows.map((row) => {
        if (row.type !== TableErrorRow) {
            nonErrorRows += 1;
        }

        const evenRow = (nonErrorRows % 2 === 0);
        let className = row.props.className;

        className = classNames(className, {
            even: evenRow,
            odd: !evenRow,
        });

        return React.cloneElement(row, {
            className
        });
    });

    return (
        <ThemedTableBody {...otherProps} >
            {rows}
        </ThemedTableBody>
    );
}

TableBody.propTypes = {
    children: elementsOfType([
        TableRow,
        TableErrorRow,
    ]),
};

export default TableBody;
