import { sendGetRequest } from 'Http/request';
import { getResources } from 'Common/util/ResourceCollection';

export function getAlertSubscriptions() {
    return getResources({
        resourceType: 'alertSubscriptions',
        requestId: 'getAlertSubscriptions',
        sendRequest: async () => {
            return sendGetRequest('/api/v1/accounts/user/alert-subscriptions');
        },
    });
}

export default getAlertSubscriptions;
