import { connect } from 'react-redux';
import React from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import LoadingPage from 'Components/pages/loading/LoadingPage';
import PropTypes from 'prop-types';
import {
    selectIsSupport,
    selectIsInitialized,
    selectIsLoggedIn,
} from 'Redux/reducers/session';

// routes only for vorne support employees
const SupportRoute = ({ component: Component, ...rest }) => {
    const session = rest.session;
    const showLoading = !selectIsInitialized(session);
    const isLoggedIn = selectIsLoggedIn(session);
    const isSupport = selectIsSupport(session);

    const renderFunc = function(props) {
        if (showLoading) {
            return (
                <LoadingPage />
            );
        }
        else if (!isLoggedIn || !isSupport) {
            return (
                <Redirect to={{
                    pathname: '/',
                }}/>
            );
        }

        return (
            <Component {...props} />
        );
    };

    return (
        <Route
            {...rest}
            render={renderFunc}
        />
    );
};

SupportRoute.propTypes = {
    session: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const { session } = state;
    return { session };
};

export default withRouter(connect(mapStateToProps)(SupportRoute));
