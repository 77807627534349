import { sendGetRequest } from 'Http/request';
import { getResource, resetResourceRequest } from 'Common/util/ResourceCollection';

export function getHierarchy() {
    return getResource({
        resourceType: 'hierarchy',
        requestId: 'getHierarchy',
        sendRequest: async () => {
            const response = await sendGetRequest('/api/v1/devices/hierarchy');

            // If the response is a 403 (forbidden), it means the user has no
            // hierarchy visibility. Just fake a success with an empty hierarchy.
            if (response.statusCode === 403) {
                return {
                    ok: true,
                    statusCode: 200,
                    data: null,
                };
            }
            else {
                return response;
            }
        },
    });
}

export function getHierarchyReset() {
    return resetResourceRequest({
        resourceType: 'hierarchy',
        requestId: 'getHierarchy',
    });
}

export default getHierarchy;
