import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { childrenAreOneOf } from 'Common/util/propTypeHelpers';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import MenuButton from './MenuButton';
import Icon from 'Common/components/Icon';
import MenuSeparator from 'Common/components/MenuToolbar/MenuSeparator';

/**
 * MenuDropdown
 *
 * A dropdown used in a MenuToolbar. Really should be top level, do not use MenuDropdown in MenuDropdown
 * instead check out MenuList. Children are the items in the dropdown, and text and icon are used in label.
 *
 */

const Root = styled(Dropdown)`
    display: inline-block;
`;

const Container = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

const dropdownIconStyle = { fontSize: 10, alignSelf: 'center' };

export default function MenuDropdown(props) {
    const { text, icon, children, disabled, buttonStyle } = props;

    const [isOpen, setIsOpen] = useState(false);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const renderContent = useCallback(() => {
        return (
            <Container>
                {React.Children.map(children, (child) => {
                    if (child) {
                        let childProps = { ...child.props, onClose };
                        return React.cloneElement(child, childProps);
                    }
                    else {
                        return null;
                    }
                })}
            </Container>
        );
    }, [children, onClose]);

    return (
        <Root
            isOpen={isOpen && !disabled}
            onMaskClick={onClose}
            renderContent={renderContent}
        >
            <MenuButton
                onClick={onOpen}
                icon={icon}
                text={text}
                disabled={disabled}
                dropdownIcon={(<Icon style={dropdownIconStyle} type="fontAwesome" iconName="chevron-down"/>)}
                style={buttonStyle}
            />
        </Root>
    );
}

MenuDropdown.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.node,
    children: childrenAreOneOf({
        elementTypes: [
            MenuSeparator
        ],
        propShapes: [
            // list:
            [
                'text',
                'children',
            ],
            // group:
            [
                'label',
                'children',
            ],
            // item:
            [
                'onClick',
                'text',
            ],
            // info item:
            [
                'children'
            ]
        ],
    }),
    buttonStyle: PropTypes.object,
    disabled: PropTypes.bool,
};

MenuDropdown.defaultProps = {
    buttonStyle: {},
    icon: null,
    disabled: false,
};
