import React from 'react';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentWide';
import licenses from 'Licenses/licenses.json';
import LicenseDescription from './LicenseDesciption';
import PageDescription from 'Components/page/PageDescription';

export default function LicensesPage() {
    return (
        <Page>
            <PageTitle>Third-Party Licenses</PageTitle>
            <PageDescription>
                XL Enterprise&trade; utilizes a collection of various pieces of software
                (referred to collectively as the &quot;Software&quot;).
                <br />
                <br />
                Some pieces of the Software require license information to be provided.
                The licensing information in this section pertains only to the
                specifically listed software libraries.
                <br />
                <br />
                Please note that copyright notices and license text appear in their
                original language.
            </PageDescription>
            <PageContent>
                {
                    licenses.map((license) => {
                        return (
                            <LicenseDescription
                                name={license.packageName}
                                key={license.packageName}
                                text={license.licenseText}
                            />
                        );
                    })
                }
            </PageContent>
        </Page>
    );
}
