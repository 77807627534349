const COLUMN_PROPERTIES = {
    full: {num: 1 },
    halves: {num: 2},
    'two-thirds-one-third': {num: 2},
    'one-third-two-thirds': {num: 2},
    thirds: {num: 3},
    fourths: {num: 4},
    fifths: {num: 5},
    sixths: {num: 6},
    scoreboard: {num: 3},
};

export {
    COLUMN_PROPERTIES
};
