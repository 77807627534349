import React from 'react';
import PropTypes from 'prop-types';
import SparkLine from 'Common/components/SparkLine';

function KPISparkLine({ data, noColor, ...otherProps }) {

    let sparkLineProps = {};

    if (noColor) {
        sparkLineProps = {
            lineWidth: 2,
            lineColor: 'black',
            fillColor: 'silver',
        };
    }

    return (
        <SparkLine
            {...otherProps}
            data={data}
            {...sparkLineProps}
        />
    );
}

KPISparkLine.propTypes = {
    data: PropTypes.array,
    noColor: PropTypes.bool,
};

KPISparkLine.defaultProps = {
    data: null,
    noColor: false,
};

export default KPISparkLine;
