import React from 'react';
import { Body1 } from 'Components/typography/typography';
import { Link } from 'react-router-dom';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentStandard';

export default class InvalidUrlPage extends React.Component {
    render() {
        return (
            <Page>
                <PageTitle>That page seems to be missing</PageTitle>
                <PageContent>
                    <Body1>Return to the <Link to="/"> home page</Link>.</Body1>
                </PageContent>
            </Page>
        );
    }
}
