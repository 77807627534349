import some from 'lodash/some';
import processStates from 'System/processStates';
import every from 'lodash/every';

function validateProcessState(value) {
    if (value.length === 0) {
        return 'required';
    }
    else if (!some(processStates, ps => ps.value === value)) {
        return 'invalid production state';
    }
    else {
        return null;
    }
}

function validateThreshold(value) {
    if (value.length === 0) {
        return 'required';
    }
    else if (!Number.isFinite(Number(value))) {
        return 'not a number';
    }
    else {
        return null;
    }
}

export default function validate(processStateDurationAlert) {
    const { process_state, threshold } = processStateDurationAlert;

    const validation = {
        process_state: validateProcessState(process_state),
        threshold: validateThreshold(threshold),
    };

    if (every(validation, v => !v)) {
        return null;
    }
    else {
        return validation;
    }
}
