import React from 'react';
import styled from 'styled-components';
import Icon from 'Common/components/Icon';
import TextField from '../TextField';

const StyledTextField = styled(TextField)`
    min-width: 185px;
`;

export default function SearchField(props) {
    return (
        <StyledTextField
            type="search"
            {...props}
            leftIcon={(
                <Icon
                    type='vorne'
                    iconName='search'
                />
            )}
        />
    );
}

/**
 * @see TextField
 */
SearchField.propTypes = {};

/**
 * @see TextField
 */
SearchField.defaultProps = {};
