import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GroupedDragDropList from './GroupedDragDropList';

const triggerEscape = () => {
    const event = new window.KeyboardEvent('keydown', {
       bubbles: true,
       cancelable: true,
       keyCode: 27, // Escape,
    });
    document.body.dispatchEvent(event);
};

function getNodeLevel(node) {
    return node.path.length - 1;
}

function getFlatNodeParentId(flatNode) {
    if (flatNode.path.length > 1) {
        return flatNode.path[flatNode.path.length - 2];
    }
    else if (flatNode.path.length === 1) {
        return null; // Root node, no parent.
    }
    else {
        throw new Error('Invalid path for flatNode');
    }
}

function flattenNodes(rootId, nodes, dragSource, path) {
    const { children, ...rootNode } = nodes[rootId];

    const currNode = {
        ...rootNode,
        path: path,
        isDragging: Boolean(dragSource && dragSource.draggableId === rootId),
        groupedChildren: [],
        allowedDropLevels: [],
    };

    const flatChildNodes = children.reduce((curr, childId, siblingIndex) => {
        return curr.concat(
            flattenNodes(
                childId,
                nodes,
                dragSource,
                [ ...path, childId ],
            )
        );
    }, []);

    if (currNode.isDragging) {
        // The node is being dragged, so group the children inside of the
        // draggable so they will be dragged with the parent.
        currNode.groupedChildren = flatChildNodes;

        return [ currNode ];
    }
    else {
        // Create a flattened list starting with the parent and the
        // children after.
        return [
            currNode,
            ...flatChildNodes,
        ];
    }
}

/*
 * Calculate a range of indent levels that a dragged node can be dropped at in
 * the flat list.
 *
 * At any given index in the flattened tree, there are usually multiple options
 * for what level a node can be placed. Basically, the rule is that it can be
 * placed at any level of the hierarchy that does not cause any other nodes to
 * get re-parented.
 *
 * For example, if you drop a node between two sibling nodes (at the same
 * level), there are only two options for what level it can be placed at: As a
 * child of the parent above, or a sibling of both the node before and the node
 * after. After determining the available levels, the desired level for the
 * drop is based on the horizontal position of the dragged element.
 *
 */
function calculateDropLevelRange(flatNodes, draggingId, dropIndex) {
    // Start by removing the dragged node from the list
    flatNodes = flatNodes.filter((node, index) => {
        return node.id !== draggingId;
    });

    const indexBefore = dropIndex - 1;
    const indexAfter = dropIndex;

    const nodeBefore = flatNodes[indexBefore];
    const nodeAfter = flatNodes[indexAfter];

    if (!nodeBefore && !nodeAfter) {
        // Empty list
        return [ 1, 1 ];
    }
    else if (!nodeBefore && nodeAfter) {
        // Start of list
        return [ 1, 1 ];
    }
    else if (nodeBefore && !nodeAfter) {
        // End of list
        return [ 1, getNodeLevel(nodeBefore) + 1 ];
    }

    if (getNodeLevel(nodeBefore) === getNodeLevel(nodeAfter)) {
        // Between two nodes of the same level
        return [ getNodeLevel(nodeBefore), getNodeLevel(nodeBefore) + 1 ];
    }
    else if (getNodeLevel(nodeBefore) < getNodeLevel(nodeAfter)) {
        // Inserting at first child of nodeBefore
        return [ getNodeLevel(nodeAfter), getNodeLevel(nodeAfter) ];
    }
    else if (getNodeLevel(nodeBefore) > getNodeLevel(nodeAfter)) {
        // Inserting sibling of nodeAfter to child of nodeBefore
        return [ getNodeLevel(nodeAfter), getNodeLevel(nodeBefore) + 1 ];
    }

    throw new Error('Unable to calculate drop level range');
}

function prepareDraggables(value, dragSource, allowDropChildFn) {
    const { rootId, nodes } = value;

    if (!rootId) {
        return [];
    }

    const { children } = nodes[rootId];

    const allowDropChildMap = {
        [rootId]: Boolean(dragSource && allowDropChildFn(nodes[rootId], nodes[dragSource.draggableId])),
    };

    let flatNodes = children
        .reduce((curr, childId) => {
            return curr.concat(
                flattenNodes(
                    childId,
                    nodes,
                    dragSource,
                    [ rootId, childId ]
                )
            );
        }, [])
        .map((flatNode) => {
            let allowDropChild = true;

            if (dragSource && !flatNode.isDragging) {
                allowDropChild = allowDropChildFn(nodes[flatNode.id], nodes[dragSource.draggableId]);
            }

            allowDropChildMap[flatNode.id] = allowDropChild;

            return {
                ...flatNode,
                allowDropChild: allowDropChild,
            };
        });

    const isDragging = !!dragSource;

    const dragSourceIndex = flatNodes.findIndex((flatNode) => {
        return flatNode.id === dragSource?.draggableId;
    });

    if (isDragging && dragSourceIndex === -1) {
        throw new Error(`Unable to find index of drag source with id ${dragSource?.draggableId}`);
    }

    flatNodes = flatNodes.map((flatNode, index) => {
            const draggingId = dragSource?.draggableId;

            // If this is the node being dragged, don't bother with it.
            if (draggingId === flatNode.id) {
                return {
                    ...flatNode,
                    blockDropAfter: false,
                    allowedDropLevels: [],
                };
            }

            // The hypothetical index that the dragged node could be dropped at.
            // To figure this out, the starting index of the dragged item must
            // be considered, because moving the dragged item could cause indexes
            // to shift.
            let dropIndex;

            if (isDragging) {
                if (dragSourceIndex < index) {
                    dropIndex = index;
                }
                else {
                    dropIndex = index + 1;
                }
            }
            else {
                dropIndex = index + 1;
            }

            // Find the range of drop levels if the dragged item was dropped after
            // this node.
            const [ minDropLevel, maxDropLevel ] = calculateDropLevelRange(flatNodes, draggingId, dropIndex);

            const adjustedPath = [ '', ...flatNode.path ];

            const allowedDropLevels = adjustedPath.map((nodeId, dropLevel) => {
                return (
                    dropLevel >= minDropLevel &&
                    dropLevel <= maxDropLevel &&
                    allowDropChildMap[nodeId]
                );
            });

            // If every node in it's path does not allow the node to be dropped
            // as a child, then mark it as blocking all drops
            const blockDropAfter = allowedDropLevels.every((allowDrop) => {
                return allowDrop === false;
            });

            return {
                ...flatNode,
                allowedDropLevels: allowedDropLevels,
                blockDropAfter: blockDropAfter,
            };
        });

    return flatNodes;
}

function calculateDropLevel(flatNodes, dragSource, dropDestination, dropLevelIndent) {
    if (dropDestination.index === 0) {
        return 1;
    }

    // Start by removing the dragged node from the list
    flatNodes = flatNodes.filter((node, index) => {
        const isDraggedNode = (
            dragSource.droppableId === dropDestination.droppableId &&
            dragSource.index === index
        );

        return !isDraggedNode;
    });

    const indexBefore = dropDestination.index - 1;
    const nodeBefore = flatNodes[indexBefore];

    let dropLevel = null;

    for (let i = 0; i < nodeBefore.allowedDropLevels.length; i++) {
        const isLevelAllowed = nodeBefore.allowedDropLevels[i];

        if (dropLevel === null) {
            if (isLevelAllowed) {
                dropLevel = i;
            }
        }
        else {
            if (isLevelAllowed && i <= dropLevelIndent) {
                dropLevel = i;
            }
        }
    }

    return dropLevel;
}

function calculatePlaceholderTop(flatNodeHeights, dragSource, dropDestination) {
    if (dropDestination === null) {
        return null;
    }

    const rv = flatNodeHeights
        .filter((height, index) => {
            const isDraggedNode = (
                dragSource.droppableId === dropDestination.droppableId &&
                dragSource.index === index
            );

            return !isDraggedNode;
        })
        .reduce((curr, height, index) => {
            if (index < dropDestination.index) {
                return curr + height;
            }
            else {
                return curr;
            }
        }, 0);

    return rv;
}

/**
 * After a drag is completed, this will "refresh" the `parents` array of each node
 * in the hierarchy.
 *
 * @param {Object} nodes
 * @param {string} rootId Id of root node
 *
 * @returns {Object} Updated nodes.
 */
function updateNodeParentsAfterDragEnd(nodes, rootId) {
    const updatedNodes = {};

    const recurse = (nodeId, parents) => {
        const currentNode = nodes[nodeId];

        updatedNodes[nodeId] = {
            ...currentNode,
            parents: parents,
        };

        const childParents = [ ...parents, nodeId ];

        currentNode.children.forEach((childNodeId) => {
            recurse(childNodeId, childParents);
        });
    };

    recurse(rootId, []);

    return updatedNodes;
}

const Root = styled.div``;

const DropPlaceholder = styled.div.attrs((props) => {
    return {
        style: {
            top: `${props._top}px`,
            left: `${props._left}px`,
            height: `${props._height}px`,
        },
    };
})`
    position: absolute;
    right: 0;
    background-color: rgba(0,0,0,0.1);
`;

export default class HierarchyEditor extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.shape({
            rootId: PropTypes.string.isRequired,
            nodes: PropTypes.objectOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    children: PropTypes.arrayOf(PropTypes.string).isRequired,
                    parents: PropTypes.arrayOf(PropTypes.string).isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
        validation: PropTypes.objectOf(PropTypes.string),
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool.isRequired,
        indentPerLevel: PropTypes.number,
        renderNode: PropTypes.func.isRequired,
        allowDropChild: PropTypes.func,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        name: '',
        validation: {},
        disabled: false,
        indentPerLevel: 32,
        allowDropChild: (node, draggedNode) => true,
    };

    constructor(props) {
        super(props);

        this.state = {
            draggingId: null,
            dragSource: null,

            dropDestination: null,
            dropLevel: null,

            showPlaceholder: false,
            placeholderTop: null,
            placeholderHeight: null,
            placeholderLevel: null,

            flatNodeHeights: null,
        };

        this._isDropAnimating = false;

        this._droppableInnerElem = null;
        this._draggableInnerElems = {};
        this._draggableOuterElems = {};
    }

    static getDerivedStateFromProps(props, state) {
        const dragSource = state.draggingId ?
            {
                draggableId: state.draggingId,
            }
            : null;

        return {
            flatNodes: prepareDraggables(
                props.value,
                dragSource,
                props.allowDropChild,
            ),
        };
    }

    _handleBeforeCapture = ({ draggableId }) => {
        this.setState({
            draggingId: draggableId,
        });
    };

    _handleDragStart = (start) => {
        const { draggableId, source, mode } = start;
        const { flatNodes } = this.state;

        // This is a hack to prevent dragging with the keyboard. Keybaord is
        // not supported and will cause issues.
        if (mode === 'SNAP') {
            setTimeout(triggerEscape, 10);
        }

        const dragSource = {
            draggableId: draggableId,
            droppableId: source.droppableId,
            index: source.index,
        };

        // Fake out the drop destination as the source, just for the start of
        // the drag
        const fakeDropDestination = {
            droppableId: source.droppableId,
            index: source.index,
        };

        const flatNodeHeights = flatNodes.map((node) => {
            const outerElems = this._draggableOuterElems;
            return outerElems[node.id].getBoundingClientRect().height;
        });

        const placeholderHeight = flatNodeHeights[dragSource.index];
        const placeholderTop = calculatePlaceholderTop(flatNodeHeights, dragSource, fakeDropDestination);
        const placeholderLevel = getNodeLevel(flatNodes[dragSource.index]);

        this.setState({
            draggingId: draggableId,
            dragSource: dragSource,
            dropDestination: null,
            dropLevel: null,

            showPlaceholder: true,
            placeholderTop: placeholderTop,
            placeholderHeight: placeholderHeight,
            placeholderLevel: placeholderLevel,

            flatNodeHeights: flatNodeHeights,
        });
    };

    _handleDragUpdate = (update) => {
        const { draggableId, source, destination } = update;
        const { flatNodeHeights } = this.state;

        const dragSource = {
            draggableId: draggableId,
            droppableId: source.droppableId,
            index: source.index,
        };

        let dropDestination = null;

        if (destination) {
            dropDestination = {
                droppableId: destination.droppableId,
                index: destination.index,
            };
        }

        this.setState({
            draggingId: draggableId,
            dragSource: dragSource,
            dropDestination: dropDestination,

            showPlaceholder: dropDestination !== null,
            placeholderTop: calculatePlaceholderTop(flatNodeHeights, dragSource, dropDestination),
        });
    };

    _handleDragEnd = (result) => {
        const { draggableId, source } = result;
        let { destination } = result;

        const { value } = this.props;
        const { flatNodes } = this.state;
        let { dropLevel } = this.state;

        if (!destination) {
            destination = {
                droppableId: source.droppableId,
                index: source.index,
            };
        }

        if (dropLevel === null) {
            dropLevel = getNodeLevel(flatNodes[source.index]);
        }

        const draggedFlatNode = flatNodes[source.index];
        const sourceParentId = getFlatNodeParentId(draggedFlatNode);

        const otherFlatNodes = flatNodes.filter((node) => {
            return node.id !== draggableId;
        });

        const nodeBefore = otherFlatNodes[destination.index - 1];
        let firstSiblingIndex = 0;
        let destinationParentId = null;

        if (nodeBefore) {
            const dropPath = nodeBefore.path.slice(0, dropLevel);

            destinationParentId = dropPath[dropPath.length - 1];

            if (destinationParentId === value.rootId) {
                firstSiblingIndex = 0;
            }
            else {
                // In the flat list, the first child is always right after the parent.
                firstSiblingIndex = 1 + otherFlatNodes.findIndex((node) => {
                    return node.id === destinationParentId;
                });
            }
        }
        else {
            // Parent is the root node
            destinationParentId = value.rootId;
            // First sibling is the start of the flat list
            firstSiblingIndex = 0;
        }

        let siblingsBefore = 0;

        for (let i = firstSiblingIndex; i < destination.index; i++) {
            const currNode = otherFlatNodes[i];
            const isSibling = getFlatNodeParentId(currNode) === destinationParentId;

            if (isSibling) {
                siblingsBefore++;
            }
        }

        const siblingIndex = siblingsBefore;

        let newNodes = { ...value.nodes };

        // Remove draggable from old parent
        newNodes[sourceParentId] = {
            ...newNodes[sourceParentId],
            children: newNodes[sourceParentId].children.filter((childId) => {
                return childId !== draggableId;
            }),
        };

        const newSiblings = [ ...newNodes[destinationParentId].children ];
        newSiblings.splice(siblingIndex, 0, draggableId);

        newNodes[destinationParentId] = {
            ...newNodes[destinationParentId],
            children: newSiblings,
        };

        // Now that the drag has finished, update the parent nodes.
        newNodes = updateNodeParentsAfterDragEnd(newNodes, value.rootId);

        this.props.onChange({
            target: {
                name: this.props.name,
                value: {
                    ...value,
                    nodes: newNodes,
                },
            },
        });

        this._isDropAnimating = false;

        this.setState({
            draggingId: null,
            dragSource: null,

            dropDestination: null,
            dropLevel: null,

            showPlaceholder: false,
            placeholderTop: null,
            placeholderHeight: null,
            placeholderLevel: null,

            flatNodeHeights: null,
        });
    };

    _handleMouseMove = () => {
        const { indentPerLevel } = this.props;
        const { draggingId, flatNodes, dragSource } = this.state;
        let { dropDestination } = this.state;

        // If we are not currently dragging, do nothing.
        if (draggingId === null || !dragSource) {
            return;
        }

        // There is a short time after the dragged item is "released", when it
        // is animating to its new position. Because the `onDragEnd` is not
        // fired until after the animation, we do not want to update the drop
        // level during this time.
        if (this._isDropAnimating) {
            return;
        }

        // If there is no dropDestination, fake it.
        if (!dropDestination) {
            dropDestination = {
                droppableId: dragSource.droppableId,
                index: dragSource.index,
            };
        }

        const dragElem = this._draggableInnerElems[draggingId];
        const dropElem = this._droppableInnerElem;

        // Calculate drop level
        const dragRect = dragElem.getBoundingClientRect();
        const dropRect = dropElem.getBoundingClientRect();

        const dropLevelPx = Math.max(0, dragRect.left - dropRect.left);
        const dropLevelIndent = Math.floor(dropLevelPx / indentPerLevel);

        const dropLevel = calculateDropLevel(flatNodes, dragSource, dropDestination, dropLevelIndent);

        if (dropLevel !== this.state.dropLevel || dropLevel !== this.state.placeholderLevel) {
            this.setState({
                dropLevel: dropLevel,
                placeholderLevel: dropLevel,
            });
        }
    };

    _renderNode(flatNode, provided, snapshot) {
        const { value: valueProp, indentPerLevel, renderNode } = this.props;

        const mergedProvided = {
            innerRef: () => {},
            dragHandleProps: {},
            draggableProps: {},
            ...provided,
        };

        const mergedSnapshot = {
            isDragging: false,
            isParentDragging: false,
            isDropAnimating: false,
            allowDropChild: flatNode.allowDropChild,
            blockDropAfter: flatNode.blockDropAfter,
            allowedDropLevels: flatNode.allowedDropLevels || [],
            dropLevel: null,
            path: flatNode.path || [],
            ...snapshot,
        };

        const nodeId = flatNode.id;
        const node = valueProp.nodes[nodeId];

        let visibleIndentLevel = getNodeLevel(flatNode);

        if ((mergedSnapshot.isDragging || mergedSnapshot.isParentDragging) && mergedSnapshot.isDropAnimating) {
            visibleIndentLevel = mergedSnapshot.dropLevel;
        }

        let transition = '';

        if (mergedSnapshot.dropAnimation) {
            transition = `padding-left ${mergedSnapshot.dropAnimation.duration}s ${mergedSnapshot.dropAnimation.curve}`;
        }

        return (
            <div
                key={`node-${nodeId}`}
                style={{
                    transition: transition,
                    paddingLeft: `${visibleIndentLevel * indentPerLevel}px`,
                }}
            >
                {renderNode({
                    node: node,
                    level: getNodeLevel(flatNode),
                    provided, mergedProvided,
                    snapshot: mergedSnapshot,
                })}
            </div>
        );
    }

    _renderItem(flatNode, provided, snapshot) {
        const { dropLevel } = this.state;

        let dropLevelChange = null;

        if (snapshot.isDropAnimating) {
            this._isDropAnimating = true;
        }

        if (snapshot.isDragging && dropLevel !== null) {
            dropLevelChange = dropLevel - getNodeLevel(flatNode);
        }

        return (
            <div
                key={flatNode.id}
                ref={(elem) => {
                    this._draggableOuterElems[flatNode.id] = elem;
                }}
                {...provided.draggableProps}
            >
                {this._renderNode(
                    flatNode,
                    {
                        ...provided,
                        innerRef: (elem) => {
                            this._draggableInnerElems[flatNode.id] = elem;
                        },
                    },
                    {
                        ...snapshot,
                        dropLevel: dropLevel,
                        allowDropChild: flatNode.allowDropChild,
                    }
                )}
                {flatNode.groupedChildren && flatNode.groupedChildren.map((child) => {
                    return this._renderNode(
                        child,
                        {
                            innerRef: () => {},
                            dragHandleProps: {}
                        },
                        {
                            isDragging: false,
                            isParentDragging: true,
                            isDropAnimating: snapshot.isDropAnimating,
                            dropAnimation: snapshot.dropAnimation,
                            dropLevel: getNodeLevel(child) + dropLevelChange,
                        }
                    );
                })}
            </div>
        );
    }

    _renderRootNode() {
        const { value } = this.props;
        const { rootId, nodes } = value;
        const { children: rootChildren, ...rootNode } = nodes[rootId];

        const flatRootNode = {
            ...rootNode,
            path: [ rootId ],
            groupedChildren: [],
        };

        return this._renderNode(
            flatRootNode,
            // provided
            {
                innerRef: (elem) => {
                    this._draggableOuterElems[rootNode.id] = elem;
                    this._draggableInnerElems[rootNode.id] = elem;
                },
                dragHandleProps: {},
            },
            // snapshot
            {
                isDragging: false,
                allowedDropLevels: [],
            }
        );
    }

    render() {
        const {
            value,
            validation,
            disabled,
            name: nameProp,
            onChange,
            indentPerLevel,
            readOnly,
            ...otherProps
        } = this.props;

        const {
            draggingId,
            flatNodes,
            showPlaceholder,
            placeholderTop,
            placeholderHeight,
            placeholderLevel,
        } = this.state;

        return (
            <Root {...otherProps}>
                {this._renderRootNode()}
                <GroupedDragDropList
                    readOnly={readOnly}
                    onBeforeCapture={this._handleBeforeCapture}
                    onDragStart={this._handleDragStart}
                    onDragUpdate={this._handleDragUpdate}
                    onDragEnd={this._handleDragEnd}
                    droppableId={`droppable-${value?.rootId}`}
                    items={flatNodes.map((node) => ({ id: node.id }))}
                    renderItem={(index, provided, snapshot) => {
                        return this._renderItem(flatNodes[index], provided, snapshot);
                    }}
                    allowDropAtIndex={(index) => {
                        // Always allow dropping at the top
                        if (index === 0) {
                            return true;
                        }

                        // Note: The code below should be optimized to not use
                        // a filter.

                        const otherNodes = flatNodes.filter((flatNode) => {
                            return flatNode.id !== draggingId;
                        });

                        const nodeAbove = otherNodes[index - 1];

                        if (!nodeAbove) {
                            throw new Error(`Unable to find node for allow drop at index ${index}, otherNodes.length=${otherNodes.length}`);
                        }

                        return !nodeAbove.blockDropAfter;
                    }}
                >
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={(elem) => {
                                this._droppableInnerElem = elem;
                                provided.innerRef(elem);
                            }}
                            style={{
                                pointerEvents: 'auto',
                                position: 'relative',
                            }}
                            onMouseMove={this._handleMouseMove}
                        >
                            {provided.draggables}
                            {provided.placeholder}
                            {/* This placeholder will not be positioned
                            properly if dropping at the top of the list is
                            disabled. This is not currently supported, but
                            could be in the future. */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    right: '0',
                                }}
                            >
                                {showPlaceholder && (
                                    <DropPlaceholder
                                        _top={placeholderTop}
                                        _height={placeholderHeight}
                                        _left={placeholderLevel * indentPerLevel}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </GroupedDragDropList>
            </Root>
        );
    }
}
