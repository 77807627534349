import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

const deepEqualityCheck = (a, b) => isEqual(a, b);

/**
 * A utility hook to memoize an arbitrary set of arguments. Useful for
 * a compound set of values in a useMemo, useCallback, or useEffect
 * dependency array where a deeper equality check is needed.
 *
 * @param {Array} args Arguments to memoize
 * @param {Function} [equalityCheck=deepEqualityCheck] Takes in two args and
 *     determines if they are equal. By default uses lodash's deep equality.
 *
 * @returns {Array} Memoized version of args.
 */
export default function useMemoizedArgs(args, equalityCheck = deepEqualityCheck) {
    const ref = useRef();
    const prevArgs = ref.current;

    const argsAreEqual =
        prevArgs !== undefined
        && args.length === prevArgs.length
        && args.every((v, i) => equalityCheck(v, prevArgs[i]));

    useEffect(() => {
        if (!argsAreEqual) {
            ref.current = args;
        }
    });

    return argsAreEqual ? prevArgs : args;
}
