import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChangePasswordControl from 'Components/password/ChangePasswordControl';
import RoleSelect from 'Components/role-select/RoleSelect';
import TimezoneSelect from 'Components/timezone/TimezoneSelect';
import TextInput from 'Components/text-input/TextInput';
import OutlinedButton from 'Components/button/OutlinedButton';
import { Caption } from 'Components/typography/typography';
import PageFrame from 'Components/page/PageFrame';
import { createPath } from 'Common/util/FormUtils';
import EnterpriseHierarchySelect from 'Components/enterprise-hierarchy-select/EnterpriseHierarchySelect';
import { nullable } from 'Components/util/propTypesUtils';

const Root = styled.div`
    word-break: break-word;
`;

const StyledTextInput = styled(TextInput)`
    display: block;
`;

const ChangePasswordLabel = styled(Caption)`
    display: block;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    line-height: 2em;
    display: none;
`;

const ChangePasswordButton = styled(OutlinedButton)`
    margin-bottom: 16px;
`;

function getDateString(dateTime) {
    if (!dateTime) {
        return '--';
    }

    return (new Date(dateTime)).toDateString();
}

export default class SessionUserEditor extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        validation: PropTypes.object,
        value: PropTypes.shape({
            id: PropTypes.number.isRequired,
            current_password: PropTypes.string.isRequired,
            new_password: PropTypes.string.isRequired,
            created: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            timezone: PropTypes.string.isRequired,
            hierarchy_visibility: nullable(PropTypes.number).isRequired,
        }).isRequired,
        enterpriseHierarchy: PropTypes.object,
    };

    static defaultProps = {
        disabled: false,
        validation: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            showChangePassword: false,
        };
    }

    _handleShowPasswordClick = () => {
        this.setState(({ showChangePassword }) => ({
            showChangePassword: !showChangePassword
        }));
    };

    render() {
        const {
            name: nameProp,
            value,
            validation,
            disabled,
            onChange,
            enterpriseHierarchy,
            ...otherProps
        } = this.props;
        const {
            showChangePassword,
        } = this.state;

        const password_validation = {
            current_password: validation?.current_password,
            new_password: validation?.new_password
        };

        const {
            created,
            current_password,
            name,
            new_password,
            role,
            id,
            timezone,
            hierarchy_visibility,
        } = value;

        const roleElementId = `SessionUserEditor_${id}_role`;
        const timezoneElementId = `SessionUserEditor_${id}_timezone`;

        return (
            <Root {...otherProps}>
                <PageFrame title="Password">
                    {!showChangePassword && (
                        <React.Fragment>
                            <ChangePasswordLabel>Password</ChangePasswordLabel>
                            <ChangePasswordButton
                                color="primary"
                                onClick={this._handleShowPasswordClick}
                            >
                                {'Change Password'}
                            </ChangePasswordButton>
                        </React.Fragment>
                    )}
                    {showChangePassword && (
                        <ChangePasswordControl
                            name={nameProp} // same `name` as parent so child inputs have proper path
                            disabled={disabled}
                            validation={password_validation}
                            value={{
                                current_password: current_password,
                                new_password: new_password,
                            }}
                            onChange={onChange}
                        />
                    )}
                </PageFrame>

                <PageFrame title="Profile">
                    <StyledTextInput
                        label="Name"
                        value={name}
                        disabled={disabled}
                        name={createPath(nameProp, 'name')}
                        error={!!validation?.name}
                        helperText={validation?.name || ''}
                        onChange={onChange}
                    />
                    <StyledTextInput
                        label="Created"
                        value={getDateString(created)}
                        name={createPath(nameProp, 'created')}
                        disabled
                        helperText=""
                    />
                    <TimezoneSelect
                        id={timezoneElementId}
                        label="Time Zone"
                        value={timezone}
                        disabled={disabled}
                        name={createPath(nameProp, 'timezone')}
                        error={!!validation?.timezone}
                        helperText={validation?.timezone || ''}
                        onChange={onChange}
                    />
                </PageFrame>

                <PageFrame title="Access Control">
                    <RoleSelect
                        id={roleElementId}
                        label="Role"
                        value={role}
                        disabled
                        name={createPath(nameProp, 'role')}
                        error={!!validation?.role}
                        helperText={validation?.role || ''}
                        onChange={onChange}
                    />
                    <EnterpriseHierarchySelect
                        label="Hierarchy Visibility"
                        value={hierarchy_visibility === null ?
                            null : String(hierarchy_visibility)}
                        disabled
                        name={createPath(nameProp, 'hierarchy_visibility')}
                        error={!!validation?.hierarchy_visibility}
                        helperText={validation?.hierarchy_visibility || ''}
                        onChange={onChange}
                        enterpriseHierarchy={enterpriseHierarchy}
                    />
                </PageFrame>
            </Root>
        );
    }
}
