import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const sourceIdPrefix = 'portal-source-';

class PortalHelper extends Component {
    static propTypes = {
        sourceId: PropTypes.string.isRequired,
        parentStyle: PropTypes.object,
        children: PropTypes.node,
    };

    static defaultProps = {
        parentStyle: { },
    };

    constructor(props) {
        super(props);

        const id = props.sourceId.replace(sourceIdPrefix, '');
        this.el = document.createElement('div');
        this.el.id = `portal-window-${id}`;
        document.body.appendChild(this.el);
      }

    componentWillUnmount() {
        if (this.el) {
            this.el.remove();
        }
    }

    renderChildren() {
        const { sourceId, parentStyle, children } = this.props;

        const style = {
            position: 'fixed',
            top: 0,
            left: 0,
            ...parentStyle,
        };

        return (
            <div style={style} data-portal-source-id={sourceId}>
                {children}
            </div>
        );
    }

    render() {
        return ReactDOM.createPortal(this.renderChildren(), this.el);
    }
}

let nextPortalSourceNumber = 1000;

function getNextPortalSourceId() {
    const id = `${sourceIdPrefix}${nextPortalSourceNumber}`;
    nextPortalSourceNumber++;
    return id;
}

const PortalSource = styled.div`
    display: none;
`;

// eslint-disable-next-line react/no-multi-comp
export default function Portal({
    parentStyle,
    children,
}) {
    // Generate the ID initially and never update it again for the life
    // of the component
    const [ id ] = useState(() => {
        return getNextPortalSourceId();
    });

    return (
        <PortalSource id={id}>
            <PortalHelper parentStyle={parentStyle} sourceId={id}>
                {children}
            </PortalHelper>
        </PortalSource>
    );
}

Portal.propTypes = {
    parentStyle: PropTypes.object,
    children: PropTypes.node,
};

Portal.defaultProps = {
    parentStyle: { },
};
