import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import every from 'lodash/every';
import SelectGroup from 'Components/select/SelectGroup';
import SelectGroupLabel from 'Components/select/SelectGroupLabel';
import SelectOption from 'Components/select/SelectOption';
import SelectOptionContent from 'Components/select/SelectOptionContent';
import SelectInput from 'Components/select/SelectInput';
import FormElement from 'Components/form-element/FormElement';
import MetricAlertText from './MetricAlertText';
import ProcessStateDurationAlertText from './ProcessStateDurationAlertText';
import ProcessStateTargetTimeAlertText from './ProcessStateTargetTimeAlertText';

const StyledSelectInput = styled(SelectInput)`
    min-width: 225px;
`;

export default class AlertSubscriptionsEditor extends React.Component {
    constructor(props) {
        super(props);
    }

    _handleChange = (event) => {
        const value = event.target.value;

        let subscriptions = {
            metric: [],
            process_state_duration: [],
            process_state_target_time: [],
        };

        value.forEach((selectedValue) => {
            const [ alertType, alertId ] = selectedValue.split('/');
            subscriptions[alertType].push(Number(alertId));
        });

        this.props.onChange({
            target: {
                name: this.props.name,
                value: subscriptions,
            },
        });
    };

    render() {
        const { name, alerts, subscriptions, allowEdit } = this.props;

        // Construct the value
        const value = [];
        Object.keys(subscriptions).forEach((alertType) => {
            subscriptions[alertType].forEach((alertId) => {
                value.push(`${alertType}/${alertId}`);
            });
        });

        const noAlerts = every(alerts, list => list.length === 0);
        const disabled = !allowEdit || noAlerts;

        return (
            <FormElement disabled={disabled}>
                <StyledSelectInput
                    name={name}
                    value={value}
                    multiple
                    selectionDisplay={(
                        <SelectOptionContent>
                            <b>{`Select Alerts (${value.length})`}</b>
                        </SelectOptionContent>
                    )}
                    onChange={this._handleChange}
                    optionsWidth={350}
                    maxOptionsHeight={500}
                >
                    {Object.keys(alerts).map((alertType) => {
                        let title;
                        let AlertText = 'div';

                        switch (alertType) {
                            case 'metric':
                                title = 'Metric';
                                AlertText = MetricAlertText;
                                break;
                            case 'process_state_duration':
                                title = 'Production State Time';
                                AlertText = ProcessStateDurationAlertText;
                                break;
                            case 'process_state_target_time':
                                title = 'Production State Target';
                                AlertText = ProcessStateTargetTimeAlertText;
                                break;
                            default:
                                throw new Error(`Unknown alert type "${alertType}"`);
                        }

                        const alertList = alerts[alertType];

                        // Don't show empty groups
                        if (alertList.length === 0) {
                            return null;
                        }

                        return (
                            <SelectGroup
                                key={alertType}
                                label={<SelectGroupLabel>{title}</SelectGroupLabel>}
                            >
                                {alertList.map((alert) => {
                                    const alertId = alert.id;

                                    return (
                                        <SelectOption
                                            key={alertId}
                                            value={`${alertType}/${alertId}`}
                                        >
                                            <SelectOptionContent>
                                                <AlertText {...alert} />
                                            </SelectOptionContent>
                                        </SelectOption>
                                    );
                                })}
                            </SelectGroup>
                        );
                    })}
                </StyledSelectInput>
            </FormElement>
        );
    }
}

const AlertListPropType = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
).isRequired;

AlertSubscriptionsEditor.propTypes = {
    name: PropTypes.string,
    alerts: PropTypes.shape({
        metric: AlertListPropType,
        process_state_duration: AlertListPropType,
        process_state_target_time: AlertListPropType,
    }).isRequired,
    subscriptions: PropTypes.shape({
        metric: PropTypes.arrayOf(PropTypes.number).isRequired,
        process_state_duration: PropTypes.arrayOf(PropTypes.number).isRequired,
        process_state_target_time: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    allowEdit: PropTypes.bool.isRequired,
};
