
export const channel_schema = {
  'calendar_day': {
    'display_name': 'calendar_day_channel_literal',
    'name': 'calendar_day',
    'projecting_base': 'production_metric'
  },
  'diagnostic': {
    'annotations': {
      'message': {
        'display_name': 'term.message.name',
        'help_text': 'term.message.short',
        'name': 'message',
        'rollup': true,
        'scoreboard_name': 'term.message.scoreboard_name'
      }
    },
    'category_values': {
      'diagnostic_event_type': {
        'category': 'diagnostic_event_type',
        'display_name': 'term.diagnostic_event_type.name',
        'help_text': 'term.diagnostic_event_type.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'diagnostic_event_type',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.diagnostic_event_type.scoreboard_name'
      },
      'error_category': {
        'category': 'error_category',
        'display_name': 'term.error_category.name',
        'help_text': 'term.error_category.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'error_category',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.error_category.scoreboard_name'
      }
    },
    'display_name': 'diagnostic_channel_literal',
    'fragmenting_parent': 'production_metric',
    'metrics': {
      'diagnostic_event_id': {
        'classification': 'miscellaneous',
        'display_name': 'term.diagnostic_event_id.name',
        'help_text': 'term.diagnostic_event_id.short',
        'name': 'diagnostic_event_id',
        'rollup': true,
        'scoreboard_name': 'term.diagnostic_event_id.scoreboard_name',
        'type': 'uint64'
      },
      'event_id': {
        'display_name': 'term.event_id.name',
        'help_text': 'term.event_id.short',
        'indexed': true,
        'internal': true,
        'name': 'event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.event_id.scoreboard_name',
        'type': 'id'
      },
      'production_day_number': {
        'aggregation': 'none',
        'display_name': 'term.production_day_number.name',
        'help_text': 'term.production_day_number.short',
        'internal': true,
        'name': 'production_day_number',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_day_number.scoreboard_name',
        'type': 'uint64'
      },
      'production_metric_parent_event_id': {
        'display_name': 'term.production_metric_parent_event_id.name',
        'help_text': 'term.production_metric_parent_event_id.short',
        'name': 'production_metric_parent_event_id',
        'persist': true,
        'reference': true,
        'rollup': true,
        'scoreboard_name': 'term.production_metric_parent_event_id.scoreboard_name',
        'type': 'id'
      },
      'record_id': {
        'aggregation': 'max',
        'display_name': 'term.record_id.name',
        'help_text': 'term.record_id.short',
        'name': 'record_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.record_id.scoreboard_name',
        'type': 'id'
      },
      'record_order': {
        'aggregation': 'min',
        'display_name': 'term.record_order.name',
        'help_text': 'term.record_order.short',
        'indexed': true,
        'internal': true,
        'name': 'record_order',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.record_order.scoreboard_name',
        'type': 'id'
      },
      'slice_order': {
        'display_name': 'term.slice_order.name',
        'help_text': 'term.slice_order.short',
        'indexed': true,
        'internal': true,
        'name': 'slice_order',
        'persist': true,
        'reference': true,
        'rollup': true,
        'scoreboard_name': 'term.slice_order.scoreboard_name',
        'type': 'id'
      },
      'sync_id': {
        'aggregation': 'sync_id',
        'display_name': 'term.sync_id.name',
        'help_text': 'term.sync_id.short',
        'indexed': true,
        'internal': true,
        'name': 'sync_id',
        'rollup': true,
        'scoreboard_name': 'term.sync_id.scoreboard_name',
        'type': 'id'
      },
      'time': {
        'classification': 'miscellaneous',
        'display_name': 'term.time.name',
        'help_text': 'term.time.short',
        'name': 'time',
        'rollup': true,
        'scoreboard_name': 'term.time.scoreboard_name',
        'type': 'date_time'
      }
    },
    'name': 'diagnostic',
    'primary_key': 'record_id'
  },
  'hour': {
    'display_name': 'hour_channel_literal',
    'name': 'hour',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'job': {
    'display_name': 'job_channel_literal',
    'name': 'job',
    'projecting_base': 'production_metric'
  },
  'month': {
    'display_name': 'month_channel_literal',
    'name': 'month',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'ordinal_shift': {
    'display_name': 'ordinal_shift_channel_literal',
    'name': 'ordinal_shift',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'part': {
    'display_name': 'part_channel_literal',
    'name': 'part',
    'projecting_base': 'production_metric'
  },
  'process_state': {
    'display_name': 'process_state_channel_literal',
    'name': 'process_state',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'production_day': {
    'display_name': 'production_day_channel_literal',
    'name': 'production_day',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'production_metric': {
    'annotations': {
      'job_display_name': {
        'display_name': 'term.job_display_name.name',
        'help_text': 'term.job_display_name.short',
        'name': 'job_display_name',
        'scoreboard_name': 'term.job_display_name.scoreboard_name'
      },
      'part_display_name': {
        'display_name': 'term.part_display_name.name',
        'help_text': 'term.part_display_name.short',
        'name': 'part_display_name',
        'scoreboard_name': 'term.part_display_name.scoreboard_name'
      },
      'process_state_reason_display_name': {
        'display_name': 'term.process_state_reason_display_name.name',
        'help_text': 'term.process_state_reason_display_name.short',
        'name': 'process_state_reason_display_name',
        'scoreboard_name': 'term.process_state_reason_display_name.scoreboard_name'
      },
      'shift_display_name': {
        'display_name': 'term.shift_display_name.name',
        'help_text': 'term.shift_display_name.short',
        'name': 'shift_display_name',
        'scoreboard_name': 'term.shift_display_name.scoreboard_name'
      },
      'team_display_name': {
        'display_name': 'term.team_display_name.name',
        'help_text': 'term.team_display_name.short',
        'name': 'team_display_name',
        'scoreboard_name': 'term.team_display_name.scoreboard_name'
      }
    },
    'category_values': {
      'calendar_day': {
        'category': 'calendar_day',
        'display_name': 'term.calendar_day.name',
        'help_text': 'term.calendar_day.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'calendar_day',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.calendar_day.scoreboard_name'
      },
      'hour': {
        'category': 'hour',
        'display_name': 'term.hour.name',
        'help_text': 'term.hour.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'hour',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.hour.scoreboard_name'
      },
      'information_source': {
        'category': 'information_source',
        'display_name': 'term.information_source.name',
        'help_text': 'term.information_source.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'information_source',
        'persist': true,
        'projecting_source': 'process_state',
        'rollup': true,
        'scoreboard_name': 'term.information_source.scoreboard_name'
      },
      'job': {
        'category': 'job',
        'display_name': 'term.job.name',
        'help_text': 'term.job.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'job',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.job.scoreboard_name'
      },
      'month': {
        'category': 'month',
        'display_name': 'term.month.name',
        'help_text': 'term.month.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'month',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.month.scoreboard_name'
      },
      'ordinal_shift': {
        'category': 'ordinal_shift',
        'display_name': 'term.ordinal_shift.name',
        'help_text': 'term.ordinal_shift.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'ordinal_shift',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.ordinal_shift.scoreboard_name'
      },
      'part': {
        'category': 'part',
        'display_name': 'term.part.name',
        'help_text': 'term.part.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'part',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.part.scoreboard_name'
      },
      'performance_impact': {
        'category': 'performance_impact',
        'display_name': 'term.performance_impact.name',
        'help_text': 'term.performance_impact.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'performance_impact',
        'persist': true,
        'projecting_source': 'process_state',
        'rollup': true,
        'scoreboard_name': 'term.performance_impact.scoreboard_name'
      },
      'process_state': {
        'category': 'process_state',
        'display_name': 'term.process_state.name',
        'help_text': 'term.process_state.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'process_state',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.process_state.scoreboard_name'
      },
      'process_state_reason': {
        'category': 'process_state_reason',
        'display_name': 'term.process_state_reason.name',
        'help_text': 'term.process_state_reason.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'process_state_reason',
        'persist': true,
        'projecting_source': 'process_state',
        'rollup': true,
        'scoreboard_name': 'term.process_state_reason.scoreboard_name'
      },
      'production_day': {
        'category': 'production_day',
        'display_name': 'term.production_day.name',
        'help_text': 'term.production_day.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'production_day',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_day.scoreboard_name'
      },
      'production_phase': {
        'category': 'production_phase',
        'display_name': 'term.production_phase.name',
        'help_text': 'term.production_phase.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'production_phase',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_phase.scoreboard_name'
      },
      'quarter': {
        'category': 'quarter',
        'display_name': 'term.quarter.name',
        'help_text': 'term.quarter.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'quarter',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.quarter.scoreboard_name'
      },
      'shift': {
        'category': 'shift',
        'display_name': 'term.shift.name',
        'help_text': 'term.shift.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'shift',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.shift.scoreboard_name'
      },
      'shift_hour': {
        'category': 'shift_hour',
        'display_name': 'term.shift_hour.name',
        'help_text': 'term.shift_hour.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'shift_hour',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.shift_hour.scoreboard_name'
      },
      'team': {
        'category': 'team',
        'display_name': 'term.team.name',
        'help_text': 'term.team.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'team',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.team.scoreboard_name'
      },
      'week': {
        'category': 'week',
        'display_name': 'term.week.name',
        'help_text': 'term.week.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'week',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.week.scoreboard_name'
      },
      'year': {
        'category': 'year',
        'display_name': 'term.year.name',
        'help_text': 'term.year.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'year',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.year.scoreboard_name'
      }
    },
    'display_name': 'production_metric_channel_literal',
    'is_slicing_parent': true,
    'metrics': {
      'all_time': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.all_time.name',
        'formula': 'production_time + not_scheduled_time',
        'help_text': 'term.all_time.short',
        'name': 'all_time',
        'rollup': true,
        'scoreboard_name': 'term.all_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'availability': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.availability.name',
        'formula': 'run_time / production_time',
        'help_text': 'term.availability.short',
        'increasing_is': 'good',
        'name': 'availability',
        'rollup': true,
        'scoreboard_name': 'term.availability.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'availability_loss': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.availability_loss.name',
        'formula': '1 - availability',
        'help_text': 'term.availability_loss.short',
        'increasing_is': 'bad',
        'name': 'availability_loss',
        'rollup': true,
        'scoreboard_name': 'term.availability_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'availability_lost_time': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.availability_lost_time.name',
        'formula': 'availability_loss * production_time',
        'help_text': 'term.availability_lost_time.short',
        'increasing_is': 'bad',
        'name': 'availability_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.availability_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'availability_or_zero': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.availability_or_zero.name',
        'formula': 'zero_null(availability)',
        'help_text': 'term.availability_or_zero.short',
        'increasing_is': 'good',
        'internal': true,
        'name': 'availability_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.availability_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'average_cycle_time': {
        'aggregation': 'none',
        'classification': 'cycles',
        'display_name': 'term.average_cycle_time.name',
        'formula': 'total_cycle_time / total_cycles',
        'help_text': 'term.average_cycle_time.short',
        'increasing_is': 'bad',
        'name': 'average_cycle_time',
        'rollup': true,
        'scoreboard_name': 'term.average_cycle_time.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'average_ideal_cycle_time': {
        'aggregation': 'none',
        'classification': 'cycles',
        'display_name': 'term.average_ideal_cycle_time.name',
        'formula': 'ideal_time / total_cycles',
        'help_text': 'term.average_ideal_cycle_time.short',
        'name': 'average_ideal_cycle_time',
        'rollup': true,
        'scoreboard_name': 'term.average_ideal_cycle_time.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'average_run_cycle_time': {
        'aggregation': 'none',
        'classification': 'cycles',
        'display_name': 'term.average_run_cycle_time.name',
        'formula': '(ideal_time / (partial_cycles + run_cycles + small_stops)) + (run_cycle_lost_time / run_cycles)',
        'help_text': 'term.average_run_cycle_time.short',
        'name': 'average_run_cycle_time',
        'rollup': true,
        'scoreboard_name': 'term.average_run_cycle_time.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'average_small_stop_time': {
        'aggregation': 'none',
        'classification': 'cycles',
        'display_name': 'term.average_small_stop_time.name',
        'formula': '(ideal_time / (partial_cycles + run_cycles + small_stops)) + (small_stop_lost_time / small_stops)',
        'help_text': 'term.average_small_stop_time.short',
        'increasing_is': 'bad',
        'name': 'average_small_stop_time',
        'rollup': true,
        'scoreboard_name': 'term.average_small_stop_time.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'average_takt_time': {
        'aggregation': 'none',
        'classification': 'target',
        'display_name': 'term.average_takt_time.name',
        'formula': 'manufacturing_time / target_cycles',
        'help_text': 'term.average_takt_time.short',
        'name': 'average_takt_time',
        'rollup': true,
        'scoreboard_name': 'term.average_takt_time.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'average_team_size': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.average_team_size.name',
        'formula': 'labor / production_time',
        'help_text': 'term.average_team_size.short',
        'name': 'average_team_size',
        'rollup': true,
        'scoreboard_name': 'term.average_team_size.scoreboard_name',
        'translation_arguments': {},
        'type': 'double'
      },
      'calendar_day_event_id': {
        'display_name': 'term.calendar_day_event_id.name',
        'help_text': 'term.calendar_day_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'calendar_day_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.calendar_day_event_id.scoreboard_name',
        'type': 'id'
      },
      'calendar_day_number': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.calendar_day_number.name',
        'help_text': 'term.calendar_day_number.short',
        'indexed': true,
        'internal': true,
        'name': 'calendar_day_number',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.calendar_day_number.scoreboard_name',
        'type': 'uint64'
      },
      'capacity_loss': {
        'aggregation': 'none',
        'classification': 'teep',
        'display_name': 'term.capacity_loss.name',
        'formula': '1 - utilization',
        'help_text': 'term.capacity_loss.short',
        'increasing_is': 'bad',
        'name': 'capacity_loss',
        'rollup': true,
        'scoreboard_name': 'term.capacity_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'capacity_lost_time': {
        'aggregation': 'none',
        'classification': 'teep',
        'display_name': 'term.capacity_lost_time.name',
        'formula': 'capacity_loss * all_time',
        'help_text': 'term.capacity_lost_time.short',
        'increasing_is': 'bad',
        'name': 'capacity_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.capacity_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'count_variance': {
        'aggregation': 'none',
        'classification': 'target',
        'display_name': 'term.count_variance.name',
        'formula': 'good_count - target_count',
        'help_text': 'term.count_variance.short',
        'name': 'count_variance',
        'rollup': true,
        'scoreboard_name': 'term.count_variance.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {},
        'type': 'double'
      },
      'current_cycle_time': {
        'classification': 'cycles',
        'display_name': 'term.current_cycle_time.name',
        'ephemeral': true,
        'help_text': 'term.current_cycle_time.short',
        'name': 'current_cycle_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.current_cycle_time.scoreboard_name',
        'subtype': 'cycle_time',
        'type': 'time_span'
      },
      'current_good_rate': {
        'classification': 'rates',
        'display_name': 'term.current_good_rate.name',
        'ephemeral': true,
        'help_text': 'term.current_good_rate.short',
        'name': 'current_good_rate',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_good_rate.scoreboard_name',
        'subtype': 'rate',
        'type': 'double'
      },
      'current_good_rate_per_hour': {
        'classification': 'rates',
        'display_name': 'term.current_good_rate_per_hour.name',
        'ephemeral': true,
        'help_text': 'term.current_good_rate_per_hour.short',
        'name': 'current_good_rate_per_hour',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_good_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'type': 'double'
      },
      'current_in_rate': {
        'classification': 'rates',
        'display_name': 'term.current_in_rate.name',
        'ephemeral': true,
        'help_text': 'term.current_in_rate.short',
        'name': 'current_in_rate',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_in_rate.scoreboard_name',
        'subtype': 'rate',
        'type': 'double'
      },
      'current_in_rate_per_hour': {
        'classification': 'rates',
        'display_name': 'term.current_in_rate_per_hour.name',
        'ephemeral': true,
        'help_text': 'term.current_in_rate_per_hour.short',
        'name': 'current_in_rate_per_hour',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_in_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'type': 'double'
      },
      'current_pack_out_rate': {
        'classification': 'rates',
        'display_name': 'term.current_pack_out_rate.name',
        'ephemeral': true,
        'help_text': 'term.current_pack_out_rate.short',
        'name': 'current_pack_out_rate',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_pack_out_rate.scoreboard_name',
        'subtype': 'rate',
        'type': 'double'
      },
      'current_pack_out_rate_per_hour': {
        'classification': 'rates',
        'display_name': 'term.current_pack_out_rate_per_hour.name',
        'ephemeral': true,
        'help_text': 'term.current_pack_out_rate_per_hour.short',
        'name': 'current_pack_out_rate_per_hour',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_pack_out_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'type': 'double'
      },
      'current_reject_rate': {
        'classification': 'rates',
        'display_name': 'term.current_reject_rate.name',
        'ephemeral': true,
        'help_text': 'term.current_reject_rate.short',
        'name': 'current_reject_rate',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_reject_rate.scoreboard_name',
        'subtype': 'rate',
        'type': 'double'
      },
      'current_reject_rate_per_hour': {
        'classification': 'rates',
        'display_name': 'term.current_reject_rate_per_hour.name',
        'ephemeral': true,
        'help_text': 'term.current_reject_rate_per_hour.short',
        'name': 'current_reject_rate_per_hour',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_reject_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'type': 'double'
      },
      'current_target_labor_per_piece': {
        'classification': 'labor',
        'display_name': 'term.current_target_labor_per_piece.name',
        'ephemeral': true,
        'help_text': 'term.current_target_labor_per_piece.short',
        'name': 'current_target_labor_per_piece',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.current_target_labor_per_piece.scoreboard_name',
        'subtype': 'cycle_time',
        'type': 'time_span'
      },
      'current_total_rate': {
        'classification': 'rates',
        'display_name': 'term.current_total_rate.name',
        'ephemeral': true,
        'help_text': 'term.current_total_rate.short',
        'name': 'current_total_rate',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_total_rate.scoreboard_name',
        'subtype': 'rate',
        'type': 'double'
      },
      'current_total_rate_per_hour': {
        'classification': 'rates',
        'display_name': 'term.current_total_rate_per_hour.name',
        'ephemeral': true,
        'help_text': 'term.current_total_rate_per_hour.short',
        'name': 'current_total_rate_per_hour',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.current_total_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'type': 'double'
      },
      'cycle_loss': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.cycle_loss.name',
        'formula': 'cycle_lost_time / production_time',
        'help_text': 'term.cycle_loss.short',
        'increasing_is': 'bad',
        'name': 'cycle_loss',
        'rollup': true,
        'scoreboard_name': 'term.cycle_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'cycle_lost_time': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.cycle_lost_time.name',
        'formula': 'run_cycle_lost_time + partial_cycle_lost_time',
        'help_text': 'term.cycle_lost_time.short',
        'increasing_is': 'bad',
        'name': 'cycle_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.cycle_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'down_loss': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.down_loss.name',
        'formula': 'unplanned_stop_loss',
        'help_text': 'term.down_loss.short',
        'increasing_is': 'bad',
        'name': 'down_loss',
        'rollup': true,
        'scoreboard_name': 'term.down_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'down_lost_time': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.down_lost_time.name',
        'formula': 'unplanned_stop_lost_time',
        'help_text': 'term.down_lost_time.short',
        'increasing_is': 'bad',
        'name': 'down_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.down_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'down_time': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.down_time.name',
        'formula': 'unplanned_stop_time',
        'help_text': 'term.down_time.short',
        'increasing_is': 'bad',
        'name': 'down_time',
        'rollup': true,
        'scoreboard_name': 'term.down_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'duration': {
        'classification': 'production_times',
        'display_name': 'term.duration.name',
        'help_text': 'term.duration.short',
        'name': 'duration',
        'rollup': true,
        'scoreboard_name': 'term.duration.scoreboard_name',
        'type': 'time_span'
      },
      'earned_labor': {
        'classification': 'labor',
        'display_name': 'term.earned_labor.name',
        'help_text': 'term.earned_labor.short',
        'name': 'earned_labor',
        'rollup': true,
        'scoreboard_name': 'term.earned_labor.scoreboard_name',
        'type': 'time_span'
      },
      'efficiency': {
        'aggregation': 'none',
        'classification': 'target',
        'display_name': 'term.efficiency.name',
        'formula': 'good_count / target_count',
        'help_text': 'term.efficiency.short',
        'increasing_is': 'good',
        'name': 'efficiency',
        'rollup': true,
        'scoreboard_name': 'term.efficiency.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'elapsed_time': {
        'classification': 'production_times',
        'display_name': 'term.elapsed_time.name',
        'ephemeral': true,
        'help_text': 'term.elapsed_time.short',
        'name': 'elapsed_time',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.elapsed_time.scoreboard_name',
        'type': 'time_span'
      },
      'end_time': {
        'aggregation': 'datetime_max',
        'classification': 'production_times',
        'display_name': 'term.end_time.name',
        'help_text': 'term.end_time.short',
        'name': 'end_time',
        'persist': true,
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.end_time.scoreboard_name',
        'type': 'date_time'
      },
      'equipment_cycles': {
        'classification': 'cycles',
        'display_name': 'term.equipment_cycles.name',
        'help_text': 'term.equipment_cycles.short',
        'name': 'equipment_cycles',
        'rollup': true,
        'scoreboard_name': 'term.equipment_cycles.scoreboard_name',
        'type': 'uint64'
      },
      'event_efficiency': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.event_efficiency.name',
        'formula': 'event_target / duration',
        'help_text': 'term.event_efficiency.short',
        'increasing_is': 'good',
        'internal': true,
        'name': 'event_efficiency',
        'rollup': true,
        'scoreboard_name': 'term.event_efficiency.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'event_id': {
        'display_name': 'term.event_id.name',
        'help_text': 'term.event_id.short',
        'indexed': true,
        'internal': true,
        'name': 'event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.event_id.scoreboard_name',
        'type': 'id'
      },
      'event_target': {
        'classification': 'production_times',
        'display_name': 'term.event_target.name',
        'help_text': 'term.event_target.short',
        'internal': true,
        'name': 'event_target',
        'rollup': true,
        'scoreboard_name': 'term.event_target.scoreboard_name',
        'type': 'time_span'
      },
      'event_time_variance': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.event_time_variance.name',
        'formula': 'event_target - duration',
        'help_text': 'term.event_time_variance.short',
        'internal': true,
        'name': 'event_time_variance',
        'rollup': true,
        'scoreboard_name': 'term.event_time_variance.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'event_variance': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.event_variance.name',
        'formula': '(event_target - duration) / event_target',
        'help_text': 'term.event_variance.short',
        'internal': true,
        'name': 'event_variance',
        'rollup': true,
        'scoreboard_name': 'term.event_variance.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'fully_productive_time': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.fully_productive_time.name',
        'formula': 'ideal_time * (1 - zero_null(percent_reject))',
        'help_text': 'term.fully_productive_time.short',
        'increasing_is': 'good',
        'name': 'fully_productive_time',
        'rollup': true,
        'scoreboard_name': 'term.fully_productive_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'goal_count': {
        'classification': 'planning',
        'display_name': 'term.goal_count.name',
        'ephemeral': true,
        'help_text': 'term.goal_count.short',
        'name': 'goal_count',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.goal_count.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'count': {
        'comment': 'This is added to allow email templates to have a special count column that combines changeover and down events',
        'classification': 'counts',
        'display_name': 'config.counts.displayKey',
        'name': 'count',
        'rollup': true,
        'subtype': 'count',
        'type': 'double'
      },
      'good_count': {
        'classification': 'counts',
        'display_name': 'term.good_count.name',
        'help_text': 'term.good_count.short',
        'name': 'good_count',
        'rollup': true,
        'scoreboard_name': 'term.good_count.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'good_pieces_left': {
        'classification': 'target',
        'display_name': 'term.good_pieces_left.name',
        'ephemeral': true,
        'help_text': 'term.good_pieces_left.short',
        'name': 'good_pieces_left',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.good_pieces_left.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'good_pieces_per_labor_hour': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.good_pieces_per_labor_hour.name',
        'formula': 'good_count / (labor / 3600)',
        'help_text': 'term.good_pieces_per_labor_hour.short',
        'increasing_is': 'good',
        'name': 'good_pieces_per_labor_hour',
        'rollup': true,
        'scoreboard_name': 'term.good_pieces_per_labor_hour.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'good_rate': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.good_rate.name',
        'formula': 'good_count / manufacturing_time * 60',
        'help_text': 'term.good_rate.short',
        'name': 'good_rate',
        'rollup': true,
        'scoreboard_name': 'term.good_rate.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'good_rate_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.good_rate_per_hour.name',
        'formula': 'good_count / manufacturing_time * 3600',
        'help_text': 'term.good_rate_per_hour.short',
        'name': 'good_rate_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.good_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'good_speed': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.good_speed.name',
        'formula': 'good_count / run_time * 60',
        'help_text': 'term.good_speed.short',
        'name': 'good_speed',
        'rollup': true,
        'scoreboard_name': 'term.good_speed.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'good_speed_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.good_speed_per_hour.name',
        'formula': 'good_count / run_time * 3600',
        'help_text': 'term.good_speed_per_hour.short',
        'name': 'good_speed_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.good_speed_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'hidden_factory_time': {
        'aggregation': 'none',
        'classification': 'teep',
        'display_name': 'term.hidden_factory_time.name',
        'formula': 'capacity_lost_time + oee_lost_time',
        'help_text': 'term.hidden_factory_time.short',
        'increasing_is': 'bad',
        'name': 'hidden_factory_time',
        'rollup': true,
        'scoreboard_name': 'term.hidden_factory_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'hour_event_id': {
        'display_name': 'term.hour_event_id.name',
        'help_text': 'term.hour_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'hour_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.hour_event_id.scoreboard_name',
        'type': 'id'
      },
      'ideal_cycle_time': {
        'classification': 'cycles',
        'display_name': 'term.ideal_cycle_time.name',
        'ephemeral': true,
        'help_text': 'term.ideal_cycle_time.short',
        'name': 'ideal_cycle_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.ideal_cycle_time.scoreboard_name',
        'subtype': 'cycle_time',
        'type': 'time_span'
      },
      'ideal_time': {
        'classification': 'cycles',
        'display_name': 'term.ideal_time.name',
        'help_text': 'term.ideal_time.short',
        'name': 'ideal_time',
        'rollup': true,
        'scoreboard_name': 'term.ideal_time.scoreboard_name',
        'type': 'time_span'
      },
      'in_count': {
        'classification': 'counts',
        'display_name': 'term.in_count.name',
        'help_text': 'term.in_count.short',
        'name': 'in_count',
        'rollup': true,
        'scoreboard_name': 'term.in_count.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'in_rate': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.in_rate.name',
        'formula': 'in_count / manufacturing_time * 60',
        'help_text': 'term.in_rate.short',
        'name': 'in_rate',
        'rollup': true,
        'scoreboard_name': 'term.in_rate.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'in_rate_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.in_rate_per_hour.name',
        'formula': 'in_count / manufacturing_time * 3600',
        'help_text': 'term.in_rate_per_hour.short',
        'name': 'in_rate_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.in_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'in_speed': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.in_speed.name',
        'formula': 'in_count / run_time * 60',
        'help_text': 'term.in_speed.short',
        'name': 'in_speed',
        'rollup': true,
        'scoreboard_name': 'term.in_speed.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'in_speed_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.in_speed_per_hour.name',
        'formula': 'in_count / run_time * 3600',
        'help_text': 'term.in_speed_per_hour.short',
        'name': 'in_speed_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.in_speed_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'job_event_id': {
        'display_name': 'term.job_event_id.name',
        'help_text': 'term.job_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'job_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.job_event_id.scoreboard_name',
        'type': 'id'
      },
      'job_null_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'job_null_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Job',
          'category_value': 'No Job'
        },
        'type': 'id'
      },
      'job_null_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'job_null_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Job',
          'category_value': 'No Job'
        },
        'type': 'double'
      },
      'job_occurrences': {
        'display_name': 'term.job_occurrences.name',
        'help_text': 'term.job_occurrences.short',
        'internal': true,
        'name': 'job_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.job_occurrences.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Job'
        },
        'type': 'double'
      },
      'job_unknown_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'job_unknown_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Job',
          'category_value': 'Unknown Job'
        },
        'type': 'id'
      },
      'job_unknown_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'job_unknown_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Job',
          'category_value': 'Unknown Job'
        },
        'type': 'double'
      },
      'labor': {
        'classification': 'labor',
        'display_name': 'term.labor.name',
        'help_text': 'term.labor.short',
        'name': 'labor',
        'rollup': true,
        'scoreboard_name': 'term.labor.scoreboard_name',
        'type': 'time_span'
      },
      'labor_efficiency': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.labor_efficiency.name',
        'formula': 'earned_labor / labor',
        'help_text': 'term.labor_efficiency.short',
        'increasing_is': 'good',
        'name': 'labor_efficiency',
        'rollup': true,
        'scoreboard_name': 'term.labor_efficiency.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'labor_per_good_piece': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.labor_per_good_piece.name',
        'formula': 'labor / good_count',
        'help_text': 'term.labor_per_good_piece.short',
        'increasing_is': 'bad',
        'name': 'labor_per_good_piece',
        'rollup': true,
        'scoreboard_name': 'term.labor_per_good_piece.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'labor_per_piece': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.labor_per_piece.name',
        'formula': 'labor / total_count',
        'help_text': 'term.labor_per_piece.short',
        'increasing_is': 'bad',
        'name': 'labor_per_piece',
        'rollup': true,
        'scoreboard_name': 'term.labor_per_piece.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'last_down_time': {
        'classification': 'production_times',
        'display_name': 'term.last_down_time.name',
        'ephemeral': true,
        'help_text': 'term.last_down_time.short',
        'name': 'last_down_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.last_down_time.scoreboard_name',
        'type': 'time_span'
      },
      'last_not_scheduled_time': {
        'classification': 'production_times',
        'display_name': 'term.last_not_scheduled_time.name',
        'ephemeral': true,
        'help_text': 'term.last_not_scheduled_time.short',
        'name': 'last_not_scheduled_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.last_not_scheduled_time.scoreboard_name',
        'type': 'time_span'
      },
      'last_planned_stop_time': {
        'classification': 'production_times',
        'display_name': 'term.last_planned_stop_time.name',
        'ephemeral': true,
        'help_text': 'term.last_planned_stop_time.short',
        'name': 'last_planned_stop_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.last_planned_stop_time.scoreboard_name',
        'type': 'time_span'
      },
      'last_run_time': {
        'classification': 'production_times',
        'display_name': 'term.last_run_time.name',
        'ephemeral': true,
        'help_text': 'term.last_run_time.short',
        'name': 'last_run_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.last_run_time.scoreboard_name',
        'type': 'time_span'
      },
      'last_unplanned_stop_time': {
        'classification': 'production_times',
        'display_name': 'term.last_unplanned_stop_time.name',
        'ephemeral': true,
        'help_text': 'term.last_unplanned_stop_time.short',
        'name': 'last_unplanned_stop_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.last_unplanned_stop_time.scoreboard_name',
        'type': 'time_span'
      },
      'lost_labor': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.lost_labor.name',
        'formula': 'labor - earned_labor',
        'help_text': 'term.lost_labor.short',
        'name': 'lost_labor',
        'rollup': true,
        'scoreboard_name': 'term.lost_labor.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'manufacturing_time': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.manufacturing_time.name',
        'formula': 'run_time + unplanned_stop_time',
        'help_text': 'term.manufacturing_time.short',
        'name': 'manufacturing_time',
        'rollup': true,
        'scoreboard_name': 'term.manufacturing_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'max_slice_order': {
        'aggregation': 'none',
        'display_name': 'term.max_slice_order.name',
        'help_text': 'term.max_slice_order.short',
        'internal': true,
        'name': 'max_slice_order',
        'rollup': false,
        'scoreboard': false,
        'scoreboard_name': 'term.max_slice_order.scoreboard_name',
        'translation_arguments': {},
        'type': 'uint64'
      },
      'min_slice_order': {
        'aggregation': 'none',
        'display_name': 'term.min_slice_order.name',
        'help_text': 'term.min_slice_order.short',
        'internal': true,
        'name': 'min_slice_order',
        'rollup': false,
        'scoreboard': false,
        'scoreboard_name': 'term.min_slice_order.scoreboard_name',
        'translation_arguments': {},
        'type': 'uint64'
      },
      'month_event_id': {
        'display_name': 'term.month_event_id.name',
        'help_text': 'term.month_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'month_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.month_event_id.scoreboard_name',
        'type': 'id'
      },
      'mtbf': {
        'aggregation': 'none',
        'classification': 'mean_times',
        'display_name': 'term.mtbf.name',
        'formula': 'run_time / process_state_down_occurrences',
        'help_text': 'term.mtbf.short',
        'increasing_is': 'good',
        'name': 'mtbf',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.mtbf.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'mttr': {
        'aggregation': 'none',
        'classification': 'mean_times',
        'display_name': 'term.mttr.name',
        'formula': 'unplanned_stop_time / process_state_down_occurrences',
        'help_text': 'term.mttr.short',
        'increasing_is': 'bad',
        'name': 'mttr',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.mttr.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'not_scheduled_time': {
        'classification': 'production_times',
        'display_name': 'term.not_scheduled_time.name',
        'help_text': 'term.not_scheduled_time.short',
        'name': 'not_scheduled_time',
        'rollup': true,
        'scoreboard_name': 'term.not_scheduled_time.scoreboard_name',
        'type': 'time_span'
      },
      'oee': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.oee.name',
        'formula': 'availability * performance_or_zero * quality_or_zero',
        'help_text': 'term.oee.short',
        'increasing_is': 'good',
        'name': 'oee',
        'rollup': true,
        'scoreboard_name': 'term.oee.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'oee_loss': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.oee_loss.name',
        'formula': '1 - oee',
        'help_text': 'term.oee_loss.short',
        'increasing_is': 'bad',
        'name': 'oee_loss',
        'rollup': true,
        'scoreboard_name': 'term.oee_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'oee_lost_time': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.oee_lost_time.name',
        'formula': 'production_time - fully_productive_time',
        'help_text': 'term.oee_lost_time.short',
        'increasing_is': 'bad',
        'name': 'oee_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.oee_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'ordinal_shift_event_id': {
        'display_name': 'term.ordinal_shift_event_id.name',
        'help_text': 'term.ordinal_shift_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'ordinal_shift_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.ordinal_shift_event_id.scoreboard_name',
        'type': 'id'
      },
      'pace_timer': {
        'classification': 'target',
        'display_name': 'term.pace_timer.name',
        'ephemeral': true,
        'help_text': 'term.pace_timer.short',
        'name': 'pace_timer',
        'rollup': false,
        'scoreboard_name': 'term.pace_timer.scoreboard_name',
        'type': 'time_span'
      },
      'pack_out_count': {
        'classification': 'counts',
        'display_name': 'term.pack_out_count.name',
        'help_text': 'term.pack_out_count.short',
        'name': 'pack_out_count',
        'rollup': true,
        'scoreboard_name': 'term.pack_out_count.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'pack_out_rate': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.pack_out_rate.name',
        'formula': 'pack_out_count / manufacturing_time * 60',
        'help_text': 'term.pack_out_rate.short',
        'name': 'pack_out_rate',
        'rollup': true,
        'scoreboard_name': 'term.pack_out_rate.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'pack_out_rate_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.pack_out_rate_per_hour.name',
        'formula': 'pack_out_count / manufacturing_time * 3600',
        'help_text': 'term.pack_out_rate_per_hour.short',
        'name': 'pack_out_rate_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.pack_out_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'pack_out_speed': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.pack_out_speed.name',
        'formula': 'pack_out_count / run_time * 60',
        'help_text': 'term.pack_out_speed.short',
        'name': 'pack_out_speed',
        'rollup': true,
        'scoreboard_name': 'term.pack_out_speed.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'pack_out_speed_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.pack_out_speed_per_hour.name',
        'formula': 'pack_out_count / run_time * 3600',
        'help_text': 'term.pack_out_speed_per_hour.short',
        'name': 'pack_out_speed_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.pack_out_speed_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'part_event_id': {
        'display_name': 'term.part_event_id.name',
        'help_text': 'term.part_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'part_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.part_event_id.scoreboard_name',
        'type': 'id'
      },
      'part_null_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'part_null_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Part',
          'category_value': 'No Part'
        },
        'type': 'id'
      },
      'part_null_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'part_null_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Part',
          'category_value': 'No Part'
        },
        'type': 'double'
      },
      'part_occurrences': {
        'display_name': 'term.part_occurrences.name',
        'help_text': 'term.part_occurrences.short',
        'internal': true,
        'name': 'part_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.part_occurrences.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Part'
        },
        'type': 'double'
      },
      'part_sample_part_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'part_sample_part_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Part',
          'category_value': 'Sample Part'
        },
        'type': 'id'
      },
      'part_sample_part_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'part_sample_part_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Part',
          'category_value': 'Sample Part'
        },
        'type': 'double'
      },
      'part_unknown_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'part_unknown_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Part',
          'category_value': 'Unknown Part'
        },
        'type': 'id'
      },
      'part_unknown_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'part_unknown_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Part',
          'category_value': 'Unknown Part'
        },
        'type': 'double'
      },
      'partial_cycle_lost_time': {
        'classification': 'cycles',
        'display_name': 'term.partial_cycle_lost_time.name',
        'help_text': 'term.partial_cycle_lost_time.short',
        'name': 'partial_cycle_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.partial_cycle_lost_time.scoreboard_name',
        'type': 'time_span'
      },
      'partial_cycles': {
        'classification': 'cycles',
        'display_name': 'term.partial_cycles.name',
        'help_text': 'term.partial_cycles.short',
        'name': 'partial_cycles',
        'rollup': true,
        'scoreboard_name': 'term.partial_cycles.scoreboard_name',
        'subtype': 'cycle',
        'type': 'double'
      },
      'percent_done': {
        'classification': 'planning',
        'display_name': 'term.percent_done.name',
        'ephemeral': true,
        'help_text': 'term.percent_done.short',
        'name': 'percent_done',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.percent_done.scoreboard_name',
        'subtype': 'percent',
        'type': 'double'
      },
      'percent_down': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.percent_down.name',
        'formula': 'percent_unplanned_stop',
        'help_text': 'term.percent_down.short',
        'increasing_is': 'bad',
        'name': 'percent_down',
        'rollup': true,
        'scoreboard_name': 'term.percent_down.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_good': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.percent_good.name',
        'formula': 'good_count / total_count',
        'help_text': 'term.percent_good.short',
        'increasing_is': 'good',
        'name': 'percent_good',
        'rollup': true,
        'scoreboard_name': 'term.percent_good.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_good_or_zero': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.percent_good_or_zero.name',
        'formula': 'zero_null(percent_good)',
        'help_text': 'term.percent_good_or_zero.short',
        'increasing_is': 'good',
        'internal': true,
        'name': 'percent_good_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.percent_good_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_planned_stop': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.percent_planned_stop.name',
        'formula': 'planned_stop_time / production_time',
        'help_text': 'term.percent_planned_stop.short',
        'increasing_is': 'bad',
        'name': 'percent_planned_stop',
        'rollup': true,
        'scoreboard_name': 'term.percent_planned_stop.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_production_rejects_or_zero': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.percent_production_rejects_or_zero.name',
        'formula': 'zero_null(production_rejects / total_count)',
        'help_text': 'term.percent_production_rejects_or_zero.short',
        'increasing_is': 'bad',
        'internal': true,
        'name': 'percent_production_rejects_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.percent_production_rejects_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_reject': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.percent_reject.name',
        'formula': 'reject_count / total_count',
        'help_text': 'term.percent_reject.short',
        'increasing_is': 'bad',
        'name': 'percent_reject',
        'rollup': true,
        'scoreboard_name': 'term.percent_reject.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_run': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.percent_run.name',
        'formula': 'run_time / production_time',
        'help_text': 'term.percent_run.short',
        'increasing_is': 'good',
        'name': 'percent_run',
        'rollup': true,
        'scoreboard_name': 'term.percent_run.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_startup_rejects_or_zero': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.percent_startup_rejects_or_zero.name',
        'formula': 'zero_null(startup_rejects / total_count)',
        'help_text': 'term.percent_startup_rejects_or_zero.short',
        'increasing_is': 'bad',
        'internal': true,
        'name': 'percent_startup_rejects_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.percent_startup_rejects_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_unplanned_stop': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.percent_unplanned_stop.name',
        'formula': 'unplanned_stop_time / production_time',
        'help_text': 'term.percent_unplanned_stop.short',
        'increasing_is': 'bad',
        'name': 'percent_unplanned_stop',
        'rollup': true,
        'scoreboard_name': 'term.percent_unplanned_stop.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'percent_variance': {
        'aggregation': 'none',
        'classification': 'target',
        'display_name': 'term.percent_variance.name',
        'formula': 'count_variance / target_count',
        'help_text': 'term.percent_variance.short',
        'name': 'percent_variance',
        'rollup': true,
        'scoreboard_name': 'term.percent_variance.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'performance': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.performance.name',
        'formula': 'ideal_time / run_time',
        'help_text': 'term.performance.short',
        'increasing_is': 'good',
        'name': 'performance',
        'rollup': true,
        'scoreboard_name': 'term.performance.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'performance_loss': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.performance_loss.name',
        'formula': '(1 - performance) * availability_or_zero',
        'help_text': 'term.performance_loss.short',
        'increasing_is': 'bad',
        'name': 'performance_loss',
        'rollup': true,
        'scoreboard_name': 'term.performance_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'performance_lost_time': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.performance_lost_time.name',
        'formula': 'performance_loss * production_time',
        'help_text': 'term.performance_lost_time.short',
        'increasing_is': 'bad',
        'name': 'performance_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.performance_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'performance_or_zero': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.performance_or_zero.name',
        'formula': 'zero_null(performance)',
        'help_text': 'term.performance_or_zero.short',
        'increasing_is': 'good',
        'internal': true,
        'name': 'performance_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.performance_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'pieces_per_labor_hour': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.pieces_per_labor_hour.name',
        'formula': 'total_count / (labor / 3600)',
        'help_text': 'term.pieces_per_labor_hour.short',
        'increasing_is': 'good',
        'name': 'pieces_per_labor_hour',
        'rollup': true,
        'scoreboard_name': 'term.pieces_per_labor_hour.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'planned_stop_loss': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.planned_stop_loss.name',
        'formula': 'planned_stop_time / production_time',
        'help_text': 'term.planned_stop_loss.short',
        'increasing_is': 'bad',
        'name': 'planned_stop_loss',
        'rollup': true,
        'scoreboard_name': 'term.planned_stop_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'planned_stop_lost_time': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.planned_stop_lost_time.name',
        'formula': 'planned_stop_time',
        'help_text': 'term.planned_stop_lost_time.short',
        'increasing_is': 'bad',
        'name': 'planned_stop_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.planned_stop_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'planned_stop_time': {
        'classification': 'production_times',
        'display_name': 'term.planned_stop_time.name',
        'help_text': 'term.planned_stop_time.short',
        'increasing_is': 'bad',
        'name': 'planned_stop_time',
        'rollup': true,
        'scoreboard_name': 'term.planned_stop_time.scoreboard_name',
        'type': 'time_span'
      },
      'previous_cycle_time': {
        'classification': 'cycles',
        'display_name': 'term.previous_cycle_time.name',
        'ephemeral': true,
        'help_text': 'term.previous_cycle_time.short',
        'name': 'previous_cycle_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.previous_cycle_time.scoreboard_name',
        'subtype': 'cycle_time',
        'type': 'time_span'
      },
      'process_state_break_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_break_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Meal/Break'
        },
        'type': 'id'
      },
      'process_state_break_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_break_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Meal/Break'
        },
        'type': 'double'
      },
      'process_state_changeover_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_changeover_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Changeover'
        },
        'type': 'id'
      },
      'process_state_changeover_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_changeover_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Changeover'
        },
        'type': 'double'
      },
      'process_state_detecting_state_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_detecting_state_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Detecting State'
        },
        'type': 'id'
      },
      'process_state_detecting_state_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_detecting_state_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Detecting State'
        },
        'type': 'double'
      },
      'process_state_down_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_down_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Down'
        },
        'type': 'id'
      },
      'process_state_down_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_down_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Down'
        },
        'type': 'double'
      },
      'process_state_event_id': {
        'display_name': 'term.process_state_event_id.name',
        'help_text': 'term.process_state_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'process_state_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.process_state_event_id.scoreboard_name',
        'type': 'id'
      },
      'process_state_information_source_barcode_scan_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_barcode_scan_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Barcode Scan'
        },
        'type': 'id'
      },
      'process_state_information_source_barcode_scan_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_barcode_scan_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Barcode Scan'
        },
        'type': 'double'
      },
      'process_state_information_source_data_modification_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_data_modification_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Data Modification'
        },
        'type': 'id'
      },
      'process_state_information_source_data_modification_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_data_modification_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Data Modification'
        },
        'type': 'double'
      },
      'process_state_information_source_digital_input_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_digital_input_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Digital Input'
        },
        'type': 'id'
      },
      'process_state_information_source_digital_input_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_digital_input_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Digital Input'
        },
        'type': 'double'
      },
      'process_state_information_source_late_start_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_late_start_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Time Schedule (Late Start)'
        },
        'type': 'id'
      },
      'process_state_information_source_late_start_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_late_start_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Time Schedule (Late Start)'
        },
        'type': 'double'
      },
      'process_state_information_source_part_configuration_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_part_configuration_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Part Configuration'
        },
        'type': 'id'
      },
      'process_state_information_source_part_configuration_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_part_configuration_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Part Configuration'
        },
        'type': 'double'
      },
      'process_state_information_source_run_detector_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_run_detector_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Run Detector'
        },
        'type': 'id'
      },
      'process_state_information_source_run_detector_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_run_detector_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Run Detector'
        },
        'type': 'double'
      },
      'process_state_information_source_time_schedule_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_time_schedule_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Time Schedule'
        },
        'type': 'id'
      },
      'process_state_information_source_time_schedule_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_time_schedule_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Time Schedule'
        },
        'type': 'double'
      },
      'process_state_information_source_timer_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_timer_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Timer'
        },
        'type': 'id'
      },
      'process_state_information_source_timer_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_timer_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Timer'
        },
        'type': 'double'
      },
      'process_state_information_source_web_page_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_web_page_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Web Page'
        },
        'type': 'id'
      },
      'process_state_information_source_web_page_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_information_source_web_page_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source',
          'category_value': 'Web Page'
        },
        'type': 'double'
      },
      'process_state_maintenance_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_maintenance_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Maintenance'
        },
        'type': 'id'
      },
      'process_state_maintenance_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_maintenance_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Maintenance'
        },
        'type': 'double'
      },
      'process_state_meeting_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_meeting_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Meeting'
        },
        'type': 'id'
      },
      'process_state_meeting_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_meeting_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Meeting'
        },
        'type': 'double'
      },
      'process_state_no_production_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_no_production_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'No Production'
        },
        'type': 'id'
      },
      'process_state_no_production_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_no_production_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'No Production'
        },
        'type': 'double'
      },
      'process_state_not_monitored_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_not_monitored_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Not Monitored'
        },
        'type': 'id'
      },
      'process_state_not_monitored_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_not_monitored_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Not Monitored'
        },
        'type': 'double'
      },
      'process_state_occurrences': {
        'display_name': 'term.process_state_occurrences.name',
        'help_text': 'term.process_state_occurrences.short',
        'internal': true,
        'name': 'process_state_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.process_state_occurrences.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Information Source'
        },
        'type': 'double'
      },
      'process_state_performance_impact_not_scheduled_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_not_scheduled_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Not Scheduled'
        },
        'type': 'id'
      },
      'process_state_performance_impact_not_scheduled_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_not_scheduled_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Not Scheduled'
        },
        'type': 'double'
      },
      'process_state_performance_impact_planned_stop_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_planned_stop_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Planned Stop'
        },
        'type': 'id'
      },
      'process_state_performance_impact_planned_stop_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_planned_stop_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Planned Stop'
        },
        'type': 'double'
      },
      'process_state_performance_impact_run_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_run_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Run'
        },
        'type': 'id'
      },
      'process_state_performance_impact_run_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_run_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Run'
        },
        'type': 'double'
      },
      'process_state_performance_impact_unplanned_stop_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_unplanned_stop_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Unplanned Stop'
        },
        'type': 'id'
      },
      'process_state_performance_impact_unplanned_stop_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_performance_impact_unplanned_stop_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Impact',
          'category_value': 'Unplanned Stop'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_5s_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_5s_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': '5S'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_5s_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_5s_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': '5S'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_adjustment_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_adjustment_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Adjustment'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_adjustment_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_adjustment_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Adjustment'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_autonomous_maintenance_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_autonomous_maintenance_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Autonomous Maintenance'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_autonomous_maintenance_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_autonomous_maintenance_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Autonomous Maintenance'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_break_ended_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_break_ended_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Break Ended'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_break_ended_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_break_ended_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Break Ended'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_break_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_break_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Break'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_break_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_break_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Break'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_breakdown_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_breakdown_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Breakdown'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_breakdown_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_breakdown_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Breakdown'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_changeover_ended_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_changeover_ended_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Changeover Ended'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_changeover_ended_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_changeover_ended_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Changeover Ended'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_changeover_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_changeover_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Setup'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_changeover_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_changeover_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Setup'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_cleaning_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_cleaning_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Cleaning'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_cleaning_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_cleaning_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Cleaning'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_detecting_state_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_detecting_state_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Detecting State'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_detecting_state_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_detecting_state_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Detecting State'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_down_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_down_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Missing Reason'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_down_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_down_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Missing Reason'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_event_cleared_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_event_cleared_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Event Cleared'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_event_cleared_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_event_cleared_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Event Cleared'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_jam_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_jam_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Jam'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_jam_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_jam_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Jam'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_lubrication_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_lubrication_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Lubrication'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_lubrication_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_lubrication_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Lubrication'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_lunch_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_lunch_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Lunch'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_lunch_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_lunch_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Lunch'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_maintenance_ended_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_maintenance_ended_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Maintenance Ended'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_maintenance_ended_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_maintenance_ended_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Maintenance Ended'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_maintenance_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_maintenance_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'General Maintenance'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_maintenance_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_maintenance_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'General Maintenance'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_material_change_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_material_change_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Material Change'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_material_change_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_material_change_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Material Change'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_meeting_ended_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_meeting_ended_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Meeting Ended'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_meeting_ended_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_meeting_ended_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Meeting Ended'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_meeting_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_meeting_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Team Meeting'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_meeting_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_meeting_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Team Meeting'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_no_material_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_material_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Material'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_no_material_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_material_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Material'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_no_operator_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_operator_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Operator'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_no_operator_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_operator_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Operator'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_no_orders_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_orders_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Orders'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_no_orders_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_orders_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Orders'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_no_process_state_enabled_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_process_state_enabled_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No State Enabled'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_no_process_state_enabled_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_process_state_enabled_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No State Enabled'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_no_production_ended_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_production_ended_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Production Ended'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_no_production_ended_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_production_ended_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Production Ended'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_no_production_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_production_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Shift Scheduled'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_no_production_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_no_production_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'No Shift Scheduled'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_not_monitored_ended_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_not_monitored_ended_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Not Monitored Ended'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_not_monitored_ended_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_not_monitored_ended_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Not Monitored Ended'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_not_monitored_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_not_monitored_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Not Monitored'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_not_monitored_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_not_monitored_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Not Monitored'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_optional_shift_scheduled_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_optional_shift_scheduled_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Optional Shift Scheduled'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_optional_shift_scheduled_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_optional_shift_scheduled_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Optional Shift Scheduled'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_part_change_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_part_change_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Part Change'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_part_change_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_part_change_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Part Change'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_powered_off_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_powered_off_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Powered Off'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_powered_off_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_powered_off_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Powered Off'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_production_rejects_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_production_rejects_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Production Rejects'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_production_rejects_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_production_rejects_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Production Rejects'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_running_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Running Normally'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_running_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Running Normally'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_running_poor_quality_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_poor_quality_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Running Poor Quality'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_running_poor_quality_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_poor_quality_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Running Poor Quality'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_running_poorly_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_poorly_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Slow w/ Poor Quality'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_running_poorly_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_poorly_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Slow w/ Poor Quality'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_running_slow_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_slow_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Slow and/or Small Stops'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_running_slow_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_running_slow_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Slow and/or Small Stops'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_safety_meeting_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_safety_meeting_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Safety Meeting'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_safety_meeting_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_safety_meeting_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Safety Meeting'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_shift_ended_early_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_shift_ended_early_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Shift Ended Early'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_shift_ended_early_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_shift_ended_early_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Shift Ended Early'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_shift_handover_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_shift_handover_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Shift Handover'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_shift_handover_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_shift_handover_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Shift Handover'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_startup_rejects_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_startup_rejects_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Startup Rejects'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_startup_rejects_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_startup_rejects_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Startup Rejects'
        },
        'type': 'double'
      },
      'process_state_process_state_reason_upgrading_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_upgrading_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Upgrading'
        },
        'type': 'id'
      },
      'process_state_process_state_reason_upgrading_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_process_state_reason_upgrading_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Reason',
          'category_value': 'Upgrading'
        },
        'type': 'double'
      },
      'process_state_remaining_time': {
        'classification': 'production_times',
        'display_name': 'term.process_state_remaining_time.name',
        'ephemeral': true,
        'help_text': 'term.process_state_remaining_time.short',
        'name': 'process_state_remaining_time',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.process_state_remaining_time.scoreboard_name',
        'type': 'time_span'
      },
      'process_state_running_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running'
        },
        'type': 'id'
      },
      'process_state_running_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running'
        },
        'type': 'double'
      },
      'process_state_running_poor_quality_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_poor_quality_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running Poor Quality'
        },
        'type': 'id'
      },
      'process_state_running_poor_quality_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_poor_quality_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running Poor Quality'
        },
        'type': 'double'
      },
      'process_state_running_poorly_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_poorly_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running Poorly'
        },
        'type': 'id'
      },
      'process_state_running_poorly_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_poorly_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running Poorly'
        },
        'type': 'double'
      },
      'process_state_running_slow_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_slow_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running Slow'
        },
        'type': 'id'
      },
      'process_state_running_slow_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'process_state_running_slow_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production State',
          'category_value': 'Running Slow'
        },
        'type': 'double'
      },
      'production_day_event_id': {
        'display_name': 'term.production_day_event_id.name',
        'help_text': 'term.production_day_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'production_day_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_day_event_id.scoreboard_name',
        'type': 'id'
      },
      'production_day_number': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.production_day_number.name',
        'help_text': 'term.production_day_number.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'internal': true,
        'name': 'production_day_number',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_day_number.scoreboard_name',
        'type': 'uint64'
      },
      'production_loss': {
        'aggregation': 'none',
        'classification': 'teep',
        'display_name': 'term.production_loss.name',
        'formula': ' 1 - teep - capacity_loss',
        'help_text': 'term.production_loss.short',
        'increasing_is': 'bad',
        'name': 'production_loss',
        'rollup': true,
        'scoreboard_name': 'term.production_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'production_lost_time': {
        'aggregation': 'none',
        'classification': 'teep',
        'display_name': 'term.production_lost_time.name',
        'formula': 'oee_lost_time',
        'help_text': 'term.production_lost_time.short',
        'increasing_is': 'bad',
        'name': 'production_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.production_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'production_phase_changeover_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_changeover_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'Changeover'
        },
        'type': 'id'
      },
      'production_phase_changeover_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_changeover_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'Changeover'
        },
        'type': 'double'
      },
      'production_phase_event_id': {
        'display_name': 'term.production_phase_event_id.name',
        'help_text': 'term.production_phase_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'production_phase_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_phase_event_id.scoreboard_name',
        'type': 'id'
      },
      'production_phase_null_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_null_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'No Part'
        },
        'type': 'id'
      },
      'production_phase_null_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_null_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'No Part'
        },
        'type': 'double'
      },
      'production_phase_occurrences': {
        'display_name': 'term.production_phase_occurrences.name',
        'help_text': 'term.production_phase_occurrences.short',
        'internal': true,
        'name': 'production_phase_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.production_phase_occurrences.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production Phase'
        },
        'type': 'double'
      },
      'production_phase_remaining_time': {
        'classification': 'production_times',
        'display_name': 'term.production_phase_remaining_time.name',
        'ephemeral': true,
        'help_text': 'term.production_phase_remaining_time.short',
        'internal': true,
        'name': 'production_phase_remaining_time',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_phase_remaining_time.scoreboard_name',
        'type': 'time_span'
      },
      'production_phase_startup_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_startup_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'Startup'
        },
        'type': 'id'
      },
      'production_phase_startup_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_startup_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'Startup'
        },
        'type': 'double'
      },
      'production_phase_steady_state_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_steady_state_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'Steady-State'
        },
        'type': 'id'
      },
      'production_phase_steady_state_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'production_phase_steady_state_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Production Phase',
          'category_value': 'Steady-State'
        },
        'type': 'double'
      },
      'production_reject_loss': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.production_reject_loss.name',
        'formula': 'percent_production_rejects_or_zero * availability_or_zero * performance_or_zero',
        'help_text': 'term.production_reject_loss.short',
        'increasing_is': 'bad',
        'name': 'production_reject_loss',
        'rollup': true,
        'scoreboard_name': 'term.production_reject_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'production_reject_loss_or_zero': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.production_reject_loss_or_zero.name',
        'formula': 'zero_null(production_reject_loss)',
        'help_text': 'term.production_reject_loss_or_zero.short',
        'increasing_is': 'bad',
        'internal': true,
        'name': 'production_reject_loss_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.production_reject_loss_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'production_reject_lost_time': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.production_reject_lost_time.name',
        'formula': 'production_reject_loss_or_zero * production_time',
        'help_text': 'term.production_reject_lost_time.short',
        'increasing_is': 'bad',
        'name': 'production_reject_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.production_reject_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'production_rejects': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.production_rejects.name',
        'formula': 'reject_count - startup_rejects',
        'help_text': 'term.production_rejects.short',
        'name': 'production_rejects',
        'rollup': true,
        'scoreboard_name': 'term.production_rejects.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {},
        'type': 'double'
      },
      'production_time': {
        'aggregation': 'none',
        'classification': 'production_times',
        'display_name': 'term.production_time.name',
        'formula': 'manufacturing_time + planned_stop_time',
        'help_text': 'term.production_time.short',
        'name': 'production_time',
        'rollup': true,
        'scoreboard_name': 'term.production_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'quality': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.quality.name',
        'formula': '1 - zero_null(reject_count / total_count)',
        'help_text': 'term.quality.short',
        'increasing_is': 'good',
        'name': 'quality',
        'rollup': true,
        'scoreboard_name': 'term.quality.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'quality_loss': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.quality_loss.name',
        'formula': '(1 - quality) * availability_or_zero * performance_or_zero',
        'help_text': 'term.quality_loss.short',
        'increasing_is': 'bad',
        'name': 'quality_loss',
        'rollup': true,
        'scoreboard_name': 'term.quality_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'quality_lost_time': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.quality_lost_time.name',
        'formula': 'quality_loss * production_time',
        'help_text': 'term.quality_lost_time.short',
        'increasing_is': 'bad',
        'name': 'quality_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.quality_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'quality_or_zero': {
        'aggregation': 'none',
        'classification': 'oee',
        'display_name': 'term.quality_or_zero.name',
        'formula': 'zero_null(quality)',
        'help_text': 'term.quality_or_zero.short',
        'increasing_is': 'good',
        'internal': true,
        'name': 'quality_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.quality_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'quarter_event_id': {
        'display_name': 'term.quarter_event_id.name',
        'help_text': 'term.quarter_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'quarter_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.quarter_event_id.scoreboard_name',
        'type': 'id'
      },
      'rate_efficiency': {
        'aggregation': 'none',
        'classification': 'target',
        'display_name': 'term.rate_efficiency.name',
        'formula': 'efficiency',
        'help_text': 'term.rate_efficiency.short',
        'increasing_is': 'good',
        'name': 'rate_efficiency',
        'rollup': true,
        'scoreboard_name': 'term.rate_efficiency.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'record_id': {
        'aggregation': 'max',
        'display_name': 'term.record_id.name',
        'help_text': 'term.record_id.short',
        'name': 'record_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.record_id.scoreboard_name',
        'type': 'id'
      },
      'record_order': {
        'aggregation': 'min',
        'display_name': 'term.record_order.name',
        'help_text': 'term.record_order.short',
        'indexed': true,
        'internal': true,
        'name': 'record_order',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.record_order.scoreboard_name',
        'type': 'id'
      },
      'reject_count': {
        'aggregate_to': {
          'channel': 'quality',
          'metric': 'count'
        },
        'classification': 'counts',
        'display_name': 'term.reject_count.name',
        'help_text': 'term.reject_count.short',
        'name': 'reject_count',
        'rollup': true,
        'scoreboard_name': 'term.reject_count.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'reject_rate': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.reject_rate.name',
        'formula': 'reject_count / manufacturing_time * 60',
        'help_text': 'term.reject_rate.short',
        'name': 'reject_rate',
        'rollup': true,
        'scoreboard_name': 'term.reject_rate.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'reject_rate_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.reject_rate_per_hour.name',
        'formula': 'reject_count / manufacturing_time * 3600',
        'help_text': 'term.reject_rate_per_hour.short',
        'name': 'reject_rate_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.reject_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'reject_speed': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.reject_speed.name',
        'formula': 'reject_count / run_time * 60',
        'help_text': 'term.reject_speed.short',
        'name': 'reject_speed',
        'rollup': true,
        'scoreboard_name': 'term.reject_speed.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'reject_speed_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.reject_speed_per_hour.name',
        'formula': 'reject_count / run_time * 3600',
        'help_text': 'term.reject_speed_per_hour.short',
        'name': 'reject_speed_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.reject_speed_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'remaining_percent': {
        'classification': 'production_times',
        'display_name': 'term.remaining_percent.name',
        'ephemeral': true,
        'help_text': 'term.remaining_percent.short',
        'internal': true,
        'name': 'remaining_percent',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.remaining_percent.scoreboard_name',
        'subtype': 'percent',
        'type': 'double'
      },
      'run_confidence': {
        'classification': 'production_times',
        'display_name': 'term.run_confidence.name',
        'ephemeral': true,
        'help_text': 'term.run_confidence.short',
        'internal': true,
        'name': 'run_confidence',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.run_confidence.scoreboard_name',
        'subtype': 'percent',
        'type': 'double'
      },
      'run_cycle_lost_time': {
        'classification': 'cycles',
        'display_name': 'term.run_cycle_lost_time.name',
        'help_text': 'term.run_cycle_lost_time.short',
        'increasing_is': 'bad',
        'name': 'run_cycle_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.run_cycle_lost_time.scoreboard_name',
        'type': 'time_span'
      },
      'run_cycles': {
        'classification': 'cycles',
        'display_name': 'term.run_cycles.name',
        'help_text': 'term.run_cycles.short',
        'name': 'run_cycles',
        'rollup': true,
        'scoreboard_name': 'term.run_cycles.scoreboard_name',
        'subtype': 'cycle',
        'type': 'double'
      },
      'run_time': {
        'classification': 'production_times',
        'display_name': 'term.run_time.name',
        'help_text': 'term.run_time.short',
        'increasing_is': 'good',
        'name': 'run_time',
        'rollup': true,
        'scoreboard_name': 'term.run_time.scoreboard_name',
        'type': 'time_span'
      },
      'shift_event_id': {
        'display_name': 'term.shift_event_id.name',
        'help_text': 'term.shift_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'shift_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.shift_event_id.scoreboard_name',
        'type': 'id'
      },
      'shift_first_shift_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_first_shift_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'First Shift'
        },
        'type': 'id'
      },
      'shift_first_shift_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_first_shift_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'First Shift'
        },
        'type': 'double'
      },
      'shift_hour_event_id': {
        'display_name': 'term.shift_hour_event_id.name',
        'help_text': 'term.shift_hour_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'shift_hour_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.shift_hour_event_id.scoreboard_name',
        'type': 'id'
      },
      'shift_hour_occurrences': {
        'display_name': 'term.shift_hour_occurrences.name',
        'help_text': 'term.shift_hour_occurrences.short',
        'internal': true,
        'name': 'shift_hour_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.shift_hour_occurrences.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Hour'
        },
        'type': 'double'
      },
      'shift_null_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_null_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'No Shift'
        },
        'type': 'id'
      },
      'shift_null_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_null_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'No Shift'
        },
        'type': 'double'
      },
      'shift_occurrences': {
        'display_name': 'term.shift_occurrences.name',
        'help_text': 'term.shift_occurrences.short',
        'internal': true,
        'name': 'shift_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.shift_occurrences.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Shift'
        },
        'type': 'double'
      },
      'shift_second_shift_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_second_shift_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'Second Shift'
        },
        'type': 'id'
      },
      'shift_second_shift_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_second_shift_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'Second Shift'
        },
        'type': 'double'
      },
      'shift_third_shift_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_third_shift_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'Third Shift'
        },
        'type': 'id'
      },
      'shift_third_shift_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_third_shift_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'Third Shift'
        },
        'type': 'double'
      },
      'shift_unknown_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_unknown_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'Unknown Shift'
        },
        'type': 'id'
      },
      'shift_unknown_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'shift_unknown_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Shift',
          'category_value': 'Unknown Shift'
        },
        'type': 'double'
      },
      'slice_order': {
        'display_name': 'term.slice_order.name',
        'help_text': 'term.slice_order.short',
        'indexed': true,
        'internal': true,
        'name': 'slice_order',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.slice_order.scoreboard_name',
        'type': 'id',
        'unique': true
      },
      'small_stop_loss': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.small_stop_loss.name',
        'formula': 'small_stop_lost_time / production_time',
        'help_text': 'term.small_stop_loss.short',
        'increasing_is': 'bad',
        'name': 'small_stop_loss',
        'rollup': true,
        'scoreboard_name': 'term.small_stop_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'small_stop_lost_time': {
        'classification': 'six_big_losses',
        'display_name': 'term.small_stop_lost_time.name',
        'help_text': 'term.small_stop_lost_time.short',
        'increasing_is': 'bad',
        'name': 'small_stop_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.small_stop_lost_time.scoreboard_name',
        'type': 'time_span'
      },
      'small_stops': {
        'classification': 'cycles',
        'display_name': 'term.small_stops.name',
        'help_text': 'term.small_stops.short',
        'name': 'small_stops',
        'rollup': true,
        'scoreboard_name': 'term.small_stops.scoreboard_name',
        'subtype': 'cycle',
        'type': 'double'
      },
      'start_time': {
        'aggregation': 'datetime_min',
        'classification': 'production_times',
        'display_name': 'term.start_time.name',
        'help_text': 'term.start_time.short',
        'name': 'start_time',
        'persist': true,
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.start_time.scoreboard_name',
        'type': 'date_time'
      },
      'startup_reject_loss': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.startup_reject_loss.name',
        'formula': 'percent_startup_rejects_or_zero * availability_or_zero * performance_or_zero',
        'help_text': 'term.startup_reject_loss.short',
        'increasing_is': 'bad',
        'name': 'startup_reject_loss',
        'rollup': true,
        'scoreboard_name': 'term.startup_reject_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'startup_reject_loss_or_zero': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.startup_reject_loss_or_zero.name',
        'formula': 'zero_null(startup_reject_loss)',
        'help_text': 'term.startup_reject_loss_or_zero.short',
        'increasing_is': 'bad',
        'internal': true,
        'name': 'startup_reject_loss_or_zero',
        'rollup': true,
        'scoreboard_name': 'term.startup_reject_loss_or_zero.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'startup_reject_lost_time': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.startup_reject_lost_time.name',
        'formula': 'startup_reject_loss_or_zero * production_time',
        'help_text': 'term.startup_reject_lost_time.short',
        'increasing_is': 'bad',
        'name': 'startup_reject_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.startup_reject_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'startup_rejects': {
        'classification': 'counts',
        'display_name': 'term.startup_rejects.name',
        'help_text': 'term.startup_rejects.short',
        'name': 'startup_rejects',
        'rollup': true,
        'scoreboard_name': 'term.startup_rejects.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'sync_id': {
        'aggregation': 'sync_id',
        'display_name': 'term.sync_id.name',
        'help_text': 'term.sync_id.short',
        'indexed': true,
        'internal': true,
        'name': 'sync_id',
        'rollup': true,
        'scoreboard_name': 'term.sync_id.scoreboard_name',
        'type': 'id'
      },
      'takt_time': {
        'classification': 'target',
        'display_name': 'term.takt_time.name',
        'ephemeral': true,
        'help_text': 'term.takt_time.short',
        'name': 'takt_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.takt_time.scoreboard_name',
        'subtype': 'cycle_time',
        'type': 'time_span'
      },
      'target_count': {
        'classification': 'target',
        'display_name': 'term.target_count.name',
        'help_text': 'term.target_count.short',
        'name': 'target_count',
        'rollup': true,
        'scoreboard_name': 'term.target_count.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'target_cycles': {
        'classification': 'target',
        'display_name': 'term.target_cycles.name',
        'help_text': 'term.target_cycles.short',
        'name': 'target_cycles',
        'rollup': true,
        'scoreboard_name': 'term.target_cycles.scoreboard_name',
        'subtype': 'cycle',
        'type': 'double'
      },
      'target_labor_per_piece': {
        'aggregation': 'none',
        'classification': 'labor',
        'display_name': 'term.target_labor_per_piece.name',
        'formula': 'earned_labor / good_count',
        'help_text': 'term.target_labor_per_piece.short',
        'name': 'target_labor_per_piece',
        'rollup': true,
        'scoreboard_name': 'term.target_labor_per_piece.scoreboard_name',
        'subtype': 'cycle_time',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'target_rate': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.target_rate.name',
        'formula': 'target_count / manufacturing_time * 60',
        'help_text': 'term.target_rate.short',
        'name': 'target_rate',
        'rollup': true,
        'scoreboard_name': 'term.target_rate.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'target_rate_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.target_rate_per_hour.name',
        'formula': 'target_count / manufacturing_time * 3600',
        'help_text': 'term.target_rate_per_hour.short',
        'name': 'target_rate_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.target_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'target_time': {
        'classification': 'production_times',
        'display_name': 'term.target_time.name',
        'ephemeral': true,
        'help_text': 'term.target_time.short',
        'name': 'target_time',
        'persist': true,
        'rollup': false,
        'scoreboard_name': 'term.target_time.scoreboard_name',
        'type': 'time_span'
      },
      'team_event_id': {
        'display_name': 'term.team_event_id.name',
        'help_text': 'term.team_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'team_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.team_event_id.scoreboard_name',
        'type': 'id'
      },
      'team_null_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'team_null_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Team',
          'category_value': 'No Team'
        },
        'type': 'id'
      },
      'team_null_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'team_null_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Team',
          'category_value': 'No Team'
        },
        'type': 'double'
      },
      'team_occurrences': {
        'display_name': 'term.team_occurrences.name',
        'help_text': 'term.team_occurrences.short',
        'internal': true,
        'name': 'team_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': 'term.team_occurrences.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Team'
        },
        'type': 'double'
      },
      'team_size': {
        'classification': 'labor',
        'display_name': 'term.team_size.name',
        'ephemeral': true,
        'help_text': 'term.team_size.short',
        'name': 'team_size',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.team_size.scoreboard_name',
        'type': 'double'
      },
      'team_unknown_list_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'team_unknown_list_occurrences',
        'post_process': 'id_array',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'translation_arguments': {
          'category': 'Team',
          'category_value': 'Unknown Team'
        },
        'type': 'id'
      },
      'team_unknown_occurrences': {
        'classification': 'events',
        'display_name': 'occurrences_display_name_template',
        'help_text': 'occurrences_help_text_template',
        'name': 'team_unknown_occurrences',
        'rollup': true,
        'scoreboard': false,
        'scoreboard_name': '',
        'subtype': 'count',
        'translation_arguments': {
          'category': 'Team',
          'category_value': 'Unknown Team'
        },
        'type': 'double'
      },
      'teep': {
        'aggregation': 'none',
        'classification': 'teep',
        'display_name': 'term.teep.name',
        'formula': 'availability_or_zero * performance_or_zero * quality_or_zero * utilization',
        'help_text': 'term.teep.short',
        'increasing_is': 'good',
        'name': 'teep',
        'rollup': true,
        'scoreboard_name': 'term.teep.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'time_variance': {
        'aggregation': 'none',
        'classification': 'target',
        'display_name': 'term.time_variance.name',
        'formula': 'percent_variance * manufacturing_time',
        'help_text': 'term.time_variance.short',
        'name': 'time_variance',
        'rollup': true,
        'scoreboard_name': 'term.time_variance.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'total_count': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.total_count.name',
        'formula': 'good_count + reject_count',
        'help_text': 'term.total_count.short',
        'name': 'total_count',
        'rollup': true,
        'scoreboard_name': 'term.total_count.scoreboard_name',
        'subtype': 'count',
        'translation_arguments': {},
        'type': 'double'
      },
      'total_cycle_time': {
        'aggregation': 'none',
        'classification': 'cycles',
        'display_name': 'term.total_cycle_time.name',
        'formula': 'ideal_time + run_cycle_lost_time + small_stop_lost_time + partial_cycle_lost_time',
        'help_text': 'term.total_cycle_time.short',
        'name': 'total_cycle_time',
        'rollup': true,
        'scoreboard_name': 'term.total_cycle_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'total_cycles': {
        'aggregation': 'none',
        'classification': 'cycles',
        'display_name': 'term.total_cycles.name',
        'formula': 'run_cycles + small_stops + partial_cycles',
        'help_text': 'term.total_cycles.short',
        'name': 'total_cycles',
        'rollup': true,
        'scoreboard_name': 'term.total_cycles.scoreboard_name',
        'subtype': 'cycle',
        'translation_arguments': {},
        'type': 'double'
      },
      'total_rate': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.total_rate.name',
        'formula': 'total_count / manufacturing_time * 60',
        'help_text': 'term.total_rate.short',
        'name': 'total_rate',
        'rollup': true,
        'scoreboard_name': 'term.total_rate.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'total_rate_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.total_rate_per_hour.name',
        'formula': 'total_count / manufacturing_time * 3600',
        'help_text': 'term.total_rate_per_hour.short',
        'name': 'total_rate_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.total_rate_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'total_speed': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.total_speed.name',
        'formula': 'total_count / run_time * 60',
        'help_text': 'term.total_speed.short',
        'name': 'total_speed',
        'rollup': true,
        'scoreboard_name': 'term.total_speed.scoreboard_name',
        'subtype': 'rate',
        'translation_arguments': {},
        'type': 'double'
      },
      'total_speed_per_hour': {
        'aggregation': 'none',
        'classification': 'rates',
        'display_name': 'term.total_speed_per_hour.name',
        'formula': 'total_count / run_time * 3600',
        'help_text': 'term.total_speed_per_hour.short',
        'name': 'total_speed_per_hour',
        'rollup': true,
        'scoreboard_name': 'term.total_speed_per_hour.scoreboard_name',
        'subtype': 'rate_per_hour',
        'translation_arguments': {},
        'type': 'double'
      },
      'unplanned_stop_loss': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.unplanned_stop_loss.name',
        'formula': 'unplanned_stop_time / production_time',
        'help_text': 'term.unplanned_stop_loss.short',
        'increasing_is': 'bad',
        'name': 'unplanned_stop_loss',
        'rollup': true,
        'scoreboard_name': 'term.unplanned_stop_loss.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'unplanned_stop_lost_time': {
        'aggregation': 'none',
        'classification': 'six_big_losses',
        'display_name': 'term.unplanned_stop_lost_time.name',
        'formula': 'unplanned_stop_time',
        'help_text': 'term.unplanned_stop_lost_time.short',
        'increasing_is': 'bad',
        'name': 'unplanned_stop_lost_time',
        'rollup': true,
        'scoreboard_name': 'term.unplanned_stop_lost_time.scoreboard_name',
        'translation_arguments': {},
        'type': 'time_span'
      },
      'unplanned_stop_time': {
        'classification': 'production_times',
        'display_name': 'term.unplanned_stop_time.name',
        'help_text': 'term.unplanned_stop_time.short',
        'increasing_is': 'bad',
        'name': 'unplanned_stop_time',
        'rollup': true,
        'scoreboard_name': 'term.unplanned_stop_time.scoreboard_name',
        'type': 'time_span'
      },
      'utilization': {
        'aggregation': 'none',
        'classification': 'teep',
        'display_name': 'term.utilization.name',
        'formula': 'production_time / all_time',
        'help_text': 'term.utilization.short',
        'increasing_is': 'good',
        'name': 'utilization',
        'rollup': true,
        'scoreboard_name': 'term.utilization.scoreboard_name',
        'subtype': 'percent',
        'translation_arguments': {},
        'type': 'double'
      },
      'week_event_id': {
        'display_name': 'term.week_event_id.name',
        'help_text': 'term.week_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'week_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.week_event_id.scoreboard_name',
        'type': 'id'
      },
      'wip': {
        'aggregation': 'none',
        'classification': 'counts',
        'display_name': 'term.wip.name',
        'help_text': 'term.wip.short',
        'name': 'wip',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.wip.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'year_event_id': {
        'display_name': 'term.year_event_id.name',
        'help_text': 'term.year_event_id.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'year_event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.year_event_id.scoreboard_name',
        'type': 'id'
      }
    },
    'name': 'production_metric',
    'primary_key': 'record_id',
    'slicing_parent': 'production_metric'
  },
  'production_phase': {
    'display_name': 'production_phase_channel_literal',
    'name': 'production_phase',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'quality': {
    'aggregating_boundary_parent': 'production_metric',
    'annotations': {
      'reject_reason_display_name': {
        'display_name': 'term.reject_reason_display_name.name',
        'help_text': 'term.reject_reason_display_name.short',
        'name': 'reject_reason_display_name',
        'scoreboard_name': 'term.reject_reason_display_name.scoreboard_name'
      }
    },
    'category_values': {
      'reject_reason': {
        'category': 'reject_reason',
        'display_name': 'term.reject_reason.name',
        'help_text': 'term.reject_reason.short',
        'indexed': true,
        'name': 'reject_reason',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.reject_reason.scoreboard_name'
      }
    },
    'display_name': 'quality_channel_literal',
    'metrics': {
      'boundary_parent_event_id': {
        'display_name': 'term.boundary_parent_event_id.name',
        'help_text': 'term.boundary_parent_event_id.short',
        'indexed': true,
        'internal': true,
        'name': 'boundary_parent_event_id',
        'persist': true,
        'reference': true,
        'rollup': true,
        'scoreboard_name': 'term.boundary_parent_event_id.scoreboard_name',
        'type': 'id'
      },
      'count': {
        'classification': 'counts',
        'display_name': 'term.count.name',
        'help_text': 'term.count.short',
        'name': 'count',
        'rollup': true,
        'scoreboard_name': 'term.count.scoreboard_name',
        'subtype': 'count',
        'type': 'double'
      },
      'event_id': {
        'display_name': 'term.event_id.name',
        'help_text': 'term.event_id.short',
        'indexed': true,
        'internal': true,
        'name': 'event_id',
        'persist': true,
        'reference': true,
        'rollup': true,
        'scoreboard_name': 'term.event_id.scoreboard_name',
        'type': 'id'
      },
      'production_day_number': {
        'aggregation': 'none',
        'display_name': 'term.production_day_number.name',
        'help_text': 'term.production_day_number.short',
        'internal': true,
        'name': 'production_day_number',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_day_number.scoreboard_name',
        'type': 'uint64'
      },
      'record_id': {
        'aggregation': 'max',
        'display_name': 'term.record_id.name',
        'help_text': 'term.record_id.short',
        'name': 'record_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.record_id.scoreboard_name',
        'type': 'id'
      },
      'record_order': {
        'aggregation': 'min',
        'display_name': 'term.record_order.name',
        'help_text': 'term.record_order.short',
        'indexed': true,
        'internal': true,
        'name': 'record_order',
        'persist': true,
        'reference': true,
        'rollup': true,
        'scoreboard_name': 'term.record_order.scoreboard_name',
        'type': 'id'
      },
      'slice_order': {
        'display_name': 'term.slice_order.name',
        'help_text': 'term.slice_order.short',
        'indexed': true,
        'internal': true,
        'name': 'slice_order',
        'persist': true,
        'reference': true,
        'rollup': true,
        'scoreboard_name': 'term.slice_order.scoreboard_name',
        'type': 'id'
      },
      'sync_id': {
        'aggregation': 'sync_id',
        'display_name': 'term.sync_id.name',
        'help_text': 'term.sync_id.short',
        'indexed': true,
        'internal': true,
        'name': 'sync_id',
        'rollup': true,
        'scoreboard_name': 'term.sync_id.scoreboard_name',
        'type': 'id'
      }
    },
    'name': 'quality',
    'primary_key': 'record_id'
  },
  'quarter': {
    'display_name': 'quarter_channel_literal',
    'name': 'quarter',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'shift': {
    'display_name': 'shift_channel_literal',
    'name': 'shift',
    'projecting_base': 'production_metric'
  },
  'shift_hour': {
    'display_name': 'shift_hour_channel_literal',
    'name': 'shift_hour',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'system': {
    'annotations': {},
    'category_values': {
      'internal_storage_lifetime': {
        'category': 'internal_storage_lifetime',
        'display_name': 'term.internal_storage_lifetime.name',
        'help_text': 'term.internal_storage_lifetime.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'internal_storage_lifetime',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.internal_storage_lifetime.scoreboard_name'
      },
      'last_scan_reason': {
        'category': 'process_state_reason',
        'display_name': 'term.last_scan_reason.name',
        'ephemeral': true,
        'help_text': 'term.last_scan_reason.short',
        'indexed': true,
        'indexed_with': [
          'record_order'
        ],
        'name': 'last_scan_reason',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.last_scan_reason.scoreboard_name'
      }
    },
    'display_name': 'system_channel_literal',
    'metrics': {
      'event_id': {
        'display_name': 'term.event_id.name',
        'help_text': 'term.event_id.short',
        'indexed': true,
        'internal': true,
        'name': 'event_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.event_id.scoreboard_name',
        'type': 'id'
      },
      'production_day_number': {
        'aggregation': 'none',
        'display_name': 'term.production_day_number.name',
        'help_text': 'term.production_day_number.short',
        'internal': true,
        'name': 'production_day_number',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.production_day_number.scoreboard_name',
        'type': 'uint64'
      },
      'record_id': {
        'aggregation': 'max',
        'display_name': 'term.record_id.name',
        'help_text': 'term.record_id.short',
        'name': 'record_id',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.record_id.scoreboard_name',
        'type': 'id'
      },
      'record_order': {
        'aggregation': 'min',
        'display_name': 'term.record_order.name',
        'help_text': 'term.record_order.short',
        'indexed': true,
        'internal': true,
        'name': 'record_order',
        'persist': true,
        'rollup': true,
        'scoreboard_name': 'term.record_order.scoreboard_name',
        'type': 'id'
      },
      'slice_order': {
        'display_name': 'term.slice_order.name',
        'help_text': 'term.slice_order.short',
        'indexed': true,
        'internal': true,
        'name': 'slice_order',
        'persist': true,
        'reference': true,
        'rollup': true,
        'scoreboard_name': 'term.slice_order.scoreboard_name',
        'type': 'id'
      },
      'sync_id': {
        'aggregation': 'sync_id',
        'display_name': 'term.sync_id.name',
        'help_text': 'term.sync_id.short',
        'indexed': true,
        'internal': true,
        'name': 'sync_id',
        'rollup': true,
        'scoreboard_name': 'term.sync_id.scoreboard_name',
        'type': 'id'
      }
    },
    'name': 'system',
    'primary_key': 'record_id'
  },
  'team': {
    'display_name': 'team_channel_literal',
    'name': 'team',
    'projecting_base': 'production_metric'
  },
  'week': {
    'display_name': 'week_channel_literal',
    'name': 'week',
    'projecting_base': 'production_metric',
    'protected': true
  },
  'year': {
    'display_name': 'year_channel_literal',
    'name': 'year',
    'projecting_base': 'production_metric',
    'protected': true
  }
};
