import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function prepareDraggables(items, draggingId, allowDropAtIndexFn) {
    if (!draggingId) {
        return {
            before: { id: null, items: [] },
            middle: items.map((item) => {
                return { id: item.id, items: [ item ] };
            }),
            after: { id: null, items: [] },
        };
    }

    let dragStartIndex = null;

    const otherItems = items.filter((item, index) => {
        if (item.id === draggingId) {
            dragStartIndex = index;
            return false;
        }

        return true;
    });

    const allowDropAtIndex = (index) => {
        return allowDropAtIndexFn(index);
    };

    const groups = [];

    let currentGroup = {
        id: null,
        items: [],
    };

    otherItems.forEach((item, index) => {
        const allowDrop = allowDropAtIndex(index);
        const itemAbove = otherItems[index - 1];
        const itemBelow = otherItems[index];

        // Insert the currently dragged item in its own group at its starting
        // index.
        if (index === dragStartIndex) {
            groups.push(currentGroup);
            groups.push({
                id: draggingId,
                items: [ items[dragStartIndex] ],
            });
            currentGroup = {
                id: itemBelow.id,
                items: [ itemBelow ],
            };
            return;
        }

        if (!itemAbove) {
            // Start of list
            if (allowDrop) {
                // Drop allowed at start of list
                groups.push(currentGroup); // Push the first group
                currentGroup = {
                    id: itemBelow.id,
                    items: [ itemBelow ],
                };
            }
            else {
                // Drop not allowed at start of list
                currentGroup.id = itemBelow.id;
                currentGroup.items.push(itemBelow);
            }
        }
        else {
            // Somewhere in the middle of the list
            if (allowDrop) {
                // Close out the current group and start a new one
                groups.push(currentGroup);
                currentGroup = {
                    id: itemBelow.id,
                    items: [ itemBelow ],
                };
            }
            else {
                // Add item to the current group
                currentGroup.items.push(itemBelow);
            }
        }
    });

    // End of list
    if (dragStartIndex === otherItems.length) {
        const draggingItem = items[dragStartIndex];

        // Edge case, literally. Last item is the one being dragged.
        groups.push(currentGroup);
        groups.push({
            id: draggingItem.id,
            items: [ draggingItem ],
        });
        // Last group is always empty in this case.
        groups.push({
            id: null,
            items: [],
        });
    }
    else if (allowDropAtIndex(otherItems.length)) {
        // Drop allowed at end of list, push current group, then an
        // empty group at the end
        groups.push(currentGroup);
        groups.push({
            id: null,
            items: [],
        });
    }
    else {
        // Drop not allowed at end of list, push current group as the
        // last group
        groups.push(currentGroup);
    }

    if (groups.length < 2) {
        throw new Error('Cannot be less than 2 groups');
    }

    const totalItemCount = groups.reduce((count, group) => {
        return count + group.items.length;
    }, 0);

    if (totalItemCount !== items.length) {
        throw new Error(`Invalid number of items. Expected ${items.length}, got ${totalItemCount}`);
    }

    return {
        before: groups[0],
        middle: groups.slice(1, -1),
        after: groups[groups.length - 1],
    };
}

export default class GroupedDragDropList extends React.Component {
    static propTypes = {
        droppableId: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        renderItem: PropTypes.func.isRequired,
        allowDropAtIndex: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        children: PropTypes.func.isRequired,

        onBeforeCapture: PropTypes.func,
        onDragStart: PropTypes.func,
        onDragUpdate: PropTypes.func,
        onDragEnd: PropTypes.func,
    };

    static defaultProps = {
        readOnly: false,
        onBeforeCapture: () => {},
        onDragStart: () => {},
        onDragUpdate: () => {},
        onDragEnd: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            draggingId: null,
            idToIndexMap: null, // Derived
            draggables: null, // Derived
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            idToIndexMap: props.items.reduce((map, item, index) => {
                map[item.id] = index;
                return map;
            }, {}),
            draggables: prepareDraggables(
                props.items,
                state.draggingId || null,
                props.allowDropAtIndex,
            ),
        };
    }

    _translateSource(draggableId, source) {
        const { items } = this.props;

        const index = items.findIndex((item) => {
            return item.id === draggableId;
        });

        if (index === -1) {
            throw new Error(`Unable to find item with id ${draggableId}`);
        }

        return {
            ...source,
            index: index,
        };
    }

    _translateDestination(destination) {
        const { draggingId, draggables } = this.state;

        let translatedDestination = null;

        if (destination) {
            const draggableGroups = draggables.middle.filter((draggable) => {
                return draggable.id !== draggingId;
            });

            const translatedDestinationIndex = draggables.before.items.length + draggableGroups.reduce((curr, group, groupIndex) => {
                if (groupIndex < destination.index) {
                    curr += group.items.length;
                }

                return curr;
            }, 0);

            translatedDestination = {
                ...destination,
                index: translatedDestinationIndex,
            };
        }

        return translatedDestination;
    }

    _handleBeforeCapture = (beforeCapture) => {
        this.setState({
            draggingId: beforeCapture.draggableId,
        });

        this.props.onBeforeCapture(beforeCapture);
    };

    _handleDragStart = (start) => {
        const { draggableId, source } = start;
        const { onDragStart } = this.props;

        onDragStart({
            ...start,
            source: this._translateSource(draggableId, source),
        });
    };

    _handleDragUpdate = (update) => {
        const { draggableId, source, destination } = update;
        const { onDragUpdate } = this.props;

        onDragUpdate({
            ...update,
            source: this._translateSource(draggableId, source),
            destination: this._translateDestination(destination),
        });
    };

    _handleDragEnd = (dragEnd) => {
        const { draggableId, source, destination } = dragEnd;
        const { onDragEnd } = this.props;

        onDragEnd({
            ...dragEnd,
            source: this._translateSource(draggableId, source),
            destination: this._translateDestination(destination),
        });

        this.setState({
            draggingId: null,
        });
    };

    _renderItem(item, provided = null, snapshot = {}) {
        const index = this.state.idToIndexMap[item.id];

        const mergedProvided = {
            dragHandleProps: {},
            ...provided,
        };

        return this.props.renderItem(index, mergedProvided, snapshot);
    }

    _renderItemsBefore() {
        const { draggables } = this.state;

        return draggables.before.items.map((item) => {
            return this._renderItem(item);
        });
    }

    _renderItemsAfter() {
        const { draggables } = this.state;

        return draggables.after.items.map((item) => {
            return this._renderItem(item);
        });
    }

    _renderDraggableItems() {
        const { draggables } = this.state;
        const { readOnly } = this.props;

        return draggables.middle.map((group, index) => {
            return (
                <Draggable
                    key={group.id}
                    draggableId={group.id}
                    isDragDisabled={readOnly}
                    index={index}
                >
                    {(provided, snapshot) => {
                        return (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                // Uncomment to highlight the draggables with a
                                // red box. Extremely useful for debugging.
                                //
                                // style={{
                                //     ...(provided.draggableProps.style || {}),
                                //     boxShadow: 'inset 0 0 0 5px red',
                                // }}
                            >
                                {group.items.map((item) => {
                                    return this._renderItem(
                                        item,
                                        {
                                            dragHandleProps: provided.dragHandleProps,
                                        },
                                        snapshot
                                    );
                                })}
                            </div>
                        );
                    }}
                </Draggable>
            );
        });
    }

    render() {
        const {
            children,
            droppableId,
            ...otherProps
        } = this.props;

        return (
            <DragDropContext
                {...otherProps}
                onBeforeCapture={this._handleBeforeCapture}
                onDragStart={this._handleDragStart}
                onDragUpdate={this._handleDragUpdate}
                onDragEnd={this._handleDragEnd}
            >
                {this._renderItemsBefore()}
                <Droppable
                    droppableId={droppableId}
                    ignoreContainerClipping={true}
                >
                    {(provided, snapshot) => {
                        const mergedProvided = {
                            ...provided,
                            draggables: this._renderDraggableItems(),
                        };

                        return this.props.children(
                            mergedProvided,
                            snapshot,
                        );
                    }}
                </Droppable>
                {this._renderItemsAfter()}
            </DragDropContext>
        );
    }
}
