import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import Page from 'Components/page/Page';
import { Body1 } from 'Components/typography/typography';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentStandard';
import ErrorNotice from 'Components/notice/ErrorNotice';
import SuccessNotice from 'Components/notice/SuccessNotice';
import { login, logout } from 'Redux/actions';
import LoginForm from 'Components/login-form/LoginForm';
import { selectSessionRequest, selectIsLoggedIn } from 'Redux/reducers/session';
import { PENDING } from 'Common/data/RequestStatus';
import { REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';
import verifyAccount from 'Redux/actions/verifyAccount';
import ContainedButton from 'Components/button/ContainedButton';

const STEPS = {
    VERIFY_TOKEN: 'VERIFY_TOKEN',
    LOGIN: 'LOGIN',
    COMPLETE: 'COMPLETE',
};

function getTokenFromProps(props) {
    const urlParams = queryString.parse(props.location.search);
    const token = urlParams.t;
    return token;
}

export class VerifyAccountPagePresentation extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string
        }).isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        requests: PropTypes.shape({
            login: PropTypes.object.isRequired,
            logout: PropTypes.object.isRequired,
            verifyAccount: PropTypes.object.isRequired,
        }),
        actions: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
            verifyAccount: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentStep: STEPS.VERIFY_TOKEN,
            email: '',
            showSuccessMessage: true,
        };
    }

    _submitToken = async (token) => {
        const { isLoggedIn, actions } = this.props;

        if (isLoggedIn) {
            // We don't actually care if this succeeds, it's just a nice-to-have.
            await actions.logout();
        }

        const response = await actions.verifyAccount({
            token: token,
        });

        // If the verify token request was successful, move to the next step
        if (response.type === REQUEST_SUCCESS) {
            this.setState((state) => {
                return {
                    currentStep: STEPS.LOGIN,
                    email: response.payload?.email || '',
                };
            });
        }
    };

    _handleLoginSubmit = async ({ email, password }) => {
        const { actions } = this.props;

        const response = await actions.login({ email, password });

        // If the login request was successful, move to the next step
        if (response.type === REQUEST_SUCCESS) {
            this.setState({
                currentStep: STEPS.COMPLETE,
            });
        }
        else if (response.type === REQUEST_FAILURE) {
            this.setState({
                showSuccessMessage: false,
            });
        }
    };

    _renderVerifyToken() {
        const { requests } = this.props;

        const token = getTokenFromProps(this.props);

        let mergedRequest = { ...requests.verifyAccount };

        // Merge the requests together and treat them like one request.
        if (requests.logout.status === PENDING) {
            mergedRequest.status = PENDING;
        }

        return (
            <Page>
                <PageTitle>Verify Account</PageTitle>
                <PageContent>
                    {token && (
                        <ContainedButton
                            onClick={() => { this._submitToken(token); }}
                        >
                            Verify Account
                        </ContainedButton>
                    )}
                    {!token && (
                        <ErrorNotice>
                            <Body1 as="div">
                                The link used to come to this page is invalid
                            </Body1>
                        </ErrorNotice>
                    )}
                </PageContent>
            </Page>
        );
    }

    _renderLogin() {
        const { requests } = this.props;
        const { email, showSuccessMessage } = this.state;

        return (
            <Page>
                <PageTitle>Log In</PageTitle>
                <PageContent>
                    {showSuccessMessage && (
                        <SuccessNotice>
                            Congratulations, you have verified your email address!
                            Please log in to continue.
                        </SuccessNotice>
                    )}
                    <LoginForm
                        initialValue={{
                            email: email,
                            password: '',
                        }}
                        request={requests.login}
                        onSubmit={this._handleLoginSubmit}
                    />
                </PageContent>
            </Page>
        );
    }

    _renderComplete() {
        const urlParams = queryString.parse(this.props.location.search);
        const linkingToken = urlParams.dlt;
        const assetName = urlParams.an;

        if (linkingToken && assetName) {
            return (
                <Redirect to={{
                    pathname: '/confirm-device',
                    search: queryString.stringify({
                        linking_token: linkingToken,
                        asset_name: assetName,
                    })
                }}/>
            );
        }
        else {
            // Redirect to home page
            return (
                <Redirect to={{
                    pathname: '/',
                }}/>
            );
        }
    }

    render() {
        const { currentStep } = this.state;

        switch (currentStep) {
            case STEPS.VERIFY_TOKEN:
                return this._renderVerifyToken();
            case STEPS.LOGIN:
                return this._renderLogin();
            case STEPS.COMPLETE:
                return this._renderComplete();
            default:
                throw new Error(`Unknown step: ${currentStep}`);
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: selectIsLoggedIn(state.session),
        requests: {
            login: selectSessionRequest(state.session, 'login'),
            logout: selectSessionRequest(state.session, 'logout'),
            verifyAccount: selectSessionRequest(state.session, 'verifyAccount'),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            login: login,
            logout: logout,
            verifyAccount: verifyAccount,
        }, dispatch),
    };
};

// connect the component to Redux
const VerifyAccountPage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyAccountPagePresentation));

export default VerifyAccountPage;
