import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormElement from 'Components/form-element/FormElement';
import FormElementHelperText from 'Components/form-element/FormElementHelperText';
import FormElementLabel from 'Components/form-element/FormElementLabel';
import uniqueId from 'Common/util/uniqueId';
import EnterpriseHierarchySelectInput from './EnterpriseHierarchySelectInput';

export default function EnterpriseHierarchySelect(props) {
    const {
        name,
        value,
        onChange,
        disabled,
        error,
        label,
        helperText,
        enterpriseHierarchy,
        optionsFilter,
        selectionDisplay,
    } = props;

    const [ id ] = useState(() => {
        return uniqueId();
    });

    return (
        <FormElement
            disabled={disabled}
            error={error}
        >
            <FormElementLabel htmlFor={id}>{label}</FormElementLabel>
            <EnterpriseHierarchySelectInput
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                enterpriseHierarchy={enterpriseHierarchy}
                optionsFilter={optionsFilter}
                selectionDisplay={selectionDisplay}
            />
            {helperText !== null && (
                <FormElementHelperText>{helperText}</FormElementHelperText>
            )}
        </FormElement>
    );
}

EnterpriseHierarchySelect.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    enterpriseHierarchy: PropTypes.shape({
        nodes: PropTypes.object.isRequired,
    }),
    optionsFilter: PropTypes.func,
    selectionDisplay: PropTypes.element,
};

EnterpriseHierarchySelect.defaultProps = {
    disabled: false,
    error: false,
    helperText: null,
};
