import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = styled.div`
    margin-top: auto;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.23);
    z-index: 100;

    a {
        text-decoration: none;
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
        color: #2352a2;
    }
`;

const Line1 = styled.div`
    font-size: 12px;
    font-weight: bold;
    text-align: center;
`;

const Line2 = styled.div`
    font-size: 10px;
    margin-top: 10px;
    text-align: center;
`;

const Spacer = styled.span`
    padding-left: 5px;
    padding-right: 5px;
`;

export default class FooterContainer extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    constructor() {
        super();
    }

    _renderSpacer() {
        return (
            <Spacer> | </Spacer>
        );
    }

    render() {
        const { className } = this.props;

        return (
            <Footer className={className}>
                <Line1>
                    <span> Vorne XL Enterprise</span>

                    {this._renderSpacer()}
                    <a href="//www.vorne.com"> www.vorne.com </a>

                    {this._renderSpacer()}
                    <span> +1.630.875.3600 </span>

                    {this._renderSpacer()}
                    <a href="mailto:support@vorne.com"> support@vorne.com </a>
                </Line1>
                <Line2>
                    <span> Copyright &copy; {new Date().getFullYear()} Vorne Industries, Inc.</span>

                    {this._renderSpacer()}
                    <span> <Link to="/about/privacy-policy"> Privacy Policy </Link> </span>

                    {this._renderSpacer()}
                    <span> <Link to="/about/cookie-policy"> Cookie Policy </Link> </span>

                    {this._renderSpacer()}
                    <span> <Link to="/about/terms-of-use"> Terms of Use </Link> </span>

                    {this._renderSpacer()}
                    <span> <Link to="/about/third-party-licenses"> Third-Party Licenses </Link> </span>
                </Line2>
            </Footer>
        );
    }
}
