import styled from 'styled-components';

const IconLink = styled.a`
    border: none;
    background-color: transparent;

    display: inline-flex;
    position: relative;
    align-items: center;
    height: 50px;
    padding: 0 16px;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    font-size: 18px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none;
    z-index: 5001;

    :hover, &.active {
        color: ${props => props.theme.colors.darkText.highEmphasis};
    }
`;

export default IconLink;
