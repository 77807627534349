import React, { useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import LiveControlBar from 'Common/components/LiveControlBar';
import LiveControl from 'Common/components/LiveControlBar/LiveControl';
import LiveControlIcon from 'Common/components/LiveControlBar/LiveControlIcon';
import LiveControlSeparator from 'Common/components/LiveControlBar/LiveControlSeparator';
import Filters from 'Common/types/Filters/Filters';

import useTranslation, { useTranslator } from 'Common/hooks/useTranslation';

import EnterpriseHierarchyControl from 'Common/components/LiveControls/EnterpriseHierarchyControl';
import FieldSelectControl from 'Common/components/LiveControls/FieldSelectControl';
import FilterControl from 'Common/components/LiveControls/FilterControl';
import TimeRangeControl from 'Common/components/LiveControls/TimeRangeControl';
import renderDuplicateLiveControl from 'Common/components/LiveControls/DuplicateLiveControl/renderDuplicateLiveControl';


export default function KPIWidgetLiveControls ({
    controller,
    suppressedControls,
    children,
}) {
    const {
        metric,
        setMetric,
        metricOptions,

        filters,
        setSliceFilters,
        filterOptions,

        followPageTimeRange,
        timeRange,
        setTimeRange,

        followAppBarHierarchyNode,
        hierarchyNode,
        setHierarchyNode,
        hierarchyNodeOptions,

        isLayoutModeActive,
        duplicateWidget,
    } = controller;
    const translator = useTranslator();
    
    const [selectedControl, setSelectedControl] = useState(null);

    const closeSelectedControl = useCallback(() => {
        setSelectedControl(null);
    }, []);

    const hideControl = (controlName) => {
        // hide control there is a suppressed object and the control name is truthy within that
        // object
        return Boolean(suppressedControls && suppressedControls[controlName]);
    };

    const filterControlRef = useRef(null);

    const onFilterClose = useCallback(() => {
        return filterControlRef.current.onClose();
    }, []);

    const handleSelectionChange = useCallback((newSelection) => {
        let blockSelectionChange = false;

        if (selectedControl === 'filter' && newSelection !== 'filter') {
            blockSelectionChange = !onFilterClose();
        }

        if (!blockSelectionChange) {
            setSelectedControl(newSelection);
        }
    }, [onFilterClose, selectedControl]);

    // Filters
    //

    const activeFilterCount = useMemo(() => {
        return Filters.countActiveExpressions(filters);
    }, [filters]);

    const transformedFilterValue = useMemo(() => {
        // Enforce that ONLY slice filters are ever used.
        return {
            slice: filters.slice,
            group: { expressions: [] },
        };
    }, [ filters ]);

    const handleFilterApply = useCallback((event) => {
        // Close the control when the filters are applied
        closeSelectedControl();

        // We only care about slice filters
        const newSliceFilters = event.target.value.slice;
        setSliceFilters(newSliceFilters);
    }, [closeSelectedControl, setSliceFilters]);

    // Metric
    //

    const transformedMetricValue = useMemo(() => {
        return {
            name: metric,
        };
    }, [metric]);

    const handleMetricChange = useCallback((event) => {
        // Close the control after picking a metric field
        handleSelectionChange(null);
        setMetric(event.target.value);
    }, [handleSelectionChange, setMetric]);

    // Hierarchy Node
    //

    const handleHierarchyNodeChange = useCallback((event) => {
        // Close the control after picking a node
        handleSelectionChange(null);
        setHierarchyNode(event.target.value);
    }, [handleSelectionChange, setHierarchyNode]);

    const handleFollowAppBarHierarchyNodeChange = useCallback((event) => {
        const newFollowAppBarHierarchyNode = event.target.value;

        let newHierarchyNode;

        if (newFollowAppBarHierarchyNode) {
            // Clear the hierarchy node
            newHierarchyNode = null;
        }
        else {
            // Set hierarchy node to default
            newHierarchyNode = 'self';
        }

        setHierarchyNode(newHierarchyNode);
    }, [setHierarchyNode]);

    // Time Range
    //

    const handleTimeRangeChange = useCallback((event) => {
        // Close the control after picking a time range
        handleSelectionChange(null);
        setTimeRange(event.target.value);
    }, [handleSelectionChange, setTimeRange]);

    const handleFollowPageTimeRangeChange = useCallback((event) => {
        const newFollowPageTimeRange = event.target.value;

        let newTimeRange;

        if (newFollowPageTimeRange) {
            // Clear the time range
            newTimeRange = null;
        }
        else {
            // Set time range to default
            newTimeRange = { units: 'shift', start: 0, end: 0 };
        }

        setTimeRange(newTimeRange);
    }, [setTimeRange]);

    const assetHierarchyLiteral = useTranslation('control.common.asset_hierarchy');
    const timeRangeLiteral = useTranslation('control.common.time_range');
    const kpiMetricLiteral = useTranslation('control.kpi.metric');
    const filterLiteral = useTranslation('control.common.filter');

    const showDuplicateControl = () => {
        return isLayoutModeActive && ! hideControl('duplicateWidget');
    };

    return (
        <LiveControlBar
            selection={selectedControl}
            onSelectionChange={handleSelectionChange}
        >
            { hideControl('hierarchyNode') || 
                <LiveControl
                    name="hierarchyNode"
                    displayName={assetHierarchyLiteral}
                    controlRender={() => {
                        return (
                            <EnterpriseHierarchyControl
                                hierarchySelection={hierarchyNode}
                                onHierarchySelectionChange={handleHierarchyNodeChange}
                                hierarchyList={hierarchyNodeOptions}
                                followAppBar={followAppBarHierarchyNode}
                                showFollowAppBarCheckbox={true}
                                onFollowAppBar={handleFollowAppBarHierarchyNodeChange}
                            />
                        );
                    }}
                    icon={(
                        <LiveControlIcon type="vorne" iconName="globe" />
                    )}
                />
            }
            { hideControl('timeRange') || 
                <LiveControl
                    name="timeRange"
                    displayName={timeRangeLiteral}
                    suppressControlHeader={true}
                    controlRender={() => {
                        return (
                            <TimeRangeControl
                                showFollowPageCheckbox
                                selectionValue={timeRange}
                                onSelectionChange={handleTimeRangeChange}
                                onFollowPageChange={handleFollowPageTimeRangeChange}
                                followPageValue={followPageTimeRange}
                            />
                        );
                    }}
                    icon={(
                        <LiveControlIcon type="vorne" iconName="time" />
                    )}
                />
            }
            <LiveControlSeparator />
            { hideControl('field') || 
                <LiveControl
                    name="field"
                    displayName={kpiMetricLiteral}
                    controlRender={() => {
                        return (
                            <FieldSelectControl
                                collapsibleGroups
                                showFlattenCheckbox
                                options={metricOptions}
                                value={transformedMetricValue}
                                onChange={handleMetricChange}
                            />
                        );
                    }}
                    icon={(
                        <LiveControlIcon type="vorne" iconName="column-control" />
                    )}
                />
            }
            { hideControl('filter') || 
                <LiveControl
                    name="filter"
                    displayName={filterLiteral}
                    badge={activeFilterCount}
                    icon={(
                        <LiveControlIcon type="vorne" iconName="filter" />
                    )}
                    controlRender={() => {
                        return (
                            <FilterControl
                                ref={filterControlRef}
                                initialValue={transformedFilterValue}
                                onApply={handleFilterApply}
                                onCancel={closeSelectedControl}
                                categoryValueOptions={filterOptions.values}
                                categoryOptions={filterOptions.categories}
                                metricOptions={[]}
                            />
                        );
                    }}
                />
            }
            {showDuplicateControl() && <LiveControlSeparator />}
            {showDuplicateControl() && (
                renderDuplicateLiveControl({
                    name: 'duplicateWidget',
                    key: 'duplicateWidget',
                    translator: translator,
                    action: duplicateWidget,
                })
            )}
            {children}
        </LiveControlBar>
    );
}

KPIWidgetLiveControls.propTypes = {
    name: PropTypes.string,
    controller: PropTypes.shape({
        metric: PropTypes.string.isRequired,
        setMetric: PropTypes.func.isRequired,
        metricOptions: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                isDummyGroup: PropTypes.bool.isRequired,
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                    }).isRequired
                ).isRequired,
            }).isRequired
        ).isRequired,

        filters: PropTypes.object.isRequired,
        setSliceFilters: PropTypes.func.isRequired,
        filterOptions: PropTypes.shape({
            categories: PropTypes.arrayOf(
                PropTypes.object
            ).isRequired,
            values: PropTypes.object.isRequired,
        }).isRequired,

        followPageTimeRange: PropTypes.bool.isRequired,
        timeRange: PropTypes.object,
        setTimeRange: PropTypes.func.isRequired,

        followAppBarHierarchyNode: PropTypes.bool.isRequired,
        hierarchyNode: PropTypes.string,
        setHierarchyNode: PropTypes.func.isRequired,
        hierarchyNodeOptions: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                iconClass: PropTypes.string.isRequired,
                level: PropTypes.number.isRequired,
            })
        ).isRequired,

        isLayoutModeActive: PropTypes.bool.isRequired,
        duplicateWidget: PropTypes.func.isRequired,
    }),
    suppressedControls: PropTypes.object,
    children: PropTypes.node,
};

KPIWidgetLiveControls.defaultProps = {};
