import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Body1 } from 'Components/typography/typography';
import withFormElementContext from 'Components/form-element/withFormElementContext';

const Root = styled(Body1).attrs({ as: 'input' })`
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 0 6px;
    height: calc(2em + 2px);
    line-height: 2em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    outline: none;

    &.error {
        border: 1px solid ${props => props.theme.colors.palette.error.red};
    }

    &.focused:not(.error) {
        border: 1px solid rgba(0, 0, 0, 1.0);
    }

    &[disabled] {
        cursor: default;
        color: ${props => props.theme.colors.darkText.disabled};
        background-color: rgba(0,0,0,0.05);
    }
`;

class TextInputField extends React.Component {
    constructor() {
        super();
    }

    _handleFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
        this.props.formElementContext.onFocus(event);
    };

    _handleBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
        this.props.formElementContext.onBlur(event);
    };

    render() {
        const {
            className: classNameProp,
            formElementContext,
            readOnly,
            disabled: disabledProps,
            ...otherProps
        } = this.props;

        const {
            focused,
            disabled: disabledFormContext,
            required,
            error,
        } = formElementContext;

        const className = classNames(classNameProp, { focused, error });

        return (
            <Root
                className={className}
                {...otherProps}
                focused={focused}
                disabled={disabledFormContext || disabledProps}
                readOnly={readOnly}
                required={required}
                onFocus={this._handleFocus}
                onBlur={this._handleBlur}
            />
        );
    }
}

TextInputField.defaultProps = {
    readOnly: false,
};

TextInputField.propTypes = {
    className: PropTypes.string,
    formElementContext: PropTypes.object.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default withFormElementContext(TextInputField);
