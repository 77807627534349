import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ThemedTableHeaderColumn = styled.th`
    padding: 8px;
    text-align: left;
`;

function TableHeaderColumn({ children, ...otherProps }) {
    return (
        <ThemedTableHeaderColumn {...otherProps} >
            {children}
        </ThemedTableHeaderColumn>
    );
}

TableHeaderColumn.propTypes = {
    children: PropTypes.node,
};

export default TableHeaderColumn;
