
import { sendDeleteRequest } from 'Http/request';
import {
    deleteResource,
} from 'Common/util/ResourceCollection';

export function deleteSupportUser(userId) {
    return deleteResource({
        requestId: 'deleteSupportUser',
        resourceId: userId,
        resourceType: 'supportUsers',
        sendRequest: () => sendDeleteRequest(`/api/v1/vornesupport/user/${userId}`),
    });
}

export default deleteSupportUser;
