import { useCallback, useMemo } from 'react';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import TimeRange from 'Common/types/TimeRange/TimeRange';
import { useWidgetCache } from 'Common/components/Dashboard/DashboardCache';
import useRepeatingRequest, { getMaxAgeForRepeatingRequest } from 'Common/hooks/requests/useRepeatingRequest';
import { useTranslator } from 'Common/hooks/useTranslation';
import useRequestWithStatus from 'Common/hooks/requests/useRequestWithStatus';

export const TIME_RANGE_PRIMARY_DETAILS = 'timeRangePrimaryDetails';
export const TIME_RANGE_SECONDARY_DETAILS = 'timeRangeSecondaryDetails';

function useTimeRangeDataInternal({
    getTimeRangePrimaryDetails,
    getTimeRangeSecondaryDetails,
    hierarchyNodes,
    timeRange,
    translator,
    updateIntervalMs,
    disableRepeat,
}) {
    const timeRangePrimaryRequest = useRequestWithStatus(
        useCallback(async ({ abortSignal }) => {
            try {
                if (timeRange) {
                    return await getTimeRangePrimaryDetails({
                        abortSignal: abortSignal,
                        hierarchyNodes,
                        timeRange,
                    });
                }
                else {
                    return {
                        response: null,
                        error: null
                    };
                }
            }
            catch (err) {
                console.error(err);
                return { error: translator('error_literal') };
            }
        }, [ getTimeRangePrimaryDetails, hierarchyNodes, timeRange, translator ]),
    );

    const timeRangeSecondaryRequest = useRequestWithStatus(
        useCallback(async ({ abortSignal }) => {
            try {
                if (timeRange) {
                    return await getTimeRangeSecondaryDetails({
                        abortSignal: abortSignal,
                        hierarchyNodes,
                        timeRange,
                    });
                }
                else {
                    return {
                        response: null,
                        error: null
                    };
                }
            }
            catch (err) {
                console.error(err);
                return { error: translator('error_literal') };
            }
        }, [ getTimeRangeSecondaryDetails, hierarchyNodes, timeRange, translator ]),
    );

    const {
        send: sendTimeRangePrimaryRequest,
        resend: resendTimeRangePrimaryRequest,
        requestState: timeRangePrimaryRequestState,
    } = timeRangePrimaryRequest;

    const {
        send: sendTimeRangeSecondaryRequest,
        resend: resendTimeRangeSecondaryRequest,
        requestState: timeRangeSecondaryRequestState,
    } = timeRangeSecondaryRequest;

    // Put both of the requests into a single async function.
    const requestTimeRangeData  = useCallback(
        async ({ isInitialRequest, abortSignal }) => {
            if (isInitialRequest) {
                await Promise.allSettled([
                    sendTimeRangePrimaryRequest({ abortSignal }),
                    sendTimeRangeSecondaryRequest({ abortSignal }),
                ]);
            }
            else {
                await Promise.allSettled([
                    resendTimeRangePrimaryRequest({ abortSignal }),
                    resendTimeRangeSecondaryRequest({ abortSignal }),
                ]);
            }
        },
        [
            sendTimeRangePrimaryRequest,
            sendTimeRangeSecondaryRequest,
            resendTimeRangePrimaryRequest,
            resendTimeRangeSecondaryRequest,
        ]
    );

    useRepeatingRequest({
        sendRequest: requestTimeRangeData,
        delayMs: updateIntervalMs,
        disableRepeat,
    });

    return useMemo(() => {
        return {
            [TIME_RANGE_PRIMARY_DETAILS]: timeRangePrimaryRequestState,
            [TIME_RANGE_SECONDARY_DETAILS]: timeRangeSecondaryRequestState,
        };
    }, [ timeRangePrimaryRequestState, timeRangeSecondaryRequestState ]);
}

const paramTypes = ParamTypes.shape({
    getTimeRangePrimaryDetails: ParamTypes.func.isRequired,
    getTimeRangeSecondaryDetails: ParamTypes.func.isRequired,

    hierarchyNode: ParamTypes.string.isRequired,
    timeRange: TimeRange.timeRangeParamType,
    updateInterval: ParamTypes.number,
});

export default function useTimeRangeData(parameters) {
    paramTest(parameters, paramTypes, 'useTimeRangeData');

    const {
        getTimeRangePrimaryDetails,
        getTimeRangeSecondaryDetails,
        hierarchyNode,
        timeRange,
        updateInterval,
    } = parameters;

    const disableRepeat = useMemo(() => {
        // Disable the repeat if there's no update interval or if the time
        // range is not suitable.
        return (
            !updateInterval ||
            !timeRange ||
            !TimeRange.shouldRepeatRequestWithTimeRange(timeRange)
        );
    }, [ timeRange, updateInterval ]);

    const updateIntervalMs = updateInterval || 0;

    const translator = useTranslator();

    const hierarchyNodes = useMemo(() => { return [hierarchyNode]; }, [hierarchyNode]);

    // getting timerange primary details
    const getTimeRangePrimaryDetailsCache = useWidgetCache({
        cacheId: 'getTimeRangePrimaryDetails',
        load: ({ abortSignal }, args) => {
            return getTimeRangePrimaryDetails({
                ...args,
                abortSignal,
            });
        },
    });

    // A cached version of `getTimeRangePrimaryDetails`
    const getTimeRangePrimaryDetailsCached = useCallback((args) => {
        const { abortSignal, ...otherArgs } = args;
        const maxAge = getMaxAgeForRepeatingRequest(disableRepeat, updateIntervalMs);

        return getTimeRangePrimaryDetailsCache.get(
            {
                abortSignal,
                maxAge,
            },
            otherArgs
        );
    }, [ disableRepeat, getTimeRangePrimaryDetailsCache, updateIntervalMs ]);

    // getting timerange secondary details
    const getTimeRangeSecondaryDetailsCache = useWidgetCache({
        cacheId: 'getTimeRangeSecondaryDetails',
        load: ({ abortSignal }, args) => {
            return getTimeRangeSecondaryDetails({
                ...args,
                abortSignal,
            });
        },
    });

    // A cached version of `getTimeRangeSecondaryDetails`
    const getTimeRangeSecondaryDetailsCached = useCallback((args) => {
        const { abortSignal, ...otherArgs } = args;
        const maxAge = getMaxAgeForRepeatingRequest(disableRepeat, updateIntervalMs);

        return getTimeRangeSecondaryDetailsCache.get(
            {
                abortSignal,
                maxAge,
            },
            otherArgs
        );
    }, [ disableRepeat, getTimeRangeSecondaryDetailsCache, updateIntervalMs ]);

    return useTimeRangeDataInternal({
        getTimeRangePrimaryDetails: getTimeRangePrimaryDetailsCached,
        getTimeRangeSecondaryDetails: getTimeRangeSecondaryDetailsCached,
        hierarchyNodes,
        timeRange,
        translator,
        updateIntervalMs,
        disableRepeat,
    });
}
