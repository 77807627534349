import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonText from 'Components/button/ButtonText';
import List from 'Components/list/List';
import { Caption } from 'Components/typography/typography';
import ContainedButton from 'Components/button/ContainedButton';
import PageFrame from 'Components/page/PageFrame';
import SectionDescription from 'Components/text/SectionDescription';

const AddAlertButtonWrapper = styled.div`
    display: block;
    margin: 10px 0;
`;

const AlertList = styled(List)`
    border: 1px solid #d5d5d5;

    & > * {
        border-top: 1px solid #d5d5d5;

        &:first-child {
            border-top: none;
        }
    }
`;

const EmptyAlerts = styled.div`
    display: inline-block;
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 3px;
    background-color: #f5f5f5;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};

    & > * {
        display: block;
        &.secondary {
            font-weight: normal;
        }
    }
`;

export default class AlertsEditorSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            title,
            description,
            addButtonEnabled,
            children,
            showAddButton,
            onAddClick,
        } = this.props;

        const hasAlerts = React.Children.count(children) > 0;

        return (
            <PageFrame title={title}>
                {description && (
                    <SectionDescription>{description}</SectionDescription>
                )}
                {showAddButton &&
                    <AddAlertButtonWrapper>
                        <ContainedButton
                            color="primary"
                            type="button"
                            disabled={!addButtonEnabled}
                            onClick={onAddClick}
                        >
                            <ButtonText>
                                {'Add Alert'}
                            </ButtonText>
                        </ContainedButton>
                    </AddAlertButtonWrapper>
                }
                {hasAlerts && (
                    <AlertList>
                        {children}
                    </AlertList>
                )}
                {!hasAlerts && (
                    <EmptyAlerts>
                        <Caption>There are no alerts.</Caption>
                        <Caption className="secondary">{'Click "Add Alert" to create one.'}</Caption>
                    </EmptyAlerts>
                )}
            </PageFrame>
        );
    }
}

AlertsEditorSection.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node,
    addButtonEnabled: PropTypes.bool,
    children: PropTypes.any,
    showAddButton: PropTypes.bool,
    onAddClick: PropTypes.func,
};

AlertsEditorSection.defaultProps = {
    addButtonEnabled: true,
    children: null,
    showAddButton: true,
    onAddClick: () => {},
};
