import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HierarchyEditor from './HierarchyEditor';
import AddNodeDropdown from './AddNodeDropdown';
import HierarchyNodeEditor from './HierarchyNodeEditor';
import { updateAtPath, createPath } from 'Common/util/FormUtils';
import { NodeType } from './HierarchyNodeType';
import mapValues from 'lodash/mapValues';
import { Spacing } from 'Components/common/common';

const Root = styled.div``;

const AddNodeDropdownWrapper = styled.div`
    margin-top: ${Spacing.formButtonMargin};
`;

export default class EnterpriseHierarchyEditor extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.object.isRequired,
        validation: PropTypes.object,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        name: '',
        validation: {},
        disabled: false,
    };

    constructor(props) {
        super(props);
    }

    _emitOnChange = (newValue) => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: newValue,
            },
        });
    };

    _handleHierarchyChange = (event) => {
        this._emitOnChange(event.target.value);
    };

    _newNodeIdGen = (function * () {
        let n = 1000;

        while (true) {
            yield `--new-${n++}`;
        }
    })();

    _handleAddNodeSelect = (event) => {
        const { value: newNodeType } = event.target;
        const rootId = this.props.value.rootId;

        const newNode = {
            id: this._newNodeIdGen.next().value,
            isNew: true,
            node_name: '',
            node_type: newNodeType,
            children: [],
            parents: [rootId],
        };

        // Add the node
        let newValue = updateAtPath(this.props.value, 'nodes', (nodes) => {
            return {
                ...nodes,
                [newNode.id]: newNode,
            };
        });

        // Set the node as the first child of the root node
        newValue = updateAtPath(newValue, createPath('nodes', newValue.rootId, 'children'), (rootChildren) => {
            return [
                newNode.id,
                ...rootChildren,
            ];
        });

        this._emitOnChange(newValue);
    };

    _handleNodeChange = (event) => {
        const newValue = updateAtPath(this.props.value, event.target.name, () => {
            return event.target.value;
        });

        this._emitOnChange(newValue);
    };

    _handleNodeDelete = (nodeId) => {
        const { nodes } = this.props.value;

        const nodeToDelete = nodes[nodeId];

        // Remove the node from all other nodes' children
        let newNodes = mapValues(nodes, (node) => {
            return {
                ...node,
                children: node.children.filter(childId => childId !== nodeToDelete.id),
            };
        });

        const getChildIds = (node) => {
            const flatChildIds = node.children.reduce((curr, childId) => {
                const childNode = nodes[childId];
                return curr.concat(getChildIds(childNode));
            }, []);

            return node.children.concat(flatChildIds);
        };

        const flatChildIds = getChildIds(nodeToDelete);

        delete newNodes[nodeToDelete.id];

        flatChildIds.forEach((childId) => {
            delete newNodes[childId];
        });

        this._emitOnChange({
            ...this.props.value,
            nodes: newNodes,
        });
    };

    _renderAddNodeDropdown() {
        const {
            readOnly,
        } = this.props;

        if (! readOnly) {
            return (
                <AddNodeDropdownWrapper>
                    <AddNodeDropdown
                        onSelect={this._handleAddNodeSelect}
                    />
                </AddNodeDropdownWrapper>
            );
        }
    }

    render() {
        const {
            value,
            validation,
            readOnly,
        } = this.props;

        return (
            <Root>
                <HierarchyEditor
                    role="list"
                    value={value}
                    onChange={this._handleHierarchyChange}
                    readOnly={readOnly}
                    renderNode={({ node, provided, snapshot }) => {
                        return (
                            <HierarchyNodeEditor
                                innerRef={provided.innerRef}
                                name={`nodes.${node.id}`}
                                value={node}
                                onChange={this._handleNodeChange}
                                onDelete={this._handleNodeDelete}
                                enterpriseHierarchy={value}
                                dragHandleProps={provided.dragHandleProps}
                                readOnly={readOnly}
                                isDragging={snapshot.isDragging || snapshot.isParentDragging}
                                validation={validation?.nodes?.[node.id] || null}
                            />
                        );
                    }}
                    allowDropChild={(node, draggedNode) => {
                        switch (node.node_type) {
                            case NodeType.DEVICE:
                                return false;
                            case NodeType.AREA:
                                return [
                                    NodeType.DEVICE,
                                ].indexOf(draggedNode.node_type) !== -1;
                            case NodeType.PLANT:
                                return [
                                    NodeType.AREA,
                                    NodeType.DEVICE,
                                ].indexOf(draggedNode.node_type) !== -1;
                            case NodeType.ENTERPRISE:
                                return true;
                            default:
                                throw new Error(`Unknown node_type ${node.node_type}`);
                        }
                    }}
                />
                {this._renderAddNodeDropdown()}
            </Root>
        );
    }
}
