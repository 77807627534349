import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import KPIData from './internal/KPIData';
import KPITrendDot from './internal/KPITrendDot';
import * as TrendGoodness from 'Common/components/TrendDot/TrendGoodness';


const Container = styled.div`
    height: 65px;

    padding-top: 0;
    padding-bottom: ${({theme}) => theme.layout.widget.verticalPadding };
    padding-left: ${({theme}) => theme.layout.widget.horizontalPadding };
    padding-right: ${({theme}) => theme.layout.widget.horizontalPadding };

    ${({ condensed }) => condensed && `
        padding: 0px 12px;
        height: 55px;
    `}

    ${({ isPrint }) => isPrint && `
        padding: 0 8px 6px 8px;
    `}
`;

export default function KPIContent({
    condensed,
    noColor,
    kpiData,
    sparkLineData,
    trendGoodness,
    trendValue,
    isPrint,
    hideTrend,
}) {
    const finalTrendGoodness =
            noColor ? TrendGoodness.NONE : trendGoodness;

    return (
        <Container
            isPrint={isPrint}
            condensed={condensed}
        >
            <KPIData
                isPrint={isPrint}
                condensed={condensed}
                noColor={noColor}
                value={kpiData}
                sparkLineData={sparkLineData}
                hideTrend={hideTrend}
                trendDot={(
                    <KPITrendDot
                        trendGoodness={finalTrendGoodness}
                        value={trendValue}
                        condensed={condensed}
                        isPrint={isPrint}
                        noColor={noColor}
                        showTrendLabel
                        showPositiveTrendQualifier
                    />
                )}
            />
        </Container>
    );
}

KPIContent.propTypes = {
    condensed: PropTypes.bool,
    noColor: PropTypes.bool.isRequired,
    kpiData: PropTypes.node.isRequired,
    sparkLineData: PropTypes.array.isRequired,
    trendGoodness: PropTypes.string.isRequired,
    trendValue: PropTypes.number,
    isPrint: PropTypes.bool,
    hideTrend: PropTypes.bool.isRequired,
};

KPIContent.defaultProps = {
    condensed: false,
    isPrint: false,
};
