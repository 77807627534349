import * as RequestStatus from 'Common/data/RequestStatus';
import {
    REQUEST_RESET,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from './actions';

const initialState = {
    status: RequestStatus.IDLE,
    error: null,
};

export function requestReducer(state = initialState, action) {
    const {
        type,
        payload,
    } = action;

    switch (type) {
        case REQUEST_RESET: {
            return initialState;
        }
        case REQUEST_PENDING: {
            return {
                status: RequestStatus.PENDING,
                error: null,
            };
        }
        case REQUEST_SUCCESS: {
            return {
                status: RequestStatus.SUCCESS,
                error: null,
            };
        }
        case REQUEST_FAILURE: {
            return {
                status: RequestStatus.FAILURE,
                error: { ...payload.error },
            };
        }
        default:
            return state;
    }
}
