
export function getColumnFormatType(columnSchema, isCategoryValue = false) {
    const isAssetBased = false;

    const {
        type,
        subtype,
        name,
        post_process,
    } = columnSchema;

    let formatType;

    if (post_process) {
        // Each post process type is it's own formatType
        formatType = post_process;
    }
    else if (isAssetBased) {
        // Each asset has its own formatType.
        formatType = name;
    }
    else if (isCategoryValue) {
        formatType = 'category_value';
    }
    else {
        // Anything else just uses the subtype if they have one
        // or they just fallback to type.
        //
        formatType = subtype || type;
    }

    return formatType;
}
