import React from 'react';
import styled from 'styled-components';
import { childrenAreOneOf } from 'Common/util/propTypeHelpers';
import { useElidedChildren } from 'Common/components/MenuToolbar/hooks';
import MenuToolbarOverflow from './MenuToolbarOverflow';


/**
 * MenuToolbar
 *
 * A horizontal toolbar that holds MenuDropdowns or MenuButtons.
 *
 */
const Root = styled.div`
    white-space: nowrap;
    overflow-y: visible;

    &:first-child {
        margin-left: -10px;
    }
`;

export default function MenuToolbar(props) {
    const children = React.Children.toArray(props.children);
    const { containerRef, visibleItems } = useElidedChildren(children.length);
    const vc = visibleItems !== null ? visibleItems : children.length;

    let visibleChildren = [];
    let overflowChildren = [];

    // Display only the number of items that will fit in the menu bar.
    for (let ii = 0; ii < children.length; ii++) {
        // Clone each item in children so that we can add a key to keep react happy,
        // then add them to the main or overflow list.
        const list = ii < vc ? visibleChildren : overflowChildren;
        list.push(React.cloneElement(children[ii], {key: ii}));
    }

    // And put the rest into an overflow dropdown.
    if (overflowChildren.length > 0) {
        const overflowDropdown = (
            <MenuToolbarOverflow
                key='overflow'
            >
                {overflowChildren}
            </MenuToolbarOverflow>
        );
        visibleChildren.push(overflowDropdown);
    }

    return (
        <Root ref={containerRef}>
            {visibleChildren}
        </Root>
    );
}


MenuToolbar.propTypes = {
    children: childrenAreOneOf({
        elementTypes: [],
        propShapes: [
            // dropdown:
            [
                'text',
                'children',
            ],
            // item:
            [
                'onClick',
                'text',
            ],
        ],
    }),
};
