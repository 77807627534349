import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import 'Common/font/fontawesome';
import 'Common/font/vorne-icon';

function Icon({ type, iconName, children, className, style }) {
    let classes = [];

    if (type === 'vorne') {
        classes = [
            'vorne-icon',
            `vorne-icon-${iconName}`
        ];
    }
    else {
        classes = [
            'fa',
            `fa-${iconName}`
        ];
    }

    const fullClassName = classNames(className, classes);

    return (
        <span className={fullClassName} style={style} aria-hidden='true'>
            {children}
        </span>
    );
}

Icon.propTypes = {
    type: PropTypes.oneOf(['vorne', 'fontAwesome']).isRequired,
    iconName: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.element,
    style: PropTypes.object,
};

Icon.defaultProps = {
    className: '',
};

export default Icon;
