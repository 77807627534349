import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Subtitle1 } from 'Components/typography/typography';

const Description = styled(Subtitle1)`
    display: block;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

function DataUseDescription() {
    return (
        <Description>
            <p>We’re Vorne. We are experts in improving manufacturing productivity and we look forward to helping you.</p>
            <p>We will use the information you provide to:</p>
            <ul>
                <li>Create XL Enterprise accounts.</li>
                <li>Send transactional emails that are generated by (or directly relate to) your XL Enterprise account.</li>
                <li>Periodically send emails about improving manufacturing productivity and how our XL products can help. We are confident you will find this information to be valuable, but of course you can unsubscribe at any time.</li>
            </ul>
            <p>We do not rent, sell, or lease personal information that you disclose to us. If you are located in a country with an approved XL reseller, we will share your information with our reseller so that they can contact and work with you directly. You can read our complete <Link to='/about/privacy-policy'>privacy policy</Link> here.</p>
        </Description>
    );
}

export default DataUseDescription;
