import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextButton from 'Components/button/TextButton';
import ContainedButton from 'Components/button/ContainedButton';
import TextInput from 'Components/text-input/TextInput';
import { sendPostRequest } from 'Http/request';
import { Spacing } from 'Components/common/common';
import Form from 'Components/form/Form';

const EmailInput = styled(TextInput)`
    display: block;
    width: 100%;
    box-sizing: border-box;
`;

const CancelLink = styled(TextButton)``;
const SubmitButton = styled(ContainedButton)``;
const FormButtonsContainer = styled.div`
    margin-top: ${Spacing.formButtonMargin};

    ${SubmitButton} {
        margin-right: 8px;
    }

    ${CancelLink} {
        text-decoration: none;
    }
`;

export default class ForgotPasswordForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        initialEmail: PropTypes.string,
        onSuccess: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: '',
        initialEmail: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            email: {
                value: props.initialEmail,
                dirty: false,
            },
        };
    }

    _validate() {
        const { email } = this.state;

        const validation = {
            email: {
                ...email,
                error: null,
            },
        };

        if (!email.value && email.dirty) {
            validation.email.error = 'required';
        }

        return validation;
    }

    _handleEmailChange = (event) => {
        const value = event.target.value;

        this.setState((state) => ({
            email: {
                ...state.email,
                value: value,
                dirty: true,
            },
        }));
    };

    _handleSubmit = (event) => {
        this._sendRequest();
    };

    async _sendRequest() {
        const email = this.state.email.value;

        this.setState({
            isLoading: true,
            error: null,
        });

        try {
            const response = await sendPostRequest('/api/v1/accounts/reset-password', {
                body: { email }
            });

            if (response.ok) {
                this.setState({
                    isLoading: false,
                    error: null,
                });
                this.props.onSuccess({ email });
            }
            else {
                this.setState({
                    isLoading: false,
                    error: response.data,
                });
            }
        }
        catch (err) {
            this.setState({
                isLoading: false,
                error: err || { message: 'Network error' },
            });
        }
    }

    render() {
        const { className } = this.props;
        const { isLoading, error } = this.state;
        const validation = this._validate();

        return (
            <Form
                className={className}
                showSubmit={false}
                showCancel={false}
                error={error}
                onSubmit={this._handleSubmit}
            >
                <EmailInput
                    id="forgot-password-email"
                    label="Email"
                    helperText={validation.email.error || ''}
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="email@example.com"
                    required
                    disabled={isLoading}
                    value={validation.email.value}
                    error={!!validation.email.error}
                    onChange={this._handleEmailChange}
                />
                <FormButtonsContainer>
                    <CancelLink
                        color="primary"
                        Component={Link}
                        to={{ pathname: '/' }}
                    >
                        Cancel
                    </CancelLink>
                    <SubmitButton
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                    >
                        Submit
                    </SubmitButton>
                </FormButtonsContainer>
            </Form>
        );
    }
}
