import ParamTypes, { paramTest } from '../ParamTypes';
import paramTypeWrapper from './paramTypeWrapper';

/**
 * ParamType checker which checks if the value is equal (===) to one of the given
 * options.
 *
 * @param {String[]|Number[]|Boolean[]} options The values to check against. An option
 *     can also be null or undefined.
 *
 * @returns {Function} ParamType checker which returns an array of failures.
 */
export default function oneOf(options) {
    paramTest(
        options, 
        ParamTypes.arrayOf(
            ParamTypes.oneOfType([
                ParamTypes.string, 
                ParamTypes.number, 
                ParamTypes.boolean,
            ])
        ).isRequired,
        'ParamTypes.oneOf options arg',
        { throwError: true, }
    );

    return paramTypeWrapper((arg, key) => {
        let failures = [];

        const matchesOption = options.some((option) => {
            return arg === option;
        });

        if (!matchesOption) {
            const formattedOptions = options.map((option) => {
                if (typeof option === 'string') {
                    return `"${option}"`;
                }
                else {
                    return `${option}`;
                }
            }).join(', ');

            failures.push(
                `${key} must be one of: ${formattedOptions}`
            );
        }

        return failures;
    });
}
