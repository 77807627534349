import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import Tooltip from 'Common/components/Tooltip';
import TooltipContent from './internal/TooltipContent';
import PropTypes from 'prop-types';
import { TOOLTIP_LARGE } from 'Common/components/Tooltip/Tooltip';
import { COLUMN_FORMAT_TYPE } from 'Common/components/SparkVisualization/SparkDimensionConstants';
import { useTranslator } from 'Common/hooks/useTranslation';
import addDimensionColors from './internal/addDimensionColors';
import { mergeValuesAfterMaxElements } from './internal/barAndColumnUtils';
import { categoryValueFormatTypes } from '../FormattedDatum/CategoryValue/CategoryValue';

const PADDING = 4;
const BORDER_RADIUS = 4;

const SparkColumn = styled.div.attrs(({ $width, $height, $margin, $backgroundColor }) => {
    // Heights between 0 and 1px or having decimal px can look weird when rendered.
    const height = Math.round(Math.max(1, $height));

    // Don't let the border radius be larger than the height or the browser
    // might render it weird.
    let borderRadius = Math.min(BORDER_RADIUS, height);

    // For some reason, a height and border radius of 1px will also lead the
    // browser to some weird rendering.
    if (height <= 1) {
        borderRadius = 0;
    }

    return {
        style: {
            width: `${$width}px`,
            height: `${height}px`,
            marginLeft: `${$margin}px`,
            marginRight: `${$margin}px`,
            backgroundColor: $backgroundColor,
            borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
        },
    };
})`
    display: inline-block;
    vertical-align: bottom;
`;

const SparkColumnContainer = styled.div.attrs(({ $width, $height }) => {
    return {
        style: {
            width: `${$width}px`,
            height: `${$height}px`,
        },
    };
})`
    padding: ${PADDING}px;
    text-align: left;
    display: flex;
    align-items: end;
`;

const NoValueContainer = styled.div`
    align-self: end;
`;

function getMaxMetricValue(values){
    return values.reduce((max, val) => {
        if (val.metric.rawValue) {
            return Math.max(max, val.metric.rawValue);
        }
        else {
            return max;
        }
    }, 0);
}

function calcHeight(currValue, maxValue, totalHeight) {
    if (!currValue) {
        return 0;
    }

    return (currValue / maxValue) * totalHeight;
}

function ColumnSparkVisualization(props) {
    const {width, height, data, maxElements, noColor} = props;
    const heightSansPadding = height - (2*PADDING);
    const theme = useTheme();
    const translator = useTranslator();

    const coloredData = useMemo(() => {
        if (!data) {
            return data;
        }

        return {
            ...data,
            values: addDimensionColors({
                values: data.values,
                otherColor: theme.colors.palette.grey.ash,
                maxElements,
            }),
        };
    }, [ data, maxElements, theme ]);

    const mergedValues = useMemo(() => {
        return mergeValuesAfterMaxElements(coloredData?.values || [], maxElements);
    }, [ coloredData, maxElements ]);

    const columnDetails = useMemo(() => {
        const margin = (maxElements > 7) ? 1 : 2;
        const colWidth = (width/maxElements) - (2*margin);
        let allValuesAreZero = !!mergedValues.length;
        let allValuesAreNull = true;

        const max = getMaxMetricValue(mergedValues);

        const columnValues = mergedValues.map((elem, index) => {
            let backgroundColor = elem.dimension.rawValue.color;

            if (noColor) {
                backgroundColor = index % 2 === 0
                    ? theme.colors.palette.grey.silver
                    : theme.colors.palette.grey.ash;
            }

            const rawValue = elem.metric.rawValue;
            const colHeight = calcHeight(rawValue, max, heightSansPadding);

            if (Number.isFinite(rawValue)) {
                allValuesAreNull = false;
                if (rawValue !== 0) {
                    allValuesAreZero = false;
                }
            }
            
            return (
                <SparkColumn
                    key={index}
                    $height={colHeight}
                    $width={colWidth}
                    $margin = {margin}
                    $backgroundColor={backgroundColor}
                />
            );
        });

        return {
            columns: columnValues,
            allValuesAreNull,
            allValuesAreZero
        };
    }, [theme, heightSansPadding, mergedValues, maxElements, width, noColor]);

    return (
        <Tooltip
            delay={500}
            placement="auto"
            scrollContentOnOverflow
            stickyOnClick
            content={<TooltipContent data={coloredData} />}
            style={{ display: 'inline-block' }}
            maxWidth={TOOLTIP_LARGE}
            disabled={columnDetails.allValuesAreNull}
        >
            {columnDetails.allValuesAreNull
                ? (
                    <NoValueContainer>
                        {translator("null_metric_literal")}
                    </NoValueContainer>
                )
                : columnDetails.allValuesAreZero
                    ? (
                        <NoValueContainer>
                            {translator("all_zero_values")}
                        </NoValueContainer>
                    )
                    : (
                        <SparkColumnContainer $width={width} $height={height}>
                            {columnDetails.columns}
                        </SparkColumnContainer>
                    )
            }
        </Tooltip>
    );
}

ColumnSparkVisualization.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        formatType: PropTypes.oneOf([COLUMN_FORMAT_TYPE]).isRequired,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                dimension: PropTypes.shape({
                    displayName: PropTypes.string,
                    name: PropTypes.string,
                    formatType: PropTypes.oneOf(categoryValueFormatTypes).isRequired,
                    rawValue: PropTypes.shape({
                        displayName: PropTypes.string,
                        name: PropTypes.string,
                        color: PropTypes.string
                    })
                }).isRequired,
                metric: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    formatType: PropTypes.string.isRequired,
                    rawValue: PropTypes.number
                }).isRequired,
            }),
        ).isRequired,
    }),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    maxElements: PropTypes.number.isRequired,
    noColor: PropTypes.bool,
};

export default ColumnSparkVisualization;
