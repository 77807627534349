import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/**
 * PopupLayer is a component that renders a single child through a portal so it
 * can be on top of all other components.
 *
 * See https://reactjs.org/docs/portals.html for more info about portals
 */

 const POPUP_ROOT_ID = 'popup-root';

function getPopupRoot() {
    let popupRoot = document.getElementById(POPUP_ROOT_ID);

    if (!popupRoot) {
        console.warn(`PopupLayer: Unable to find #${POPUP_ROOT_ID} in the DOM.` +
            ' One will be added to the body. To prevent issues, the element' +
            ' should exist in the DOM before rendering a PopupLayer.');
        popupRoot = document.createElement('div');
        popupRoot.setAttribute('id', POPUP_ROOT_ID);
        document.body.appendChild(popupRoot);
    }

    return popupRoot;
}

export default class PopupLayer extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);
        this._popupRoot = getPopupRoot();
        this._el = document.createElement('div');
        this.state = { mounted: false };
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to PopupLayer and only render the children when PopupLayer
        // is inserted in the DOM tree.
        this._popupRoot.appendChild(this._el);
        // Now that everything is attached to the DOM tree, trigger another render
        this.setState({ mounted: true });
    }

    componentWillUnmount() {
        this._popupRoot.removeChild(this._el);
    }

    _renderChildren() {
        const { children } = this.props;
        const { mounted } = this.state;

        if (!mounted) {
            return null;
        }

        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }

    render() {
        return ReactDOM.createPortal(
            this._renderChildren(),
            this._el,
        );
    }
}
