import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { interceptProps } from 'Components/util/reactUtils';
import PopupMask from 'Components/popup/PopupMask';

const ContentWrapper = styled(interceptProps('div', [ 'measured', 'top', 'left', 'width', 'height', 'maxHeight' ])).attrs(props => {
    let container = Number.isFinite(props.maxHeight)
        ? { style: { maxHeight: `${props.maxHeight}px` } }
        : { style: {} };

    if (!props.measured) {
        return container;
    }

    container.style.top = `${props.top}px`;
    container.style.left = `${props.left}px`;
    container.style.width = `${props.width}px`;
    container.style.height = `${props.height}px`;

    return container;
})`
    position: fixed;

    z-index: 1001;

    &.measured {
        & > .ContentWrapper-inner {
            display: block;
        }
    }

    &:not(.measured) {
        visibility: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    & > .ContentWrapper-inner {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
    }
`;

export default class SelectOptionsPopup extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        originTop: PropTypes.number.isRequired,
        originLeft: PropTypes.number.isRequired,
        onOverlayClick: PropTypes.func.isRequired,
        maxHeight: PropTypes.number,
        // width will be used as the actual width of the popup unless the screen
        // is narrower. If undefined, the width of the popup will fit the content.
        width: PropTypes.number,
    };

    static defaultProps = {
        children: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            measured: false,
            originTop: 0,
            originLeft: 0,
            top: 0,
            left: 0,
            width: 0,
            height: 0,
        };

        this._overlayRef = React.createRef();
        this._contentRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        // By copying originTop and originLeft from props to state we can use
        // the difference between props and state to determine if the props have changed.
        if (state.originTop !== props.originTop || state.originLeft !== props.originLeft) {
            // They have changed, force a re-measurement of the content.
            return {
                originTop: props.originTop,
                originLeft: props.originLeft,
                measured: false,
            };
        }

        return null;
    }

    componentDidMount() {
        window.addEventListener('resize', this._handleWindowResize);
        this._afterRender();
    }

    componentDidUpdate(prevProps, prevState) {
        this._afterRender();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleWindowResize);
    }

    _afterRender() {
        const {
            width: widthProp,
        } = this.props;

        const {
            measured,
            originTop,
            originLeft,
        } = this.state;

        if (!measured) {

            const overlay = this._overlayRef.current;
            const overlayRect = overlay.getBoundingClientRect();

            const content = this._contentRef.current;
            const contentRect = content.getBoundingClientRect();

            const preferredWidth = widthProp === undefined ? contentRect.width : widthProp;

            const width = Math.min(preferredWidth, overlayRect.width);
            const height = Math.min(contentRect.height, overlayRect.height);

            let topOffset = 0;

            // If it hangs off the bottom, push it up
            if (originTop + height > overlayRect.height) {
                topOffset = overlayRect.height - (originTop + height);
            }

            let leftOffset = 0;

            // If it hangs off the right, push it left
            if (originLeft + width > overlayRect.width) {
                leftOffset = overlayRect.width - (originLeft + width);
            }

            this.setState({
                measured: true,
                top: originTop + topOffset,
                left: originLeft + leftOffset,
                width: width,
                height: height,
            });
        }
    }

    _handleWindowResize = () => {
        this.setState({
            measured: false,
        });
    };

    render() {
        const {
            children,
            maxHeight,
            onOverlayClick,
        } = this.props;

        const {
            measured,
            top,
            left,
            width,
            height,
        } = this.state;

        return (
            <React.Fragment>
                <PopupMask
                    maskRef={this._overlayRef}
                    onClick={onOverlayClick}
                />

                <ContentWrapper
                    className={classNames({ measured })}
                    measured={measured}
                    top={top}
                    left={left}
                    width={width}
                    height={height}
                    maxHeight={maxHeight}
                >
                    {React.cloneElement(React.Children.only(children), {
                        ref: this._contentRef,
                        className: 'ContentWrapper-inner',
                    })}
                </ContentWrapper>
            </React.Fragment>
        );
    }
}
