import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MetricAndMetricSetOption from 'Common/components/ColumnControl/MetricAndMetricSetOption';

const MetricListColumnSelect = (props) => {
    const {
        fieldOptions,
        metricListMetrics,
        metricListAddMetrics
    } = props;

    const handleOnChange = useCallback((event) => {
        const newColumns = event.target.value;
        const metricSetChosenAsFirstMetric = metricListMetrics.length === 0 && newColumns.length > 1;
        if (metricSetChosenAsFirstMetric) {
            let foundMetricSet;
            fieldOptions.metric_set.find(group => {
                return group.entries.find(metricSet => {
                    const isFound  = metricSet.columns.length === newColumns.length
                        && metricSet.columns.every((col, index) => (col.name === newColumns[index].name));

                    if (isFound) {
                        foundMetricSet = metricSet;
                    }
                    return isFound;
                });
            });
            metricListAddMetrics({
                target: {
                    value: {
                        columns: newColumns,
                        displayName: foundMetricSet?.displayName || ''
                    }
                }
            });
        }
        else {
            metricListAddMetrics({
                target: {
                    value: {
                        columns: newColumns
                    }
                }
            });
        }
    }, [metricListMetrics, metricListAddMetrics, fieldOptions]);

    return (
        <MetricAndMetricSetOption
            fieldOptions={fieldOptions}
            selectedColumns={metricListMetrics}
            onChange={handleOnChange}
        />
    );
};

MetricListColumnSelect.propTypes = {
    fieldOptions: PropTypes.shape({
        metric: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                        fieldType: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ),
        metric_set: PropTypes.arrayOf(
            PropTypes.shape({
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                        fieldType: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ),
    }).isRequired,
    metricListMetrics: PropTypes.array.isRequired,

    metricListAddMetrics: PropTypes.func.isRequired,
};

export default MetricListColumnSelect;
