import { useCallback, useMemo } from 'react';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import TimeRange from 'Common/types/TimeRange/TimeRange';
import Filters from 'Common/types/Filters/Filters';
import { useWidgetCache } from 'Common/components/Dashboard/DashboardCache';
import { getMaxAgeForRepeatingRequest } from 'Common/hooks/requests/useRepeatingRequest';
import { FIELD_TYPE } from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidgetConstants';
import useRepeatingRequestWithStatus from 'Common/hooks/requests/useRepeatingRequestWithStatus';
import { useTranslator } from 'Common/hooks/useTranslation';

const paramTypes = ParamTypes.shape({
    getKPIGroupWidgetData: ParamTypes.func.isRequired,
    contents: ParamTypes.array.isRequired,
    hierarchyNode: ParamTypes.string.isRequired,
    pageFilters: Filters.paramType,
    widgetFilters: Filters.paramType,
    timeRange: TimeRange.timeRangeParamType.isRequired,
    updateInterval: ParamTypes.number,
});

export default function useKPIGroupWidgetData(parameters) {
    paramTest(parameters, paramTypes, 'useKPIGroupWidgetData');

    const translator = useTranslator();

    const {
        getKPIGroupWidgetData,
        contents,
        hierarchyNode,
        pageFilters,
        widgetFilters,
        timeRange,
        updateInterval,
    } = parameters;

    const disableRepeat = useMemo(() => {
        // Disable the repeat if there's no update interval or if the time
        // range is not suitable.
        return (
            !updateInterval ||
            !TimeRange.shouldRepeatRequestWithTimeRange(timeRange)
        );
    }, [ timeRange, updateInterval ]);

    // getting data
    const getKPIGroupWidgetDataCache = useWidgetCache({
        cacheId: 'getKPIGroupWidgetData',
        load: ({ abortSignal }, args) => {
            return getKPIGroupWidgetData({
                ...args,
                abortSignal,
            });
        },
    });

    // A cached version of `getKPIGroupWidgetData`
    const getKPIGroupWidgetDataCached = useCallback((args) => {
        const { abortSignal, ...otherArgs } = args;
        const maxAge = getMaxAgeForRepeatingRequest(disableRepeat, updateInterval);

        return getKPIGroupWidgetDataCache.get(
            {
                abortSignal,
                maxAge,
            },
            otherArgs
        );
    }, [ disableRepeat, getKPIGroupWidgetDataCache, updateInterval ]);

    const sendRequest = useCallback(async ({ abortSignal }) => {
        let result;
        try {
            result = await getKPIGroupWidgetDataCached({
                abortSignal,
                fields: contents.reduce((acc, item) => {
                    if (item.type === FIELD_TYPE) {
                        acc.push(item.field);
                    }
                    return acc;
                }, []),
                filters: Filters.mergeFilters(widgetFilters, pageFilters),
                hierarchyNode,
                timeRange,
            });

            if (result?.response) {
                result = {
                    error: null,
                    response: {
                        data: result.response,
                        contents
                    }
                };
            }
        }
        catch (err) {
            console.error(err);
            result = { error: translator('error_literal') };
        }

        return result;
    }, [contents, hierarchyNode, widgetFilters, pageFilters, timeRange, getKPIGroupWidgetDataCached, translator ]);

    return useRepeatingRequestWithStatus({
        sendRequest: sendRequest,
        delayMs: updateInterval || 0,
        // Disable the repeat if there's no update interval or if the time
        // range is not an open interval.
        disableRepeat,
    });
}
