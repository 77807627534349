import { connect } from 'react-redux';
import React from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import LoadingPage from 'Components/pages/loading/LoadingPage';
import PropTypes from 'prop-types';
import ForbiddenPage from 'Components/pages/forbidden/ForbiddenPage';
import { isAtLeastMinimumRole, getLowestRole } from './roles';
import { selectIsLoggedIn, selectIsInitialized, selectSessionUser } from 'Redux/reducers/session';

const PrivateRoute = ({ component: Component, minRole, ...rest }) => {
    const session = rest.session;
    const showLoading = !selectIsInitialized(session);
    const isLoggedIn = selectIsLoggedIn(session);
    const sessionData = selectSessionUser(session);

    // object passed on to the redirect so it can bring us back here after successful login
    const fromObj = {
        path: rest.location.pathname,
        search: rest.location.search
    };

    const renderFunc = function(props) {
        if (showLoading) {
            return (
                <LoadingPage />
            );
        }
        else if (!isLoggedIn) {
            return (
                <Redirect to={{
                    pathname: '/',
                    state: { from: fromObj },
                }}/>
            );
        }

        const hasPermission = isAtLeastMinimumRole(minRole || getLowestRole(), sessionData.role);

        if (hasPermission) {
            return (
                <Component {...props} />
            );
        }
        else {
            return <ForbiddenPage minRole={minRole} />;
        }
    };

    return (
        <Route
            {...rest}
            render={renderFunc}
        />
    );
};

PrivateRoute.propTypes = {
    session: PropTypes.object.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]).isRequired,
    minRole: PropTypes.string,
};

const mapStateToProps = state => {
    const { session } = state;
    return { session };
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
