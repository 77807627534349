import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import { interceptProps } from 'Components/util/reactUtils';

const Root = styled(interceptProps(Button, [ '__textColor' ]))`
    color: ${(props) => props.__textColor};
    background-color: transparent;
    padding: 0 8px;
`;

function TextButton(props) {
    const {
        theme,
        color,
        children,
        ...otherProps
    } = props;

    let textColor = theme.colors[color];

    return (
        <Root {...otherProps} __textColor={textColor}>
            {children}
        </Root>
    );
}

TextButton.propTypes = {
    theme: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default withTheme(TextButton);
