import PropTypes from 'prop-types';
import { getProcessStatesMap } from 'System/processStates';

const processStatesMap = getProcessStatesMap();

const comparisonMap = {
    over_target: 'Over Target',
    before_target: 'Before Target',
};

export default function ProcessStateTargetTimeAlertText(props) {
    const { process_state, comparison, threshold } = props;

    const thresholdMin = Math.floor(threshold / 60);

    return (
        `${processStatesMap[process_state].name} ${thresholdMin} min. ${comparisonMap[comparison]}`
    );
}

ProcessStateTargetTimeAlertText.propTypes = {
    process_state: PropTypes.oneOf(Object.keys(processStatesMap)).isRequired,
    comparison: PropTypes.oneOf(Object.keys(comparisonMap)).isRequired,
    threshold: PropTypes.number.isRequired,
};
