import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    generateDeviceSubscriptionsPatch,
    getAlerts,
    getAlertWindow,
    getDeviceSubscriptions,
    getHierarchy,
    getUsers,
    patchAlertWindow,
    patchDeviceSubscriptions,
} from 'Redux/actions';
import getAlertSubscriptions from 'Redux/actions/getAlertSubscriptions';
import { patchAlertSubscriptions, generateAlertSubscriptionsPatch } from 'Redux/actions/patchAlertSubscriptions';
import { selectAlertSubscriptionsRequest, selectAlertSubscriptions } from 'Redux/reducers/alertSubscriptions';
import { selectAlertsRequest, selectAlerts } from 'Redux/reducers/alerts';
import { selectAlertWindow, selectAlertWindowRequest } from 'Redux/reducers/alertWindow';
import { selectAdminUsers, selectUsersRequest } from 'Redux/reducers/users';
import { selectDeviceSubscriptionsRequest, selectDeviceSubscriptions } from 'Redux/reducers/deviceSubscriptions';
import { selectDevicesList, selectHierarchyRequest } from 'Redux/reducers/hierarchy';
import { selectSessionUser } from 'Redux/reducers/session';
import * as RequestStatus from 'Common/data/RequestStatus';

import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import RequestLoadingWrapper from 'Components/loading-indicator/RequestLoadingWrapper';
import MultiRequestLoadingWrapper from 'Components/loading-indicator/MultiRequestLoadingWrapper';
import AlertSubscriptionsForm from './AlertSubscriptionsForm';
import AlertWindowForm from './AlertWindowForm';
import DeviceSubscriptionsForm from './DeviceSubscriptionsForm';
import PageFrame from 'Components/page/PageFrame';
import PageDescription from 'Components/page/PageDescription';
import PageContentWide from 'Components/page/PageContentWide';
import SectionDescription from 'Components/text/SectionDescription';
import WarningNotice from 'Components/notice/WarningNotice';
import { nullable } from 'Components/util/propTypesUtils';

export class AlertsSubscribePagePresentation extends React.Component {
    static propTypes = {
        // Admins
        admins: PropTypes.arrayOf(PropTypes.object).isRequired,
        // Alerts
        alerts: PropTypes.object.isRequired,
        alertSubscriptions: PropTypes.object.isRequired,
        // Alert Window
        alertWindow: PropTypes.object.isRequired,
        // Device Subscriptons
        deviceSubscriptions: PropTypes.array.isRequired,

        sessionUser: PropTypes.shape({
            hierarchy_visibility: nullable(PropTypes.number).isRequired,
        }).isRequired,
        hierarchy: PropTypes.object.isRequired,

        actions: PropTypes.exact({
            getUsers: PropTypes.func.isRequired,
            getAlerts: PropTypes.func.isRequired,
            getAlertSubscriptions: PropTypes.func.isRequired,
            patchAlertSubscriptions: PropTypes.func.isRequired,
            getAlertWindow: PropTypes.func.isRequired,
            patchAlertWindow: PropTypes.func.isRequired,
            getDeviceSubscriptions: PropTypes.func.isRequired,
            getHierarchy: PropTypes.func.isRequired,
            patchDeviceSubscriptions: PropTypes.func.isRequired,
        }).isRequired,
        requests: PropTypes.exact({
            getUsers: PropTypes.object.isRequired,
            getAlerts: PropTypes.object.isRequired,
            getAlertSubscriptions: PropTypes.object.isRequired,
            patchAlertSubscriptions: PropTypes.object.isRequired,
            getAlertWindow: PropTypes.object.isRequired,
            patchAlertWindow: PropTypes.object.isRequired,
            getDeviceSubscriptions: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
            getHierarchy: PropTypes.object.isRequired,
            patchDeviceSubscriptions: PropTypes.object.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.actions.getUsers();
        this.props.actions.getAlerts();
        this.props.actions.getAlertSubscriptions();
        this.props.actions.getAlertWindow();
        this.props.actions.getDeviceSubscriptions();
        this.props.actions.getHierarchy();
    }

    _renderPageDescription() {
        const { admins } = this.props;

        const names = admins.map(user => user.data.name).join(', ');

        return (
            `Use this page to subscribe to automatic email notifications for
            alerts that fall within the scope of your responsibility.
            Alert rules are configured by XL Enterprise
            Administrators${names.length ? ` (${names})` : ''}.`
        );
    }

    _handleAlertWindowSubmit = (event) => {
        this.props.actions.patchAlertWindow(event.target.value);
    };

    _renderAlertWindowForm() {
        const { alertWindow, requests } = this.props;

        return (
            <RequestLoadingWrapper
                request={requests.getAlertWindow}
                render={() => {
                    return (
                        <AlertWindowForm
                            data={alertWindow}
                            requests={{
                                patchAlertWindow: requests.patchAlertWindow
                            }}
                            onSubmit={this._handleAlertWindowSubmit}
                        />
                    );
                }}
            />
        );
    }

    _handleAlertSubscriptionsSubmit = (event) => {
        const patchData = generateAlertSubscriptionsPatch(
            this.props.alertSubscriptions,
            event.target.value
        );

        this.props.actions.patchAlertSubscriptions(patchData);
    };

    _renderAlertSubscriptionsForm = () => {
        const {
            alerts,
            alertSubscriptions,
            requests,
        } = this.props;

        return (
            <MultiRequestLoadingWrapper
                requests={[
                    requests.getAlerts,
                    requests.getAlertSubscriptions,
                ]}
                render={() => {
                    return (
                        <AlertSubscriptionsForm
                            alerts={alerts}
                            data={alertSubscriptions}
                            requests={{
                                patchAlertSubscriptions: requests.patchAlertSubscriptions,
                            }}
                            onSubmit={this._handleAlertSubscriptionsSubmit}
                        />
                    );
                }}
            />
        );
    };

    _handleDeviceSubscriptionsSubmit = (event) => {
        const patchData = generateDeviceSubscriptionsPatch(
            this.props.deviceSubscriptions,
            event.target.value
        );

        this.props.actions.patchDeviceSubscriptions(patchData);
    };

    _renderDeviceSubscriptionsForm = () => {
        const {
            deviceSubscriptions,
            sessionUser,
            requests,
            hierarchy
        } = this.props;

        const devicesList = hierarchy ? selectDevicesList(hierarchy) : [];

        const hasNoVisibility = (
            sessionUser.hierarchy_visibility === null ||
            devicesList.length === 0
        );

        return (
            <MultiRequestLoadingWrapper
                requests={[
                    requests.getHierarchy,
                    requests.getDeviceSubscriptions,
                ]}
                render={() => {
                    return (
                        <React.Fragment>
                            {hasNoVisibility && (
                                <WarningNotice>
                                    You currently have no access to any devices in the
                                    enterprise hierarchy and therefore are unable to
                                    subscribe to any work centers.
                                    Contact your organization&apos;s administrator(s)
                                    to gain access.
                                </WarningNotice>
                            )}
                            <DeviceSubscriptionsForm
                                devices={devicesList}
                                data={deviceSubscriptions}
                                requests={{
                                    patchDeviceSubscriptions: requests.patchDeviceSubscriptions,
                                }}
                                onSubmit={this._handleDeviceSubscriptionsSubmit}
                            />
                        </React.Fragment>
                    );
                }}
            />
        );
    };

    render() {
        const { deviceSubscriptions, requests, alertSubscriptions } = this.props;

        const alertSubscriptionsCount = (
            alertSubscriptions.metric.length
            + alertSubscriptions.process_state_duration.length
            + alertSubscriptions.process_state_target_time.length
        );

        return (
            <Page>
                <PageTitle>Alert Subscriptions</PageTitle>

                <PageDescription>
                    {this._renderPageDescription()}
                </PageDescription>

                <PageContentWide>
                    {requests.getDeviceSubscriptions.status === RequestStatus.SUCCESS &&
                        requests.getAlertSubscriptions.status === RequestStatus.SUCCESS &&
                        alertSubscriptionsCount > 0 &&
                        deviceSubscriptions.length === 0 && (
                        <WarningNotice>
                            You haven&apos;t subscribed to any work centers. Alerts will only be sent for
                            work centers that you have selected below.
                        </WarningNotice>
                    )}
                </PageContentWide>

                <PageFrame title="Alerts">
                    <SectionDescription>
                        Select the alerts for which you would like to receive
                        email notifications. Metric Alerts are only sent after
                        there has been at least 30 minutes of manufacturing time
                        in the shift (enough time for the alert to be meaningful
                        and actionable). Each <b>Metric Alert</b> is sent one
                        time per shift. In other words, if a metric crosses an
                        alert threshold multiple times, the alert is sent only
                        the first time. <b>Production State Time</b> and <b>
                        Production State Target</b> alerts are sent each time
                        the alert threshold is crossed.
                    </SectionDescription>
                    {this._renderAlertSubscriptionsForm()}
                </PageFrame>

                <PageFrame title="Work Centers">
                    <SectionDescription>
                        Select the work centers for which you would like to receive notifications.
                    </SectionDescription>
                    <PageContentWide>
                        {this._renderDeviceSubscriptionsForm()}
                    </PageContentWide>
                </PageFrame>

                <PageFrame title="Receive Window">
                    <SectionDescription>
                        Configure a receive window to establish when you would like to receive
                        alerts. This typically should be set to match your regular working hours
                        (in your local time). You can also choose to temporarily override the
                        configured receive window.
                    </SectionDescription>
                    {this._renderAlertWindowForm()}
                </PageFrame>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    const {
        users,
        alerts,
        alertSubscriptions,
        alertWindow,
        deviceSubscriptions,
        hierarchy,
    } = state;

    return {
        admins: selectAdminUsers(users),
        alerts: selectAlerts(alerts),
        alertSubscriptions: selectAlertSubscriptions(alertSubscriptions),
        alertWindow: selectAlertWindow(alertWindow),
        deviceSubscriptions: selectDeviceSubscriptions(deviceSubscriptions),
        hierarchy: state.hierarchy,
        sessionUser: selectSessionUser(state.session),

        requests: {
            getUsers: selectUsersRequest(users, 'getUsers'),
            getAlerts: selectAlertsRequest(alerts, 'getAlerts'),
            getAlertSubscriptions: selectAlertSubscriptionsRequest(alertSubscriptions, 'getAlertSubscriptions'),
            patchAlertSubscriptions: selectAlertSubscriptionsRequest(alertSubscriptions, 'patchAlertSubscriptions'),
            getAlertWindow: selectAlertWindowRequest(alertWindow, 'getAlertWindow'),
            patchAlertWindow: selectAlertWindowRequest(alertWindow, 'patchAlertWindow'),
            getDeviceSubscriptions: selectDeviceSubscriptionsRequest(deviceSubscriptions, 'getDeviceSubscriptions'),
            patchDeviceSubscriptions: selectDeviceSubscriptionsRequest(deviceSubscriptions, 'patchDeviceSubscriptions'),
            getHierarchy: selectHierarchyRequest(hierarchy, 'getHierarchy'),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getUsers: getUsers,
            getAlerts: getAlerts,
            getAlertSubscriptions: getAlertSubscriptions,
            patchAlertSubscriptions: patchAlertSubscriptions,
            getAlertWindow: getAlertWindow,
            patchAlertWindow: patchAlertWindow,
            getDeviceSubscriptions: getDeviceSubscriptions,
            patchDeviceSubscriptions: patchDeviceSubscriptions,
            getHierarchy: getHierarchy,
        }, dispatch),
    };
};

// connect the component to Redux
const AlertsSubscribePage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertsSubscribePagePresentation));

export default AlertsSubscribePage;
