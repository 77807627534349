import every from 'lodash/every';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';
import forOwn from 'lodash/forOwn';

export function isValidationValid(validation) {
    if (isObject(validation) || isArray(validation)) {
        // We need to go down every level to ensure everything is valid
        return every(validation, isValidationValid);
    }
    else {
        // If we're a falsy value, we're valid!
        return !validation;
    }
}

/**
 * Combine an object of validator functions into a single validator function.
 * When the resulting validation function is called, each validation function
 * will be called with the value of the input data at its corresponding key.
 *
 * @param {object} validators Object of validator functions
 * @returns {function} Validator function that returns an object of validation
 *      errors or `null` if there are none
 */
export function combineValidators(validators) {
    // Check that the argument is an object
    if (!isPlainObject(validators)) {
        throw new Error('First argument of `combineValidators` must be a plain object.');
    }

    // Check that all values in the object are functions
    if (!every(validators, validatorFn => isFunction(validatorFn))) {
        throw new Error('First argument of `combineValidators` must be a plain object of validator functions');
    }

    return (data) => {
        let validation = mapValues(validators, (validatorFn, key) => {
            return validatorFn(data[key]);
        });

        // Filter out keys with no validation result
        validation = pickBy(validation, (subValidation, key) => {
            return subValidation !== null && subValidation !== undefined;
        });

        if (size(validation) === 0) {
            return null; // Valid
        }
        else {
            return validation; // Invalid
        }
    };
}

/**
 * Merge the results of mutiple validator functions that return objects.
 * Similar to an `Object.assign`, but `null` and `undefined` values will not
 * overwrite existing values during the merge. Furthermore, if none of the
 * objects contain any errors, `null` is returned.
 *
 * @param {object[]} validationObjects Array of results from validator functions
 *          that return objects.
 * @returns {object|null} Merged validation object.
 */
export function mergeValidationObjects(validationObjects) {
    const validation = validationObjects
        // Remove null and undefined from array
        .filter((validationObject) => {
            return validationObject !== null && validationObject !== undefined;
        })
        // Perform the merge
        .reduce((currentValidation, validationObject) => {
            forOwn(validationObject, (subValidation, key) => {
                // Ignore keys with null/undefined values
                if (subValidation !== null && subValidation !== undefined) {
                    currentValidation[key] = subValidation;
                }
            });

            return currentValidation;
        }, {});

    if (size(validation) === 0) {
        return null; // Valid
    }
    else {
        return validation; // Invalid
    }
}

/**
 * Combine multiple validator functions that return objects into a single
 * validator function that returns a single validation object with the results
 * merged.
 *
 * @param {function[]} validators Array of validator functions that return objects
 * @returns {function} Validator function that returns an object of validation
 *      errors or `null` if there are none
 */
export function mergeObjectValidators(validators) {
    // Check that the argument is an array
    if (!isArray(validators)) {
        throw new Error('First argument of `mergeValidators` must be an array.');
    }

    // Check that all validators are functions
    if (!every(validators, validatorFn => isFunction(validatorFn))) {
        throw new Error('First argument of `mergeValidators` must be an array of validator functions');
    }

    return (data) => {
        const validationObjects = validators.map((validatorFn) => {
            return validatorFn(data);
        });

        return mergeValidationObjects(validationObjects);
    };
}
