import { sendGetRequest } from 'Http/request';
import { getResources } from 'Common/util/ResourceCollection';

export function getAlerts() {
    return getResources({
        resourceType: 'alerts',
        requestId: 'getAlerts',
        sendRequest: async () => {
            return sendGetRequest('/api/v1/alerts');
        },
    });
}

export default getAlerts;
