import React from 'react';
import LoadingIndicator from 'Common/components/LoadingIndicator';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px;
    justify-content: center;
`;

const Box = styled.div`
    border: 1px solid ${({theme}) => theme.colors.palette.grey.silver};
    padding: 10px;
    border-radius: 4px;
`;

export default function WidgetLoadingIndicator() {
    return (
        <Container data-testid="loading-indicator">
            <Box>
                <LoadingIndicator/>
            </Box>
        </Container>
    );
}
