import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

const Overlay = styled.div``;

const Root = styled.button`
    position: relative;
    cursor: pointer;
    outline: none;

    & > ${Overlay} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(p) => p.theme.colors.palette.black};
        opacity: 0;
        transition: all 300ms;
        border-radius: inherit;
    }

    &[disabled] {
        cursor: default;
    }

    &:active > ${Overlay} {
        opacity: 0.125;
    }

    &:not(:active) {
        /* Overlay when selected */
        &.selected > ${Overlay} {
            opacity: 0.15;
        }

        /* Only :hover and :focus when not selected */
        &:not(.selected) {
            :hover > ${Overlay} {
                opacity: 0.06;
            }

            :focus > ${Overlay} {
                opacity: 0.10;
            }
        }
    }
`;

const ButtonLike = React.forwardRef((props, ref) => {
    const {
        Component,
        className: classNameProp,
        children,
        selected,
        disabled,
        ...otherProps
    } = props;

    const className = classNames(classNameProp, { selected: selected });

    return (
        <Root ref={ref} as={Component} className={className} role="button" tabIndex="0" disabled={disabled} {...otherProps}>
            <Overlay />
            {children}
        </Root>
    );
});

ButtonLike.defaultProps = {
    Component: 'button',
    selected: false,
    disabled: false,
};

ButtonLike.propTypes = {
    Component: PropTypes.any,
    className: PropTypes.string,
    children: PropTypes.node,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
};

ButtonLike.displayName = 'ButtonLike';

export default ButtonLike;
