import { useContext, useMemo } from 'react';
import { LocaleContext } from 'Common/providers/LocaleProvider';


 export default function useLocale() {
    const localeContext = useContext(LocaleContext);

    const locale = useMemo(() => {
        return localeContext();
    }, [localeContext]);

    return locale;
}
