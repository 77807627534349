/**
 * All typography definitions come from the WPI Lookbook:
 *
 * https://vornewiki.atlassian.net/wiki/spaces/XSD/pages/1527021724/WPI+Lookbook
 *
 */

import styled from 'styled-components';

export const FontWeight = {
    thin: 200,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    heavy: 900,
};

export const FontSize = {
    size1: 27,
    size2: 21,
    size3: 17,
    size4: 14,
    size5: 12,
    size6: 11,
    size7: 10,
};

export const FontColor = {
    heading: ({ theme }) => theme.colors.darkText.highEmphasis,
    subheading: ({ theme }) => theme.colors.palette.blue.awesome,
    primary: ({ theme }) => theme.colors.darkText.highEmphasis,
    secondary: ({ theme }) => theme.colors.darkText.mediumEmphasis,
    body: ({ theme }) => theme.colors.darkText.highEmphasis,
};

export const FontFamily = {
    primary: 'Roboto, Helvetica, sans-serif',
    serif: 'Georgia, "Times New Roman", Times, serif',
};

// HEADING

const HeadingBase = styled.span`
    font-family: ${FontFamily.primary};
    color: ${FontColor.heading};
`;

export const Heading1 = styled(HeadingBase)`
    font-size: ${FontSize.size1}px;
    font-weight: ${FontWeight.bold};
`;

export const Heading2 = styled(HeadingBase)`
    font-size: ${FontSize.size2}px;
    font-weight: ${FontWeight.bold};
`;

export const Heading3 = styled(HeadingBase)`
    font-size: ${FontSize.size3}px;
    font-weight: ${FontWeight.bold};
`;

export const Heading4 = styled(HeadingBase)`
    font-size: ${FontSize.size4}px;
    font-weight: ${FontWeight.medium};
`;

export const Heading5 = styled(HeadingBase)`
    font-size: ${FontSize.size5}px;
    font-weight: ${FontWeight.medium};
`;

export const Heading6 = styled(HeadingBase)`
    font-size: ${FontSize.size6}px;
    font-weight: ${FontWeight.medium};
`;

export const Heading7 = styled(HeadingBase)`
    font-size: ${FontSize.size7}px;
    font-weight: ${FontWeight.medium};
`;

// SUBHEADING

const SubheadingBase = styled.span`
    font-family: ${FontFamily.primary};
    color: ${FontColor.subheading};
`;

export const Subheading1 = styled(SubheadingBase)`
    font-size: ${FontSize.size1}px;
    font-weight: ${FontWeight.bold};
`;

export const Subheading2 = styled(SubheadingBase)`
    font-size: ${FontSize.size2}px;
    font-weight: ${FontWeight.bold};
`;

export const Subheading3 = styled(SubheadingBase)`
    font-size: ${FontSize.size3}px;
    font-weight: ${FontWeight.bold};
`;

export const Subheading4 = styled(SubheadingBase)`
    font-size: ${FontSize.size4}px;
    font-weight: ${FontWeight.medium};
`;

export const Subheading5 = styled(SubheadingBase)`
    font-size: ${FontSize.size5}px;
    font-weight: ${FontWeight.medium};
`;

export const Subheading6 = styled(SubheadingBase)`
    font-size: ${FontSize.size6}px;
    font-weight: ${FontWeight.medium};
`;

export const Subheading7 = styled(SubheadingBase)`
    font-size: ${FontSize.size7}px;
    font-weight: ${FontWeight.medium};
`;

// PRIMARY

const PrimaryBase = styled.span`
    font-family: ${FontFamily.primary};
    font-weight: ${FontWeight.regular};
    color: ${FontColor.primary};
`;

export const Primary1 = styled(PrimaryBase)`
    font-size: ${FontSize.size1}px;
`;

export const Primary2 = styled(PrimaryBase)`
    font-size: ${FontSize.size2}px;
`;

export const Primary3 = styled(PrimaryBase)`
    font-size: ${FontSize.size3}px;
`;

export const Primary4 = styled(PrimaryBase)`
    font-size: ${FontSize.size4}px;
`;

export const Primary5 = styled(PrimaryBase)`
    font-size: ${FontSize.size5}px;
`;

export const Primary6 = styled(PrimaryBase)`
    font-size: ${FontSize.size6}px;
`;

// SECONDARY

const SecondaryBase = styled.span`
    font-family: ${FontFamily.primary};
    font-weight: ${FontWeight.regular};
    color: ${FontColor.secondary};
`;

export const Secondary1 = styled(SecondaryBase)`
    font-size: ${FontSize.size1}px;
`;

export const Secondary2 = styled(SecondaryBase)`
    font-size: ${FontSize.size2}px;
`;

export const Secondary3 = styled(SecondaryBase)`
    font-size: ${FontSize.size3}px;
`;

export const Secondary4 = styled(SecondaryBase)`
    font-size: ${FontSize.size4}px;
`;

export const Secondary5 = styled(SecondaryBase)`
    font-size: ${FontSize.size5}px;
`;

export const Secondary6 = styled(SecondaryBase)`
    font-size: ${FontSize.size6}px;
`;

// BODY

const BodyBase = styled.span`
    font-family: ${FontFamily.primary};
    font-weight: ${FontWeight.regular};
    color: ${FontColor.body};
`;

export const Body1 = styled(BodyBase)`
    font-size: ${FontSize.size1}px;
`;

export const Body2 = styled(BodyBase)`
    font-size: ${FontSize.size2}px;
`;

export const Body3 = styled(BodyBase)`
    font-size: ${FontSize.size3}px;
`;

export const Body4 = styled(BodyBase)`
    font-size: ${FontSize.size4}px;
`;

export const Body5 = styled(BodyBase)`
    font-size: ${FontSize.size5}px;
`;

export const Body6 = styled(BodyBase)`
    font-size: ${FontSize.size6}px;
`;

export const Body7 = styled(BodyBase)`
    font-size: ${FontSize.size7}px;
`;
