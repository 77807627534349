import { resourceCollectionReducer, resourceReducer } from 'Common/util/ResourceCollection';
import { Roles } from 'Components/app/roles';
import { selectResource, selectResources, selectResourcesRequest } from 'Common/util/ResourceCollection/resourceCollectionReducer';
import { selectResourceData, selectResourceRequest } from 'Common/util/ResourceCollection/resourceReducer';

export default resourceCollectionReducer('users', {
    getResourceId: (resource) => resource.id,
    requests: [ 'getUsers' ],
    resourceReducer: resourceReducer({
        requests: [ 'getUser', 'patchUser' ],
    }),
    sort: (userA, userB) => {
        return userA.email_address.email.localeCompare(userB.email_address.email);
    },
});

export function getUserById(state, userId) {
    return selectResource(state, userId);
}

export function getUsersList(state) {
    return selectResources(state);
}

export function selectUserData(state, userId) {
    const resourceState = selectResource(state, userId);
    return selectResourceData(resourceState);
}

export function selectUserRequest(state, userId, requestId) {
    const resourceState = selectResource(state, userId);
    return selectResourceRequest(resourceState, requestId);
}

export function selectAdminUsers(state) {
    return selectResources(state).filter((resourceState) => {
        return selectResourceData(resourceState)?.role === Roles.ADMIN;
    });
}

export function selectUsersRequest(state, requestId) {
    return selectResourcesRequest(state, requestId);
}
