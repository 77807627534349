import { formatDate, formatNumber } from 'Common/util/format';
import { ProductionDay } from "Common/data/date/ProductionDay";

/**
 * @typedef {Object} PrimaryDescription
 * @property {string} title Primary text of the primary description
 * @property {string} detail Secondary text of the primary description
 */

/**
 *
 * Gets primary description for a dimension.
 *
 * @param {String} dimensionDetailsDisplayName
 * @param {Boolean} dimensionDetailsIsMixed
 * @param {Number} timeRangeStart
 * @param {Object} timeRangeSameDimensionValue
 * @param {String} timeRangeUnits
 * @param {Boolean} atEndOfData
 * @param {Function} translator
 *
 * @returns {PrimaryDescription}
 *
 */
const handleDimensions = ({
    dimensionDetailsDisplayName,
    dimensionDetailsIsMixed,
    timeRangeStart,
    timeRangeSameDimensionValue,
    timeRangeUnits,
    timeRangeProductionDayOffset,
    atEndOfData,
    translator,
}) => {
    const formattedCount = formatNumber(-timeRangeStart);

    if (timeRangeSameDimensionValue && timeRangeUnits !== 'ordinal_shift') {
        const dimensionName = dimensionDetailsDisplayName;

        if (timeRangeStart == 0) {
            return {
                title: `${translator(`timerange.dimensions.most_recent`)}`,
                detail: `${translator(`timerange.dimensions.only`, {
                    x: dimensionName,
                })}`,
            };
        }
        else if (atEndOfData) {
            return {
                title: `${translator(`timerange.dimensions.end`)}`,
                detail: `${translator(`timerange.dimensions.only`, {
                    x: dimensionName,
                })}`,
            };
        }
        else {
            return {
                title: `${translator(`timerange.dimensions.n_back`, {
                    x: formattedCount,
                })}`,
                detail: `${translator(`timerange.dimensions.only`, {
                    x: dimensionName,
                })}`,
            };
        }
    }
    else if (timeRangeStart === 0) {
        const detail = dimensionDetailsIsMixed
            ? `${translator(`timerange.dimensions.${timeRangeUnits}.mixed`)}`
            : dimensionDetailsDisplayName
            ? `${dimensionDetailsDisplayName}`
            : '';
        return {
            title: translator(`timerange.dimensions.${timeRangeUnits}.this`),
            detail,
        };
    }
    else if (timeRangeStart === -1) {
        const detail = dimensionDetailsIsMixed
            ? `${translator(`timerange.dimensions.${timeRangeUnits}.mixed`)}`
            : dimensionDetailsDisplayName
            ? `${dimensionDetailsDisplayName}`
            : '';
        return {
            title: translator(`timerange.dimensions.${timeRangeUnits}.last`),
            detail,
        };
    }
    else {
        let title;

        let detail = dimensionDetailsIsMixed
            ? `${translator(`timerange.dimensions.${timeRangeUnits}.mixed`)}`
            : dimensionDetailsDisplayName
            ? `${dimensionDetailsDisplayName}`
            : '';

        if (timeRangeUnits === 'ordinal_shift') {
            title = timeRangeProductionDayOffset === 0
                ? translator(`timerange.times.production_day.current`)
                : timeRangeProductionDayOffset === -1
                ? translator(`timerange.times.production_day.previous`)
                : translator(`timerange.times.production_day.n_ago`, {
                    x: formatNumber(-timeRangeProductionDayOffset),
                });

            if (timeRangeSameDimensionValue) {
                detail = translator(`timerange.dimensions.only`, {
                    x: detail,
                });
            }
        }
        else {
            title = translator(`timerange.dimensions.${timeRangeUnits}.n_ago`, {
                x: formattedCount,
            });
        }

        return {
            title,
            detail,
        };
    }
};

/**
 *
 * Gets primary description for time.
 *
 * @param {String} dimensionDetailsDisplayName
 * @param {Boolean} dimensionDetailsIsMixed
 * @param {Number} timeRangeStart
 * @param {Number} timeRangeEnd
 * @param {String} timeRangeUnits
 * @param {Function} translator
 *
 * @returns {PrimaryDescription}
 *
 */
const handleTime = ({
    dimensionDetailsDisplayName,
    dimensionDetailsIsMixed,
    timeRangeStart,
    timeRangeEnd,
    timeRangeUnits,
    translator,
}) => {
    const formattedCount = formatNumber(-timeRangeStart);

    if (timeRangeUnits === "shift_hour") {
        const detail = dimensionDetailsIsMixed
            ? `${translator(`timerange.times.${timeRangeUnits}.mixed`)}`
            : dimensionDetailsDisplayName
            ? `${dimensionDetailsDisplayName}`
            : '';
        if (timeRangeStart === 0) {
            return {
                title: translator(`timerange.times.shift_hour.this`),
                detail,
            };
        } else if (timeRangeStart === -1) {
            return {
                title: translator(`timerange.times.shift_hour.last`),
                detail,
            };
        } else {
            return {
                title: translator(`timerange.times.shift_hour.n_ago`, {
                    x: formattedCount,
                }),
                detail,
            };
        }
    }
    else {

        const isSingleUnit = timeRangeStart === timeRangeEnd;

        if (isSingleUnit) {
            const detail = dimensionDetailsDisplayName ? `${dimensionDetailsDisplayName}` : "";
            if (timeRangeStart === 0) {
                return {
                    title: `${translator(
                        `timerange.times.${timeRangeUnits}.current`
                    )}`,
                    detail,
                };
            }
            else if (timeRangeStart === 1) {
                return {
                    title: `${translator(
                        `timerange.times.${timeRangeUnits}.future`
                    )}`,
                    detail,
                };
            }
            else if (timeRangeStart === -1) {
                return {
                    title: `${translator(
                        `timerange.times.${timeRangeUnits}.previous`
                    )}`,
                    detail,
                };
            }
            else if (timeRangeStart < -1) {
                return {
                    title: `${translator(
                        `timerange.times.${timeRangeUnits}.n_ago`,
                        {
                            x: formattedCount,
                        }
                    )}`,
                    detail,
                };
            }
        }
        else if (timeRangeUnits === "production_day") {
            const translationKey =
                timeRangeEnd === 0
                    ? `timerange.times.last_n_days_ago`
                    : `timerange.times.n_days_ago`;
            return {
                title: `${translator(translationKey, {
                    x: -(timeRangeStart - timeRangeEnd - 1),
                })}`,
                detail: "",
            };
        }
    }
};

/**
 *
 * Gets primary description for all time.
 * 
 * @param {Function} translator
 *
 * @returns {PrimaryDescription}
 *
 */
const handleAllTime = (translator) => {
    return {
        title: translator("timerange.all_time"),
        detail: "",
    };
};

/**
 *
 * Gets primary description for time range.
 *
 * @param {Number} timeRangeStart
 * @param {Number} timeRangeEnd
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {PrimaryDescription}
 *
 */
const handleTimeRange = (timeRangeStart, timeRangeEnd, locale, translator) => {
    const isSingleUnit = timeRangeStart === timeRangeEnd;
    const start = new ProductionDay(timeRangeStart);
    const end = new ProductionDay(timeRangeEnd);

    if (isSingleUnit) {
        return {
            title: formatDate(start, {
                selector: "date",
                formatLength: "short",
                locale
            }),
            detail: "",
        };
    }
    else {
        return {
            title: translator("timerange.times.date_range", {
                x: formatDate(start, {
                    selector: "date",
                    formatLength: "short",
                    locale
                }),
                y: formatDate(end, {
                    selector: "date",
                    formatLength: "short",
                    locale
                }),
            }),
            detail: "",
        };
    }
};

/**
 *
 * Gets primary description
 *
 * @param {String} dimensionDetailsDisplayName
 * @param {Boolean} dimensionDetailsIsMixed
 * @param {Number} timeRangeStart
 * @param {Number} timeRangeEnd
 * @param {Object} timeRangeSameDimensionValue
 * @param {String} timeRangeUnits
 * @param {Boolean} atEndOfData
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {PrimaryDescription}
 *
 */
const getPrimaryDescription = ({
    dimensionDetailsDisplayName,
    dimensionDetailsIsMixed,
    timeRangeStart,
    timeRangeEnd,
    timeRangeSameDimensionValue,
    timeRangeUnits,
    timeRangeProductionDayOffset,
    atEndOfData,
    locale,
    translator,
}) => {
    const supportedDimensions = ["part", "shift", "ordinal_shift"];
    const supportedTimes = [
        "shift_hour",
        "production_day",
        "week",
        "month",
        "quarter",
        "year",
    ];

    if (supportedDimensions.includes(timeRangeUnits)) {
        return handleDimensions({
            dimensionDetailsDisplayName,
            dimensionDetailsIsMixed,
            timeRangeStart,
            timeRangeSameDimensionValue,
            timeRangeUnits,
            timeRangeProductionDayOffset,
            atEndOfData,
            translator,
        });
    } else if (supportedTimes.includes(timeRangeUnits)) {
        return handleTime({
            dimensionDetailsDisplayName,
            dimensionDetailsIsMixed,
            timeRangeStart,
            timeRangeEnd,
            timeRangeUnits,
            translator,
        });
    } else if (timeRangeUnits === "all") {
        return handleAllTime(translator);
    } else if (timeRangeUnits === "time") {
        return handleTimeRange(timeRangeStart, timeRangeEnd, locale, translator);
    } else {
        throw `${timeRangeUnits} is not supported`;
    }
};

export default getPrimaryDescription;
