import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import { interceptProps } from 'Components/util/reactUtils';

const Root = styled(interceptProps(Button, ['styled']))`
    color: ${(props) => props.styled.textColor};
    background-color: ${(props) => props.styled.bgColor};
`;

function ContainedButton(props) {
    const {
        theme,
        children,
        ...otherProps
    } = props;

    let bgColor = theme.colors.palette.blue.awesome;
    let textColor = theme.colors.palette.white;

    return (
        <Root {...otherProps} styled={{ textColor, bgColor }}>
            {children}
        </Root>
    );
}

ContainedButton.propTypes = {
    theme: PropTypes.object.isRequired,
    children: PropTypes.node,
};

export default withTheme(ContainedButton);
