
import uniqueId from 'Common/util/uniqueId';
import { useRef } from 'react';


export default function useUniqueId(prefix) {
    const ref = useRef(null);

    if (ref.current === null) {
        ref.current = uniqueId(prefix);
    }

    return ref.current;
}