function getPrettyOperator(uglyOp, translator) {
    if (uglyOp === 'eq') {
        return translator('filter.equal_to');
    }
    else if (uglyOp === 'ne') {
        return translator('filter.not_equal_to');
    }
    else if (uglyOp === 'lt') {
        return translator('filter.less_than');
    }
    else if (uglyOp === 'le') {
        return translator('filter.less_than_or_equal_to');
    }
    else if (uglyOp === 'gt') {
        return translator('filter.greater_than');
    }
    else if (uglyOp === 'ge') {
        return translator('filter.greater_than_or_equal_to');
    }
    else {
        return uglyOp;
    }
}

function getFormulaOperator(op) {
    if (op === 'eq') {
        return '==';
    }
    else if (op === 'ne') {
        return '!=';
    }
    else if (op === 'lt') {
        return '<';
    }
    else if (op === 'le') {
        return '<=';
    }
    else if (op === 'gt') {
        return '>';
    }
    else if (op === 'ge') {
        return '>=';
    }
    else {
        throw `Cannot convert ${op} to a formula equivalent.`;
    }
}

function formatForURL ({ rhs, op, lhs }) {
    if (Array.isArray(rhs)) {
        throw 'Break rhs out individually before trying to format';
    }
    let opString =  op;
    let lhsString = '';
    let rhsString = '';

    if (typeof lhs === 'string' ) {
        lhsString = lhs;
    }
    else {
        lhsString = lhs.getName();
        // [FUTUREHACK]
        // swap out list_occurrences for _occurrences so it doesn't try to do
        // [8,9,4] > 3
        const list_occurrences = '_list_occurrences';
        if (lhsString.endsWith(list_occurrences)) {
            lhsString = `${lhsString.slice(0, -list_occurrences.length)}_occurrences`;
        }

        // [FUTUREHACK]
        // swap out category_value fieldType columns that have
        // a display name column counterpart
        // this helps when the request is made across devices
        // and has discrepancies between category value keys
        if (lhs.isCategoryValue() && lhs.isValid()) {
            const displayName = `${lhsString}_display_name`;
            const displayNameColumn = lhs.getTable().getColumn(displayName);
            
            if (displayNameColumn.isValid()) {
                lhsString = displayNameColumn.getName();

                // convert what is expected to be category_value key to display name
                // if not found stick with original value
                const rhsDisplayName = lhs.getConfigurationCategoryValue(rhs)?.getDisplayName();
                rhsString = rhsDisplayName ? rhsDisplayName : rhs;
            }
            else {
                rhsString = rhs;
            }

            rhsString = `'${rhsString}'`;
        }

        // current is a viable option for metrics ending in event_id
        const event_ids = '_event_id';
        if (rhs === 'current' && lhsString.endsWith(event_ids)) {
            rhsString = `'${rhs}'`;
        }
    }

    if (rhs instanceof Date) {
        // In our REST API, Dates are represented in UNIX timestamps.
        // Make sure to convert them from their JS milliseconds to seconds.
        rhsString = (rhs.valueOf() / 1000).toString();  // Convert from ms to s.
    }
    else {
        rhsString = (rhsString || rhs ).toString();
    }

    return `${lhsString} ${opString} ${rhsString}`;
}

function formatForFormula ({ rhs, op, lhs }) {
    if (Array.isArray(rhs)) {
        throw 'Break rhs out individually before trying to format';
    }
    let opString = getFormulaOperator(op);
    let lhsString = '';
    let rhsString = rhs.toString();

    if (typeof lhs === 'string' ) {
        lhsString = lhs;
    }
    else {
        // [FUTUREHACK]
        if (lhs.isCategoryValue()) {
            throw 'Currently unsupported category value in filter formula.';
        }

        lhsString = lhs.getName();
    }

    return `${lhsString} ${opString} ${rhsString}`;
}

function formatForReadable ({ rhs, op, lhs, translator, combineRHS }) {
    let opString = getPrettyOperator(op, translator);
    let lhsString = '';
    let rhsStrings = [];

    if (typeof lhs === 'string' ) {
        lhsString = lhs;
        rhsStrings = rhs.map(singleValue => (singleValue.toString()));
    }
    else {
        lhsString = lhs.getDisplayName();
        rhsStrings = rhs.map(singleValue => {
            if (lhs.isCategoryValue()) {            
                // convert what is expected to be category_value key to display name
                // if not found stick with original value
                const rhsDisplayName = lhs.getConfigurationCategoryValue(singleValue)?.getDisplayName();
                return rhsDisplayName ? rhsDisplayName : translator('unknown_literal');
            }
            else {
                const formatter = lhs.getFormatter(null, {
                    // This value is what it looks like after post process
                    handlePostProcess: false,
                });
                return formatter(rhs);
            }
        });
    }

    let rhsFinalString = '';

    if (rhsStrings.length === 1) {
        rhsFinalString = rhsStrings[0];
    }
    else if (rhsStrings.length === 2) {
        rhsFinalString = `${rhsStrings[0]} ${combineRHS} ${rhsStrings[1]}`;
    }
    else {
        const lastRHSValue = rhsStrings.pop();
        rhsFinalString = `${rhsStrings.join(', ')}, ${combineRHS} ${lastRHSValue}`;
    }

    return `${lhsString} ${opString} ${rhsFinalString}`;
}

/**
 * Filter out any filter expression that is not active.
 * @param {Object[]} filters the filters to write out
 * 
 * @returns {Object[]}
 */
export function filterActiveFilters(filters) {
    return filters
        .map((filter) => {
            // Filter out any expressions that have no rhs value
            const activeExpressions = filter.expressions.filter((expression) => {
                if (Array.isArray(expression.rhs)) {
                    return expression.rhs.length > 0;
                }
                else {
                    return expression.rhs !== null && expression.rhs !== undefined;
                }
            });

            return {
                ...filter,
                expressions: activeExpressions,
            };
        })
        // Filter out any filters with no expressions
        .filter((filter) => {
            return filter.expressions.length > 0;
        });
}

export const HUMAN_READABLE = 'hr';
export const FOR_URL = 'url';
export const FOR_FORMULA = 'formula';

function isHumanReadable(printType) {
    return printType === HUMAN_READABLE;
}

/**
 * type should be and or or, this will either return the translated version of the base
 * version depending on if the text should be human readable or not
 */
function handleTranslate(printType, type, translator) {
    if (isHumanReadable(printType)) {
        return translator('filter.' + type);
    }
    return type;
}

const validPrintTypes = [HUMAN_READABLE, FOR_URL, FOR_FORMULA]
    .reduce((validTypes, type) => {
        validTypes[type] = true;
        return validTypes;
    }, {});

/**
 * Translate a filter expression to a string.
 * This is used to generate the text for the url, or just displaying it
 * so the user can more easily read it and more.
 * @param {Object} expression the filters to write out
 * @param {Object} opts the options for how to generate the filter text
 * @param {String} opts.printType the different ways to print it 
 *                          humanReadable - this will include spaces 
 *                            between the parenthesis, show > instead of ge,
 *                            and format values (.ie 75% instead of .75)
 *                          url - this will print it with less spaces and show ge instead of >
 *                          formula - this will print it so it can be utilized in formula
 *                            so it will show > instead of ge.
 * @param {Function} translator A function which accepts an object and returns the
 *     translated string. The objects shape is:
 *     - {String} key: the translation key.
 *     - {Object} substitutions: The substitutions passed along to the translator
 *                               for dynamic translations.
 * 
 * @returns {String}
 */
export function generateFilterExpressionText(expression, printType = HUMAN_READABLE, translator) {
    const { lhs, op, rhs } = expression;
    const rhsArray = Array.isArray(rhs) ? rhs : [rhs];
    const showExcessSpacing = isHumanReadable(printType);

    const combineRHS = op === 'eq'
        ? handleTranslate(printType, 'or', translator)
        : handleTranslate(printType, 'and', translator);

    if (isHumanReadable(printType)) {
        return formatForReadable({ lhs, rhs: rhsArray, op, translator, combineRHS });
    }
    else {
        return rhsArray.map((rr) => {
            let stringExpression = '';
            switch (printType) {
                case FOR_FORMULA:
                    stringExpression = formatForFormula({ lhs, rhs:rr, op });
                    break;
                case FOR_URL:
                    stringExpression = formatForURL({ lhs, rhs:rr, op });
                    break;
            }

            return rhsArray.length > 1
                ? showExcessSpacing ? `( ${stringExpression} )` : `(${stringExpression})`
                : `${stringExpression}`;
        }).join(` ${combineRHS} `);
    }
}

/**
 * Based on a filter array this will write out the expressions as strings.
 * This is used to generate the text for the url, or just displaying it
 * so the user can more easily read it and more.
 * @param {Object[]} filters the filters to write out
 * @param {Object} opts the options for how to generate the filter text
 * @param {String} opts.printType the different ways to print it 
 *                          humanReadable - this will include spaces 
 *                            between the parenthesis, show > instead of ge,
 *                            and format values (.ie 75% instead of .75)
 *                          url - this will print it with less spaces and show ge instead of >
 *                          formula - this will print it so it can be utilized in formula
 *                            so it will show > instead of ge.
 * @param {Function} translator A function which accepts an object and returns the
 *     translated string. The objects shape is:
 *     - {String} key: the translation key.
 *     - {Object} substitutions: The substitutions passed along to the translator
 *                               for dynamic translations.
 * 
 * @returns {String}
 */
export function generateFilterText (filters, printType = HUMAN_READABLE, translator) {
    if (!validPrintTypes[printType]) {
        throw `${printType} is not a valid print type.`;
    }

    const showExcessSpacing = isHumanReadable(printType);

    filters = filterActiveFilters(filters);

    return filters
        .reduce((agg, { expressions, combine_type }) => {
            if (agg) {
                agg += ` ${handleTranslate(printType, 'and', translator)} `;
            }

            // [FUTURUEHACK] combine_custom exists but is not yet implemented to work
            if (combine_type === 'custom') {
                throw 'This functionality has not yet been implemented';
            }

            if (expressions.length > 1) {
                agg += showExcessSpacing ? '( ' : '(';
            }

            expressions.forEach((exp, expIndex) => {
                if (expIndex > 0) {
                    const translatedOp = combine_type === 'and'
                        ? handleTranslate(printType, 'and', translator)
                        : handleTranslate(printType, 'or', translator);
                    agg += ` ${translatedOp} `;
                }

                const rhsLength = Array.isArray(exp.rhs) ? exp.rhs.length : 1;
                if (filters.length > 1 || expressions.length > 1 || rhsLength > 1) {
                    agg += showExcessSpacing ? '( ' : '(';
                }

                agg += generateFilterExpressionText(exp, printType, translator);

                if (filters.length > 1 || expressions.length > 1 || rhsLength > 1) {
                    agg += showExcessSpacing ? ' )' : ')';
                }
            });

            if (expressions.length > 1) {
                agg += showExcessSpacing ? ' )' : ')';
            }

            return agg;
        }, '');
}

/**
 * Get only slice filters from a filter array.
 * @param {Object[]} filters the filters
 * @param {Object} opts
 * @param {String} opts.type the type we want to filter slice or group
 * @param {Boolean} opts.isValid set to true to only get valid filters that have rhs defined
 * 
 * @returns {Object[]}
 */
function filterFilters(filters, opts) {
    return filters.reduce((validFilters, filter) => {
        if (filter.type === opts.type) {
            validFilters.push({
                ...filter,
                expressions: opts.isValid
                    ? filter.expressions.filter(({lhs, rhs, op}) =>
                        (lhs && op && rhs !== null && rhs !== undefined)
                    )
                    : filter.expressions
            });
        }
        return validFilters;
    }, []);
}

/**
 * Get only slice filters from a filter array.
 * @param {Object[]} filters the filters
 * @param {Object} opts
 * @param {Boolean} opts.isValid filters so its only valid filters that have rhs defined
 * 
 * @returns {Object[]}
 */
export function getSliceFilters(filters, opts={}) {
    return filterFilters(filters, { isValid: !!opts.isValid, type: 'slice' });
}

/**
 * Get only group filters from a filter array.
 * @param {Object[]} filters the filters
 * @param {Object} opts
 * @param {Boolean} opts.isValid filters so its only valid filters that have rhs defined
 * 
 * @returns {Object[]}
 */
export function getGroupFilters(filters, opts={}) {
    return filterFilters(filters, { isValid: !!opts.isValid, type: 'group' });
}

/**
 * Get the lhs column for each filter.
 * @param {Object[]} filters 
 *
 * @returns {Column/RequestColumn[]}
 */
export function getLHSColumns(filters) {
    return filters.reduce((columns, {expressions}) => {
        expressions.forEach(({lhs}) => {
            columns.push(lhs);
        });
        return columns;
    },[]);
}

/**
 * Update lhs column or remove expression based on updateRemoveFunc.
 * @param {Object[]} filters
 * @param {Function} updateRemoveFunc
 *
 * @returns {Object[]}
 */
export function updateRemoveFilterBasedOnLHS(filters, updateRemoveFunc) {
    return filters.reduce((updatedFilters, filter) => {
        let expressions = filter.expressions.reduce((updatedExpressions, expression) => {
            let updatedLHS = updateRemoveFunc(expression.lhs);
            if (updatedLHS) {
                updatedExpressions.push({ ...expression, lhs: updatedLHS });
            }
            return updatedExpressions;
        }, []);

        if (expressions.length) {
            updatedFilters.push({ ...filter, expressions });
        }

        return updatedFilters;
    }, []);
}
