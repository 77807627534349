import AsyncValueCache from './AsyncValueCache';

const validateWidgetId = (widgetId) => {
    if (typeof widgetId === 'undefined') {
        throw new Error('widgetId is required');
    }
    else if (typeof widgetId !== 'string') {
        throw new Error('widgetId must be a string');
    }
};

const validateCacheId = (cacheId) => {
    if (typeof cacheId === 'undefined') {
        throw new Error('cacheId is required');
    }
    else if (typeof cacheId !== 'string') {
        throw new Error('cacheId must be a string');
    }
};

/**
 * A class to manage many AsyncValueCaches for a dashboard. Each cache is
 * accessed by a combination of widget ID and cache ID strings.
 */
export default class DashboardCache {
    constructor() {
        this._widgetCaches = {};
    }

    /**
     * Get a value cache for a widget by widget ID and cache ID. If one does
     * not exist yet, it will be created.
     *
     * @param {String} widgetId 
     * @param {String} cacheId 
     * @returns {AsyncValueCache}
     */
    getWidgetValueCache(widgetId, cacheId) {
        validateWidgetId(widgetId);
        validateCacheId(cacheId);

        if (!this._widgetCaches[widgetId]) {
            this._widgetCaches[widgetId] = {};
        }

        if (!this._widgetCaches[widgetId][cacheId]) {
            this._widgetCaches[widgetId][cacheId] = new AsyncValueCache();
        }

        return this._widgetCaches[widgetId][cacheId];
    }

    /**
     * Clear a value cache for a widget by widget ID and cache ID.
     *
     * @param {String} widgetId
     * @param {String} cacheId
     */
    clearWidgetValueCache(widgetId, cacheId) {
        validateWidgetId(widgetId);
        validateCacheId(cacheId);

        if (!this._widgetCaches[widgetId]) {
            return; // Nothing to clear
        }

        if (!this._widgetCaches[widgetId][cacheId]) {
            return; // Nothing to clear
        }

        delete this._widgetCaches[widgetId][cacheId];
    }

    /**
     * Clear all the value caches for the specified widget.
     *
     * @param {String} widgetId
     */
    clearAllValueCachesForWidget(widgetId) {
        validateWidgetId(widgetId);

        if (!this._widgetCaches[widgetId]) {
            return; // Nothing to clear
        }

        // Delete all value caches for the widget.
        delete this._widgetCaches[widgetId];
    }
}
