import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from 'Components/text-input/TextInput';
import OutlinedButton from 'Components/button/OutlinedButton';
import PopupWindow from 'Components/popup/PopupWindow';
import ContainedButton from 'Components/button/ContainedButton';
import ErrorNotice from 'Components/notice/ErrorNotice';
import VerificationNotice from './VerificationNotice';

const StyledTextInput = styled(TextInput)`
    display: block;
`;

export default class PendingEmailEditor extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.shape({
            email: PropTypes.string,
            id: PropTypes.number,
        }),
        onChange: PropTypes.func.isRequired,
        validation: PropTypes.shape({
            email: PropTypes.string,
        }),
        disabled: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired,
        onCancelVerificationClick: PropTypes.func.isRequired,
        onResendVerificationClick: PropTypes.func.isRequired,
        error: PropTypes.node,
        success: PropTypes.node,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
    }

    _emitChange(value) {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: value,
            },
        });
    }

    _handleEmailChange = (event) => {
        this._emitChange({
            ...this.props.value,
            email: event.target.value,
        });
    };

    _handleChangeClick = (event) => {
        this._emitChange({
            email: '',
            verified: false,
        });
    };

    _handleCancelClick = (event) => {
        this._emitChange(null);
        this.props.onReset();
    };

    _handleConfirmClick = () => {
        this.props.onSubmit(this.props.value);
    };

    render() {
        const {
            name,
            value,
            validation,
            disabled,
            error,
            success,
            onCancelVerificationClick,
            onResendVerificationClick,
        } = this.props;

        const hasPendingEmail = value !== null;
        const isSaved = typeof value?.id === 'number';

        return (
            <React.Fragment>
                {!isSaved && (
                    <OutlinedButton
                        color="primary"
                        onClick={this._handleChangeClick}
                    >
                        Change Email
                    </OutlinedButton>
                )}
                {hasPendingEmail && !isSaved && (
                    <PopupWindow
                        title="Change Email"
                        width={500}
                        buttons={[
                            <ContainedButton
                                key="confirm"
                                color="primary"
                                onClick={this._handleConfirmClick}
                                disabled={disabled || !!validation?.email}
                            >
                                Change Email
                            </ContainedButton>,
                            <OutlinedButton
                                key="cancel"
                                color="primary"
                                onClick={this._handleCancelClick}
                                disabled={disabled}
                            >
                                Cancel
                            </OutlinedButton>
                        ]}
                    >
                        <StyledTextInput
                            label="New Email"
                            placeholder="email@example.com"
                            value={value.email}
                            type="email"
                            name={`${name}.email`}
                            error={!!validation?.email}
                            disabled={disabled}
                            helperText={validation?.email || ''}
                            onChange={this._handleEmailChange}
                            autoComplete="email"
                            autoFocus
                        />
                        {error && (
                            <ErrorNotice>
                                {error}
                            </ErrorNotice>
                        )}
                    </PopupWindow>
                )}
                {hasPendingEmail && isSaved && (
                    <VerificationNotice
                        disabled={disabled}
                        error={error}
                        success={success}
                        onCancelVerificationClick={onCancelVerificationClick}
                        onResendVerificationClick={onResendVerificationClick}
                    >
                        A verification link has been sent to <b>{value.email}</b>.
                        You must confirm this email address before it will become
                        your primary email.
                    </VerificationNotice>
                )}
            </React.Fragment>
        );
    }
}
