import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Column from './Column';
import { COLUMN_PROPERTIES } from './constants';
import styled, { css } from 'styled-components';

import SectionToolbar from './SectionToolbar/SectionToolbar';
import SectionHeadingEditor from './SectionHeadingEditor';
import useHover from 'Common/hooks/useHover';

const breakWidth = {
    md: '1020px',
    sm: '680px',
    xs: '400px',
};

const breakMap = {
    full: {
        lg: 'minmax(1px, auto)',
        md: 'minmax(1px, auto)',
        sm: 'minmax(1px, auto)',
        xs: 'minmax(1px, auto)',
    },
    halves: {
        lg: 'repeat(2, 50%)',
        md: 'repeat(2, 50%)',
        sm: 'minmax(1px, auto)',
        xs: 'minmax(1px, auto)',
    },
    'two-thirds-one-third': {
        lg: '66.666% 33.333%',
        md: '66.666% 33.333%',
        sm: 'minmax(1px, auto)',
        xs: 'minmax(1px, auto)',
    },
    'one-third-two-thirds':
    {
        lg: '33.333% 66.666%',
        md: '33.333% 66.666%',
        sm: 'minmax(1px, auto)',
        xs: 'minmax(1px, auto)',
    },
    thirds: {
        lg: 'repeat(3, 33.333%)',
        md: 'repeat(3, 33.333%)',
        sm: 'repeat(2, 50%)',
        xs: 'minmax(1px, auto)',
    },
    fourths: {
        lg: 'repeat(4, 25%)',
        md: 'repeat(3, 33.333%)',
        sm: 'repeat(2, 50%)',
        xs: 'minmax(1px, auto)',
    },
    fifths: {
        lg: 'repeat(5, 20%)',
        md: 'repeat(3, 33.333%)',
        sm: 'repeat(2, 50%)',
        xs: 'minmax(1px, auto)',
    },
    sixths: {
        lg: 'repeat(6, 16.666%)',
        md: 'repeat(3, 33.333%)',
        sm: 'repeat(2, 50%)',
        xs: 'minmax(1px, auto)',
    },
    scoreboard: {
        lg: '16.666% 66.666% 16.666%',
        md: '16.666% 66.666% 16.666%',
        sm: 'minmax(1px, auto)',
        xs: 'minmax(1px, auto)',
    },
};

const SectionRoot = styled.div`
`;

const SectionToolbarContainer = styled.div`
    transition: opacity 300ms, margin-top 300ms, height 300ms;

    ${({ $height }) => Number.isFinite($height) && css`
        height: ${$height}px;
    `}

    opacity: 0;
    margin-top: 0;

    ${({ $isEntering }) => $isEntering && css`
        opacity: 1;
        margin-top: 20px;
    `}

    ${({ $isEntered }) => $isEntered && css`
        opacity: 1;
        margin-top: 20px;
    `}

    ${({ $isLeaving }) => $isLeaving && css`
        opacity: 0;
        margin-top: 0;
    `}
`;

const animationReducer = (state, action) => {
    const { type, payload } = action;

    switch(type) {
        case 'ENTER_BEFORE': {
            return {
                ...state,
                isEntering: false,
                isEntered: false,
                isLeaving: false,
                toolbarHeight: 0,
                renderToolbar: true,
            };
        }
        case 'ENTER_START': {
            const { toolbarContainerRef } = payload;

            return {
                ...state,
                isEntering: true,
                isEntered: false,
                isLeaving: false,
                toolbarHeight: toolbarContainerRef.current ?
                    toolbarContainerRef.current.children[0].getBoundingClientRect().height
                    : null,
                renderToolbar: true,
            };
        }
        case 'ENTER_END': {
            return {
                ...state,
                isEntering: false,
                isEntered: true,
                isLeaving: false,
                toolbarHeight: null,
                renderToolbar: true,
            };
        }
        case 'LEAVE_BEFORE': {
            const { toolbarContainerRef } = payload;

            return {
                ...state,
                isEntering: false,
                isEntered: false,
                isLeaving: false,
                toolbarHeight: toolbarContainerRef.current ?
                    toolbarContainerRef.current.children[0].getBoundingClientRect().height
                    : null,
                renderToolbar: true,
            };
        }
        case 'LEAVE_START': {
            return {
                ...state,
                isEntering: false,
                isEntered: false,
                isLeaving: true,
                toolbarHeight: 0,
                renderToolbar: true,
            };
        }
        case 'LEAVE_END': {
            return {
                ...state,
                isEntering: false,
                isEntered: false,
                isLeaving: false,
                toolbarHeight: null,
                renderToolbar: false,
            };
        }
    }
};

// Do not use grid on print and/or full section cause a full section doesn't have more than 1 column
// Allow print views to lay things out in a reasonable manner with a wrapping flex box.
const ColumnContainer = styled.div`
    margin-top: 15px;

    ${({$isPrint, $isFullSection}) => (!$isPrint && !$isFullSection) && css`
        display: grid;
        grid-template-columns: ${({$colOpts}) => $colOpts.lg};

        @media (max-width: ${breakWidth.md}) {
            grid-template-columns: ${({$colOpts}) => $colOpts.md};
        }

        @media (max-width: ${breakWidth.sm}) {
            grid-template-columns: ${({$colOpts}) => $colOpts.sm};
        }

        @media (max-width: ${breakWidth.xs}) {
            grid-template-columns: ${({$colOpts}) => $colOpts.xs};
        }
    `}

    ${({$isPrint}) => $isPrint && css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        & > div {
            flex: 1;
            // let flex gap handle spacing between widgets
            margin-right: 0;
            margin-bottom: 0;
            // needed so it does not grow bigger than the parent
            min-width: 0;
        }

        & > div > div {
            margin-bottom: 0;
        }
    `}
`;

const ColumnContainerAsSelection = styled(ColumnContainer)`
    ${({ $isLayoutModeActive }) => $isLayoutModeActive && css`
        border: 1px solid ${({ theme }) => theme.colors.palette.grey.ash};
        padding: 10px 10px;
        border-radius: 6px;
        min-height: 50px;
        user-select: none;
    `}

    ${({ $showHover }) => $showHover && css`
        border-color: ${({ theme }) => theme.colors.palette.grey.manatee};
        background-color: ${({ theme }) => theme.colors.palette.blue.ice};
    `}

    ${({ $showSelected }) => $showSelected && css`
        border-color: ${({ theme }) => theme.colors.palette.blue.awesome};
        background-color: ${({ theme }) => theme.colors.palette.blue.ice};
    `}

    ${({ $showDeleteHover }) => $showDeleteHover && css`
        border-color: ${({ theme }) => theme.colors.palette.error.red};
        background-color: ${({ theme }) => theme.colors.palette.error.helios};
    `}

`;

export default function Section(props) {
    const {
        isDeleteDisabled,
        isLastSection,
        isLayoutModeActive,
        isPrint,
        sectionIndex,
        sectionConfig,
        selected,
        addSection,
        deleteSection,
        getScrollParent,
        modifySection,
        moveSection,
        onAddWidget,
        onSelect,
        renderWidget,
        widgetSchema,
        sectionTypeOptions,
        maxWidgetsPerColumn,
        sectionAsSelection,
    } = props;

    const showSelected = selected && isLayoutModeActive;

    const numColumns = COLUMN_PROPERTIES[sectionConfig.type].num;

    const [ deleteSectionHovered, setDeleteSectionHovered ] = useState(false);

    const handleDeleteSectionHoverChange = (val) => {
        if (val !== deleteSectionHovered) {
            setDeleteSectionHovered(val);
        }
    };

    const handleClick = () => {
        onSelect(sectionIndex);
    };

    const [sectionElement, setSectionElement] = useState(null);
    const isHovered = useHover(sectionElement);

    const showHover = isHovered && ! deleteSectionHovered;

    const showToolbar = isLayoutModeActive && selected;

    const toolbarContainerRef = useRef(null);

    const [{ toolbarHeight, renderToolbar, isEntering, isEntered }, dispatch] = useReducer(animationReducer, {
        toolbarHeight: null,
        renderToolbar: showToolbar,
        isEntering: false,
        isEntered: showToolbar,
        isLeaving: false,
    });

    const firstRender = useRef(true);

    useEffect(() => {
        let animationTimer = null;

        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (showToolbar) {
            dispatch({
                type: 'ENTER_BEFORE',
                payload: { toolbarContainerRef },
            });

            animationTimer = setTimeout(() => {
                dispatch({
                    type: 'ENTER_START',
                    payload: { toolbarContainerRef },
                });
                animationTimer = setTimeout(() => {
                    dispatch({
                        type: 'ENTER_END',
                        payload: { toolbarContainerRef },
                    });
                    animationTimer = null;
                }, 300);
            }, 10);
        }
        else {
            dispatch({
                type: 'LEAVE_BEFORE',
                payload: { toolbarContainerRef },
            });

            animationTimer = setTimeout(() => {
                dispatch({
                    type: 'LEAVE_START',
                    payload: { toolbarContainerRef },
                });
                animationTimer = setTimeout(() => {
                    dispatch({
                        type: 'LEAVE_END',
                        payload: { toolbarContainerRef },
                    });
                    animationTimer = null;
                }, 1000);
            }, 10);
        }

        return () => {
            if (animationTimer) {
                clearTimeout(animationTimer);
                animationTimer = null;
            }
        };
    }, [ showToolbar ]);

    const handleSectionToolbarContainerClick = useCallback((event) => {
        // Stop clicks from bubbling out of the toolbar. This is needed because
        // clicking anywhere in the section will cause the section to become
        // selected. That is not good because sometimes we want to switch
        // the selected selection in response to a toolbar click, like when
        // adding or moving a section.
        event.stopPropagation();
    }, []);

    const ColumnContainerComponent = sectionAsSelection ? ColumnContainerAsSelection : ColumnContainer;

    return (
        <SectionRoot
            onClick={handleClick}
            ref={setSectionElement}
        >
            {isLayoutModeActive && (<SectionHeadingEditor
                sectionIndex={sectionIndex} 
                canEdit={isLayoutModeActive} 
                onChange={modifySection}
                value={sectionConfig.heading} />
            )}
            <ColumnContainerComponent
                $isLayoutModeActive={isLayoutModeActive}
                $colOpts={breakMap[sectionConfig.type]}
                $isPrint={isPrint}
                $isFullSection={sectionConfig.type === 'full'}
                $showHover={showHover && !showSelected}
                $showDeleteHover={deleteSectionHovered}
                $showSelected={showSelected}
            >
                {Array(numColumns).fill(null).map((_, columnIndex) => {
                    const widgets = sectionConfig.columns[columnIndex]?.widgets ||  [];

                    return (
                        <Column
                            key={`${sectionIndex}-${columnIndex}`}
                            sectionAsSelection={sectionAsSelection}
                            sectionIndex={sectionIndex}
                            columnIndex={columnIndex}
                            maxWidgets={maxWidgetsPerColumn}
                            widgets={widgets}
                            renderWidget={renderWidget}
                            isLayoutModeActive={isLayoutModeActive}
                            getScrollParent={getScrollParent}
                            showHover={showHover && isLayoutModeActive}
                            showDeleteHover={deleteSectionHovered}
                            showSelected={selected && isLayoutModeActive}
                            onAddWidget={onAddWidget}
                            widgetSchema={widgetSchema}
                        />
                    );
                })}
            </ColumnContainerComponent>
            <SectionToolbarContainer
                ref={toolbarContainerRef}
                $height={toolbarHeight}
                $isEntering={isEntering}
                $isEntered={isEntered}
                onClick={handleSectionToolbarContainerClick}
            >
                {renderToolbar && (
                    <SectionToolbar
                        sectionConfig={sectionConfig}
                        isLastSection={isLastSection}
                        isDeleteDisabled={isDeleteDisabled}
                        index={sectionIndex}
                        onAddSection={addSection}
                        onDeleteSection={deleteSection}
                        onModifySection={modifySection}
                        onMoveSection={moveSection}
                        onDeleteSectionHovered={handleDeleteSectionHoverChange}
                        sectionTypeOptions={sectionTypeOptions}
                    />
                )}
            </SectionToolbarContainer>
        </SectionRoot>
    );
}

Section.propTypes = {
    isDeleteDisabled: PropTypes.bool,
    isLastSection: PropTypes.bool,
    isLayoutModeActive: PropTypes.bool.isRequired,
    isPrint: PropTypes.bool.isRequired,
    sectionConfig: PropTypes.shape(
        {
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    widgets: PropTypes.arrayOf(
                        PropTypes.string.isRequired,
                    ),
                }).isRequired,
            ).isRequired,
            heading: PropTypes.string,
            type: PropTypes.string.isRequired,
        }
    ).isRequired,
    sectionIndex: PropTypes.number.isRequired,
    // true if the entire section should show selected, hover and deleted activity or if it should
    // be displayed individually per column
    sectionAsSelection: PropTypes.bool.isRequired,
    maxWidgetsPerColumn: PropTypes.number.isRequired,
    selected: PropTypes.bool,

    addSection: PropTypes.func,
    deleteSection: PropTypes.func,
    getScrollParent: PropTypes.func,
    modifySection: PropTypes.func,
    moveSection: PropTypes.func,
    onAddWidget: PropTypes.func,
    onSelect: PropTypes.func,
    renderWidget: PropTypes.func.isRequired,
    widgetSchema: PropTypes.object,
    sectionTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            icon: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    ),
};

Section.defaultProps = {
    isDeleteDisabled: false,
    isLastSection: false,
    selected: false,

    addSection: () => {},
    deleteSection: () => {},
    getScrollParent: () => window,
    modifySection: () => {},
    moveSection: () => {},
    onAddWidget: () => {},
    onSelect: () => {},
};
