import { Roles } from 'Components/app/roles';

export function getDefaultPageForRole(role) {
    if (role === Roles.ADMIN) {
        return '/config/plant-floor/alert-definitions';
    }
    else {
        return '/subscriptions/alerts';
    }
}
