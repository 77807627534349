import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { elideTextCss } from 'Common/util/cssSnippets';

import Icon from 'Common/components/Icon';
import { Secondary5 } from 'Common/components/typography';

export const TRASH_CAN_HEIGHT = 62;

const Root = styled.div`
    border-top: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
    margin-top: -1px;
    padding: 15px 0px 8px 0px;
    height: ${TRASH_CAN_HEIGHT}px;
`;

const DropTargetContainer = styled.div`
    box-shadow: 0px 0px 4px -2px ${({theme}) => theme.colors.palette.grey.emperor};
    background-color: ${({theme}) => theme.colors.palette.grey.cloud };
    border-radius: 4px;
    border: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
    ${({ $isOver }) => $isOver && css`
        background-color: ${({theme}) => theme.colors.palette.error.helios };
    `}
    color: ${({ theme }) => theme.colors.darkText.mediumEmphasis};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

const StyledIcon = styled(Icon)`
    font-size: 14px;
`;

const StyledText = styled(Secondary5)`
    ${elideTextCss}
`;

/**
 * This is used in DraggableListWithTrashCan. It's the displayed drop target that looks
 * like a trash can with additional text.
 */

function TrashCan(props) {
    const { deleteHelperText, isOver } = props;

    return (
        <Root>
            <DropTargetContainer
                $isOver={isOver}
            >
                <StyledIcon
                    type="vorne"
                    iconName="delete"
                />
                <StyledText>
                    {deleteHelperText}
                </StyledText>
            </DropTargetContainer>
        </Root>
    );
}

TrashCan.propTypes = {
    deleteHelperText: PropTypes.string,
    isOver: PropTypes.bool.isRequired,
};

export default React.memo(TrashCan);
