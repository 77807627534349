import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ButtonBase from 'Common/components/Button/ButtonBase';
import Icon from 'Common/components/Icon';
import ImageIcon from 'Common/components/Icon/ImageIcon';
import { Heading5 } from 'Common/components/typography';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import BasicSelectList from 'Common/components/SelectList/BasicSelectList';
import { useTranslator } from 'Common/hooks/useTranslation';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledDropdown = styled(Dropdown)`
    width: 100%;
    max-width: 200px;
`;

const DropdownContentContainer = styled.div``;

const OpenButton = styled(Heading5).attrs({ as: ButtonBase })`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 10px 10px;
    width: 100%;
    height: 42px;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;

    ${({ theme }) => css`
        color: ${theme.colors.darkText.mediumEmphasis};
        color: ${theme.colors.palette.blue.awesome};
    `};

    transition: color 0.25s, background-color 0.25s;

    &:not(:disabled) {
        cursor: pointer;

        &:hover, &:focus {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:active { /* When the button is being pressed. */
            ${({ theme }) => css`
                background-color: ${theme.colors.palette.grey.silver};
            `};
        }
    }

    &:disabled {
        ${({ theme }) => css`
            color: ${theme.colors.darkText.disabled};
        `};
    }
`;

const ButtonIcon = styled(Icon)`
    margin-right: 4px;
`;

const ButtonText = styled.span``;

export default function AddWidgetButton({
    disabled,
    onAddWidget,
    widgetSchema,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const openDropdown = useCallback(() => {
        setIsOpen(true);
    }, [ setIsOpen ]);

    const closeDropdown = useCallback(() => {
        setIsOpen(false);
    }, [ setIsOpen ]);

    const handleChange = useCallback((event) => {
        onAddWidget(event.target.value);
        closeDropdown();
    }, [ onAddWidget, closeDropdown ]);

    const translator = useTranslator();
    const options = useMemo(() => {
        return Object.values(widgetSchema).map((item) => {
            return {
                description: translator(item.descriptionKey),
                displayName: translator(item.displayKey) + (item.liveControls ? '' : '…'),
                icon: (
                    <ImageIcon url={item.iconUrl} />
                ),
                value: item.type,
            };
        });
    }, [translator, widgetSchema]);

    const renderContent = useCallback(() => {
        return (
            <DropdownContentContainer>
                <BasicSelectList
                    onChange={handleChange}
                    options={options}
                />
            </DropdownContentContainer>
        );
    }, [ handleChange, options ]);

    return (
        <Root>
            <StyledDropdown
                isOpen={isOpen && !disabled}
                onMaskClick={closeDropdown}
                renderContent={renderContent}
                shadow
            >
                <OpenButton
                    onClick={openDropdown}
                >
                    <ButtonIcon type="vorne" iconName="add" />
                    <ButtonText>{translator('add_widget_literal')}</ButtonText>
                </OpenButton>
            </StyledDropdown>
        </Root>
    );
}

AddWidgetButton.propTypes = {
    disabled: PropTypes.bool,
    onAddWidget: PropTypes.func.isRequired,
    widgetSchema: PropTypes.object.isRequired,
};

AddWidgetButton.defaultProps = {
    disabled: false,
};
