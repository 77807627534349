import React from 'react';
import IconLink from '../IconLink';
import FaIcon from 'Components/icon/FaIcon';

export default React.forwardRef(
    function MainMenuMobileIconLink(props, ref) {
        return (
            <IconLink ref={ref} {...props}>
                <FaIcon i="bars" />
            </IconLink>
        );
    }
);
