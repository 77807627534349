import React from 'react';
import PropTypes from 'prop-types';
import 'Fonts/vornefont/style.css';
import classNames from 'classnames';

export default class VorneIcon extends React.Component {
    render() {
        const { className, ...otherProps } = this.props;

        return (
            <span className={classNames(`vorne-icon vorne-icon-${this.props.i}`, className)} {...otherProps} />
        );
    }
}

VorneIcon.propTypes = {
    className: PropTypes.string,
    i: PropTypes.string,
};

VorneIcon.defaultProps = {
    i: '',
};
