import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Icon from 'Common/components/Icon';
import { TouchableTooltipTarget } from 'Common/components/Tooltip';

export const INFO = 'info';
export const ERROR = 'error';

const InfoIcon = styled(Icon).attrs({
    type: 'vorne',
    iconName: 'info',
})`
    color: ${({color}) => color};

    font-size: ${(props) => props.fontSize}px;

    /* Slightly push the icon down within the touchable container
       to better align with other inline text.
    */
    transform: translateY(${(props) => props.fontSize / 10}px);
`;

const typeMap = {
    [INFO]: {
        type: 'vorne',
        iconName: 'info',
        getColor: (theme) => theme.colors.palette.grey.manatee,
    },
    [ERROR]: {
        type: 'fontAwesome',
        iconName: 'exclamation-circle',
        getColor: (theme) => theme.colors.palette.error.red,
    },
};

/**
 * A touchable, clickable, and hoverable icon which shows more information in a
 * tooltip. Has types for showing general information or for showing an error.
 *
 * @param {Node} children The content of the tooltip shown on hover or click/tap
 * @param {Enum} [type=INFO] The type of info to be shown, INFO or ERROR, which
 *     have different icons and default colors.
 * @param {Number} [fontSize=12] The font size of the icon.
 * @param {String} [circleColor] The color of the icon, defaults to a grey for INFO
 *     and a red for ERROR.
 * @param {String} [placement='auto'] The default placement direction of the tooltip,
 *     if it fits.
 */
function InfoCircle({
    children,
    type,
    fontSize,
    circleColor,
    placement,
}) {
    const theme = useTheme();
    const typeInfo = typeMap[type];

    if (!circleColor) {
        circleColor = typeInfo.getColor(theme);
    }

    return (
        <TouchableTooltipTarget
            content={children}
            placement={placement}
        >
            <InfoIcon
                color={circleColor}
                fontSize={fontSize}
                type={typeInfo.type}
                iconName={typeInfo.iconName}
            />
        </TouchableTooltipTarget>
    );
}

InfoCircle.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf([
        INFO,
        ERROR,
    ]),
    fontSize: PropTypes.number,
    circleColor: PropTypes.string,
    placement: PropTypes.string,
};

InfoCircle.defaultProps = {
    type: INFO,
    fontSize: 12,
    placement: 'auto',
};

export default React.memo(InfoCircle);
