import {useState, useLayoutEffect}  from 'react';
import useResizeObserver from '@react-hook/resize-observer';

/**
 * A hook to provide the bounding rect of a target element. Updates on resize.
 *
 * @param {Element} target The DOM element to track the size of.
 *
 * @returns {Object} the bounding content rect of the target.
 */
export default function useBounds(target) {
    const [bounds, setBounds] = useState();

    useLayoutEffect(() => {
        if (target) {
            setBounds(target.getBoundingClientRect());
        }
    }, [target]);

    useResizeObserver(target, (entry) => setBounds(entry.contentRect));

    return bounds;
}
