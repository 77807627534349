import React, { 
    useCallback,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    KeyboardSensor,
    LayoutMeasuringStrategy,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import Section from './Section';
import Button from 'Common/components/Button';
import ModalWindow from 'Common/components/ModalWindow/ModalWindow';
import { WorkaroundDndContext } from './WorkaroundDndContext';
import { useTranslator } from 'Common/hooks/useTranslation';

function closest(rects, rect, testFn) {
    let closestId = null;
    let minDistance = Infinity;

    rects.forEach((testRect) => {
        const distance = Math.abs(testFn(testRect[1], rect));
        if (distance < minDistance) {
            closestId = testRect[0];
            minDistance = distance;
        }
    });

    return closestId;
}

function closestTopLeftCorner(rects, rect) {
    return closest(rects, rect, (a, b) => {
        // 2D distance
        return Math.sqrt(
            Math.pow(a.offsetTop - b.top, 2) +
                Math.pow(a.offsetLeft - b.left, 2),
        );
    });
}

function customCollisionDetectionStrategy(rects, rect) {
    return closestTopLeftCorner(rects, rect);
}

const Root = styled.div``;

export default function DashboardLayoutEditor(props) {
    const {
        sections,
        onWidgetMove,
        renderWidget,
        getScrollParent,
        addSection,
        deleteSection,
        modifySection,
        moveSection,
        onAddWidget,
        widgetSchema,
        sectionTypeOptions,
        maxWidgetsPerColumn,
        sectionAsSelection,
    } = props;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor),
    );

    const translator = useTranslator();

    const [ isDragging, setIsDragging ] = useState(false);

    const handleDragStart = useCallback(() => {
        setIsDragging(true);
    }, []);

    const handleDragCancel = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleDragEnd = useCallback((event) => {
        setIsDragging(false);

        const source = event.active.data.current;

        if (!event.over) {
            return;
        }

        const destination = event.over.data.current;

        // If the widget is being moved within the same column, then we may need
        // to apply an offset to the destination widgetIndex to account for the
        // fact that higher indexes will shift when the widget is removed.
        let destinationOffset = 0;
        if (
            // Same starting and ending column
            source.sectionIndex === destination.sectionIndex &&
            source.columnIndex === destination.columnIndex &&
            // Dropped at higher than starting index
            source.widgetIndex < destination.widgetIndex
        ) {
            destinationOffset = -1;
        }

        onWidgetMove({
            source: {
                sectionIndex: source.sectionIndex,
                columnIndex: source.columnIndex,
                widgetIndex: source.widgetIndex,
            },
            destination: {
                sectionIndex: destination.sectionIndex,
                columnIndex: destination.columnIndex,
                widgetIndex: destination.widgetIndex + destinationOffset,
            },
        });
    }, [ onWidgetMove ]);

    const [ selectedSection, setSelectedSection ] = useState(0);

    const onMoveSection = useCallback((event) => {
        const { sectionDestIndex } = event;
        moveSection(event);
        setSelectedSection(sectionDestIndex);
    }, [
        moveSection, setSelectedSection
    ]);

    const onAddSection = useCallback((event) => {
        const { sectionIndex } = event;
        addSection(event);
        setSelectedSection(sectionIndex + 1);
    }, [addSection, setSelectedSection]);

    const [ deleteSectionConfirmationState, setDeleteSectionConfirmationState ] = useState({showDialog: false});

    const onDeleteSectionContinue = useCallback(() => {
        deleteSection(deleteSectionConfirmationState.event);
        setDeleteSectionConfirmationState({showDialog: false});
    }, [deleteSection, deleteSectionConfirmationState]);

    const onDeleteSectionCancel = useCallback(() => {
        setDeleteSectionConfirmationState({showDialog: false});
    }, []);

    const onDeleteSelection = useCallback((event) => {
        const { sectionIndex } = event;
        const sectionToDelete = sections[sectionIndex];
        const sectionToDeleteHasColumns = !!sectionToDelete?.columns.find(col => (col.widgets.find(widget => (widget))));

        if (!sectionToDelete) {
            return;
        }
        else if (sectionToDeleteHasColumns) {
            setDeleteSectionConfirmationState({event, showDialog: true});
        }
        else {
            deleteSection(event);
            const newSelectedSection = sectionIndex > 0 ?  sectionIndex - 1 : 0;
            setSelectedSection(newSelectedSection);
        }
    }, [
        sections, deleteSection, setSelectedSection, setDeleteSectionConfirmationState
    ]);

    return (
        <Root
            style={{
                // Stops the dragging item from resizing the parent while
                // dragging
                overflow: isDragging ? 'hidden' : 'unset',
            }}
        >
            <WorkaroundDndContext
                sensors={sensors}
                collisionDetection={customCollisionDetectionStrategy}
                layoutMeasuring={{ strategy: LayoutMeasuringStrategy.Always }}
                autoScroll={true}
                onDragStart={handleDragStart}
                onDragCancel={handleDragCancel}
                onDragEnd={handleDragEnd}
            >
                {sections.map((section, sectionIndex) => {
                    return (
                        <Section
                            key={sectionIndex}
                            sectionConfig={section}
                            sectionIndex={sectionIndex}
                            renderWidget={renderWidget}
                            // Do not allow deleting the last section
                            isDeleteDisabled={sections.length === 1}
                            isLastSection={sectionIndex == sections.length - 1}
                            isLayoutModeActive={true}
                            // shouldn't be printing the DashboardLayoutEditor, only the DashboardLayout
                            isPrint={false}
                            getScrollParent={getScrollParent}
                            addSection={onAddSection}
                            deleteSection={onDeleteSelection}
                            modifySection={modifySection}
                            moveSection={onMoveSection}
                            onSelect={setSelectedSection}
                            selected={selectedSection === sectionIndex}
                            onAddWidget={onAddWidget}
                            widgetSchema={widgetSchema}
                            sectionTypeOptions={sectionTypeOptions}
                            maxWidgetsPerColumn={maxWidgetsPerColumn}
                            sectionAsSelection={sectionAsSelection}
                        />
                    );
                })}
            </WorkaroundDndContext>
            {deleteSectionConfirmationState.showDialog &&
                (
                    <ModalWindow
                        title={translator('control.section.delete_dialog.title')}
                        bodyWidth={550}
                        buttons={[
                            <Button
                                key="cancel"
                                type="secondary"
                                onClick={onDeleteSectionCancel}
                            >
                                {translator('cancel_literal')}
                            </Button>,
                            <Button
                                key="continue"
                                type="primary"
                                onClick={onDeleteSectionContinue}
                            >
                                {translator('control.section.delete_dialog.delete_section')}
                            </Button>
                        ]}
                    >
                        <div>
                            {translator('control.section.delete_dialog.body')}
                        </div>
                    </ModalWindow>
                )
            }
        </Root>
    );
}

DashboardLayoutEditor.defaultProps = {
    maxWidgetsPerColumn: Number.MAX_SAFE_INTEGER,
};

DashboardLayoutEditor.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            columns: PropTypes.array.isRequired,
        }).isRequired,
    ).isRequired,
    onWidgetMove: PropTypes.func.isRequired,
    renderWidget: PropTypes.func.isRequired,
    getScrollParent: PropTypes.func,
    addSection: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
    modifySection: PropTypes.func.isRequired,
    moveSection: PropTypes.func.isRequired,
    onAddWidget: PropTypes.func.isRequired,
    widgetSchema: PropTypes.object,
    // sectionTypeOptions is an array of options that will be placed after the core section options
    // of add, move up, move down, delete. Currently in XLPA they are used for modifying the number
    // of columns a section has
    sectionTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            icon: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    // true if the entire section should show selected, hover and deleted activity or if it should
    // be displayed individually per column
    sectionAsSelection: PropTypes.bool.isRequired,
    maxWidgetsPerColumn: PropTypes.number.isRequired,
};
