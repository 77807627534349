import React from 'react';
import PropTypes from 'prop-types';
import AlertSubscriptionsEditor from 'Components/pages/alerts-subscribe/AlertSubscriptionsEditor';
import Form from 'Components/form/Form';
import * as RequestStatus from 'Common/data/RequestStatus';

export default class AlertSubscriptionsForm extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        alerts: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
        requests: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            modifiedData: props.data,
            lastPatchStatus: props?.requests?.patchAlertSubscriptions?.status,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const lastPatchStatus = state.lastPatchStatus;
        const nextPatchStatus = props?.requests?.patchAlertSubscriptions?.status;

        if (lastPatchStatus !== nextPatchStatus) {
            let nextModifiedData = state.modifiedData;

            // Watch for when patchAlertSubscriptions changes from PENDING to SUCCESS and
            // reset the data
            if (lastPatchStatus === RequestStatus.PENDING &&
                nextPatchStatus === RequestStatus.SUCCESS
            ) {
                nextModifiedData = props.data;
            }

            return {
                modifiedData: nextModifiedData,
                lastPatchStatus: nextPatchStatus,
            };
        }

        return null; // No changes
    }

    _handleChange = (event) => {
        this.setState({
            modifiedData: event.target.value,
        });
    };

    _handleReset = () => {
        this.setState({
            modifiedData: this.props.data,
        });
    };

    _handleSubmit = () => {
        this.props.onSubmit({
            target: {
                name: this.props.name,
                value: this.state.modifiedData,
            },
        });
    };

    render() {
        const { alerts, data: initialData, requests } = this.props;
        const { modifiedData } = this.state;

        const hasChanges = modifiedData !== initialData;
        const isLoading = requests.patchAlertSubscriptions.status === RequestStatus.PENDING;

        const allowEdit = !isLoading;
        const allowSubmit = !isLoading && hasChanges;
        const allowCancel = !isLoading && hasChanges;

        return (
            <Form
                error={requests?.patchAlertSubscriptions?.error}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <AlertSubscriptionsEditor
                    name="AlertSubscriptionsEditor"
                    alerts={alerts}
                    subscriptions={modifiedData}
                    allowEdit={allowEdit}
                    onChange={this._handleChange}
                />
            </Form>
        );
    }
}
