import styled from 'styled-components';
import Icon from 'Common/components/Icon';

const LiveControlIcon = styled(Icon)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export default LiveControlIcon;
