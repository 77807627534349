import React from 'react';
import PropTypes from 'prop-types';
import LoadingWrapper from 'Components/loading-indicator/LoadingWrapper';
import * as RequestStatus from 'Common/data/RequestStatus';

/**
 * A wrapper component that is given an array of request states, and will not
 * render its contents until all requests have completed.
 */
function MultiRequestLoadingWrapper({ children, requests, render, ...otherProps }) {
    let isLoading = false;
    let errorMessage;

    requests.forEach((request) => {
        const { status, error } = request;
        // The loading indicator should be set until all requests are complete
        isLoading = isLoading ||
            (status !== RequestStatus.SUCCESS && status !== RequestStatus.FAILURE);

        // If this request has failed, set the error message if one hasn't been
        // set already
        if (status === RequestStatus.FAILURE) {
            errorMessage = errorMessage || error.message;
        }
    });

    return (
        <LoadingWrapper
            loading={isLoading}
            error={errorMessage}
            render={render}
            {...otherProps}
        >
            {children}
        </LoadingWrapper>
    );
}

MultiRequestLoadingWrapper.propTypes = {
    children: PropTypes.node,
    render: PropTypes.func,
    requests: PropTypes.arrayOf(
        PropTypes.shape({
            status: PropTypes.string,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired
    ).isRequired,
};

export default MultiRequestLoadingWrapper;
