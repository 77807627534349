import React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

function _calculateVisibleItems(node) {
    let visibleCount = 0;
    const bounds = node.getBoundingClientRect();
    const containerWidth = bounds.width;
    let cumeWidth = 0;

    for (let ii = 0; ii < node.childElementCount; ii++) {
        const childBounds = node.children[ii].getBoundingClientRect();
        cumeWidth += childBounds.width;
        if (cumeWidth <= containerWidth) {
            visibleCount += 1;
        }
    }
    return visibleCount;
}


export function useElidedChildren(childCount) {
    const [visibleItems, setVisibleItems] = React.useState(null);
    const [previousWidth, setPreviousWidth] = React.useState(null);

    // Set up the ref with a callback so that we're updated with any changes to it.
    const ref = React.useRef(null);
    const setRef = React.useCallback(node => {
        if (ref.current || node) {
            // Ref is being changed, so clean up anything related to the previous ref.
            setVisibleItems(null);
        }

        ref.current = node;
    }, [ref]);

    // Trigger a re-measurement on resize.
    useResizeObserver(ref, (e) => {
        if (e.contentRect.width !== previousWidth) {
            setVisibleItems(null);
            setPreviousWidth(e.contentRect.width);
        }
    });

    // Trigger a re-measurement if the number of children changes.
    React.useEffect(() => {
        setVisibleItems(null);
    }, [childCount]);

    React.useEffect(() => {
        // When ref changes or visibleItems is set to null, recalculate the
        // correct number of visibleItems.
        if (ref.current && visibleItems === null) {
            setVisibleItems(_calculateVisibleItems(ref.current));
        }
    }, [ref, visibleItems]);

    return {
        containerRef: setRef,
        visibleItems: visibleItems,
    };
}
