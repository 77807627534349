import React from 'react';
import styled from 'styled-components';
import { elementsOfType } from 'Components/util/propTypesUtils';

import AnchorTocItem from './AnchorTocItem';
import { Body1 } from 'Components/typography/typography';

const Toc = styled(Body1)``;

export default function AnchorToc(props) {
    const { children } = props;

    return (
        <Toc>
            {children}
        </Toc>
    );
}

AnchorToc.propTypes = {
    children: elementsOfType([
        AnchorTocItem,
    ]),
};
