import React from 'react';
import PropTypes from 'prop-types';

import SessionUserEditor from './SessionUserEditor';
import validate from './SessionUserEditorValidation';
import Form from 'Components/form/Form';
import { generateSessionUserPatch } from 'Redux/actions';
import { REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';
import { FormController } from 'Components/form/FormController';

export class SessionUserForm extends React.Component {
    static propTypes = {
        initialValue: PropTypes.object.isRequired,
        requests: PropTypes.shape({
            patchSessionUser: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            patchSessionUserReset: PropTypes.func.isRequired,
            patchSessionUser: PropTypes.func.isRequired,
        }).isRequired,
        enterpriseHierarchy: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.actions.patchSessionUserReset();
    }

    _handleSubmit = async (value, formController) => {
        const {
            initialValue,
            resetValue,
            setSubmitError,
        } = formController;

        const payload = generateSessionUserPatch(initialValue.user, value.user);

        // Send the request
        const responseAction = await this.props.actions.patchSessionUser(payload);

        if (responseAction.type === REQUEST_SUCCESS) {
            resetValue();
        }
        else if (responseAction.type === REQUEST_FAILURE) {
            setSubmitError(responseAction.payload?.error);
        }
    };

    _handleReset = (event) => {
        this.props.actions.patchSessionUserReset();
    };

    render() {
        const {
            initialValue,
            enterpriseHierarchy,
        } = this.props;

        return (
            <FormController
                initialValue={{
                    user: {
                        ...initialValue,
                        current_password: '',
                        new_password: '',
                    },
                }}
                validate={(value) => {
                    return {
                        user: validate(value.user),
                    };
                }}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                render={(innerProps) => {
                    const {
                        value,
                        validation,
                        hasChanges,
                        isSubmitting,
                        submitError,

                        handleChange,
                        handleSubmit,
                        handleReset,
                    } = innerProps;

                    return (
                        <Form
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                            allowSubmit={hasChanges && !isSubmitting && !validation.user}
                            allowCancel={hasChanges && !isSubmitting}
                            error={submitError}
                        >
                            <SessionUserEditor
                                name='user'
                                value={value.user}
                                onChange={handleChange}
                                validation={validation.user}
                                disabled={isSubmitting}
                                enterpriseHierarchy={enterpriseHierarchy}
                            />
                        </Form>
                    );
                }}
            />
        );
    }
}
