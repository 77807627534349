import {
    SET_RESOURCE,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from './actions';
import { requestReducer } from './requestReducer';
import actionMap from 'Common/util/reducerUtils/actionMap';
import { combineReducers } from 'redux';

function createDataReducer(options) {
    const { setResource } = options;

    function handleGetRequest(state, action) {
        const {
            type,
            payload,
        } = action;

        switch (type) {
            case REQUEST_PENDING: {
                return state;
            }
            case REQUEST_SUCCESS: {
                return setResource(payload);
            }
            case REQUEST_FAILURE: {
                return setResource(null);
            }
            default: {
                return state;
            }
        }
    }

    function handlePatchRequest(state, action) {
        const {
            type,
            payload,
        } = action;

        switch (type) {
            case REQUEST_PENDING: {
                return state;
            }
            case REQUEST_SUCCESS: {
                return setResource(payload);
            }
            case REQUEST_FAILURE: {
                return state;
            }
            default: {
                return state;
            }
        }
    }

    function handlePutRequest(state, action) {
        const {
            type,
            payload,
        } = action;

        switch (type) {
            case REQUEST_PENDING: {
                return state;
            }
            case REQUEST_SUCCESS: {
                return setResource(payload);
            }
            case REQUEST_FAILURE: {
                return state;
            }
            default: {
                return state;
            }
        }
    }

    function handleDeleteRequest(state, action) {
        const {
            type,
        } = action;

        switch (type) {
            case REQUEST_PENDING: {
                return state;
            }
            case REQUEST_SUCCESS: {
                return setResource(null);
            }
            case REQUEST_FAILURE: {
                return state;
            }
            default: {
                return state;
            }
        }
    }

    const initialState = setResource(null);

    return function dataReducer(state = initialState, action) {
        const {
            type,
            payload,
            requestMethod,
        } = action;

        if (type === SET_RESOURCE) {
            return setResource(payload);
        }

        switch (requestMethod) {
            case 'GET': {
                return handleGetRequest(state, action);
            }
            case 'PATCH': {
                return handlePatchRequest(state, action);
            }
            case 'DELETE': {
                return handleDeleteRequest(state, action);
            }
            case 'PUT': {
                return handlePutRequest(state, action);
            }
            default: {
                return state;
            }
        }
    };
}

/**
 * A reducer for a resource in a `resourceCollection`
 * 
 * @param {Object} options 
 * @param {string[]} options.requests array of `requestId`s handled by the resource
 * @param {function} [options.setResource] optional function to transform the
 *  resource data before it is saved in the store. Called each time the data
 *  is changed.  
 */

export function resourceReducer(options) {
    const {
        requests: requestIds,
        setResource = (data) => data,
    } = options;

    const dataReducer = createDataReducer({ setResource });

    const requestsReducer = actionMap(
        (action) => action.requestId,
        requestIds.reduce((map, requestId) => {
            map[requestId] = requestReducer;
            return map;
        }, {})
    );

    const combinedReducer = combineReducers({
        data: dataReducer,
        requests: requestsReducer,
    });

    const initialState = combinedReducer(undefined, {});

    return function reducer(state = initialState, action) {
        const {
            requestId,
        } = action;

        // If `requestId` is set on the action, only process requests that were
        // specified in the constructor
        if (requestId && !state.requests[requestId]) {
            return state; // Unknown `requestId`, ignore action
        }

        return combinedReducer(state, action);
    };
}

export function selectResourceData(state) {
    return state.data;
}

export function selectResourceRequest(state, requestId) {
    const request = state.requests[requestId];

    if (!request) {
        throw new Error(`Unknown resource request with ID "${requestId}"` + Object.keys(state.requests));
    }

    return request;
}
