import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropdownContent from 'Components/dropdown/DropdownContent';
import { Body1 } from 'Components/typography/typography';
import Dropdown from 'Components/dropdown/Dropdown';
import ContainedButton from 'Components/button/ContainedButton';
import ButtonLike from 'Components/button/ButtonLike';
import { getSelectableNodeTypeValues, getNodeTypeDisplayText, getNodeTypeIconName, getNodeTypeIconColor } from './HierarchyNodeType';
import { Spacing } from 'Components/common/common';
import VorneIcon from 'Components/vorne-icon/VorneIcon';

const DropdownItem = styled.div`
    padding: 8px;
    display: flex;
    align-items: center;
`;

const StyledIcon = styled(VorneIcon)`
    padding-right: ${Spacing.inlineButtonMargin};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    color: ${props => props.color || 'inherit'}
`;

export default class AddNodeDropdown extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this._itemClickHandlers = {};
    }

    _handleOpenButtonClick = () => {
        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    };

    _handleDropdownBlurDeep = () => {
        this.setState({ isOpen: false });
    };

    _handleItemClick = (nodeType) => {
        const { name, onSelect } = this.props;

        onSelect({
            target: {
                name: name,
                value: nodeType,
            },
        });

        this.setState({ isOpen: false });
    };

    render() {
        const { isOpen } = this.state;

        return (
            <Dropdown open={isOpen} onBlurDeep={this._handleDropdownBlurDeep}>
                <ContainedButton
                    color="primary"
                    onClick={this._handleOpenButtonClick}
                >
                    Add Node
                </ContainedButton>
                <DropdownContent>
                    {getSelectableNodeTypeValues().map((nodeType) => {
                        return (
                            <ButtonLike
                                key={nodeType}
                                Component={DropdownItem}
                                onClick={() => this._handleItemClick(nodeType)}
                            >
                                <StyledIcon
                                    i={getNodeTypeIconName(nodeType)}
                                    color={getNodeTypeIconColor(nodeType)}
                                />
                                <Body1>{getNodeTypeDisplayText(nodeType)}</Body1>
                            </ButtonLike>
                        );
                    })}
                </DropdownContent>
            </Dropdown>
        );
    }
}
