/**
 * Gets the depth of the table data. Currently only the first data
 * row is sampled as all rows are assumed to have the same depth.
 *
 * @param {Array} data The data or sub-data of a table data-set.
 * @param {Number} [depth=0] The depth into the data the caller is at.
 */
export function getMaxDepth(data, depth = 0) {
    if (data.length > 0) {
        return data.reduce((acc, dd) => {
            const subRows = dd.subRows;

            if (subRows && subRows.length > 0) {
                return Math.max(getMaxDepth(subRows, depth + 1), acc);
            }

            return acc;
        }, depth);
    }

    return depth;
}

/**
 * Calculates the full expanded state for react-table given a
 * collapse level.
 *
 * @param {Number} collapseLevel The level at which to collapse to.
 *     Negative values are relative to the max depth as calculated
 *     by getMaxDepth .
 * @param {Array} data Data passed in to Table component
 */
export function calculateExpandedStateAtLevel(collapseLevel, data) {
    // Negative collapse levels should be relative to the max depth
    // of the hierarchy.
    //
    if (collapseLevel < 0) {
        collapseLevel = getMaxDepth(data) + collapseLevel;
    }

    const buildState = (rows, expandedState = {}, levels = []) => {
        if (levels.length >= collapseLevel) {
            return expandedState;
        }

        rows.forEach((row, rowIndex) => {
            const currentLevels = levels.concat(rowIndex);

            expandedState[currentLevels.join('.')] = true;

            if (row.subRows && row.subRows.length > 0) {
                buildState(
                    row.subRows,
                    expandedState,
                    currentLevels
                );
            }
        });

        return expandedState;
    };

    return buildState(data);
}

/**
 * Font weight for the dimension cell based on its depth,
 * the maxDepth, and rootNodeCount.
 * 
 * @param {Number} depth 
 * @param {*} maxDepth 
 * @param {*} rootNodeCount 
 */
export const getCellFontWeight = (depth, maxDepth, rootNodeCount) => {
    // only bold certain rows if there is any depth at all
    if (maxDepth !== 0) {
        // assume that only the first depth will have bold text
        let boldDepth = 0;
        // if there is only a single root node (as in Enterprise) bold the first two depths
        if (rootNodeCount === 1) {
            boldDepth = 1;
        }
        if (depth <= boldDepth && depth !== maxDepth) {
            return '700';
        }
    }
};

/**
 * Helper function for handling the resize column info that comes from table.
 * 
 * @param {Object} savedColumnWidths
 * @param {Object} updatedColumnWidths
 */
export function updateSavedColumnWidths({ savedColumnWidths, updatedColumnWidths }) {
    let newColumnWidths = { ...savedColumnWidths };
    Object.keys(updatedColumnWidths).forEach(id => {
        if (Number.isFinite(updatedColumnWidths[id])) {
            newColumnWidths[id] = updatedColumnWidths[id];
        }
        else {
            delete newColumnWidths[id];
        }
    });
    return newColumnWidths;
}
