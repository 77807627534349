import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonBase from 'Common/components/Button/ButtonBase';
import { elideTextCss } from 'Common/util/cssSnippets';
import { FontFamily, FontWeight } from 'Common/components/typography';

/**
 * MenuItem
 *
 * A MenuItem represents a single selection in a MenuList or MenuDropdown.
 * It is displayed with its configured icon, text, and dropdownicon if supplied.
 * When clicked, the `onClick` function
 * prop will be called (if the item is not disabled).
 *
 */

const Root = styled(ButtonBase)`
    display: inline-block;
    color: ${({theme}) => theme.colors.palette.grey.emperor};
    padding: 4px 8px;
    font-family: ${FontFamily.primary};

    font-weight: ${FontWeight.medium};
    color: ${({ theme }) => theme.colors.palette.grey.manatee};

    &:hover {
        background-color: ${({ theme }) => theme.colors.palette.grey.cloud};
    }

    &:disabled {
        background: transparent;
        color: ${({ theme }) => theme.colors.darkText.disabled};
    }

    /* Give mobile users a nice big touch target */
    @media (hover: none) and (pointer: coarse) {
        min-width: 44px;
        min-height: 44px;
    }
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${({hasText}) => hasText ? 'flex-start' : 'center'};
    align-items: baseline;
    padding-left: ${({level}) => {
        // allow only 4 levels deep of padding
        const limitedLevel = Math.min(level, 4);
        return limitedLevel * 8;
    }}px;

    & > * :not(:last-child) {
        padding-right: 4px;
        // enough space to make sure text next to icons lines up
        min-width: 20px;
    }
`;

const TextContainer = styled.span`
    ${elideTextCss}

    flex: 1 1 auto;
    text-align: left;
`;

export default function MenuItem(props) {
    const {
        onClick,
        onClose,
        disabled,
        text,
        icon,
        dropdownIcon,
        level,
        ...otherProps
    } = props;

    const itemOnClick = useCallback(() => {
        onClick();
        onClose();
    }, [onClick, onClose]);

    return (
        <Root
            disabled={disabled}
            onClick={itemOnClick}
            {...otherProps}
        >
            <Label hasText={!!text} level={level}>
                {icon}
                {text ? (<TextContainer>{text}</TextContainer>) : null}
                {dropdownIcon}
            </Label>
        </Root>
    );
}

MenuItem.propTypes = {
    disabled: PropTypes.bool,
    dropdownIcon: PropTypes.node,
    icon: PropTypes.node,
    level: PropTypes.number,
    text: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

MenuItem.defaultProps = {
    disabled: false,
    dropdownIcon: null,
    icon: null,
    level: 0,
    onClose: () => {},
};

