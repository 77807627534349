import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { elementsOfType } from 'Components/util/propTypesUtils';
import NavItem from './NavItem';

const SubMenu = styled.li`
    margin: 0;
    padding: 0;
    list-style: none;
    width: 170px;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
`;

const MenuHeading = styled.span`
    line-height: 16px;
    text-shadow: none;
    color: #505050;
    font-weight: bold;
    text-transform: uppercase;
`;

const MenuList = styled.ul`
    margin: 0;
    padding: 0;
`;

function NavSubMenu({ heading, children, ...otherProps }) {
    return (
        <SubMenu {...otherProps}>
            <MenuHeading>
                {heading}
            </MenuHeading>
            <MenuList>
                {children}
            </MenuList>
        </SubMenu>
    );
}

NavSubMenu.propTypes = {
    heading: PropTypes.string,
    children: elementsOfType([
        NavItem,
    ]),
};

export default NavSubMenu;
