// Import our styling for .cpe-ellipsis.

// Adapted from: https://stackoverflow.com/a/13382873
const scrollBarWidth = (() => {
    if (!document) {
        return 0;
    }

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth >= 0 ? scrollbarWidth : 0;
})();

/**
 * Gets the width of the scrollbar in the current browser. This function uses
 * a value calculated on initial load, so the scrollbar is not remeasured on
 * every call to this method.
 *
 * @returns the calculated width of the scrollbar in the browser in pixels
 */
export function getScrollBarWidth() {
    return scrollBarWidth;
}
