import React from 'react';
import styled from 'styled-components';
import BaseCheckboxInput from './internal/BaseCheckboxInput';

const CheckboxRoot = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const CheckboxInputWithPointer = styled(BaseCheckboxInput)`
    cursor: pointer;
`;

const PlainCheckboxInput = function(props) {
    return (
        <CheckboxRoot>
            <CheckboxInputWithPointer {...props} />
        </CheckboxRoot>
    );
};

export default PlainCheckboxInput;
