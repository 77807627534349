import { combineReducers } from 'redux';
import session from './session';
import environment from './environment';
import hierarchy from './hierarchy';
import deviceSubscriptions from './deviceSubscriptions';
import users from './users';
import supportUsers from './supportUsers';
import organization from './organization';
import alerts from './alerts';
import alertSubscriptions from './alertSubscriptions';
import alertWindow from './alertWindow';
import version from './version';
import emailTemplates from './emailTemplates';
import emailSubscriptions from './emailSubscriptions';
import metricThresholds from './metricThresholds';
import activeSoftwareUpdates from './activeSoftwareUpdates';
import completedSoftwareUpdates from './completedSoftwareUpdates';
import availableSoftwareUpdates from './availableSoftwareUpdates';

export default combineReducers({
    session,
    environment,
    deviceSubscriptions,
    hierarchy,
    users,
    supportUsers,
    organization,
    alerts,
    alertSubscriptions,
    alertWindow,
    version,
    emailTemplates,
    emailSubscriptions,
    metricThresholds,
    activeSoftwareUpdates,
    completedSoftwareUpdates,
    availableSoftwareUpdates
});
