import {useState, useCallback} from 'react';

/**
 * This hook holds a stack of objects and returns the last item on the stack.
 * It allows the user to pop items off the stack to show the previous item,
 * and to add items to the stack to show a new one. The user can provide
 * updates for the current item on push as well, so the stack has the most
 * up-to-date info.
 *
 */
export default function useStackHistory(initialItem) {

    const [ historyStack, setHistoryStack ] = useState([initialItem]);

    const push = useCallback((newItem, currentItem) => {
        setHistoryStack(currHistoryStack => {
            let newStack = currHistoryStack.concat([]);
            if (currentItem) {
                // remove current item so it can be updated
                newStack.pop();
                // update current item
                newStack.push(currentItem);
            }

            // add new item
            if (newItem) {
                newStack.push(newItem);
            }

            return newStack;
        });
    }, []);

    const clear = useCallback(() => {
        setHistoryStack([initialItem]);
    }, [initialItem]);

    const pop = useCallback(() => {
        setHistoryStack(currHistoryStack => {
            if (currHistoryStack.length > 1) {
                let k = currHistoryStack.slice(0, currHistoryStack.length - 1);
                return k;
            }
            else  {
                return [initialItem];
            }
        });
    }, [initialItem]);

    return { current: historyStack[historyStack.length -1], canPop: historyStack.length > 1, push, pop, clear };
}
