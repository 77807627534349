import { sendPatchRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';

export const PATCH_ORGANIZATION_RESET = 'PATCH_ORGANIZATION_RESET';
export const PATCH_ORGANIZATION_REQUEST = 'PATCH_ORGANIZATION_REQUEST';
export const PATCH_ORGANIZATION_SUCCESS = 'PATCH_ORGANIZATION_SUCCESS';
export const PATCH_ORGANIZATION_FAILURE = 'PATCH_ORGANIZATION_FAILURE';

export function patchOrganizationReset() {
    return {
        type: PATCH_ORGANIZATION_RESET,
    };
}

export const patchOrganization = (changes) => requestActionCreator(
    {
        request: () => ({
            type: PATCH_ORGANIZATION_REQUEST,
        }),
        success: (payload) => ({
            type: PATCH_ORGANIZATION_SUCCESS,
            payload,
        }),
        failure: (error) => ({
            type: PATCH_ORGANIZATION_FAILURE,
            payload: { error },
        }),
    },
    () => sendPatchRequest('/api/v1/accounts/organization', {
        body: { ...changes }
    })
);

export default patchOrganization;
