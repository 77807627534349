export const Roles = {
    ADMIN: 'admin',
    MEMBER: 'member',
};

// Roles listed in order from least-permission to most-permission
const roleOrder = [
    Roles.MEMBER,
    Roles.ADMIN,
];

export function getHighestRole() {
    return roleOrder[roleOrder.length - 1];
}

export function getLowestRole() {
    return roleOrder[0];
}

export function isAtLeastMinimumRole(minRole, role) {
    const minRoleNum = roleOrder.indexOf(minRole);
    const roleNum = roleOrder.indexOf(role);

    if (minRoleNum < 0) {
        throw new Error(`Unknown minRole '${minRole}'`);
    }
    else if (roleNum < 0) {
        throw new Error(`Unknown role '${role}'`);
    }

    return roleNum >= minRoleNum;
}

export function getRoleDisplayText(role) {
    switch (role) {
        case Roles.ADMIN: return 'Admin';
        case Roles.MEMBER: return 'Regular User';
        default: throw new Error(`Unknown role '${role}'`);
    }
}

export function getRoleValues() {
    return [
        Roles.MEMBER,
        Roles.ADMIN,
    ];
}
