
import React, {
    useEffect,
    useState,
} from 'react';

import {
    useHistory,
    Prompt,
} from 'react-router-dom';

import ModalWindow from 'Common/components/ModalWindow/ModalWindow';
import Button from 'Common/components/Button';

/**
 * Hook which can be used to prevent navigation if the page has unsaved changes (dirty)
 *
 * @returns {Component} A component that needs to be rendered which will handle displaying a modal
 *                      window for the user to confirm that they want to navigate away with unsaved
 *                      changes.
 */
export default function useNavigationPrevention(isDirty) {

    const history = useHistory();

    const [state, setState] = useState({
        showModal: false,
        confirmedNavigation: false,
        location: null,
    });

    const handlePreventNavigation = (nextLocation) => {
        if (!state.confirmedNavigation) {
            // set state to show modal, stop navigation, store where they were planning on going
            setState({
                ...state,
                showModal: true,
                location: nextLocation,
            });
            return false;
        }
        return true;
    };

    const handleModelLeave = () => {
        // if they decide to leave, set the confirmedNavigation to true so the
        // handlePreventNavigation function will let us go
        setState({
            ...state,
            showModal: false,
            confirmedNavigation: true,
        });
    };

    const handleModalStay = () => {
        // if they want to stay, hide the modal and reset other state variables
        setState({
            ...state,
            showModal: false,
            confirmedNavigation: false,
            location: null,
        });
    };

    useEffect(() => {
        // when state changes, check if they confirmed navigating away, if so go ahead and do the
        // navigation manually
        if (state.confirmedNavigation && state.location) {
            history.push(state.location.pathname);
            // now that the browser has navigated away reset the confirmedNavigation
            setState({
                ...state,
                confirmedNavigation: false,
            });
        }
    }, [history, state]);

    const getModal = () => {
        if (state.showModal) {
            const title = 'Confirm Navigation';
            const messages = [
                "You have made changes to this view. Save your changes by clicking on 'Save' near the top of the view. Otherwise your changes will be lost.",
                'Are you sure you want to leave this view?',
            ];
            const modalButtons = [
                <Button
                    key="leave"
                    type="primary"
                    onClick={handleModelLeave}
                >
                    {'Leave this View'}
                </Button>,
                <Button
                    key="stay"
                    type="secondary"
                    onClick={handleModalStay}
                >
                    {'Stay on this View'}
                </Button>
            ];

            return (
                <ModalWindow
                    title={title}
                    bodyWidth={550}
                    buttons={modalButtons}
                >
                    {messages.map((message, index) => (<p key={index}>{message}</p>))}
                </ModalWindow>
            );
        }
    };

    const [ cancelled, setCancelled ] = useState(false);

    return {
        component: (
            <React.Fragment>
                <Prompt when={isDirty && ! cancelled} message={handlePreventNavigation} />
                {getModal()}
            </React.Fragment>
        ),
        cancel: () => { setCancelled(true); },
        reset: () => { setCancelled(false); },
    };
}
