/**
 * Given an array of values for a bar or column visualization, calculate the
 * total of all metric values. Any `metric.rawValue` that is not a finite number
 * is considered 0.
 *
 * @param {Object[]} values
 * @returns {Number} Total of all `metric.rawValue`.
 */
export function getTotalMetricValue(values) {
    return values.reduce((total, value) => {
        return total + (Number.isFinite(value.metric.rawValue) ? value.metric.rawValue : 0);
    }, 0);
}

/**
 * Given an array of values for a bar or column visualization, merge any values
 * after `maxElements` into the last value, resulting in an array no longer
 * than `maxElements`. If the initial length does not exceed `maxElements`,
 * nothing will be done.
 *
 * NOTE: Not for use with chronogram visualizations. Chronograms don't have
 * maxElements and they don't have the same data shape.
 *
 * @param {Object[]} values
 * @param {Number} maxElements
 * @returns {Object[]} (Possibly) Merged values.
 */
export function mergeValuesAfterMaxElements(values, maxElements) {
    if (!Number.isInteger(maxElements) || maxElements <= 0) {
        throw new Error(`maxElements must be a positive integer > 0. Got '${maxElements}'`);
    }

    // If needed, merge any values after maxElements into the last element.
    //
    if (values.length > maxElements) {
        const graphableData = values.slice(0, maxElements - 1);
        const otherData = values.slice(maxElements - 1);
        const otherMetricValue = getTotalMetricValue(otherData);
        // Use the first color in `otherData` as the color for the whole group.
        // This is safe because in this context, otherData.length > 0.
        const otherColor = otherData[0].dimension.rawValue.color;
        // Carry over props from `metric`
        const metricProps = otherData[0].metric;

        return [
            ...graphableData,
            // The additional "Other" value...
            {
                dimension: {
                    rawValue: {
                        displayName: null,
                        name: null,
                        color: otherColor,
                    },
                },
                metric: {
                    ...metricProps,
                    rawValue: otherMetricValue
                },
            },
        ];
    }
    else {
        // No merging needed.
        return values;
    }
}
