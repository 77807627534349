import { sendPatchRequest } from 'Http/request';
import { patchResources } from 'Common/util/ResourceCollection';

export function patchAlertWindow(data) {
    return patchResources({
        resourceType: 'alertWindow',
        requestId: 'patchAlertWindow',
        requestData: data,
        sendRequest: async () => {
            return sendPatchRequest('/api/v1/accounts/user/alert-window', {
                body: { ...data },
            });
        },
    });
}
