
import React, {
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import EmailsSubscriptionTable from './EmailsSubscriptionTable';
import Form from 'Components/form/Form';
import * as RequestStatus from 'Common/data/RequestStatus';

function getUniqueSubscriptionId(sub) {
    return sub.data.hierarchy_node_id + '-' + sub.data.report_template_id + '-' + sub.data.reporting_period;
}

function getSetOfSubscriptionIds(modifiedSubscriptions) {
    return new Set(modifiedSubscriptions.map((sub) => {
        return getUniqueSubscriptionId(sub);
    }));
}

export default function EmailsSubscriptionForm(props) {
    const {
        emailSubscriptions,
        emailTemplates,
        flattenedHierarchy,
        onSubmit,
        requests,
    } = props;

    const [ modifiedSubscriptions, setModifiedSubscriptions ] = useState(emailSubscriptions);
    const [ isDirty, setIsDirty ] = useState(false);
    const [ fullRow, setFullRow ] = useState(true);

    const onChange = (subscriptionId, data) => {
        const currentSubscriptions = getSetOfSubscriptionIds(modifiedSubscriptions);

        const originalSubscription = modifiedSubscriptions.find((sub) => {
            return sub.data.id === data.id;
        });

        const newBackendSubscriptions = modifiedSubscriptions.map((sub) => {
            if (sub.data.hierarchy_node_id === originalSubscription.data.hierarchy_node_id &&
                sub.data.report_template_id === originalSubscription.data.report_template_id) {
                const rv = {
                    data: {
                        ...sub.data,
                        report_template_id: data.report_template_id,
                        hierarchy_node_id: data.hierarchy_node_id,
                    }
                };
                const uniqueId = getUniqueSubscriptionId(rv);
                if (currentSubscriptions.has(uniqueId)) {
                    return null;
                }
                return rv;
            }

            return sub;
        }).filter((sub) => {
            return !!sub;
        });

        setIsDirty(true);
        setModifiedSubscriptions(newBackendSubscriptions);
    };

    const onAddRemove  = (createdSub, deletedSubs) => {
        let newSubs = [
            ...modifiedSubscriptions,
        ];

        // track unique subscriptions based on hierarchy node, report template id, and reporting period
        const currentSubscriptions = getSetOfSubscriptionIds(modifiedSubscriptions);

        // the new subscription sent here will contain an array of reporting periods. Each element
        // in that array should correspond to a unique subscription as far as the back-end is
        // concerned
        const newBackendSubscriptions = createdSub.reporting_period.map((rp, index) => {
            return {
                data: {
                    ...createdSub,
                    // add an index because this loop is tight enough that the now value doesn't always change
                    id: Date.now() + index,
                    reporting_period: rp,
                },
            };
        }).filter((sub) => {
            // do not bother to add subscriptions that match something that is already added (possibly
            // in another row of the table)
            const uniqueId = getUniqueSubscriptionId(sub);
            return ! currentSubscriptions.has(uniqueId);
        });

        // it's possible that all new subscriptions were filtered out, in that case there is nothing to do here
        if (newBackendSubscriptions.length > 0) {
            // concatenate the current set of modified subscriptions (they may not actually be modified though)
            // with any new subscriptions that may have been created, and set that as our new modifiedSubscription state
            newSubs = [
                ...newSubs,
                ...newBackendSubscriptions,
            ];
        }

        // list containing only the sub ids to be deleted
        const deletedSubIds = deletedSubs.map((ss) => ss.subscription_id);
        newSubs = newSubs.filter((sub) => {
            return ! deletedSubIds.includes(sub.data.id);
        });

        setIsDirty(true);
        setModifiedSubscriptions(newSubs);
    };

    const onDeleteRow = (deletedSub) => {
        // filter out all subscriptions that match the hierarchy_node_id and report_template_id for the sent deletedSub
        const newSubs = modifiedSubscriptions.filter((sub) => {
            return ! (deletedSub.data.hierarchy_node_id === sub.data.hierarchy_node_id && deletedSub.data.report_template_id === sub.data.report_template_id);
        });
        setIsDirty(true);
        setModifiedSubscriptions(newSubs);
    };

    const onPartialRowStatusUpdate = (partialRowStatus) => {
        setFullRow(! partialRowStatus);
    };

    // track status of the patch request to know when we've gone from a pending to success and we
    // can clear the dirty flag
    const [ lastPatchStatus, setLastPatchStatus ] = useState(requests?.patchEmailSubscriptions?.status);

    useEffect(() => {
        const nextPatchStatus = requests?.patchEmailSubscriptions?.status;

        if (lastPatchStatus === RequestStatus.PENDING &&
            nextPatchStatus === RequestStatus.SUCCESS
        ) {
            setIsDirty(false);
        }

        if (lastPatchStatus !== nextPatchStatus) {
            setLastPatchStatus(nextPatchStatus);
        }
    }, [lastPatchStatus, requests?.patchEmailSubscriptions?.status]);

    const isLoading = requests.patchEmailSubscriptions.status === RequestStatus.PENDING;

    const allowSubmit = !isLoading && isDirty && fullRow;
    const allowCancel = !isLoading && isDirty && fullRow;

    const handleReset = () => {
        setModifiedSubscriptions(emailSubscriptions);
        setIsDirty(false);
    };

    const handleSubmit = (a) => {
        onSubmit(modifiedSubscriptions);
    };

    return (
        <React.Fragment>
            <Form
                error={requests?.patchEmailSubscriptions?.error}
                onSubmit={handleSubmit}
                onReset={handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <EmailsSubscriptionTable
                    emailSubscriptions={modifiedSubscriptions}
                    emailTemplates={emailTemplates}
                    flattenedHierarchy={flattenedHierarchy}
                    onAddRemove={onAddRemove}
                    onChange={onChange}
                    onDeleteRow={onDeleteRow}
                    partialRowStatus={onPartialRowStatusUpdate}
                />
            </Form>
        </React.Fragment>
    );
}

EmailsSubscriptionForm.propTypes = {
    emailSubscriptions: PropTypes.array.isRequired,
    emailTemplates: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    flattenedHierarchy: PropTypes.object,
    requests: PropTypes.object.isRequired,
};
