
export function sortSupportUsers(accessor, direction) {
    return (dispatch, b) => {
        dispatch({
            type: 'sort-support-table',
            resourceType: 'supportUsers',
            payload: {
                accessor,
                direction,
            }
        });
    };
}

export default sortSupportUsers;
