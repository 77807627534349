import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import FieldSelectControl from 'Common/components/LiveControls/FieldSelectControl';
import CategorySelectList from 'Common/components/CategorySelectList';
import FieldBuilderTypeSelect, { flattenEntries } from 'Common/components/FieldBuilderTypeSelect/FieldBuilderTypeSelect';

import memoizeOne from 'memoize-one';
import { useTranslator } from 'Common/hooks/useTranslation';
import omit from 'lodash/omit';

const generatePrimaryMenuOptions = memoizeOne((
    metricFields,
    categoryFields,
    disableEventsToInclude,
    translator
) => {
    let primaryMenuOptions = [];

    if (metricFields.length > 0) {
        primaryMenuOptions.push({
            name: 'metric',
            displayName: translator('metric_literal'),
            hasChildren: true,
        });
    }

    if (!disableEventsToInclude && (categoryFields.length > 0)) {
        primaryMenuOptions.push({
            name: 'dimension',
            displayName: translator('category_literal'),
            hasChildren: true,
        });
    }

    return primaryMenuOptions;
});

/**
 * This is a Select component that allows picking fields from all the various types.
 *
 */
function FieldBuilderSelect({
    categoryFields,
    disableEventsToInclude,
    metricFields,
    name,
    onChange,
    value,
}) {
    const translator = useTranslator();
    const primaryMenuOptions = generatePrimaryMenuOptions(
        metricFields,
        categoryFields,
        disableEventsToInclude,
        translator
    );

    // if there is only one option default state to that option.
    const [selectedMenu, setSelectedMenu] = useState(primaryMenuOptions.length === 1 ? primaryMenuOptions[0].name : '');

    const handleMainMenuChange = useCallback((event) => {
        const { target } = event;
        if (target.type === 'main') {
            setSelectedMenu(target.value.name);
        }
        else {
            // displayType was added so lets remove it
            onChange({
                target: {
                    value: omit(target.value, 'displayType')
                }
            });
        }
    }, [onChange]);

    const valueIsDimension = value && categoryFields.find((dimensionField) => (dimensionField.name === value.name));

    if (selectedMenu === 'metric') {
        return (
            <FieldSelectControl
                name={name}
                collapsibleGroups
                showFlattenCheckbox
                options={metricFields}
                onChange={onChange}
                value={value && !valueIsDimension ? value : null}
            />
        );
    }
    else if (selectedMenu === 'dimension') {
        return (
            <CategorySelectList
                name={name}
                options={categoryFields}
                onChange={onChange}
                value={value && valueIsDimension ? value : null}
            />
        );
    }
    else {
        const flattenedMetricFields = flattenEntries(metricFields);
        const flattenedCategoryFields = flattenEntries(categoryFields);
        const primaryMenuSearchOptions = flattenedCategoryFields
            .concat(flattenedMetricFields)
            .map((option) => {
                let displayType = '';
                if (option.fieldType === 'metric') {
                    displayType = translator('metric_literal');
                }
                else if (option.fieldType === 'category_value') {
                    displayType = translator('category_literal');
                }

                return { ...option, displayType };
            });

        // No secondary menu is selected, so render the primary menu.
        return (
            <FieldBuilderTypeSelect
                name={name}
                onChange={handleMainMenuChange}
                options={primaryMenuOptions}
                searchOptions={primaryMenuSearchOptions}
                instruction={translator('control.table.column_primary_instruction')}
            />
        );
    }
}

FieldBuilderSelect.propTypes = {
    categoryFields: PropTypes.arrayOf(PropTypes.object.isRequired),
    disableEventsToInclude: PropTypes.bool,
    metricFields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            isDummyGroup: PropTypes.bool.isRequired,
            entries: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    displayName: PropTypes.string.isRequired,
                }).isRequired
            ).isRequired,
        }).isRequired
    ).isRequired,
    value: PropTypes.shape({
        name: PropTypes.string.isRequired,
        channel: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
};

FieldBuilderSelect.defaultProps = {
    categoryFields: [],
    disableEventsToInclude: false,
    metricFields: [],
    name: '',
};

export default FieldBuilderSelect;
