import validatePassword from 'Components/password/PasswordRulesValidation';
import { combineValidators, mergeObjectValidators } from 'Components/util/validationUtils';

const emailAddressValidator = combineValidators({
    email: (email) => email.trim() === '' ? 'Required' : null,
});

export const pendingEmailAddressValidator = (editorData) => {
    if (editorData.pending_email_address === null) {
        return null;
    }

    const email_address = {
        ...editorData.email_address,
        email: editorData.email_address.email.trim().toLowerCase(),
    };

    const pending_email_address = {
        ...editorData.pending_email_address,
        email: editorData.pending_email_address.email.trim().toLowerCase(),
    };

    const validation = {
        pending_email_address: emailAddressValidator(pending_email_address),
    };

    if (!validation.pending_email_address) {
        if (pending_email_address.email === email_address.email) {
            validation.pending_email_address = {
                email: 'New email must be different from your current',
            };
        }
    }

    return validation;
};

export const smsEmailAddressValidator = (editorData) => {
    if (editorData.sms_email_address === null) {
        return null;
    }

    const emailValidation = emailAddressValidator(editorData.sms_email_address);

    if (emailValidation !== null) {
        return {
            sms_email_address: emailValidation,
        };
    }
    else {
        return null;
    }
};

const baseValidator = combineValidators({
    name: (name) => name.trim() === '' ? 'Required' : null,
    role: (role) => role ? null : 'Required',
    timezone: (timezone) => timezone ? null : 'Required',
});

const passwordsValidator = (editorData) => {
    if (editorData?.current_password !== '' && editorData?.new_password !== '') {
        return {
            new_password: validatePassword(editorData?.new_password),
        };
    }
    else {
        return null;
    }
};

const validate = mergeObjectValidators([
    baseValidator,
    passwordsValidator,
]);

export default validate;
