import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import FaIcon from 'Components/icon/FaIcon';
import { Link } from 'react-router-dom';
import { Body1 } from 'Components/typography/typography';

const Root = styled.div``;

const MenuLabel = styled.div``;
const MenuLabelIcon = styled.div``;
const MenuLabelText = styled.div``;

const CollapseIcon = styled(({ open, ...otherProps }) => {
    return (
        <FaIcon i={open ? 'chevron-up' : 'chevron-down'} {...otherProps} />
    );
})``;

const ChildListItem = styled.li``;

const ChildList = styled.ul`
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;

    ${ChildListItem} {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    border: 1px solid rgb(213, 213, 213);
    border-width: 1px 0px;

    &:not(.open) {
        display: none;
    }
`;

const indentLevelPadding = [ 16, 48, 66, 82 ];

const MenuLabelWrapper = styled(Body1)`
    display: block;

    ${MenuLabel} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        padding-left: ${props => indentLevelPadding[props.indentLevel]}px;
        padding-right: 16px;

        font-size: 12px;
        line-height: 16px;
        text-shadow: none;
        color: #505050;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;

        background-color: transparent;
        transition: background-color 0.3s;
        cursor: pointer;

        &.open, &:hover {
            background-color: #eeeeee;
        }

        &.link {
            line-height: 32px;
            color: #505050;
            font-size: 12px;
            font-weight: normal;
            text-decoration: none;
            text-transform: none;

            :hover {
                text-decoration: none;
            }
        }

        ${MenuLabelIcon} {
            display: block;
            font-size: 18px;
            padding-right: 1em;
        }

        ${MenuLabelText} {
            flex: 1 1 auto;
        }

        ${CollapseIcon} {
            display: block;
        }
    }
`;

export default class MobileNavMenuNode extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        icon: PropTypes.node,
        label: PropTypes.node.isRequired,
        link: PropTypes.string,
        indentLevel: PropTypes.number,
        children: PropTypes.any,
    };

    static defaultProps = {
        icon: null,
        label: null,
        link: null,
        indentLevel: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    _handleClick = () => {
        if (React.Children.count(this.props.children) === 0) {
            return;
        }

        this.setState(({ open }) => ({ open: !open }));
    };

    render() {
        const { className, indentLevel, icon, label, link, children } = this.props;
        const { open } = this.state;

        const isLink = !!link;

        const hasChildren = React.Children.count(children) > 0;

        return (
            <Root className={classNames(className, { open })}>
                <MenuLabelWrapper indentLevel={indentLevel}>
                    <MenuLabel
                        as={isLink ? Link : 'div'}
                        to={link}
                        className={classNames({ open, link: isLink })}
                        onClick={this._handleClick}
                    >
                        {icon && (
                            <MenuLabelIcon>{icon}</MenuLabelIcon>
                        )}
                        <MenuLabelText>
                            {label}
                        </MenuLabelText>
                        {hasChildren && (
                            <CollapseIcon open={open} />
                        )}
                    </MenuLabel>
                </MenuLabelWrapper>
                <ChildList className={classNames({ open })}>
                    {React.Children.map(children, (child, i) => {
                        return (
                            <ChildListItem key={`child-${i}`}>
                                {child}
                            </ChildListItem>
                        );
                    })}
                </ChildList>
            </Root>
        );
    }
}
