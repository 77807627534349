import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const store = createStore(
    rootReducer,
    // initial store state
    {},
    applyMiddleware(
        thunk
    )
);

export default store;
