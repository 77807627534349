import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Tooltip from 'Common/components/Tooltip';
import TooltipContent from './internal/TooltipContent';
import { TOOLTIP_LARGE } from 'Common/components/Tooltip/Tooltip';
import { useTranslator } from 'Common/hooks/useTranslation';
import addDimensionColors from './internal/addDimensionColors';
import { getTotalMetricValue, mergeValuesAfterMaxElements } from './internal/barAndColumnUtils';
import { BAR_FORMAT_TYPE } from 'Common/components/SparkVisualization/SparkDimensionConstants';
import { categoryValueFormatTypes } from '../FormattedDatum/CategoryValue/CategoryValue';

const PADDING = 4;
const MAX_HEIGHT = 30;

const Root = styled.div.attrs(({ $width, $height }) => {
    return {
        style: {
            width: `${$width}px`,
            height: `${$height}px`,
        },
    };
})`
    padding: ${PADDING}px;
    padding-right: ${({$showBars}) => $showBars ? `${PADDING}px` : '0'};
    justify-content: ${({$showBars}) => $showBars ? 'center' : 'end'};
    display: flex;
    align-items: center;
`;

const SparkBar = styled.div.attrs(({ $width, $height, $backgroundColor }) => {
    return {
        style: {
            width: `${$width}px`,
            height: `${$height}px`,
            backgroundColor: $backgroundColor,
        },
    };
})``;

const SparkBarContainer = styled.div`
    border-radius: 5px;
    overflow: hidden;
    display: flex;
`;

function calcWidth(curr, total, totWidth) {
    if (total === 0 || !curr) {
        return 0;
    }

    return (curr / total) * totWidth;
}

function BarSparkVisualization(props) {
    const { width, height, data, maxElements, noColor } = props;
    const widthSansPadding = width - (2*PADDING);
    const heightSansPadding = height - (2*PADDING);
    const theme = useTheme();
    const translator = useTranslator();

    const coloredData = useMemo(() => {
        if (!data) {
            return data;
        }

        return {
            ...data,
            values: addDimensionColors({
                values: data.values,
                otherColor: theme.colors.palette.grey.ash,
                maxElements,
            }),
        };
    }, [ data, maxElements, theme ]);

    const mergedValues = useMemo(() => {
        return mergeValuesAfterMaxElements(coloredData?.values || [], maxElements);
    }, [ coloredData, maxElements ]);

    const barDetails = useMemo(() => {
        const total = getTotalMetricValue(mergedValues);
        let allValuesAreZero = !!mergedValues.length;
        let allValuesAreNull = true;
        const barValues = mergedValues.map((elem, index) => {
            let backgroundColor = elem.dimension?.rawValue.color;
            if (noColor) {
                backgroundColor = index % 2 === 0
                    ? theme.colors.palette.grey.silver
                    : theme.colors.palette.grey.ash;
            }

            const rawValue = elem.metric.rawValue;

            if (Number.isFinite(rawValue)) {
                allValuesAreNull = false;
                if (rawValue !== 0) {
                    allValuesAreZero = false;
                }
            }

            const barWidth = calcWidth(rawValue, total, widthSansPadding);
            const barHeight = Math.min(heightSansPadding, MAX_HEIGHT);

            return (
                <SparkBar
                    key={index}
                    $height={barHeight}
                    $backgroundColor={backgroundColor}
                    $width={barWidth}
                />
            );
        });
        return {
            bars: barValues,
            allValuesAreNull,
            allValuesAreZero
        };
    }, [heightSansPadding, widthSansPadding, mergedValues, theme, noColor]);

    return (
        <Root $width={widthSansPadding} $height={heightSansPadding} $showBars={!barDetails.allValuesAreNull && !barDetails.allValuesAreZero}>
            <Tooltip
                delay={500}
                placement="auto"
                scrollContentOnOverflow
                stickyOnClick
                content={<TooltipContent data={coloredData} />}
                style={{ display: 'inline-block'}}
                maxWidth={TOOLTIP_LARGE}
                disabled={barDetails.allValuesAreNull}
            >
            {coloredData?.error || barDetails.allValuesAreNull
                ? (
                    <div>
                        {translator("null_metric_literal")}
                    </div>
                )
                : barDetails.allValuesAreZero
                    ? (
                        <div>
                            {translator("all_zero_values")}
                        </div>
                    )
                    : (
                        <SparkBarContainer>
                            {barDetails.bars}
                        </SparkBarContainer>
                    )}
            </Tooltip>
        </Root>
    );
}

BarSparkVisualization.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        formatType: PropTypes.oneOf([BAR_FORMAT_TYPE]).isRequired,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                dimension: PropTypes.shape({
                    displayName: PropTypes.string,
                    name: PropTypes.string,
                    formatType: PropTypes.oneOf(categoryValueFormatTypes).isRequired,
                    rawValue: PropTypes.shape({
                        displayName: PropTypes.string,
                        name: PropTypes.string,
                        color: PropTypes.string
                    })
                }).isRequired,
                metric: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    formatType: PropTypes.string.isRequired,
                    rawValue: PropTypes.number
                }).isRequired,
            }),
        ).isRequired,
    }),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    maxElements: PropTypes.number.isRequired,
    noColor: PropTypes.bool,
};

export default BarSparkVisualization;
