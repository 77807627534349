import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormElement from 'Components/form-element/FormElement';
import ThemedCheckboxInput from 'Components/checkbox/ThemedCheckboxInput';
import FormElementLabel from 'Components/form-element/FormElementLabel';
import uniqueId from 'Common/util/uniqueId';
import FormElementHelperText from 'Components/form-element/FormElementHelperText';

const StyledFormElement = styled(FormElement)`
    display: inline-block;
`;

const ContentWrapper = styled.div`
    display: inline-flex;
    align-items: center;
`;

const RightLabel = styled(FormElementLabel)`
    display: inline-block;
    padding-left: 8px;
`;

export default class CheckboxFormElement extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.node.isRequired,
        helperText: PropTypes.string,
        value: PropTypes.bool.isRequired,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        // ... other props passed to `FormElement`
    };

    constructor(props) {
        super(props);
        this._uniqueId = uniqueId('CheckboxElement-input-');
    }

    render() {
        const {
            name,
            label,
            helperText,
            value,
            onFocus,
            onBlur,
            onChange,
            ...otherProps
        } = this.props;

        const inputId = this._uniqueId;

        return (
            <StyledFormElement {...otherProps}>
                <ContentWrapper>
                    <ThemedCheckboxInput
                        id={inputId}
                        name={name}
                        value={value}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                    <RightLabel htmlFor={inputId}>{label}</RightLabel>
                </ContentWrapper>
                {helperText !== undefined && (
                    <FormElementHelperText>{helperText}</FormElementHelperText>
                )}
            </StyledFormElement>
        );
    }
}
