import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LiveControlSeparator from 'Common/components/LiveControlBar/LiveControlSeparator';
import BasicSelectField from 'Common/components/BasicSelectField/BasicSelectField';
import { useDisplayMode, DisplayMode } from 'Common/util/DisplayMode';
import SectionToolbarButton from './SectionToolbarButton';

const Root = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
`;

const ShadowBorderWrapper = styled.div`
    display: inline-block;
    white-space: nowrap;
    border: 1px solid ${({ theme }) => theme.colors.palette.grey.silver};
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
`;

const ChildRow = styled.div`
    display: block;
`;

const SelectFieldWrapper = styled.div`
    padding: 8px;
`;

export default  function SectionToolbarBase(props) {
    const {
        children: childrenProp,
        onSectionTypeChange,
        sectionTypeOptions,
        sectionTypeValue,
    } = props;

    const displayMode = useDisplayMode();

    let childRows = [];

    const children = React.Children.toArray(childrenProp);

    const handleSectionTypeDropdownChange = useCallback((event) => {
        onSectionTypeChange({
            target: {
                value: event.target.value.value,
            },
        });
    }, [ onSectionTypeChange ]);

    if (displayMode !== DisplayMode.MOBILE) {
        childRows.push([
            ...children,
            <LiveControlSeparator key="separator" />,
            ...sectionTypeOptions.map((opt) => {
                return (
                    <SectionToolbarButton
                        key={opt.value}
                        displayName={opt.displayName}
                        icon={opt.icon}
                        selected={opt.value === sectionTypeValue}
                        onClick={() => {
                            onSectionTypeChange({
                                target: {
                                    value: opt.value,
                                },
                            });
                        }}
                    />
                );
            }),
        ]);
    }
    else {
        childRows.push(children);

        let selectedOption = null;

        // Object sectionTypeOptions can be empty which means there are no section options for the
        // user to choose from. This happens in enterprise email report templates.
        if (sectionTypeOptions.length > 0) {
            const options = sectionTypeOptions.map((opt) => {
                const rv = {
                    value: opt.value,
                    displayName: opt.displayName,
                    icon: opt.icon,
                };

                if (opt.value === sectionTypeValue) {
                    selectedOption = opt;
                }

                return rv;
            });

            childRows.push(
                <SelectFieldWrapper>
                    <BasicSelectField
                        selectedValue={selectedOption.value}
                        selectedDisplayName={selectedOption.displayName}
                        options={options}
                        onChange={handleSectionTypeDropdownChange}
                    />
                </SelectFieldWrapper>
            );
        }
    }

    return (
        <Root>
            <ShadowBorderWrapper>
                {childRows.map((rowChildren, i) => {
                    return (
                        <ChildRow key={i}>
                            {rowChildren}
                        </ChildRow>
                    );
                })}
            </ShadowBorderWrapper>
        </Root>
    );
}

SectionToolbarBase.propTypes = {
    children: PropTypes.node,
    onSectionTypeChange: PropTypes.func.isRequired,
    sectionTypeOptions: PropTypes.arrayOf(
        PropTypes.exact({
            displayName: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            icon: PropTypes.node.isRequired,
        })
    ).isRequired,
    sectionTypeValue: PropTypes.string.isRequired,
};

SectionToolbarBase.defaultProps = {};
