import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BasicSelectList from 'Common/components/SelectList/BasicSelectList';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import SelectDropdownButton from 'Common/components/SelectList/SelectDropdownButton';
import { anyOf } from 'Common/util/propTypeHelpers';

/**
 * Basic select field which wraps a SelectList in a dropdown with internally
 * managed state.
 */
const BasicSelectField = ({
    name,
    selectedValue,
    selectedDisplayName,
    options,
    onChange,
    disabled,
    buttonProps,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const openHandler = () => {
        setIsOpen(true);
    };

    const closeHandler = () => {
        setIsOpen(false);
    };

    const onChangeHandler = (event) => {
        closeHandler();
        onChange(
            {
                target: {
                    name: name,
                    value: options.find((item) => event.target.value === item.value) || null,
                }
            }
        );
    };

    const renderContent = () => {
        return (
            <BasicSelectList
                name={name}
                options={options}
                value={selectedValue}
                onChange={onChangeHandler}
            />
        );
    };

    return (
        <Dropdown
            isOpen={isOpen}
            onMaskClick={closeHandler}
            renderContent={renderContent}>
            <SelectDropdownButton
                style={{width: '100%'}}
                disabled={disabled}
                isPlaceholderText={!selectedValue}
                onClick={openHandler}
                {...buttonProps}>
                {selectedDisplayName}
            </SelectDropdownButton>
        </Dropdown>
    );
};

BasicSelectField.propTypes = {
    name: PropTypes.string,
    selectedValue: PropTypes.string,
    selectedDisplayName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape(
        anyOf([ {
            value: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            icon: PropTypes.node,
        }, {
            value: PropTypes.string.isRequired,
            displayKey: PropTypes.string.isRequired,
            icon: PropTypes.node,
        } ])
    )),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    buttonProps: PropTypes.object,
};

BasicSelectField.defaultProps = {
    disabled: false,
    buttonProps: {},
};

export default BasicSelectField;
