import { useContext, useMemo, useRef } from 'react';
import WidgetCacheContext from './internal/InternalWidgetCacheContext';

/**
 * A React hook to get a cache for a widget. Requires `WidgetCacheProvider` to
 * be present higher up in the React tree.
 *
 * @param {object} options
 * @param {string} options.cacheId A name for the cache.
 * @param {function} options.load An async function to load the value.
 *      `load` is called with arguments ({ abortSignal: AbortSignal }, ...args: any[])
 *      where `args` are forwarded from the call to `cache.get(...)`
 * @returns {object} A widget cache object with the following methods:
 *      get( cacheOptions: object, ...args: any[] ).
 *          @see AsyncValueCache for valid cacheOptions
 */
export default function useWidgetCache({
    cacheId,
    load,
}) {
    const loadRef = useRef(null);
    loadRef.current = load;

    const widgetCache = useContext(WidgetCacheContext);

    if (widgetCache === null) {
        throw new Error('useWidgetCache requires WidgetCacheProvider to be in the React tree.');
    }

    const widgetValueCache = useMemo(() => {
        return widgetCache.getValueCache(cacheId);
    }, [ widgetCache, cacheId ]);

    return useMemo(() => {
        return {
            get: (cacheOptions, ...args) => {
                return widgetValueCache.get(
                    {
                        ...(cacheOptions || {}),
                        load: loadRef.current,
                    },
                    ...args,
                );
            },
        };
    }, [ widgetValueCache ]);
}
