import { parseTime } from 'Components/util/timeUtils';

export default function validate(value) {
    let error;

    if (!parseTime(String(value)).isValid()) {
        error = 'Must provide a valid time entry';
    }

    return error;
}
