import ParamTypes, { paramTest } from 'Common/util/ParamTypes/ParamTypes';

const AVG_STRING_CHAR_WIDTH = 6.5;
const AVG_NUMBER_CHAR_WIDTH = 6.85;

/**
* Given a string, measure its approximate length. This does not consider any desired padding
* or spacing within a table cell, that is left as a final step of measureColumn.
*
* @param {String} dataString
* @param {Object} [opts]
* @param {Boolean} [opts.numeric = false] If true measure as a numeric string. Numeric
*     strings use a different constant as the average character width is wider.
*
* @returns {Number} Approximate width of the string in pixels.
*/
export function measureString(dataString, { numeric = false } = {}) {
   if (!paramTest(dataString, ParamTypes.string, 'measureString.dataString', { throwError: false })) {
       console.error(
           `column.width set to "auto" and measureCell's measureFunction has been called with unmeasurable type ${typeof dataString}`
       );
   }

   const avgCharWidth = numeric ? AVG_NUMBER_CHAR_WIDTH : AVG_STRING_CHAR_WIDTH;

   return ((dataString?.length || 0) * avgCharWidth);
}

export function isHtmlElement(obj) {
    return (
        typeof HTMLElement === 'object' ? obj instanceof HTMLElement : //DOM2
        obj && typeof obj === 'object' && obj !== null && obj.nodeType === 1 && typeof obj.nodeName === 'string'
    );
}

export function getComputedStyle(element) {
    return window.getComputedStyle(element);
}

function isScrollStyle(styleProp) {
    return /auto|scroll|overlay|hidden/.test(styleProp);
}

export function isScrollElement(element) {
    const computedStyle = getComputedStyle(element);

    return (
        isScrollStyle(computedStyle.overflow) ||
        isScrollStyle(computedStyle.overflowX) ||
        isScrollStyle(computedStyle.overflowY)
    );
}

/**
 * Find the closest parent to a node that is scrollable. This does not
 * necessarily mean the parent has a scrollbar, but it has style properties that
 * will allow it to scroll on overflow.
 * 
 * @param {HTMLElement} node
 * @returns {HTMLElement} The scrollable parent
 */

export function getScrollParent(node) {
    // Check if we are at the top-level <body> node. If so, the window is always
    // the scroll parent.
    if (node === document.body) {
        return window;
    }

    let parentNode;

    // If the element is part of a portal, then follow the portal back to its
    // original source.
    if (node.hasAttribute?.('data-portal-source-id')) {
        parentNode = document.getElementById(node.getAttribute('data-portal-source-id'))?.parentNode;
    }
    else {
        parentNode = node.parentNode;
    }

    if (!parentNode) {
        return null;
    }

    if (isScrollElement(parentNode)) {
        return parentNode;
    }
    else {
        // Try again with the parent node
        return getScrollParent(parentNode);
    }
}

export function getAllScrollParents(node, scrollParentArr = []) {
    const scrollParent = getScrollParent(node);

    if (!scrollParent) {
        return scrollParentArr;
    }
    else {
        return scrollParentArr.concat(
            [ scrollParent ],
            getAllScrollParents(scrollParent)
        );
    }
}

export function getTextWidth({fontName, fontSize, fontWeight}, text) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontWeight} ${fontSize} ${fontName}`;
    return context.measureText(text).width;
}
