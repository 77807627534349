import { combineReducers } from 'redux';
import actionFilter from 'Common/util/reducerUtils/actionFilter';
import actionMap from 'Common/util/reducerUtils/actionMap';

import { requestReducer } from 'Common/util/ResourceCollection';
import { REQUEST_FAILURE, REQUEST_PENDING, REQUEST_SUCCESS } from 'Common/util/ResourceCollection/actions';

const initialListState = [];

const listReducer = (state = initialListState, action) => {
    const {
        type,
        requestMethod,
        payload,
    } = action;

    if (requestMethod === 'GET') {
        switch (type) {
            case REQUEST_PENDING:
                return initialListState;
            case REQUEST_FAILURE:
                return initialListState;
            case REQUEST_SUCCESS: {
                return [ ...payload.subscribed ];
            }
            default:
                return state;
        }
    }
    else if (requestMethod === 'PATCH') {
        switch (type) {
            case REQUEST_SUCCESS: {
                return [ ...payload.subscribed ];
            }
            default:
                return state;
        }
    }
    else {
        return state;
    }

};

const deviceSubscriptionsReducer = actionFilter(
    (action) => action?.resourceType === 'deviceSubscriptions',
    combineReducers({
        list: listReducer,
        requests: actionMap(
            (action) => action?.requestId,
            {
                getDeviceSubscriptions: requestReducer,
                patchDeviceSubscriptions: requestReducer,
            }
        ),
    })
);

export default deviceSubscriptionsReducer;

export function selectDeviceSubscriptions(state) {
    return state.list;
}

export function selectDeviceSubscriptionsRequest(state, requestId) {
    const request = state.requests[requestId];

    if (!request) {
        throw new Error(`Unknown device subscriptions request with ID "${requestId}"`);
    }

    return request;
}
