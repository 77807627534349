import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { detectOverflow } from '@popperjs/core';
import Portal from 'Common/components/Portal/Portal';
import Mask, { MASK_Z_INDEX } from '../Portal/Mask';
import useDelayedHover from 'Common/hooks/useDelayedHover';

const TOOLTIP_VERTICAL_PADDING = 8;
const TOOLTIP_HORIZONTAL_PADDING = 10;

const TOOLTIP_MIN = 10;
const TOOLTIP_SMALL = 100;
const TOOLTIP_MEDIUM = 200;
const TOOLTIP_LARGE = 400;
const TOOLTIP_EXTRA_LARGE = 600;

export {
    TOOLTIP_SMALL,
    TOOLTIP_MEDIUM,
    TOOLTIP_LARGE,
    TOOLTIP_EXTRA_LARGE,
};

const borderCss = css`1px solid rgba(0, 0, 0, 0.2)`;

const TooltipArrow = styled.div`
    visibility: hidden;

    &, &::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
    }

    &::before {
        visibility: visible;
        content: ' ';
        border-top: ${borderCss};
        border-left: ${borderCss};

        background: ${({theme}) => theme.colors.palette.white};
    }
`;

const TooltipContainer = styled.div`
    background: ${({theme}) => theme.colors.palette.white};
    color: ${({theme}) => theme.colors.palette.grey.emperor};

    border: ${borderCss};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

    padding: ${TOOLTIP_VERTICAL_PADDING}px ${TOOLTIP_HORIZONTAL_PADDING}px;
    font-size: 12px;
    border-radius: 4px;

    min-width: ${({minWidth}) => minWidth}px;
    max-width: min(100vw, ${({maxWidth}) => maxWidth}px);

    z-index: ${MASK_Z_INDEX + 1};

    /*
      Rotate the arrow by different amounts depending on tooltip placement
      to ensure the borders 
    */
    &[data-popper-placement^='top'] > ${TooltipArrow} {
        bottom: -5px;

        &::before {
            transform: rotate(225deg);
        }
    }

    &[data-popper-placement^='bottom'] > ${TooltipArrow} {
        top: -5px;

        &::before {
            transform: rotate(45deg);
        }
    }

    &[data-popper-placement^='left'] > ${TooltipArrow} {
        right: -5px;

        &::before {
            transform: rotate(135deg);
        }
    }

    &[data-popper-placement^='right'] > ${TooltipArrow} {
        left: -5px;

        &::before {
            transform: rotate(-45deg);
        }
    }
`;

/**
 * A generic tooltip component which can wrap a react component and give it a tooltip
 * on hover or (optionally) on click. Any props not listed below are forwarded to the
 * element wrapping the tooltip children.
 *
 * @param {Node} content A React component or string which will make up the body of
 *     the tooltip.
 * @param {String} [placement='auto'] The direction the tooltip should prefer to
 *     pop out to when possible.
 * @param {Number} [minWidth=TOOLTIP_MIN] The min width for the tooltip content.
 * @param {Number} [maxWidth=TOOLTIP_MEDIUM] The max width for the tooltip content.
 * @param {Number} [delay=400] How many milliseconds to wait when hovering to display
 *     the tooltip.
 * @param {Boolean} [stickyOnClick=false] If true, when the child element is clicked
 *     display the tooltip until the mask is clicked.
 * @param {Boolean} [disabled=false] If true, don't display the tooltip content at all.
 * @param {Boolean} [scrollContentOnOverflow=false] If true, tooltip content
 *      will be scrollable if it extends beyond the available vertical space.
 *
 * @returns {Tooltip}
 */
function Tooltip({
    content,
    children,
    placement,
    minWidth,
    maxWidth,
    delay,
    stickyOnClick,
    disabled,
    scrollContentOnOverflow,
    ...otherProps
}) {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [opened, setOpened] = useState(false);

    const sustainedHover = useDelayedHover({
        target: referenceElement,
        delay,
    });

    const scrollContentOnOverflowModifier = useMemo(() => {
        return {
            name: 'scrollContentOnOverflow',
            phase: 'main',
            enabled: scrollContentOnOverflow,
            fn: ({ state, instance, options, name }) => {
                const overflow = detectOverflow(state);

                if (overflow.top > 0 && overflow.bottom > 0) {
                    state.rects.popper.height = state.rects.popper.height - (overflow.top + overflow.bottom);

                    if (!state.styles.popperContent) {
                        state.styles.popperContent = {};
                    }

                    state.styles.popperContent = {
                        maxHeight: (state.rects.popper.height - (2 * TOOLTIP_VERTICAL_PADDING)) + 'px',
                        overflow: 'auto',
                    };
                }
            }
        };
    }, [ scrollContentOnOverflow ]);

    const { state, styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        strategy: 'fixed',
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            scrollContentOnOverflowModifier,
        ],
    });

    const shown = !disabled && (sustainedHover || opened);

    return (
        <React.Fragment>
            <div
                // Forward the other props to the containing element
                {...otherProps}
                onClick={(event) => {
                    if (stickyOnClick) {
                        // Don't bubble the click
                        event.stopPropagation();

                        setOpened(true);
                    }
                }}
                ref={setReferenceElement}
            >
                {children}
            </div>

            {shown && (
                <Portal>
                    {opened && (
                        <Mask
                            onClick={(event) => {
                                // Don't bubble the click
                                event.stopPropagation();

                                setOpened(false);
                            }}
                        />
                    )}
                    <TooltipContainer
                        ref={setPopperElement}
                        style={styles.popper}
                        minWidth={minWidth}
                        maxWidth={maxWidth}
                        {...attributes.popper}
                    >
                        <div style={state?.styles?.popperContent || {}}>
                            {content}
                        </div>
                        <TooltipArrow ref={setArrowElement} style={styles.arrow} />
                    </TooltipContainer>
                </Portal>
            )}
        </React.Fragment>
    );
}

Tooltip.propTypes = {
    content: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf([
        'auto',
        'left',
        'top',
        'right',
        'bottom',
    ]),
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    delay: PropTypes.number,
    stickyOnClick: PropTypes.bool,
    disabled: PropTypes.bool,
    scrollContentOnOverflow: PropTypes.bool,
};

Tooltip.defaultProps = {
    placement: 'auto',
    delay: 400,
    minWidth: TOOLTIP_MIN,
    maxWidth: TOOLTIP_MEDIUM,
    stickyOnClick: false,
    disabled: false,
    scrollContentOnOverflow: false,
};

export default Tooltip;
