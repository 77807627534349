import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import Icon from 'Common/components/Icon';
import SectionToolbarBase from './SectionToolbarBase';
import SectionToolbarButton from './SectionToolbarButton';
import useHover from 'Common/hooks/useHover';
import { useTranslator } from 'Common/hooks/useTranslation';

/**
 * Manages the Section Toolbar on a dashboard page where the user can add, delete, and move sections.
 * Also allows the user to change the number and layout of columns in a section.
 */
export default function SectionToolbar(props) {
    const {
        index,
        sectionConfig,
        isLastSection,
        isDeleteDisabled,
        onAddSection,
        onDeleteSection,
        onMoveSection,
        onModifySection,
        onDeleteSectionHovered,
        sectionTypeOptions,
    } = props;

    const translator = useTranslator();

    const [selectedControl, setSelectedControl] = useState(null);

    const handleSelectionChange = useCallback((sc) => {
        setSelectedControl(sc);
    }, []);

    const moveUpEnabled = index > 0;
    const moveDownEnabled = ! isLastSection;

    const [deleteSectionElement, setDeleteSectionElement] = useState(null);
    const isDeleteSectionHovered = useHover(deleteSectionElement);

    useEffect(() => {
        onDeleteSectionHovered(!isDeleteDisabled && isDeleteSectionHovered);
    }, [isDeleteSectionHovered, onDeleteSectionHovered, isDeleteDisabled]);

    const handleSectionTypeChange = useCallback((event) => {
        onModifySection({
            sectionIndex: index,
            sectionType: event.target.value,
        });
    }, [ onModifySection, index ]);

    const handleActionButtonClick = useCallback((event) => {
        const buttonName = event.currentTarget.name;

        switch (buttonName) {
            case 'add_section':
                onAddSection({
                    sectionIndex: index,
                    sectionType: 'full'
                });
                break;
            case 'move_section_up':
                onMoveSection({
                    sectionSrcIndex: index,
                    sectionDestIndex: index - 1
                });
                break;
            case 'move_section_down':
                onMoveSection({
                    sectionSrcIndex: index,
                    sectionDestIndex: index + 1
                });
                break;
            case 'delete_section':
                onDeleteSection({ sectionIndex: index });
                break;
        }
    }, [ index, onAddSection, onMoveSection, onDeleteSection ]);

    return (
        <SectionToolbarBase
            onSelectionChange={handleSelectionChange}
            selection={selectedControl}
            sectionTypeValue={sectionConfig.type}
            sectionTypeOptions={sectionTypeOptions}
            onSectionTypeChange={handleSectionTypeChange}
        >
            <SectionToolbarButton
                name={'add_section'}
                displayName={translator('control.section.add_section')}
                icon={(<Icon type="vorne" iconName="add" />)}
                onClick={handleActionButtonClick}
            />
            <SectionToolbarButton
                name={'move_section_up'}
                displayName={translator('control.section.move_section_up')}
                icon={(<Icon type="vorne" iconName="move-section-up" />)}
                disabled={! moveUpEnabled}
                onClick={handleActionButtonClick}
            />
            <SectionToolbarButton
                name={'move_section_down'}
                displayName={translator('control.section.move_section_down')}
                icon={(<Icon type="vorne" iconName="move-section-down" />)}
                disabled={! moveDownEnabled}
                onClick={handleActionButtonClick}
            />
            <SectionToolbarButton
                name={'delete_section'}
                displayName={translator('control.section.delete_section')}
                buttonRef={setDeleteSectionElement}
                icon={(<Icon type="vorne" iconName="delete-section" />)}
                disabled={isDeleteDisabled}
                onClick={handleActionButtonClick}
            />
        </SectionToolbarBase>
    );
}

SectionToolbar.propTypes = {
    sectionConfig: PropTypes.object.isRequired,
    sectionTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            icon: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    index: PropTypes.number.isRequired,
    isLastSection: PropTypes.bool.isRequired,
    isDeleteDisabled: PropTypes.bool,
    onAddSection: PropTypes.func.isRequired,
    onDeleteSection: PropTypes.func.isRequired,
    onMoveSection: PropTypes.func.isRequired,
    onModifySection: PropTypes.func.isRequired,
    onDeleteSectionHovered: PropTypes.func.isRequired,
};

SectionToolbar.defaultProps = {
    isDeleteDisabled: false,
};
