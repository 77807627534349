import React from 'react';
import styled from 'styled-components';
import IconLink from '../IconLink';
import FaIcon from 'Components/icon/FaIcon';
import { Caption } from 'Components/typography/typography';

const MainMenuText = styled(Caption)`
    display: inline-block;
    padding-left: 6px;

    text-transform: uppercase;
    font-weight: 900;
`;

const Root = styled(IconLink)`
    ${MainMenuText} {
        display: inline-block;
        padding-left: 6px;

        text-transform: uppercase;
        font-weight: 900;
    }
`;

export default React.forwardRef(
    function MainMenuIconLink(props, ref) {
        return (
            <Root ref={ref} {...props}>
                <FaIcon i="bars" />
                <MainMenuText>Main Menu</MainMenuText>
            </Root>
        );
    }
);
