import React from 'react';
import PropTypes from 'prop-types';
import 'Fonts/fontawesome/css/font-awesome.css';
import classNames from 'classnames';

export default class FaIcon extends React.Component {
    render() {
        const { className, ...otherProps } = this.props;

        return (
            <span className={classNames(`fa fa-${this.props.i}`, className)} {...otherProps} />
        );
    }
}

FaIcon.propTypes = {
    className: PropTypes.string,
    i: PropTypes.string,
};

FaIcon.defaultProps = {
    i: '',
};
