/**
 * Helper function for sending requests in async action creators
 * 
 * @param {Object} actions Map of action creators for each stage of the request
 * @param {function} actions.request Dispatched before the request
 * @param {function} actions.success Dispatched on success
 * @param {function} actions.failure Dispatched on failure
 * @param {function} sendRequest Function that actually does the sending of the request
 * @returns {function} async action creator
 */
export function requestActionCreator(actions, sendRequest) {
    return async (dispatch) => {
        dispatch(actions.request());

        let response = null;

        try {
            response = await sendRequest();
        }
        catch (err) {
            return dispatch(actions.failure({
                message: err.message,
            }));
        }

        if (response.ok) {
            return dispatch(actions.success(response.data));
        }
        else {
            return dispatch(actions.failure(response.data));
        }
    };
}
