import { sendGetRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';
import { REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';

export const getSessionUser = () => requestActionCreator(
    {
        request: () => ({
            type: REQUEST_PENDING,
            requestId: 'getSessionUser',
        }),
        success: (payload) => ({
            type: REQUEST_SUCCESS,
            requestId: 'getSessionUser',
            payload,
        }),
        failure: (error) => ({
            type: REQUEST_FAILURE,
            requestId: 'getSessionUser',
            payload: { error },
        }),
    },
    () => sendGetRequest('/api/v1/accounts/user')
);

export default getSessionUser;
