import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    padding-right: 16px;
`;

function ListItemRightComponent(props) {
    const {
        children: childrenProp,
        ...otherProps
    } = props;

    const child = React.Children.only(childrenProp);

    return (
        <Root {...otherProps}>
            {child}
        </Root>
    );
}

ListItemRightComponent.propTypes = {
    children: PropTypes.element.isRequired,
};

export default ListItemRightComponent;
