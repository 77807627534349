import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropdownContentBox from './internal/DropdownContentBox';
import { withDropdownContext } from './internal/DropdownContext';

// Give a margin for scrollbars on the edge of the screen, and it looks a
// little nicer.
const windowEdgeMargin = 24;

const Root = styled(DropdownContentBox)`
    position: absolute;
    z-index: 100;
    transition: opacity 300ms;
    min-width: 120px;
    max-width: calc(100vw - ${2 * windowEdgeMargin}px);
    max-height: calc(100vh - ${2 * windowEdgeMargin}px);
`;

function getCurrentWindowSize() {
    return {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
    };
}

function calculateAbsolutePosition(anchorRect, windowSize) {
    let style = {};

    const spaceAboveAnchor = anchorRect.top;
    const spaceBelowAnchor = windowSize.height - anchorRect.bottom;

    if (spaceAboveAnchor > spaceBelowAnchor) {
        // Position off the top of the anchor
        style = {
            ...style,
            bottom: `${anchorRect.height}px`,
            maxHeight: `${spaceAboveAnchor - windowEdgeMargin}px`,
        };
    }
    else {
        // Position off the bottom of the anchor
        style = {
            ...style,
            top: `${anchorRect.height}px`,
            maxHeight: `${spaceBelowAnchor - windowEdgeMargin}px`,
        };
    }

    const spaceLeftOfAnchor = anchorRect.right;
    const spaceRightOfAnchor = windowSize.width - anchorRect.left;

    if (spaceLeftOfAnchor > spaceRightOfAnchor) {
        // Position off the left of the anchor
        style = {
            ...style,
            right: '0',
            maxWidth: `${spaceLeftOfAnchor - windowEdgeMargin}px`,
        };
    }
    else {
        // Position off the right of the anchor
        style = {
            ...style,
            left: '0',
            maxWidth: `${spaceRightOfAnchor - windowEdgeMargin}px`,
        };
    }

    return style;
}

class DropdownContent extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,

        // Provided by `withDropdownContext`
        dropdownContext: PropTypes.shape({
            isDefault: PropTypes.bool.isRequired,
            getAnchorNode: PropTypes.func.isRequired,
            contentRef: PropTypes.shape({
                current: PropTypes.any,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        if (props.dropdownContext.isDefault) {
            throw new Error('DropdownContent must be rendered as a descendant of Dropdown');
        }

        this.state = {
            mounted: false,
            anchorRect: null,
            windowSize: null,
        };
    }

    get _rootRef() {
        return this.props.dropdownContext.contentRef;
    }

    componentDidMount() {
        const { dropdownContext } = this.props;

        const anchorNode = dropdownContext.getAnchorNode();
        const anchorRect = anchorNode.getBoundingClientRect();

        this.setState({
            mounted: true,
            anchorRect: anchorRect,
            windowSize: getCurrentWindowSize(),
        });

        window.addEventListener('resize', this._handleWindowResize);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mounted && !prevState.mounted) {
            this._rootRef.current.focus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleWindowResize);
    }

    _handleWindowResize = (event) => {
        this.setState({
            windowSize: getCurrentWindowSize(),
        });
    };

    render() {
        const { className, children } = this.props;
        const { anchorRect, windowSize } = this.state;

        let style = {
            opacity: '0',
        };

        if (anchorRect && windowSize) {
            style = {
                opacity: '1',
                ...calculateAbsolutePosition(anchorRect, windowSize),
            };
        }

        return (
            <Root
                ref={this._rootRef}
                className={className}
                style={style}
                tabIndex={0} // This tabIndex is important, it allows for focus
                role="dialog"
            >
                {children}
            </Root>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
function BlockedRender(props) {
    if (props.dropdownContext.open) {
        return (
            <DropdownContent {...props} />
        );
    }
    else {
        return null;
    }
}

BlockedRender.propTypes = {
    dropdownContext: PropTypes.shape({
        open: PropTypes.bool
    }),
};

export default withDropdownContext(BlockedRender);
