import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectSessionUser, selectSessionRequest } from 'Redux/reducers/session';

import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentStandard';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import {
    getSessionUser,
    patchSessionUser,
    patchSessionUserReset,
    patchSessionUserEmail,
    patchSessionUserEmailReset,
    patchSessionUserSmsEmail,
    patchSessionUserSmsEmailReset,
    getHierarchy,
} from 'Redux/actions';
import MultiRequestLoadingWrapper from 'Components/loading-indicator/MultiRequestLoadingWrapper';
import PageDescription from 'Components/page/PageDescription';
import PrimaryEmailForm from './internal/PrimaryEmailForm';
import SmsEmailForm from './internal/SmsEmailForm';
import { SessionUserForm } from './internal/SessionUserForm';
import verifyEmailResend, { verifyEmailResendReset } from 'Redux/actions/verifyEmailResend';
import verifySmsEmailResend, { verifySmsEmailResendReset } from 'Redux/actions/verifySmsEmailResend';
import { selectFlattenedHierarchy, selectHierarchyRequest } from 'Redux/reducers/hierarchy';

export class EditSessionUserPagePresentation extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        flattenedHierarchy: PropTypes.object,
        requests: PropTypes.shape({
            getHierarchy: PropTypes.object.isRequired,
            getSessionUser: PropTypes.object.isRequired,
            patchSessionUser: PropTypes.object.isRequired,
            patchSessionUserEmail: PropTypes.object.isRequired,
            patchSessionUserSmsEmail: PropTypes.object.isRequired,
            verifyEmailResend: PropTypes.object.isRequired,
            verifySmsEmailResend: PropTypes.object.isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            getHierarchy: PropTypes.func.isRequired,
            getSessionUser: PropTypes.func.isRequired,
            patchSessionUserReset: PropTypes.func.isRequired,
            patchSessionUser: PropTypes.func.isRequired,
            patchSessionUserEmailReset: PropTypes.func.isRequired,
            patchSessionUserEmail: PropTypes.func.isRequired,
            patchSessionUserSmsEmailReset: PropTypes.func.isRequired,
            patchSessionUserSmsEmail: PropTypes.func.isRequired,
            verifyEmailResend: PropTypes.func.isRequired,
            verifyEmailResendReset: PropTypes.func.isRequired,
            verifySmsEmailResend: PropTypes.func.isRequired,
            verifySmsEmailResendReset: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.actions.getSessionUser();
        this.props.actions.getHierarchy();
    }

    render() {
        const {
            user: initialValue,
            requests,
            actions,
            flattenedHierarchy,
        } = this.props;

        return (
            <Page>
                <ConfigPageTitle>Account</ConfigPageTitle>
                <PageDescription>
                    Use this page to view or change your account details.
                </PageDescription>
                <PageContent>
                    <MultiRequestLoadingWrapper
                        requests={[
                            requests.getSessionUser,
                            requests.getHierarchy,
                        ]}
                        render={() => {
                            return (
                                <React.Fragment>
                                    <PrimaryEmailForm
                                        initialValue={initialValue}
                                        requests={{
                                            patchSessionUserEmail: requests.patchSessionUserEmail,
                                            verifyEmailResend: requests.verifyEmailResend,
                                        }}
                                        actions={{
                                            patchSessionUserEmailReset: actions.patchSessionUserEmailReset,
                                            patchSessionUserEmail: actions.patchSessionUserEmail,
                                            verifyEmailResendReset: actions.verifyEmailResendReset,
                                            verifyEmailResend: actions.verifyEmailResend,
                                        }}
                                    />
                                    <SmsEmailForm
                                        initialValue={initialValue}
                                        requests={{
                                            patchSessionUserSmsEmail: requests.patchSessionUserSmsEmail,
                                            verifySmsEmailResend: requests.verifySmsEmailResend,
                                        }}
                                        actions={{
                                            patchSessionUserSmsEmail: actions.patchSessionUserSmsEmail,
                                            patchSessionUserSmsEmailReset: actions.patchSessionUserSmsEmailReset,
                                            verifySmsEmailResendReset: actions.verifySmsEmailResendReset,
                                            verifySmsEmailResend: actions.verifySmsEmailResend,
                                        }}
                                    />
                                    <SessionUserForm
                                        initialValue={initialValue}
                                        requests={{
                                            patchSessionUser: requests.patchSessionUser,
                                        }}
                                        actions={{
                                            patchSessionUser: actions.patchSessionUser,
                                            patchSessionUserReset: actions.patchSessionUserReset,
                                        }}
                                        enterpriseHierarchy={flattenedHierarchy}
                                    />
                                </React.Fragment>
                            );
                        }}
                    />
                </PageContent>
            </Page>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { session, hierarchy } = state;

    return {
        user: selectSessionUser(session),
        flattenedHierarchy: selectFlattenedHierarchy(hierarchy),
        requests: {
            getHierarchy: selectHierarchyRequest(hierarchy, 'getHierarchy'),
            getSessionUser: selectSessionRequest(session, 'getSessionUser'),
            patchSessionUser: selectSessionRequest(session, 'patchSessionUser'),
            patchSessionUserEmail: selectSessionRequest(session, 'patchSessionUserEmail'),
            patchSessionUserSmsEmail: selectSessionRequest(session, 'patchSessionUserSmsEmail'),
            verifyEmailResend: selectSessionRequest(session, 'verifyEmailResend'),
            verifySmsEmailResend: selectSessionRequest(session, 'verifySmsEmailResend'),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getHierarchy: getHierarchy,
            getSessionUser: getSessionUser,
            patchSessionUserReset: patchSessionUserReset,
            patchSessionUser: patchSessionUser,
            patchSessionUserEmailReset: patchSessionUserEmailReset,
            patchSessionUserEmail: patchSessionUserEmail,
            patchSessionUserSmsEmailReset: patchSessionUserSmsEmailReset,
            patchSessionUserSmsEmail: patchSessionUserSmsEmail,
            verifyEmailResendReset: verifyEmailResendReset,
            verifyEmailResend: verifyEmailResend,
            verifySmsEmailResendReset: verifySmsEmailResendReset,
            verifySmsEmailResend: verifySmsEmailResend,
        }, dispatch),
    };
};

// connect the component to Redux
const EditSessionUserPage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSessionUserPagePresentation));

export default EditSessionUserPage;
