import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import { sendPostRequest } from 'Http/request';

import { Body1 } from 'Components/typography/typography';
import queryString from 'query-string';
import ContainedButton from 'Components/button/ContainedButton';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentStandard';
import { Spacing } from 'Components/common/common';
import PageDescription from 'Components/page/PageDescription';
import FaIcon from 'Components/icon/FaIcon';

const ContentWrapper = styled.div`

`;

const SubmitButton = styled(ContainedButton)`
    margin-top: ${Spacing.formButtonMargin};
`;

const CheckIcon = styled(props => (<span {...props}><FaIcon i="check-circle" /></span>))`
    color: ${props => props.theme.colors.success};
    font-size: 120px;
`;

class XLConnectPage extends React.Component {
    constructor() {
        super();

        this.state = {
            complete: false,
            linking: false,
            error: false,
            redirecting: false,
        };

        this._handleDeviceLink = this._handleDeviceLink.bind(this);
    }

    componentWillUnmount() {
        if (this.redirectTimeoutId) {
            clearTimeout(this.redirectTimeoutId);
        }
    }

    async _handleDeviceLink() {
        const params = queryString.parse(this.props.location.search);

        this.setState({ linking: true });

        const payload = {
            linking_token: params.linking_token,
        };

        try {
            const response = await sendPostRequest('/api/v1/devices/linking-token', {
                body: payload
            });

            if (response.ok) {
                this.setState({
                    linking: false,
                    complete: true
                });

                // Wait ten seconds before loading the hierarchy while the
                // linking process is finalized asynchronously between XLPA
                // and the Enterprise API
                this.redirectTimeoutId = setTimeout(() => {
                    this.setState({ redirecting: true });
                }, 10000);
            }
            else {
                this.setState({
                    linking: false,
                    error: true,
                    errorInfo: response.data,
                });

            }
        }
        catch (err) {
            console.error('Unable to send request', err);
            this.setState({
                linking: false,
                error: true,
                errorInfo: {
                    message: 'Unable to send request'
                }
            });
        }
    }

    _valid(params) {
        // Linking token and asset name are required URL parameters.
        return !!params.linking_token && !!params.asset_name;
    }

    _renderReady() {
        const params = queryString.parse(this.props.location.search);

        if (!this._valid(params)) {
            return (
                <Page>
                    <PageTitle>Invalid Link</PageTitle>
                    <PageContent>The link used to come to this page is invalid.</PageContent>
                </Page>
            );
        }
        else {
            return (
                <Page>
                    <PageTitle>Link XL Device</PageTitle>
                    <PageDescription>
                        You’ve reached this page by linking one of your XL devices to XL Enterprise.
                        Click the Link Device button below to complete the linking process.
                    </PageDescription>
                    <PageContent>
                        <ContentWrapper>
                            <Body1>Link &quot;{params.asset_name}&quot; to your XL Enterprise organization?</Body1>
                        </ContentWrapper>
                        <SubmitButton
                            color="primary"
                            onClick={this._handleDeviceLink}
                        >
                            Link Device
                        </SubmitButton>
                    </PageContent>
                </Page>
            );
        }
    }

    _renderInProgress() {
        return (
            <Page>
                <PageTitle>Link XL Device</PageTitle>
                <PageContent>
                    <ContentWrapper>
                        <Body1>Please wait, linking in progress...</Body1>
                    </ContentWrapper>
                </PageContent>
            </Page>
        );
    }

    _renderLinkingError() {
        let errorMessage = this.state.errorInfo.message;
        if (this.state.errorInfo.status_code === 409) {
            errorMessage = (
                <React.Fragment>
                    <p>
                        The Asset ID for this XL device is already used in XL Enterprise.
                        This can happen if a backup file from one device is restored to another device.
                        Please contact Vorne technical support, who will assist you in assigning a new Asset ID.
                    </p>
                    <p><pre>Asset ID: {this.state.errorInfo.payload.asset_id}</pre></p>
                </React.Fragment>
            );
        }
        return (
            <Page>
                <PageTitle>Error</PageTitle>
                <PageContent>
                    <Body1>{errorMessage}</Body1>
                </PageContent>
            </Page>
        );
    }

    _renderSuccess() {
        return (
            <Page>
                <CheckIcon />
                <PageTitle>Device has been linked successfully</PageTitle>
                <PageDescription>
                    The enterprise hierarchy will open automatically in a few seconds.
                    {this.state.redirecting && (
                        <Redirect to={{ pathname: '/config/devices/all-assets' }} />
                    )}
                </PageDescription>
            </Page>
        );
    }

    render() {
        if (this.state.linking) {
            return this._renderInProgress();
        }
        else if (this.state.error) {
            return this._renderLinkingError();
        }
        else if (this.state.complete || this.state.redirecting) {
            return this._renderSuccess();
        }
        else {
            return this._renderReady();
        }
    }
}

XLConnectPage.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string
    }).isRequired,
};

export default XLConnectPage;
