import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Caption } from 'Components/typography/typography';
import withFormElementContext from 'Components/form-element/withFormElementContext';

const Root = styled(Caption)`
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 1.5em;
    line-height: 1.5em;
    opacity: 0.3;
    transition: opacity 300ms;

    &.error {
        color: ${props => props.theme.colors.palette.error.red};
        opacity: 1.0;
    }
`;

class FormElementHelperText extends React.Component {
    render() {
        const {
            className: classNameProp,
            formElementContext,
            ...otherProps
        } = this.props;

        const { error } = formElementContext;

        const className = classNames(classNameProp, { error });

        return (
            <Root className={className} {...otherProps} />
        );
    }
}

FormElementHelperText.defaultProps = {
    className: ''
};

FormElementHelperText.propTypes = {
    className: PropTypes.string,
    formElementContext: PropTypes.object.isRequired,
};

export default withFormElementContext(FormElementHelperText);
