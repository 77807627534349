import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body1 } from 'Components/typography/typography';

const NoticeBox = styled.div`
    box-sizing: border-box;
    background-color: ${props => props.colors.background};
    color: ${props => props.colors.text};
    border-radius: 5px;
    border: 2px solid ${props => props.colors.border};
    margin-bottom: 8px;
    padding: 20px 28px;
`;

function Notice({ children, textColor, backgroundColor, borderColor, ...otherProps }) {
    const boxColors = {
        background: backgroundColor,
        text: textColor,
        border: borderColor,
    };

    return (
        <NoticeBox colors={boxColors} {...otherProps}>
            <Body1>
                {children}
            </Body1>
        </NoticeBox>
    );
}

Notice.defaultProps = {
    backgroundColor: '#ffffff',
    textColor: '#000000',
    borderColor: 'transparent',
};

Notice.propTypes = {
    children: PropTypes.node,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
};

export default Notice;
