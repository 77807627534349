import React from 'react';
import { getDisplayName } from 'Components/util/reactUtils';
import FormElementContext from './FormElementContext';

export default function withFormElementContext(WrappedComponent) {
    const WithFormElementContext = React.forwardRef((props, ref) => (
        <FormElementContext.Consumer>
            {(context) => {
                return (
                    <WrappedComponent
                        ref={ref}
                        {...props}
                        formElementContext={context}
                    />
                );
            }}
        </FormElementContext.Consumer>
    ));

    // Wrap the display name for easy debugging
    WithFormElementContext.displayName = `WithFormElementContext(${getDisplayName(WrappedComponent)})`;

    return WithFormElementContext;
}
