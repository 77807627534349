import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import { interceptProps } from 'Components/util/reactUtils';

const Root = styled(interceptProps(Button, ['styled']))`
    color: ${(props) => props.styled.textColor};
    border: 1px solid ${(props) => props.styled.borderColor};
    background-color: ${(props) => props.styled.backgroundColor};
`;

function OutlinedButton(props) {
    const {
        theme,
        children,
        ...otherProps
    } = props;

    let borderColor = theme.colors.palette.blue.awesome;
    let textColor = theme.colors.palette.blue.awesome;
    let backgroundColor = theme.colors.palette.white;

    return (
        <Root {...otherProps} styled={{ textColor, borderColor, backgroundColor }}>
            {children}
        </Root>
    );
}

OutlinedButton.propTypes = {
    theme: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default withTheme(OutlinedButton);
