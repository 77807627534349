import { useCallback } from 'react';
import useRepeatingRequest from './useRepeatingRequest';
import useRequestWithStatus from './useRequestWithStatus';

/**
 * Like useRepeatingRequest, but returns the current status and state of the
 * request.
 *
 * IMPORTANT: Be sure to memoize (i.e., useCallback) the `sendRequest` function
 * because the loop will be restarted (and any pending requests aborted)
 * each time the reference to the function changes.
 *
 * @param {Object} opts
 * @param {function({ abortSignal: AbortSignal }): Promise<{ error?: *, response?: * }>} opts.sendRequest
 *      Async function to send a request.
 * @param {number} opts.delayMs How often (in milliseconds) should the request repeat?
 * @param {boolean} opts.disableRepeat If true, opts.delayMs is ignored and the
 *      request will not repeat.
 *
 * @returns {{
 *      status: RequestStatus,
 *      error?: *,
 *      response?: *,
 * }}
 */
export default function useRepeatingRequestWithStatus({
    sendRequest,
    delayMs,
    disableRepeat,
}) {
    const {
        send,
        resend,
        requestState,
    } = useRequestWithStatus(sendRequest);

    const internalSendRequest = useCallback(async ({ isInitialRequest, abortSignal }) => {
        if (isInitialRequest) {
            return send({ abortSignal });
        }
        else {
            return resend({ abortSignal });
        }
    }, [ send, resend ]);

    useRepeatingRequest({
        sendRequest: internalSendRequest,
        delayMs,
        disableRepeat,
    });

    return requestState;
}
