import React from 'react';
import Icon from 'Common/components/Icon';
import styled from 'styled-components';
import useTranslation from 'Common/hooks/useTranslation';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    align-items: center;
    min-height: 24px;
`;

const IconContainer = styled.div`
    padding-right: 3px;
`;

export default function NoDataPlaceholder() {
    const iconTitle = useTranslation('no_data_in_gap_extended_copy');
    const noDataText = useTranslation('no_data_literal');

    return (
        <Container>
            <IconContainer title={iconTitle}>
                <Icon type="fontAwesome" iconName="info-circle"/>
            </IconContainer>
            <div>{noDataText}</div>
        </Container>
    );
}
