import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { T3, Body1 } from 'Components/typography/typography';
import FaIcon from 'Components/icon/FaIcon';

const ANIMATION_LENGTH_MS = 500;

const Root = styled.div`
    position: relative;
    margin: 24px 0;
`;

const TitleLineWrapper = styled.div``;
const TitleText = styled.span``;

const Title = styled(T3)`
    display: block;
    line-height: 2em;
    color: ${props => props.theme.colors.palette.blue.awesome};

    ${TitleLineWrapper} {
        display: inline-block;

        &.collapsible {
            cursor: pointer;

            ${TitleText} {
                ${'' /* Add some padding for the caret */}
                padding-left: 7px;
            }
        }
    }
`;

const TitleCaret = styled(FaIcon)`
    transition: transform ${ANIMATION_LENGTH_MS}ms cubic-bezier(0.4, 0.0, 0.2, 1);
    transform: rotate(-90deg);

    &.isOpen {
        transform: rotate(-45deg);
    }
`;

const Content = styled(Body1)``;

const ContentMeasureable = styled.div``;

const ContentWrapper = styled.div.attrs((props) => {
    const style = {};

    if (props._height !== null) {
        style.height = `${props._height}px`;
        style.overflow = 'hidden';
    }

    return { style };
})`
    display: block;
    transition: height ${ANIMATION_LENGTH_MS}ms cubic-bezier(0.4, 0.0, 0.2, 1);
    box-sizing: border-box;
    margin: 5px;

    ${Content} {
        display: block;
    }
`;

export default class PageFrame extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string.isRequired,
        collapsible: PropTypes.bool,
        children: PropTypes.node,
    };

    static defaultProps = {
        collapsible: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            contentHeight: null,
        };

        this._contentRef = React.createRef();
        this._contentWrapperRef = React.createRef();
        this._contentMeasureableRef = React.createRef();

        this._openAnimation = this._openAnimation.bind(this);
        this._closeAnimation = this._closeAnimation.bind(this);

        this._isAnimating = false;
    }

    componentWillUnmount() {
        this._abortCurrentAnimation();
    }

    _handleTitleClick = () => {
        if (!this.props.collapsible) {
            return;
        }

        let animation;

        if (this.state.isOpen) {
            animation = this._closeAnimation;
        }
        else {
            animation = this._openAnimation;
        }

        this._runAnimation(animation);
    };

    _runAnimation(animation) {
        // Abort any animations in progress
        this._abortCurrentAnimation();

        const gen = animation();

        const step = (cb) => {
            const { value, done } = gen.next();

            if (typeof value !== 'function') {
                if (!done) {
                    console.error('Stopping animation because it yielded a non-function.');
                    gen.return();
                }
                cb();
                return;
            }

            value(() => {
                if (!done) {
                    step(cb);
                }
                else {
                    cb();
                }
            });
        };

        // Set as the current animation
        this._currentAnimation = gen;

        step(() => {
            // Only clear if we are still the current animation (not aborted)
            if (this._currentAnimation === gen) {
                this._currentAnimation = null;
            }
        });
    }

    _abortCurrentAnimation() {
        if (this._currentAnimation) {
            this._currentAnimation.return();
            this._currentAnimation = null;
        }
    }

    * _openAnimation() {
        const contentNode = this._contentMeasureableRef.current;
        const height = contentNode.getBoundingClientRect().height;

        yield f => this.setState((state) => {
            return {
                isOpen: true,
                contentHeight: 0,
            };
        }, f);

        yield f => requestAnimationFrame(f);

        yield f => this.setState(() => {
            return { contentHeight: height };
        }, f);

        yield f => setTimeout(f, ANIMATION_LENGTH_MS);

        yield f => this.setState(() => {
            return { contentHeight: null };
        }, f);
    }

    * _closeAnimation() {
        const contentNode = this._contentMeasureableRef.current;
        const height = contentNode.getBoundingClientRect().height;

        yield f => this.setState((state) => {
            return {
                isOpen: false,
                contentHeight: height,
            };
        }, f);

        yield f => requestAnimationFrame(f);

        yield f => this.setState(() => {
            return { contentHeight: 0 };
        }, f);

        yield f => setTimeout(f, ANIMATION_LENGTH_MS);
    }

    render() {
        const { className, title, collapsible, children, ...otherProps } = this.props;
        const { isOpen, contentHeight } = this.state;

        return (
            <Root className={className} {...otherProps}>
                <Title>
                    <TitleLineWrapper onClick={this._handleTitleClick} className={classNames({ collapsible })}>
                        {collapsible && (
                            <TitleCaret i="caret-down" className={classNames({ isOpen })} />
                        )}
                        <TitleText>{title}</TitleText>
                    </TitleLineWrapper>
                </Title>
                <ContentWrapper
                    ref={this._contentWrapperRef}
                    _height={contentHeight}
                    as="div"
                >
                    <ContentMeasureable ref={this._contentMeasureableRef}>
                        <Content
                            ref={this._contentRef}
                            as="div"
                        >
                            {children}
                        </Content>
                    </ContentMeasureable>
                </ContentWrapper>
            </Root>
        );
    }
}
