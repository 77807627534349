import { preprocessOptions } from 'Common/util/format.js';
import { exformat } from 'Common/util/language/string.js';

import { createItem, DurationFormatterUnits } from './DurationFormatter';

const SPECIAL_FORMATS = {
    'vorne-s': 's{s}',
    'vorne-ms': 'm{m} s{s}',
    'vorne-hm': 'h{h} m{m}',
    'vorne-hms': 'h{h} m{m} s{s}',
    'vorne-hms.sss': 'h{h} m{m} s.000{s}'
};

function getSpecialFormat(formatName, translator) {
    let specialFormat = SPECIAL_FORMATS[formatName];

    if (specialFormat) {
        specialFormat = exformat(specialFormat, {
            h: '"' + translator('hours_abbreviation') + '"',
            m: '"' + translator('minutes_abbreviation') + '"',
            s: '"' + translator('seconds_abbreviation') + '"'
        });
    }

    return specialFormat;
}

export default function formatDuration(duration, opts) {
    opts = preprocessOptions(opts);
    let pattern = opts.pattern ||
            getSpecialFormat(opts.formatItem, opts.translator) ||
            'h:mm:ss',
        re = /(,?([Dd]+|[Hh]+|[Mm]+|[Ss]+)|\s+|[:]|".*?"|'.*?'|\.0*[0#]#*)/g,
        negative = duration < 0,
        items = pattern.match(re).map((...mapArgs) => createItem(...mapArgs, opts.locale)),
        units, rv;

    duration = negative ? -duration : duration;

    units = new DurationFormatterUnits({
        duration: duration,
        items: items
    });

    rv = items.map(function(item) {
        return item.format(units);
    });

    return (negative ? '-' : '') + rv.join('');
}

/**
 * Provides a convenient default formatter for the '_h __m __s' (e.g. 1h 22m 15s) that
 * we often use. This formatter also truncates the least significant 0 values. So what
 * would be "1h 23m 00s" would instead be "1h 23m".
 *
 * @param {Number} duration The duration to format in seconds
 * @param {Object} opts
 * @param {Function} opts.translator A text translator function such as cpe.t to
 *     translate the h, m, and s abbreviations.
 * @param {Number} [opts.maxSignificantValues = 3] The max number of significant values
 *     to include. For example if set to 2 "1h 22m 15s" would instead be "1h 22m".
 * @param {String} [opts.locale] The locale string for the duration formatter
 */
export function formatHMSDuration(duration, { translator, maxSignificantValues = 3, locale }) {
    const hmsDuration = formatDuration(duration, { pattern: "[d]:[h]:[m]:[s]", locale, translator }).split(':');
    const patternTranslations = ['days_abbreviation', 'hours_abbreviation', 'minutes_abbreviation', 'seconds_abbreviation'];

    const hmsDurationFormatted = hmsDuration.reduce((agg, val, index) => {
        if (val !== '0' && agg.length < maxSignificantValues) {
            agg.push(`${val}${translator(patternTranslations[index])} `);
        }

        return agg;
    }, []).join('').trim();

    return hmsDurationFormatted;
}
