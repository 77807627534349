export const NodeType = {
    DEVICE: 'device',
    AREA: 'area',
    PLANT: 'plant',
    ENTERPRISE: 'enterprise',
};

export function getNodeTypeIconName(nodeType) {
    switch (nodeType) {
        case NodeType.DEVICE: return 'gear';
        case NodeType.AREA: return 'department';
        case NodeType.PLANT: return 'factory';
        case NodeType.ENTERPRISE: return 'globe';
        default: throw new Error(`Unknown node type '${nodeType}'`);
    }
}

export function getNodeTypeIconColor(nodeType) {
    switch (nodeType) {
        case NodeType.DEVICE: return '#505050';
        case NodeType.AREA: return '#1070c0';
        case NodeType.PLANT: return '#8f5b2a';
        case NodeType.ENTERPRISE: return '#4f9d47';
        default: throw new Error(`Unknown node type '${nodeType}'`);
    }
}

export function getNodeTypeDisplayText(nodeType) {
    switch (nodeType) {
        case NodeType.DEVICE: return 'Work Center';
        case NodeType.AREA: return 'Area';
        case NodeType.PLANT: return 'Site';
        case NodeType.ENTERPRISE: return 'Enterprise';
        default: throw new Error(`Unknown node type '${nodeType}'`);
    }
}

export function getNodeTypePlaceholderText(nodeType) {
    return `${getNodeTypeDisplayText(nodeType)} name`;
}

export function getNodeTypeValues() {
    return [
        NodeType.DEVICE,
        NodeType.AREA,
        NodeType.PLANT,
        NodeType.ENTERPRISE,
    ];
}

export function getSelectableNodeTypeValues() {
    return [
        NodeType.PLANT,
        NodeType.AREA,
    ];
}

export function getHierarchyNodeDisplayName(node) {
    if (node.node_type === NodeType.DEVICE) {
        return node.device.asset_name;
    }
    else {
        return node.node_name;
    }
}
