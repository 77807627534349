import PropTypes from 'prop-types';
import { getMetricsMap } from 'System/metrics';

const metricsMap = getMetricsMap();

const comparisonMap = {
    greater_than: '>',
    less_than: '<',
};

export default function MetricAlertText(props) {
    const { metric, comparison, threshold } = props;

    return (
        `${metricsMap[metric].name} ${comparisonMap[comparison]} ${Math.round(threshold * 100.0)}%`
    );
}

MetricAlertText.propTypes = {
    metric: PropTypes.oneOf(Object.keys(metricsMap)).isRequired,
    comparison: PropTypes.oneOf(Object.keys(comparisonMap)).isRequired,
    threshold: PropTypes.number.isRequired,
};
