

/**
 * Validation function for the common NumberField component.
 *
 * @param {Number|String} num Number value to validate.
 * @param {Object} [opts]
 * @param {Number} [opts.min = MIN_SAFE_INT] The lower bound for a valid number.
 * @param {Number} [opts.max = MAX_SAFE_INT] The upper bound for a valid number.
 * @param {Boolean} [opts.integral = false] If true the number should be a whole number to
 *     pass validation.
 * @param {Boolean} [opts.percentage = false] If true treat as a percentage.
 * @param {Boolean} [opts.allowEmpty = false] If true count empty values as valid.
 * @param {Function} [opts.translator] The translator used to return string literals from
 *     provided keys and substitution mapping.
 *
 * @returns {String} Error message if applicable, undefined if there are no errors to report.
 */
export default function validateNumber(num, {
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    integral,
    percentage,
    allowEmpty,
    translator,
}) {
    let error;

    const originalNum = num;

    num = Number(num);

    const nanErrorLiteral = translator({ key: 'not_a_number_error'});
    const gteErrorLiteral = translator({
        key: 'number_must_be_gte_error',
        substitutions: { min: min }
    });
    const lteErrorLiteral = translator({
        key: 'number_must_be_lte_error',
        substitutions: { max: max }
    });
    const wholeNumberErrorLiteral = translator({ key: 'not_a_whole_number_error'});

    if (Number.isNaN(num)) {
        if (!allowEmpty || originalNum) {
            error = nanErrorLiteral;
        }
        else {
            // No need to check anything else since we are NaN
            return undefined;
        }
    }
    else if (num < min) {
        error = gteErrorLiteral;
    }
    else if (num > max) {
        error = lteErrorLiteral;
    }
    else if (integral) {
        const adjustedNum = percentage ? num * 100 : num;

        if (adjustedNum !== Math.trunc(adjustedNum)) {
            error = wholeNumberErrorLiteral;
        }
    }

    return error;
}
