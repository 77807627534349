import { sendPutRequest } from 'Http/request';
import { putResource } from 'Common/util/ResourceCollection';

export function putMetricThresholds(newMetricThresholds) {
    return putResource({
        resourceType: 'metricThresholds',
        requestId: 'putMetricThresholds',
        sendRequest: () => {
            return sendPutRequest('/api/v1/alerts/metric-thresholds', {
                body: { ...newMetricThresholds },
            });
        },
    });
}

export default putMetricThresholds;
