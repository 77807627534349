import React, { useMemo, useState } from 'react';
import useBounds from 'Common/hooks/useBounds';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslator } from 'Common/hooks/useTranslation';
import { FAILURE } from 'Common/data/RequestStatus';
import useKPIGroupWidgetController from './internal/useKPIGroupWidgetController';
import KPIGroupWidgetLiveControls from './KPIGroupWidgetLiveControls';
import WidgetFrame from 'Common/components/WidgetFrame/WidgetFrame';
import FrameHeader from 'Common/components/WidgetFrame/FrameHeader';
import WidgetValidationErrorMessage from 'Common/components/WidgetFrame/WidgetValidationErrorMessage';
import WidgetLoadingIndicator from 'Common/components/WidgetFrame/WidgetLoadingIndicator';
import NoDataMessage from 'Common/components/NoDataMessage';
import KPIGroupContent from 'Common/components/widgets/KPIGroupWidget/KPIGroupContent';
import KPIGroupHeading from 'Common/components/widgets/KPIGroupWidget/KPIGroupHeading';
import EventDataRow from 'Common/components/EventData/EventDataRow';
import EventDataCell from 'Common/components/EventData/EventDataCell';
import { DIMENSION_VISUALIZATION_FORMAT_TYPES } from 'Common/components/SparkVisualization/SparkDimensionConstants';
import usePersistWidgetHeight from 'Common/hooks/usePersistWidgetHeight';
import RequestState from 'Common/types/RequestState/RequestState';
import KPIGroupFormattedDatum from './internal/KPIGroupFormattedDatum';
import CategoryValue from 'Common/components/FormattedDatum/CategoryValue/CategoryValue';
import { FIELD_TYPE, HEADING_TYPE } from './KPIGroupWidgetConstants';

const KPIGroupContainer = styled.div`
    padding: 4px 12px 12px 12px;
`;

const DataCellContentContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    text-align: right;
`;

const AssetLabelContainer = styled.div`
    padding-left: 15px;
`;

const LoadingContainer = styled.div.attrs(({ $minHeight }) => {
    if ($minHeight) {
        return {
            style: { minHeight: `${$minHeight}px` }
        };
    }
})``;

const calculateSparkVisualizationWidth = (containerWidth) => {
    const defaultMaxWidth = 150;

    return containerWidth === undefined || isNaN(containerWidth)
        ? defaultMaxWidth
        : Math.min(defaultMaxWidth, containerWidth);
};

export default function KPIGroupWidget({
    actions,
    alwaysShowAsset,
    areLiveControlsVisible,
    dataService,
    dragHandleProps,
    explorationPath,
    isDragEnabled,
    isLayoutModeActive,
    isPrint,
    mutable,
    printConfig,
    reportHierarchyNode,
    timeRange: pageTimeRange,
    widgetState,
    widgetStf,
    suppressedControls,
    suppressDelete,
}) {

    const translator = useTranslator();
    const noColor = isPrint && printConfig.noColor;

    const {
        defaultTitle,
        handleTitleChange,
        title,

        dataRequest,

        filterOptions,
        filters,
        setSliceFilters,
        formattedFilters,

        followPageTimeRange,
        setTimeRange,
        widgetTimeRange,
        timeRangeDescription,

        followAppBarHierarchyNode,
        hierarchyNode,
        hierarchyNodeOptions,
        setHierarchyNode,

        columns,
        setColumns,
        columnOptions,

        isInitialized,

        supportsLiveControls,
        toggleShowLiveControls,
    } = useKPIGroupWidgetController({
        areLiveControlsVisible,
        dataService,
        isPrint,
        pageExplorationPath: explorationPath,
        pageTimeRange,
        reportHierarchyNode,
        mutable,
        setWidgetLiveControlsVisibility: actions.setWidgetLiveControlsVisibility,
        setWidgetPrintStatus: actions.setWidgetPrintStatus,
        setWidgetState: actions.setWidgetState,
        setWidgetStf: actions.setWidgetStf,
        widgetStf,
        widgetState,
    });

    const {
        widgetContainerRef,
        lastWidgetHeight
    } = usePersistWidgetHeight(dataRequest?.status);

    const [dataCellContentContainer, setDataCellContentContainer] = useState(null);
    const dataCellContentContainerBounds = useBounds(dataCellContentContainer);
    const dataCellContentContainerWidth = dataCellContentContainerBounds?.width;

    const contentElement = useMemo(() => {
        if (!RequestState.isRequestCompleted(dataRequest)) {
            return (
                <LoadingContainer $minHeight={lastWidgetHeight}>
                    <WidgetLoadingIndicator />
                </LoadingContainer>
            );
        }
        else if (dataRequest?.status === FAILURE) {
            return (
                <WidgetValidationErrorMessage error={dataRequest.error}/>
            );
        }
        else {
            const contents = dataRequest.response.contents;
            const hasFieldsOrHeadings = !!contents.find(row => row.type === FIELD_TYPE || row.type === HEADING_TYPE);
            if (hasFieldsOrHeadings) {
                const data = dataRequest.response.data;
                const rowElements = contents.reduce((acc, row) => {
                    if (row.type === HEADING_TYPE) {
                        const displayName = row.heading?.displayName || translator(row.heading?.displayKey || '');
                        acc.push(
                            <EventDataRow key={acc.length}>
                                <EventDataCell colspan={2}>
                                    <KPIGroupHeading title={displayName} noColor={noColor}>
                                        {displayName}
                                    </KPIGroupHeading>
                                </EventDataCell>
                            </EventDataRow>
                        );
                    }
                    else if (row.type === FIELD_TYPE) {
                        const dataItem = data?.[row.field.name];
                        const label = dataItem?.displayName || '';
                        const formatType = dataItem?.formatType;
                        const maxElements = row.field?.dimensionVisualization?.maxElementsShown;
                        const cellHeight = 24;
                        const dataCellWidth = calculateSparkVisualizationWidth(dataCellContentContainerWidth);

                        // we need to handle having a row for each asset of the chronogram
                        if (formatType === DIMENSION_VISUALIZATION_FORMAT_TYPES.CHRONOGRAM_FORMAT_TYPE) {
                            // when we have multiple assets we need one per row
                            if (dataItem?.values?.length > 1) {
                                // put a title for the dimension
                                acc.push(
                                    <EventDataRow key={acc.length}>
                                        <EventDataCell>
                                            <span title={label}>
                                                {label}
                                            </span>
                                        </EventDataCell>
                                    </EventDataRow>
                                );
                                
                                dataItem.values.forEach(assetData => {
                                    const finalDataItem = {
                                        ...dataItem,
                                        values: assetData.values
                                    };

                                    const dataCellContent = dataItem ? (
                                        <DataCellContentContainer ref={setDataCellContentContainer}>
                                            <KPIGroupFormattedDatum
                                                data={finalDataItem}
                                                validationError={assetData.validationError}
                                                error={assetData.error}
                                                noColor={noColor}
                                                width={dataCellWidth}
                                                height={cellHeight}
                                                maxElements={maxElements}
                                            />
                                        </DataCellContentContainer>
                                    ) : null;
            
                                    acc.push(
                                        <EventDataRow key={acc.length}>
                                            <EventDataCell>
                                                <AssetLabelContainer>
                                                    <CategoryValue
                                                        data={assetData.asset}
                                                        error={assetData.error}
                                                        noColor={noColor}
                                                    />
                                                </AssetLabelContainer>
                                            </EventDataCell>
                                            <EventDataCell alignRight>
                                                {dataCellContent} 
                                            </EventDataCell>
                                        </EventDataRow>
                                    );
                                });

                            }
                            // if were only showing one put it a single row
                            else {
                                let finalDataItem = null;
                                let finalDataItemError;
                                let finalDataItemValidationError;
                                if (dataItem) {
                                    let newValues = [];
                                    if (dataItem.values.length) {
                                        newValues = dataItem.values[0].values;
                                        finalDataItemError = dataItem.values[0].error;
                                        finalDataItemValidationError = dataItem.values[0].validationError;
                                    }
                                    finalDataItem = {
                                        ...dataItem,
                                        values: newValues
                                    };
                                }

                                const dataCellContent = finalDataItem ? (
                                    <DataCellContentContainer ref={setDataCellContentContainer}>
                                        <KPIGroupFormattedDatum
                                            data={finalDataItem}
                                            error={finalDataItemError}
                                            validationError={finalDataItemValidationError}
                                            noColor={noColor}
                                            width={dataCellWidth}
                                            height={cellHeight}
                                            maxElements={maxElements}
                                        />
                                    </DataCellContentContainer>
                                ) : null;
        
                                acc.push(
                                    <EventDataRow key={acc.length}>
                                        <EventDataCell>
                                            <span title={label}>
                                                {label}
                                            </span>
                                        </EventDataCell>
                                        <EventDataCell alignRight>
                                            {dataCellContent} 
                                        </EventDataCell>
                                    </EventDataRow>
                                );
                            }
                        }
                        else {
                            const dataCellContent = dataItem ? (
                                <DataCellContentContainer ref={setDataCellContentContainer}>
                                    <KPIGroupFormattedDatum
                                        data={dataItem}
                                        error={dataItem.error}
                                        validationError={dataItem.validationError}
                                        noColor={noColor}
                                        width={dataCellWidth}
                                        height={cellHeight}
                                        maxElements={maxElements}
                                    />
                                </DataCellContentContainer>
                            ) : null;
    
                            acc.push(
                                <EventDataRow key={acc.length}>
                                    <EventDataCell>
                                        <span title={label}>
                                            {label}
                                        </span>
                                    </EventDataCell>
                                    <EventDataCell alignRight>
                                        {dataCellContent} 
                                    </EventDataCell>
                                </EventDataRow>
                            );
                        }
                    }
                    else {
                        // Any other row type can just be left a blank row
                        acc.push(
                            <EventDataRow key={acc.length}>
                                <EventDataCell colspan={2} />
                            </EventDataRow>
                        );
                    }
                    return acc;
                }, []);
                return (
                    <KPIGroupContainer ref={widgetContainerRef}>
                        <KPIGroupContent isPrint={isPrint} >{rowElements}</KPIGroupContent>
                    </KPIGroupContainer>
                );
            }
            else {
                return (
                    <KPIGroupContainer ref={widgetContainerRef}>
                        <NoDataMessage
                            title={translator('widget.kpi_group.no_rows_title')}
                            message={translator('widget.kpi_group.no_rows_message')}
                        />
                    </KPIGroupContainer>
                );
            }
        }
    }, [dataRequest, noColor, translator, isPrint, lastWidgetHeight, widgetContainerRef, dataCellContentContainerWidth]);

    return (
        <WidgetFrame
            isPrint={isPrint}
            frameHeader={(
                <FrameHeader
                    hierarchyNodes={hierarchyNodeOptions}
                    hierarchyNodeValue={hierarchyNode || reportHierarchyNode?.name}
                    canEditTitle={areLiveControlsVisible && !isPrint && mutable}
                    defaultTitleText={defaultTitle}
                    deleteOnClick={actions.deleteWidget}
                    dragHandleProps={dragHandleProps}
                    formattedFilters={formattedFilters}
                    isDragEnabled={isDragEnabled}
                    isPrint={isPrint}
                    isSectionFrameTitle={false}
                    showAsset={alwaysShowAsset || !followAppBarHierarchyNode}
                    showDelete={isLayoutModeActive && !isPrint && !suppressDelete}
                    shouldShowFilterText={true}
                    shouldShowLiveControls={areLiveControlsVisible}
                    showLiveControlsButton={supportsLiveControls}
                    showLiveControlsOnClick={toggleShowLiveControls}
                    timeRangeDescription={timeRangeDescription}
                    titleOnChange={handleTitleChange}
                    titleValue={title}
                />
            )}
            liveControls={isInitialized && mutable && areLiveControlsVisible && (
                <KPIGroupWidgetLiveControls
                    followPageTimeRange={followPageTimeRange}
                    timeRange={widgetTimeRange}
                    setTimeRange={setTimeRange}

                    followAppBarHierarchyNode={followAppBarHierarchyNode}
                    hierarchyNode={hierarchyNode}
                    setHierarchyNode={setHierarchyNode}
                    hierarchyNodeOptions={hierarchyNodeOptions}

                    filters={filters}
                    setSliceFilters={setSliceFilters}
                    filterOptions={filterOptions}

                    columns={columns}
                    setColumns={setColumns}
                    columnOptions={columnOptions}

                    isLayoutModeActive={isLayoutModeActive}
                    duplicateWidget={actions.duplicateWidget}
                    suppressedControls={suppressedControls}
                />
            )}
        >
            {contentElement}
        </WidgetFrame>
    );
}

KPIGroupWidget.propTypes = {
    widgetStf: PropTypes.object.isRequired,
    widgetState: PropTypes.object,
    alwaysShowAsset: PropTypes.bool,
    areLiveControlsVisible: PropTypes.bool.isRequired,
    suppressedControls: PropTypes.object,
    suppressDelete: PropTypes.bool,

    actions: PropTypes.shape({
        setWidgetStf: PropTypes.func.isRequired,
        setWidgetState: PropTypes.func.isRequired,
        setWidgetPrintStatus: PropTypes.func.isRequired,
        setWidgetExportItem: PropTypes.func.isRequired,
        deleteWidget: PropTypes.func.isRequired,
        duplicateWidget: PropTypes.func.isRequired,
        setWidgetLiveControlsVisibility: PropTypes.func.isRequired,
    }).isRequired,

    timeRange: PropTypes.object,
    explorationPath: PropTypes.object,
    reportHierarchyNode: PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string
    }),

    mutable: PropTypes.bool.isRequired,
    dragHandleProps: PropTypes.object,
    isDragEnabled: PropTypes.bool.isRequired,
    isLayoutModeActive: PropTypes.bool.isRequired,
    isPrint: PropTypes.bool.isRequired,
    printConfig: PropTypes.object,

    dataService: PropTypes.shape({
        getColumnConfig: PropTypes.func.isRequired,
        getTimeRangePrimaryDetails: PropTypes.func.isRequired,
        getTimeRangeSecondaryDetails: PropTypes.func.isRequired,
    }).isRequired,
};
