import { connect } from 'react-redux';
import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import LoadingPage from 'Components/pages/loading/LoadingPage';
import PropTypes from 'prop-types';
import { selectIsInitialized } from 'Redux/reducers/session';

const InitializationRequiredRoute = ({ component: Component, isInitialized, ...otherProps }) => {
    return (
        <Route
            {...otherProps}
            render={(innerProps) => {
                if (isInitialized) {
                    return (
                        <Component {...innerProps} />
                    );
                }
                else {
                    return (
                        <LoadingPage />
                    );
                }
            }}
        />
    );
};

InitializationRequiredRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isInitialized: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    const { session } = state;
    return {
        isInitialized: selectIsInitialized(session),
    };
};

export default withRouter(connect(mapStateToProps)(InitializationRequiredRoute));
