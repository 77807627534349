import React from 'react';
import PropTypes from 'prop-types';
import Form from 'Components/form/Form';
import AlertsEditor from 'Components/pages/alerts-edit/AlertsEditor';
import validate from 'Components/pages/alerts-edit/AlertsEditorValidation';
import isEqual from 'lodash/isEqual';
import * as RequestStatus from 'Common/data/RequestStatus';
import { isValidationValid } from 'Components/util/validationUtils';

function _secondsToMinutes(seconds) {
    return Math.floor(seconds / 60);
}

function _minutesToSeconds(minutes) {
    return Math.floor(minutes * 60);
}

const _timeBasedAlertTypes = ['process_state_duration', 'process_state_target_time'];

// The server store the thresholds in seconds and that's how the form receives
// it. But the editors want to just handle the thresholds as minutes, so we
// convert the seconds to minute for all the thresholds.
//
function _cleanUpDataForState(data) {
    let cleanedUpData = {};

    Object.keys(data).forEach((alertType) => {
        cleanedUpData[alertType] = data[alertType].map((alert) => {
            let cleanedUpAlert = { ...alert };

            if (_timeBasedAlertTypes.includes(alertType)) {
                cleanedUpAlert.threshold = _secondsToMinutes(alert.threshold);
            }
            else {
                // Convert from a 0.0-1.0 value to an integer percentage.
                cleanedUpAlert.threshold = Math.round(alert.threshold * 100.0);
            }

            return cleanedUpAlert;
        });
    });

    return cleanedUpData;
}

function _cleanUpDataForSubmit(data) {
    const cleanedUpData = {};

    Object.keys(data).forEach((alertType) => {
        cleanedUpData[alertType] = data[alertType].map((alert) => {
            let cleanedUpAlert = { ...alert };

            // Newly added alerts are given a temp id we must remove
            if (cleanedUpAlert.temp_id !== undefined) {
                delete cleanedUpAlert.temp_id;
                cleanedUpAlert.id = null;
            }

            // Make sure threshold values get converted back to Numbers. They get
            // turned into strings when they are edited.
            //
            cleanedUpAlert.threshold = Number(alert.threshold);

            // For time-based alert types we want to edit the values as minutes, but
            // submit them as seconds.
            //
            if (_timeBasedAlertTypes.includes(alertType)) {
                cleanedUpAlert.threshold = _minutesToSeconds(cleanedUpAlert.threshold);
            }
            else
            {
                // Convert from a percentage to a 0.0-1.0 value.
                cleanedUpAlert.threshold /= 100.0;
            }

            return cleanedUpAlert;
        });
    });

    return cleanedUpData;
}

export default class EditAlertsForm extends React.Component {
    constructor(props) {
        super(props);

        const initialData = _cleanUpDataForState(props.data);

        this.state = {
            modifiedData: initialData,
            initialData: initialData,
            lastPatchStatus: props?.requests?.patchAlerts?.status,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const lastPatchStatus = state.lastPatchStatus;
        const nextPatchStatus = props?.requests?.patchAlerts?.status;

        if (lastPatchStatus !== nextPatchStatus) {
            let nextModifiedData = state.modifiedData;
            let nextInitialData = state.initialData;

            // Watch for when patchAlerts changes from PENDING to SUCCESS and
            // reset the data
            if (lastPatchStatus === RequestStatus.PENDING &&
                nextPatchStatus === RequestStatus.SUCCESS)
            {
                nextModifiedData = _cleanUpDataForState(props.data);
                nextInitialData = nextModifiedData;
            }

            return {
                modifiedData: nextModifiedData,
                initialData: nextInitialData,
                lastPatchStatus: nextPatchStatus,
            };
        }

        return null; // No changes
    }

    _handleSubmit = (event) => {
        // Perform any data cleanup here
        const { modifiedData } = this.state;
        const cleanedUpData = _cleanUpDataForSubmit(modifiedData);

        this.props.onSubmit({
            target: {
                name: this.props.name,
                value: cleanedUpData,
            },
        });
    };

    _handleChange = (event) => {
        const { value } = event.target;

        this.setState({
            modifiedData: value,
        });
    };

    _handleReset = (event) => {
        this.setState({
            modifiedData: this.state.initialData,
        });
    };

    render() {
        const { requests } = this.props;
        const { modifiedData, initialData } = this.state;

        // Compare the submission formatted data to determine if there are changes.
        const hasChanges = !isEqual(
            _cleanUpDataForSubmit(initialData),
            _cleanUpDataForSubmit(modifiedData)
        );

        const validation = validate(modifiedData);
        const isValid = isValidationValid(validation);

        const isLoading = requests.patchAlerts.status === RequestStatus.PENDING;

        const allowEdit = !isLoading;
        const allowSubmit = !isLoading && isValid && hasChanges;
        const allowCancel = !isLoading && hasChanges;

        return (
            <Form
                error={requests?.patchAlerts?.error}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <AlertsEditor
                    value={modifiedData}
                    onChange={this._handleChange}
                    validation={validation}
                    allowEdit={allowEdit}
                />
            </Form>
        );
    }
}

EditAlertsForm.propTypes = {
    name: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    // There may be more fields in here than what's below, but this is just
    // what's required by this component
    data: PropTypes.object.isRequired,
    requests: PropTypes.shape({
        patchAlerts: PropTypes.shape({
            status: PropTypes.string.isRequired,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
};

EditAlertsForm.defaultProps = {
    name: '',
};
