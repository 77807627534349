import { isDate } from 'lodash';
import moment from 'moment';

const DAYS = 'days';
const HOURS = 'hours';
const MINUTES = 'minutes';
const SECONDS = 'seconds';

// We used to base our device-side dates on 1900. Now it's 1970.
const OLD_BASE_DATE = new Date(Date.parse("January 1, 1900"));
const BASE_DATE = new Date(0);

// Seconds and milliseconds per hour.
export const S_PER_HOUR = 60 * 60;
export const MS_PER_HOUR = S_PER_HOUR * 1000;

// Seconds and milliseconds per day.
export const S_PER_DAY = S_PER_HOUR * 24;
export const MS_PER_DAY = S_PER_DAY * 1000;

// Julian Day of 1/1/1970.
export const EPOCH_JULIAN_DAY = 2440588;

function add(dt, portion, value) {
    return moment(dt).add(value, portion).toDate();
}

export function addSeconds (dt, value) { return add(dt, SECONDS,  value); }
export function addMinutes (dt, value) { return add(dt, MINUTES,  value); }
export function addHours   (dt, value) { return add(dt, HOURS,    value); }
export function addDays    (dt, value) { return add(dt, DAYS,     value); }

       function subtractMinutes (dt, value) { return addMinutes (dt, -value); }
export function subtractHours   (dt, value) { return addHours   (dt, -value); }
export function subtractDays    (dt, value) { return addDays    (dt, -value); }

// Is the given year a leap year? Expects a four digit year.
function isLeapYear(year) {
    return (year % 400 === 0) || (year % 4 === 0 && year % 100 !== 0);
}

export function fromEpochSeconds(s) {
    const dt = new Date(BASE_DATE.getTime() + s * 1000);
    return dt;
}

export function from1900EpochSeconds(s) {
    const dt = new Date(OLD_BASE_DATE.getTime() + s * 1000);
    return dt;
}

export function toEpochSeconds(date) {
     return date.getTime() / 1000;
}

export function toEpochDays(date) {
    return Math.floor(toEpochSeconds(date) / (24 * 3600));
}

export function dateToJSON(dt, opts) {
    opts = opts || {};

    if (opts.relative) {
        dt = subtractMinutes(dt, dt.getTimezoneOffset());
    }

    let rv = dt.toJSON(),
        parts = rv.split('T'),
        stripZ = opts.relative;

    if (opts.selector === 'date') {
        rv = parts[0];
        stripZ = false;
    }
    else if (opts.selector === 'time') {
        rv = parts[1];
        stripZ = true;
    }

    if (stripZ) {
        rv = rv.slice(0, rv.length - 1);
    }

    return rv;
}

export function isDateValid(date) {
    return isDate(date) && !isNaN(date.getTime());
}

/**
 * zero based month (0 = January)
 */
export function getDaysInMonth(year, month) {
    const days = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return days[month];
}

/**
 * compute the Julian day for a given year, month, and day of month. Computation will not
 * match Gregorian calendar before 1582-10-15 so beware.
 *
 * ref: http://en.wikipedia.org/wiki/Julian_day#Converting_Julian_or_Gregorian_calendar_date_to_Julian_Day_Number
 */
export function toJulianDay(year, month, day) {
    if (year instanceof Date) {
        return toJulianDay(year.getFullYear(), year.getMonth() + 1, year.getDate());
    }
    return moment.utc([year, month-1, day]).diff(moment.utc([1970]), 'days') + EPOCH_JULIAN_DAY;
}

/**
 * converts a Julian Number to an array of Year, Month, and Day
 * Months are 1-12 - not zero based like JavaScript Date
 */
export function fromJulianDay(J) {
    const details = moment.utc([1970]).add(J - EPOCH_JULIAN_DAY, 'days').toArray();
    details[1]++;
    return details.slice(0,3);
}

export function addToJulianDay(julianDay, portion, value) {
    let details = fromJulianDay(julianDay);
    details[1]--;
    details = moment.utc(details).add(value, portion).toArray();
    details[1]++;
    return toJulianDay.apply(null, (details.slice(0,3)));
}

/**
 * convert a Julian number to day of week. Zero is Sunday.
 *
 * @param {Number} julianNumber (see http://en.wikipedia.org/wiki/Julian_day)
 *
 * @ref https://en.wikipedia.org/wiki/Julian_day#Finding_day_of_week_given_Julian_day_number
 *
 */
export function getJulianDayOfWeek(julianNumber) {
    return (julianNumber + 1) % 7;
}

/**
 * Converts a JS Date to a filename-friendly string
 *
 * @param {Date} the date to convert
 */
export function dateToFilenameString(date) {
    return moment(date).format('YYYY-MM-DD-HHmm');
}

export function clearTime(date) {
    if (!isDateValid(date)) {
        throw new Error('Not a valid date');
    }

    const year = date.getFullYear(),
        month = date.getMonth(),
        day = date.getDate();

    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    date.setFullYear(year);
    date.setMonth(month);
    date.setDate(day);

    return date;
}

function compareDates(l, r) {
    l = l || new Date(r.getTime() - 1);
    r = r || new Date(l.getTime() - 1);

    return l.getTime() - r.getTime();
}

export function areDatesEqual(l, r) {
    return compareDates(l, r) === 0;
}

/**
 * gets the week number associated with this date
 * @param {Date} dt the date to get the week number for
 * @param Number startDOW the day of week to use as week start 0=Sunday
 */
export function getWeekNumber(dt) {
    return moment(dt).isoWeek();
}

export function getQuarter(d) {
    const q = [ 1, 2, 3, 4];
    return q[Math.floor(d.getMonth() / 3)];
}

const DAYS_OF_WEEK = Object.freeze([
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
]);

export function getDayOfWeekForDay(dayNumber) {
    let rv = DAYS_OF_WEEK[dayNumber];

    if (rv) {
        return rv;
    }
    else {
        throw new Error(`Invalid day number: ${dayNumber}`);
    }
}

export const DAYS_OF_WEEK_NUMBERS = Object.freeze({
    sunday:    0,
    monday:    1,
    tuesday:   2,
    wednesday: 3,
    thursday:  4,
    friday:    5,
    saturday:  6
});

export function getDayOfWeekIndexForDayOfWeek(dayName) {
    let rv = DAYS_OF_WEEK_NUMBERS[dayName];

    if (Number.isFinite(rv)) {
        return rv;
    }
    else {
        throw new Error(`Invalid day of week: ${dayName}`);
    }
}

// http://stackoverflow.com/questions/542938/how-do-i-get-the-number-of-days-between-two-dates-in-javascript
// JavaScript date calculations are tricky because Date objects store times internally in UTC, not local time. For example,
// 3/10/2013 12:00 AM Pacific Standard Time (UTC-08:00) is stored as 3/10/2013 8:00 AM UTC, and
// 3/11/2013 12:00 AM Pacific Daylight Time (UTC-07:00) is stored as 3/11/2013 7:00 AM UTC. On this day, midnight to
// midnight local time is only 23 hours in UTC!
//
// Although a day in local time can have more or less than 24 hours, a day in UTC is always exactly 24 hours (JavaScript
// ignores leap seconds). The _daysBetween method takes advantage of this fact by first calling _treatAsUTC to adjust both
// local times to midnight UTC, before subtracting and dividing.
//
export function daysBetween(startDate, endDate) {
    const julianStart = toJulianDay(startDate),
        julianEnd = toJulianDay(endDate);

    return julianEnd - julianStart;
}

/**
 * Get the seconds between two Dates.
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Number} Positive if startDate is before endDate, otherwise negative.
 */
export function secondsBetween(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    // `diff` returns milliseconds, so convert it.
    return end.diff(start) / 1000;
}
