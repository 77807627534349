import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TextInputField from 'Components/text-input/TextInputField';
import { parseTime, format24HourTime, formatLocaleTime } from 'Components/util/timeUtils';

// Let's avoid having to do this more than once
const timeInputSupported = (() => {
    let input = document.createElement('input');

    const value = 'abcdefghijklmnop';
    input.setAttribute('type', 'time');
    input.setAttribute('value', value);

    // the time input does not allow for non-time input, so the value
    // it reports should not equal the value we gave it. If it fell
    // back to a plain 'ol text input they will be equal and this
    // check will fail.
    //
    return (input.value !== value);
})();

const StyledInput = styled(TextInputField)`
    /* These arrows in Chrome mess with the height of the input field.
       The arrows do not exist in any other browser anyway, so it should
       be fine to remove them entirely. See the chromium bug for this
       here: https://bugs.chromium.org/p/chromium/issues/detail?id=417606
    */
    &::-webkit-inner-spin-button {
        display: none;
    }
`;

export default class TimeInput extends React.Component {
    static defaultProps = {
        onChange: () => { },
        onBlur: () => { },
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            lastPropValue: undefined, // initialized in getDerivedStateFromProps
            internalValue: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
        const derived = {
            lastPropValue: props.value,
        };

        // Only update the internal value if the prop changes and the time
        // value from the props is different from what the user has entered

        if (state.lastPropValue !== props.value) {
            const parsedValue = parseTime(props.value);

            if (parsedValue.isValid()) {
                const parsedInternalValue = parseTime(state.internalValue);
                const formattedInternalValue = parsedInternalValue.isValid() ? format24HourTime(parsedInternalValue) : '';

                const formattedValue = format24HourTime(parsedValue);

                // Check if the prop is different from the internal
                if (formattedValue !== formattedInternalValue) {
                    derived.internalValue = formatLocaleTime(parsedValue);
                }
            }
        }

        return derived;
    }

    _handleChange = (event) => {
        const value = event.target.value;

        this.setState({
            internalValue: value,
        });

        let normalizedValue = '';

        const parsedValue = parseTime(value);

        if (parsedValue.isValid()) {
            normalizedValue = format24HourTime(parsedValue);
        }

        // Only fire change if the normalized value is actually changing
        if (normalizedValue !== this.props.value) {
            return this.props.onChange({
                target: {
                    name: this.props.name,
                    value: normalizedValue,
                },
            });
        }
    };

    _handleBlur = (event) => {
        let normalizedValue = '';
        const parsedValue = parseTime(this.state.internalValue);

        // When blurring, set the internal value to the cleanly formatted time.
        // We only do this if the time is valid, so there is no need to fire
        // an `onChange`, because we aren't actually changing the time.
        if (parsedValue.isValid()) {
            normalizedValue = format24HourTime(parsedValue);
            this.setState({
                internalValue: formatLocaleTime(parsedValue),
            });
        }

        event.target = {
            name: this.props.name,
            value: normalizedValue,
        };

        this.props.onBlur(event);
    };

    render() {
        const { internalValue } = this.state;
        const { value, name, onChange, onBlur, ...otherProps } = this.props;

        let stringValue = '';

        if (timeInputSupported) {
            let parsedValue = parseTime(internalValue);

            if (parsedValue.isValid()) {
                // HTML5 time input expects 24 hour time
                stringValue = format24HourTime(parsedValue);
            }
            else {
                stringValue = '';
            }
        }
        else {
            stringValue = internalValue;
        }

        return (
            <StyledInput
                {...otherProps}
                autoComplete="off"
                placeholder={formatLocaleTime(parseTime('12:00'))}
                type={timeInputSupported ? 'time' : 'text'}
                value={stringValue}
                name={name}
                onChange={this._handleChange}
                onBlur={this._handleBlur}
            />
        );
    }
}
