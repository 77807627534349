import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Subheading5 } from '../typography';

const Root = styled.div`
    padding-top: 5px;
    padding-bottom: 5px;
    padding: 0;
`;

const Label = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const LabelText = styled(Subheading5)`
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
`;

export default function SelectOptionGroup(props) {
    const { label, children, ...otherProps } = props;

    return (
        React.Children.count(children) > 0
            ? <Root {...otherProps}>
                <Label>
                    <LabelText>{label}</LabelText>
                </Label>
                <div>
                    {children}
                </div>
            </Root>
            : null
    );
}

SelectOptionGroup.propTypes = {
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};
