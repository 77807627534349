import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CategorySelectList from '.';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import SelectDropdownButton from 'Common/components/SelectList/SelectDropdownButton';
import { useTranslator } from 'Common/hooks/useTranslation';

/**
 * Drop-In SelectField for CategorySelectLists which wraps CategorySelectList in a dropdown
 * with internally managed open/close state.
 */
function CategorySelectField({
    name,
    options,
    selectedItem,
    onChange,
    disabled,
    collapsibleGroups,
    showFlattenCheckbox,
    placeholderText,
}) {
    const translator = useTranslator();
    const [isOpen, setIsOpen] = useState(false);

    const openHandler = () => {
        setIsOpen(true);
    };

    const closeHandler = () => {
        setIsOpen(false);
    };

    const onChangeHandler = (event) => {
        closeHandler();
        onChange(event);
    };

    const renderContent = () => {
        return (
            <CategorySelectList
                name={name}
                options={options}
                value={{
                    name: selectedItem.name,
                    channel: selectedItem.channel,
                }}
                onChange={onChangeHandler}
                collapsibleGroups={collapsibleGroups}
                showFlattenCheckbox={showFlattenCheckbox}
            />
        );
    };

    const itemName = selectedItem.displayKey ? translator(selectedItem.displayKey) : selectedItem.displayName;

    return (
        <Dropdown
            isOpen={isOpen}
            onMaskClick={closeHandler}
            renderContent={renderContent}
        >
            <SelectDropdownButton
                style={{width: '100%'}}
                disabled={disabled}
                onClick={openHandler}
                isPlaceholderText={!itemName}
            >
                {itemName || placeholderText || ''}
            </SelectDropdownButton>
        </Dropdown>
    );
}

CategorySelectField.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedItem: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFlattenCheckbox: PropTypes.bool,
    collapsibleGroups: PropTypes.bool,
    placeholderText: PropTypes.string,
};

CategorySelectField.defaultProps = {
    name: 'CategorySelectField',
    disabled: false,
    placeholderText: '',
};

export default CategorySelectField;
