import React from 'react';
import PropTypes from 'prop-types';

import * as RequestStatus from 'Common/data/RequestStatus';
import PageFrame from 'Components/page/PageFrame';
import { smsEmailDescription } from './sectionDescriptions';
import { generateSmsEmailAddressPatch } from 'Redux/actions';
import { smsEmailAddressValidator } from './SessionUserEditorValidation';
import SmsEmailEditor from './SmsEmailEditor';

function getInitialStateFromProps(props) {
    return {
        initialValue: props.initialValue,
        modifiedValue: props.initialValue,
        serverError: null,
        verifySmsEmailResendSuccess: false,
    };
}

export default class SmsEmailForm extends React.Component {
    static propTypes = {
        initialValue: PropTypes.shape({
            email_address: PropTypes.object.isRequired,
            sms_email_address: PropTypes.object, // can be null
        }).isRequired,
        requests: PropTypes.shape({
            patchSessionUserSmsEmail: PropTypes.shape({
                status: PropTypes.string.isRequired,
                error: PropTypes.object,
            }).isRequired,
            verifySmsEmailResend: PropTypes.shape({
                status: PropTypes.string.isRequired,
                error: PropTypes.object,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            patchSessionUserSmsEmail: PropTypes.func.isRequired,
            patchSessionUserSmsEmailReset: PropTypes.func.isRequired,
            verifySmsEmailResend: PropTypes.func.isRequired,
            verifySmsEmailResendReset: PropTypes.func.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        disabled: false,
        name: '',
        validation: null,
    };

    constructor(props) {
        super(props);
        this.state = getInitialStateFromProps(props);
    }

    componentDidMount() {
        this.props.actions.patchSessionUserSmsEmailReset();
        this.props.actions.verifySmsEmailResendReset();
    }

    _handleChange = (event) => {
        const { name: changedName, value: changedValue } = event.target;

        this.setState((state) => {
            return {
                modifiedValue: {
                    ...state.modifiedValue,
                    [changedName]: changedValue,
                },
            };
        });
    };

    _handleSubmit = async (smsEmailAddressValue) => {
        // Start by clearing any existing server error or success messages
        this.setState({
            serverError: null,
            verifySmsEmailResendSuccess: false,
        });

        let payload = {};

        const initialEmailValue = this.state.initialValue.sms_email_address;

        const emailPatch = generateSmsEmailAddressPatch(initialEmailValue, smsEmailAddressValue);

        if (emailPatch !== undefined) {
            payload.sms_email_address = emailPatch;
        }

        await this.props.actions.patchSessionUserSmsEmail(payload);

        if (this.props.requests.patchSessionUserSmsEmail.status === RequestStatus.SUCCESS) {
            // Reset the form after submit
            const nextInitialValue = this.props.initialValue;

            this.setState(prevState => {
                return {
                    initialValue: nextInitialValue,
                    modifiedValue: nextInitialValue,
                    serverError: null,
                };
            });
        }
        else if (this.props.requests.patchSessionUserSmsEmail.status === RequestStatus.FAILURE) {
            this.setState(prevState => {
                return {
                    serverError: this.props.requests.patchSessionUserSmsEmail.error,
                };
            });
        }
    };

    _handleDeleteCurrentClick = () => {
        this._handleSubmit(null);
    };

    _handleCancelVerificationClick = () => {
        this._handleSubmit(null);
    };

    _handleResendVerificationClick = async () => {
        // Start by clearing any existing server error or success messages
        this.setState({
            serverError: null,
            verifySmsEmailResendSuccess: false,
        });

        // Send the request
        await this.props.actions.verifySmsEmailResend({
            email: this.state.initialValue.sms_email_address.email,
        });

        if (this.props.requests.verifySmsEmailResend.status === RequestStatus.SUCCESS) {
            this.setState(prevState => {
                return {
                    serverError: null,
                    verifySmsEmailResendSuccess: true,
                };
            });
        }
        else if (this.props.requests.verifySmsEmailResend.status === RequestStatus.FAILURE) {
            this.setState(prevState => {
                return {
                    serverError: this.props.requests.verifySmsEmailResend.error,
                    verifySmsEmailResendSuccess: false,
                };
            });
        }
    };

    _handleReset = () => {
        this.setState(getInitialStateFromProps(this.props));
    };

    render() {
        const { requests } = this.props;
        const { modifiedValue, serverError, verifySmsEmailResendSuccess } = this.state;
        const validation = smsEmailAddressValidator(modifiedValue);

        const {
            sms_email_address,
        } = modifiedValue;

        const isLoading = (
            requests.patchSessionUserSmsEmail.status === RequestStatus.PENDING ||
            requests.verifySmsEmailResend.status === RequestStatus.PENDING
        );
        const topLevelErrorMessage = serverError?.message;
        const emailErrorMessages = serverError?.payload?.sms_email_address?.email;

        return (
            <PageFrame title="Text-Only Email">
                {smsEmailDescription}
                <SmsEmailEditor
                    value={sms_email_address}
                    name="sms_email_address"
                    disabled={isLoading}
                    onChange={this._handleChange}
                    validation={validation?.sms_email_address}
                    onSubmit={this._handleSubmit}
                    onDeleteCurrentClick={this._handleDeleteCurrentClick}
                    onCancelVerificationClick={this._handleCancelVerificationClick}
                    onResendVerificationClick={this._handleResendVerificationClick}
                    onReset={this._handleReset}
                    error={serverError && (
                        <React.Fragment>
                            {topLevelErrorMessage}
                            {emailErrorMessages?.map?.((msg, i) => (<li key={i}>{msg}</li>))}
                        </React.Fragment>
                    )}
                    success={verifySmsEmailResendSuccess && (
                        <React.Fragment>
                            Verification email resent.
                        </React.Fragment>
                    )}
                />
            </PageFrame>
        );
    }
}
