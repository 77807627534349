import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ThemedTableColumn = styled.td`
    padding: 5px;
`;

function TableColumn({ children, ...otherProps }) {
    return (
        <ThemedTableColumn {...otherProps} >
            {children}
        </ThemedTableColumn>
    );
}

TableColumn.propTypes = {
    children: PropTypes.node,
};

export default TableColumn;
