import { trend, trendPercent } from 'Common/util/language/math.js';

/**
 * this is the index that extra data items can expected to be located.
 * This is an artifact of how HighCharts expects data for charting [x, y] or [x, y, val] (for heat map)
 * We use the fourth position to store data items which can be used to further define the actual data.
 */
const DATA_INDEX = 3;

/**
 * removes open event data points that contain the current open event as well as
 * null or NaN data points
 *
 * @param {Object} opts the following options used to transform the trend data so it can be calculated
 * @param {Object[]} opts.data an array of data points to compute trend. Each data point should
 *                   consist of an array of 4 elements [x, y, ?, { ...DataItems, sync_id: DataItem }]
 *                   The DataItems should be adapted for common, i.e. they should be pure data objects with no functions
 * @param {String} opts.columnName columnName of datapoint to check if it is null
 */
function transformTrendData({data, columnName}) {
    data = data.filter((dataPoint) => {
        let rawValue = dataPoint[DATA_INDEX][columnName].rawValue;
        if (dataPoint[DATA_INDEX][columnName].formatType === 'id_array' && (Array.isArray(rawValue) || typeof rawValue === 'number')) {
            // ensure its an array
            rawValue = Array.isArray(rawValue) ? rawValue : [rawValue];
            // then find the length
            rawValue = rawValue.length;
        }
        const isNumeric = rawValue !== null && typeof rawValue === 'number' && ! Number.isNaN(rawValue);

        if (!dataPoint[DATA_INDEX].sync_id) {
            throw 'Need to have sync_id included in trend data';
        }
        const containsOpenEvent = dataPoint[DATA_INDEX].sync_id.rawValue === -1;

        return isNumeric && !containsOpenEvent;
    });

    return data;
}

const maxTrend = 10;
/**
 *
 *
 * @param {Number} value The trend percent to limit
 * @return {Object} return
 * @return {Object} return.value the original trend, or if it was too big/small the limit
 * @return {Object} return.qualifier if necessary a > or < if the value was limited
 */
export function limitTrendPercent (value) {
    let qualifier = '';

    if (Math.abs(value) >= maxTrend) {
        qualifier = (value < 0) ? '<' : '>';
        value = maxTrend * ((value < 0) ? -1 : 1);
    }

    return { value, qualifier };
}

/*
    see transformTrendData for options
*/
export function trendNoOpenEvent(opts) {
    let data = transformTrendData(opts);
    return trend(data);
}

/*
    see transformTrendData for options
*/
export function trendPercentNoOpenEvent(opts) {
    let data = transformTrendData(opts);
    return trendPercent(data);
}
