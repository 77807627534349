import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontFamily } from 'Common/components/typography';

const RowCell = styled.div`
    min-width: 1px;

    ${({ $singleLine }) => $singleLine && css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `}

    &:not(:first-child) {
        padding-left: 8px;
    }

    ${({ $alignRight }) => $alignRight && css`
        text-align: right;
    `}

    /* Line below is helpful for debugging */
    /* box-shadow: inset 0 0 0 1px rgb(255,0,0); */
`;

const RowText = styled.span`
    font-family: ${FontFamily.primary};
    font-size: 12px;
    color: ${({theme}) => theme.colors.darkText.highEmphasis};

    div.cpe-metric-container {
        display: inline-block;
    }
`;

export default function EventDataCell(props) {
    const {
        allowOverflow,
        alignRight,
        children,
        style,
    } = props;

    return (
        <RowCell style={style} $alignRight={alignRight} $singleLine={!allowOverflow}>
            <RowText>{children}</RowText>
        </RowCell>
    );
}

EventDataCell.propTypes = {
    alignRight: PropTypes.bool,
    allowOverflow: PropTypes.bool,
    children: PropTypes.node,
    colspan: PropTypes.number,
    size: PropTypes.number,
    style: PropTypes.object,
};

EventDataCell.defaultProps = {
    alignRight: false,
    allowOverflow: false,
    colspan: 1,
    size: 1,
};
