import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ListContent = styled.div``;
const NoSubscribers = styled.span`
    font-style: italic;
    color: ${props => props.theme.colors.darkText.disabled};
`;

export default class AlertItemSubscriberList extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        subscribers: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
        ).isRequired,
    };

    render() {
        const { className, subscribers } = this.props;

        return (
            <ListContent className={className}>
                {subscribers.map(user => user.name).join(', ')}
                {subscribers.length === 0 && (
                    <NoSubscribers>Nobody</NoSubscribers>
                )}
            </ListContent>
        );
    }
}
