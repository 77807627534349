import { sendPatchRequest } from 'Http/request';
import { patchResources } from 'Common/util/ResourceCollection';

export function generateDeviceSubscriptionsPatch(initialData, modifiedData) {
    const generateMap = ids => ids.reduce((map, id) => {
        map[id] = true;
        return map;
    }, {});

    const initialDataMap = generateMap(initialData);
    const modifiedDataMap = generateMap(modifiedData);

    let patch = { add: [], remove: [] };

    // IDs in initial but not modified have been removed
    Object.keys(initialDataMap).forEach((id) => {
        if (!modifiedDataMap[id]) {
            patch.remove.push(Number(id));
        }
    });

    // IDs in modified but not initial have been added
    Object.keys(modifiedDataMap).forEach((id) => {
        if (!initialDataMap[id]) {
            patch.add.push(Number(id));
        }
    });

    return patch;
}

export function patchDeviceSubscriptions(data) {
    return patchResources({
        resourceType: 'deviceSubscriptions',
        requestId: 'patchDeviceSubscriptions',
        requestData: data,
        sendRequest: async () => {
            return sendPatchRequest('/api/v1/accounts/user/device-subscriptions', {
                body: { ...data },
            });
        },
    });
}
