import {
    resourceCollectionReducer,
    resourceReducer,
} from 'Common/util/ResourceCollection';
import {
    selectResources,
} from 'Common/util/ResourceCollection/resourceCollectionReducer';

export default resourceCollectionReducer('emailTemplates', {
    getResourceId: (resource) => resource.id,
    requests: [ 'getEmailTemplates', 'postEmailTemplate', 'patchEmailTemplate', 'deleteEmailTemplate' ],
    resourceReducer: resourceReducer({
        requests: [ 'getEmailTemplates', 'patchEmailTemplate', 'deleteEmailTemplate' ],
    }),
    sort: (reportA, reportB) => {
        return reportA.title.localeCompare(reportB.title);
    },
});

export function getEmailTemplatesList(state) {
    return selectResources(state);
}
