import validateNumber from 'Common/data/validateNumber';

const hmsTypeDisplayKeys = {
    h: 'hours_literal',
    m: 'minutes_literal',
    s: 'seconds_literal',
};

/**
 * Validation function for the common DurationField component.
 *
 * [FUTUREHACK]: The validation exists outside of Common for now as we don't have a common
 *     way of handling translations.
 *
 * @param {Object} hmsValue Has h, m, and s components which should be numeral values.
 * @param {Object} [opts]
 * @param {Boolean} [opts.integral = false] If true the duration should be a whole number to
 *     pass validation.
 * @param {Number} [opts.maxHours = MAX_SAFE_INT] The upper bound for a valid number of hours.
 * @param {Function} opts.translator String translator function.
 *
 * @returns {Object} Object with each key (h, m, or s) having an error if applicable. Returns
 *     undefined if there are no validation errors.
 */
export default function validateDuration(hmsValue, { integral, maxHours = Number.MIN_SAFE_INTEGER, translator }) {
    let errors = {};

    Object.keys(hmsValue).forEach((key) => {
        const numberError = validateNumber(hmsValue[key] || 0, {
            min: 0,
            max: key === 'h' ? ( maxHours ) : 59,
            integral: (key !== 's') || integral,
            translator: translator,
        });

        if (numberError) {
            errors[key] = `${translator(hmsTypeDisplayKeys[key])}: ${numberError}`;
        }
    });

    return Object.keys(errors).length ? errors : undefined;
}
