import React from 'react';
import PropTypes from 'prop-types';

function defaultTranslator() {
    throw new Error('a translator must be provided in order to translate text');
}

export const TranslationContext = React.createContext(defaultTranslator);

export const TranslationConsumer = TranslationContext.Consumer;

/**
 * A provider for the TranslationContext which allows child elements to access the
 * provided translation service via a TranslationConsumer or the useTranslation hook.
 *
 * @param {Function} translator A function which accepts an object and returns the
 *     translated string. The objects shape is:
 *     - {String} key: the translation key.
 *     - {Object} substitutions: The substitutions passed along to the translator
 *                               for dynamic translations.
 * @param {Node} children
 *
 * @see useTranslation
 */
function TranslationProvider({
    translator,
    children,
}) {
    if (!children) {
        return null;
    }

    if (!translator) {
        translator = defaultTranslator;
    }

    return (
        <TranslationContext.Provider value={translator}>
            {children}
        </TranslationContext.Provider>
    );
}

TranslationProvider.propTypes = {
    translator: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default TranslationProvider;
