import validatePassword from 'Components/password/PasswordRulesValidation';
import every from 'lodash/every';

export default function validate(value) {
    const validation = {
        password: validatePassword(value.password),
    };

    if (every(validation, v => !v)) {
        return null;
    }
    else {
        return validation;
    }
}
