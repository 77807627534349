import React, { useState, useRef, useLayoutEffect, useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { getAllScrollParents } from 'Common/util/domUtils';
import Portal from 'Common/components/Portal/Portal';
import Mask from 'Common/components/Portal/Mask';

/**
 * A hook to get all scrollable parents of the provided DOM element.
 *
 * @param {*} [element] A DOM element
 */
function useScrollParents(element) {
    return useMemo(() => {
        if (!element) {
            return [];
        }

        return getAllScrollParents(element);
    }, [ element ]);
}

/**
 * A hook to listen for any scroll events on scrollable parents of the provided
 * DOM element.
 * 
 * @param {Object} opts
 * @param {*} [opts.element]
 * @param {Function} opts.onScroll
 */
function useParentScrollTracking({ element, onScroll }) {
    const scrollParents = useScrollParents(element);

    useEffect(() => {
        scrollParents.forEach((elem) => {
            elem.addEventListener('scroll', onScroll);
        });

        return () => {
            scrollParents.forEach((elem) => {
                elem.removeEventListener('scroll', onScroll);
            });
        };
    }, [ scrollParents, onScroll ]);
}

/**
 * A hook to get the dimensions of the viewport/window. Listens to the window
 * resize event.
 * 
 * @returns {DOMRect} 
 */
function useViewportRect() {
    const [ viewportRect, setViewportRect ] = useState({
        x: 0,
        y: 0,
        top: 0,
        bottom: window.innerHeight,
        left: 0,
        right: window.innerWidth,
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setViewportRect({
                top: 0,
                bottom: window.innerHeight,
                left: 0,
                right: window.innerWidth,
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return viewportRect;
}

const areEqualRects = (rectA, rectB) => {
    const keys = [ 'top', 'bottom', 'left', 'right', 'width', 'height' ];

    for (let i in keys) {
        const key = keys[i];

        if (rectA?.[key] !== rectB?.[key]) {
            return false;
        }
    }

    return true;
};

function useElementRect(element) {
    const elementRef = useRef(element);
    const [ rect, setRect ] = useState(null);

    // Update the ref on every render so it can be acessed in callbacks while
    // keeping the callback refrence stable.
    elementRef.current = element;

    const setRectIfChanged = useCallback((newRect) => {
        setRect((prevRect) => {
            if (areEqualRects(prevRect, newRect)) {
                return prevRect;
            }
            else {
                return newRect;
            }
        });
    }, []);

    const updateRect = useCallback(() => {
        let newRect = null;

        if (elementRef.current) {
            newRect = elementRef.current.getBoundingClientRect();
        }

        setRectIfChanged(newRect);
    }, [ setRectIfChanged ]);

    // Update the rect after every render
    useLayoutEffect(() => {
        updateRect();
    });

    return {
        rect: rect,
        forceUpdate: updateRect,
    };
}

function calculateWindowPosition(anchorRect, viewportRect, minWidth) {
    const pos = {
        top: null,
        bottom: null,

        left: null,
        right: null,

        maxWidth: null,
        maxHeight: null,
    };

    const spaceAbove = anchorRect.top;
    const spaceBelow = viewportRect.height - anchorRect.bottom;

    if (spaceBelow >= spaceAbove) {
        // Position below the anchor
        pos.top = anchorRect.bottom;
        pos.maxHeight = spaceBelow;
    }
    else {
        // Position above the anchor
        pos.bottom = viewportRect.height - anchorRect.top;
        pos.maxHeight = spaceAbove;
    }

    const spaceToLeft = anchorRect.right;
    const spaceToRight = viewportRect.width - anchorRect.left;

    if (spaceToRight >= spaceToLeft) {
        // Align with the left side of the anchor
        pos.left = anchorRect.left;
        pos.maxWidth = spaceToRight;

        // If there isn't enough space for the minWidth, nudge the window to the
        // left so it can have more space.
        if (spaceToRight < minWidth) {
            const nudgeLeft = minWidth - spaceToRight;
            pos.right = 0;
            pos.left = Math.max(0, anchorRect.left - nudgeLeft);
            pos.maxWidth = viewportRect.width - pos.left;
        }
    }
    else {
        // Align with the right side of the anchor
        pos.right = viewportRect.width - anchorRect.right;
        pos.maxWidth = spaceToLeft;

        // If there isn't enough space for the minWidth, nudge the window to the
        // right so it can have more space.
        if (spaceToLeft < minWidth) {
            const nudgeRight = minWidth - spaceToLeft;
            pos.left = 0;
            pos.right = Math.max(0, (viewportRect.width - anchorRect.right) - nudgeRight);
            pos.maxWidth = viewportRect.width - pos.right;
        }
    }

    return pos;
}

const StyledWindow = styled.div.attrs((props) => {

    const { $windowPos } = props;

    const style = {};

    for (let key in $windowPos) {
        if ($windowPos[key] !== null) {
            style[key] = `${$windowPos[key]}px`;
        }
    }

    return { style };
})`
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.palette.white};
    border: 1px solid ${({ theme }) => theme.colors.palette.grey.cloud};
    border-radius: 4px;
    position: fixed;
    z-index: 300;
    display: flex;
    flex-direction: column;
    overflow: auto;

    ${({ $shadow }) => $shadow && css`
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
    `}
`;

export default function DropdownWindow(props) {
    const { anchorElement, children, minWidth, onMaskClick, shadow } = props;

    const windowRef = useRef(null);

    const viewportRect = useViewportRect();

    const {
        rect: anchorRect,
        forceUpdate: updateAnchorRect,
    } = useElementRect(anchorElement);

    useParentScrollTracking({
        element: anchorElement,
        onScroll: updateAnchorRect,
    });

    const windowPos = useMemo(() => {
        if (!anchorRect) {
            return {
                top: 0,
                left: 0,
                maxWidth: viewportRect.width,
                maxHeight: viewportRect.height,
            };
        }

        return calculateWindowPosition(anchorRect, viewportRect, minWidth);
    }, [ anchorRect, viewportRect, minWidth ]);

    return (
        <Portal>
            <Mask onClick={onMaskClick} />
            <StyledWindow
                ref={windowRef}
                $windowPos={windowPos}
                $shadow={shadow}
            >
                {children}
            </StyledWindow>
        </Portal>
    );
}

DropdownWindow.propTypes = {
    anchorElement: PropTypes.object,
    children: PropTypes.node,
    minWidth: PropTypes.number,
    onMaskClick: PropTypes.func,
    shadow: PropTypes.bool,
};

DropdownWindow.defaultProps = {
    minWidth: 375,
    onMaskClick: () => { },
    shadow: false,
};
