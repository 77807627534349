import { sendPostRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';
import { REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';

export const logout = () => requestActionCreator(
    {
        request: () => ({
            type: REQUEST_PENDING,
            requestId: 'logout',
        }),
        success: (payload) => ({
            type: REQUEST_SUCCESS,
            requestId: 'logout',
            payload,
        }),
        failure: (error) => ({
            type: REQUEST_FAILURE,
            requestId: 'logout',
            payload: { error },
        }),
    },
    async () => {
        const val = await sendPostRequest('/api/v1/accounts/logout');
        document.location.href = '/';
        return new Promise((resolve, reject) => {
            resolve(val);
        });
    }
);

export default logout;
