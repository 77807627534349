import { Heading4 } from 'Common/components/typography';
import styled from 'styled-components';

const KPIGroupHeading = styled(Heading4)`
    color: ${({theme, noColor}) => noColor
        ? theme.colors.darkText.highEmphasis
        : theme.colors.palette.blue.awesome
    };
`;

export default KPIGroupHeading;
