import { sendGetRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';

export const getOrganization = () => requestActionCreator(
    {
        request: () => ({
            type: GET_ORGANIZATION_REQUEST,
        }),
        success: (payload) => ({
            type: GET_ORGANIZATION_SUCCESS,
            payload,
        }),
        failure: (error) => ({
            type: GET_ORGANIZATION_FAILURE,
            payload: { error },
        }),
    },
    () => sendGetRequest('/api/v1/accounts/organization')
);

export default getOrganization;
