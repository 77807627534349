import { sendPatchRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';
import { REQUEST_RESET, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';

function cleanUpEmail(email) {
    return email.trim().toLowerCase();
}

export function generatePendingEmailAddressPatch(initialData, modifiedData) {
    // Clean up initial
    if (initialData === null) {
        initialData = {
            email: '',
        };
    }
    else {
        initialData = {
            ...initialData,
            email: cleanUpEmail(initialData.email),
        };
    }

    // Clean up modified
    if (modifiedData === null) {
        modifiedData = {
            email: '',
        };
    }
    else {
        modifiedData = {
            ...modifiedData,
            email: cleanUpEmail(modifiedData.email),
        };
    }

    if (initialData.email !== modifiedData.email) {
        if (modifiedData.email.length === 0) {
            // Email deleted
            return null;
        }
        else {
            // Email changed
            return {
                email: modifiedData.email,
            };
        }
    }
    else {
        // No change
        return undefined;
    }
}

export function generateSmsEmailAddressPatch(initialData, modifiedData) {
    // Clean up initial
    if (initialData === null) {
        initialData = {
            email: '',
        };
    }
    else {
        initialData = {
            ...initialData,
            email: cleanUpEmail(initialData.email),
        };
    }

    // Clean up modified
    if (modifiedData === null) {
        modifiedData = {
            email: '',
        };
    }
    else {
        modifiedData = {
            ...modifiedData,
            email: cleanUpEmail(modifiedData.email),
        };
    }

    if (initialData.email !== modifiedData.email) {
        if (modifiedData.email.length === 0) {
            // Email deleted
            return null;
        }
        else {
            // Email changed
            return {
                email: modifiedData.email,
            };
        }
    }
    else {
        // No change
        return undefined;
    }
}

export function generateSessionUserPatch(initialData, modifiedData) {
    const patchData = {};

    // Start with some cleanup
    modifiedData = {
        ...modifiedData,
        name: modifiedData.name.trim(),
    };

    // These are the basic fields that the user can modify
    const fields = [
        'name',
        'timezone',
    ];

    fields.forEach((key) => {
        if (initialData[key] !== modifiedData[key]) {
            patchData[key] = modifiedData[key];
        }
    });

    // Any values in BOTH password fields indicate the user is trying to change
    // their password
    if (modifiedData.current_password.length && modifiedData.new_password.length) {
        patchData.current_password = modifiedData.current_password;
        patchData.new_password = modifiedData.new_password;
    }

    return patchData;
}

export function patchSessionUserReset() {
    return {
        type: REQUEST_RESET,
        requestId: 'patchSessionUser',
    };
}

export const patchSessionUser = (changes) => requestActionCreator(
    {
        request: () => ({
            type: REQUEST_PENDING,
            requestId: 'patchSessionUser',
        }),
        success: (payload) => ({
            type: REQUEST_SUCCESS,
            requestId: 'patchSessionUser',
            payload,
        }),
        failure: (error) => ({
            type: REQUEST_FAILURE,
            requestId: 'patchSessionUser',
            payload: { error },
        }),
    },
    () => sendPatchRequest('/api/v1/accounts/user', {
        body: { ...changes }
    })
);

export function patchSessionUserEmailReset() {
    return {
        type: REQUEST_RESET,
        requestId: 'patchSessionUserEmail',
    };
}

export const patchSessionUserEmail = (changes) => requestActionCreator(
    {
        request: () => ({
            type: REQUEST_PENDING,
            requestId: 'patchSessionUserEmail',
        }),
        success: (payload) => ({
            type: REQUEST_SUCCESS,
            requestId: 'patchSessionUserEmail',
            payload,
        }),
        failure: (error) => ({
            type: REQUEST_FAILURE,
            requestId: 'patchSessionUserEmail',
            payload: { error },
        }),
    },
    () => sendPatchRequest('/api/v1/accounts/user', {
        body: { ...changes }
    })
);

export function patchSessionUserSmsEmailReset() {
    return {
        type: REQUEST_RESET,
        requestId: 'patchSessionUserSmsEmail',
    };
}

export const patchSessionUserSmsEmail = (changes) => requestActionCreator(
    {
        request: () => ({
            type: REQUEST_PENDING,
            requestId: 'patchSessionUserSmsEmail',
        }),
        success: (payload) => ({
            type: REQUEST_SUCCESS,
            requestId: 'patchSessionUserSmsEmail',
            payload,
        }),
        failure: (error) => ({
            type: REQUEST_FAILURE,
            requestId: 'patchSessionUserSmsEmail',
            payload: { error },
        }),
    },
    () => sendPatchRequest('/api/v1/accounts/user', {
        body: { ...changes }
    })
);

export default patchSessionUser;
