import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { elementsOfType } from 'Common/util/propTypeHelpers';
import EventDataRow from './EventDataRow';

const Root = styled.div`
    border-radius: 5px;
`;

export default function EventDataGroup(props) {
    const { columnOrder, columnConfigs, children } = props;

    const rows = React.Children.map(children, (child, i) => {
        return React.cloneElement(child, {
            striped: i % 2 === 0,
            columnOrder: columnOrder,
            columnConfigs: columnConfigs,
        });
    });

    return (
        <Root>
            {rows}
        </Root>
    );
}

EventDataGroup.propTypes = {
    columnOrder: PropTypes.array,
    columnConfigs: PropTypes.object,
    children: elementsOfType([ EventDataRow ]),
};

EventDataGroup.defaultProps = {};
