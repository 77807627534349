import styled from 'styled-components';
import { FontFamily } from 'Common/components/typography';

const KPIValue = styled.div`
    display: inline-block;
    font-family: ${FontFamily.primary};
    color: ${({theme}) => theme.colors.palette.black};
    cursor: text;
`;

export default KPIValue;
