import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonLike from 'Components/button/ButtonLike';

const RootButton = styled(ButtonLike)`
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    outline: none;
    cursor: pointer;

    /* Make room for arrow */
    padding-right: 32px;

    /* Render an arrow after the content */
    &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        right: calc(16px - 4px);
        top: calc(50% - 4px);

        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid rgba(0,0,0,0.4);
        border-bottom: none;
    }

    /* Flip arrow when open */
    &.open::after {
        border-top: none;
        border-bottom: 8px solid rgba(0,0,0,0.4);
    }

    &.disabled {
        color: ${props => props.theme.colors.darkText.disabled};
        background-color: rgba(0,0,0,0.05);
    }

    &.focused {
        border: 1px solid rgba(0, 0, 0, 1.0);
    }
`;

export default class SelectOpenButton extends React.Component {
    static propTypes = {
        forwardedRef: PropTypes.any,
        Component: PropTypes.any,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        focused: PropTypes.bool,
        open: PropTypes.bool,
    };

    static defaultProps = {
        Component: 'div',
        disabled: false,
        focused: false,
        open: false,
    };

    render() {
        const {
            forwardedRef,
            className,
            disabled,
            focused,
            open,
            ...otherProps
        } = this.props;

        return (
            <RootButton
                {...otherProps}
                ref={forwardedRef}
                className={classNames({
                    className,
                    disabled,
                    focused,
                    open,
                })}
                disabled={disabled}
                focused={focused}
            />
        );
    }
}
