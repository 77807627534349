import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body5 } from '../typography';
import Icon from '../Icon';
import useUniqueId from 'Common/hooks/useUniqueId';

const Root = styled.div``;

const Label = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 5px;

    color: ${({ theme }) => theme.colors.palette.grey.manatee};
    background-color: ${({ theme }) => theme.colors.palette.white};

    cursor: pointer;
    padding: 4px ${({ hasSearch }) => hasSearch ? 18 : 8}px;
    border-radius: 4px;

    transition: background-color 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.colors.palette.grey.cloud};
    }

    /* Give mobile users a nice big touch target */
    @media (hover: none) and (pointer: coarse) {
        padding: 13px ${({ hasSearch }) => hasSearch ? 18 : 8}px;
    }
`;

const CollapseIcon = styled(Icon).attrs(({ open }) => {
    return {
        type: 'vorne',
        iconName: open ? 'collapse-arrow-open' : 'collapse-arrow-closed',
    };
})`
    font-size: 6px;
    padding-right: 6px;
`;

const LabelText = styled(Body5)`
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
`;

const ChildrenContainer = styled.div`
    padding-left: 26px;
`;

export default function CollapsibleSelectOptionGroup(props) {
    const { groupName, label, children, open, onLabelClick, ...otherProps } = props;

    const handleLabelClick = useCallback(() => {
        onLabelClick(groupName);
    }, [ groupName, onLabelClick ]);

    const labelId = useUniqueId('group-label-');

    return (
        React.Children.count(children) > 0
            ? <Root
                role="group"
                aria-expanded={open ? 'true' : false}
                aria-labelledby={labelId}
                {...otherProps}
            >
                <Label
                    id={labelId}
                    onClick={handleLabelClick}
                >
                    <CollapseIcon open={open} />
                    <LabelText>{label}</LabelText>
                </Label>
                {open && (
                    <ChildrenContainer>
                        {children}
                    </ChildrenContainer>
                )}
            </Root>
            : null
    );
}

CollapsibleSelectOptionGroup.propTypes = {
    groupName: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    open: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onLabelClick: PropTypes.func,
};

CollapsibleSelectOptionGroup.defaultProps = {
    open: false,
    onLabelClick: (groupName) => {},
};
