import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import TextField from '../TextField';
import { formatNumber } from 'Common/util/format';
import { trunc } from 'Common/util/language/math';
import InlineError from 'Common/components/Error/InlineError';

const DEFAULT_MAX_FRACTION_DIGITS = 3;

const NumberInput = styled(TextField).attrs(({isEditing}) => ({
    // While editing we want to be a number field for better input filtering and mobile
    // keypad support. But we change to a readonly text field when not editing so we can
    // show formatted values like percentages and thousands separators.
    //
    type: isEditing ? 'number' : 'text',
    readonly: !isEditing,
}))`
    /* Hides the up/down arrows for number inputs */
    & input[type=number] {
        /* Chrome / Safari */
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;

        /* In Firefox we also need to hide the box shadow for invalid inputs */
        &:invalid {
            box-shadow: none;
        }
    }

    text-align: right;
    ${({disabled}) => disabled && css`
        color: ${({theme}) => theme.colors.darkText.disabled};
    `}
`;

const Container = styled.div`
    display: inline-block;
    width: 100%;
`;

function convertInputValue(percent, value, maximumFractionDigits) {
    let newInputValue = '';

    if (Number.isFinite(value)) {
        // Adjust the value to look like a percentage instead of a fractional value
        // for the user to edit.
        //
        newInputValue = percent ? value * 100 : value;

        // Do not show precision beyond our max precision
        newInputValue = Number(newInputValue.toFixed(maximumFractionDigits));
    }

    return newInputValue;
}

/**
 * A number input field with the standard styles applied with prop type
 * validation and numerical conversion for the onChange event.
 *
 * @param {Number} [value] The number to use as the value of the field, decimal portion
 *     may be truncated on blur if integral prop is set to true.
 * @param {Function} [onChange] Called with the new value when a value is committed on a
 *     blur event.
 * @param {Number} [minFractionDigits = 0] Enforce a minimum number for fractional digits.
 * @param {Boolean} [percent = false] When true values are multiplied by 100 and shown with a %
 *     symbol while not focused.
 * @param {Number} [minLeftDigits = 0] Number of digits to ensure on the left side of the
 *     decimal point, padding with zeros as needed.
 * @param {String} [placeholder = '']
 * @param {Node} [error] An error message if relevant.
 * @param {Boolean} [showErrorInline = true] If there is an error present show it inline under the
 *     input.
 */
export default function NumberField({
    className,
    value,
    name,
    placeholder,
    onChange: onChangeProp,
    minLeftDigits,
    minFractionDigits,
    percent,
    error,
    showErrorInline,
    disabled
}) {
    const maximumFractionDigits = Math.max(
        DEFAULT_MAX_FRACTION_DIGITS,
        minFractionDigits,
    );

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(convertInputValue(percent, value, maximumFractionDigits));

    let stringValue = '';

    if (Number.isFinite(value)) {
        stringValue = formatNumber(value, {
            minDigits: minLeftDigits,
            type: percent ? 'percent' : 'decimal',
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits,
        });
    }

    const onFocus = useCallback(() => {
        setIsEditing(true);
        setInputValue(convertInputValue(percent, value, maximumFractionDigits));
    }, [percent, value, maximumFractionDigits]);

    const handleChange = useCallback(({ newValue: newValueString, shouldTruncate = false }) => {
        newValueString = newValueString.trim();
        let newValue = Number(newValueString);

        if (newValueString.length > 0 && !Number.isNaN(newValue)) {
            if (shouldTruncate) {
                newValue = trunc(newValue, maximumFractionDigits);
            }

            // Apply percentage adjustment after truncation as the max fraction digits
            // applies to what the user sees, not the value used under the hood.
            //
            if (percent) {
                newValue = Number((newValue / 100).toFixed(maximumFractionDigits + 2));
            }
        }
        else {
            newValue = null;
        }

        onChangeProp({
            target: {
                value: newValue,
                name,
            },
        });
    }, [onChangeProp, maximumFractionDigits, name, percent]);

    const onBlur = useCallback((event) => {
        setIsEditing(false);

        handleChange({
            newValue: event.target.value,
            shouldTruncate: true,
        });
    }, [handleChange]);

    const onChange = useCallback((event) => {
        setInputValue(event.target.value);
        handleChange({
            newValue: event.target.value,
        });
    }, [handleChange]);

    return (
        <Container className={className}>
            <NumberInput
                isEditing={isEditing}
                value={isEditing ? String(inputValue) : stringValue}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                error={error}
                disabled={disabled}
            />
            {showErrorInline && error && (
                <InlineError style={{ paddingTop: 4 }}>
                    {error}
                </InlineError>
            )}
        </Container>
    );
}

NumberField.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    minLeftDigits: PropTypes.number,
    minFractionDigits: PropTypes.number,
    percent: PropTypes.bool,
    error: PropTypes.node,
    showErrorInline: PropTypes.bool,
    disabled: PropTypes.bool
};

NumberField.defaultProps = {
    placeholder: '',
    onChange: () => { },
    minLeftDigits: 0,
    minFractionDigits: 0,
    percent: false,
    showErrorInline: true,
    disabled: false
};
