import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DroppableSpot from './DroppableSpot';
import DraggableWrapper from './DraggableWrapper';
import AddWidgetButton from './AddWidgetButton';

const Root = styled.div`
    margin-right: 10px;

    :last-of-type {
        margin-right: 0;
    };
`;

const WidgetHolder = styled.div`
    position: relative;
    vertical-align: top;

`;

const WidgetHolderAsSelection = styled(WidgetHolder)`
    margin-bottom: 10px;

    ${({ $isLayoutModeActive }) => $isLayoutModeActive && css`
        border: 1px solid ${({ theme }) => theme.colors.palette.grey.ash};
        padding: 3px 2px;
        border-radius: 6px;
        min-height: 50px;
        user-select: none;
    `}

    transition: background-color 0.25s, border-color 0.25s;

    ${({ $showHover }) => $showHover && css`
        border-color: ${({ theme }) => theme.colors.palette.grey.manatee};
        background-color: ${({ theme }) => theme.colors.palette.blue.ice};
    `}

    ${({ $showSelected }) => $showSelected && css`
        border-color: ${({ theme }) => theme.colors.palette.blue.awesome};
        background-color: ${({ theme }) => theme.colors.palette.blue.ice};
    `}

    ${({ $showDeleteHover }) => $showDeleteHover && css`
        border-color: ${({ theme }) => theme.colors.palette.error.red};
        background-color: ${({ theme }) => theme.colors.palette.error.helios};
    `}
`;

export default function Column(props) {
    const {
        columnIndex,
        sectionIndex,
        maxWidgets,
        widgets,
        renderWidget,
        isLayoutModeActive,
        getScrollParent,
        showHover,
        showDeleteHover,
        showSelected,
        onAddWidget,
        widgetSchema,
        sectionAsSelection,
    } = props;

    const numWidgets = widgets.length;

    const handleAddWidget = useCallback((widgetType) => {
        onAddWidget({
            destination: {
                sectionIndex,
                columnIndex,
                widgetIndex: numWidgets,
            },
            widgetType,
        });
    }, [ onAddWidget, sectionIndex, columnIndex, numWidgets ]);

    const showAddWidgetButton = () => {
        return isLayoutModeActive && widgets.length < maxWidgets;
    };

    const WidgetHolderComponent = sectionAsSelection ? WidgetHolder : WidgetHolderAsSelection;

    return (
        <Root>
            <WidgetHolderComponent
                $isLayoutModeActive={isLayoutModeActive}
                $showHover={showHover && !showSelected}
                $showDeleteHover={showDeleteHover}
                $showSelected={showSelected}
            >
                {widgets.map((widgetId, widgetIndex) => {
                    // Use widgetId (not index) for key and IDs so that the nodes
                    // are not unnecessarily unmounted and remounted when widgets
                    // are moved.
                    const dropBeforeId = `${widgetId}-before`;
                    return [
                        <DroppableSpot
                            key={dropBeforeId}
                            droppableId={dropBeforeId}
                            sectionIndex={sectionIndex}
                            columnIndex={columnIndex}
                            widgetIndex={widgetIndex}
                            widgetId={widgetId}
                            outerHeight={0}
                            disabled={!isLayoutModeActive}
                        />,
                        <DraggableWrapper
                            key={widgetId}
                            isLayoutModeActive={isLayoutModeActive}
                            sectionIndex={sectionIndex}
                            columnIndex={columnIndex}
                            widgetIndex={widgetIndex}
                            widgetId={widgetId}
                            renderWidget={renderWidget}
                            disabled={!isLayoutModeActive}
                            getScrollParent={getScrollParent}
                        />,
                    ];
                })}
                <DroppableSpot
                    droppableId={`${sectionIndex}-${columnIndex}-end`}
                    sectionIndex={sectionIndex}
                    columnIndex={columnIndex}
                    widgetIndex={widgets.length}
                    widgetId={null}
                    outerHeight={0}
                    disabled={!isLayoutModeActive}
                />
                {showAddWidgetButton() && widgetSchema && (
                    <AddWidgetButton
                        onAddWidget={handleAddWidget}
                        widgetSchema={widgetSchema}
                    />
                )}
            </WidgetHolderComponent>
        </Root>
    );
}

Column.propTypes = {
    sectionIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    maxWidgets: PropTypes.number.isRequired,
    widgets: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderWidget: PropTypes.func.isRequired,
    isLayoutModeActive: PropTypes.bool.isRequired,
    getScrollParent: PropTypes.func.isRequired,
    showHover: PropTypes.bool.isRequired,
    showDeleteHover: PropTypes.bool.isRequired,
    showSelected: PropTypes.bool,
    onAddWidget: PropTypes.func.isRequired,
    widgetSchema: PropTypes.object,
    // true if the entire section should show selected, hover and deleted activity or if it should
    // be displayed individually per column
    sectionAsSelection: PropTypes.bool.isRequired,
};
