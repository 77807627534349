import React, { useMemo } from 'react';
import styled from 'styled-components';
import CategoryValue from 'Common/components/FormattedDatum/CategoryValue';
import Metric from 'Common/components/FormattedDatum/Metric';
import PropTypes from 'prop-types';
import { Body5, Heading5 } from 'Common/components/typography';
import { useTranslator } from 'Common/hooks/useTranslation';

const StyledCategoryValue = styled(CategoryValue)`
    padding-right: 20px;
    display: block;
`;
const StyledMetric = styled(Metric)`
    display: block;
    text-align: right;
`;

const Row = styled(Body5).attrs({ as: 'div' })`
    display: grid;
    padding: 4px 5px 5px 5px;
    grid-template-columns: auto auto;
    align-items: center;
`;

const Root = styled.div`
    line-height: 16px;

    ${Row}:nth-child(2n) {
        border-radius: 3px;
        background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    }
`;

const InformationalText = styled.div`
    padding: 8px 5px 4px 5px;
`;

const TooltipHeader = styled(Heading5)`
    display: block;
    text-align: left;
`;

const MAX_ITEMS = 100;

function TooltipContent({ data }) {
    const { displayName, values:dataValues } = data;
    const translator = useTranslator();
    const header = useMemo(() => {
        const hasMetrics = dataValues.find(x => x.metric);
        return hasMetrics
            ? (
                <Row>
                    <TooltipHeader>
                            {displayName}
                    </TooltipHeader>
                </Row>
            )
            : null;
    },[displayName, dataValues]);
    const content = useMemo(() => {
        if (!dataValues) {
            return [];
        }
        let values = dataValues;
        const orignalValuesLength = values.length;
        // we will only count too many items as max items + 1
        // this means we won't show a message that one item is missing
        // instead of just showing that one item
        const hasTooManyItems = orignalValuesLength > (MAX_ITEMS + 1);

        // we limit how much we show, too many items in here
        // can cause the tooltip to take a full second to load
        // and is soo much data that it's not very digestable to the user.
        if (hasTooManyItems) {
            values = values.slice(0, MAX_ITEMS);
        }

        let contentValues = values.map((elem, index) => {
            return elem.metric
                ? (<Row key={index}>
                    <StyledCategoryValue
                        data={{
                            name: elem.dimension.name,
                            formatType: 'category_value',
                            rawValue: elem.dimension.rawValue,
                            // Only show color indicator if a color is provided.
                            hasColorIndicator: Boolean(elem.dimension.rawValue.color),
                        }}
                        noTooltip
                    />
                    <StyledMetric
                        data={elem.metric}
                        noTooltip
                    />
                </Row>)
                : (<Row key={index}>
                    <CategoryValue
                        data={{
                            name: elem.dimension.name,
                            formatType: 'category_value',
                            rawValue: elem.dimension.rawValue,
                            // Only show color indicator if a color is provided.
                            hasColorIndicator: Boolean(elem.dimension.rawValue.color),
                        }}
                        noTooltip
                    />
                </Row>);
        });

        if (hasTooManyItems) {
            contentValues.push(<InformationalText key={MAX_ITEMS}>{translator('widget.spark_visualization.spark_dimension_label.chronogram_max_items', {numItems: orignalValuesLength - MAX_ITEMS})}</InformationalText>);
        }

        return contentValues;
    }, [dataValues, translator]);

    return (
        <Root>
            {header}
            {content}
        </Root>
    );
}

TooltipContent.propTypes = {
    data: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                dimension: PropTypes.shape({
                    displayName: PropTypes.string,
                    name: PropTypes.string,
                    formatType: PropTypes.string,
                    rawValue: PropTypes.shape({
                        displayName: PropTypes.string,
                        name: PropTypes.string,
                        color: PropTypes.string,
                    }),
                }).isRequired,
                metric: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    formatType: PropTypes.string.isRequired,
                    rawValue: PropTypes.number
                }),
            }),
        ).isRequired,
    }),
};

export default TooltipContent;
