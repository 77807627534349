
import React from 'react';
import { getColumnFormatType } from './getColumnFormatType';
import {
    channel_schema
} from './ChannelSchema';
import FormattedDatum from 'Common/components/FormattedDatum';
import { OffsetDate } from 'Common/data/date/OffsetDate';
import { getHeatConfig } from './HeatMapColors';

export const metricOptions = [
    {
        'name': 'counts',
        'displayName': 'Counts',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'pack_out_count',
                'channel': 'production_metric',
                'displayName': 'End of Line Count',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'good_count',
                'channel': 'production_metric',
                'displayName': 'Good Count',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'in_count',
                'channel': 'production_metric',
                'displayName': 'In Count',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'percent_good',
                'channel': 'production_metric',
                'displayName': 'Percent Good',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'percent_reject',
                'channel': 'production_metric',
                'displayName': 'Percent Reject',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'production_rejects',
                'channel': 'production_metric',
                'displayName': 'Production Rejects',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'reject_count',
                'channel': 'production_metric',
                'displayName': 'Reject Count',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'startup_rejects',
                'channel': 'production_metric',
                'displayName': 'Startup Rejects',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'total_count',
                'channel': 'production_metric',
                'displayName': 'Total Count',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            }
        ]
    },
    {
        'name': 'cycles',
        'displayName': 'Cycles',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'average_cycle_time',
                'channel': 'production_metric',
                'displayName': 'Avg. Cycle Time',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'average_ideal_cycle_time',
                'channel': 'production_metric',
                'displayName': 'Avg. Ideal Cycle Time',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'average_run_cycle_time',
                'channel': 'production_metric',
                'displayName': 'Avg. Run Cycle Time',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'average_small_stop_time',
                'channel': 'production_metric',
                'displayName': 'Avg. Small Stop Time',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'equipment_cycles',
                'channel': 'production_metric',
                'displayName': 'Equipment Cycles',
                'fieldType': 'metric',
                'type': 'uint64'
            },
            {
                'name': 'ideal_time',
                'channel': 'production_metric',
                'displayName': 'Ideal Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'partial_cycle_lost_time',
                'channel': 'production_metric',
                'displayName': 'Partial Cycle Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'partial_cycles',
                'channel': 'production_metric',
                'displayName': 'Partial Cycles',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'cycle'
            },
            {
                'name': 'run_cycle_lost_time',
                'channel': 'production_metric',
                'displayName': 'Run Cycle Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'run_cycles',
                'channel': 'production_metric',
                'displayName': 'Run Cycles',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'cycle'
            },
            {
                'name': 'small_stops',
                'channel': 'production_metric',
                'displayName': 'Small Stops',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'cycle'
            },
            {
                'name': 'total_cycle_time',
                'channel': 'production_metric',
                'displayName': 'Total Cycle Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'total_cycles',
                'channel': 'production_metric',
                'displayName': 'Total Cycles',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'cycle'
            }
        ]
    },
    {
        'name': 'labor',
        'displayName': 'Labor',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'earned_labor',
                'channel': 'production_metric',
                'displayName': 'Earned Labor',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'good_pieces_per_labor_hour',
                'channel': 'production_metric',
                'displayName': 'Good Pieces per Labor Hour',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'labor_efficiency',
                'channel': 'production_metric',
                'displayName': 'Labor Efficiency',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'labor_per_good_piece',
                'channel': 'production_metric',
                'displayName': 'Labor per Good Piece',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'labor_per_piece',
                'channel': 'production_metric',
                'displayName': 'Labor per Piece',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'lost_labor',
                'channel': 'production_metric',
                'displayName': 'Lost Labor',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'pieces_per_labor_hour',
                'channel': 'production_metric',
                'displayName': 'Pieces per Labor Hour',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'target_labor_per_piece',
                'channel': 'production_metric',
                'displayName': 'Target Labor per Piece',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'average_team_size',
                'channel': 'production_metric',
                'displayName': 'Team Size',
                'fieldType': 'metric',
                'type': 'double'
            },
            {
                'name': 'labor',
                'channel': 'production_metric',
                'displayName': 'Total Labor',
                'fieldType': 'metric',
                'type': 'time_span'
            }
        ]
    },
    {
        'name': 'oee',
        'displayName': 'OEE',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'availability',
                'channel': 'production_metric',
                'displayName': 'Availability',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'availability_loss',
                'channel': 'production_metric',
                'displayName': 'Availability Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'availability_lost_time',
                'channel': 'production_metric',
                'displayName': 'Availability Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'fully_productive_time',
                'channel': 'production_metric',
                'displayName': 'Fully Productive Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'oee',
                'channel': 'production_metric',
                'displayName': 'OEE',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'oee_loss',
                'channel': 'production_metric',
                'displayName': 'OEE Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'oee_lost_time',
                'channel': 'production_metric',
                'displayName': 'OEE Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'performance',
                'channel': 'production_metric',
                'displayName': 'Performance',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'performance_loss',
                'channel': 'production_metric',
                'displayName': 'Performance Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'performance_lost_time',
                'channel': 'production_metric',
                'displayName': 'Performance Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'quality',
                'channel': 'production_metric',
                'displayName': 'Quality',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'quality_loss',
                'channel': 'production_metric',
                'displayName': 'Quality Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'quality_lost_time',
                'channel': 'production_metric',
                'displayName': 'Quality Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            }
        ]
    },
    {
        'name': 'production_times',
        'displayName': 'Production Times',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'all_time',
                'channel': 'production_metric',
                'displayName': 'All Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'down_time',
                'channel': 'production_metric',
                'displayName': 'Down Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'duration',
                'channel': 'production_metric',
                'displayName': 'Duration',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'end_time',
                'channel': 'production_metric',
                'displayName': 'End Time',
                'fieldType': 'metric',
                'type': 'date_time'
            },
            {
                'name': 'manufacturing_time',
                'channel': 'production_metric',
                'displayName': 'Manufacturing Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'not_scheduled_time',
                'channel': 'production_metric',
                'displayName': 'Not Scheduled Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'percent_down',
                'channel': 'production_metric',
                'displayName': 'Percent Down',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'percent_planned_stop',
                'channel': 'production_metric',
                'displayName': 'Percent Planned Stop',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'percent_run',
                'channel': 'production_metric',
                'displayName': 'Percent Run',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'percent_unplanned_stop',
                'channel': 'production_metric',
                'displayName': 'Percent Unplanned Stop',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'planned_stop_time',
                'channel': 'production_metric',
                'displayName': 'Planned Stop Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'production_time',
                'channel': 'production_metric',
                'displayName': 'Production Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'run_time',
                'channel': 'production_metric',
                'displayName': 'Run Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'start_time',
                'channel': 'production_metric',
                'displayName': 'Start Time',
                'fieldType': 'metric',
                'type': 'date_time'
            },
            {
                'name': 'unplanned_stop_time',
                'channel': 'production_metric',
                'displayName': 'Unplanned Stop Time',
                'fieldType': 'metric',
                'type': 'time_span'
            }
        ]
    },
    {
        'name': 'rates',
        'displayName': 'Rates',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'pack_out_rate_per_hour',
                'channel': 'production_metric',
                'displayName': 'End of Line Rate PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'pack_out_rate',
                'channel': 'production_metric',
                'displayName': 'End of Line Rate PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'pack_out_speed_per_hour',
                'channel': 'production_metric',
                'displayName': 'End of Line Speed PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'pack_out_speed',
                'channel': 'production_metric',
                'displayName': 'End of Line Speed PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'good_rate_per_hour',
                'channel': 'production_metric',
                'displayName': 'Good Rate PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'good_rate',
                'channel': 'production_metric',
                'displayName': 'Good Rate PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'good_speed_per_hour',
                'channel': 'production_metric',
                'displayName': 'Good Speed PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'good_speed',
                'channel': 'production_metric',
                'displayName': 'Good Speed PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'in_rate_per_hour',
                'channel': 'production_metric',
                'displayName': 'In Rate PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'in_rate',
                'channel': 'production_metric',
                'displayName': 'In Rate PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'in_speed_per_hour',
                'channel': 'production_metric',
                'displayName': 'In Speed PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'in_speed',
                'channel': 'production_metric',
                'displayName': 'In Speed PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'reject_rate_per_hour',
                'channel': 'production_metric',
                'displayName': 'Reject Rate PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'reject_rate',
                'channel': 'production_metric',
                'displayName': 'Reject Rate PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'reject_speed_per_hour',
                'channel': 'production_metric',
                'displayName': 'Reject Speed PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'reject_speed',
                'channel': 'production_metric',
                'displayName': 'Reject Speed PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'target_rate_per_hour',
                'channel': 'production_metric',
                'displayName': 'Target Rate PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'target_rate',
                'channel': 'production_metric',
                'displayName': 'Target Rate PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'total_rate_per_hour',
                'channel': 'production_metric',
                'displayName': 'Total Rate PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'total_rate',
                'channel': 'production_metric',
                'displayName': 'Total Rate PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            },
            {
                'name': 'total_speed_per_hour',
                'channel': 'production_metric',
                'displayName': 'Total Speed PPH',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate_per_hour'
            },
            {
                'name': 'total_speed',
                'channel': 'production_metric',
                'displayName': 'Total Speed PPM',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'rate'
            }
        ]
    },
    {
        'name': 'six_big_losses',
        'displayName': 'Six Big Losses',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'cycle_loss',
                'channel': 'production_metric',
                'displayName': 'Cycle Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'cycle_lost_time',
                'channel': 'production_metric',
                'displayName': 'Cycle Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'down_loss',
                'channel': 'production_metric',
                'displayName': 'Down Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'down_lost_time',
                'channel': 'production_metric',
                'displayName': 'Down Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'planned_stop_loss',
                'channel': 'production_metric',
                'displayName': 'Planned Stop Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'planned_stop_lost_time',
                'channel': 'production_metric',
                'displayName': 'Planned Stop Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'production_reject_loss',
                'channel': 'production_metric',
                'displayName': 'Production Reject Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'production_reject_lost_time',
                'channel': 'production_metric',
                'displayName': 'Production Reject Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'small_stop_loss',
                'channel': 'production_metric',
                'displayName': 'Small Stop Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'small_stop_lost_time',
                'channel': 'production_metric',
                'displayName': 'Small Stop Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'startup_reject_loss',
                'channel': 'production_metric',
                'displayName': 'Startup Reject Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'startup_reject_lost_time',
                'channel': 'production_metric',
                'displayName': 'Startup Reject Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'unplanned_stop_loss',
                'channel': 'production_metric',
                'displayName': 'Unplanned Stop Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'unplanned_stop_lost_time',
                'channel': 'production_metric',
                'displayName': 'Unplanned Stop Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            }
        ]
    },
    {
        'name': 'target',
        'displayName': 'Target',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'average_takt_time',
                'channel': 'production_metric',
                'displayName': 'Avg. Takt Time',
                'fieldType': 'metric',
                'type': 'time_span',
                'subType': 'cycle_time'
            },
            {
                'name': 'count_variance',
                'channel': 'production_metric',
                'displayName': 'Count Variance',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'efficiency',
                'channel': 'production_metric',
                'displayName': 'Efficiency',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'percent_variance',
                'channel': 'production_metric',
                'displayName': 'Percent Variance',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'rate_efficiency',
                'channel': 'production_metric',
                'displayName': 'Rate Efficiency',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },

            {
                'name': 'target_count',
                'channel': 'production_metric',
                'displayName': 'Target Count',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'count'
            },
            {
                'name': 'target_cycles',
                'channel': 'production_metric',
                'displayName': 'Target Cycles',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'cycle'
            },
            {
                'name': 'time_variance',
                'channel': 'production_metric',
                'displayName': 'Time Variance',
                'fieldType': 'metric',
                'type': 'time_span'
            }
        ]
    },
    {
        'name': 'teep',
        'displayName': 'TEEP',
        'isDummyGroup': false,
        'entries': [
            {
                'name': 'hidden_factory_time',
                'channel': 'production_metric',
                'displayName': 'Hidden Factory Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'production_loss',
                'channel': 'production_metric',
                'displayName': 'Production Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'production_lost_time',
                'channel': 'production_metric',
                'displayName': 'Production Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'capacity_loss',
                'channel': 'production_metric',
                'displayName': 'Schedule Loss',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'capacity_lost_time',
                'channel': 'production_metric',
                'displayName': 'Schedule Lost Time',
                'fieldType': 'metric',
                'type': 'time_span'
            },
            {
                'name': 'teep',
                'channel': 'production_metric',
                'displayName': 'TEEP',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            },
            {
                'name': 'utilization',
                'channel': 'production_metric',
                'displayName': 'Utilization',
                'fieldType': 'metric',
                'type': 'double',
                'subType': 'percent'
            }
        ]
    }
];

export const hierarchyNodeOptions = [
    {
        'displayName': 'Enterprise',
        'iconClass': 'vorne-icon vorne-icon-globe',
        'level': 0,
        'name': '3eb732c7-aeaa-4e46-8ac1-bfccd1d537a9',
        'isAsset': false
    },
    {
        'displayName': 'Site',
        'iconClass': 'vorne-icon vorne-icon-factory',
        'level': 1,
        'name': 'fc76e8de-f046-46e5-803a-d1f5230b69a5',
        'isAsset': false
    },
    {
        'displayName': 'Area',
        'iconClass': 'vorne-icon vorne-icon-department',
        'level': 2,
        'name': '0f1deb3a-b76e-45be-b033-bce3417f7565',
        'isAsset': false
    },
    {
        'displayName': 'XL810-1',
        'iconClass': 'vorne-icon vorne-icon-gear',
        'level': 3,
        'name': 'self',
        'isAsset': true
    }
];

export const filterOptions = {
    'metrics': [],
    'categories': [
        {
            'displayName': 'Production',
            'name': 'production',
            'entries': [
                {
                    'name': 'shift',
                    'displayName': 'Shift',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'team',
                    'groupingLevel': 0,
                    'assetLevel': null,
                    'isAssetBased': false
                },
                {
                    'name': 'team',
                    'displayName': 'Team',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'team',
                    'groupingLevel': 1,
                    'assetLevel': null,
                    'isAssetBased': false
                },
                {
                    'name': 'part',
                    'displayName': 'Part',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': 0,
                    'groupName': 'production',
                    'groupingLevel': 2,
                    'assetLevel': null,
                    'isAssetBased': false
                },
                {
                    'name': 'job',
                    'displayName': 'Job',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': 0,
                    'groupName': 'production',
                    'groupingLevel': 3,
                    'assetLevel': null,
                    'isAssetBased': false
                },
                {
                    'name': 'process_state',
                    'displayName': 'Production State',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': 1,
                    'groupName': 'process_state',
                    'groupingLevel': 4,
                    'assetLevel': null,
                    'isAssetBased': false
                },
                {
                    'name': 'process_state_reason',
                    'displayName': 'Reason',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': 1,
                    'groupName': 'process_state',
                    'groupingLevel': 5,
                    'assetLevel': null,
                    'isAssetBased': false
                },
                {
                    'name': 'performance_impact',
                    'displayName': 'Impact',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': 2,
                    'groupName': 'impact',
                    'groupingLevel': 6,
                    'assetLevel': null,
                    'isAssetBased': false
                },
                {
                    'name': 'production_phase',
                    'displayName': 'Production Phase',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': 2,
                    'groupName': 'impact',
                    'groupingLevel': 7,
                    'assetLevel': null,
                    'isAssetBased': false
                }
            ]
        },
        {
            'displayName': 'Date and Time',
            'name': 'date_and_time',
            'entries': [
                {
                    'name': 'shift_hour',
                    'displayName': 'Hour',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'time_based',
                    'groupingLevel': 0,
                    'assetLevel': null,
                    'isAssetBased': false,
                    'short_description_key': 'shift_hour_short_description'
                },
                {
                    'name': 'ordinal_shift',
                    'displayName': 'Ordinal Shift',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'time_based',
                    'groupingLevel': 1,
                    'assetLevel': null,
                    'isAssetBased': false,
                    'short_description_key': 'ordinal_shift_short_description'
                },
                {
                    'name': 'production_day',
                    'displayName': 'Day of Week',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'time_based',
                    'groupingLevel': 3,
                    'assetLevel': null,
                    'isAssetBased': false,
                    'short_description_key': 'production_day_short_description'
                },
                {
                    'name': 'week',
                    'displayName': 'Week',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'time_based',
                    'groupingLevel': 4,
                    'assetLevel': null,
                    'isAssetBased': false,
                    'short_description_key': 'week_short_description'
                },
                {
                    'name': 'month',
                    'displayName': 'Month',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'time_based',
                    'groupingLevel': 6,
                    'assetLevel': null,
                    'isAssetBased': false,
                    'short_description_key': 'month_short_description'
                },
                {
                    'name': 'quarter',
                    'displayName': 'Quarter',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'time_based',
                    'groupingLevel': 7,
                    'assetLevel': null,
                    'isAssetBased': false,
                    'short_description_key': 'quarter_short_description'
                },
                {
                    'name': 'year',
                    'displayName': 'Year',
                    'channel': 'production_metric',
                    'fieldType': 'category_value',
                    'type': 'string',
                    'grouping': -1,
                    'groupName': 'time_based',
                    'groupingLevel': 8,
                    'assetLevel': null,
                    'isAssetBased': false,
                    'short_description_key': 'year_short_description'
                }
            ]
        }
    ],
    'values': {
        'shift': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': 'first_shift',
                        'displayName': 'First Shift'
                    },
                    {
                        'name': 'second_shift',
                        'displayName': 'Second Shift'
                    },
                    {
                        'name': 'third_shift',
                        'displayName': 'Third Shift'
                    },
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown Shift'
                    }
                ]
            }
        ],
        'team': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown Team'
                    }
                ]
            }
        ],
        'part': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': 'sample_part',
                        'displayName': 'Sample Part'
                    },
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown Part'
                    }
                ]
            }
        ],
        'job': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown Job'
                    }
                ]
            }
        ],
        'process_state': [
            {
                'name': 'not_scheduled',
                'displayName': 'Not Scheduled (Excluded from OEE)',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'meeting',
                        'displayName': 'Meeting',
                        'link': 'not_scheduled'
                    },
                    {
                        'name': 'break',
                        'displayName': 'Meal/Break',
                        'link': 'not_scheduled'
                    },
                    {
                        'name': 'no_production',
                        'displayName': 'No Production',
                        'link': 'not_scheduled'
                    },
                    {
                        'name': 'not_monitored',
                        'displayName': 'Not Monitored',
                        'link': 'not_scheduled'
                    }
                ]
            },
            {
                'name': 'planned_stop',
                'displayName': 'Planned Stop',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'maintenance',
                        'displayName': 'Maintenance',
                        'link': 'planned_stop'
                    },
                    {
                        'name': 'changeover',
                        'displayName': 'Changeover',
                        'link': 'planned_stop'
                    }
                ]
            },
            {
                'name': 'run',
                'displayName': 'Run',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'running',
                        'displayName': 'Running',
                        'link': 'run'
                    },
                    {
                        'name': 'running_slow',
                        'displayName': 'Running Slow',
                        'link': 'run'
                    },
                    {
                        'name': 'running_poor_quality',
                        'displayName': 'Running Poor Quality',
                        'link': 'run'
                    },
                    {
                        'name': 'running_poorly',
                        'displayName': 'Running Poorly',
                        'link': 'run'
                    }
                ]
            },
            {
                'name': 'unplanned_stop',
                'displayName': 'Unplanned Stop',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'down',
                        'displayName': 'Down',
                        'link': 'unplanned_stop'
                    }
                ]
            }
        ],
        'process_state_reason': [
            {
                'name': 'changeover',
                'displayName': 'Changeover',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'material_change',
                        'displayName': 'Material Change',
                        'link': 'changeover'
                    },
                    {
                        'name': 'part_change',
                        'displayName': 'Part Change',
                        'link': 'changeover'
                    },
                    {
                        'name': 'changeover',
                        'displayName': 'Setup',
                        'link': 'changeover'
                    }
                ]
            },
            {
                'name': 'down',
                'displayName': 'Down',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'adjustment',
                        'displayName': 'Adjustment',
                        'link': 'down'
                    },
                    {
                        'name': 'autonomous_maintenance',
                        'displayName': 'Autonomous Maintenance',
                        'link': 'down'
                    },
                    {
                        'name': 'breakdown',
                        'displayName': 'Breakdown',
                        'link': 'down'
                    },
                    {
                        'name': 'jam',
                        'displayName': 'Jam',
                        'link': 'down'
                    },
                    {
                        'name': 'down',
                        'displayName': 'Missing Reason',
                        'link': 'down'
                    },
                    {
                        'name': 'no_material',
                        'displayName': 'No Material',
                        'link': 'down'
                    },
                    {
                        'name': 'no_operator',
                        'displayName': 'No Operator',
                        'link': 'down'
                    }
                ]
            },
            {
                'name': 'maintenance',
                'displayName': 'Maintenance',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': '5s',
                        'displayName': '5S',
                        'link': 'maintenance'
                    },
                    {
                        'name': 'cleaning',
                        'displayName': 'Cleaning',
                        'link': 'maintenance'
                    },
                    {
                        'name': 'maintenance',
                        'displayName': 'General Maintenance',
                        'link': 'maintenance'
                    },
                    {
                        'name': 'lubrication',
                        'displayName': 'Lubrication',
                        'link': 'maintenance'
                    }
                ]
            },
            {
                'name': 'break',
                'displayName': 'Meal/Break',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'break',
                        'displayName': 'Break',
                        'link': 'break'
                    },
                    {
                        'name': 'lunch',
                        'displayName': 'Lunch',
                        'link': 'break'
                    }
                ]
            },
            {
                'name': 'meeting',
                'displayName': 'Meeting',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'safety_meeting',
                        'displayName': 'Safety Meeting',
                        'link': 'meeting'
                    },
                    {
                        'name': 'shift_handover',
                        'displayName': 'Shift Handover',
                        'link': 'meeting'
                    },
                    {
                        'name': 'meeting',
                        'displayName': 'Team Meeting',
                        'link': 'meeting'
                    }
                ]
            },
            {
                'name': 'no_production',
                'displayName': 'No Production',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'no_orders',
                        'displayName': 'No Orders',
                        'link': 'no_production'
                    },
                    {
                        'name': 'no_production',
                        'displayName': 'No Shift Scheduled',
                        'link': 'no_production'
                    },
                    {
                        'name': 'no_process_state_enabled',
                        'displayName': 'No State Enabled',
                        'link': 'no_production'
                    },
                    {
                        'name': 'optional_shift_scheduled',
                        'displayName': 'Optional Shift Scheduled',
                        'link': 'no_production'
                    },
                    {
                        'name': 'shift_ended_early',
                        'displayName': 'Shift Ended Early',
                        'link': 'no_production'
                    }
                ]
            },
            {
                'name': 'not_monitored',
                'displayName': 'Not Monitored',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'event_cleared',
                        'displayName': 'Event Cleared',
                        'link': 'not_monitored'
                    },
                    {
                        'name': 'not_monitored',
                        'displayName': 'Not Monitored',
                        'link': 'not_monitored'
                    },
                    {
                        'name': 'powered_off',
                        'displayName': 'Powered Off',
                        'link': 'not_monitored'
                    },
                    {
                        'name': 'upgrading',
                        'displayName': 'Upgrading',
                        'link': 'not_monitored'
                    }
                ]
            },
            {
                'name': 'running',
                'displayName': 'Running',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'running',
                        'displayName': 'Running Normally',
                        'link': 'running'
                    }
                ]
            },
            {
                'name': 'running_poor_quality',
                'displayName': 'Running Poor Quality',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'production_rejects',
                        'displayName': 'Production Rejects',
                        'link': 'running_poor_quality'
                    },
                    {
                        'name': 'running_poor_quality',
                        'displayName': 'Running Poor Quality',
                        'link': 'running_poor_quality'
                    },
                    {
                        'name': 'startup_rejects',
                        'displayName': 'Startup Rejects',
                        'link': 'running_poor_quality'
                    }
                ]
            },
            {
                'name': 'running_poorly',
                'displayName': 'Running Poorly',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'running_poorly',
                        'displayName': 'Slow w/ Poor Quality',
                        'link': 'running_poorly'
                    }
                ]
            },
            {
                'name': 'running_slow',
                'displayName': 'Running Slow',
                'isDummyGroup': false,
                'entries': [
                    {
                        'name': 'running_slow',
                        'displayName': 'Slow and/or Small Stops',
                        'link': 'running_slow'
                    }
                ]
            }
        ],
        'performance_impact': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': 'run',
                        'displayName': 'Run'
                    },
                    {
                        'name': 'planned_stop',
                        'displayName': 'Planned Stop'
                    },
                    {
                        'name': 'unplanned_stop',
                        'displayName': 'Unplanned Stop'
                    },
                    {
                        'name': 'not_scheduled',
                        'displayName': 'Not Scheduled'
                    }
                ]
            }
        ],
        'production_phase': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': 'changeover',
                        'displayName': 'Changeover'
                    },
                    {
                        'name': 'startup',
                        'displayName': 'Startup'
                    },
                    {
                        'name': 'steady_state',
                        'displayName': 'Steady State'
                    }
                ]
            }
        ],
        'shift_hour': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': '1',
                        'displayName': 'Shift Hour 01'
                    },
                    {
                        'name': '2',
                        'displayName': 'Shift Hour 02'
                    },
                    {
                        'name': '3',
                        'displayName': 'Shift Hour 03'
                    },
                    {
                        'name': '4',
                        'displayName': 'Shift Hour 04'
                    },
                    {
                        'name': '5',
                        'displayName': 'Shift Hour 05'
                    },
                    {
                        'name': '6',
                        'displayName': 'Shift Hour 06'
                    },
                    {
                        'name': '7',
                        'displayName': 'Shift Hour 07'
                    },
                    {
                        'name': '8',
                        'displayName': 'Shift Hour 08'
                    },
                    {
                        'name': '9',
                        'displayName': 'Shift Hour 09'
                    },
                    {
                        'name': '10',
                        'displayName': 'Shift Hour 10'
                    },
                    {
                        'name': '11',
                        'displayName': 'Shift Hour 11'
                    },
                    {
                        'name': '12',
                        'displayName': 'Shift Hour 12'
                    },
                    {
                        'name': '13',
                        'displayName': 'Shift Hour 13'
                    },
                    {
                        'name': '14',
                        'displayName': 'Shift Hour 14'
                    },
                    {
                        'name': '15',
                        'displayName': 'Shift Hour 15'
                    },
                    {
                        'name': '16',
                        'displayName': 'Shift Hour 16'
                    },
                    {
                        'name': '17',
                        'displayName': 'Shift Hour 17'
                    },
                    {
                        'name': '18',
                        'displayName': 'Shift Hour 18'
                    },
                    {
                        'name': '19',
                        'displayName': 'Shift Hour 19'
                    },
                    {
                        'name': '20',
                        'displayName': 'Shift Hour 20'
                    },
                    {
                        'name': '21',
                        'displayName': 'Shift Hour 21'
                    },
                    {
                        'name': '22',
                        'displayName': 'Shift Hour 22'
                    },
                    {
                        'name': '23',
                        'displayName': 'Shift Hour 23'
                    },
                    {
                        'name': '24',
                        'displayName': 'Shift Hour 24'
                    },
                    {
                        'name': 'extra',
                        'displayName': 'Shift Hour 25+'
                    }
                ]
            }
        ],
        'ordinal_shift': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': '1',
                        'displayName': 'Ordinal Shift 1'
                    },
                    {
                        'name': '2',
                        'displayName': 'Ordinal Shift 2'
                    },
                    {
                        'name': '3',
                        'displayName': 'Ordinal Shift 3'
                    },
                    {
                        'name': '4',
                        'displayName': 'Ordinal Shift 4'
                    },
                    {
                        'name': '5',
                        'displayName': 'Ordinal Shift 5'
                    },
                    {
                        'name': '6',
                        'displayName': 'Ordinal Shift 6'
                    },
                    {
                        'name': '7',
                        'displayName': 'Ordinal Shift 7'
                    },
                    {
                        'name': '8',
                        'displayName': 'Ordinal Shift 8'
                    },
                    {
                        'name': '9',
                        'displayName': 'Ordinal Shift 9+'
                    }
                ]
            }
        ],
        'production_day': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': '0',
                        'displayName': 'Monday'
                    },
                    {
                        'name': '1',
                        'displayName': 'Tuesday'
                    },
                    {
                        'name': '2',
                        'displayName': 'Wednesday'
                    },
                    {
                        'name': '3',
                        'displayName': 'Thursday'
                    },
                    {
                        'name': '4',
                        'displayName': 'Friday'
                    },
                    {
                        'name': '5',
                        'displayName': 'Saturday'
                    },
                    {
                        'name': '6',
                        'displayName': 'Sunday'
                    }
                ]
            }
        ],
        'week': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': '1',
                        'displayName': 'Week 01'
                    },
                    {
                        'name': '2',
                        'displayName': 'Week 02'
                    },
                    {
                        'name': '3',
                        'displayName': 'Week 03'
                    },
                    {
                        'name': '4',
                        'displayName': 'Week 04'
                    },
                    {
                        'name': '5',
                        'displayName': 'Week 05'
                    },
                    {
                        'name': '6',
                        'displayName': 'Week 06'
                    },
                    {
                        'name': '7',
                        'displayName': 'Week 07'
                    },
                    {
                        'name': '8',
                        'displayName': 'Week 08'
                    },
                    {
                        'name': '9',
                        'displayName': 'Week 09'
                    },
                    {
                        'name': '10',
                        'displayName': 'Week 10'
                    },
                    {
                        'name': '11',
                        'displayName': 'Week 11'
                    },
                    {
                        'name': '12',
                        'displayName': 'Week 12'
                    },
                    {
                        'name': '13',
                        'displayName': 'Week 13'
                    },
                    {
                        'name': '14',
                        'displayName': 'Week 14'
                    },
                    {
                        'name': '15',
                        'displayName': 'Week 15'
                    },
                    {
                        'name': '16',
                        'displayName': 'Week 16'
                    },
                    {
                        'name': '17',
                        'displayName': 'Week 17'
                    },
                    {
                        'name': '18',
                        'displayName': 'Week 18'
                    },
                    {
                        'name': '19',
                        'displayName': 'Week 19'
                    },
                    {
                        'name': '20',
                        'displayName': 'Week 20'
                    },
                    {
                        'name': '21',
                        'displayName': 'Week 21'
                    },
                    {
                        'name': '22',
                        'displayName': 'Week 22'
                    },
                    {
                        'name': '23',
                        'displayName': 'Week 23'
                    },
                    {
                        'name': '24',
                        'displayName': 'Week 24'
                    },
                    {
                        'name': '25',
                        'displayName': 'Week 25'
                    },
                    {
                        'name': '26',
                        'displayName': 'Week 26'
                    },
                    {
                        'name': '27',
                        'displayName': 'Week 27'
                    },
                    {
                        'name': '28',
                        'displayName': 'Week 28'
                    },
                    {
                        'name': '29',
                        'displayName': 'Week 29'
                    },
                    {
                        'name': '30',
                        'displayName': 'Week 30'
                    },
                    {
                        'name': '31',
                        'displayName': 'Week 31'
                    },
                    {
                        'name': '32',
                        'displayName': 'Week 32'
                    },
                    {
                        'name': '33',
                        'displayName': 'Week 33'
                    },
                    {
                        'name': '34',
                        'displayName': 'Week 34'
                    },
                    {
                        'name': '35',
                        'displayName': 'Week 35'
                    },
                    {
                        'name': '36',
                        'displayName': 'Week 36'
                    },
                    {
                        'name': '37',
                        'displayName': 'Week 37'
                    },
                    {
                        'name': '38',
                        'displayName': 'Week 38'
                    },
                    {
                        'name': '39',
                        'displayName': 'Week 39'
                    },
                    {
                        'name': '40',
                        'displayName': 'Week 40'
                    },
                    {
                        'name': '41',
                        'displayName': 'Week 41'
                    },
                    {
                        'name': '42',
                        'displayName': 'Week 42'
                    },
                    {
                        'name': '43',
                        'displayName': 'Week 43'
                    },
                    {
                        'name': '44',
                        'displayName': 'Week 44'
                    },
                    {
                        'name': '45',
                        'displayName': 'Week 45'
                    },
                    {
                        'name': '46',
                        'displayName': 'Week 46'
                    },
                    {
                        'name': '47',
                        'displayName': 'Week 47'
                    },
                    {
                        'name': '48',
                        'displayName': 'Week 48'
                    },
                    {
                        'name': '49',
                        'displayName': 'Week 49'
                    },
                    {
                        'name': '50',
                        'displayName': 'Week 50'
                    },
                    {
                        'name': '51',
                        'displayName': 'Week 51'
                    },
                    {
                        'name': '52',
                        'displayName': 'Week 52'
                    },
                    {
                        'name': '53',
                        'displayName': 'Week 53'
                    },
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown'
                    }
                ]
            }
        ],
        'month': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': '1',
                        'displayName': 'January'
                    },
                    {
                        'name': '2',
                        'displayName': 'February'
                    },
                    {
                        'name': '3',
                        'displayName': 'March'
                    },
                    {
                        'name': '4',
                        'displayName': 'April'
                    },
                    {
                        'name': '5',
                        'displayName': 'May'
                    },
                    {
                        'name': '6',
                        'displayName': 'June'
                    },
                    {
                        'name': '7',
                        'displayName': 'July'
                    },
                    {
                        'name': '8',
                        'displayName': 'August'
                    },
                    {
                        'name': '9',
                        'displayName': 'September'
                    },
                    {
                        'name': '10',
                        'displayName': 'October'
                    },
                    {
                        'name': '11',
                        'displayName': 'November'
                    },
                    {
                        'name': '12',
                        'displayName': 'December'
                    },
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown'
                    }
                ]
            }
        ],
        'quarter': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': '1',
                        'displayName': 'Q1'
                    },
                    {
                        'name': '2',
                        'displayName': 'Q2'
                    },
                    {
                        'name': '3',
                        'displayName': 'Q3'
                    },
                    {
                        'name': '4',
                        'displayName': 'Q4'
                    },
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown'
                    }
                ]
            }
        ],
        'year': [
            {
                'name': 'none',
                'displayName': 'none',
                'isDummyGroup': true,
                'entries': [
                    {
                        'name': '1999',
                        'displayName': '1999'
                    },
                    {
                        'name': '2000',
                        'displayName': '2000'
                    },
                    {
                        'name': '2001',
                        'displayName': '2001'
                    },
                    {
                        'name': '2002',
                        'displayName': '2002'
                    },
                    {
                        'name': '2003',
                        'displayName': '2003'
                    },
                    {
                        'name': '2004',
                        'displayName': '2004'
                    },
                    {
                        'name': '2005',
                        'displayName': '2005'
                    },
                    {
                        'name': '2006',
                        'displayName': '2006'
                    },
                    {
                        'name': '2007',
                        'displayName': '2007'
                    },
                    {
                        'name': '2008',
                        'displayName': '2008'
                    },
                    {
                        'name': '2009',
                        'displayName': '2009'
                    },
                    {
                        'name': '2010',
                        'displayName': '2010'
                    },
                    {
                        'name': '2011',
                        'displayName': '2011'
                    },
                    {
                        'name': '2012',
                        'displayName': '2012'
                    },
                    {
                        'name': '2013',
                        'displayName': '2013'
                    },
                    {
                        'name': '2014',
                        'displayName': '2014'
                    },
                    {
                        'name': '2015',
                        'displayName': '2015'
                    },
                    {
                        'name': '2016',
                        'displayName': '2016'
                    },
                    {
                        'name': '2017',
                        'displayName': '2017'
                    },
                    {
                        'name': '2018',
                        'displayName': '2018'
                    },
                    {
                        'name': '2019',
                        'displayName': '2019'
                    },
                    {
                        'name': '2020',
                        'displayName': '2020'
                    },
                    {
                        'name': '2021',
                        'displayName': '2021'
                    },
                    {
                        'name': '2022',
                        'displayName': '2022'
                    },
                    {
                        'name': '2023',
                        'displayName': '2023'
                    },
                    {
                        'name': '2024',
                        'displayName': '2024'
                    },
                    {
                        'name': '2025',
                        'displayName': '2025'
                    },
                    {
                        'name': '2026',
                        'displayName': '2026'
                    },
                    {
                        'name': '2027',
                        'displayName': '2027'
                    },
                    {
                        'name': '2028',
                        'displayName': '2028'
                    },
                    {
                        'name': '2029',
                        'displayName': '2029'
                    },
                    {
                        'name': '2030',
                        'displayName': '2030'
                    },
                    {
                        'name': 'unknown',
                        'displayName': 'Unknown'
                    }
                ]
            }
        ]
    }
};

const mockDimensionValues = {
    asset: [
        'Stamping 1',
        'Stamping 2',
        'Stamping 3',
        'Bottling 1',
        'Bottling 2',
        'Bottling 3',
    ],
    part: [
        'Part A',
        'Part B',
        'Part C',
        'Part D',
        'Part E',
    ],
    process_state_reason: [
        'Material Change',
        'Part Change',
        'Setup',
        'Breakdown',
        'Jam',
        'Adjustment',
        'Autonomous Maintenance',
        'No Material',
    ],
    reject_reason: [
        'Cosmetic Defect',
        'Scratch',
        'Underweight',
        'Overweight',
        'Out of Spec',
    ],
};

const getColumnChannelName = (columnId) => {
    const channel = columnId.channel ? columnId.channel : 'production_metric';
    const columnName = columnId.name ? columnId.name : columnId;
    return {
        channel,
        columnName,
    };
};

// helper function to find the columnConfig from the channel schema
const getColumnConfig = (channel, columnName) => {
    let columnConfig = channel_schema[channel].metrics[columnName];
    if (columnConfig) {
        return columnConfig;
    }

    columnConfig = channel_schema[channel].category_values[columnName];
    if (columnConfig) {
        return columnConfig;
    }

    if (columnName === 'asset') {
        return {
            category: 'asset',
            display_name: 'email_table.group.work_center.display_name',
            help_text: 'term.part.short',
            indexed: true,
            indexed_with: [
                'record_order'
            ],
            name: 'asset',
            persist: true,
            rollup: true,
            formatType: 'category_value'
        };
    }

    return {};
};

/**
 * creates a js object with fake data for a column that can then be used by widgets to display something
 */
const getMockData = (columnId, translator, index, metricThresholds) => {
    const { channel, columnName } = getColumnChannelName(columnId);

    const columnConfig = getColumnConfig(channel, columnName);
    // if category exists in config then it is a category value
    const isCategoryValue = !!columnConfig.category;

    let format = getColumnFormatType(columnConfig, isCategoryValue);

    const displayName = translator ? translator(columnConfig.display_name) : columnName;

    let rawValue;

    if (format === 'count') {
        rawValue = 1234;
    }
    else if (format === 'cycle') {
        rawValue = 1234;
    }
    else if (format === 'cycle_time') {
        rawValue = 1.23;
    }
    else if (format === 'percent') {
        // heatConfig = {
        //     'severity': 'caution',
        //     'color': '#84a213',
        // };
        rawValue = 0.123;
    }
    else if (format === 'rate') {
        rawValue = 12.34;
    }
    else if (format === 'rate_per_hour') {
        rawValue = 123.4;
    }
    else if (format === 'time_span') {
        rawValue = 5025;
    }
    else if (format === 'category_value') {
        const randomValues = mockDimensionValues[columnConfig.category];
        if (!randomValues) {
            console.error(`Could not find random list for ${columnConfig.category}, update mockDimensionValues`);
        }
        const ii = index % randomValues.length;
        rawValue = {
            displayName: randomValues[ii],
        };
    }
    else if (format === 'date_time') {
        const now = new Date();
        now.setMinutes(now.getMinutes() - index * 11);
        rawValue = OffsetDate.fromDate(now, now.getTimezoneOffset() * -1);
    }
    else {
        rawValue = 999;
    }

    let heatConfig;

    if (metricThresholds?.[columnName]) {
        heatConfig = getHeatConfig(columnName, rawValue, metricThresholds[columnName]);
    }

    return {
        name: columnName,
        formatType: format,
        heatConfig,
        rawValue,
        displayName,
    };
};

/**
 * generate fake KPI Group data based on widget stf
 */
export const getKpiGroupData = (widgetData, translator, metricThresholds) => {
    const {
        fields
    } = widgetData;

    // for each field in fields create an entry into an js object indexed by column name pointing
    // at data for that column
    let fieldData = fields.reduce((rv, field) => {
        const data = getMockData(field.name, translator, null, metricThresholds);
        rv[field.name] = data;
        return rv;
    }, {});

    return {
        response: fieldData,
        error: null
    };
};

/**
 * generate fake KPI data based on widget stf
 */
export const getKpiData = (name, translator, metricThresholds) => {
    const data = getMockData(name, translator, null, metricThresholds);

    return {
        response: {
            metricData: data,
        },
        error: null
    };
};

export const hierarchyNodeDisplayName = {
    'response': 'Node Display Name',
    'error': null
};

export const timeRangePrimaryDetails = {
    'response': null,
    'error': null
};

export const timeRangeSecondaryDetails = {
    'response': null,
    'error': null
};

export const emailWidgetFieldOptions = {
    'metric': [
        {
            'name': 'counts',
            'displayName': 'Counts',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'pack_out_count',
                    'channel': 'production_metric',
                    'displayName': 'End of Line Count',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'good_count',
                    'channel': 'production_metric',
                    'displayName': 'Good Count',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'in_count',
                    'channel': 'production_metric',
                    'displayName': 'In Count',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'percent_good',
                    'channel': 'production_metric',
                    'displayName': 'Percent Good',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'percent_reject',
                    'channel': 'production_metric',
                    'displayName': 'Percent Reject',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'production_rejects',
                    'channel': 'production_metric',
                    'displayName': 'Production Rejects',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'reject_count',
                    'channel': 'production_metric',
                    'displayName': 'Reject Count',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'startup_rejects',
                    'channel': 'production_metric',
                    'displayName': 'Startup Rejects',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'total_count',
                    'channel': 'production_metric',
                    'displayName': 'Total Count',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                }
            ]
        },
        {
            'name': 'cycles',
            'displayName': 'Cycles',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'average_cycle_time',
                    'channel': 'production_metric',
                    'displayName': 'Avg. Cycle Time',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'average_ideal_cycle_time',
                    'channel': 'production_metric',
                    'displayName': 'Avg. Ideal Cycle Time',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'average_run_cycle_time',
                    'channel': 'production_metric',
                    'displayName': 'Avg. Run Cycle Time',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'average_small_stop_time',
                    'channel': 'production_metric',
                    'displayName': 'Avg. Small Stop Time',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'equipment_cycles',
                    'channel': 'production_metric',
                    'displayName': 'Equipment Cycles',
                    'fieldType': 'metric',
                    'type': 'uint64'
                },
                {
                    'name': 'ideal_time',
                    'channel': 'production_metric',
                    'displayName': 'Ideal Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'partial_cycle_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Partial Cycle Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'partial_cycles',
                    'channel': 'production_metric',
                    'displayName': 'Partial Cycles',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'cycle'
                },
                {
                    'name': 'run_cycle_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Run Cycle Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'run_cycles',
                    'channel': 'production_metric',
                    'displayName': 'Run Cycles',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'cycle'
                },
                {
                    'name': 'small_stops',
                    'channel': 'production_metric',
                    'displayName': 'Small Stops',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'cycle'
                },
                {
                    'name': 'total_cycle_time',
                    'channel': 'production_metric',
                    'displayName': 'Total Cycle Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'total_cycles',
                    'channel': 'production_metric',
                    'displayName': 'Total Cycles',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'cycle'
                }
            ]
        },
        {
            'name': 'labor',
            'displayName': 'Labor',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'earned_labor',
                    'channel': 'production_metric',
                    'displayName': 'Earned Labor',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'good_pieces_per_labor_hour',
                    'channel': 'production_metric',
                    'displayName': 'Good Pieces per Labor Hour',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'labor_efficiency',
                    'channel': 'production_metric',
                    'displayName': 'Labor Efficiency',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'labor_per_good_piece',
                    'channel': 'production_metric',
                    'displayName': 'Labor per Good Piece',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'labor_per_piece',
                    'channel': 'production_metric',
                    'displayName': 'Labor per Piece',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'lost_labor',
                    'channel': 'production_metric',
                    'displayName': 'Lost Labor',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'pieces_per_labor_hour',
                    'channel': 'production_metric',
                    'displayName': 'Pieces per Labor Hour',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'target_labor_per_piece',
                    'channel': 'production_metric',
                    'displayName': 'Target Labor per Piece',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'average_team_size',
                    'channel': 'production_metric',
                    'displayName': 'Team Size',
                    'fieldType': 'metric',
                    'type': 'double'
                },
                {
                    'name': 'labor',
                    'channel': 'production_metric',
                    'displayName': 'Total Labor',
                    'fieldType': 'metric',
                    'type': 'time_span'
                }
            ]
        },
        {
            'name': 'oee',
            'displayName': 'OEE',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'availability',
                    'channel': 'production_metric',
                    'displayName': 'Availability',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'availability_loss',
                    'channel': 'production_metric',
                    'displayName': 'Availability Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'availability_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Availability Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'fully_productive_time',
                    'channel': 'production_metric',
                    'displayName': 'Fully Productive Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'oee',
                    'channel': 'production_metric',
                    'displayName': 'OEE',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'oee_loss',
                    'channel': 'production_metric',
                    'displayName': 'OEE Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'oee_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'OEE Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'performance',
                    'channel': 'production_metric',
                    'displayName': 'Performance',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'performance_loss',
                    'channel': 'production_metric',
                    'displayName': 'Performance Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'performance_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Performance Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'quality',
                    'channel': 'production_metric',
                    'displayName': 'Quality',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'quality_loss',
                    'channel': 'production_metric',
                    'displayName': 'Quality Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'quality_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Quality Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                }
            ]
        },
        {
            'name': 'production_times',
            'displayName': 'Production Times',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'all_time',
                    'channel': 'production_metric',
                    'displayName': 'All Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'down_time',
                    'channel': 'production_metric',
                    'displayName': 'Down Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'duration',
                    'channel': 'production_metric',
                    'displayName': 'Duration',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'end_time',
                    'channel': 'production_metric',
                    'displayName': 'End Time',
                    'fieldType': 'metric',
                    'type': 'date_time'
                },
                {
                    'name': 'manufacturing_time',
                    'channel': 'production_metric',
                    'displayName': 'Manufacturing Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'not_scheduled_time',
                    'channel': 'production_metric',
                    'displayName': 'Not Scheduled Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'percent_down',
                    'channel': 'production_metric',
                    'displayName': 'Percent Down',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'percent_planned_stop',
                    'channel': 'production_metric',
                    'displayName': 'Percent Planned Stop',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'percent_run',
                    'channel': 'production_metric',
                    'displayName': 'Percent Run',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'percent_unplanned_stop',
                    'channel': 'production_metric',
                    'displayName': 'Percent Unplanned Stop',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'planned_stop_time',
                    'channel': 'production_metric',
                    'displayName': 'Planned Stop Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'production_time',
                    'channel': 'production_metric',
                    'displayName': 'Production Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'run_time',
                    'channel': 'production_metric',
                    'displayName': 'Run Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'start_time',
                    'channel': 'production_metric',
                    'displayName': 'Start Time',
                    'fieldType': 'metric',
                    'type': 'date_time'
                },
                {
                    'name': 'unplanned_stop_time',
                    'channel': 'production_metric',
                    'displayName': 'Unplanned Stop Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                }
            ]
        },
        {
            'name': 'rates',
            'displayName': 'Rates',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'pack_out_rate_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'End of Line Rate PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'pack_out_rate',
                    'channel': 'production_metric',
                    'displayName': 'End of Line Rate PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'pack_out_speed_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'End of Line Speed PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'pack_out_speed',
                    'channel': 'production_metric',
                    'displayName': 'End of Line Speed PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'good_rate_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'Good Rate PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'good_rate',
                    'channel': 'production_metric',
                    'displayName': 'Good Rate PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'good_speed_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'Good Speed PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'good_speed',
                    'channel': 'production_metric',
                    'displayName': 'Good Speed PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'in_rate_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'In Rate PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'in_rate',
                    'channel': 'production_metric',
                    'displayName': 'In Rate PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'in_speed_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'In Speed PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'in_speed',
                    'channel': 'production_metric',
                    'displayName': 'In Speed PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'reject_rate_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'Reject Rate PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'reject_rate',
                    'channel': 'production_metric',
                    'displayName': 'Reject Rate PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'reject_speed_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'Reject Speed PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'reject_speed',
                    'channel': 'production_metric',
                    'displayName': 'Reject Speed PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'target_rate_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'Target Rate PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'target_rate',
                    'channel': 'production_metric',
                    'displayName': 'Target Rate PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'total_rate_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'Total Rate PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'total_rate',
                    'channel': 'production_metric',
                    'displayName': 'Total Rate PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                },
                {
                    'name': 'total_speed_per_hour',
                    'channel': 'production_metric',
                    'displayName': 'Total Speed PPH',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate_per_hour'
                },
                {
                    'name': 'total_speed',
                    'channel': 'production_metric',
                    'displayName': 'Total Speed PPM',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'rate'
                }
            ]
        },
        {
            'name': 'six_big_losses',
            'displayName': 'Six Big Losses',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'cycle_loss',
                    'channel': 'production_metric',
                    'displayName': 'Cycle Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'cycle_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Cycle Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'down_loss',
                    'channel': 'production_metric',
                    'displayName': 'Down Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'down_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Down Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'planned_stop_loss',
                    'channel': 'production_metric',
                    'displayName': 'Planned Stop Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'planned_stop_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Planned Stop Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'production_reject_loss',
                    'channel': 'production_metric',
                    'displayName': 'Production Reject Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'production_reject_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Production Reject Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'small_stop_loss',
                    'channel': 'production_metric',
                    'displayName': 'Small Stop Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'small_stop_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Small Stop Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'startup_reject_loss',
                    'channel': 'production_metric',
                    'displayName': 'Startup Reject Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'startup_reject_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Startup Reject Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'unplanned_stop_loss',
                    'channel': 'production_metric',
                    'displayName': 'Unplanned Stop Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'unplanned_stop_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Unplanned Stop Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                }
            ]
        },
        {
            'name': 'target',
            'displayName': 'Target',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'average_takt_time',
                    'channel': 'production_metric',
                    'displayName': 'Avg. Takt Time',
                    'fieldType': 'metric',
                    'type': 'time_span',
                    'subType': 'cycle_time'
                },
                {
                    'name': 'count_variance',
                    'channel': 'production_metric',
                    'displayName': 'Count Variance',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'efficiency',
                    'channel': 'production_metric',
                    'displayName': 'Efficiency',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'percent_variance',
                    'channel': 'production_metric',
                    'displayName': 'Percent Variance',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'rate_efficiency',
                    'channel': 'production_metric',
                    'displayName': 'Rate Efficiency',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'target_count',
                    'channel': 'production_metric',
                    'displayName': 'Target Count',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'count'
                },
                {
                    'name': 'target_cycles',
                    'channel': 'production_metric',
                    'displayName': 'Target Cycles',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'cycle'
                },
                {
                    'name': 'time_variance',
                    'channel': 'production_metric',
                    'displayName': 'Time Variance',
                    'fieldType': 'metric',
                    'type': 'time_span'
                }
            ]
        },
        {
            'name': 'teep',
            'displayName': 'TEEP',
            'isDummyGroup': false,
            'entries': [
                {
                    'name': 'hidden_factory_time',
                    'channel': 'production_metric',
                    'displayName': 'Hidden Factory Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'production_loss',
                    'channel': 'production_metric',
                    'displayName': 'Production Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'production_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Production Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'capacity_loss',
                    'channel': 'production_metric',
                    'displayName': 'Schedule Loss',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'capacity_lost_time',
                    'channel': 'production_metric',
                    'displayName': 'Schedule Lost Time',
                    'fieldType': 'metric',
                    'type': 'time_span'
                },
                {
                    'name': 'teep',
                    'channel': 'production_metric',
                    'displayName': 'TEEP',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                },
                {
                    'name': 'utilization',
                    'channel': 'production_metric',
                    'displayName': 'Utilization',
                    'fieldType': 'metric',
                    'type': 'double',
                    'subType': 'percent'
                }
            ]
        }
    ],
    'metric_set': [
        {
            'name': 'overview',
            'displayName': 'Overview',
            'entries': [
                {
                    'name': 'overview_set',
                    'displayName': 'Production Overview',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'overview',
                    'group': 'overview',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'OEE',
                            'fieldType': 'metric',
                            'name': 'oee',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'In Count',
                            'fieldType': 'metric',
                            'name': 'in_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Good Count',
                            'fieldType': 'metric',
                            'name': 'good_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Run Time',
                            'fieldType': 'metric',
                            'name': 'run_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Down Time',
                            'fieldType': 'metric',
                            'name': 'down_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Planned Stop Time',
                            'fieldType': 'metric',
                            'name': 'planned_stop_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'target_overview_set',
                    'displayName': 'Target Overview',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'target_overview',
                    'group': 'overview',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Target Count',
                            'fieldType': 'metric',
                            'name': 'target_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Good Count',
                            'fieldType': 'metric',
                            'name': 'good_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Efficiency',
                            'fieldType': 'metric',
                            'name': 'efficiency',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Down Time',
                            'fieldType': 'metric',
                            'name': 'down_time',
                            'type': 'time_span'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        },
        {
            'name': 'counts',
            'displayName': 'Counts',
            'entries': [
                {
                    'name': 'counts_set',
                    'displayName': 'Count Overview',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'counts',
                    'group': 'counts',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'In Count',
                            'fieldType': 'metric',
                            'name': 'in_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Good Count',
                            'fieldType': 'metric',
                            'name': 'good_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Reject Count',
                            'fieldType': 'metric',
                            'name': 'reject_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'End of Line Count',
                            'fieldType': 'metric',
                            'name': 'pack_out_count',
                            'subType': 'count',
                            'type': 'double'
                        }
                    ]
                },
                {
                    'name': 'reject_count_set',
                    'displayName': 'Reject Count Overview',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'reject_count',
                    'group': 'counts',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Reject Count',
                            'fieldType': 'metric',
                            'name': 'reject_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Percent Reject',
                            'fieldType': 'metric',
                            'name': 'percent_reject',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Startup Rejects',
                            'fieldType': 'metric',
                            'name': 'startup_rejects',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Production Rejects',
                            'fieldType': 'metric',
                            'name': 'production_rejects',
                            'subType': 'count',
                            'type': 'double'
                        }
                    ]
                },
                {
                    'name': 'total_count_set',
                    'displayName': 'Total Count Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'total_count',
                    'group': 'counts',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Total Count',
                            'fieldType': 'metric',
                            'name': 'total_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Good Count',
                            'fieldType': 'metric',
                            'name': 'good_count',
                            'subType': 'count',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Reject Count',
                            'fieldType': 'metric',
                            'name': 'reject_count',
                            'subType': 'count',
                            'type': 'double'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        },
        {
            'name': 'cycles',
            'displayName': 'Cycles',
            'entries': [
                {
                    'name': 'cycle_time_set',
                    'displayName': 'Total Cycle Time Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'cycle_time',
                    'group': 'cycles',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Total Cycle Time',
                            'fieldType': 'metric',
                            'name': 'total_cycle_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Ideal Time',
                            'fieldType': 'metric',
                            'name': 'ideal_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Cycle Lost Time',
                            'fieldType': 'metric',
                            'name': 'cycle_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Small Stop Lost Time',
                            'fieldType': 'metric',
                            'name': 'small_stop_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Partial Cycle Lost Time',
                            'fieldType': 'metric',
                            'name': 'partial_cycle_lost_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'cycle_time_validation_set',
                    'displayName': 'Cycle Time Audit',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'cycle_time_validation',
                    'group': 'cycles',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance',
                            'fieldType': 'metric',
                            'name': 'performance',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Avg. Ideal Cycle Time',
                            'fieldType': 'metric',
                            'name': 'average_ideal_cycle_time',
                            'subType': 'cycle_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Avg. Run Cycle Time',
                            'fieldType': 'metric',
                            'name': 'average_run_cycle_time',
                            'subType': 'cycle_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Run Cycles',
                            'fieldType': 'metric',
                            'name': 'run_cycles',
                            'subType': 'cycle',
                            'type': 'double'
                        }
                    ]
                },
                {
                    'name': 'cycles_set',
                    'displayName': 'Total Cycles Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'cycles',
                    'group': 'cycles',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Total Cycles',
                            'fieldType': 'metric',
                            'name': 'total_cycles',
                            'subType': 'cycle',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Run Cycles',
                            'fieldType': 'metric',
                            'name': 'run_cycles',
                            'subType': 'cycle',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Small Stops',
                            'fieldType': 'metric',
                            'name': 'small_stops',
                            'subType': 'cycle',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Partial Cycles',
                            'fieldType': 'metric',
                            'name': 'partial_cycles',
                            'subType': 'cycle',
                            'type': 'double'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        },
        {
            'name': 'times',
            'displayName': 'Times',
            'entries': [
                {
                    'name': 'all_time_set',
                    'displayName': 'All Time Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'all_time',
                    'group': 'times',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'All Time',
                            'fieldType': 'metric',
                            'name': 'all_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Run Time',
                            'fieldType': 'metric',
                            'name': 'run_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Down Time',
                            'fieldType': 'metric',
                            'name': 'down_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Planned Stop Time',
                            'fieldType': 'metric',
                            'name': 'planned_stop_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Not Scheduled Time',
                            'fieldType': 'metric',
                            'name': 'not_scheduled_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'production_time_set',
                    'displayName': 'Production Time Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'production_time',
                    'group': 'times',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Production Time',
                            'fieldType': 'metric',
                            'name': 'production_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Run Time',
                            'fieldType': 'metric',
                            'name': 'run_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Down Time',
                            'fieldType': 'metric',
                            'name': 'down_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Planned Stop Time',
                            'fieldType': 'metric',
                            'name': 'planned_stop_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'production_time_percentages_set',
                    'displayName': 'Production Time Percentages',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'production_time_percentages',
                    'group': 'times',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Production Time',
                            'fieldType': 'metric',
                            'name': 'production_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Percent Run',
                            'fieldType': 'metric',
                            'name': 'percent_run',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Percent Down',
                            'fieldType': 'metric',
                            'name': 'percent_down',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Percent Planned Stop',
                            'fieldType': 'metric',
                            'name': 'percent_planned_stop',
                            'subType': 'percent',
                            'type': 'double'
                        }
                    ]
                },
                {
                    'name': 'run_time_set',
                    'displayName': 'Run Time Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'run_time',
                    'group': 'times',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Run Time',
                            'fieldType': 'metric',
                            'name': 'run_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Fully Productive Time',
                            'fieldType': 'metric',
                            'name': 'fully_productive_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Cycle Lost Time',
                            'fieldType': 'metric',
                            'name': 'cycle_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Small Stop Lost Time',
                            'fieldType': 'metric',
                            'name': 'small_stop_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality Lost Time',
                            'fieldType': 'metric',
                            'name': 'quality_lost_time',
                            'type': 'time_span'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        },
        {
            'name': 'labor',
            'displayName': 'Labor',
            'entries': [
                {
                    'name': 'labor_overview_set',
                    'displayName': 'Labor Overview',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'labor_overview',
                    'group': 'labor',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Team Size',
                            'fieldType': 'metric',
                            'name': 'average_team_size',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Total Labor',
                            'fieldType': 'metric',
                            'name': 'labor',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Lost Labor',
                            'fieldType': 'metric',
                            'name': 'lost_labor',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Labor Efficiency',
                            'fieldType': 'metric',
                            'name': 'labor_efficiency',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Target Labor per Piece',
                            'fieldType': 'metric',
                            'name': 'target_labor_per_piece',
                            'subType': 'cycle_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Labor per Good Piece',
                            'fieldType': 'metric',
                            'name': 'labor_per_good_piece',
                            'subType': 'cycle_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'labor_time_set',
                    'displayName': 'Labor Time Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'labor_time',
                    'group': 'labor',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Total Labor',
                            'fieldType': 'metric',
                            'name': 'labor',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Lost Labor',
                            'fieldType': 'metric',
                            'name': 'lost_labor',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Earned Labor',
                            'fieldType': 'metric',
                            'name': 'earned_labor',
                            'type': 'time_span'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        },
        {
            'name': 'oee',
            'displayName': 'OEE',
            'entries': [
                {
                    'name': 'oee_loss_set',
                    'displayName': 'OEE Loss Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'oee_loss',
                    'group': 'oee',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'OEE Loss',
                            'fieldType': 'metric',
                            'name': 'oee_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Availability Loss',
                            'fieldType': 'metric',
                            'name': 'availability_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance Loss',
                            'fieldType': 'metric',
                            'name': 'performance_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality Loss',
                            'fieldType': 'metric',
                            'name': 'quality_loss',
                            'subType': 'percent',
                            'type': 'double'
                        }
                    ]
                },
                {
                    'name': 'oee_lost_time_set',
                    'displayName': 'OEE Lost Time Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'oee_lost_time',
                    'group': 'oee',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'OEE Lost Time',
                            'fieldType': 'metric',
                            'name': 'oee_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Availability Lost Time',
                            'fieldType': 'metric',
                            'name': 'availability_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance Lost Time',
                            'fieldType': 'metric',
                            'name': 'performance_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality Lost Time',
                            'fieldType': 'metric',
                            'name': 'quality_lost_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'oee_waterfall_set',
                    'displayName': 'OEE Waterfall',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'oee_waterfall',
                    'group': 'oee',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Production Time',
                            'fieldType': 'metric',
                            'name': 'production_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Availability Lost Time',
                            'fieldType': 'metric',
                            'name': 'availability_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance Lost Time',
                            'fieldType': 'metric',
                            'name': 'performance_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality Lost Time',
                            'fieldType': 'metric',
                            'name': 'quality_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Fully Productive Time',
                            'fieldType': 'metric',
                            'name': 'fully_productive_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'oee_with_factors_set',
                    'displayName': 'OEE Factors',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'oee_with_factors',
                    'group': 'oee',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'OEE',
                            'fieldType': 'metric',
                            'name': 'oee',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Availability',
                            'fieldType': 'metric',
                            'name': 'availability',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance',
                            'fieldType': 'metric',
                            'name': 'performance',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality',
                            'fieldType': 'metric',
                            'name': 'quality',
                            'subType': 'percent',
                            'type': 'double'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        },
        {
            'name': 'six_big_losses',
            'displayName': 'Six Big Losses',
            'entries': [
                {
                    'name': 'six_big_losses_set',
                    'displayName': 'Six Big Loss Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'six_big_losses',
                    'group': 'six_big_losses',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'OEE Loss',
                            'fieldType': 'metric',
                            'name': 'oee_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Down Loss',
                            'fieldType': 'metric',
                            'name': 'down_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Planned Stop Loss',
                            'fieldType': 'metric',
                            'name': 'planned_stop_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Cycle Loss',
                            'fieldType': 'metric',
                            'name': 'cycle_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Small Stop Loss',
                            'fieldType': 'metric',
                            'name': 'small_stop_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Startup Reject Loss',
                            'fieldType': 'metric',
                            'name': 'startup_reject_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Production Reject Loss',
                            'fieldType': 'metric',
                            'name': 'production_reject_loss',
                            'subType': 'percent',
                            'type': 'double'
                        }
                    ]
                },
                {
                    'name': 'six_big_losses_lost_time_set',
                    'displayName': 'Six Big Loss Time Detail',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'six_big_losses_lost_time',
                    'group': 'six_big_losses',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'OEE Lost Time',
                            'fieldType': 'metric',
                            'name': 'oee_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Down Lost Time',
                            'fieldType': 'metric',
                            'name': 'down_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Planned Stop Lost Time',
                            'fieldType': 'metric',
                            'name': 'planned_stop_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Cycle Lost Time',
                            'fieldType': 'metric',
                            'name': 'cycle_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Small Stop Lost Time',
                            'fieldType': 'metric',
                            'name': 'small_stop_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Startup Reject Lost Time',
                            'fieldType': 'metric',
                            'name': 'startup_reject_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Production Reject Lost Time',
                            'fieldType': 'metric',
                            'name': 'production_reject_lost_time',
                            'type': 'time_span'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        },
        {
            'name': 'teep',
            'displayName': 'TEEP',
            'entries': [
                {
                    'name': 'hidden_factory_set',
                    'displayName': 'Hidden Factory',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'hidden_factory',
                    'group': 'teep',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'Hidden Factory Time',
                            'fieldType': 'metric',
                            'name': 'hidden_factory_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Schedule Lost Time',
                            'fieldType': 'metric',
                            'name': 'capacity_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Availability Lost Time',
                            'fieldType': 'metric',
                            'name': 'availability_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance Lost Time',
                            'fieldType': 'metric',
                            'name': 'performance_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality Lost Time',
                            'fieldType': 'metric',
                            'name': 'quality_lost_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'teep_capacity_set',
                    'displayName': 'TEEP Overview',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'teep_capacity',
                    'group': 'teep',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'TEEP',
                            'fieldType': 'metric',
                            'name': 'teep',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Utilization',
                            'fieldType': 'metric',
                            'name': 'utilization',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Schedule Loss',
                            'fieldType': 'metric',
                            'name': 'capacity_loss',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Schedule Lost Time',
                            'fieldType': 'metric',
                            'name': 'capacity_lost_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'teep_waterfall_set',
                    'displayName': 'TEEP Waterfall',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'teep_waterfall',
                    'group': 'teep',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'All Time',
                            'fieldType': 'metric',
                            'name': 'all_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Schedule Lost Time',
                            'fieldType': 'metric',
                            'name': 'capacity_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Availability Lost Time',
                            'fieldType': 'metric',
                            'name': 'availability_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance Lost Time',
                            'fieldType': 'metric',
                            'name': 'performance_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality Lost Time',
                            'fieldType': 'metric',
                            'name': 'quality_lost_time',
                            'type': 'time_span'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Fully Productive Time',
                            'fieldType': 'metric',
                            'name': 'fully_productive_time',
                            'type': 'time_span'
                        }
                    ]
                },
                {
                    'name': 'teep_with_factors_set',
                    'displayName': 'TEEP Factors',
                    'type': 'metric_set',
                    'fieldType': 'metric_set',
                    'metric_set': 'teep_with_factors',
                    'group': 'teep',
                    'channel': 'production_metric',
                    'columns': [
                        {
                            'channel': 'production_metric',
                            'displayName': 'TEEP',
                            'fieldType': 'metric',
                            'name': 'teep',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Utilization',
                            'fieldType': 'metric',
                            'name': 'utilization',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Availability',
                            'fieldType': 'metric',
                            'name': 'availability',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Performance',
                            'fieldType': 'metric',
                            'name': 'performance',
                            'subType': 'percent',
                            'type': 'double'
                        },
                        {
                            'channel': 'production_metric',
                            'displayName': 'Quality',
                            'fieldType': 'metric',
                            'name': 'quality',
                            'subType': 'percent',
                            'type': 'double'
                        }
                    ]
                }
            ],
            'isDummyGroup': false
        }
    ],
};

// search the email table metric list options for a specific metric
export const getEmailTableMetricListMetricOptions = (metricName) => {
    let found;
    for (let i = 0; i < emailWidgetFieldOptions.metric.length; i += 1) {
        const metricGroup = emailWidgetFieldOptions.metric[i];
        found = metricGroup.entries.find((mm) => {
            return mm.name === metricName;
        });
        if (found) {
            break;
        }
    }
    return found;
};

export const getMockTableData = (translator, rowCount) => {
    // this mock data is not actually read when filling out the table, this table information comes
    // from the 'accessor' function on the column
    // This array does need to exist though for each row that may be represented in the table
    return Array(rowCount).fill({});
};

export const getMockTableHeaderColumns = (columns, translator, metricThresholds) => {
    return columns.map((columnId) => {
        const { channel, columnName } = getColumnChannelName(columnId);

        const columnConfig = getColumnConfig(channel, columnName);

        return {
            Header: translator(columnConfig.display_name),
            id: columnName,
            width: 200,
            fillCellWidth: true,
            accessor: (datum, rowIndex) => {
                const dataItem = getMockData(columnId, translator, rowIndex, metricThresholds);
                const get = () => {
                    return (
                        <FormattedDatum
                            data={dataItem}
                        />
                    );
                };

                return get();
            },
            title: 'A Title',
        };
    });
};
