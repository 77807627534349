import Dropdown from 'Common/components/Dropdown/Dropdown';
import FieldSelectControl from 'Common/components/LiveControls/FieldSelectControl';
import PropTypes from 'prop-types';
import React from 'react';
import SelectDropdownButton from 'Common/components/SelectList/SelectDropdownButton';
import { useTranslator } from 'Common/hooks/useTranslation';

/**
 * Dropdown with internally managed open/close state.
 */
function MetricSelectField({
    disabled,
    name,
    onChange,
    options,
    selectedItem,
    collapsibleGroups,
    showFlattenCheckbox,
    placeholderText,
}) {
    const translator = useTranslator();
    const [isOpen, setIsOpen] = React.useState(false);

    const openHandler = () => {
        setIsOpen(true);
    };

    const closeHandler = () => {
        setIsOpen(false);
    };

    const onChangeHandler = (event) => {
        closeHandler();
        onChange(event);
    };

    const renderContent = () => {
        return (
            <FieldSelectControl
                name={name}
                options={options}
                value={{
                    name: selectedItem.name,
                    channel: selectedItem.channel,
                }}
                onChange={onChangeHandler}
                collapsibleGroups={collapsibleGroups}
                showFlattenCheckbox={showFlattenCheckbox}
            />
        );
    };

    const itemName = (selectedItem.displayKey ? translator(selectedItem.displayKey) : selectedItem.displayName);

    return (
        <Dropdown
            isOpen={isOpen}
            onMaskClick={closeHandler}
            renderContent={renderContent}
        >
            <SelectDropdownButton
                style={{width: '100%'}}
                disabled={disabled}
                onClick={openHandler}
                isPlaceholderText={!itemName}
            >
                {itemName || placeholderText || ''}
            </SelectDropdownButton>
        </Dropdown>
    );
}

MetricSelectField.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedItem: PropTypes.object.isRequired,
    showFlattenCheckbox: PropTypes.bool,
    collapsibleGroups: PropTypes.bool,
    placeholderText: PropTypes.string,
};

MetricSelectField.defaultProps = {
    disabled: false,
    name: 'MetricSelectField',
    placeholderText: '',
};

export default MetricSelectField;
