import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectInput from 'Components/select/SelectInput';
import FirstSelectInput from './FirstSelectInput';
import SelectOption from 'Components/select/SelectOption';
import FormElement from 'Components/form-element/FormElement';
import { Body1 } from 'Components/typography/typography';
import SelectOptionContent from 'Components/select/SelectOptionContent';
import metrics from 'System/metrics';
import IntegerInputField from 'Components/number-input/IntegerInputField';
import didPropsChange from 'Components/util/didPropsChange';

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const StyledSelectElement = styled.div`
    margin: 8px;
    flex-shrink: 0;
`;

const ThresholdWrapper = styled.div``;
const ThresholdInputField = styled(IntegerInputField)``;
const Percent = styled(Body1)``;

const ThresholdElement = styled.div`
    margin: 8px;

    ${ThresholdWrapper} {
        display: flex;
        align-items: center;

        ${ThresholdInputField} {
            width: 70px;
        }

        ${Percent} {
            padding-left: 8px;
            color: ${props => props.theme.colors.darkText.mediumEmphasis};
        }
    }
`;

export default class MetricAlertEditor extends React.Component {
    constructor(props) {
        super(props);
    }

    _handleChange = (event) => {
        this.props.onChange({
            ...event,
            target: {
                name: this.props.name,
                value: {
                    ...this.props.value,
                    [event.target.name]: event.target.value,
                },
            },
        });
    };

    shouldComponentUpdate(nextProps) {
        return didPropsChange(this.props, nextProps, [
            'value',
            'validation',
        ]);
    }

    render() {
        const { allowEdit, value } = this.props;
        const { metric, comparison, threshold } = value;

        const validation = this.props.validation || {};

        return (
            <ContentWrapper>
                <FormElement
                    Component={StyledSelectElement}
                    error={Boolean(validation.metric)}
                    disabled={!allowEdit}
                >
                    <FirstSelectInput
                        name="metric"
                        value={metric}
                        onChange={this._handleChange}
                    >
                        {metrics.map(option => {
                            return (
                                <SelectOption
                                    key={option.value}
                                    value={option.value}
                                >
                                    <SelectOptionContent>
                                        {option.name}
                                    </SelectOptionContent>
                                </SelectOption>
                            );
                        })}
                    </FirstSelectInput>
                </FormElement>
                <FormElement
                    Component={StyledSelectElement}
                    error={Boolean(validation.comparison)}
                    disabled={!allowEdit}
                >
                    <SelectInput
                        name="comparison"
                        value={comparison}
                        onChange={this._handleChange}
                    >
                        <SelectOption value="less_than">
                            <SelectOptionContent>
                                {'<'}
                            </SelectOptionContent>
                        </SelectOption>
                        <SelectOption value="greater_than">
                            <SelectOptionContent>
                                {'>'}
                            </SelectOptionContent>
                        </SelectOption>
                    </SelectInput>
                </FormElement>
                <FormElement
                    Component={ThresholdElement}
                    error={Boolean(validation.threshold)}
                    disabled={!allowEdit}
                >
                    <ThresholdWrapper>
                        <ThresholdInputField
                            name="threshold"
                            type="number"
                            value={String(threshold)}
                            onChange={this._handleChange}
                        />
                        <Percent>%</Percent>
                    </ThresholdWrapper>
                </FormElement>
            </ContentWrapper>
        );
    }
}

MetricAlertEditor.propTypes = {
    allowEdit: PropTypes.bool.isRequired,
    name: PropTypes.string,
    value: PropTypes.shape({
        metric: PropTypes.string.isRequired,
        comparison: PropTypes.string.isRequired,
        threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
    validation: PropTypes.shape({
        metric: PropTypes.string,
        comparison: PropTypes.string,
        threshold: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};

MetricAlertEditor.defaultProps = {
    name: '',
    validation: {},
};
