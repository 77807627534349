import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Body5 } from 'Common/components/typography';
import Icon from 'Common/components/Icon';

const Container = styled.div`
    position: relative;
    display: block;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
`;

const StyledText = styled(Body5)`
    padding-left: 15px;  
    color: ${({theme}) => theme.colors.palette.grey.manatee };
`;

const StyledIcon = styled(Icon)`
    font-size: 9px;
    margin-right: 6px;
`;


/**
 * Container for TimeRange title and detail.
 * Rendered when containing FrameHeader width shrinks beyond a specified breakpoint
 */
export default function TimeRangeDisplaySmall({
    title,
    detail
}) 
{
    return (
        <Container>
            <StyledText>
                <StyledIcon
                    type="vorne"
                    iconName="time"
                />
                {title}  {detail}
            </StyledText>
        </Container>
    );
}

TimeRangeDisplaySmall.propTypes = {
    title: PropTypes.string,
    detail: PropTypes.string,
};
