import ParamTypes, { paramTest, paramTypeToPropType } from 'Common/util/ParamTypes/ParamTypes';
import { getDefaultFormatter } from 'Common/data/defaultFormatters';

export const FILTER_COMBINE_TYPE_AND = 'and';
export const FILTER_COMBINE_TYPE_OR = 'or';
export const FILTER_TYPE_GROUP = 'group';
export const FILTER_TYPE_SLICE = 'slice';
const GROUP_FILTER_COMPARE_TYPES = {
    gt: (rawValue, filterValue) => { return rawValue > filterValue; },
    ge: (rawValue, filterValue) => { return rawValue >= filterValue; },
    lt: (rawValue, filterValue) => { return rawValue < filterValue; },
    le: (rawValue, filterValue) => { return rawValue <= filterValue; },
    ne: (rawValue, filterValue) => { return rawValue !== filterValue; },
    eq: (rawValue, filterValue) => { return rawValue === filterValue; },
};

const SLICE_FILTER_COMPARE_TYPES = {
    eq: (rawValue, filterValue, plainCategory) => { 
            let validFilter = false;
            filterValue.forEach((dimensionValue) => {
                if (dimensionValue === rawValue || plainCategory[dimensionValue] === rawValue) {
                    validFilter = true; 
                }
            });
            return validFilter;
        },
    ne: (rawValue, filterValue, plainCategory) => { 
            let validFilter = false;
            filterValue.forEach((dimensionValue) => {
                if (dimensionValue !== rawValue && plainCategory[dimensionValue] !== rawValue) {
                    validFilter = true; 
                }
            });
            return validFilter;
    },
};



function getColumnsFromSliceFilters(sliceFilters) {
    const columns = [];

    const expressions = sliceFilters.expressions;

    expressions.forEach(({ lhs }) => {
        columns.push(lhs.name);
    });

    return columns;
}

function getColumnsFromGroupFilters(groupFilters) {
    const columns = [];

    const expressions = groupFilters.expressions;

    expressions.forEach(({ lhs }) => {
        columns.push(lhs.name);
    });

    return columns;
}

function getCategoryValuesFromSliceFilters(sliceFilters) {
    const categoryValues = [];

    const expressions = sliceFilters.expressions;

    expressions.forEach(({ lhs, rhs }) => {
        const rhsArray = rhs
            ? (Array.isArray(rhs) ? rhs : [ rhs ])
            : [];

        rhsArray.forEach((categoryValueName) => {
            categoryValues.push({
                category: lhs.name,
                categoryValue: categoryValueName,
            });
        });
    });

    return categoryValues;
}

function convertSliceFilterExpressionToDisplayValues({
    expression,
    columnConfigs,
    categoryValueConfigs,
}) {
    const { lhs, rhs } = expression;

    const lhsItemColumnConfig = columnConfigs[lhs.name];
    const lhsString = lhsItemColumnConfig.displayName;

    const rhsArray = rhs
        ? (Array.isArray(rhs) ? rhs : [ rhs ])
        : [];

    const rhsStringArray = rhsArray.map((categoryValueName) => {
        return categoryValueConfigs[lhs.name][categoryValueName].displayName;
    });

    return {
        ...expression,
        lhs: lhsString,
        rhs: rhsStringArray,
    };
}

function convertGroupFilterExpressionToDisplayValues({
    expression,
    locale,
    translator,
    columnConfigs,
}) {
    const { lhs, rhs } = expression;

    const lhsItemColumnConfig = columnConfigs[lhs.name];
    const lhsString = lhsItemColumnConfig.displayName;

    let rhsFormatter = getDefaultFormatter({
        type: lhsItemColumnConfig.formatType,
        locale,
        translator,
    });

    if (!rhsFormatter) {
        console.error('missing formatter for formatType', lhsItemColumnConfig.formatType);
        rhsFormatter = (name) => name;
    }

    const rhsString = rhsFormatter(rhs, {react: true});

    return {
        ...expression,
        lhs: lhsString,
        rhs: rhsString,
    };
}

function isSliceExpressionActive(expression) {
    return Array.isArray(expression.rhs)
        ? expression.rhs.length > 0
        : false;
}

function isGroupExpressionActive(expression) {
    return expression.rhs !== undefined && expression.rhs !== null;
}

/**
 * A set of utility functions using the standard filter object.
 */
export default class Filters {
    //
    // ParamTypes:
    //

    static sliceFilterExpressionParamType = ParamTypes.shape({
        lhs: ParamTypes.shape({
            channel: ParamTypes.string.isRequired,
            name: ParamTypes.string.isRequired,
        }),
        op: ParamTypes.string,
        rhs: ParamTypes.oneOfType([
            ParamTypes.arrayOf(
                ParamTypes.string
            ),
            ParamTypes.string,
        ])
    });

    static groupFilterExpressionParamType = ParamTypes.shape({
        lhs: ParamTypes.shape({
            channel: ParamTypes.string.isRequired,
            name: ParamTypes.string.isRequired,
        }),
        op: ParamTypes.string,
        rhs: ParamTypes.any,
    });

    static sliceFiltersParamType = ParamTypes.shape({
        combine_type: ParamTypes.oneOf([FILTER_COMBINE_TYPE_AND, FILTER_COMBINE_TYPE_OR]),
        expressions: ParamTypes.arrayOf(
            Filters.sliceFilterExpressionParamType,
        ),
    });

    static groupFiltersParamType = ParamTypes.shape({
        combine_type: ParamTypes.oneOf([FILTER_COMBINE_TYPE_AND, FILTER_COMBINE_TYPE_OR]),
        expressions: ParamTypes.arrayOf(
            Filters.groupFilterExpressionParamType,
        ),
    });

    static paramType = ParamTypes.shape({
        slice: Filters.sliceFiltersParamType,
        group: Filters.groupFiltersParamType,
    });

    //
    // PropTypes:
    //

    static sliceFilterExpressionPropType = paramTypeToPropType(
        Filters.sliceFilterExpressionParamType
    );

    static groupFilterExpressionPropType = paramTypeToPropType(
        Filters.groupFilterExpressionParamType
    );

    static sliceFiltersPropType = paramTypeToPropType(
        Filters.sliceFiltersParamType
    );

    static groupFiltersPropType = paramTypeToPropType(
        Filters.groupFiltersParamType
    );

    static propType = paramTypeToPropType(
        Filters.paramType
    );

    static validate(filters) {
        paramTest(filters, Filters.paramType, 'Filters');
    }

    /**
     * Extract all the column names used by the filters
     *
     * @param {Object} filters
     * @returns {String[]} Column names
     */
    static getColumnsFromFilters(filters) {
        Filters.validate(filters);

        let columnNames = [];

        if (filters.slice) {
            columnNames = columnNames.concat(
                getColumnsFromSliceFilters(filters.slice)
            );
        }

        if (filters.group) {
            columnNames = columnNames.concat(
                getColumnsFromGroupFilters(filters.group)
            );
        }

        return columnNames;
    }

    /**
     * Extract all the category values used by the filters.
     *
     * @param {Object} filters
     * @returns {Object[]} Category value identifiers in the form of
     *      { category: '', categoryValue: '' }
     */
    static getCategoryValuesFromFilters(filters) {
        Filters.validate(filters);

        let categoryValueIdentifiers = [];

        // Only slice filters have category values
        if (filters.slice) {
            categoryValueIdentifiers = categoryValueIdentifiers.concat(
                getCategoryValuesFromSliceFilters(filters.slice)
            );
        }

        return categoryValueIdentifiers;
    }

    /**
     * Convert filters from the standard format into where all expressions' lhs
     * values are converted to display name strings, and all rhs values are
     * converted to either formatted numbers or category value display names.
     *
     * Useful for preparing filters to be rendered as plain text.
     *
     * @param {Object} filters
     * @param {Object} columnConfigs An object of column configs, keyed by
     *      column name.
     * @param {Object} categoryValueConfigs A two-layer object of category value
     *      configs. Keyed by category name, then category value name.
     * @param {Function} translator
     * @param {*} locale
     * @returns {Object} Filters with values converted to display strings.
     */
    static convertToDisplayValues(filters, columnConfigs, categoryValueConfigs, translator, locale) {
        Filters.validate(filters);

        const convertedFilters = {};

        if (filters.slice) {
            convertedFilters.slice = {
                ...filters.slice,
                expressions: filters.slice.expressions.map((expression) => {
                    return convertSliceFilterExpressionToDisplayValues({
                        expression,
                        columnConfigs,
                        categoryValueConfigs,
                    });
                }),
            };
        }

        if (filters.group) {
            convertedFilters.group = {
                ...filters.group,
                expressions: filters.group.expressions.map((expression) => {
                    return convertGroupFilterExpressionToDisplayValues({
                        expression,
                        translator,
                        locale,
                        columnConfigs,
                    });
                }),
            };
        }

        return {
            ...filters,
            ...convertedFilters,
        };
    }

    /**
     * Get the number of active expressions. This means they have valid rhs values.
     * 
     * @param {Object} filters
     * @returns {Number} number of active expressions
     */
    static countActiveExpressions(filters) {
        Filters.validate(filters);

        let count = 0;

        (filters?.slice?.expressions || []).forEach((expression) => {
            if (isSliceExpressionActive(expression)) {
                count++;
            }
        });

        (filters?.group?.expressions || []).forEach((expression) => {
            if (isGroupExpressionActive(expression)) {
                count++;
            }
        });
        
        return count;
    }

    /**
     * Are there any slice filters? This includes inactive filters.
     *
     * @param {object} filters
     * @returns {boolean}
     */
    static hasAnySliceFilters(filters) {
        Filters.validate(filters);
        const sliceExpressions = filters?.slice?.expressions || [];

        return sliceExpressions.length;
    }

    static validateAndonFilter(pageFitlers, widgetFilters, row, plainCategories = {}) {
        // merge filters also does filter validation
        const filters = this.mergeFilters(pageFitlers, widgetFilters);
        let validFilter = true;

        // This is a list of two filter types, Dimension and Metrics
        filters.forEach(filterType => {
            // check if any of the dimension or metric filters failed validation
            if (!validFilter) {
                return;
            }
            // check if the filter is and / or to use the proper comparison functions
            const compareType = filterType.type === FILTER_TYPE_SLICE ? 
                                SLICE_FILTER_COMPARE_TYPES : GROUP_FILTER_COMPARE_TYPES;
    
            const subFilterResults = filterType?.expressions?.map(filter => {
                // Handle Slice Filters
                if (filterType.type === FILTER_TYPE_SLICE) {
                    // Some rawValues in row are returned as a list. 
                    let rowValue = Array.isArray(row[filter.lhs.name].rawValue) ? row[filter.lhs.name].rawValue[0] : row[filter.lhs.name].rawValue;

                    if (rowValue?.name) {
                        return compareType[filter.op](rowValue.name, filter.rhs, {});
                    }
                    else {
                        return compareType[filter.op](rowValue.displayName, filter.rhs, plainCategories[filter.lhs.name]);
                    }
                }
                // Handle Group Filters
                else {
                    if (Array.isArray(row[filter.lhs.name].rawValue)) {
                        // This is only for occurrence Metrics. 
                        // We pass in the length of the list of events instead of the actual values in the list.
                        return compareType[filter.op](row[filter.lhs.name].rawValue.length, filter.rhs);
                    }
                    else if (Number.isFinite(row[filter.lhs.name].rawValue)) {
                        return compareType[filter.op](row[filter.lhs.name].rawValue, filter.rhs);
                    }
                    else {
                        return false;
                    }
                }
            });
            validFilter = filterType.combine_type === FILTER_COMBINE_TYPE_AND ? 
            !subFilterResults.some((result) => result === false) 
            : !subFilterResults.every(result => result === false);
        });
        return validFilter;
    }

    /**
     * Merge together two filters (mostly widget and page filters). These are anded together if they exist.
     * This also handles excluding 'non-active' filters. Filters who don't have a right hand side.
     */
    static mergeFilters(filtersA, filtersB) {
        Filters.validate(filtersA);
        Filters.validate(filtersB);
        let filters = [];

        [
            { filter: filtersA.slice, type: FILTER_TYPE_SLICE },
            { filter: filtersA.group, type: FILTER_TYPE_GROUP },
            { filter: filtersB.slice, type: FILTER_TYPE_SLICE },
            { filter: filtersB.group, type: FILTER_TYPE_GROUP }
        ]
            .forEach(({filter, type}) => {
                if (filter) {
                    const activeExpressions = filter.expressions.filter((expression) => {
                        return Array.isArray(expression.rhs)
                            ? expression.rhs.length > 0
                            : expression.rhs !== null && expression.rhs !== undefined;
                    });
                    if (activeExpressions.length) {
                        filters.push({ ...filter, expressions: activeExpressions, type });
                    }
                }
            });
        
        return filters;
    }
}

