import React from 'react';
import styled from 'styled-components';
import LoadingWrapper from 'Components/loading-indicator/LoadingWrapper';

const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.palette.white};
`;

function LoadingPage(props) {
    return (
        <Root {...props}>
            <LoadingWrapper />
        </Root>
    );
}

export default LoadingPage;
