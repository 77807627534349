import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import NavMenu from './NavMenu';
import NavSubMenu from './NavSubMenu';
import NavLink from './NavLink';
import NavItem from './NavItem';
import NavSeparator from './NavSeparator';

const StyledNavMenu = styled(NavMenu)`
    box-sizing: border-box;
    margin-left: 6px;
    margin-right: 6px;
    padding: 10px;
`;

export default class StaticNavMenu extends React.PureComponent {
    static propTypes = {
        subMenuContents: PropTypes.arrayOf(
            PropTypes.shape({
                menuTitle: PropTypes.string.isRequired,
                menuItems: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.string.isRequired,
                        to: PropTypes.string,
                        text: PropTypes.string,
                        minRole: PropTypes.string,
                    })
                ).isRequired,
            }).isRequired,
        ).isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
    }

    _renderNavItem({ type, to, text }, i) {
        let innerItem = null;

        if (type === 'link') {
            innerItem = (
                <NavLink to={to}>{text}</NavLink>
            );
        }
        else if (type === 'separator') {
            innerItem = (
                <NavSeparator />
            );
        }

        return (
            <NavItem key={`static-menu-${i}`}>
                {innerItem}
            </NavItem>
        );
    }

    _renderMenuList(subMenuContents) {
        return subMenuContents.map(({ menuItems, menuTitle }, i) => {
            return (
                <NavSubMenu heading={menuTitle} key={`static-sub-menu-${i}`}>
                    {menuItems.map((item, itemIndex) => {
                        return this._renderNavItem(item, itemIndex);
                    })}
                </NavSubMenu>
            );
        });
    }

    render() {
        const { subMenuContents, ...otherProps } = this.props;

        return (
            <StyledNavMenu {...otherProps}>
                {this._renderMenuList(subMenuContents)}
            </StyledNavMenu>
        );
    }
}
