import { formatDate, formatNumber } from 'Common/util/format';
import formatDuration from 'Common/util/formatDuration';
import { getPlacesForNumericRange } from 'Common/util/language/math';

function _commonRate(range, locale, translator, template) {
    return function(data, opts) {
        opts = {
            type: 'decimal',
            places: 1,
            template: translator(template),
            locale,
            ...opts,
        };

        return formatNumber(data, opts);
    };
}

const DEFAULT_FORMATTERS = {
    boolean: function(range, locale, translator) {
        return function(data, opts) {
            return data ? translator('yes_literal') : translator('no_literal');
        };
    },
    date_time: function(range, locale, translator) {
        return function(data, opts) {
            opts = {
                formatLength: 'short',
                timeFormatItem: 'hms',
                locale,
                ...opts,
            };

            return !data ? '' : formatDate(data, opts);
        };
    },
    time_span: function(range, locale, translator) {
        return function(data, opts) {
            opts = { locale, translator, ...opts };
            // Avoid truncating '.9999999' down to zero by first rounding it up.
            data = Number.parseFloat(data).toFixed(4);

            return formatDuration(data, opts);
        };
    },
    cycle: function(range, locale, translator) {
        return function(data, opts) {
            // We round to the nearest hundredth for display
            const roundedData = Number(Number(data).toFixed(2));

            // We do not care to show decimals for larger cycle counts as it is
            // often unnecessary precision which makes it harder to read.
            //
            const noDecimal = Number.isInteger(roundedData) || roundedData >= 1000;

            opts = {
                locale,
                type: 'decimal',
                places: noDecimal ? 0 : 1,
                ...opts,
            };

            return formatNumber(roundedData, opts);
        };
    },
    cycle_time: function(range, locale, translator) {
        return function(data, opts) {
            // this function is sometimes called with no opts parameter, so initialize the defaults
            // call with an empty object
            opts = {
                locale,
                translator,
                pattern: 'h:mm:ss.00',
                ...opts,
            };

            // As with time_span, avoid truncating '0.49999999' down to '0.49' by first rounding up.
            data = Number.parseFloat(data).toFixed(4);

            return formatDuration(data, opts);
        };
    },
    count: function(range, locale, translator) {
        return function(data, opts) {
            opts = {
                locale,
                type: 'decimal',
                places: 0,
                round: false,
                ...opts,
            };

            return formatNumber(data, opts);
        };
    },
    double: function(range, locale, translator) {
        return function(data, opts) {
            opts = {
                locale,
                type: 'decimal',
                places: 1,
                round: false,
                ...opts,
            };

            return formatNumber(data, opts);
        };
    },
    percent: function(range, locale, translator) {
        return function(data, opts) {
            opts = {
                type: 'percent',
                // percentages are limited to at most 1 decimal place (possibly zero)
                places: Math.min(1, getPlacesForNumericRange(range, 1)),
                locale,
                ...opts,
            };

            return formatNumber(data, opts);
        };
    },
    rate: function(range, locale, translator) {
        return _commonRate(range, locale, translator, 'rpm_format_template');
    },
    rate_per_hour: function(range, locale, translator) {
        return _commonRate(range, locale, translator, 'rph_format_template');
    },
    string: function(range, locale, translator) {
        // replace \n with new lines
        return function(data, opts) {
            return data.replace(/\n/g, '<br>');
        };
    },
    id_array: function(range, locale, translator) {
        return function(data, opts) {
            // Treat nulls like an empty array
            data = data || [];
            data = Array.isArray(data) ? data : [data];

            opts = {
                locale,
                type: 'decimal',
                places: 0,
                round: false,
                ...opts,
            };

            return formatNumber(String(data.length), opts);
        };
    },
    invalid: function(range, locale, translator) {
        return function(data, opts) {
            if (opts?.react) {
                return translator('unknown_field_format_not_escaped');
            }
            else {
                return translator('unknown_field_format_escaped');
            }
        };
    },
    uint64: function(range, locale, translator) {
        return function (data, opts) {
            opts = {
                locale,
                type: 'decimal',
                places: 0,
                round: false,
                ...opts,
            };

            return formatNumber(data, opts);
        };
    }
};

export function getDefaultFormatter({
    type,
    locale,
    translator,
    range,
}) {
    return DEFAULT_FORMATTERS[type]
        ? DEFAULT_FORMATTERS[type](range, locale, translator)
        : null;
}
