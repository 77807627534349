import ParamTypes, { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import { ProductionDay, getCategoryBounds } from 'Common/data/date/ProductionDay';
import { daysBetween } from 'Common/util/language/date';
import has from 'lodash/has';

// shift/team/part/job/shift_hour/hour/week/month/quarter/year/production_day/time
// in the case of time start and end our actual production day numbers
const basicTimeRangeParamType = ParamTypes.shape({
    units: ParamTypes.oneOf(['shift', 'team', 'part', 'job', 'shift_hour', 'hour', 'week',
        'month', 'quarter', 'year', 'production_day', 'time'
    ]).isRequired,
    start: ParamTypes.number.isRequired,
    end: ParamTypes.number.isRequired,
    sameDimensionValue: ParamTypes.string,
    // though units will always be production_day this stipulates if it is
    // actually calendar_day (when dayBasis === 'calendar')
    dayBasis: ParamTypes.string,
});

// ordinal shift
const ordinalShiftRangeParamType = ParamTypes.shape({
    units: ParamTypes.exactly('ordinal_shift').isRequired,
    productionDayOffset: ParamTypes.number.isRequired,
    ordinalShiftValue: ParamTypes.string.isRequired,
    sameDimensionValue: ParamTypes.string,
});

// all time
const allTimeRangeParamType = ParamTypes.shape({
    units: ParamTypes.exactly('all').isRequired,
});

const timeRangeParamType = ParamTypes.oneOfType([
    basicTimeRangeParamType,
    ordinalShiftRangeParamType,
    allTimeRangeParamType,
]);

const productionDayBasedUnits = [
    'production_day',
    'week',
    'month',
    'quarter',
    'year',
];

export function getTimeRangeObjectPropType() {
    return function(props, propName, componentName) {
        const isValid = paramTest(props[propName], timeRangeParamType, 'argument', { throwError: false, noLog: true });
        return isValid
            ? null
            : new Error(`Invalid prop '${propName} supplied to '${componentName}' was an invalid timerange`);
    };
}

export default class TimeRange {
    static timeRangeParamType = timeRangeParamType;

    static includesNow (timeRange, now) {
        if (timeRange.units === 'time') {
            return now >= timeRange.start && now <= timeRange.end;
        }
        else if (timeRange.units === 'ordinal_shift') {
            return false;
        }
        else if (timeRange.units == 'all') {
            return true;
        }
        // If we're using other units, and the range includes the current
        // unit, then this TimeRange certainly includes right now
        else if (timeRange.end === 0) {
            return true;
        }
    
        return false;
    }

    static isCalendarDayBasis (timeRange) {
        return timeRange.dayBasis === 'calendar';
    }
    
    /**
     * Is this time range unit based on production days?
     *
     * @param {String} timeRangeUnit
     */
    static isProductionDayBasedUnit(timeRangeUnit) {
        return productionDayBasedUnits.indexOf(timeRangeUnit) !== -1;
    }
    
    /**
     * Return a time range start and end in absolute terms using today and the time range start
     * and end offsets for the given units. Only works for production day based units.
     *
     * @param {Object} timeRange Object representing the time range with units, start, and end.
     * @param {Any} today The ProductionDay, CalendarDay, production day number, or date representing today.
     *
     * @returns {Object} With start and end as absolute production day numbers.
     */
    static computeProductionDayRangeFromToday(timeRange, today) {
        const { units, start, end } = timeRange;
    
        let startDate, endDate;
    
        if (units === 'time') {
            // already in terms of production day numbers
            startDate = start;
            endDate = end;
        }
        else if (TimeRange.isProductionDayBasedUnit(units)) {
            const productionDay = new ProductionDay(today);
    
            const unitsToUse = (units === 'production_day' ? 'days' : (units + 's'));
            startDate = productionDay.add(unitsToUse, start);
            endDate = productionDay.add(unitsToUse, end);
    
            // Now, startDate and endDate are within the desired week/month/whatever.
            // Get the bounds for each.
            startDate = getCategoryBounds(startDate, units)[0];
            endDate = getCategoryBounds(endDate, units)[1];
        }
        else {
            // This must be category based. We can't form an accurate range for this,
            // and therefor don't support it.
            //
            throw new Error(
                `computeProductionDayRangeFromToday does not support time range units "${units}"`
            );
        }
    
        return {
            start: startDate,
            end: endDate
        };
    }

    /**
     * Get the estimated number of days in a time range.
     *
     * @param {Object} timeRange The time range.
     *
     * @return {Number} An estimated number of days in range. Will be Infinity
     *      if the time range is "all time". Will be zero if the units are not
     *      time based (e.g., part, shift) or a unit is expected to be less than
     *      a day (e.g., shift_hour).
     */
    static getEstimatedNumberOfDays(timeRange) {
        const { units, start, end } = timeRange;

        if (units === 'all') {
            return Infinity;
        }
        else if (units === 'time') {
            // 'time' is in terms of production day numbers.
            return Math.abs(end - start);
        }
        else if (units === 'production_day') {
            return Math.abs(end - start);
        }
        else if (units === 'week') {
            return 7;
        }
        else if (units === 'month') {
            return 365 / 12; // Avg number of days in a month.
        }
        else if (units === 'quarter') {
            return 365 / 4; // Avg number of days in a quarter.
        }
        else if (units === 'year') {
            return 365;
        }
        else {
            // Default
            return 0;
        }
    }

    /**
     * A simple utility function for determining if a request should repeat.
     *
     * @param {object} timeRange
     * @returns {boolean} True if a repeating request is recommended.
     */
    static shouldRepeatRequestWithTimeRange(timeRange) {
        let rangeContainsOpenInterval = false;

        if (timeRange.units !== 'all'
            && timeRange.units !== 'ordinal_shift'
            && timeRange.units !== 'time'
            ) {
            // We're in the future, therefore not in the open interval
            const inTheFuture = timeRange.start > 0;

            // Start must be less than or equal to 0, so if end is 0 or greater
            // we must contain the open interval. Otherwise we must not.
            //
            rangeContainsOpenInterval = !inTheFuture && timeRange.end >= 0;
        }

        return (
            rangeContainsOpenInterval &&
            TimeRange.getEstimatedNumberOfDays(timeRange) <= 7
        );
    }

    /**
     * Given a time range object, generate text describing the units of the time range.
     *
     * @param {Object} timeRange Object representing the time range with units, start, and end.
     * @param {Function} translator Function for translating text keys for the user output.
     *
     * @returns {String} Some text representing the time range units.
     */
    static getFullUnitText(timeRange, translator) {
        let units = timeRange.units === 'ordinal_shift' ? 'shift' : timeRange.units;
        let dayCount;
        let startAsDate;
        let endAsDate;
        let fullUnitText;

        // Anything represented in a number of days gets special treatment. Right now, that's
        // days and time.
        //
        if (units === 'production_day') {
            dayCount = timeRange.end - timeRange.start + 1;
        }
        else if (units === 'time') {
            units = 'production_day';
            startAsDate = new ProductionDay(timeRange.start).toDate();
            endAsDate = new ProductionDay(timeRange.end).toDate();
            dayCount = daysBetween(startAsDate, endAsDate) + 1;
        }

        // If we have a dayCount that's greater than 1, express ourself from the translation table.
        // Otherwise, we're just the singular of the unit name (since we changed `time` to `day`
        // above)
        //
        fullUnitText = dayCount > 1
            ? translator('timerange.n_days', { x: dayCount })
            : translator(`time_range_selector_dropdown.${units}.singular`);

        return fullUnitText;
    }
    /*
     * Is the `current` identifier available for a request made with this time
     * range?
     *
     * @param {Object} timeRange Object representing the time range with units, start, and end.
     * @returns {Boolean}
     */
    static isCurrentIdentifierAvailable(timeRange) {
        // current data is only for special units
        // this shift, this shift_hour, this part
        //
        const specialUnits = {
            shift: true,
            part: true,
            shift_hour: true
        };

        const isThis = (!timeRange.sameDimensionValue) &&
            (timeRange.end === 0) &&
            (timeRange.start === 0);

        return isThis && has(specialUnits, timeRange.units);
    }

    /**
     * Get column name for the event id based on the current units and if they are calendar day based
     *
     * @param {Object} timeRange
     *
     * @returns {String} event_id name or empty string if timerange had no event id name
     */
    static getUnitsEventIdName(timeRange) {
        const units = timeRange.units;
        const isCalendarDayBased = TimeRange.isCalendarDayBasis(timeRange);
        let rv = '';
        if (units === 'time') {
            if (isCalendarDayBased) {
                rv = 'calendar_day_event_id';
            }
            else {
                rv = 'production_day_event_id';
            }
        }
        else if (units !== 'all') {
            rv = `${units}_event_id`;
        }
        return rv;
    }
}
