import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BasicSelectOption from 'Common/components/SelectList/BasicSelectOption';
import SelectList from 'Common/components/SelectList';
import Icon from 'Common/components/Icon';
import useSearchBox from 'Common/hooks/useSearchBox';
import SelectSeparator from 'Common/components/SelectList/SelectSeparator';
import InfoCircle from 'Common/components/InfoCircle';
import {Heading5} from 'Common/components/typography';
import { useTranslator } from 'Common/hooks/useTranslation';

export const SEPARATOR_TYPE = 'separator';

export function flattenEntries(entries) {
    let flatList = [];

    entries.forEach((entry) => {
        if (entry.entries) {
            flatList = flatList.concat(flattenEntries(entry.entries));
        }
        else {
            flatList.push(entry);
        }
    });

    return flatList;
}

const StyledBasicSelectOption = styled(BasicSelectOption)`
    flex: 1 1 auto;
`;

const OptionContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledIcon = styled(Icon)`
    font-size: 9px;
`;

const StyledOption = styled.span`
    font-weight: 500;
`;

const StyledSearchFieldContainer = styled.div`
    padding: 10px;
`;

const StyledMetricType = styled.span`
    color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
    padding-left: 5px;
`;

const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 16px;
    width: 100%;
    align-items: center;
`;

const SearchOptionText = styled.span`
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;

    color: ${({ theme }) => theme.colors.darkText.highEmphasis};
`;

const MetricSetIcon = styled(Icon)`
    padding-left : 5px;
    opacity : 0.5;
`;

const TooltipHeaderText = styled(Heading5)`
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
`;

/**
 * This is a very specialized component that should be avoided. If wanting
 * a selector to get fields check out FieldBuilderSelect.
 *
 */
function FieldBuilderTypeSelect({
    name,
    options,
    searchOptions,
    onChange,
    instruction,
    value,
}) {
    const translator = useTranslator();
    const {
        searchValue,
        renderSearchBox,
    } = useSearchBox({
        placeholder: translator('type_to_search_literal'),
    });

    const hasSearchValue = Boolean(searchValue?.length);

    // For this special select list we want a different set of options
    // when searching, so if the user types in a search swap out the
    // normal options with the search specific options.
    //
    const selectListOptions = hasSearchValue
        ? searchOptions
        : options;

    const handleChange = (event) => {
        const { value: nextSelectedOptionName } = event.target;
        const type = hasSearchValue ? 'search' : 'main';

        // Use the value to find the actual option selected
        const nextSelectedOption = selectListOptions.find((option) => {
            return option.name === nextSelectedOptionName;
        });

        onChange({
            target: {
                name: name,
                type,
                value: nextSelectedOption,
            },
        });
    };

    const renderIcons = (entry) => {
        if (entry.fieldType === 'metric_set') {
            return (
                <MetricSetIcon 
                    iconName="list-ul"
                    type="fontAwesome"
                />
            );
        }
    };


    const renderTooltip = (entry) => {
        if (entry.fieldType === 'metric_set') {
            const metrics = entry.columns.map((metric) => (<li key={metric.name}>{metric.displayName}</li>));
            return (
                <InfoCircle>
                    <TooltipHeaderText>{translator("widget.table.column_control.metric_set_tooltip_column_text")}</TooltipHeaderText>
                    <ul>
                        {metrics}
                    </ul>
                </InfoCircle>
            );
        }
    };

    return (
        <React.Fragment>
            <StyledSearchFieldContainer>
                {renderSearchBox()}
            </StyledSearchFieldContainer>
            <SelectList
                instruction={instruction}
                searchValue={searchValue}
                onChange={handleChange}
                noSelectionText={translator('filterbox_no_selections_copy')}
                value={hasSearchValue ? null : value}
            >
                {selectListOptions.map((option, optionIndex) => {
                    if (option.type === SEPARATOR_TYPE) {
                        return (
                            <SelectSeparator key={optionIndex} />
                        );
                    }
                    else {
                        return (
                            <StyledBasicSelectOption
                                key={option.name}
                                value={option.name}
                                searchValue={option.searchValue || option.displayName}
                                disabled={option.disabled}
                            >
                                {hasSearchValue
                                    ? (
                                        <OptionContainer>
                                            <SearchOptionText>
                                                <span>{option.displayName}</span>
                                                {option.type !== "metric_set" && option.displayType ? (<StyledMetricType>{option.displayType}</StyledMetricType>) : null}
                                                {renderIcons(option)}
                                            </SearchOptionText> 
                                            {renderTooltip(option)}
                                        </OptionContainer>
                                    )
                                    : (
                                        <OptionContentWrapper>
                                            <StyledOption>
                                                {option.displayName}
                                            </StyledOption>
                                            {option.hasChildren
                                                ? (<StyledIcon
                                                    type="vorne"
                                                    iconName="collapse-arrow-closed"
                                                />)
                                                : null
                                            }
                                        </OptionContentWrapper>
                                    )
                                }
                            </StyledBasicSelectOption>
                        );
                    }
                })}
            </SelectList>
        </React.Fragment>
    );
}

FieldBuilderTypeSelect.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            displayName: PropTypes.node,
            disabled: PropTypes.bool,
            type: PropTypes.oneOf([SEPARATOR_TYPE]),
            hasChildren: PropTypes.bool,
        }).isRequired).isRequired,
    searchOptions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.node.isRequired,
            displayType: PropTypes.string
        }).isRequired),
    onChange: PropTypes.func.isRequired,
    instruction: PropTypes.node,
    value: PropTypes.string,
};

export default React.memo(FieldBuilderTypeSelect);
