import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import TimeRange from 'Common/types/TimeRange/TimeRange';
import explorationPathParamType from 'Common/types/stf/explorationPathParamType';
import { useEffect, useMemo, useCallback } from 'react';
import { useTranslator } from 'Common/hooks/useTranslation';
import useKPIGroupWidgetInitialization from './useKPIGroupWidgetInitialization';
import useKPIGroupWidgetData from './useKPIGroupWidgetData';
import getPrimaryDescription from "Common/components/TimeRangeSelector/helpers/getPrimaryDescription";
import getSecondaryDescription from "Common/components/TimeRangeSelector/helpers/getSecondaryDescription";
import useFormattedFilters from 'Common/hooks/useFormattedFilters';
import { getDataUpdateInterval } from 'Common/hooks/requests/useRepeatingRequest';
import { DIMENSION_VISUALIZATION_TYPES, DIMENSION_VISUALIZATION_ORDER_BY_TYPES } from 'Common/components/SparkVisualization/SparkDimensionConstants';
import useTimeRangeData, { TIME_RANGE_PRIMARY_DETAILS, TIME_RANGE_SECONDARY_DETAILS } from 'Common/components/TimeRangeSelector/hooks/useTimeRangeData';
import RequestState from 'Common/types/RequestState/RequestState';
import { BLANK_ROW_TYPE, FIELD_TYPE, HEADING_TYPE } from '../KPIGroupWidgetConstants';

const extractFiltersFromExplorationPath = (explorationPath) => {
    let filters = {};

    if (explorationPath?.group_filter) {
        filters.group = explorationPath.group_filter;
    }

    if (explorationPath?.slice_filter) {
        filters.slice = explorationPath.slice_filter;
    }

    if (Object.keys(filters) === 0) {
        // There were either no filters, or no exploration path.
        return null;
    }
    else {
        return filters;
    }
};

const contentRowParamType = ParamTypes.arrayOf(
    ParamTypes.shape({
        type: ParamTypes.oneOf([
            HEADING_TYPE,
            FIELD_TYPE,
            BLANK_ROW_TYPE,
        ]).isRequired,
        // For the heading type:
        heading: ParamTypes.shape({
            displayName: ParamTypes.string,
            displayKey: ParamTypes.string,
        }),
        // For field type:
        field: ParamTypes.shape({
            name: ParamTypes.string.isRequired,
            channel: ParamTypes.string.isRequired,
            // Only for dimension fields (ie: shift)
            dimensionVisualization: ParamTypes.shape({ 
                visualization: ParamTypes.oneOf(Object.values(DIMENSION_VISUALIZATION_TYPES)).isRequired,
                metric: ParamTypes.string,
                orderBy: ParamTypes.oneOf(Object.values(DIMENSION_VISUALIZATION_ORDER_BY_TYPES)),
                maxElementsShown: ParamTypes.number
            }),
        }),
    })
).isRequired;

const paramTypes = ParamTypes.shape({
    areLiveControlsVisible: ParamTypes.bool.isRequired,
    dataService: ParamTypes.object.isRequired,
    isPrint: ParamTypes.bool.isRequired,
    mutable: ParamTypes.bool.isRequired,
    pageExplorationPath: explorationPathParamType,
    pageTimeRange: TimeRange.timeRangeParamType,
    parentTitle: ParamTypes.string,
    reportHierarchyNode: ParamTypes.shape({
        name: ParamTypes.string,
        displayName: ParamTypes.string
    }),
    setWidgetLiveControlsVisibility: ParamTypes.func.isRequired,
    setWidgetPrintStatus: ParamTypes.func.isRequired,
    setWidgetStf: ParamTypes.func.isRequired,
    widgetStf: ParamTypes.shape({
        type: ParamTypes.exactly('kpi_group_widget').isRequired,
        exploration_path: explorationPathParamType,
        rangeSelection: TimeRange.timeRangeParamType,
        hideLiveControls: ParamTypes.bool,
        hideTimeRange: ParamTypes.bool,
        contents: contentRowParamType,
    }),
});

const useKPIGroupWidgetController = (parameters) => {
    paramTest(parameters, paramTypes, 'useKPIGroupWidgetController');

    const {
        areLiveControlsVisible,
        dataService,
        isPrint,
        pageExplorationPath,
        pageTimeRange,
        reportHierarchyNode,
        mutable,
        setWidgetLiveControlsVisibility,
        setWidgetPrintStatus,
        setWidgetStf,
        widgetStf,
    } = parameters;

    const {
        isInitialized,
        liveControlOptions,
    } = useKPIGroupWidgetInitialization({ dataService });

    const translator = useTranslator();
    const widgetExplorationPath = widgetStf.exploration_path;


    // hide/show livecontrol stuff
    const supportsLiveControls = !widgetStf.hideLiveControls && mutable;

    const toggleShowLiveControls = useCallback(() => {
        setWidgetLiveControlsVisibility(!areLiveControlsVisible);
    }, [ areLiveControlsVisible, setWidgetLiveControlsVisibility ]);


    // hierarchy stuff
    const widgetHierarchyNode = widgetExplorationPath?.asset_control?.root;
    const followAppBarHierarchyNode = !widgetHierarchyNode;
    const mergedHierarchyNode = widgetHierarchyNode || reportHierarchyNode?.name;

    const setHierarchyNode = useCallback((newHierarchyNode) => {
        setWidgetStf((stf) => {
            let newExplorationPath = {
                ...stf.exploration_path
            };
            if (newHierarchyNode) {
                newExplorationPath.asset_control = {
                    root: newHierarchyNode,
                };
            }
            else {
                delete newExplorationPath.asset_control;
            }

            return {
                ...stf,
                exploration_path: newExplorationPath,
            };
        });
    }, [setWidgetStf]);


    // title stuff
    const defaultTitle = translator('kpi_group_literal');
    let title = widgetStf.title;
    if (!title && widgetStf.titleKey) {
        title = translator(widgetStf.titleKey);
    }
    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        setWidgetStf((stf) => ({
            ...stf,
            title: newTitle,
        }));
    };


    // filter stuff
    const rawSliceFilters = widgetStf.exploration_path?.slice_filter;

    const filters = useMemo(() => {
        if (!rawSliceFilters) {
            return {
                slice: {
                    combine_type: 'and',
                    expressions: [],
                }
            };
        }
        else {
            return {
                slice: rawSliceFilters
            };
        }
    }, [rawSliceFilters]);

    const setSliceFilters = useCallback((newFilters) => {
        setWidgetStf((stf) => ({
            ...stf,
            exploration_path: {
                ...stf.exploration_path,
                slice_filter: newFilters,
            },
        }));
    }, [setWidgetStf]);

    const formattedFilters = useFormattedFilters({
        sliceFilters: filters.slice,
        dataService,
    });

    const pageFilters = useMemo(() => {
        return extractFiltersFromExplorationPath(pageExplorationPath);
    }, [ pageExplorationPath ]);

    const widgetFilters = useMemo(() => {
        return extractFiltersFromExplorationPath(widgetExplorationPath);
    }, [ widgetExplorationPath ]);


    // column stuff
    const widgetContents = widgetStf.contents;
    const setContents = useCallback((updatedContents) => {
        paramTest(updatedContents, contentRowParamType, 'setContents.row');

        setWidgetStf((stf) => {
            return {
                ...stf,
                contents: updatedContents,
            };
        });
    }, [setWidgetStf]);


    // data stuff

    // Disable the update interval if the widget is in print view
    const widgetUpdateTimeInterval = isPrint ? null : widgetStf.updateTimeInterval;

    const dataRequest = useKPIGroupWidgetData({
        getKPIGroupWidgetData: dataService.getKPIGroupWidgetData,
        contents: widgetContents,
        hierarchyNode: mergedHierarchyNode,
        pageFilters,
        widgetFilters,
        timeRange: widgetStf.rangeSelection || pageTimeRange,
        updateInterval: getDataUpdateInterval(widgetUpdateTimeInterval),
    });
    

    // time range stuff
    const followPageTimeRange = !widgetStf.rangeSelection;
    const setTimeRange = useCallback((rs) => {
        setWidgetStf((stf) => ({
            ...stf,
            rangeSelection: rs,
        }));
    }, [setWidgetStf]);
    const widgetTimeRange = widgetStf.rangeSelection;

    const timeRangeRequests = useTimeRangeData({
        getTimeRangePrimaryDetails: dataService.getTimeRangePrimaryDetails,
        getTimeRangeSecondaryDetails: dataService.getTimeRangeSecondaryDetails,
        hierarchyNode: mergedHierarchyNode,
        timeRange: widgetStf.hideTimeRange ? undefined : widgetStf.rangeSelection,
        updateInterval: getDataUpdateInterval(widgetUpdateTimeInterval),
    });


    const timeRangeDescription = useMemo(() => {
        const primaryDetails = timeRangeRequests[TIME_RANGE_PRIMARY_DETAILS]?.response;
        const secondaryDetails = timeRangeRequests[TIME_RANGE_SECONDARY_DETAILS]?.response;

        if (primaryDetails && translator) {
            const primary = getPrimaryDescription({
                dimensionDetailsDisplayName: primaryDetails?.dimensionDetails.displayName,
                dimensionDetailsIsMixed: primaryDetails?.dimensionDetails.isMixed || false,
                timeRangeStart: primaryDetails?.timeRange.start,
                timeRangeEnd: primaryDetails?.timeRange.end,
                timeRangeSameDimensionValue: primaryDetails?.timeRange.sameDimensionValue || false,
                timeRangeUnits: primaryDetails?.timeRange.units,
                timeRangeProductionDayOffset: primaryDetails?.timeRange.productionDayOffset,
                atEndOfData: !!secondaryDetails?.atEndOfData,
                locale: primaryDetails.locale,
                translator: translator,
            });

            const secondary = getSecondaryDescription({
                range: secondaryDetails?.range,
                timeRangeStart: primaryDetails?.timeRange.start,
                timeRangeEnd: primaryDetails?.timeRange.end,
                timeRangeSameDimensionValue: primaryDetails?.timeRange.sameDimensionValue || null,
                timeRangeUnits: primaryDetails?.timeRange.units || '',
                originDay: primaryDetails?.originDay || '',
                locale: primaryDetails?.locale,
                translator: translator,
            });

            return {
                primary,
                secondary
            };
        }
        else {
            return null;
        }

    }, [timeRangeRequests, translator]);


    // print stuff
    // Effect to keep the widget's print status up to date.
    useEffect(() => {
        if (isPrint) {
            return;
        }

        setWidgetPrintStatus(RequestState.areAllRequestsCompleted([
            dataRequest,
            timeRangeRequests[TIME_RANGE_PRIMARY_DETAILS],
            timeRangeRequests[TIME_RANGE_SECONDARY_DETAILS],
        ]));
    }, [ isPrint, setWidgetPrintStatus, dataRequest, timeRangeRequests ]);

    return {
        defaultTitle,
        handleTitleChange,
        title,

        dataRequest: dataRequest,

        filterOptions: liveControlOptions.filterOptions,
        filters,
        setSliceFilters,
        formattedFilters,

        followPageTimeRange,
        setTimeRange,
        widgetTimeRange,
        timeRangeDescription,

        followAppBarHierarchyNode,
        hierarchyNode: widgetHierarchyNode,
        hierarchyNodeOptions: liveControlOptions.hierarchyNodeOptions,
        setHierarchyNode,

        columns: widgetContents,
        setColumns: setContents,
        columnOptions: liveControlOptions.columnOptions,

        isInitialized,

        supportsLiveControls,
        toggleShowLiveControls,
    };
};

export default useKPIGroupWidgetController;
