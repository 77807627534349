import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormElement from 'Components/form-element/FormElement';
import FormElementLabel from 'Components/form-element/FormElementLabel';
import SelectInput from 'Components/select/SelectInput';
import SelectOption from 'Components/select/SelectOption';
import SelectOptionContent from 'Components/select/SelectOptionContent';

const StyledSelectInput = styled(SelectInput)`
    min-width: 225px;
`;

function DndSelect(props) {
    const {
        id,
        label,
        name,
        value,
        error,
        onChange,
        ...otherProps
    } = props;

    return (
        <FormElement error={error} {...otherProps}>
            <FormElementLabel htmlFor={id}>{label}</FormElementLabel>
            <StyledSelectInput
                id={id}
                name={name}
                value={value}
                onChange={onChange}
            >
                <SelectOption value="window">
                    <SelectOptionContent>
                        Only Send Alerts During Receive Window
                    </SelectOptionContent>
                </SelectOption>
                <SelectOption value="always">
                    <SelectOptionContent>
                        Send Alerts 24/7
                    </SelectOptionContent>
                </SelectOption>
                <SelectOption value="never">
                    <SelectOptionContent>
                        Never
                    </SelectOptionContent>
                </SelectOption>
            </StyledSelectInput>
        </FormElement>
    );
}

DndSelect.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
};

export default DndSelect;
