import paramTypeWrapper from './paramTypeWrapper';

function basicTypeWrapper(name, test) {
    const wrappedTest = (arg, key) => {
        if (test(arg)) {
            return [];
        }
        else {
            return [`${key} must have type ${name}`];
        }
    };

    return paramTypeWrapper(wrappedTest);
}

export const object = basicTypeWrapper('object', (arg) => typeof arg === 'object' && arg !== null);

export const array = basicTypeWrapper('array', (arg) => Array.isArray(arg));

export const string = basicTypeWrapper('string', (arg) => typeof arg === 'string');

export const number = basicTypeWrapper('number', (arg) => typeof arg === 'number');

export const boolean = basicTypeWrapper('boolean', (arg) => typeof arg === 'boolean');

export const func = basicTypeWrapper('func', (arg) => typeof arg === 'function');

export const any = paramTypeWrapper(() => []);

// Custom "basic" types

export const paramType = basicTypeWrapper('paramType', (arg) => {
    return (typeof arg === 'object') && (typeof arg._test === 'function') && (typeof arg._required === 'boolean');
});
