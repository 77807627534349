import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TextButton from 'Components/button/TextButton';
import ContainedButton from 'Components/button/ContainedButton';
import TextInput from 'Components/text-input/TextInput';
import { Spacing } from 'Components/common/common';
import * as RequestStatus from 'Common/data/RequestStatus';
import PropTypes from 'prop-types';
import Form from 'Components/form/Form';

const StyledTextInput = styled(TextInput)``;
const LoginButtonsContainer = styled.div``;
const ForgotPasswordLink = styled(TextButton)``;
const SubmitButton = styled(ContainedButton)``;

const LoginFormBody = styled(Form)`
    display: block;
    width: 100%;
    box-sizing: border-box;

    ${StyledTextInput} {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    ${LoginButtonsContainer} {
        margin-top: ${Spacing.formButtonMargin};

        ${SubmitButton} {
            margin-right: ${Spacing.inlineButtonMargin};
        }

        ${ForgotPasswordLink} {
            text-decoration: none;
        }
    }
`;

export default class LoginForm extends React.Component {
    static propTypes = {
        initialValue: PropTypes.shape({
            email: PropTypes.string,
            password: PropTypes.string,
        }),
        request: PropTypes.shape({
            status: PropTypes.string.isRequired,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            email: props.initialValue?.email || '',
            password: props.initialValue?.password || '',
        };
    }

    _handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    };
    _handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    };

    _handleSubmit = (event) => {
        this.props.onSubmit({
            email: this.state.email,
            password: this.state.password,
        });
    };

    render() {
        const { request } = this.props;
        const { email, password } = this.state;

        const isLoading = request.status === RequestStatus.PENDING;
        const error = request.status === RequestStatus.FAILURE ?
            request.error : null;

        return (
            <LoginFormBody
                onSubmit={this._handleSubmit}
                error={error}
                showSubmit={false}
                showCancel={false}
                disableNavigationPrevention={true}
            >
                <StyledTextInput
                    label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="email@example.com"
                    required
                    disabled={isLoading}
                    value={email}
                    helperText=""
                    onChange={this._handleEmailChange}
                />
                <StyledTextInput
                    label="Password"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    required
                    disabled={isLoading}
                    value={password}
                    helperText=""
                    onChange={this._handlePasswordChange}
                />
                <LoginButtonsContainer>
                    <SubmitButton
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                    >
                        Log In
                    </SubmitButton>
                    <ForgotPasswordLink
                        Component={Link}
                        to={{
                            pathname: '/forgot-password',
                            state: { email: email },
                        }}
                        color="primary"
                        href="forgot-password"
                    >
                        Forgot Password?
                    </ForgotPasswordLink>
                </LoginButtonsContainer>
            </LoginFormBody>
        );
    }
}
