import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslator } from 'Common/hooks/useTranslation';

import { Subheading2 } from 'Common/components/typography';

const Root = styled.div`
    margin-top: 20px;
    margin-left: 0;
    margin-right: 10px;
`;

const Container = styled(Subheading2).attrs({ as: 'div' })`
    position: relative;
    display: block;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    height: 39px;
    max-width: 350px;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.emperor};

    transition: background-color 0.3s, border-color 0.3s;

    ${({hasFocus}) => hasFocus && css`
        background-color: ${({theme}) => theme.colors.palette.blue.ice};
        border-bottom-color: ${({theme}) => theme.colors.palette.blue.awesome};
    `}

`;

const TextInput = styled(Subheading2).attrs({ as: 'input' })`
    position: absolute;
    display: block;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    /* Since there is a bottom border, in order of the text to line up with
       the baseline we need to nudge down a pixel.
    */
    top: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;

    transition: color 0.3s;

    text-align: inherit;

    background-color: transparent;

    &::placeholder {
        color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
        opacity: 0.5;
        transition: color 0.3s, opacity 0.3s;
    }

    &:focus {
        /* Only hide placeholder text on focused number inputs */
        &[type="number"]::placeholder {
            opacity: 0;
        }
    }
`;

/**
 * This component is the Section Heading TextField. For now it does not use
 * TextField because there are some size complexity we would need to handle in TextField
 * that we are going to hold off on til we find another time we need to make this change.
 */
const SectionHeadingEditor = ({
    value,
    onChange,
    sectionIndex,
}) => {
    const [ hasFocus, setHasFocus ] = useState(false);

    const handleFocus = useCallback((event) => {
        setHasFocus(true);
    }, []);

    const handleBlur = useCallback((event) => {
        setHasFocus(false);
    }, []);

    const onTextFieldChange = useCallback((event) => {
        onChange({
            sectionIndex,
            sectionHeading: event.target.value,
        });
    }, [sectionIndex, onChange]);

    const translator = useTranslator();
    
    return (
        <Root>
            <Container
                hasFocus={hasFocus}
            >
                <TextInput
                    type="text"
                    placeholder={translator('dashboard.sections.heading_placeholder')}
                    value={value ?? ""}
                    onBlur={handleBlur}
                    onChange={onTextFieldChange}
                    onFocus={handleFocus}
                />
            </Container>
        </Root>
    );
};

SectionHeadingEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    sectionIndex: PropTypes.number,
};

export default SectionHeadingEditor;
