
import PropTypes from 'prop-types';

const IconSizeProp = PropTypes.oneOfType([
    PropTypes.oneOf([
        'small',
        'medium',
        // 'large', Not implemented yet...
    ]),
    // Custom sizing. Use sparingly.
    PropTypes.exact({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }),
]);

export default IconSizeProp;
