import { connect } from 'react-redux';
import React from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import LoadingPage from 'Components/pages/loading/LoadingPage';
import PropTypes from 'prop-types';
import { getDefaultPageForRole } from 'Components/app/defaultPages';
import { selectIsLoggedIn, selectIsInitialized, selectSessionUser } from 'Redux/reducers/session';

/**
 * A Route for pages which have no use to a logged in user, such as a landing page
 * or login page. It will automatically redirect to the default page for the
 * particular role of the user signed in.
 */
const StrictlyPublicRoute = ({ component: Component, redirectToPrevious, ...otherProps }) => {
    const { session } = otherProps;
    const showLoading = !selectIsInitialized(session);
    const isLoggedIn = selectIsLoggedIn(session);

    const renderFunc = (props) => {
        if (showLoading) {
            return (
                <LoadingPage />
            );
        }
        else if (isLoggedIn) {
            const { role } = selectSessionUser(session);

            let redirectPath = getDefaultPageForRole(role);

            if (redirectToPrevious) {
                const from = otherProps.location?.state?.from;

                if (from) {
                    // if came to this from a redirect and we are now logged in, time to go back to that page
                    redirectPath = from.path + from.search;
                }
            }

            return (
                <Redirect to={redirectPath} />
            );
        }
        else {
            return (
                <Component {...props} />
            );
        }
    };

    return (
        <Route
            {...otherProps}
            render={renderFunc}
        />
    );
};

StrictlyPublicRoute.defaultProps = {
    redirectToPrevious: false,
};

StrictlyPublicRoute.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            from: PropTypes.shape({
                path: PropTypes.string.isRequired,
                search: PropTypes.string.isRequired,
            })
        })
    }).isRequired,
    session: PropTypes.shape({
        isInitialized: PropTypes.bool.isRequired,
        sessionData: PropTypes.shape({
            role: PropTypes.string,
        }),
        requests: PropTypes.shape({
            getSessionUser: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
            login: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    component: PropTypes.func.isRequired,
    // If true, redirect the page based on the router location 'from' value,
    // instead of the default page. If there is no from value, redirect to the
    // default page.
    //
    redirectToPrevious: PropTypes.bool,
};

const mapStateToProps = state => {
    const { session } = state;
    return { session };
};

export default withRouter(connect(mapStateToProps)(StrictlyPublicRoute));
