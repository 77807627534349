import React, {
    useCallback,
    useState,
} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styled from 'styled-components';

import PropTypes from 'prop-types';
import LiveControlDropdownHeader from 'Common/components/LiveControlBar/LiveControlDropdownHeader';
import LiveControlDropdownLayout from 'Common/components/LiveControlBar/LiveControlDropdownLayout';
import Checkbox from 'Common/components/Checkbox';
import LiveControlIcon from 'Common/components/LiveControlBar/LiveControlIcon';
import Icon from 'Common/components/Icon';
import useStackHistory from 'Common/hooks/useStackHistory';
import BasicSelectOption from 'Common/components/SelectList/BasicSelectOption';
import SelectList from 'Common/components/SelectList';
import SelectSeparator from 'Common/components/SelectList/SelectSeparator';
import { ProductionDay } from 'Common/data/date/ProductionDay';
import Button from 'Common/components/Button';
import { useTranslator } from 'Common/hooks/useTranslation';

const DatePickerWrapper = styled.div`
    align-self: center;
`;

const CalendarContainer = styled.div`
    font-size: 12px;
    font-family: inherit;
    color: inherit;
    border-radius: 4px;

    .react-datepicker__current-month {
        font-size: 0.9em;
        font-family: inherit;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: inherit;
        width: 25px;
        line-height: 25px;
    }

    .react-datepicker__day--keyboard-selected {
        color: inherit;
        background-color: transparent;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        border-radius: 50%;
        background-color:  ${({theme}) => theme.colors.palette.grey.ash};
        color: ${({theme}) => theme.colors.palette.grey.cloud};
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        border-radius: 50%;
        background-color: ${({theme}) => theme.colors.palette.grey.manatee};
        color: ${({theme}) => theme.colors.palette.grey.cloud};
    }

    .react-datepicker__header {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    }

    .react-datepicker__navigation--previous {
        border-right-color: ${({theme}) => theme.colors.palette.grey.manatee};
    }

    .react-datepicker__navigation--previous:hover {
        border-right-color: ${({theme}) => theme.colors.palette.grey.ash};
    }

    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
        border-left-color: ${({theme}) => theme.colors.palette.grey.manatee};
        opacity: 50%;
    }

    .react-datepicker__navigation--next {
        border-left-color: ${({theme}) => theme.colors.palette.grey.manatee};
    }

    .react-datepicker__navigation--next:hover {
        border-left-color: ${({theme}) => theme.colors.palette.grey.ash};
    }

    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
        border-left-color: ${({theme}) => theme.colors.palette.grey.manatee};
        opacity: 50%;
    }
`;

const DatePickerControl = styled.div`
    display: flex;
    flex-direction: column;

    padding: 12px;

    & > div {
        display: inline-block;
    }
`;

const ApplyButton = styled(Button)`
    margin-top: 12px;
    align-self: flex-end;
`;

const Root = styled.div`
    box-sizing: border-box;
    width: 275px;
    display: flex;
    flex-direction: column;
    min-height: 1px;
    color: ${({theme}) => theme.colors.darkText.highEmphasis};
`;

const Separator = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
    margin: 3px 11px;
`;

const StyledBasicSelectOption = styled(BasicSelectOption)`
    flex: 1 1 auto;
`;

const OptionContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledIcon = styled(Icon)`
    font-size: 9px;
`;

const StyledCheckbox = styled(Checkbox)`
    padding-left: 18px;
    padding-right: 18px;
`;

const INITIAL = 'initial';

// These all look "off by one" as the start and end range is inclusive, so that
// { start: -6, end: 0 }, is a seven day (one week) time range.
//
const lastNOptions = {
    week: 6,
    month: 29,
    quarter: 89,
    year: 364,
};

const staticMenuOptions = {
    'this': {
        start: 0,
        end: 0,
    },
    'last': {
        start: -1,
        end: -1,
    },
    '2_back': {
        start: -2,
        end: -2,
    },
    '3_back': {
        start: -3,
        end: -3,
    },
};

function getTimeRangeFromMenuOption(type, count = null) {
    if (type === 'last_n') {
        return {
            units: 'production_day',
            start: -count,
            end: 0,
        };
    }

    return { ...staticMenuOptions[type] };
}

// need to handle ordinal_shift same as shift
function handleUnits(units) {
    if (units === 'ordinal_shift') {
        return 'shift';
    }
    else {
        return units;
    }
}

function getMenuOptionFromTimeRange(timeRangeValue, openMenuName) {
    const outer = handleUnits(timeRangeValue?.units);
    let menuOption = {
        outer,
        inner: undefined,
    };

    // First check if we are a "same" type
    if (timeRangeValue?.sameDimensionValue) {
        menuOption.inner = 'same';
    }
    // Then check if start and end match our static options
    else {
        const staticOption = Object.keys(staticMenuOptions).find((key) => {
            const staticTimeRange = staticMenuOptions[key];

            return timeRangeValue?.start === staticTimeRange.start
                && timeRangeValue?.end === staticTimeRange.end;
        });

        if (staticOption) {
            menuOption.inner = staticOption;
        }
        // If not, move on to the 'last_n' options if end is 0
        else if(timeRangeValue?.end === 0) {
            const matchingLastNKey = Object.keys(lastNOptions).find((lastNOptionKey) => {
                return timeRangeValue.start === -lastNOptions[lastNOptionKey];
            });

            if (matchingLastNKey) {
                menuOption.outer = matchingLastNKey;
                menuOption.inner = 'last_n';
            }
        }
    }

    // The outer menu does not match the currently selected menu,
    // therefore there must not be an inner menu option for this
    // menu selection.
    //
    if (menuOption.outer !== openMenuName) {
        delete menuOption.inner;
    }

    return menuOption;
}

export default function TimeRangeControl(props) {
    const {
        monthLimited,
        isDayBased,
        showFollowPageCheckbox,
        dimensionValueDetails,

        selectionValue,
        followPageValue,
        onSelectionChange,
        onFollowPageChange,
        onSameOnlyOptionChange,
    } = props;

    const translator = useTranslator();

    const icon = (
        <LiveControlIcon type="vorne" iconName="time" />
    );

    // handles navigation between different controls
    const {
        current: shownItemProps,
        canPop: internalCanGoBack,
        push: transitionForward,
        pop: internalGoBack,
    } = useStackHistory({ name: INITIAL, props: {}});

    const shownItemName = shownItemProps.name;

    let title = translator('time_range_selector_dropdown.title');

    const [ customStartDate, setCustomStartDate] = useState(selectionValue?.units === 'time'
        ? new ProductionDay(selectionValue.start).toDate()
        : null
    );
    const [ customEndDate, setCustomEndDate] = useState(selectionValue?.units === 'time'
        ? new ProductionDay(selectionValue.end).toDate()
        : null
    );

    if (shownItemName === 'time') {
        title = translator('time_range_selector_dropdown.custom_dates');
    }
    else if (shownItemName !== INITIAL) {
        title = translator(`time_range_selector_dropdown.${shownItemName}.singular`);
    }

    let currentControl = null;

    const handleHeaderGoBack =  internalCanGoBack ? internalGoBack : null;

    const handleInnerMenuSelection = useCallback((identifier, event) => {
        const { value: menuValue } = event.target;

        if (menuValue === 'same') {
            onSameOnlyOptionChange();
        }
        else if (identifier !== 'all') {
            let count = menuValue === 'last_n'
                ? lastNOptions[identifier]
                : undefined;

            const value = {
                units: identifier,
                ...getTimeRangeFromMenuOption(menuValue, count),
            };

            onSelectionChange({
                target: {
                    value,
                },
            });
        }
        else if (identifier === 'all') {
            onSelectionChange({
                target: {
                    value: {
                        units: identifier,
                    }
                },
            });
        }

    }, [onSelectionChange, onSameOnlyOptionChange]);

    const handleInitialMenuSelection = (event) => {
        const { value } = event.target;

        if (value === 'all') {
            handleInnerMenuSelection('all', event);
        }
        else if (value === 'time') {
            transitionForward(
                {name: value, props: {name: 'time'}}
            );
        }
        else {
            transitionForward(
                {name: value, props: {}},
                {name: INITIAL, props: {}}
            );
        }
    };

    let buildWrapper = function(identifier) {
        return (
            <StyledBasicSelectOption
                value={identifier}
            >
                <OptionContentWrapper>
                    {translator(`time_range_selector_dropdown.${identifier}.singular`)}
                    <StyledIcon
                        type="vorne"
                        iconName="collapse-arrow-closed"
                    />
                </OptionContentWrapper>
            </StyledBasicSelectOption>
        );
    };

    const followPageCheckbox = showFollowPageCheckbox
        ? (
            <React.Fragment>
                <StyledCheckbox
                    label={translator('time_range_selector_dropdown.follow_page')}
                    value={followPageValue}
                    onChange={onFollowPageChange}
                />
                <Separator />
            </React.Fragment>
        )
        : null;

    const currentMenuOption = getMenuOptionFromTimeRange(selectionValue, shownItemName);

    if (shownItemName === INITIAL) {
        currentControl = (
            <React.Fragment>
                {followPageCheckbox}
                <SelectList
                    onChange={handleInitialMenuSelection}
                    disabled={followPageValue}
                    value={currentMenuOption.outer}
                >
                    {!isDayBased && buildWrapper('shift')}
                    {!isDayBased && buildWrapper('part')}
                    {!isDayBased && <SelectSeparator />}
                    {!isDayBased && buildWrapper('shift_hour')}
                    {buildWrapper('production_day')}
                    {buildWrapper('week')}
                    {buildWrapper('month')}
                    {!monthLimited && buildWrapper('quarter')}
                    {!monthLimited && buildWrapper('year')}
                    <SelectSeparator />
                    {!monthLimited && (
                        <StyledBasicSelectOption value="all">
                            {translator('time_range_selector_dropdown.all.singular')}
                        </StyledBasicSelectOption>
                    )}
                    <StyledBasicSelectOption value="time">
                        <OptionContentWrapper>
                            {translator('time_range_selector_dropdown.custom_dates')}
                            <StyledIcon
                                type="vorne"
                                iconName="collapse-arrow-closed"
                            />
                        </OptionContentWrapper>
                    </StyledBasicSelectOption>
                </SelectList>
            </React.Fragment>
        );
    }
    else if (shownItemName === 'time') {
        currentControl = (
            <DatePickerControl>
                <DatePickerWrapper>
                    <DatePicker
                        calendarContainer={CalendarContainer}
                        onChange={(dates) => {
                            const [newStartDate, newEndDate] = dates;
                            setCustomStartDate(newStartDate);
                            setCustomEndDate(newEndDate);
                        }}
                        openToDate={customStartDate ? customStartDate : undefined}
                        startDate={customStartDate}
                        endDate={customEndDate}
                        inline
                        selectsRange
                    />
                </DatePickerWrapper>
                <ApplyButton
                    key="apply"
                    type="primary"
                    disabled={!customEndDate}
                    onClick={() => {
                        // The datepicker will give us dates that are midnight
                        // of the selected date. Some locales can make this
                        // a problem.
                        //
                        // For example, London's daylight savings
                        // time offset is GMT+0100 and their standard time is
                        // GMT+0000. Assume the browser's timezone is London,
                        // and the current date is during standard time.
                        // Constructing a Date for midnight on a date during
                        // DST will assume the offset at that date.
                        // So you will get something like the following:
                        //     >> new Date('2022-07-13T00:00:00').toISOString()
                        //     << '2022-07-12T23:00:00.000Z'
                        // Notice how the date "went back" from the 13th to the
                        // 12th. This is not good when the user is selecting
                        // a date, not a time.
                        //
                        // Instead, we can extract the selected year+month+day
                        // from the Date objects and use those to construct the
                        // ProductionDay so we don't lose the date.

                        const getProductionDayNumber = (d) => {
                            return new ProductionDay(
                                d.getFullYear(),
                                d.getMonth() + 1,
                                d.getDate(),
                            ).getProductionDayNumber();
                        };

                        onSelectionChange({
                            target: {
                                value: {
                                    units: 'time',
                                    start: getProductionDayNumber(customStartDate),
                                    end: getProductionDayNumber(customEndDate)
                                }
                            }
                        });
                    }}
                >
                    {translator('apply_literal')}
                </ApplyButton>
            </DatePickerControl>
        );
    }
    else {
        const lastNStart = lastNOptions[shownItemName];

        let sameOnlyOption = null;
        const hasSameOnlyOption = ['shift', 'part'].includes(shownItemName);

        if (hasSameOnlyOption) {
            let sameOnlyKeyPart = !dimensionValueDetails?.isMixed && ( !dimensionValueDetails?.sameBackDetails?.key && dimensionValueDetails?.displayName )
                ? 'same_category_multi_asset'
                : 'same_category';
            let sameOnlyKeyParams = {
                category: translator(`time_range_selector_dropdown.${shownItemName}.singular`),
            };

            const correctSameBackDetails = handleUnits(dimensionValueDetails?.sameBackDetails?.units) === shownItemName
                && handleUnits(selectionValue?.units) === shownItemName;

            const shouldBeDisabled = !correctSameBackDetails
                || dimensionValueDetails?.isMixed
                || (!dimensionValueDetails?.sameBackDetails?.key || dimensionValueDetails?.sameBackDetails?.key === 'null');

            if (!shouldBeDisabled) {
                sameOnlyKeyParams = {
                    category: dimensionValueDetails?.displayName,
                };
            }

            sameOnlyOption = [
                <SelectSeparator key="sameSep" />,
                (
                    <StyledBasicSelectOption
                        key="sameOpt"
                        value="same"
                        disabled={shouldBeDisabled}
                    >
                        {translator(`time_range_selector_dropdown.${sameOnlyKeyPart}`, sameOnlyKeyParams)}
                    </StyledBasicSelectOption>
                ),
            ];
        }

        currentControl = (
            <SelectList
                onChange={(e) => handleInnerMenuSelection(shownItemName, e)}
                value={currentMenuOption.inner}
            >
                <StyledBasicSelectOption value="this">
                    {translator(`time_range_selector_dropdown.${shownItemName}.this`)}
                </StyledBasicSelectOption>
                <SelectSeparator />
                <StyledBasicSelectOption value="last">
                    {translator(`time_range_selector_dropdown.${shownItemName}.last`)}
                </StyledBasicSelectOption>
                <StyledBasicSelectOption value="2_back">
                    {translator(`time_range_selector_dropdown.${shownItemName}.2_back`)}
                </StyledBasicSelectOption>
                <StyledBasicSelectOption value="3_back">
                {translator(`time_range_selector_dropdown.${shownItemName}.3_back`)}
                </StyledBasicSelectOption>
                {lastNStart && [
                        (<SelectSeparator key="lastNSep" />),
                        (<StyledBasicSelectOption key="lastNOpt" value="last_n">
                            {translator('time_range_selector_dropdown.last_n_days', {
                                n: lastNStart + 1,
                            })}
                        </StyledBasicSelectOption>)
                    ]
                }
                {onSameOnlyOptionChange && sameOnlyOption}
            </SelectList>
        );
    }

    const header = (
        <LiveControlDropdownHeader
            displayName={title}
            icon={icon}
            goBack={handleHeaderGoBack}
        />
    );
    const body = (<Root>{currentControl}</Root>);

    return  (
        <LiveControlDropdownLayout
            header={header}
            body={body}
        />
    );
}

TimeRangeControl.propTypes = {
    monthLimited: PropTypes.bool,
    isDayBased: PropTypes.bool,

    selectionValue: PropTypes.shape({
        units: PropTypes.string.isRequired,
        start: PropTypes.number,
        end: PropTypes.number,
        sameDimensionValue: PropTypes.string,
    }),

    showFollowPageCheckbox: PropTypes.bool,
    followPageValue: PropTypes.bool,
    onSelectionChange: PropTypes.func.isRequired,
    onFollowPageChange: PropTypes.func,

    dimensionValueDetails: PropTypes.shape({
        displayName: PropTypes.string,
        isMixed: PropTypes.bool,
        sameBackDetails: PropTypes.shape({
            key: PropTypes.string,
            units: PropTypes.string,
        }),
    }),
    onSameOnlyOptionChange: PropTypes.func
};

TimeRangeControl.defaultProps = {
    showFollowPageCheckbox: false,

    monthLimited: false,
    isDayBased: false,
};
