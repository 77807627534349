import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { T2, Body2, Body1 } from 'Components/typography/typography';

const LicenseContainer = styled.div`
    margin-bottom: 20px;
`;

const LicenseName = styled(T2)`
    display: block;
    color: ${props => props.theme.colors.palette.blue.awesome};
    text-transform: uppercase;
    margin-bottom: 10px;
`;

const LicenseIntro = styled(Body1)`
    display: block;
    margin-bottom: 10px;
`;

const LicenseText = styled(Body2)`
    display: block;
    white-space: pre-wrap;
    margin-left: 50px;
    margin-bottom: 30px;
`;

function LicenseDescription({ name, text }) {
    return (
        <LicenseContainer>
            <LicenseName>
                {name}
            </LicenseName>
            <LicenseIntro>
                This product contains code from the {name} package, which is licensed as follows.
            </LicenseIntro>
            <LicenseText>
                {text}
            </LicenseText>
        </LicenseContainer>
    );
}

LicenseDescription.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default LicenseDescription;
