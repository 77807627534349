import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import findIndex from 'lodash/findIndex';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';

import SelectOptionWrapper from './internal/SelectOptionWrapper';

import { Body5 } from '../typography';
import { childrenAreOneOf } from 'Common/util/propTypeHelpers';
import SelectSeparator from './SelectSeparator';
import SelectOptionGroup from './SelectOptionGroup';
import CollapsibleSelectOptionGroup from './CollapsibleSelectOptionGroup';

function findChildIndexByValue(children, value) {
    const childArray = React.Children.toArray(children);

    return findIndex(childArray, (child) => {
        return child.props.value === value;
    });
}

export function doesOptionMatchSearchFilter(optionChild, searchFilter) {
    return (
        searchFilter === '' ||
        (includes(toLower(optionChild.props.searchValue || optionChild.props.value), searchFilter) && !optionChild.props.disabled)
    );
}
/**
 * Determine if value is one of the selected values.
 * @param {String} item value to check
 * @param {String/String[]} selected selected values
 * @param {Boolean} isMultiSelect has multiple selected values
 */
function isItemSelected(item, selected, isMultiSelect) {
    return isMultiSelect
        ? !!selected.find((x) => (x === item))
        : item === selected;
}

/** 
    Based on passed in children (actual selection options/their group containers/separators)
    1. filter out children based on search filter
    2. separate selected from non-selected based on separateSelected
    3. maintain which groups have selected children in selectedGroupNames
 * @param {ReactNode[]} children Children of a react node to organize
 * @param {String} searchFilter String used to filter out children
 * @param {Boolean} separateSelected decides if selected children should stay within hierarchy
 * @param {String/String[]} values used to determine which children are selected
 * @param {ReactNode[]} selected hold onto selected children
 * @param {String[]} selectedGroupNames hold onto selected group names
*/
function organizeVisibleChildren({children, searchFilter, separateSelected, values, selected, selectedGroupNames}) {
    let filteredChildren = React.Children.toArray(children)
        .map((child, i) => {
            if (child.type === SelectOptionGroup || child.type === CollapsibleSelectOptionGroup) {
                const prevSelectedLength = selected.length;
                const groupChildren = organizeVisibleChildren({children: child.props.children, searchFilter, separateSelected, values, selected, selectedGroupNames});

                if (groupChildren.length) {
                    if (prevSelectedLength < selected.length) {
                        selectedGroupNames.push(child.props.groupName);
                    }
                    return React.cloneElement(child, {
                        children: groupChildren,
                    });
                }
                else {
                    return null;
                }
            }
            else if (child.type === SelectSeparator) {
                return child;
            }
            else {
                if (doesOptionMatchSearchFilter(child, searchFilter)) {
                    const isSelected = isItemSelected(child.props.value, values, separateSelected);
                    let hideChild = isSelected && separateSelected;
                    if (isSelected) {
                        selected.push(child);
                    }

                    return hideChild ? null : child;
                }
                else {
                    return null;
                }
            }
        })
        .filter((child) => {
            return Boolean(child);
        })
        .filter((child, i, newChildren) => {
            // Remove all separators after another separator
            if (child?.type === SelectSeparator) {
                const hideSeparator = (
                    newChildren[i - 1]?.type === SelectSeparator
                );

                return !hideSeparator;
            }
            else {
                return true;
            }
        });

        // remove separator if it's first in the list
        if (filteredChildren[0]?.type === SelectSeparator) {
            filteredChildren.shift();
        }

        // remove separator if it's last in the list
        if (filteredChildren[filteredChildren.length - 1]?.type === SelectSeparator) {
            filteredChildren.pop();
        }

        return filteredChildren;
}

function flattenOptions(children, curr = []) {
    children.forEach((child) => {
        if (child.type === SelectOptionGroup || child.type === CollapsibleSelectOptionGroup) {
            // Add group child options to array
            flattenOptions(React.Children.toArray(child.props.children), curr);
            return;
        }
        else if (child.type === SelectSeparator) {
            // Skip
            return;
        }

        // Add option to array
        curr.push(child);
    });

    return curr;
}

const Root = styled.div`
    width: 100%;
    min-height: 10px;

    display: flex;
    flex-direction: column;
    outline: none;
`;

const OptionsContainer = styled.div`
    overflow-x: auto;
    overflow-y: auto;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: ${({$verticalPadding}) => $verticalPadding }px;
    padding-bottom: ${({$verticalPadding}) => $verticalPadding }px;

    /* This trick forces the element to be on its own layer,
       which prevents the browser from repainting the element
       on scroll, avoiding performance hitches.
    */
   transform: translateZ(0);
`;

const StyledNoSelection = styled(Body5).attrs({ as: 'div' })`
    color: ${({ theme }) => theme.colors.palette.grey.ash };
    font-style: italic;
    padding: 10px;
    text-align: left;
`;

const StyledInstructionContainer = styled(Body5).attrs({ as: 'div' })`
    display: inline-block;
    color: ${({ theme }) => theme.colors.darkText.mediumEmphasis };
    padding: 0 14px;
    max-width: 225px;
`;

export default class SelectList extends React.PureComponent {
    static propTypes = {
        children: childrenAreOneOf({
            elementTypes: [ SelectSeparator, SelectOptionGroup, CollapsibleSelectOptionGroup ],
            propShapes: [
                ['value']
            ],
        }),
        searchValue: PropTypes.string,
        noSelectionText: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
        ]),
        instruction: PropTypes.node,
        disabled: PropTypes.bool,
        verticalPadding: PropTypes.number,
    };

    static defaultProps = {
        value: '',
        searchValue: '',
        disabled: false,
        verticalPadding: 10,
    };

    constructor(props) {
        super(props);

        this.state = {
            focusedValue: null,
            containerHeight: null,
            containerWidth: null,
            openGroups: {},
            // Derived in `getDerivedStateFromProps`
            defaultGroupOpen: false,
            searchFilterClean: '',
            visibleChildren: [],
            selectableChildren: [],
            isMultiSelect: false,
            userHasSearched: false,
        };

        this._containerRef = React.createRef();
        this._focusedRef = React.createRef();
    }

    _handleCollapsibleGroupClick = (groupName) => {
        this.setState(({ openGroups }) => {
            return {
                openGroups: {
                    ...openGroups,
                    [groupName]: !openGroups[groupName],
                },
            };
        });
    };

    _handleSelectOptionClick = (event) => {
        if (this.state.isMultiSelect) {

            const newItem = event.target.value;

            // copy value and filter out newItem
            let newValue = this.props.value.filter((x) => (x !== newItem));

            // newItem wasn't found so must be new selection
            if (newValue.length === this.props.value.length) {
                newValue.push(newItem);
            }

            this.props.onChange({
                target: {
                    value: newValue
                }
            });
        }
        else {
            this.props.onChange(event);
        }
    };

    _handleKeyDown = (event) => {
        const { key } = event;

        if (key === 'ArrowUp' || key === 'ArrowDown') {
            event.preventDefault();

            this.setState((state) => {
                const { focusedValue, selectableChildren, isMultiSelect } = state;
                const { value } = this.props;
                const firstValue = ( isMultiSelect ? value[0] : value ) || null;

                let childIndex;

                if (focusedValue === null) {
                    childIndex = findChildIndexByValue(selectableChildren, firstValue);
                }
                else {
                    childIndex = findChildIndexByValue(selectableChildren, focusedValue);
                }

                if (key === 'ArrowUp' && childIndex > 0 && selectableChildren[childIndex - 1]) {
                    this._shouldScrollOnUpdate = true;

                    return { focusedValue: selectableChildren[childIndex - 1].props.value };
                }
                else if (key === 'ArrowDown' && childIndex < selectableChildren.length - 1 && selectableChildren[childIndex + 1]) {
                    this._shouldScrollOnUpdate = true;

                    return { focusedValue: selectableChildren[childIndex + 1].props.value };
                }
            });
        }
        else if (key === 'Enter' && this.state.focusedValue) {
            event.preventDefault();

            // selection moves child to top of list don't move focus there
            // try to first move it down then try up unless this is the last selected value
            this.setState((state) => {
                const { focusedValue, selectableChildren, isMultiSelect } = state;
                const childIndex = findChildIndexByValue(selectableChildren, focusedValue);

                if (isMultiSelect && childIndex > -1) {
                    let nextIndex = null;
                    let newFocusedValue = null;

                    if ((childIndex + 1) < selectableChildren.length) {
                        nextIndex = childIndex + 1;
                    }
                    else if ((childIndex - 1) > -1) {
                        nextIndex = childIndex - 1;
                    }

                    if (nextIndex !== null) {
                        this._shouldScrollOnUpdate = true;
                        newFocusedValue = selectableChildren[nextIndex].props.value;
                    }

                    return { focusedValue: newFocusedValue };
                }
            });

            this._handleSelectOptionClick({ target: { value: this.state.focusedValue } });
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {
            openGroups: prevState.openGroups,
            searchFilterClean: prevState.searchFilterClean,
            visibleChildren: [],
            selectableChildren: [],
            defaultGroupOpen: prevState.defaultGroupOpen,
            isMultiSelect: Array.isArray(nextProps.value),
            userHasSearched: prevState.userHasSearched
        };

        // `searchFilterClean` is the cleaned-up version of `searchValue`
        newState.searchFilterClean = toLower(nextProps.searchValue).trim();

        let selected = [];
        let selectedGroupNames = [];
        newState.visibleChildren = organizeVisibleChildren({
            children: nextProps.children,
            searchFilter: newState.searchFilterClean,
            separateSelected: newState.isMultiSelect,
            values: nextProps.value,
            selected,
            selectedGroupNames
        });

        if (newState.isMultiSelect && selected.length > 0) {
            newState.visibleChildren = newState.visibleChildren.length > 0
                ? selected.concat([(<SelectSeparator key='separate_selected'/>)]).concat(newState.visibleChildren)
                : selected;
        }

        newState.selectableChildren = flattenOptions(newState.visibleChildren);

        const didSearchFilterChange = prevState.searchFilterClean !== newState.searchFilterClean;

        if (didSearchFilterChange) {
            newState.userHasSearched = true;

            if (newState.searchFilterClean.length === 0) {
                // The search filter has just been cleared. Collapse all groups.
                newState.defaultGroupOpen = false;
                newState.openGroups = {};
            }
            else {
                // Open all groups
                newState.defaultGroupOpen = true;
                newState.openGroups = {};
            }
        }

        if (selectedGroupNames.length && !newState.isMultiSelect && typeof newState.openGroups[selectedGroupNames[0]] !== 'boolean' && !newState.userHasSearched ) {
            newState.openGroups[selectedGroupNames[0]] = true;
        }

        return newState;
    }

    _renderOptions(children) {
        if (children.length === 0) {
            const { noSelectionText } = this.props;

            return (
                <StyledNoSelection>{noSelectionText}</StyledNoSelection>
            );
        }

        return React.Children.map(children, (child) => {
            if (child.type === SelectOptionGroup || child.type === CollapsibleSelectOptionGroup) {
                let childProps = {
                    children: this._renderOptions(child.props.children)
                };

                if (child.type === CollapsibleSelectOptionGroup) {
                    childProps.groupName = child.props.groupName;
                    childProps.open = this._isCollapsibleGroupOpen(child.props.groupName);
                    childProps.onLabelClick = this._handleCollapsibleGroupClick;
                }

                return React.cloneElement(child, childProps);
            }
            else if (child.type === SelectSeparator) {
                return child;
            }
            else {
                const childValue = child.props.value;
                const isFocused = this.state.focusedValue === childValue;
                const isDisabled = child.props.disabled || this.props.disabled;
                const value = this.props.value;
                const isSelected = isItemSelected(childValue, value, this.state.isMultiSelect);

                let ref = undefined;

                if (isFocused || (isSelected && !this.state.isMultiSelect)) {
                    ref = this._focusedRef;
                }

                return (
                    <div
                        ref={ref}
                        key={`${childValue}`}
                    >
                        <SelectOptionWrapper
                            isFocused={isFocused}
                            isSelected={isSelected}
                            onClick={this._handleSelectOptionClick}
                            value={childValue}
                            isMultiSelect={this.state.isMultiSelect}
                            disabled={isDisabled}
                        >
                            {child}
                        </SelectOptionWrapper>
                    </div>
                );
            }
        });
    }

    _isCollapsibleGroupOpen(groupName) {
        const { openGroups, defaultGroupOpen } = this.state;

        // If a value exists for the group in `openGroups`, use that. Otherwise,
        // use the default.
        //
        if (typeof openGroups[groupName] === 'boolean') {
            return openGroups[groupName];
        }
        else {
            return defaultGroupOpen;
        }
    }

    _isScrolledIntoView(el) {
        const containerEl = this._containerRef.current;

        if (!containerEl || !el) {
            return false;
        }

        const containerTop = containerEl.scrollTop;
        const containerBottom = containerTop + containerEl.clientHeight;

        const elTop = el.offsetTop;
        const elBottom = elTop + el.clientHeight;

        return ((elBottom <= containerBottom) && (elTop >= containerTop));
    }

    _scrollIntoView(el, opts = {}) {
        const containerEl = this._containerRef.current;

        if (!containerEl || !el) {
            return ;
        }

        const currentScrollTop = containerEl.scrollTop;
        const scrollElHeight = containerEl.clientHeight;
        const currentScrollBottom = currentScrollTop + scrollElHeight;

        const elHeight = el.clientHeight;
        const elOffsetTop = el.offsetTop;
        const elOffsetBottom = elOffsetTop + elHeight;

        let newScrollTop = currentScrollTop;

        if (opts.center) {
            newScrollTop = elOffsetTop - (scrollElHeight / 2);
        }
        else {
            if (elOffsetBottom > currentScrollBottom) {
                newScrollTop = currentScrollTop + elOffsetBottom - currentScrollBottom + elHeight;
            }
            else if (elOffsetTop < currentScrollTop) {
                newScrollTop = elOffsetTop - elHeight;
            }
        }

        containerEl.scrollTop = newScrollTop;
    }

    componentDidUpdate() {
        if (this._shouldScrollOnUpdate) {
            this._shouldScrollOnUpdate = false;

            const focusedEl = this._focusedRef.current;

            if (!this._isScrolledIntoView(focusedEl)) {
                this._scrollIntoView(focusedEl);
            }
        }
    }

    componentDidMount() {
        const el = this._focusedRef.current;

        if (!this.state.isMultiSelect) {
            this._scrollIntoView(el, {
                center: true,
            });
        }
    }

    render() {
        const {
            instruction,
            verticalPadding,
        } = this.props;

        const {
            visibleChildren,
            containerHeight,
            containerWidth,
        } = this.state;

        const instructionArea = instruction && (
            <StyledInstructionContainer>
                {instruction}
            </StyledInstructionContainer>
        );

        const containerStyle = {
            height: containerHeight,
            width: containerWidth,
        };

        return (
            <Root
                onKeyDown={this._handleKeyDown}
                tabIndex={0}
            >
                {instructionArea}
                <OptionsContainer
                    ref={this._containerRef}
                    style={containerStyle}
                    role="listbox"
                    $verticalPadding={verticalPadding}
                >
                    {this._renderOptions(visibleChildren)}
                </OptionsContainer>
            </Root>
        );
    }
}
