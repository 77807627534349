import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getOrganization, patchOrganization, patchOrganizationReset } from 'Redux/actions';
import * as RequestStatus from 'Common/data/RequestStatus';

import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentStandard';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import OrganizationEditor from 'Components/organization-editor/OrganizationEditor';
import validate from 'Components/organization-editor/OrganizationEditorValidation';
import Form from 'Components/form/Form';

import RequestLoadingWrapper from 'Components/loading-indicator/RequestLoadingWrapper';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import PageDescription from 'Components/page/PageDescription';

export class OrganizationPagePresentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            organization: props.organization,
        };

        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleReset = this._handleReset.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._renderForm = this._renderForm.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const isLoaded = props.requests &&
            props.requests.getOrganization &&
            (props.requests.getOrganization.status === RequestStatus.SUCCESS);

        if (!state.organization && props.organization && isLoaded) {
            // Data has loaded, update the derived state.
            return { organization: props.organization };
        }
        return null; // no changes to state
    }

    componentDidMount() {
        this.props.onDidMount();
    }

    componentWillUnmount() {
        this.props.onWillUnmount();
    }

    _handleSubmit(event) {
        const initialValue = this.props.organization;
        const value = this.state.organization;

        // Only include the fields that A) the API accepts and B) have changed.
        let payload = {};
        const fields = Array('name');
        for (let ii in fields) {
            let name = fields[ii];
            if (value[name] !== initialValue[name]) {
                payload[name] = value[name].trim();
            }
        }

        this.props.onSubmit(payload);
    }

    _handleChange(event) {
        const { value } = event.target;

        this.setState({
            organization: { ...value },
        });
    }

    _handleReset(event) {
        this.setState({
            organization: { ...this.props.organization },
        });
    }

    _renderForm() {
        const { requests, organization: originalOrganization } = this.props;
        const { organization } = this.state;

        const hasChanges = !isEqual(organization, originalOrganization);
        const validation = validate(organization);

        const allowEdit = requests.patchOrganization.status !== RequestStatus.PENDING;
        const allowSubmit =
            allowEdit &&
            !some(validation, val => val !== null) &&
            hasChanges;
        const allowCancel =
            allowEdit &&
            hasChanges;

        return (
            <Form
                error={requests.patchOrganization?.error}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <OrganizationEditor
                    value={organization}
                    validation={validation}
                    allowEdit={allowEdit}
                    onChange={this._handleChange}
                />
            </Form>
        );
    }

    render() {
        return (
            <Page>
                <ConfigPageTitle>Organization Name</ConfigPageTitle>
                <PageDescription>
                    Use this page to change the name of the Organization.
                </PageDescription>
                <PageContent>
                    <RequestLoadingWrapper
                        request={this.props.requests.getOrganization}
                        render={this._renderForm}
                    />
                </PageContent>
            </Page>
        );
    }
}

OrganizationPagePresentation.propTypes = {
    onDidMount: PropTypes.func.isRequired,
    onWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    organization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }),
    requests: PropTypes.shape({
        getOrganization: PropTypes.shape({
            status: PropTypes.string.isRequired,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired,
        patchOrganization: PropTypes.shape({
            status: PropTypes.string.isRequired,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
};

OrganizationPagePresentation.defaultProps = {
    organization: null,
};

const mapStateToProps = (state, ownProps) => {
    const { organization } = state;

    return {
        organization: organization.data || null,
        requests: organization.requests,
    };
};

const mapDispatchToProps = {
    onWillUnmount: patchOrganizationReset,
    onDidMount: getOrganization,
    onSubmit: patchOrganization,
};

// connect the component to Redux
const OrganizationPage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationPagePresentation));

export default OrganizationPage;
