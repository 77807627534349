import { INIT_APP_COMPLETE } from 'Redux/actions';
import { requestReducer } from 'Common/util/ResourceCollection';
import { REQUEST_SUCCESS, REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';
import { combineReducers } from 'redux';
import actionMap from 'Common/util/reducerUtils/actionMap';

const sessionDataInitialState = null;

function sessionDataReducer(state = sessionDataInitialState, action) {
    const { type, requestId, payload } = action;

    switch (requestId) {
        case 'getSessionUser':
        case 'login':
            if (type === REQUEST_SUCCESS) {
                return {
                    active: payload.active,
                    created: payload.created,
                    email_address: payload.email_address,
                    first_day_of_week: payload.first_day_of_week,
                    hierarchy_visibility: payload.hierarchy_visibility,
                    id: payload.id,
                    last_login: payload.last_login,
                    name: payload.name,
                    organization_id: payload.organization_id,
                    pending_email_address: payload.pending_email_address,
                    role: payload.role,
                    sms_email_address: payload.sms_email_address,
                    timezone: payload.timezone,
                    vorne_support: payload.vorne_support,
                };
            }
            else if (type === REQUEST_FAILURE) {
                return sessionDataInitialState;
            }
            else {
                return state;
            }
        case 'patchSessionUser':
        case 'patchSessionUserEmail':
        case 'patchSessionUserSmsEmail':
            if (type === REQUEST_SUCCESS) {
                return {
                    active: payload.active,
                    created: payload.created,
                    email_address: payload.email_address,
                    first_day_of_week: payload.first_day_of_week,
                    hierarchy_visibility: payload.hierarchy_visibility,
                    id: payload.id,
                    last_login: payload.last_login,
                    name: payload.name,
                    organization_id: payload.organization_id,
                    pending_email_address: payload.pending_email_address,
                    role: payload.role,
                    sms_email_address: payload.sms_email_address,
                    timezone: payload.timezone,
                };
            }
            else {
                return state;
            }
        case 'logout':
            return sessionDataInitialState;
        default:
            return state;
    }
}

const sessionReducer = combineReducers({
    sessionData: sessionDataReducer,
    requests: actionMap(
        (action) => action?.requestId,
        {
            getSessionUser: requestReducer,
            patchSessionUser: requestReducer,
            patchSessionUserEmail: requestReducer,
            patchSessionUserSmsEmail: requestReducer,
            login: requestReducer,
            logout: requestReducer,
            updatePassword: requestReducer,
            verifyAccount: requestReducer,
            verifyEmailChange: requestReducer,
            verifyEmailResend: requestReducer,
            verifySmsEmailResend: requestReducer,
        }
    ),
    isInitialized: (state = false, action) => {
        if (action.type === INIT_APP_COMPLETE) {
            return true;
        }
        else {
            return state;
        }
    }
});

export default sessionReducer;

export function selectIsInitialized(state) {
    return state.isInitialized;
}

export function selectSessionUser(state) {
    return state.sessionData || null;
}

export function selectIsLoggedIn(state) {
    return state.sessionData !== null;
}

export function selectIsSupport(state) {
    return state.sessionData?.vorne_support;
}

export function selectSessionRequest(state, requestId) {
    const request = state.requests[requestId];

    if (!request) {
        throw new Error(`Unknown session request with ID "${requestId}"`);
    }

    return request;
}
