import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Tooltip from 'Common/components/Tooltip';
import TooltipContent from './internal/TooltipContent';
import { TOOLTIP_LARGE } from 'Common/components/Tooltip/Tooltip';
import { addSeconds, secondsBetween } from 'Common/util/language/date';
import { useTranslator } from 'Common/hooks/useTranslation';
import addDimensionColors from './internal/addDimensionColors';
import { categoryValueFormatTypes } from '../FormattedDatum/CategoryValue/CategoryValue';

const PADDING = 4;
const MAX_HEIGHT = 30;
const MIN_BAR_WIDTH = 0.5;

const Root = styled.div.attrs(({ $width, $height, $showBars }) => {
    return {
        style: {
            width: `${$width}px`,
            height: `${$height}px`,
            justifyContent: $showBars ? 'center' : 'end',
            padding: $showBars ? `${PADDING}px` : 0,
        },
    };
})`
    display: flex;
    align-items: center;
`;

const BarContainer = styled.div.attrs(({ $width, $height }) => {
    return {
        style: {
            width: `${$width}px`,
            height: `${$height}px`,
        },
    };
})`
    position: relative;
    border-radius: 5px;
    overflow: hidden;
`;

const ChronogramEvent = styled.div.attrs(({ $width, $height, $left, $backgroundColor }) => {
    return {
        style: {
            width: `${$width}px`,
            height: `${$height}px`,
            left: `${$left}px`,
            backgroundColor: $backgroundColor,
        },
    };
})`
    position: absolute;
    top: 0;
`;

function getMinStartTime(data) {
    if (!data?.values || data.values.length === 0) {
        return null;
    }

    // Just the start_time of the first value.
    return data?.values[0]?.start_time?.rawValue?.getDate?.() || null;
}

function getTotalDuration(data) {
    if (!data?.values || data.values.length === 0) {
        return 0;
    }

    const startTime = getMinStartTime(data);
    const lastValue = data.values[data.values.length - 1];
    const lastStartTime = lastValue?.start_time?.rawValue?.getDate?.();
    const lastDuration = lastValue?.duration?.rawValue;

    const endTime = addSeconds(lastStartTime, lastDuration);

    return secondsBetween(startTime, endTime);
}

function calcOffsetLeft(startTimeDate, totalStartTimeDate, totalDuration, totalWidth) {
    const secondsFromStart = secondsBetween(totalStartTimeDate, startTimeDate);
    return totalWidth * (secondsFromStart / totalDuration);
}

function calcWidth(duration, totalDuration, totalWidth) {
    return (duration / totalDuration) * totalWidth;
}

export default function ChronogramSparkVisualization({
    width,
    height,
    data,
    noColor,
}) {
    const widthSansPadding = width - (2 * PADDING);
    const heightSansPadding = height - (2 * PADDING);
    const theme = useTheme();
    const translator = useTranslator();

    const coloredData = useMemo(() => {
        if (!data) {
            return data;
        }

        return {
            ...data,
            values: addDimensionColors({
                values: data.values,
            }),
        };
    }, [ data ]);

    const bars = useMemo(() => {
        const totalStartTimeDate = getMinStartTime(coloredData);
        const totalDuration = getTotalDuration(coloredData);
        let noChronogramValue = true;

        const chronogramContent =  (coloredData?.values || [])
            // Calculate rendered sizes for each chronogram event.
            .map((elem) => {
                let backgroundColor;

                if (elem.dimension.rawValue.color && !noColor) {
                    backgroundColor = elem.dimension.rawValue.color;
                }
                else {
                    backgroundColor = null;
                }
                if (elem.dimension.name !== 'null') {
                    noChronogramValue = false;
                }
                const offsetLeft = calcOffsetLeft(
                    elem.start_time.rawValue.getDate(),
                    totalStartTimeDate,
                    totalDuration,
                    widthSansPadding,
                );

                const newWidth = calcWidth(
                    elem.duration.rawValue,
                    totalDuration,
                    widthSansPadding,
                );

                return {
                    left: offsetLeft,
                    width: newWidth,
                    height: height >= (MAX_HEIGHT+(2*PADDING)) ? MAX_HEIGHT : heightSansPadding,
                    backgroundColor: backgroundColor,
                };
            })
            // Merge any adjacent event bars that are below the min width.
            .reduce((acc, bar) => {
                const isTooSmall = bar.width < MIN_BAR_WIDTH;

                if (isTooSmall) {
                    const prev = acc[acc.length - 1];
                    const shouldMerge = (
                        prev &&
                        prev.isCombined &&
                        bar.left - (prev.left + prev.width) <= MIN_BAR_WIDTH
                    );

                    if (shouldMerge) {
                        acc[acc.length - 1] = {
                            ...prev,
                            width: (bar.left + bar.width) - prev.left,
                        };
                    }
                    else {
                        acc.push({
                            ...bar,
                            isCombined: true,
                            backgroundColor: null,
                        });
                    }
                }
                else {
                    acc.push(bar);
                }

                return acc;
            }, [])
            // Filter any remaining bars that are less than the min width.
            .filter((bar) => {
                return bar.width >= MIN_BAR_WIDTH;
            })
            // Convert event bar objects into final components.
            .map((bar, index) => {
                let backgroundColor = bar.backgroundColor;

                if (bar.isCombined || !backgroundColor) {
                    backgroundColor =
                        index % 2 === 0
                        ? theme.colors.palette.grey.silver
                        : theme.colors.palette.grey.ash;
                }

                return (
                    <ChronogramEvent
                        key={index}
                        $left={bar.left}
                        $width={bar.width}
                        $height={bar.height}
                        $backgroundColor={backgroundColor}
                    />
                );
            });

        return noChronogramValue ? null : chronogramContent;
    }, [height, heightSansPadding, widthSansPadding, coloredData, theme, noColor]);

    // Convert tooltip data to just have metric instead of duration and
    // start_time.
    const tooltipData = useMemo(() => {
        if (!coloredData) {
            return null;
        }
        else {
            return {
                ...coloredData,
                values: (coloredData?.values || []).map((item) => {
                    return {
                        dimension: item.dimension,
                        metric: item.duration,
                    };
                }),
            };
        }
    }, [coloredData]);

    return (
        <Root $width={widthSansPadding} $height={heightSansPadding} $showBars={bars ? true : false}>
            <Tooltip
                delay={500}
                placement="auto"
                stickyOnClick
                scrollContentOnOverflow
                content={<TooltipContent data={tooltipData} />}
                style={{ display: 'inline-block'}}
                maxWidth={TOOLTIP_LARGE}
                disabled={!bars}
            >
                {bars ? (
                    <BarContainer
                        $width={widthSansPadding}
                        $height={heightSansPadding}
                        >
                        {bars}
                    </BarContainer>
                ) : (
                    <div>
                        {translator("null_metric_literal")}
                    </div>
                )

                }
                
            </Tooltip>
        </Root>
    );
}

ChronogramSparkVisualization.propTypes = {
    data: PropTypes.shape({
        values: PropTypes.arrayOf(
            PropTypes.shape({
                dimension: PropTypes.shape({
                    displayName: PropTypes.string,
                    name: PropTypes.string,
                    formatType: PropTypes.oneOf(categoryValueFormatTypes).isRequired,
                    rawValue: PropTypes.shape({
                        displayName: PropTypes.string,
                        name: PropTypes.string,
                        color: PropTypes.string
                    })
                }).isRequired,
                start_time: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    formatType: PropTypes.string.isRequired,
                    rawValue: PropTypes.object.isRequired,
                }).isRequired,
                duration: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    formatType: PropTypes.string.isRequired,
                    rawValue: PropTypes.number.isRequired,
                }).isRequired,
            }),
        ).isRequired,
    }),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    noColor: PropTypes.bool,
};
