
import { sendDeleteRequest } from 'Http/request';
import {
    deleteResource,
} from 'Common/util/ResourceCollection';

export function deleteEmailTemplate(emailTemplateId) {
    return deleteResource({
        requestId: 'deleteEmailTemplate',
        resourceId: emailTemplateId,
        resourceType: 'emailTemplates',
        sendRequest: () => sendDeleteRequest(`/api/v1/reports/${emailTemplateId}`),
    });
}

export default deleteEmailTemplate;
