import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormElement from 'Components/form-element/FormElement';
import SelectInput from 'Components/select/SelectInput';
import SelectOption from 'Components/select/SelectOption';
import SelectOptionContent from 'Components/select/SelectOptionContent';

const StyledSelectInput = styled(SelectInput)`
    min-width: 225px;
`;

export default class DeviceSubscriptionsEditor extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        devices: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                asset_name: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        value: PropTypes.arrayOf(PropTypes.number).isRequired,
        onChange: PropTypes.func.isRequired,
        allowEdit: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    _handleChange = (event) => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: event.target.value.map(Number),
            }
        });
    };

    render() {
        const { name, devices, value, allowEdit } = this.props;

        const disabled = !allowEdit || devices.length === 0;

        return (
            <FormElement disabled={disabled}>
                <StyledSelectInput
                    name={name}
                    value={value.map(String)}
                    multiple
                    selectionDisplay={(
                        <SelectOptionContent>
                            <b>{`Select Work Centers (${value.length})`}</b>
                        </SelectOptionContent>
                    )}
                    onChange={this._handleChange}
                    optionsWidth={350}
                    maxOptionsHeight={500}
                >
                    {devices.map((device) => {
                        const deviceId = device.id;

                        return (
                            <SelectOption
                                key={deviceId}
                                value={`${deviceId}`}
                            >
                                <SelectOptionContent>
                                    {device.asset_name}
                                </SelectOptionContent>
                            </SelectOption>
                        );
                    })}
                </StyledSelectInput>
            </FormElement>
        );
    }
}
