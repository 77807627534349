import { useCallback, useMemo } from 'react';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import TimeRange from 'Common/types/TimeRange/TimeRange';
import fieldParamType from 'Common/types/stf/fieldParamType';
import Filters from 'Common/types/Filters/Filters';
import { useWidgetCache } from 'Common/components/Dashboard/DashboardCache';
import { getMaxAgeForRepeatingRequest } from 'Common/hooks/requests/useRepeatingRequest';
import { useTranslator } from 'Common/hooks/useTranslation';
import useRepeatingRequestWithStatus from 'Common/hooks/requests/useRepeatingRequestWithStatus';

const filtersParamType = ParamTypes.shape({
    slice_filter: ParamTypes.shape({
        combine_type: ParamTypes.oneOf(['and', 'or']),
        expressions: ParamTypes.arrayOf(Filters.sliceFilterExpressionParamType),
    }),
    group_filter: ParamTypes.shape({
        combine_type: ParamTypes.oneOf(['and', 'or']),
        expressions: ParamTypes.arrayOf(Filters.groupFilterExpressionParamType),
    }),
});

const paramTypes = ParamTypes.shape({
    hierarchyNode: ParamTypes.string.isRequired,
    field: fieldParamType.isRequired,
    widgetFilters: filtersParamType,
    pageFilters: filtersParamType,
    timeRange: TimeRange.timeRangeParamType.isRequired,
    getKpiWidgetData: ParamTypes.func.isRequired,
    updateInterval: ParamTypes.number,
});

export default function useKpiWidgetData(parameters) {
    paramTest(parameters, paramTypes, 'getKpiWidgetData');

    const {
        hierarchyNode,
        field,
        widgetFilters,
        pageFilters,
        timeRange,
        getKpiWidgetData,
        updateInterval,
    } = parameters;

    const translator = useTranslator();

    const disableRepeat = useMemo(() => {
        // Disable the repeat if there's no update interval or if the time
        // range is not suitable.
        return (
            !updateInterval ||
            !TimeRange.shouldRepeatRequestWithTimeRange(timeRange)
        );
    }, [ timeRange, updateInterval ]);

    // getting data
    const getKpiWidgetDataCache = useWidgetCache({
        cacheId: 'getKpiWidgetData',
        load: ({ abortSignal }, args) => {
            return getKpiWidgetData({
                ...args,
                abortSignal,
            });
        },
    });

    // A cached version of `getKpiWidgetData`
    const getKpiWidgetDataCached = useCallback((args) => {
        const { abortSignal, ...otherArgs } = args;
        const maxAge = getMaxAgeForRepeatingRequest(disableRepeat, updateInterval);

        return getKpiWidgetDataCache.get(
            {
                abortSignal,
                maxAge,
            },
            otherArgs
        );
    }, [ disableRepeat, getKpiWidgetDataCache, updateInterval ]);


    const requestData = useCallback(async ({ abortSignal }) => {
        try {
            return getKpiWidgetDataCached({
                asset: hierarchyNode,
                field: field,
                filters: Filters.mergeFilters(widgetFilters, pageFilters),
                timeRange,
                abortSignal,
            });
        }
        catch(err) {
            console.error(err);
            return { error: translator('error_literal') };
        }
    }, [field, hierarchyNode, pageFilters, timeRange, widgetFilters,
            getKpiWidgetDataCached, translator]);

    return useRepeatingRequestWithStatus({
        sendRequest: requestData,
        delayMs: updateInterval || 0,
        disableRepeat: disableRepeat,
    });
}
