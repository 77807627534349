// import { combineReducers } from 'redux';
import {
    resourceCollectionReducer,
    resourceReducer,
} from 'Common/util/ResourceCollection';
import {
    selectResources,
} from 'Common/util/ResourceCollection/resourceCollectionReducer';

export default resourceCollectionReducer('supportUsers', {
    getResourceId: (resource) => resource.id,
    requests: [ 'getSupportUsers', 'deleteSupportUser', 'patchSupportUser' ],
    resourceReducer: resourceReducer({
        requests: [ 'getSupportUsers', 'deleteSupportUser', 'patchSupportUser' ],
    }),
    sort: (userA, userB) => {
        return userA.email_address.email.localeCompare(userB.email_address.email);
    },
    additionalReducers: (state, action) => {
        const {
            type,
            payload,
        } = action;
        switch (type) {
            case 'sort-support-table': {
                const newOrder = state.list.sort((left, right) => {
                    const lUser = payload.accessor(state.resources[left]);
                    const rUser = payload.accessor(state.resources[right]);

                    if (lUser.localeCompare) {
                        return lUser.localeCompare(rUser) * payload.direction;
                    }
                    return (lUser - rUser) * payload.direction;
                });

                return {
                    ...state,
                    list: newOrder,
                };
            }
            default: {
                return state;
            }
        }
    },
});

export function getSupportUsersList(state) {
    return selectResources(state);
}
