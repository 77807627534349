import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
    useTable,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
} from 'react-table';

import { FixedSizeList } from 'react-window';
import { FontFamily } from 'Common/components/typography';
import Icon from 'Common/components/Icon';
import useSizedColumns from './internal/useSizedColumns';
import * as TableConstants from './internal/tableConstants';
import { getMaxDepth, getCellFontWeight } from './tableUtil';
import { getScrollBarWidth } from 'Common/util/getScrollBarWidth';
import { cssValueToNumber } from 'Common/util/cssHelpers';
import NoDataPlaceholder from './internal/NoDataPlaceholder';
import useExpandedRows from './internal/useExpandedRows';

const printBorderColor = ({ theme }) => theme.colors.palette.grey.silver;

const scrollBarWidth = getScrollBarWidth();

const roundingRadius = '4px';

const ExpanderContainer = styled.div`
    display: inline-block;
    font-size: 6px;
    padding-right: 1em;
    height: 1.5em;
    vertical-align: middle;
`;

const CellContent = styled.div.attrs(({leftPadding, rightPadding, fontWeight, width}) => {
    return {
        style: {
            paddingLeft: leftPadding,
            paddingRight: rightPadding,
            fontWeight: fontWeight,
            width: width,
        },
    };
})`
    min-width: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    height: 100%;

    span.cpe-data-item,
    span.cpe-metric-value {
        display: inline;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const leftShadow = 'inset 8px 0px 10px -10px rgba(0,0,0,0.08)';
const rightShadow = 'inset -8px 0px 10px -10px rgba(0,0,0,0.08)';

const TableCell = styled.div`
    ${({showCellBorders, isPrint}) => showCellBorders && !isPrint && css`
        /* Ensure all cells, including the header, get padding when showing
           cell borders.
        */
        padding: 0 ${TableConstants.COLUMN_PADDING}px;

        :first-child {
            box-shadow: ${rightShadow};
        }

        :not(:first-child) {
            box-shadow: ${leftShadow}, ${rightShadow};
            border-left: ${TableConstants.COLUMN_BORDER_WIDTH}px solid white;
        }

        :last-child {
            box-shadow: ${leftShadow};
        }
    `}
`;

const HeaderCell = styled(TableCell)`
    ${({isPrint, showTopBorder, hasContent}) => showTopBorder && !isPrint && hasContent && css`
        border-top: ${TableConstants.COLUMN_BORDER_WIDTH}px solid white;
    `}
`;

const ResizeHandle = styled.div``;

const CellBorderResizeTarget = styled.div.attrs(({isLastColumn, bodyHasScrollbar}) => {
    return {
        style: {
            right: isLastColumn && !bodyHasScrollbar ?  0 : -10,
            width: isLastColumn ? 10 : 20,
        },
    };
})`
    position: absolute;
    height: 24px;

    /* Just need to make sure it ends up above the neighboring cell */
    z-index: 10;
`;

const SortClickTarget = styled.div`
    flex: 1 1 auto;
    min-width: 0px;
    height: 100%;

    ${({readonly}) => !readonly && css`
        cursor: pointer;
    `}
`;
const CellContentAndSortContainer = styled.div`
    display: flex;
    align-items: baseline;
    min-width: 0px;

    > * {
        user-select: none;
    }
`;
const SortOrderSubScript = styled.sub`
    padding-left: 2px;
    font-size: 8px;
`;

const TableRowBase = styled.div`
    ${({ isPrint }) => isPrint && css`
        border-radius: 0;
        border-top: 1px solid ${printBorderColor};
        border-left: 1px solid ${printBorderColor};
    `}
`;

const HeaderRow = styled(TableRowBase)`
    ${({ bodyHasScrollbar }) => bodyHasScrollbar && css`
        padding-right: ${scrollBarWidth}px;
    `}

    background-color: ${({theme, isSecondaryHeaderRow}) => isSecondaryHeaderRow
        ? theme.colors.palette.grey.ash
        : theme.colors.palette.grey.manatee
    };

    ${({ isPrint, theme }) => isPrint && css`
        background-color: ${theme.colors.palette.white};
    `}

    ${({isSecondaryHeaderRow}) => isSecondaryHeaderRow
        ? css`
            font-size: 12px;
        `
        : css`
            border-top-left-radius: ${roundingRadius};
            border-top-right-radius: ${roundingRadius};
        `
    }

    ${HeaderCell} {
        position: relative;
        margin: 0;
        height: ${({isSecondaryHeaderRow}) => isSecondaryHeaderRow
            ? TableConstants.SECONDARY_HEADER_ROW_HEIGHT
            : TableConstants.STANDARD_HEADER_ROW_HEIGHT
        }px;
        padding-left: ${TableConstants.COLUMN_PADDING}px;

        ${({ isPrint }) => isPrint && css`
            border-right: 1px solid ${printBorderColor};
            padding: 0 2px;
        `}

        ${ResizeHandle} {
            right: 0;
            position: relative;
            height: 13px;
            width: 18px;
            flex: 0 0 auto;

            z-index: 1;
            color: ${({theme}) => theme.colors.palette.grey.silver};
            font-size: 9px;
            text-align: center;

            /* prevents from scrolling while dragging on touch devices */
            touch-action: none;

            ${({ isPrint }) => isPrint && css`
                position: absolute;
                right: -9px; /* Half of width */
                opacity: 0; /* Invisible. Column border is there as a visual indicator. */
            `}

            /* Hide the handle when page is printed */
            @media print {
                display: none;
            }
        }
    }
`;

const BodyRow = styled(TableRowBase)`
    ${({isPrint }) => !isPrint && css`
        border-radius: ${roundingRadius};

        /* The last row should not have a bottom border radius for aesthetics. */
        :last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    `}

    ${({ stripedRow, theme, noColor }) => stripedRow && css`
        background-color: ${noColor ? theme.colors.palette.grey.cloud : theme.colors.palette.blue.ice};
    `}

    ${({isTotal}) => isTotal && css`
        font-weight: bold;
    `}

    ${({isPrint, theme}) => !isPrint && css`
        :hover {
            background-color: ${theme.colors.palette.grey.cloud};
        }
    `}

    ${TableCell} {
        position: relative;
        margin: 0;
        padding: 0 ${TableConstants.COLUMN_PADDING}px;

        ${({ isPrint }) => isPrint && css`
            border-right: 1px solid ${printBorderColor};
            padding: 0 2px;
        `}
    }
`;

const Thead = styled.div``;
const Tbody = styled.div``;
const TableFooter = styled.div``;

/**
 * When there are no header columns give the table a header.
 */
const EmptyTableHeader = styled.div`
    width: 100%;
    height: ${TableConstants.STANDARD_FOOTER_HEIGHT}px;
    background-color: ${({theme}) => theme.colors.palette.grey.manatee};
    border-top-left-radius: ${roundingRadius};
    border-top-right-radius: ${roundingRadius};

    ${({isPrint}) => isPrint && css`
        height: 0;
        border-top: 1px solid ${printBorderColor};
        border-radius: none;
    `}
`;

const Table = styled.div`
    font-family: ${FontFamily.primary};

    ${Thead} {
        color: ${({theme}) => theme.colors.palette.white};
        font-size: 14px;
        border-top-left-radius: ${roundingRadius};
        border-top-right-radius: ${roundingRadius};

        ${({ isPrint }) => isPrint && css`
            border-radius: 0;
            color: ${({theme}) => theme.colors.darkText.highEmphasis};
            background-color: ${({theme}) => theme.colors.palette.white};
            font-size: 12px;
            font-weight: bold;
        `}
    }

    ${Tbody} {
        color: ${({theme}) => theme.colors.darkText.highEmphasis};
        background-color: ${({theme}) => theme.colors.palette.white};
        font-size: 12px;
        overflow-x: hidden;

        ${({ isPrint }) => isPrint && css`
            font-size: 11px;
        `}
    }

    ${TableFooter} {
        width: 100%;
        height: ${TableConstants.STANDARD_FOOTER_HEIGHT}px;
        background-color: ${({theme}) => theme.colors.palette.grey.manatee};
        border-bottom-left-radius: ${roundingRadius};
        border-bottom-right-radius: ${roundingRadius};

        ${({isPrint}) => isPrint && css`
            height: 0;
            border-top: 1px solid ${printBorderColor};
            border-radius: none;
        `}
    }
`;

const Root = styled.div.attrs(({ minWidth, isPrint }) => {
    return {
        style: {
            minWidth,
            overflow: isPrint ? 'hidden' : 'auto',
        },
    };
})`
    display: block;
`;

const getStyle = (align, style = {}) => {
    let justifyContent = 'space-between';

    if (align === 'right') {
        justifyContent = 'flex-end';
    }
    else if (align === 'center') {
        justifyContent = 'center';
    }

    let rv = {
        justifyContent,
        alignItems: 'center',
        display: 'flex',
    };



    return rv;
};

const getStyles = (props, align = 'left') => {
    return [
        props,
        {
            style: getStyle(align, props.style)
        },
    ];
};

const headerProps = (props, { column }) => {
    let title = column.title;

    if (title === undefined && typeof column.Header === 'string') {
        title = column.Header;
    }

    return [
        ...getStyles(props, column.align),
        {
            title,
        },
    ];
};

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

/**
 * TableComponent - A simple, virtualized React table that supports
 *                  hierarchical data.
 *
 * @param {Object} props
 * @param {Array} props.columns The columns to be passed into first through
 *     useSizedColumns and then react-table. Should be memoized.
 * @param {Array} props.data The rows of data to be passed to react-table.
 *     Supports hierarchy through the subRows property. Should be memoized.
 *     Any data rows that should be treated as total rows should have the
 *     property `isTotal` set to true.
 * @param {Number} [props.containerWidth = 0] The width of the table's
 *     container in pixels.
 * @param {Function} [props.setExpandedRows] Called when state changes
 *     so user can externally save the expanded row state.
 * @param {Function} [props.setResizedColumnWidths] Called when state changes
 *     so user can externally save the resized column widths state.
 * @param {Function} [props.onSortByColumnChange] If provided, called when user
 * clicks on the header columns.
 * @param {Boolean} [props.isPrint = false] If true the table will be rendered
 *     in a print friendly format with minimal interactivity. If true isPrint
 *     also forces the height prop for the table to be 'full'.
 * @param {Boolean} [props.noColor = false] If true don't render colors for
 *     what the table controls and pass noColor to column render functions.
 * @param {Number} [props.rowHeight = 1] A number of lines of data that should
 *     fit in a row. This value changes the height of all rows in the table
 *     (excluding the header and footer).
 * @param {Number} [props.maxRowsShown] The number of rows shown within the viewport
 *     of the the table. If this value is not provided, the height of all rows will be
 *     the height of table viewport.
 * @param {Boolean} [props.showCellBorders = false] If true show vertical
 *     borders for the data / header cells and do not show the drag handles.
 * @param {Boolean} [props.showNoDataPlaceholder = false] When set to true if
 *     if there is no data it will still show a row saying no data.
 *
 * @param {Function} [props.onColumnResizeStart] If provided, called when user
 * clicks starts resizing a column.
 * @param {Function} [props.onColumnResizeEnd] If provided, called when user
 * clicks stops resizing a column.
 *
 * @see react-table
 * @see useSizedColumns
 */
function TableComponent({
    columns: originalColumns,
    containerWidth,
    data,
    expandedRows,
    isPrint,
    maxRowsShown,
    noColor,
    rowHeight,
    showCellBorders,
    showNoDataPlaceholder,

    onSortByColumnChange,
    setExpandedRows,
    setResizedColumnWidths,

    onColumnResizeStart,
    onColumnResizeEnd,
}) {

    const defaultColumn = useMemo(
        () => ({
            // When using useFlexLayout:
            // minWidth is only used as a limit for resizing
            // width is used for both the flex-basis and flex-grow
            // maxWidth is only used as a limit for resizing
            //
            minWidth: TableConstants.MIN_COLUMN_WIDTH,
            width: TableConstants.DEFAULT_COLUMN_WIDTH,
            maxWidth: TableConstants.MAX_COLUMN_WIDTH,
        }),
        []
    );

    // number of root nodes in the data
    // for enterprise hierarchies this will be 1, Enterprise
    // for other hierarchies it can be more than 1, like n parts
    const rootNodeCount = data.length;
    const maxDepth = getMaxDepth(data);
    const columns = useSizedColumns({
        columns: originalColumns,
        data,
        isPrint,
        containerWidth: containerWidth - (isPrint ? 0 : scrollBarWidth),
    });

    const {
        getTableProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
    );

    // allow saving the column resizing state
    const columnResizedWidths = state?.columnResizing?.columnWidths;
    const resizingHasFinished = state?.columnResizing?.isResizingColumn === null;
    useEffect(() => {
        if (resizingHasFinished ) {
            setResizedColumnWidths(columnResizedWidths);
        }
    }, [columnResizedWidths, setResizedColumnWidths, resizingHasFinished]);

    const isResizingColumn = typeof state?.columnResizing?.isResizingColumn === 'string';

    useEffect(() => {
        if (isResizingColumn) {
            onColumnResizeStart();
        }
        else {
            onColumnResizeEnd();
        }
    }, [isResizingColumn, onColumnResizeStart, onColumnResizeEnd]);

    const rowsWithExpandedDetails = useExpandedRows({ expanded:expandedRows, rows, setExpandedRows });

    const width = isPrint
        ? containerWidth
        : Math.max(
            totalColumnsWidth + scrollBarWidth,
            TableConstants.TABLE_MIN_WIDTH
        );

    const readonlySort = !onSortByColumnChange || isPrint;

    const tableProps = useCallback((initialTableProps) => {
        let minWidth = cssValueToNumber(initialTableProps.style.minWidth);
        minWidth = Math.max(minWidth, totalColumnsWidth);

        if (!isPrint) {
            minWidth += scrollBarWidth;
        }

        return [
            initialTableProps,
            {
                style: {
                    minWidth,
                },
            },
        ];
    }, [ isPrint, totalColumnsWidth ]);

    const renderRow = useCallback(
        ({ index, style }) => {
            const row = rowsWithExpandedDetails[index];
            prepareRow(row);

            return (
                <BodyRow
                    {...row.getRowProps({ style })}
                    stripedRow={index % 2 === 1}
                    isPrint={isPrint}
                    noColor={noColor}
                    isTotal={row.original.isTotal}
                >
                    {row.cells.map((cell, cellIndex) => {
                        const inverseDepth = maxDepth - row.depth;
                        const canExpand = row.subRows?.length;
                        let expander = null;
                        let contentProps = {};
                        let expandedIconOffset = !canExpand && !isPrint ? 12 : 0;

                        if (cell.column.expandable === true) {
                            if (canExpand && !isPrint) {
                                contentProps = {
                                    onClick: () => { row.handleExpandToggle(row.id); },
                                    style: { cursor: 'pointer'},
                                };
                                expander = (
                                    <ExpanderContainer>
                                        <Icon
                                            type="vorne"
                                            iconName={row.isExpanded
                                                ? 'collapse-arrow-open'
                                                : 'collapse-arrow-closed'
                                            }
                                        />
                                    </ExpanderContainer>
                                );
                            }

                            const indentPerDepth = isPrint
                                ? TableConstants.EXPANDABLE_COLUMN_INDENT_PER_DEPTH_PRINT
                                : TableConstants.EXPANDABLE_COLUMN_INDENT_PER_DEPTH;

                            contentProps.leftPadding = `${expandedIconOffset + (row.depth * indentPerDepth)}px`;
                        }
                        else if (!isPrint) {

                            if (inverseDepth < 0) {
                                throw new Error(
                                    `Row depth of ${row.depth} is greater than specified max depth of ${maxDepth}`
                                );
                            }

                            if (cell.column.align === 'right') {
                                contentProps.rightPadding = `${inverseDepth * TableConstants.COLUMN_RIGHT_INDENT_PER_DEPTH}px`;
                            }
                            else {
                                contentProps.leftPadding = `${row.depth * TableConstants.COLUMN_LEFT_INDENT_PER_DEPTH}px`;
                            }
                        }

                        if (cell.column.fillCellWidth) {
                            contentProps.width = '100%';
                        }

                        // The following exists for backwards compatibility:
                        // Allow left aligned data items to stretch out and use the full cell.
                        if (cell.column.align === 'left') {
                            contentProps.width = '100%';
                        }

                        contentProps.fontWeight = getCellFontWeight(row.depth, maxDepth, rootNodeCount);
                        return (
                            <TableCell
                                {...cell.getCellProps(cellProps)}
                                showCellBorders={showCellBorders}
                                isPrint={isPrint}
                                key={cellIndex}
                            >
                                <CellContent {...contentProps}>
                                    {expander}
                                    {cell.render('Cell', { noColor, rowHeight })}
                                </CellContent>
                            </TableCell>
                        );
                    })}
                </BodyRow>
            );
        },
        [prepareRow, rowsWithExpandedDetails, isPrint, maxDepth, rootNodeCount, noColor, rowHeight, showCellBorders]
    );

    const renderHeaderRows = useCallback(
        (headerGroupsToRender, bodyHasScrollbar) => {
            const maxGroupIndex = headerGroupsToRender.length - 1;

            if (headerGroupsToRender.length === 0) {
                return (
                    <EmptyTableHeader isPrint={isPrint}/>
                );
            }
            else {
                return headerGroupsToRender.map((headerGroup, groupIndex) => {
                    const showSortOrder = headerGroup.headers.filter( (hh) => {return hh.sortIndex !== null;}).length > 1;
                    const isHigherOrderHeader = (groupIndex !== maxGroupIndex);
                    const isSecondaryHeaderRow = groupIndex !== 0;
                    const isLightweightHeaderRow = maxGroupIndex > 0 && groupIndex === maxGroupIndex;
                    const showTopBorder = groupIndex > 1 ;
    
                    return (
                        <HeaderRow
                            {...headerGroup.getHeaderGroupProps()}
                            key={groupIndex}
                            isPrint={isPrint}
                            bodyHasScrollbar={bodyHasScrollbar}
                            isSecondaryHeaderRow={isSecondaryHeaderRow}
                            isLightweightHeaderRow={isLightweightHeaderRow}
                        >
                            {headerGroup.headers.map((column, columnIndex) => (
                                <HeaderCell
                                    {...column.getHeaderProps(headerProps)}
                                    showCellBorders={showCellBorders}
                                    showTopBorder={!column.isPrimary && showTopBorder}
                                    hasContent={!!column.textOnlyHeader}
                                    isPrint={isPrint}
                                    key={columnIndex}
                                >
                                    <SortClickTarget
                                        style={getStyle(column.headerAlign || (isHigherOrderHeader ? 'center' : column.align))}
                                        readonly={readonlySort || !column.sortable}
                                        onClick={(e) => {
                                            if (column.sortable && !readonlySort) {
                                                onSortByColumnChange(columnIndex, !e.shiftKey);
                                            }
                                        }}
                                    >
                                        <CellContentAndSortContainer>
                                            <CellContent>
                                                {column.render('Header')}
                                            </CellContent>
                                            {
                                                column.sortOrder && (
                                                    <Icon type="vorne" iconName={column.sortOrder === 'asc' ? "sort-up-arrow" : "sort-down-arrow"} style={{paddingLeft:5}}/>
                                                )
                                            }
                                            {
                                                showSortOrder && Number.isInteger(column.sortIndex) && (
                                                    <SortOrderSubScript>{column.sortIndex + 1}</SortOrderSubScript>
                                                )
                                            }
                                        </CellContentAndSortContainer>
                                    </SortClickTarget>
                                    {column.canResize && (showCellBorders
                                        ? (
                                            <CellBorderResizeTarget
                                                {...column.getResizerProps()}
                                                isResizing={column.isResizing}
                                                isLastColumn={columnIndex === headerGroup.headers.length - 1}
                                                onDoubleClick={(event) => {
                                                    setResizedColumnWidths({ [column.id]: null });
                                                }}
                                                bodyHasScrollbar={bodyHasScrollbar}
                                            />
                                        )
                                        : (
                                            <ResizeHandle
                                                {...column.getResizerProps()}
                                                isResizing={column.isResizing}
                                                onDoubleClick={(event) => {
                                                    setResizedColumnWidths({ [column.id]: null });
                                                }}
                                            >
                                                {<Icon type="vorne" iconName="column-resize-handle" />}
                                            </ResizeHandle>
                                        )
                                    )}
                                </HeaderCell>
                            ))}
                        </HeaderRow>
                    );
                });
            }
        },
        [isPrint, onSortByColumnChange, readonlySort, showCellBorders, setResizedColumnWidths]
    );

    // The minimum row height accounts for some padding that should not be included
    // in for additional row height increments, so the min row height and additional
    // row height are kept as separate values.
    //
    const rowPixelHeight = TableConstants.MINIMUM_ROW_HEIGHT + ((rowHeight - 1) * TableConstants.ADDITIONAL_ROW_HEIGHT_UNIT);

    let bodyHeight = 0;
    let bodyHasScrollbar;

    const totalRowHeight = rowsWithExpandedDetails.length * rowPixelHeight;

    if (isPrint) {
        bodyHeight = totalRowHeight;
        bodyHasScrollbar = false;
    }
    else {
        bodyHeight = Math.min(totalRowHeight, rowPixelHeight * maxRowsShown);

        bodyHasScrollbar = totalRowHeight > bodyHeight;

        // If the table doesn't have enough content to fill the full body
        // height, make the body height smaller.
        //
        if (!bodyHasScrollbar) {
            bodyHeight = totalRowHeight;
        }
    }

    return (
        <Root
            minWidth={Math.min(width, containerWidth)}
            isPrint={isPrint}
        >
            <Table {...getTableProps(tableProps)} isPrint={isPrint}>
                <Thead>
                    {renderHeaderRows(headerGroups, bodyHasScrollbar)}
                </Thead>
                <Tbody>
                    <FixedSizeList
                        height={bodyHeight}
                        itemCount={rowsWithExpandedDetails.length}
                        itemSize={rowPixelHeight}
                        width={width}
                        overscanCount={5}
                        style={{
                            overflowX: 'hidden',
                            // Setting `overflowY` to `scroll` is a safety
                            // measure to help ensure that the headers are
                            // aligned with the columns in the body.
                            overflowY: bodyHasScrollbar ? 'scroll' : 'auto',
                        }}
                    >
                        {renderRow}
                    </FixedSizeList>
                    {rowsWithExpandedDetails.length === 0 && showNoDataPlaceholder && (<NoDataPlaceholder/>)}
                </Tbody>
                <TableFooter />
            </Table>
        </Root>
    );
}

const ColumnPropType = PropTypes.arrayOf(
    PropTypes.shape({
        Header: PropTypes.node.isRequired,
        textOnlyHeader: PropTypes.string,
        accessor: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
        title: PropTypes.string,
        // react-table useSortBy is not current being used beware
        // of other sort props found on column
        sortOrder: PropTypes.oneOf(['asc', 'desc']),
        sortIndex: PropTypes.number,
    }),
);
TableComponent.propTypes = {
    columns: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                Header: PropTypes.node.isRequired,
                textOnlyHeader: PropTypes.string,
                columns: ColumnPropType.isRequired,
            }),
        ),
        ColumnPropType,
    ]),
    containerWidth: PropTypes.number,
    data: PropTypes.array,
    expandedRows: PropTypes.object,
    isPrint: PropTypes.bool,
    maxRowsShown: PropTypes.number,
    noColor: PropTypes.bool,
    rowHeight: PropTypes.number,
    showCellBorders: PropTypes.bool,
    showNoDataPlaceholder: PropTypes.bool,

    onSortByColumnChange: PropTypes.func,
    setExpandedRows: PropTypes.func,
    setResizedColumnWidths: PropTypes.func,

    onColumnResizeStart: PropTypes.func,
    onColumnResizeEnd: PropTypes.func,
};

TableComponent.defaultProps = {
    columns: [],
    containerWidth: 0,
    data: [],
    expandedRows: {},
    isPrint: false,
    maxRowsShown: 14,
    noColor: false,
    rowHeight: 1,
    showCellBorders: false,
    showNoDataPlaceholder: false,

    setExpandedRows: () => {},
    setResizedColumnWidths: () => {},

    onColumnResizeStart: () => {},
    onColumnResizeEnd: () => {},
};

export default TableComponent;
