import ParamTypes, { paramTest } from '../ParamTypes';
import paramTypeWrapper from './paramTypeWrapper';

/**
 * ParamType checker which checks if the value is exactly the same as the option
 * given.
 *
 * @param {String|Number|Boolean} option The value to check against. Can also be
 *     null or undefined.
 *
 * @returns {Function} ParamType checker which returns an array of failures.
 */
export default function exactly(option) {
    paramTest(
        option, 
        ParamTypes.oneOfType([
            ParamTypes.string, 
            ParamTypes.number, 
            ParamTypes.boolean,
        ]),
        'ParamTypes.exactly option arg',
        { throwError: true, }
    );
    
    return paramTypeWrapper((arg, key) => {
        let failures = [];

        const matchesOption = arg === option;

        if (!matchesOption) {
            const formattedOption = typeof option === 'string'
                ? `"${option}"`
                : `${option}`;

            failures.push(
                `${key} must be exactly: ${formattedOption}`
            );
        }

        return failures;
    });
}
