import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { T2, Body1 } from 'Components/typography/typography';
import Popup from './Popup';
import PopupLayer from './PopupLayer';

const TopBar = styled.div``;
const TitleText = styled(T2)``;
const WindowBody = styled(Body1)``;
const BottomBar = styled.div``;

const WindowWrapper = styled.div`
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    box-shadow: 2px 2px 20px #505050;
    background-color: white;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    overflow: auto;

    > ${TopBar} {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        padding: 10px 10px;
        border-bottom: 1px solid #d5d5d5;
        background-color: #f0f0f0;

        > ${TitleText} {
            display: block;
            flex: 1 0 auto;
        }
    }

    > ${WindowBody} {
        flex: 1 1 auto;
        display: block;
        padding: 20px 10px;
        font-size: 12px;
        overflow: auto;
    }

    > ${BottomBar} {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 10px;
        border-top: 1px solid #d5d5d5;
        background-color: #f0f0f0;

        > * {
            margin-left: 8px;
        }
    }
`;

export default class PopupWindow extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        buttons: PropTypes.arrayOf(PropTypes.node),
        onMaskClick: PropTypes.func,
        children: PropTypes.node,
        width: PropTypes.number,
        height: PropTypes.number,
    };

    static defaultProps = {
        onMaskClick: () => {},
        width: 500,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            title,
            buttons,
            onMaskClick,
            children,
            width,
            height,
        } = this.props;

        return (
            <PopupLayer>
                <Popup
                    onMaskClick={onMaskClick}
                    darkenMask
                    width={width}
                    height={height}
                >
                    <WindowWrapper>
                        <TopBar>
                            <TitleText>{title}</TitleText>
                        </TopBar>
                        <WindowBody>
                            {children}
                        </WindowBody>
                        <BottomBar>
                            {buttons}
                        </BottomBar>
                    </WindowWrapper>
                </Popup>
            </PopupLayer>
        );
    }
}
