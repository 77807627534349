import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAlerts, patchAlerts, generateAlertsPatch } from 'Redux/actions';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentWide';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import EditAlertsForm from './EditAlertsForm';
import RequestLoadingWrapper from 'Components/loading-indicator/RequestLoadingWrapper';
import PageDescription from 'Components/page/PageDescription';

export class EditAlertsPagePresentation extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onDidMount();
    }

    _handleSubmit = (event) => {
        // Generate the patch data
        const initialData = this.props.data;
        const modifiedData = event.target.value;
        const patchData = generateAlertsPatch(initialData, modifiedData);

        this.props.onSubmit(patchData);
    };

    render() {
        const { requests, data } = this.props;

        return (
            <Page>
                <ConfigPageTitle>Alert Definitions</ConfigPageTitle>
                <PageDescription>
                    Use this page to configure alert thresholds that can be used to send
                    automated notifications based on real-time information from the plant floor.
                    Administrators configure alerts on this page and individual users
                    subscribe to the alerts (and processes) that fall within the scope of
                    their responsibility (using
                    the <Link to="/subscriptions/alerts">Alert&nbsp;Subscriptions</Link> page).
                    Escalation is easy&nbsp;–&nbsp;simply add an alert for each escalation level.
                </PageDescription>
                <PageContent>
                    <RequestLoadingWrapper
                        request={requests.getAlerts}
                        render={() => (
                            <EditAlertsForm
                                data={data}
                                requests={requests}
                                onSubmit={this._handleSubmit}
                            />
                        )}
                    />
                </PageContent>
            </Page>
        );
    }
}

EditAlertsPagePresentation.defaultProps = {
    data: null,
    requests: null,
};

EditAlertsPagePresentation.propTypes = {
    onDidMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    // There may be more fields in here than what's below, but this is just
    // what's required by this component
    data: PropTypes.object.isRequired,
    requests: PropTypes.shape({
        getAlerts: PropTypes.object.isRequired,
        patchAlerts: PropTypes.object.isRequired,
    }),
};

const mapStateToProps = (state, ownProps) => {
    const { alerts } = state;

    return {
        data: (alerts?.data) || null,
        requests: (alerts?.requests) || null,
    };
};

const mapDispatchToProps = {
    onDidMount: getAlerts,
    onSubmit: patchAlerts,
};

// connect the component to Redux
const EditAlertsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAlertsPagePresentation);

export default EditAlertsPage;
