import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DashboardCacheContext from './internal/InternalDashboardCacheContext';
import DashboardCache from './internal/DashboardCache';

/**
 * A React context provider to enable use of widget data caches. Should be
 * placed at the top level of a dashboard.
 *
 * The cache can be accessed using `useDashboardCache`.
 */
export default function DashboardCacheProvider({
    children,
}) {
    const value = useMemo(() => {
        return new DashboardCache();
    }, []);

    return (
        <DashboardCacheContext.Provider value={value}>
            {children}
        </DashboardCacheContext.Provider>
    );
}

DashboardCacheProvider.propTypes = {
    children: PropTypes.node,
};
