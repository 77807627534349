import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Caption } from 'Components/typography/typography';
import withFormElementContext from 'Components/form-element/withFormElementContext';

const Root = styled(Caption).attrs({ as: 'label' })`
    display: block;
    box-sizing: border-box;
    width: 100%;
    line-height: 2em;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    transition: color 300ms;

    &.error {
        color: ${props => props.theme.colors.palette.error.red};
    }

    &.focused:not(.error) {
        color: ${props => props.theme.colors.darkText.highEmphasis};
    }
`;

class FormElementLabel extends React.Component {
    render() {
        const {
            className: classNameProp,
            formElementContext,
            ...otherProps
        } = this.props;

        const { focused, error } = formElementContext;

        const className = classNames(classNameProp, { focused, error });

        return (
            <Root className={className} {...otherProps} />
        );
    }
}

FormElementLabel.defaultProps = {
    className: ''
};

FormElementLabel.propTypes = {
    className: PropTypes.string,
    formElementContext: PropTypes.object.isRequired,
};

export default withFormElementContext(FormElementLabel);
