import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser, patchUser, patchUserReset, getHierarchy } from 'Redux/actions';
import { selectUserData, selectUserRequest } from 'Redux/reducers/users';

import MultiRequestLoadingWrapper from 'Components/loading-indicator/MultiRequestLoadingWrapper';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentStandard';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import PageDescription from 'Components/page/PageDescription';
import { EditUserForm } from './internal/EditUserForm';
import { selectFlattenedHierarchy, selectHierarchyRequest } from 'Redux/reducers/hierarchy';

export class EditUserPagePresentation extends React.Component {
    static propTypes = {
        // Actions
        actions: PropTypes.shape({
            getUser: PropTypes.func.isRequired,
            patchUser: PropTypes.func.isRequired,
            patchUserReset: PropTypes.func.isRequired,
            getHierarchy: PropTypes.func.isRequired,
        }).isRequired,
        // There may be more fields in here than what's below, but this is just
        // what's required by this component
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        hierarchyStore: PropTypes.object.isRequired,
        requests: PropTypes.shape({
            getUser: PropTypes.shape({
                status: PropTypes.string.isRequired,
                error: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }).isRequired,
            patchUser: PropTypes.shape({
                status: PropTypes.string.isRequired,
                error: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }).isRequired,
            getHierarchy: PropTypes.shape({
                status: PropTypes.string.isRequired,
                error: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }).isRequired,
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                user: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string
                ]).isRequired
            }).isRequired
        }).isRequired,
        sessionUser: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const userId = Number(this.props.match.params.user);
        this.props.actions.getUser(userId);
        this.props.actions.getHierarchy();
    }

    render() {
        const {
            sessionUser,
            user,
            requests,
            actions,
            hierarchyStore,
        } = this.props;

        return (
            <Page>
                <ConfigPageTitle>Edit User</ConfigPageTitle>
                <PageDescription>
                    View or change details for a user.
                </PageDescription>
                <PageContent>
                    <MultiRequestLoadingWrapper
                        requests={[
                            requests.getUser,
                            requests.getHierarchy,
                        ]}
                        render={() => {
                            return (
                                <EditUserForm
                                    sessionUserId={sessionUser?.id}
                                    initialValue={user}
                                    enterpriseHierarchy={selectFlattenedHierarchy(hierarchyStore)}
                                    requests={{
                                        patchUser: requests.patchUser,
                                    }}
                                    actions={{
                                        patchUser: actions.patchUser,
                                        patchUserReset: actions.patchUserReset,
                                    }}
                                />
                            );
                        }}
                    />
                </PageContent>
            </Page>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { users, session, hierarchy } = state;
    const userId = Number(ownProps.match.params.user);

    return {
        sessionUser: session?.sessionData,
        user: selectUserData(users, userId),
        hierarchyStore: hierarchy,
        requests: {
            getUser: selectUserRequest(users, userId, 'getUser'),
            patchUser: selectUserRequest(users, userId, 'patchUser'),
            getHierarchy: selectHierarchyRequest(hierarchy, 'getHierarchy'),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getUser: getUser,
            patchUser: patchUser,
            patchUserReset: patchUserReset,
            getHierarchy: getHierarchy,
        }, dispatch),
    };
};

// connect the component to Redux
const EditUserPage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUserPagePresentation));

export default EditUserPage;
