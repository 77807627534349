import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Subheading2 } from 'Common/components/typography';

import AutoResizeTitle from "Common/components/AutoResizeTitle";
import Icon from 'Common/components/Icon';
import themeColors from 'Common/theme/default';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const SectionContainer = styled.div`
    flex: 0 0 auto;
    padding-left: 7px;
`;

const SectionAction = styled(Subheading2)`
    align-items: center;
    display: flex;
    height: 100%;
    .fa {
        cursor: pointer;
        transform: rotate(-45deg);
    }

    ${(props) => props.collapsed && `
        .fa {
            transform: rotate(-90deg);
        }
    `}
`;

const TextContainer = styled(Subheading2).attrs(props => {
        let style = {};

        if (props.isPrint) {
            style.fontSize = 12;
        }

        return { style };
    })`
    flex: 1 1 auto;
    display: inline-flex;
    min-width: 1px;
    padding: 7px 0;
`;


function SectionFrameTitle (props) {
    return (
        <Container>
            <SectionContainer>
                <SectionAction
                    collapsed={props.isCollapsed}
                    onClick={props.sectionOnClick}
                >
                    {!props.isPrint && <Icon
                        type='fontAwesome'
                        iconName='caret-down'
                    />}
                </SectionAction>
            </SectionContainer>
            <TextContainer
                isPrint={props.isPrint}
            >
                <AutoResizeTitle
                    canEdit={false}
                    minWidth={40}
                    textColor={themeColors.colors.palette.blue.awesome}
                    type='text'
                    value={props.titleValue}
                />
            </TextContainer>
        </Container>
    );
}

SectionFrameTitle.propTypes = {
    isCollapsed: PropTypes.bool.isRequired,
    isPrint: PropTypes.bool.isRequired,
    titleValue: PropTypes.string.isRequired,

    sectionOnClick: PropTypes.func.isRequired,
};

export default React.memo(SectionFrameTitle);
