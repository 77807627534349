import {
    GET_ORGANIZATION_REQUEST,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    PATCH_ORGANIZATION_REQUEST,
    PATCH_ORGANIZATION_SUCCESS,
    PATCH_ORGANIZATION_FAILURE,
    PATCH_ORGANIZATION_RESET,
} from 'Redux/actions';
import {
    REQUEST_RESET,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from 'Common/util/ResourceCollection/actions';

import { requestReducer } from 'Common/util/ResourceCollection/requestReducer';

const initialState = {
    data: null,
    requests: {
        getOrganization: requestReducer(undefined, {}),
        patchOrganization: requestReducer(undefined, {}),
    },
};

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_ORGANIZATION_REQUEST:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    getOrganization: requestReducer(state.requests.getOrganization, {
                        ...action,
                        type: REQUEST_PENDING,
                    }),
                },
            };
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                data: {
                    id: payload.id,
                    created: payload.created,
                    name: payload.name,
                },
                requests: {
                    ...state.requests,
                    getOrganization: requestReducer(state.requests.getOrganization, {
                        ...action,
                        type: REQUEST_SUCCESS,
                    }),
                },
            };
        case GET_ORGANIZATION_FAILURE:
            return {
                ...state,
                data: null,
                requests: {
                    ...state.requests,
                    getOrganization: requestReducer(state.requests.getOrganization, {
                        ...action,
                        type: REQUEST_FAILURE,
                    }),
                },
            };
        case PATCH_ORGANIZATION_RESET:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    patchOrganization: requestReducer(state.requests.patchOrganization, {
                        ...action,
                        type: REQUEST_RESET,
                    }),
                },
            };
        case PATCH_ORGANIZATION_REQUEST:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    patchOrganization: requestReducer(state.requests.patchOrganization, {
                        ...action,
                        type: REQUEST_PENDING,
                    }),
                },
            };
        case PATCH_ORGANIZATION_SUCCESS:
            return {
                ...state,
                data: {
                    id: payload.id,
                    created: payload.created,
                    name: payload.name,
                },
                requests: {
                    ...state.requests,
                    patchOrganization: requestReducer(state.requests.patchOrganization, {
                        ...action,
                        type: REQUEST_SUCCESS,
                    }),
                },
            };
        case PATCH_ORGANIZATION_FAILURE:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    patchOrganization: requestReducer(state.requests.patchOrganization, {
                        ...action,
                        type: REQUEST_FAILURE,
                    }),
                },
            };
        default:
            return state;
    }
}
