import React from 'react';
import PropTypes from 'prop-types';
import TextInputField from 'Components/text-input/TextInputField';

function _forceInteger(value) {
    // An empty/null value should not be treated as a zero
    const valueNumber = value ? Number(value) : NaN;

    if (Number.isNaN(valueNumber)) {
        return value;
    }

    return Math.floor(valueNumber).toFixed(0);
}

class IntegerInputField extends React.Component {
    constructor(props) {
        super(props);
    }

    _handleBlur = (event) => {
        const value = event.target.value;
        const newValue = _forceInteger(value);

        const { value: propValue, onChange, name } = this.props;

        if (propValue !== newValue) {
            onChange({
                target: {
                    value: newValue,
                    name,
                },
            });
        }
    };

    render() {
        const { type, onBlur, ...otherProps } = this.props;

        if (type !== 'number') {
            throw new Error(`type for IntegerInputField must be "number", not ${type}`);
        }

        return (
            <TextInputField
                onBlur={this._handleBlur}
                type={type}
                {...otherProps}
            />
        );
    }
}

IntegerInputField.defaultProps = {
    type: 'number',
    onBlur: () => { },
    onChange: () => { },
};

IntegerInputField.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export default IntegerInputField;
