import { requestActionCreator } from './requestActionCreator';

export const SET_RESOURCE = 'SET_RESOURCE';
// General purpose request action types
export const REQUEST_RESET = 'REQUEST_RESET';
export const REQUEST_PENDING = 'REQUEST_PENDING';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

/**
 * Async action creator for GET-ing multiple resources
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {function} options.sendRequest
 * @returns {function} async action creator
 */
export function getResources(options) {
    const {
        resourceType,
        requestId,
        sendRequest,
    } = options;

    return requestActionCreator(
        {
            request: () => ({
                type: REQUEST_PENDING,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'GET',
            }),
            success: (payload) => ({
                type: REQUEST_SUCCESS,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'GET',
                payload,
            }),
            failure: (error) => ({
                type: REQUEST_FAILURE,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'GET',
                payload: { error },
            }),
        },
        sendRequest
    );
}

/**
 * Async action creator for PATCH-ing multiple resources
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {string} options.requestData
 * @param {function} options.sendRequest
 * @returns {function} async action creator
 */
export function patchResources(options) {
    const {
        resourceType,
        requestId,
        requestData,
        sendRequest,
    } = options;

    return requestActionCreator(
        {
            request: () => ({
                type: REQUEST_PENDING,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PATCH',
                requestData: requestData,
            }),
            success: (payload) => ({
                type: REQUEST_SUCCESS,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PATCH',
                requestData: requestData,
                payload,
            }),
            failure: (error) => ({
                type: REQUEST_FAILURE,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PATCH',
                requestData: requestData,
                payload: { error },
            }),
        },
        sendRequest
    );
}

/**
 * Action creator for resetting a resources request back to its initial state
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @returns {function} action creator
 */
export function resetResourcesRequest(options) {
    const {
        resourceType,
        requestId,
    } = options;

    return (dispatch) => {
        dispatch({
            type: REQUEST_RESET,
            resourceType: resourceType,
            requestId: requestId,
        });
    };
}

/**
 * Async action creator for GET-ing a resource
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {string} options.resourceId
 * @param {function} options.sendRequest
 * @returns {function} async action creator
 */
export function getResource(options) {
    const {
        resourceType,
        requestId,
        resourceId,
        sendRequest,
    } = options;

    return requestActionCreator(
        {
            request: () => ({
                type: REQUEST_PENDING,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'GET',
                resourceId: resourceId,
            }),
            success: (payload) => ({
                type: REQUEST_SUCCESS,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'GET',
                resourceId: resourceId,
                payload,
            }),
            failure: (error) => ({
                type: REQUEST_FAILURE,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'GET',
                resourceId: resourceId,
                payload: { error },
            }),
        },
        sendRequest
    );
}

/**
 * Async action creator for POST-ing a resource
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {string} options.resourceId
 * @param {function} options.sendRequest
 * @returns {function} async action creator
 */
 export function postResource(options) {
    const {
        resourceType,
        requestId,
        resourceId,
        sendRequest,
    } = options;

    return requestActionCreator(
        {
            request: () => ({
                type: REQUEST_PENDING,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'POST',
                resourceId: resourceId,
            }),
            success: (payload) => ({
                type: REQUEST_SUCCESS,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'POST',
                resourceId: resourceId,
                payload,
            }),
            failure: (error) => ({
                type: REQUEST_FAILURE,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'POST',
                resourceId: resourceId,
                payload: { error },
            }),
        },
        sendRequest
    );
}

/**
 * Async action creator for PATCH-ing a resource
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {string} options.resourceId
 * @param {function} options.sendRequest
 * @returns {function} async action creator
 */
export function patchResource(options) {
    const {
        resourceType,
        requestId,
        resourceId,
        sendRequest,
    } = options;

    return requestActionCreator(
        {
            request: () => ({
                type: REQUEST_PENDING,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PATCH',
                resourceId: resourceId,
            }),
            success: (payload) => ({
                type: REQUEST_SUCCESS,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PATCH',
                resourceId: resourceId,
                payload,
            }),
            failure: (error) => ({
                type: REQUEST_FAILURE,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PATCH',
                resourceId: resourceId,
                payload: { error },
            }),
        },
        sendRequest
    );
}

/**
 * Async action creator for PUT-ing a resource
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {string} options.resourceId
 * @param {function} options.sendRequest
 * @returns {function} async action creator
 */
export function putResource(options) {
    const {
        resourceType,
        requestId,
        resourceId,
        sendRequest,
    } = options;

    return requestActionCreator(
        {
            request: () => ({
                type: REQUEST_PENDING,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PUT',
                resourceId: resourceId,
            }),
            success: (payload) => ({
                type: REQUEST_SUCCESS,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PUT',
                resourceId: resourceId,
                payload,
            }),
            failure: (error) => ({
                type: REQUEST_FAILURE,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'PUT',
                resourceId: resourceId,
                payload: { error },
            }),
        },
        sendRequest
    );
}

/**
 * Async action creator for DELETE-ing a resource
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {string} options.resourceId
 * @param {function} options.sendRequest
 * @returns {function} async action creator
 */
export function deleteResource(options) {
    const {
        resourceType,
        requestId,
        resourceId,
        sendRequest,
    } = options;

    return requestActionCreator(
        {
            request: () => ({
                type: REQUEST_PENDING,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'DELETE',
                resourceId: resourceId,
            }),
            success: (payload) => ({
                type: REQUEST_SUCCESS,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'DELETE',
                resourceId: resourceId,
                payload,
            }),
            failure: (error) => ({
                type: REQUEST_FAILURE,
                resourceType: resourceType,
                requestId: requestId,
                requestMethod: 'DELETE',
                resourceId: resourceId,
                payload: { error },
            }),
        },
        sendRequest
    );
}

/**
 * Action creator for resetting a resource request back to its initial state
 *
 * @param {Object} options
 * @param {string} options.resourceType
 * @param {string} options.requestId
 * @param {string} options.resourceId
 * @returns {function} action creator
 */
export function resetResourceRequest(options) {
    const {
        resourceType,
        requestId,
        resourceId,
    } = options;

    return (dispatch) => {
        dispatch({
            type: REQUEST_RESET,
            resourceType: resourceType,
            requestId: requestId,
            resourceId: resourceId,
        });
    };
}
