import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getRoleValues, getRoleDisplayText } from 'Components/app/roles';
import FormElement from 'Components/form-element/FormElement';
import FormElementLabel from 'Components/form-element/FormElementLabel';
import FormElementHelperText from 'Components/form-element/FormElementHelperText';
import SelectInput from 'Components/select/SelectInput';
import SelectOption from 'Components/select/SelectOption';
import SelectOptionContent from 'Components/select/SelectOptionContent';

const StyledSelectInput = styled(SelectInput)`
    display: block;
    min-width: 200px;
`;

const OptionContent = styled(SelectOptionContent)`
    min-width: 200px;
    color: inherit;

    &.emptyOption {
        color: rgba(0,0,0,0.5);
    }
`;

export default class RoleSelect extends React.Component {
    render() {
        const {
            id,
            label,
            name,
            value,
            error,
            helperText,
            onFocus,
            onBlur,
            onChange,
            ...otherProps
        } = this.props;

        const roleValues = getRoleValues();

        return (
            <FormElement error={error} {...otherProps}>
                <FormElementLabel htmlFor={id}>{label}</FormElementLabel>
                <StyledSelectInput
                    id={id}
                    name={name}
                    value={value}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                >
                    <SelectOption value="">
                        <OptionContent className="emptyOption"><i>None</i></OptionContent>
                    </SelectOption>
                    {roleValues.map((roleValue) => (
                        <SelectOption key={roleValue} value={roleValue}>
                            <OptionContent>{getRoleDisplayText(roleValue)}</OptionContent>
                        </SelectOption>
                    ))}
                </StyledSelectInput>
                <FormElementHelperText>
                    {helperText}
                </FormElementHelperText>
            </FormElement>
        );
    }
}

RoleSelect.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOf([ '', ...getRoleValues() ]),
    error: PropTypes.bool,
    helperText: PropTypes.node,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};
