import { resourceReducer } from 'Common/util/ResourceCollection';
import { selectResourceData, selectResourceRequest } from 'Common/util/ResourceCollection/resourceReducer';
import { NodeType } from 'Components/hierarchy-editor/HierarchyNodeType';

/**
 * @typedef FlatHierarchyNode
 * @type {Object}
 * @property {string} id Node ID.
 * @property {string[]} children IDs of child nodes.
 * @property {string[]} parents IDs of parent nodes, starting with the root node
 *  at index 0 and ending with the node's immediate parent.
 * @property {string} node_type
 * @property {string|null} node_name
 * @property {(Object|null)} device
 */

/**
 * @typedef FlatHierarchy
 * @type {Object}
 * @property {string} rootId ID of the root node.
 * @property {Object.<string, FlatHierarchyNode>} nodes All nodes in the
 *  hierarchy, indexed by node ID.
 */

function flattenHierarchy(rootNode, parents = []) {
    const rootId = String(rootNode.id);
    const rootChildren = rootNode.children || [];

    const newNode = {
        ...rootNode,
        id: rootId,
        children: rootChildren.map(childNode => String(childNode.id)),
        parents: parents,
    };

    // All node IDs get converted into strings. Also do this for the device
    // `hierarchy_visibility` to keep things conistent.
    if (newNode.device) {
        newNode.device.hierarchy_visibility = String(newNode.device.hierarchy_visibility);
    }

    let nodes = {
        [rootId]: newNode,
    };

    // Add current node to parents list
    let childParents = [ ...parents, rootId ];

    rootChildren.forEach((childNode) => {
        nodes = {
            ...nodes,
            ...flattenHierarchy(childNode, childParents).nodes,
        };
    });

    return {
        rootId: rootId,
        nodes: nodes,
    };
}

function normalizeHierarchyData(hierarchy) {
    if (hierarchy === null) {
        return {
            hierarchy: null,
            flattenedHierarchy: null,
            devicesList: [],
        };
    }

    const flattenedHierarchy = flattenHierarchy(hierarchy);

    const devicesList = [];

    for (const nodeId in flattenedHierarchy.nodes) {
        const node = flattenedHierarchy.nodes[nodeId];

        if (node.node_type === NodeType.DEVICE) {
            const device = node.device;
            // Add the device to the list
            devicesList.push(device);
        }
    }

    // Sort devices by name
    devicesList.sort((deviceA, deviceB) => {
        return deviceA.asset_name.localeCompare(deviceB.asset_name);
    });

    return {
        hierarchy: hierarchy,
        flattenedHierarchy: flattenedHierarchy,
        devicesList: devicesList,
    };
}

export default resourceReducer({
    requests: [ 'getHierarchy', 'putHierarchy' ],
    setResource: (data) => {
        return normalizeHierarchyData(data);
    }
});

/**
 * Select the hierarchy in tree form.
 *
 * @param {Object} state The state of the redux store.
 * @returns {Object} The root node. Each node has an array of child nodes
 *  in `node.children`.
 */
export function selectHierarchy(state) {
    return selectResourceData(state).hierarchy;
}

/**
 * Select the current state of a request from the hierarchy store.
 *
 * @param {Object} state The state of the redux store.
 * @param {string} requestId
 * @returns {Object} The state of the request.
 */
export function selectHierarchyRequest(state, requestId) {
    return selectResourceRequest(state, requestId);
}

/**
 * Select the hierarchy in flattened format.
 *
 * @param {Object} state The state of the redux store.
 * @returns {FlatHierarchy} The hierarchy in flattened form.
 */
export function selectFlattenedHierarchy(state) {
    return selectResourceData(state).flattenedHierarchy;
}

/**
 * Select a single node from the hierarchy, by ID.
 *
 * @param {Object} state The state of the redux store.
 * @param {string} id Node ID.
 * @returns {FlatHierarchyNode} The hierarchy node.
 */
export function selectHierarchyNode(state, id) {
    const flattenedHierarchy = selectFlattenedHierarchy(state);
    const node = flattenedHierarchy.nodes[id];

    if (!node) {
        throw new Error(`Unable to find node with id "${id}".`);
    }

    return node;
}

/**
 * Select a list of all devices in the enterprise hierarchy.
 *
 * @param {Object} state The state of the redux store.
 * @returns {Object[]} List of device objects.
 */
export function selectDevicesList(state) {
    return selectResourceData(state).devicesList || [];
}
