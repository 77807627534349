import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InlineError from 'Common/components/Error/InlineError';
import { arrayJoin } from 'Common/util/language/array';

const StyledInlineError = styled(InlineError)`
    padding-top: 4px;
`;

function DurationFieldError({
    error,
    ...otherProps
}) {
    const renderError = (type) => {
        let errorJsx = null;

        if (error[type]) {
            errorJsx = (
                <span key={type}>
                    {error[type]}
                </span>
            );
        }

        return errorJsx;
    };

    return (
        <StyledInlineError {...otherProps}>
            {arrayJoin(
                ['h', 'm', 's'].map(renderError).filter(err => err),
                (<br />)
            )}
        </StyledInlineError>
    );
}

DurationFieldError.propTypes = {
    error: PropTypes.shape({
        h: PropTypes.node,
        m: PropTypes.node,
        s: PropTypes.node,
    }),
};

export default DurationFieldError;
