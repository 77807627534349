import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';

import Button from 'Common/components/Button';
import FieldBuilder from 'Common/components/FieldBuilder/FieldBuilder';
import TextField from 'Common/components/TextField';
import InlineError from 'Common/components/Error/InlineError';
import ListButton from 'Common/components/ListButton';
import MetricListColumnSelect from './MetricListColumnSelect';
import NoDataMessage from 'Common/components/NoDataMessage/NoDataMessage.js';
import { useTranslator } from 'Common/hooks/useTranslation';
import { v4 as uuid } from 'uuid';

import { elideTextCss } from 'Common/util/cssSnippets';
import { Primary5 } from 'Common/components/typography';
import { RadioButton, RadioButtonGroup } from 'Common/components/RadioButton';

import { METRIC_LIST_TYPE, SHOW_AS_BAR_CHART, SHOW_AS_TEXT_ONLY , SHOW_WITH_HEAT_MAP_INDICATORS } from "Common/util/MetricListColumnConstants";

const MetricListColumnEditorContainer = styled.div`
    padding: 0px 10px;
`;

const ApplyContainer = styled.div`
    padding: 3px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
`;

const HorizontalSeparator = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 6px;
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver};
`;

const IncludeLabel = styled(Primary5).attrs({ as: 'div' })`
    margin: 10px 0px 5px 0px;
`;

const DisplayNameContainer = styled.span`
    ${elideTextCss};
    align-items: center;
    display:flex;
    flex-direction: row;
    margin: 10px 0;
`;

const DisplayNameLabel = styled(Primary5).attrs({ as: 'div' })`
    padding-right: 12px;
`;

const RadioButtonGroupContainer = styled.div`
    padding-left: 12px;
`;

const AddButtonContainer = styled.div`
    padding: 0 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
`;

const Container = styled.div`
    overflow-y: auto;
    flex: 1 1 auto;
`;

const EmptyMetricsMessageContainer = styled.div`
    padding: 10px;
`;

const FieldBuilderContainer = styled.div`
    padding-top: 5px;
`;

function hasChanges(initialValue, value) {
    // It's considered a change if initialValue or value is falsy (such as undefined).
    if (!initialValue || !value) {
        return true;
    }

    // Check to see if any Object property indexed by key has changed.
    return some(['columns','displayName','showAs'], (key) => {
        switch (typeof initialValue[key])
        {
        case 'string':
            return (initialValue[key] !== value[key]);

        case 'object':
            return !isEqual(initialValue[key], value[key]);

        default:
            throw 'Unexpected shape';
        }
    });
}

function isCompatibleColumn(colA, colB) {
    if (colA.type === colB.type) {
        if (colA.subType === colB.subType) {
            // [FUTUREHACK]
            // these are mixed with some other double rates that are all ppm which seem should be separate
            return (colA.name.endsWith('_per_labor_hour') == colB.name.endsWith('_per_labor_hour'));
        }
        // both don't have a subType
        if (!colA.subType && !colB.subType) {
            return true;
        }
    }

    // Otherwise, do a lookup in our set of equivalent type/subType combinations.
    const typeKeyA = colA.type + (colA.subType ? '/' + colA.subType : '');
    const typeKeyB = colB.type + (colB.subType ? '/' + colB.subType : '');

    const equivalences = {
        'double/cycle': true, 'uint64': true  // All these can be cross-compared.
    };

    return equivalences[typeKeyA] && equivalences[typeKeyB];
}

function getErrors(translator, value) {
    let errors = {};

    if (!value || value.columns.length === 0) {
        errors.columns = translator('widget.table.column_control.errors.must_have_metrics');
    }

    if (!value.displayName) {
        errors.displayName = translator('widget.table.column_control.errors.must_have_header_text');
    }

    if (value.showAs === SHOW_AS_BAR_CHART) {
        const hasIncompatiableColumn = value.columns.some(col => {
            return !isCompatibleColumn(col, value.columns[0]) || col.type === 'date_time';
        });

        if (hasIncompatiableColumn) {
            errors.showAs = translator('widget.table.column_control.errors.incompatible_metrics');
        }
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

function getShowMetricListWithValue(radioValue) {
    switch (radioValue) {
    case SHOW_AS_BAR_CHART:
    case SHOW_AS_TEXT_ONLY:
    case SHOW_WITH_HEAT_MAP_INDICATORS:
        break;

    default:
        throw new Error(`Invalid radioValue: "${radioValue}"`);
    }

    return radioValue;
}

/**
 * A component containing all that is needed to add a Metric List Column
 * as a column on a table (the column and additional display info).
 *
 */
function MetricListColumnEditor(props) {
    const {
        selectedColumnIndex,
        selectedColumns,
        startingValue,
        title,

        onChange,
        transitionForward,
        transitionBackward,
    } = props;

    const translator = useTranslator();

    const initialValue = useMemo(() => {
        // this means we are editing an existing selectedColumn
        if (Number.isFinite(selectedColumnIndex)) {
            return selectedColumns[selectedColumnIndex];
        }
        else if (startingValue?.initialValue) {
            let initialStartingValue = startingValue.initialValue;
            if (!initialStartingValue.name) {
                initialStartingValue = { ...startingValue.initialValue, name: `mlist_${uuid()}` };
            }
            return initialStartingValue;
        }
        else {
            return {
                name: `mlist_${uuid()}`,
                channel: 'production_metric',
                columns: [],
                showAs: SHOW_AS_TEXT_ONLY,
                displayName: translator('metric_list'),
                fieldType: METRIC_LIST_TYPE,
                type: METRIC_LIST_TYPE
            };
        }
    },[selectedColumnIndex, startingValue, selectedColumns, translator]);

    // We will store the current value in persistedControlState
    const [value, setValue] = useState(startingValue?.value || initialValue);

    const errors = useMemo(() => {
        return getErrors(translator, value);
    }, [translator, value]);

    const onApply = useCallback(() => {
        let newSelectedColumns = selectedColumns;
        if (Number.isFinite(selectedColumnIndex)) {
            if (selectedColumns.length < selectedColumnIndex) {
                console.warn(`Can not update column at index ${selectedColumnIndex} there are only ${selectedColumns.length} columns.`);
            }
            else {
                newSelectedColumns = [].concat(selectedColumns);
                newSelectedColumns.splice(selectedColumnIndex, 1, value);
            }
            
        }
        else {
            newSelectedColumns = selectedColumns.concat([value]);
        }
        onChange({
            target: {
                value: newSelectedColumns
            }
        });
    }, [value, selectedColumns, onChange, selectedColumnIndex]);

    const canApplyChanges = useMemo(() => {
        return !errors && (hasChanges(initialValue, value) || !Number.isFinite(selectedColumnIndex));
    }, [initialValue, value, selectedColumnIndex, errors]);

    const handleFieldBuilderChange = useCallback((event) => {
        const newFieldValue = event.target.value;
        setValue(previousValue => {
            if (hasChanges(previousValue, { columns: newFieldValue })) {
                const newValue = {
                    ...previousValue,
                    columns: newFieldValue,
                };
    
                return newValue;
            }
            else {
                return previousValue;
            }
        });
    }, []);

    const handleHeaderChange = useCallback((event) => {
        const newHeaderValue = event.target.value;
        setValue(previousValue => {
            if (newHeaderValue != previousValue.displayName) {
                return { ...previousValue, displayName: newHeaderValue };
            }
            else {
                return previousValue;
            }
        });
    }, []);

    const handleRadioGroupChange = useCallback((event) => {
        const newRadioValue = getShowMetricListWithValue(event.target.value);
        setValue(previousValue => {
            if (newRadioValue !== previousValue.showAs) {
                return { ...previousValue, showAs: newRadioValue };
            }
            else {
                return previousValue;
            }
        });
    }, []);

    const handleAddMetric = useCallback((e) => {
        let propsForCurrentControl = { selectedColumnIndex, startingValue: { value, initialValue }};
        let propsForNextControl = {
            metricListMetrics:value.columns,
            metricListAddMetrics:(event) => {
                const newValue = event.target.value;
                const updatedPropsForCurrentControl = { 
                    selectedColumnIndex, 
                    startingValue: {
                        initialValue,
                        value: { ...value, columns: newValue.columns, displayName: newValue.displayName || value.displayName }
                    }
                };
                transitionBackward();
                transitionForward(
                    null,
                    { control: MetricListColumnEditor, props: updatedPropsForCurrentControl, title }
                );
            }
        };

        transitionForward(
            { control: MetricListColumnSelect, props: propsForNextControl, title: translator('add_field_template', { field: translator('metric_literal')}) },
            { control: MetricListColumnEditor, props: propsForCurrentControl, title },
        );
    }, [selectedColumnIndex, value, initialValue, transitionForward, translator, title, transitionBackward]);

    return (
        <Fragment>
            <Container>
                <AddButtonContainer>
                    <ListButton
                        onClick={handleAddMetric}
                    >
                        {translator('add_field_template', { field: translator('metric_literal')})}
                    </ListButton>
                </AddButtonContainer>
                    <FieldBuilderContainer>
                        <FieldBuilder
                            name='metrics'
                            value={value.columns}
                            onChange={handleFieldBuilderChange}
                        />
                    </FieldBuilderContainer>
                {value.columns.length === 0
                    ? (
                        <EmptyMetricsMessageContainer>
                            <NoDataMessage
                                title={translator('empty_list', { items: translator('metrics_literal').toLowerCase() })}
                                message={translator('widget.table.column_control.errors.add_to_empty_list', { add_text: translator('add_field_template', { field: translator('metric_literal')}) })}
                            />
                        </EmptyMetricsMessageContainer>
                    )
                    : null
                }
                <MetricListColumnEditorContainer>
                    <HorizontalSeparator />

                    <DisplayNameContainer>
                        <DisplayNameLabel>
                            {translator('widget.table.column_control.header_text')}
                        </DisplayNameLabel>
                        <TextField
                            name='headerText'
                            onChange={handleHeaderChange}
                            placeholder={translator('widget.table.column_control.enter_header_text')}
                            value={value.displayName}
                            error={errors?.displayName}
                        />
                    </DisplayNameContainer>

                    <IncludeLabel>
                        {translator('widget.table.column_control.show_metric_list_with.label')}
                    </IncludeLabel>

                    <RadioButtonGroupContainer>
                        <RadioButtonGroup
                            value={value.showAs}
                            onChange={handleRadioGroupChange}
                        >
                            <RadioButton value={SHOW_AS_TEXT_ONLY}>
                                {translator('widget.table.column_control.show_metric_list_with.only_text')}
                            </RadioButton>
                            <RadioButton value={SHOW_WITH_HEAT_MAP_INDICATORS}>
                                {translator('widget.table.column_control.show_metric_list_with.heat_map_indicators')}
                            </RadioButton>
                            <RadioButton value={SHOW_AS_BAR_CHART}>
                                {translator('widget.table.column_control.show_metric_list_with.bar_chart')}
                            </RadioButton>
                        </RadioButtonGroup>
                    </RadioButtonGroupContainer>
                    {errors?.showAs
                        ? (
                            <InlineError style={{ paddingTop: 4 }}>
                                {errors.showAs}
                            </InlineError>
                        )
                        : null
                    }
                </MetricListColumnEditorContainer>
            </Container>
            <ApplyContainer>
                <Button
                    onClick={onApply}
                    disabled={!canApplyChanges}
                >
                    {translator('apply_literal')}
                </Button>
            </ApplyContainer>
        </Fragment>
    );
}

const metricListColumnPropType = PropTypes.shape({
    name: PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            type: PropTypes.string,
            subType: PropTypes.string,
        })
    ).isRequired,
    displayName: PropTypes.string.isRequired,
    showAs: PropTypes.oneOf([
        '', SHOW_AS_BAR_CHART,SHOW_WITH_HEAT_MAP_INDICATORS,SHOW_AS_TEXT_ONLY
    ]),
});

MetricListColumnEditor.propTypes = {
    selectedColumnIndex: PropTypes.number,
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    displayName: PropTypes.string.isRequired,
                    type: PropTypes.string,
                    subType: PropTypes.string,
                })
            ),
            displayName: PropTypes.string.isRequired,
            showAs: PropTypes.oneOf([
                '', SHOW_AS_BAR_CHART,SHOW_WITH_HEAT_MAP_INDICATORS,SHOW_AS_TEXT_ONLY
            ]),
        }),
    ).isRequired,
    startingValue: PropTypes.shape({
        initialValue: metricListColumnPropType,
        value: metricListColumnPropType,
    }),
    title: PropTypes.string.isRequired,

    transitionForward: PropTypes.func.isRequired,
    transitionBackward: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MetricListColumnEditor;
