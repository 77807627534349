import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FirstSelectInput from './FirstSelectInput';
import SelectOption from 'Components/select/SelectOption';
import FormElement from 'Components/form-element/FormElement';
import { Body1 } from 'Components/typography/typography';
import processStates from 'System/processStates';
import SelectOptionContent from 'Components/select/SelectOptionContent';
import IntegerInputField from 'Components/number-input/IntegerInputField';
import didPropsChange from 'Components/util/didPropsChange';

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const StyledSelectElement = styled.div`
    margin: 8px;
    flex-shrink: 0;
`;

const ComparisonText = styled(Body1)`
    padding: 0 12px;
    font-weight: bold;
    color: ${props => props.theme.colors.darkText.highEmphasis};
`;

const ThresholdWrapper = styled.div``;
const ThresholdInputField = styled(IntegerInputField)``;
const TimeUnitLabel = styled(Body1)``;

const ThresholdElement = styled.div`
    margin: 8px;

    ${ThresholdWrapper} {
        display: flex;
        align-items: center;

        ${ThresholdInputField} {
            width: 70px;
        }

        ${TimeUnitLabel} {
            padding-left: 8px;
            color: ${props => props.theme.colors.darkText.mediumEmphasis};
        }
    }
`;

export default class ProcessStateDurationAlertEditor extends React.Component {
    constructor(props) {
        super(props);
    }

    _handleChange = (event) => {
        this.props.onChange({
            ...event,
            target: {
                name: this.props.name,
                value: {
                    ...this.props.value,
                    [event.target.name]: event.target.value,
                },
            },
        });
    };

    shouldComponentUpdate(nextProps) {
        return didPropsChange(this.props, nextProps, [
            'value',
            'validation',
        ]);
    }

    render() {
        const { allowEdit, value } = this.props;
        const { process_state, threshold } = value;

        const validation = this.props.validation || {};

        return (
            <ContentWrapper>
                <FormElement
                    Component={StyledSelectElement}
                    error={Boolean(validation.process_state)}
                    disabled={!allowEdit}
                >
                    <FirstSelectInput name="process_state" value={process_state} onChange={this._handleChange}>
                        {processStates.map((processState) => {
                            return (
                                <SelectOption
                                    key={processState.value}
                                    value={processState.value}
                                >
                                    <SelectOptionContent>
                                        {processState.name}
                                    </SelectOptionContent>
                                </SelectOption>
                            );
                        })}
                    </FirstSelectInput>
                </FormElement>
                <ComparisonText>{'>'}</ComparisonText>
                <FormElement
                    Component={ThresholdElement}
                    error={Boolean(validation.threshold)}
                    disabled={!allowEdit}
                >
                    <ThresholdWrapper>
                        <ThresholdInputField
                            name="threshold"
                            type="number"
                            value={String(threshold)}
                            onChange={this._handleChange}
                        />
                        <TimeUnitLabel>min.</TimeUnitLabel>
                    </ThresholdWrapper>
                </FormElement>
            </ContentWrapper>
        );
    }
}

ProcessStateDurationAlertEditor.propTypes = {
    allowEdit: PropTypes.bool.isRequired,
    name: PropTypes.string,
    value: PropTypes.shape({
        process_state: PropTypes.string.isRequired,
        threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
    validation: PropTypes.shape({
        process_state: PropTypes.string,
        threshold: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};

ProcessStateDurationAlertEditor.defaultProps = {
    name: '',
    validation: {}
};
