import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import KPIValue from './KPIValue';
import KPISparkLine from './KPISparkLine';
import { FontWeight } from 'Common/components/typography';

const StyledKpiValue = styled(KPIValue)``;

const TrendDotContainer = styled.div``;

const BottomContainer = styled.div``;
const TrendInfo = styled.div``;
const SparkLineContainer = styled.div``;
const StyledSparkLine = styled(KPISparkLine)``;

const KPIDataContainer = styled.div`

    ${({ condensed }) => condensed && css`
        margin-top: 8px;
    `}

    ${BottomContainer} {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    ${StyledKpiValue} {
        flex: 1 0 auto;
        align-self: flex-end;
        text-align: left;

        ${({hideTrend}) => hideTrend && css`
            text-align: center;
        `}

        font-weight: ${FontWeight.medium};
        font-size: 36px;
        line-height: 1em;

        padding-right: 0.5em;

        ${({isPrint}) => isPrint && css`
            font-size: 20px;
        `}

        ${({condensed}) => condensed && css`
            font-size: 18px;
        `}

        /* Very bad metrics look a little funky in KPIs
           due to how text is aligned and how large the
           font size is. Allowing the background to overflow
           and slightly reducing some of the paddings keeps
           everything aligned and looking how we want.
        */
        .cpe-metric-container {
            overflow: visible;

            span.cpe-metric-very-bad {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    ${TrendInfo} {
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        justify-content: space-between;

        flex: 0 1 154px;

        height: 53px;

        ${({condensed}) => condensed && css`
            height: 43px;
        `}

        min-width: 25px;
        max-width: 154px;

        ${({isPrint}) => isPrint && css`
            width: 40%;
        `}

        ${({condensed}) => condensed && css`
            text-align: left;
            max-width: 75px;
        `}

        ${TrendDotContainer} {
            text-align: center;
            flex: 1 0 auto;
            padding-top: 6px;
        }

        ${SparkLineContainer} {
            display: block;
            flex: 0 1 35px;
            min-height: 15px;

            ${({condensed}) => condensed && css`
                text-align: left;
                height: 15px;
            `}
        }
    }
`;

export default function KPIData({
    value,
    trendDot,
    sparkLineData,
    noColor,
    isPrint,
    condensed,
    hideTrend,
    ...otherProps
}) {

    const renderTrendInfo = () => {
        if (! hideTrend) {
            return (
                <TrendInfo>
                    <TrendDotContainer>
                        {trendDot}
                    </TrendDotContainer>
                    <SparkLineContainer>
                        <StyledSparkLine
                            data={sparkLineData}
                            noColor={noColor}
                        />
                    </SparkLineContainer>
                </TrendInfo>
            );
        }
    };
    return (
        <KPIDataContainer
            isPrint={isPrint}
            hideTrend={hideTrend}
            condensed={condensed}
            {...otherProps}
        >
            <BottomContainer>
                <StyledKpiValue>
                    {value}
                </StyledKpiValue>
                {renderTrendInfo()}
            </BottomContainer>
        </KPIDataContainer>
    );
}

KPIData.propTypes = {
    value: PropTypes.node,
    trendDot: PropTypes.node,
    sparkLineData: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.number.isRequired
        )
    ),
    noColor: PropTypes.bool,
    isPrint: PropTypes.bool,
    condensed: PropTypes.bool,
    hideTrend: PropTypes.bool.isRequired,
};

KPIData.defaultProps = {
    value: '',
    sparkLineData: null,
    noColor: false,
    isPrint: false,
    condensed: false,
};
