import { default as validateRules } from 'Components/password/PasswordRulesValidation';
import { combineValidators } from 'Components/util/validationUtils';

const validate = combineValidators({
    email: (email) => email.length > 0 ? null : 'Required',
    name: (name) => name.length > 0 ? null : 'Required',
    organization_name: (organization_name) => organization_name.length > 0 ? null : 'Required',
    password: (password) => validateRules(password),
    timezone: (timezone) => timezone.length > 0 ? null : 'Required',
    terms_accepted: (terms_accepted) => terms_accepted ? null : 'Required',
});

export default validate;
