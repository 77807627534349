import React from 'react';
import styled from 'styled-components';

import { elementsOfType } from 'Components/util/propTypesUtils';
import NavSubMenu from './NavSubMenu';
import { FontFamily } from 'Components/typography/typography';

const MenuList = styled.ul`
    font: normal 12px ${FontFamily.primary};
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
`;

function NavMenu({ children, ...otherProps }) {
    return (
        <MenuList {...otherProps}>
            {children}
        </MenuList>
    );
}

NavMenu.propTypes = {
    children: elementsOfType([
        NavSubMenu,
    ]),
};

export default NavMenu;
