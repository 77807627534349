
import {
    resourceCollectionReducer,
    resourceReducer,
} from 'Common/util/ResourceCollection';
import {
    selectResources,
} from 'Common/util/ResourceCollection/resourceCollectionReducer';

export default resourceCollectionReducer('emailSubscriptions', {
    getResourceId: (resource) => resource.id,
    requests: [
        'getEmailSubscriptions',
        'patchEmailSubscriptions',
        'deleteEmailSubscription',
    ],
    resourceReducer: resourceReducer({
        requests: [
            'getEmailSubscriptions',
            'patchEmailSubscriptions',
            'deleteEmailSubscription',
        ],
    }),
    sort: (subscriptionA, subscriptionB) => {
        return subscriptionA.id - subscriptionB.id;
    },
});

export function getEmailSubscriptionsList(state) {
    return selectResources(state);
}
