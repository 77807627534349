import React from 'react';
import PropTypes from 'prop-types';
import ErrorNotice from 'Components/notice/ErrorNotice';

function ErrorLoadingMessage(props) {
    const { children, ...otherProps } = props;

    return (
        <ErrorNotice {...otherProps}>
            {children}
        </ErrorNotice>
    );
}

ErrorLoadingMessage.propTypes = {
    children: PropTypes.node,
};

export default ErrorLoadingMessage;
