/**
 * Run a ParamType tester.
 *
 * @param {ParamType} typeChecker
 * @param {Any} arg 
 * @param {String} [key='argument']
 *
 * @returns {String[]} Array of failure messages.
 */
export default function testType(typeChecker, arg, key = 'argument') {
    if (arg === null || arg === undefined) {
        if (typeChecker._required) {
            return [
                `${key} is required`
            ];
        }
        else {
            // If null / undefined and not required then we have no problems here
            return [];
        }
    }

    return typeChecker._test(arg, key);
}
