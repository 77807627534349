import { useCallback, useMemo, useState } from 'react';

function useDialogState() {
    const [ isInvalidDialogVisible, setIsInvalidDialogVisible ] = useState(false);

    const showInvalidDialog = useCallback(() => {
        setIsInvalidDialogVisible(true);
    }, []);

    const hideInvalidDialog = useCallback(() => {
        setIsInvalidDialogVisible(false);
    }, []);

    return {
        isInvalidDialogVisible,
        showInvalidDialog,
        hideInvalidDialog,
    };
}

const defaultDetectChanges = (initialValue, value) => initialValue !== value;

/**
 * A hook for managing state of a live control that requires validation.
 *
 * Internally, the hook stores a "modified" value (set by calling the returned
 * `setValue` function), and runs that though the provided `validate` function.
 * If `validate` returns any truthy value, then the modified value is considered
 * invalid.
 *
 * `apply` Should be called when the `value` is to be applied/saved. When called:
 *  - If the current value is valid: `onChange` will be called, the internal
 *    state will be reset, and `apply` will return true to indicate success.
 *  - If the current value is invalid: `apply` will return false and
 *    `isInvalidDialogVisible` will be set to true.
 *
 * `reset` can be called to cancel any changes and return to the initial state.
 *
 * An optional `detectChanges` function can be provided for custom checking of
 * changes between the initial and modified value.
 *
 * @param {{
 *  initialValue,
 *  validate: function(value) : * | null,
 *  onChange: function(value),
 *  detectChanges?: function(initialValue, value) : bool
 * }} params
 * @returns {{
 *  value: *,
 *  setValue: function(value),
 *  isDirty: boolean,
 *  isValid: boolean,
 *  reset: function(),
 *  validationErrors: * | null,
 *  apply: function() : boolean,
 *  isInvalidDialogVisible: boolean,
 *  hideInvalidDialog: function(),
 * }}
 */
export default function useValidationState({
    initialValue,
    validate,
    onChange,
    detectChanges = defaultDetectChanges,
}) {
    const [ modifiedValue, setModifiedValue ] = useState(null);

    const {
        isInvalidDialogVisible,
        showInvalidDialog,
        hideInvalidDialog,
    } = useDialogState();

    // Let the internal value follow initialValue until the user makes changes.
    const value = modifiedValue === null ? initialValue : modifiedValue;

    const isDirty = useMemo(() => {
        return detectChanges(initialValue, value);
    }, [ detectChanges, initialValue, value ]);

    const reset = useCallback(() => {
        setModifiedValue(null);
        hideInvalidDialog();
    }, [ hideInvalidDialog ]);

    const validationErrors = useMemo(() => {
        return validate(value);
    }, [ validate, value ]);

    const isValid = !validationErrors;

    const apply = useCallback(() => {
        if (!isValid) {
            showInvalidDialog();
            return false;
        }

        setModifiedValue(null);
        hideInvalidDialog();

        if (isDirty) {
            onChange(value);
        }

        return true;
    }, [ isValid, isDirty, setModifiedValue, showInvalidDialog, hideInvalidDialog, onChange, value ]);

    return {
        value: value,
        setValue: setModifiedValue,
        isDirty: isDirty,
        isValid: isValid,
        reset: reset,
        validationErrors: validationErrors,
        apply: apply,
        isInvalidDialogVisible,
        hideInvalidDialog,
    };
}
