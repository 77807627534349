import PropTypes from 'prop-types';
import React, { useMemo, useCallback, forwardRef } from 'react';
import styled from 'styled-components';

import InvalidLiveControlDialog from 'Common/components/LiveControlBar/InvalidLiveControlDialog';
import ValidatedLiveControl from 'Common/components/LiveControlBar/ValidatedLiveControl';

import { secondsToHms, hmsToSeconds } from 'Common/components/DurationField/hmsHelpers';

import FilterBuilder from 'Common/components/FilterBuilder/FilterBuilder';
import validateFilters from './internal/validateFilters';
import useTranslation, { useTranslator } from 'Common/hooks/useTranslation';
import Filters from 'Common/types/Filters/Filters';

const DEFAULT_COMBINE_TYPE = 'and';

function addColumnInfoToFilters({ filters, categoryOptions, metricOptions }) {

    // Combine all category and metric options into a two-level object for
    // quick lookup. First keyed by channel, then by name.

    const filterLhsOptions = {};

    [ ...categoryOptions, ...metricOptions ].forEach((group) => {
        group.entries.forEach((entry) => {
            if (!filterLhsOptions[entry.channel]) {
                filterLhsOptions[entry.channel] = {};
            }

            filterLhsOptions[entry.channel][entry.name] = entry;
        });
    }, []);

    return {
        ...filters,
        slice: {
            combine_type: filters.slice?.combine_type || DEFAULT_COMBINE_TYPE,
            expressions: (filters.slice?.expressions || []).map((exp) => {
                const lhsOption = filterLhsOptions[exp.lhs.channel]?.[exp.lhs.name];

                return {
                    lhs: lhsOption,
                    op: exp.op,
                    rhs: exp.rhs || [],
                };
            }),
        },
        group: {
            combine_type: filters.group?.combine_type || DEFAULT_COMBINE_TYPE,
            expressions: (filters.group?.expressions || []).map((exp) => {
                const lhsOption = filterLhsOptions[exp.lhs.channel]?.[exp.lhs.name];

                return {
                    lhs: lhsOption,
                    op: exp.op,
                    rhs: exp.rhs,
                };
            }),
        }
    };
}

function convertFromInitialValue(filters) {
    let convertedFilters = {};

    Object.entries(filters).forEach(([type, filter]) => {
        if ('expressions' in filter) {
            convertedFilters[type] = {
                ...filter,
                expressions: filter.expressions.map((exp) => {
                    if (exp?.lhs?.type === 'time_span') {
                        return {
                            ...exp,
                            rhs: secondsToHms(exp.rhs || 0),
                        };
                    }
                    else {
                        return exp;
                    }
                })
            };
        }
        else {
            convertedFilters[type] = filter;
        }
    });

    return convertedFilters;
}

function convertToOutgoingValue(filters) {
    const {
        slice: sliceFilters,
        group: groupFilters,
        ...convertedFilters
    } = filters;

    // Convert slice filters.
    //

    const sliceFilterExpressions = Array.isArray(sliceFilters?.expressions)
        ? sliceFilters.expressions
        : [];

    convertedFilters.slice = {
        combine_type: sliceFilters.combine_type || DEFAULT_COMBINE_TYPE,
        expressions: sliceFilterExpressions.filter((exp) => exp.lhs).map((exp) => {
            return {
                lhs: {
                    name: exp.lhs.name,
                    channel: exp.lhs.channel,
                },
                op: exp.op,
                rhs: Array.isArray(exp.rhs) ?
                        exp.rhs.map((rhsPart) => {
                            // Only include the name for objects with a name field
                            return rhsPart?.name ? rhsPart.name : rhsPart;
                        })
                        : [],
            };
        }),
    };

    // Convert group filters.
    //

    const groupFilterExpressions = Array.isArray(groupFilters?.expressions) ?
        groupFilters.expressions
        : [];

    convertedFilters.group = {
        combine_type: groupFilters.combine_type || DEFAULT_COMBINE_TYPE,
        expressions: groupFilterExpressions.filter((exp) => exp.lhs).map((exp) => {
            let newRhs = exp.rhs;

            if (exp.lhs.type === 'time_span') {
                // Time spans must be converted from hms object back into
                // a number.

                // Allow rhs to be a raw zero as opposed to an hms object.
                if (exp.rhs === 0) {
                    newRhs = 0;
                }
                else {
                    newRhs = hmsToSeconds(exp.rhs);
                }
            }
            else if (exp.rhs === undefined) {
                // Prefer null over undefined for filters.
                return {
                    ...exp,
                    rhs: null,
                };
            }

            return {
                lhs: {
                    name: exp.lhs.name,
                    channel: exp.lhs.channel,
                },
                op: exp.op,
                rhs: newRhs,
            };
        }),
    };

    return convertedFilters;
}

const FilterBuilderContainer = styled.div`
    padding: 0 12px;
`;

const FilterControl = forwardRef((props, ref) => {
    const {
        initialValue,
        onApply,
        onCancel,
        categoryOptions,
        metricOptions,
        noMetricOptionsMsg,
        categoryValueOptions,
        allowDelete,
        combineTypes,
    } = props;

    const convertedInitialValue = useMemo(() => {
        const initialValueWithColumnInfo = addColumnInfoToFilters({
            filters: initialValue,
            categoryOptions,
            metricOptions,
        });

        return convertFromInitialValue(initialValueWithColumnInfo);
    }, [ initialValue, categoryOptions, metricOptions ]);

    const translator = useTranslator();
    const validate = useCallback((value) => {
        const validFilters = {
            ...value,
            slice: {
                ...value.slice,
                expressions: value.slice.expressions.filter((expression) => {
                    if (expression.lhs) {
                        return expression;
                    }
                })
            }
        };
        
        return validateFilters(validFilters, translator);
    }, [ translator ]);

    const handleApply = useCallback((event) => {
        onApply({
            target: {
                value: convertToOutgoingValue(event.target.value),
            },
        });
    }, [ onApply ]);

    const invalidChangesPopupTitleLiteral = useTranslation('editors.filter.invalid_changes_popup.title');
    const invalidChangesPopupMessageLiteral = useTranslation('editors.filter.invalid_changes_popup.message');

    return (
        <ValidatedLiveControl
            ref={ref}
            initialValue={convertedInitialValue}
            onApply={handleApply}
            onCancel={onCancel}
            validate={validate}
            renderControl={({ value, validationErrors, onChange }) => {
                return (
                    <FilterBuilderContainer>
                        <FilterBuilder
                            categoryOptions={categoryOptions}
                            metricOptions={metricOptions}
                            noMetricOptionsMsg={noMetricOptionsMsg}
                            categoryValueOptions={categoryValueOptions}
                            error={validationErrors}
                            value={value}
                            onChange={onChange}
                            allowDelete={allowDelete}
                            combineTypes={combineTypes}
                        />
                    </FilterBuilderContainer>
                );
            }}
            renderInvalidDialog={({ onContinue, onCancel: onDialogCancel }) => {
                return (
                    <InvalidLiveControlDialog
                        title={invalidChangesPopupTitleLiteral}
                        content={invalidChangesPopupMessageLiteral}
                        onContinue={onContinue}
                        onCancel={onDialogCancel}
                    />
                );
            }}
        />
    );
});

FilterControl.displayName = 'FilterControl';

FilterControl.propTypes = {
    initialValue: Filters.propType.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    categoryOptions: PropTypes.array.isRequired,
    metricOptions: PropTypes.array.isRequired,
    noMetricOptionsMsg: PropTypes.string,
    categoryValueOptions: PropTypes.object.isRequired,
    allowDelete: PropTypes.bool.isRequired,
    combineTypes: PropTypes.array.isRequired,
};

FilterControl.defaultProps = {
    allowDelete: true,
    combineTypes: ['and', 'or'],
};

export default FilterControl;
