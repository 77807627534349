import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import Notice from './Notice';

function Warning({ children, theme, ...otherProps }) {
    return (
        <Notice
            textColor={theme.colors.onWarning}
            backgroundColor={theme.colors.warningSurface}
            borderColor={theme.colors.warning}
            {...otherProps}
        >
            {children}
        </Notice>
    );
}

Warning.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object.isRequired,
};

export default withTheme(Warning);
