import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Primary5 } from 'Common/components/typography';

const Root = styled(Primary5).attrs({ as: 'div' })`
    position: relative;
    display: block;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    height: 22px;
    width: 100%;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.emperor};

    transition: background-color 0.3s, border-color 0.3s;

    ${({isDisabled}) => !isDisabled && css`
        /* Default, Non-error State */
        ${({hasError}) => !hasError && css`
            ${({hasFocus}) => hasFocus && css`
                background-color: ${({theme}) => theme.colors.palette.blue.ice};
                border-bottom-color: ${({theme}) => theme.colors.palette.blue.awesome};
            `}
        `}

        /* Error State */
        ${({hasError}) => hasError && css`
            background-color: ${({theme}) => theme.colors.palette.error.helios};
            border-bottom-color: ${({theme}) => theme.colors.palette.error.red};
        `}
    `}

    ${({isDisabled}) => isDisabled && css`
        border-bottom-color: ${({theme}) => theme.colors.palette.grey.ash};
        opacity: 0.34;
        pointer-events: none;
    `}
`;

const LeftIconContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.darkText.mediumEmphasis}; 
    pointer-events: none;
`;

const TextInput = styled(Primary5).attrs({ as: 'input' })`
    position: absolute;
    display: block;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    /* Since there is a bottom border, in order of the text to line up with
       the baseline we need to nudge down a pixel.
    */
    top: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${({hasLeftIcon}) => hasLeftIcon ? 25 : 5}px;
    padding-right: 5px;

    transition: color 0.3s;

    text-align: inherit;

    color: ${({theme}) => theme.colors.darkText.highEmphasis};
    background-color: transparent;

    /*
        This is needed to override bootstrap's _normalize.scss, which sets
        all search inputs to have 'box-sizing: content-box;'. We can probably
        remove this when we finally remove bootstrap.
    */
    &[type="search"] {
        box-sizing: border-box;
    }

    &::placeholder {
        color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
        opacity: 1;
        transition: color 0.3s, opacity 0.3s;
    }

    &:focus {
        /* Only hide placeholder text on focused number inputs */
        &[type="number"]::placeholder {
            opacity: 0;
        }
    }

    &:not(:disabled) {
        /* Error State */
        ${({hasError}) => hasError && css`
            color: ${({theme}) => theme.colors.palette.error.red};
        `}
    }

    &:disabled {
        color: ${({theme}) => theme.colors.darkText.disabled};
    }
`;

/**
 * A simple text input field with the standard styles, including disabled
 * and error states. Most props are passed along to the internal input node.
 */
export default function TextField({
    inputRef,
    className,
    type,
    autoFocus,
    disabled,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    error,
    leftIcon,
    ...inputProps
}) {
    const hasError = Boolean(error);
    const hasLeftIcon = Boolean(leftIcon);

    const [ hasFocus, setHasFocus ] = useState(false);

    const handleFocus = useCallback((event) => {
        setHasFocus(true);
        onFocus(event);
    }, [ onFocus ]);

    const handleBlur = useCallback((event) => {
        setHasFocus(false);
        onBlur(event);
    }, [ onBlur ]);

    return (
        <Root
            className={className}
            isDisabled={disabled}
            hasFocus={hasFocus}
            hasError={hasError}
        >
            {hasLeftIcon && (
                <LeftIconContainer>
                    {leftIcon}
                </LeftIconContainer>
            )}
            <TextInput
                ref={inputRef}
                hasError={hasError}
                hasLeftIcon={hasLeftIcon}
                type={type}
                autoFocus={autoFocus}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...inputProps}
            />
        </Root>
    );
}

TextField.propTypes = {
    /* inputRef: A ref that will be passed through directly to the <input />. */
    inputRef: PropTypes.any,
    className: PropTypes.string,
    type: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    /* error: A truthy value indicates the field should display in an error state. */
    error: PropTypes.any,
    /* leftIcon: Icon to be placed on the left side of the text input. */
    leftIcon: PropTypes.node,
};

TextField.defaultProps = {
    type: 'text',
    disabled: false,
    onFocus: () => {},
    onBlur: () => {},
    error: false,
};
