import isString from 'lodash/isString';

// matches numbers separated by dots. Up to 4 dotted numbers will be matched
const regex = /[0-9]+(\.[0-9]+){0,3}/;

function parseVersion(version) {
    if (!isString(version)) {
        throw new Error('Version must be a string');
    }

    if (version.length === 0) {
        throw new Error('Invalid version format - empty string');
    }

    // find a clean numeric dotted version string removing everything else
    const cleanedVersionString = version.match(regex);
    if (! cleanedVersionString) {
        throw new Error('Invalid version format - could not find valid version');
    }

    const versionNumbers = cleanedVersionString[0].split('.');

    return versionNumbers.map((numStr) => {
        if (numStr.length === 0) {
            throw new Error(`Invalid version format ${version}`);
        }

        const num = Number(numStr);

        if (!Number.isInteger(num) || !Number.isFinite(num) || num < 0) {
            throw new Error(`Invalid version format ${version}`);
        }

        return num;
    });
}

export function compareVersions(versionStringA, versionStringB) {
    const versionA = parseVersion(versionStringA);
    const versionB = parseVersion(versionStringB);

    for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
        const partA = i < versionA.length ? versionA[i] : 0;
        const partB = i < versionB.length ? versionB[i] : 0;

        if (partA < partB) {
            return -1;
        }
        else if (partA > partB) {
            return 1;
        }
    }

    return 0;
}

export function isDeviceAssetNameChangeSupported(xlFirmwareVersion) {
    return compareVersions(xlFirmwareVersion, '2.13') >= 0;
}

export function isDeviceVisibilityChangeSupported(xlFirmwareVersion) {
    return compareVersions(xlFirmwareVersion, '2.13') >= 0;
}

export function isDeviceUpgradeSupported(xlFirmwareVersion) {
    return compareVersions(xlFirmwareVersion, '2.19.2') >= 0;
}
