import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Heading5, Secondary5 } from 'Common/components/typography';
import Icon from 'Common/components/Icon';
import ButtonBase from 'Common/components/Button/ButtonBase';
import { dragDropCursor } from 'Common/util/cssSnippets';

const Root = styled.div`
    padding: 3px 15px 8px 18px;
    box-sizing: border-box;
    ${dragDropCursor}
    display: flex;
    flex-direction: column;
    user-select: none;
    border-top: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
    margin-top: -1px;
    
    ${({$isDragging}) => $isDragging && css`
        background-color: ${({theme}) => theme.colors.palette.blue.ice };
        box-shadow: 0px 11px 15px -6px ${({theme}) => theme.colors.palette.grey.emperor};
        border-radius: 4px;
        border: 1px solid ${({theme}) => theme.colors.palette.grey.silver };

        ${({$overTrash}) => $overTrash && css`
            background-color: ${({theme}) => theme.colors.palette.error.helios };
        `}
    `}
`;

const PrimaryContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
`;

const PrimaryText = styled(Heading5)`
    flex: 1 1 auto;
    display: block;
`;

const SecondaryContainer = styled(Secondary5)`
    display: block;
`;

const StyledButton = styled(ButtonBase)`
    flex: 0 0 auto;
    box-sizing: border-box;

    color: ${({theme}) => theme.colors.palette.grey.emperor };
    font-size: 12px;

    padding: 4px 8px;
    border-radius: 4px;

    :disabled {
        opacity: 0.5;
    }

    :hover:enabled {
        background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    }
`;

const StyledIcon = styled(Icon)`
    font-size: 9px;
`;

/**
 * 
 * The component used in ColumnControl for the shown draggable field. It allows
 * the user to drag it for reordering and for deleting. Has a button for the
 * user to begin edit on the field.
 */

function DraggableFieldItem(props) {
    const { name, primaryText, secondaryText, onEdit, isDragging, overTrash } = props;

    const handleEdit = useCallback(() => {
        onEdit(
            {
                target: {
                    value: name,
                },
            }
        );
    }, [onEdit, name]);

    return (
        <Root $isDragging={isDragging} $overTrash={overTrash}>
            <PrimaryContainer>
                <PrimaryText>{primaryText}</PrimaryText>
                <div>
                    {onEdit
                        ? (
                            <StyledButton
                                aria-label="Edit"
                                onClick={handleEdit}
                            >
                                <StyledIcon
                                    type="vorne"
                                    iconName="collapse-arrow-closed"
                                />
                            </StyledButton>
                        )
                        : null
                    }
                </div>
            </PrimaryContainer>
            <SecondaryContainer>
                {secondaryText}
            </SecondaryContainer>
        </Root>
    );
}

DraggableFieldItem.propTypes = {
    name: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
    onEdit: PropTypes.func,
    isDragging: PropTypes.bool,
    overTrash: PropTypes.bool,
};

DraggableFieldItem.defaultProps = {
    secondaryText: '',
    isDragging: false,
    overTrash: false,
};

export default React.memo(DraggableFieldItem);
