import React, {
    useState,
    useCallback,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body5 } from 'Common/components/typography';
import { useTranslator } from 'Common/hooks/useTranslation';

import Button from 'Common/components/Button';
import BasicSelectField from 'Common/components/BasicSelectField/BasicSelectField';
import { filterFields } from 'Common/components/FieldBuilder/FieldBuilder';

const limitOptions = [
    { displayName:'1', value:'1' },
    { displayName:'2', value:'2' },
    { displayName:'3', value:'3' },
    { displayName:'4', value:'4' },
    { displayName:'5', value:'5' },
];

const emptyValue = {
    attentionList: { limit: 3 }
};

function getColumnLimit(column) {
    return column.attentionList.limit;
}

// return a new column with limit updated
function setColumnLimit(column, limit) {
    let newAttentionListPortion = {
        ...column.attentionList,
        limit
    };

    return { ...column, attentionList: newAttentionListPortion };
}

function hasChanges(initialValue, value) {
    // its considered change if theres no value or initialValue
    if (!initialValue || !value) {
        return true;
    }
    const sameLimit = getColumnLimit(initialValue) === getColumnLimit(value);
    const sameColumn = initialValue.name === value.name;
    return !sameColumn || !sameLimit;
}

const verticalPadding = 5;

const ListDropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 14px ${verticalPadding}px 14px;
`;

const LimitContainer = styled.div`
    padding: 0 14px ${verticalPadding}px 14px;
`;

const LimitLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${verticalPadding}px 0;
`;

const AttentionListLabel = styled.div`
    width: 35%;
    flex: 0 0 auto;
    padding-right: 10px;
    color: ${({theme}) => theme.colors.palette.grey.manatee};
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AttentionListContainer = styled.div`
    min-width: 1px;
    flex: 1 1 auto;
`;

const HelperTextContainer = styled(Body5).attrs({ as: 'div' })`
    color: ${({ theme }) => theme.colors.darkText.mediumEmphasis };
    padding: 0 14px 10px;
`;

const ApplyContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

/**
 * A component containing all that is needed to add an Attention List Column
 * as a column on a table (the column and the limit).
 *
 */
function AttentionListColumnSelector(props) {
    const {
        fieldOptions,
        selectedColumnIndex,
        selectedColumns,
        onChange,
        startingValue
    } = props;

    const translator = useTranslator();

    // always provide a value for sort to be stored into
    const initialValue = Number.isFinite(selectedColumnIndex) ? selectedColumns[selectedColumnIndex] : startingValue || emptyValue;

    const [value, setValue] = useState(initialValue);

    const options = useMemo(() => {
        const unselectableColumns = initialValue
            ? selectedColumns.filter(col => col.name !== initialValue.name)
            : selectedColumns;
        return filterFields(fieldOptions.attention_list || [], unselectableColumns);
    }, [fieldOptions, selectedColumns, initialValue]);

    const handleColumnChange = useCallback((event) => {
        let newValue = value;
        const { name: targetName, value: targetValue } = event.target;

        if (targetName === 'limit') {
            newValue = setColumnLimit(value, Number(targetValue.value));
        }
        else {
            // Use the new target to find the actual option selected
            const nextColumn = options.find((option) => (option.name === targetValue.value));
            newValue = setColumnLimit(nextColumn, getColumnLimit(value));
        }

        if (!hasChanges(initialValue, newValue)) {
            newValue = initialValue;
        }

        setValue(newValue);
    }, [value, initialValue, options]);

    const onApply = useCallback(() => {
        let newSelectedColumns = selectedColumns;
        if (Number.isFinite(selectedColumnIndex)) {
            if (selectedColumns.length < selectedColumnIndex) {
                console.warn(`Can not update column at index ${selectedColumnIndex} there are only ${selectedColumns.length} columns.`);
            }
            else {
                newSelectedColumns = [].concat(selectedColumns);
                newSelectedColumns.splice(selectedColumnIndex, 1, value);
            }
            
        }
        else {
            newSelectedColumns = selectedColumns.concat([value]);
        }
        onChange({
            target: {
                value: newSelectedColumns
            }
        });
    }, [value, selectedColumns, onChange, selectedColumnIndex]);

    const canApplyChanges = useMemo(() => {
        return (hasChanges(initialValue, value) || value === startingValue) && value.name;
    }, [initialValue, value, startingValue]);

    return (
        <React.Fragment>
            <HelperTextContainer>
                {translator('widget.table.column_control.attention_list_helper_text')}
            </HelperTextContainer>
            <ListDropdownContainer>
                <AttentionListLabel>{translator('widget.table.column_control.attention_list_dropdown_label')}</AttentionListLabel>
                <AttentionListContainer>
                    <BasicSelectField
                        name='attentionList'
                        onChange={handleColumnChange}
                        options={options.map((option) => {
                            return {
                                displayName: option.displayName,
                                value: option.name,
                            };
                        })}
                        selectedValue={value?.name || ''}
                        selectedDisplayName={value?.displayName || translator('widget.table.column_control.attention_list_dropdown_placeholder')}
                    />
                </AttentionListContainer>
            </ListDropdownContainer>

            <LimitContainer>
                <LimitLabelContainer>
                    <AttentionListLabel>{translator('widget.table.column_control.attention_list_limit_label')}</AttentionListLabel>
                    <BasicSelectField
                        name='limit'
                        onChange={handleColumnChange}
                        options={limitOptions}
                        selectedValue={String(value?.attentionList?.limit)}
                        selectedDisplayName={String(getColumnLimit(value))}
                    />
                </LimitLabelContainer>
                <ApplyContainer>
                    <Button
                        onClick={onApply}
                        disabled={!canApplyChanges}
                    >
                        {translator('apply_literal')}
                    </Button>
                </ApplyContainer>
            </LimitContainer>
        </React.Fragment>
    );
}

const attentionListPropType = PropTypes.shape({
    limit: PropTypes.number.isRequired,
});

const attentionListColumnPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    attentionList: attentionListPropType.isRequired,
});

AttentionListColumnSelector.propTypes = {
    fieldOptions: PropTypes.shape({
        attention_list: PropTypes.arrayOf(attentionListColumnPropType).isRequired,
    }).isRequired,
    selectedColumnIndex: PropTypes.number,
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            attentionList: attentionListPropType,
        }),
    ).isRequired,
    startingValue: attentionListColumnPropType,

    onChange: PropTypes.func.isRequired,
};

export default AttentionListColumnSelector;
