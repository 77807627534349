import { useEffect, useRef } from 'react';
import { FAILURE, SUCCESS } from 'Common/data/RequestStatus';

export default function usePersistWidgetHeight(dataRequestStatus) {
    
    // Keep around a measurement of the most recent height of the widget and use
    // it as the height of the loading indicator. This stops the layout from
    // jumping around when the widget switches in and out of loading.

    let widgetContainerRef = useRef(null);
    let lastWidgetHeightRef = useRef(null);

    useEffect(() => {
        if (dataRequestStatus === FAILURE) {
            // Reset the widget height.
            lastWidgetHeightRef.current = null;
        }
        else if (dataRequestStatus === SUCCESS) {
            // Measure the widget height and save it.
            lastWidgetHeightRef.current = widgetContainerRef.current?.getBoundingClientRect()?.height || null;
        }
    }, [ dataRequestStatus, lastWidgetHeightRef, widgetContainerRef ]);

    return {
        lastWidgetHeight: lastWidgetHeightRef.current,
        widgetContainerRef
    };
}
