import React from 'react';

const defaultContext = {
    focused: false,
    required: false,
    disabled: false,
    error: false,
    onFocus: () => {},
    onBlur: () => {},
};

const FormElementContext = React.createContext(defaultContext);

export default FormElementContext;
