import React from 'react';
import PropTypes from 'prop-types';

/**
 * SelectOption is just a wrapper that provides `SelectInput` the `value` for the
 * wrapped component. It should not have any style, or any real DOM wrapped
 * around the component. It soley exists to provide `SelectInput` a consistent
 * interface.
 */
export default function SelectOption(props) {
    const child = React.Children.only(props.children);
    return child;
}

SelectOption.propTypes = {
    value: PropTypes.string,
    children: PropTypes.element.isRequired,
};

SelectOption.defaultProps = {
    value: '',
};
