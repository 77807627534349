import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.span.attrs(({ $url, style }) => {
    return {
        style: {
            ...style,
            backgroundImage: `url(${$url})`,
        },
    };
})`
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    align-self: center;
`;

export default function ImageIcon({ className, style, url }) {
    return (
        <Root
            className={className}
            style={style}
            aria-hidden='true'
            $url={url}
        />
    );
}

ImageIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    url: PropTypes.string.isRequired,
};

ImageIcon.defaultProps = {};
