import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropdownWindow from 'Common/components/Dropdown/DropdownWindow';

import isFunction from 'lodash/isFunction';

import { childrenAreOneOf } from 'Common/util/propTypeHelpers';
import LiveControlSeparator from './LiveControlSeparator';
import LiveControl from './LiveControl';
import LiveControlIcon from './LiveControlIcon';
import LiveControlOverflowMenu from './internal/LiveControlOverflowMenu';
import LiveControlDropdownLayout from './LiveControlDropdownLayout';
import LiveControlDropdownHeader from './LiveControlDropdownHeader';
import { useElidedChildren } from 'Common/components/MenuToolbar/hooks';
import first from 'lodash/first';
import last from 'lodash/last';

const LiveControlBarContainer = styled.div`
    position: relative;
    width: 100%;
`;

const LiveControlButtons = styled.div`
    position: relative;

    text-align: left;
    width: 100%;
`;

const OverflowLiveControlContainer = styled.div`
    display: inline-block;

    flex: 0 0 auto;

    /* In cases of overlap with the visible control container
       we don't want to take click events away from it.
    */
    pointer-events: none;
`;

const VisibleLiveControls = styled.div`
    flex: 0 1 auto;

    white-space: nowrap;
    display: flex;

    /* Needs a min-width to serve as the flex-shrink basis */
    min-width: 44px;
`;

const OverflowLiveControl = styled(LiveControl)`
    display: ${({visible}) => visible ? 'inherit' : 'none'};
    /*
        The parent, OverflowLiveControlContainer, has "pointer-events: none".
        So, re-enable pointer events on the child control.
    */
    pointer-events: auto;
`;

const OVERFLOW_MENU_NAME = 'overflowMenu';

/**
 * A collection of buttons/dropdowns that can only be opened
 * one at a time. Generally used as a toolbar for a widget. Has
 * the ability to handle overflow if the toolbar if too long for the given width.
 */

export default  function LiveControlBar(props) {
    const {
        selection,
        onSelectionChange,
        separatorBeforeOverflow,
        disabled,
        children: childrenProp,
        ...otherProps
    } = props;

    const [anchorElement, setAnchorElement] = useState(null);
    const [selectedBox, setSelectedBox] = useState(null);
    const selectedButtonRef = useRef(null);
    const setBoxSelection = (element) => {
        let newAnchorElement = null;
        let newSelectedBox = null;

        if (element) {
            newAnchorElement = element;
            newSelectedBox = element.getBoundingClientRect();
        }

        setAnchorElement(newAnchorElement);
        setSelectedBox(newSelectedBox);
    };

    const prevSelectionRef = useRef();
    useEffect(() => {
        if (prevSelectionRef.current !== selection || !selectedBox) {
            prevSelectionRef.current = selection;
            if (selection && selectedButtonRef.current) {
                setBoxSelection(selectedButtonRef.current);
            }
            else {
                setBoxSelection(null);
            }
        }
    });

    const onControlClick = ({
        selection: newSelection,
        inOverflow,
        action,
    }) => {
        const updateSelection = (aSelection) => {
            if (selection === aSelection) {
                aSelection = null;
            }

            onSelectionChange(aSelection);
        };

        // This control has an action, and controls with actions cannot be
        // selected.
        //
        if (action) {
            // By default we want to close the overflow menu after
            // an action unless overridden with preventDefault.
            //
            let closeOverflowMenu = true;

            const event = {
                value: newSelection,
                inOverflow,
                preventDefault: () => closeOverflowMenu = false,
            };

            action(event);

            if (inOverflow && closeOverflowMenu) {
                updateSelection(null);
            }
        }
        else {
            updateSelection(newSelection);
        }
    };

    const filterExtraSeparators = (childrenArray) => {
        // Separators at the beginning and end of the control sets can
        // be trimmed away so there aren't dangling separators.
        //
        if (first(childrenArray)?.type === LiveControlSeparator) {
            childrenArray.shift();
        }

        if (last(childrenArray)?.type === LiveControlSeparator) {
            childrenArray.pop();
        }
    };

    const children = React.Children.toArray(childrenProp);
    const totalChildCount = children.length;
    const { containerRef: visibleLiveControlsRef, visibleItems } = useElidedChildren(totalChildCount);
    const currentVisibleItems = visibleItems === null
        ? totalChildCount
        : visibleItems;

    let selectedControlRender = null;
    let selectedIcon = null;
    let selectedDisplayName = null;
    let selectedHandlesDropdownLayout = false;
    let showSelectedControlHeader = true;

    let visibleChildren = [];
    let overflowChildren = [];

    const itemCountThatCanFit = currentVisibleItems;
    children.forEach((child, index) => {
        let newChild = child;

        const {
            neverOverflow = false,
        } = child.props;

        let inOverflow;

        if (neverOverflow) {
            inOverflow = false;
        }
        else {
            if (totalChildCount <= itemCountThatCanFit) {
                inOverflow = false;
            }
            else {
                // index + 1 is the number of children that would be visible if the current child is added
                // if it's greater than the items that can fit then it will need to be in the overflow
                // since the overflow will take up a spot we subtract 1, this means the overflow if it 
                if ((index + 1) >= itemCountThatCanFit - 1) {
                    inOverflow = true;
                }
                else {
                    inOverflow = false;
                }
            }
        }

        if (child.props.name) {
            let clonedChildProps = {
                disabled: disabled || !!child.props.disabled,
                inOverflow,
                key: index,
                showTooltip: !inOverflow,
                onClick: () => onControlClick({
                    selection: child.props.name,
                    inOverflow,
                    action: child.props.action,
                }),
                contentRender: () => child.props.icon,
            };

            if (isFunction(child.props.controlRender)) {
                const selected = selection === child.props.name;
                clonedChildProps.buttonRef = selected && !inOverflow ? selectedButtonRef : null;
                clonedChildProps.selected = selected;

                if (selected) {
                    selectedControlRender = child.props.controlRender;
                    selectedIcon = child.props.icon;
                    selectedDisplayName = child.props.displayName;
                    selectedHandlesDropdownLayout = child.props.handleContentLayout;
                    showSelectedControlHeader = !child.props.suppressControlHeader;
                }
            }
            else {
                clonedChildProps.selected = selection === child.props.name || !!child.props.selected;
            }

            newChild = React.cloneElement(child, clonedChildProps);
        }

        if (inOverflow) {
            overflowChildren.push(newChild);
        }
        else {
            visibleChildren.push(newChild);
        }
    });

    filterExtraSeparators(visibleChildren);
    filterExtraSeparators(overflowChildren);

    const overflowChildOpen = overflowChildren.some((child) => {
        return child.props.selected;
    });

    const overflowMenuOpen = overflowChildOpen || selection === OVERFLOW_MENU_NAME;
    let dropdownContent = null;

    if (overflowMenuOpen) {
        dropdownContent = (
            <LiveControlOverflowMenu
                onSelectionChange={onSelectionChange}
            >
                {overflowChildren}
            </LiveControlOverflowMenu>
        );
    }
    else if (selectedControlRender && selectedIcon) {
        if (!selectedHandlesDropdownLayout) {
            dropdownContent = (
                <LiveControlDropdownLayout
                    header={showSelectedControlHeader && (<LiveControlDropdownHeader displayName={selectedDisplayName} icon={selectedIcon}/>)}
                    body={selectedControlRender()}
                />
            );
        }
        else {
            dropdownContent = selectedControlRender();
        }
    }


    const overflowLiveControl = (
        <OverflowLiveControlContainer
            key='overflow'
        >
            {separatorBeforeOverflow && (
                <LiveControlSeparator />
            )}
            <OverflowLiveControl
                name={OVERFLOW_MENU_NAME}
                selected={overflowMenuOpen}
                visible={overflowChildren.length > 0}
                disabled={disabled || overflowChildren.length === 0}
                buttonRef={overflowMenuOpen ? selectedButtonRef : null}
                onClick={() => onControlClick({
                    selection: OVERFLOW_MENU_NAME,
                    inOverflow: false,
                })}
                contentRender={() => (
                    <LiveControlIcon type='fontAwesome' iconName='ellipsis-h' />
                )}
            />
        </OverflowLiveControlContainer>
    );

    visibleChildren.push(overflowLiveControl);

    return (
        <LiveControlBarContainer {...otherProps}>
            <LiveControlButtons>
                <VisibleLiveControls ref={visibleLiveControlsRef}>
                    {visibleChildren}
                    {selectedBox && dropdownContent && (
                        <DropdownWindow
                            anchorElement={anchorElement}
                            inputBox={selectedBox}
                            onMaskClick={() => onControlClick({
                                selection: null,
                                inOverflow: false,
                            })}
                        >
                            {dropdownContent}
                        </DropdownWindow>
                    )}
                </VisibleLiveControls>
            </LiveControlButtons>
        </LiveControlBarContainer>
    );
}

LiveControlBar.propTypes = {
    children: childrenAreOneOf({
        elementTypes: [
            LiveControlSeparator,
        ],
        propShapes: [
            ['controlRender', 'name', 'displayName'], // for a dropdown
            ['name', 'displayName'] // simple selection
        ],
    }),
    disabled: PropTypes.bool,
    separatorBeforeOverflow: PropTypes.bool,
    onSelectionChange: PropTypes.func.isRequired,
    selection: PropTypes.string,
};

LiveControlBar.defaultProps = {
    disabled: false,
    separatorBeforeOverflow: false,
    selection: null,
};
