import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Body5 } from 'Common/components/typography';

import CheckboxInput from 'Common/components/Checkbox/CheckboxInput';

const StyledSelectOptionWrapper = styled(Body5).attrs({ as: 'div' })`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    color: ${({ theme }) => theme.colors.darkText.highEmphasis};

    transition: background-color 0.3s;

    ${({disabled}) => !disabled && css`
        &:hover {
            background-color: ${({ theme }) => theme.colors.palette.grey.cloud};
        }
    `}

    background-color: ${(props) => {
        if (props.isSelected && !props.isMultiSelect) {
            return props.theme.colors.palette.blue.ice;
        }
        else if (props.isFocused) {
            return props.theme.colors.palette.grey.cloud;
        }
        else {
            return props.theme.colors.palette.white;
        }
    }};

    cursor: pointer;
    padding: 4px ${({ hasSearch }) => hasSearch ? 12 : 8}px;
    border-radius: 4px;

    /* Give mobile users a nice big touch target */
    @media (hover: none) and (pointer: coarse) {
        padding: 13px ${({ hasSearch }) => hasSearch ? 12 : 8}px;
    }

    margin: 0;
    margin-bottom: 1px;
    min-width: 44px;

    ${({disabled}) => disabled && css`
        opacity: 0.34;
        pointer-events: none;
        cursor: default;
    `}
`;

const CheckboxOptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledCheckboxInput = styled(CheckboxInput)`
    margin-right: 5px;
`;

function SelectOptionWrapper({
    isFocused,
    isSelected,
    hasSearch,
    children,
    isMultiSelect,
    value,
    onClick,
    disabled,
}) {
    const handleOnClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!disabled) {
            onClick({ target: { value: value } });
        }
    }, [onClick, value, disabled]);

    return (
        <StyledSelectOptionWrapper
            isSelected={isSelected}
            isFocused={isFocused}
            onClick={handleOnClick}
            hasSearch={hasSearch}
            isMultiSelect={isMultiSelect}
            disabled={disabled}
            role="option"
            aria-selected={isSelected ? 'true' : 'false'}
            // The option below is to make the component focus state testable.
            // In the future, we should actually `focus()` the element so we
            // can test this without a `data-testid`,
            data-testid={isFocused ? 'isFocused' : '' }
        >
            {isMultiSelect
                ? (
                    <CheckboxOptionContainer>
                        <StyledCheckboxInput readonly value={isSelected}/>
                        {children}
                    </CheckboxOptionContainer>
                )
                : children
            }
        </StyledSelectOptionWrapper>
    );
}

SelectOptionWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    isFocused: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    hasSearch: PropTypes.bool,
    isMultiSelect: PropTypes.bool,
    disabled: PropTypes.bool,
};

SelectOptionWrapper.defaultProps = {
    hasSearch: false,
    isMultiSelect: false,
    disabled: false,
};

export default SelectOptionWrapper;
