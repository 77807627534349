import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import SupportRoute from './SupportRoute';
import StrictlyPublicRoute from './StrictlyPublicRoute';
import { Roles } from './roles';
import HomePage from 'Components/pages/home/HomePage';
import InvalidUrlPage from 'Components/pages/invalid-url/InvalidUrlPage';
import CreateAccountPage from 'Components/pages/create-account/CreateAccountPage';
import DevicesPage from 'Components/pages/devices/DevicesPage';
//import SoftwareUpdatesPage from 'Components/pages/software-updates/SoftwareUpdatePage';
import XLConnectPage from 'Components/pages/xlconnect/XLConnectPage';
import AddUserPage from 'Components/pages/add-user/AddUserPage';
import SetupNewUserPage from 'Components/pages/setup-new-user/SetupNewUserPage';
import ResetPasswordPage from 'Components/pages/reset-password/ResetPasswordPage';
import ForgotPasswordPage from 'Components/pages/forgot-password/ForgotPasswordPage';
import EditUserPage from 'Components/pages/edit-user/EditUserPage';
import EditSessionUserPage from 'Components/pages/edit-user/EditSessionUserPage';
import UsersPage from 'Components/pages/users/UsersPage';
import OrganizationPage from 'Components/pages/organization/OrganizationPage';
import EditAlertsPage from 'Components/pages/alerts-edit/EditAlertsPage';
import EmailTemplatesPage from 'Components/pages/email-templates/EmailTemplatesPage';
import MetricThresholdsPage from 'Components/pages/metric-thresholds/MetricThresholdsPage';
import AlertsSubscribePage from 'Components/pages/alerts-subscribe/AlertsSubscribePage';
import VersionPage from 'Components/pages/version/VersionPage';
import PrivacyPage from 'Components/pages/privacy/PrivacyPage';
import CookiePolicyPage from 'Components/pages/cookie-policy/CookiePolicyPage';
import LicensesPage from 'Components/pages/licenses/LicensesPage';
import TermsOfUsePage from 'Components/pages/terms-of-use/TermsOfUsePage';
import { loginReset } from 'Redux/actions';
import EmailsSubscribePage from 'Components/pages/email-subscribe/EmailsSubscribePage';
import MobileGatewaysHelpPage from 'Components/pages/help/MobileGatewaysHelpPage';
import VerifyAccountPage from 'Components/pages/verify/VerifyAccountPage';
import InitializationRequiredRoute from './InitializationRequiredRoute';
import VerifyEmailChangePage from 'Components/pages/verify/VerifyEmailChangePage';
import SupportUsersPage from 'Components/pages/support-users/SupportUsersPage';

class Main extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        loginReset: PropTypes.func.isRequired,
        // Provided by `withRouter`
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        // Detect navigation change
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.loginReset();
        }
    }

    render() {
        const { className } = this.props;

        return (
            <div className={className}>
                <Switch>
                    {/* Non-logged-in user only pages */}
                    <StrictlyPublicRoute path='/' exact redirectToPrevious component={HomePage} />
                    <StrictlyPublicRoute path='/create-organization' redirectToPrevious component={CreateAccountPage} />
                    <StrictlyPublicRoute path='/forgot-password' exact component={ForgotPasswordPage} />

                    {/* Always available public pages */}
                    <Route path='/about/version' component={VersionPage} />
                    <Route path='/about/privacy-policy' component={PrivacyPage} />
                    <Route path='/about/cookie-policy' component={CookiePolicyPage} />
                    <Route path='/about/third-party-licenses' component={LicensesPage} />
                    <Route path='/about/terms-of-use' component={TermsOfUsePage} />

                    {/* Email link only public pages. THESE SHOULD NOT CHANGE, if they do
                        you MUST include a redirect for the original link! */}
                    <Route path='/setup-new-user' component={SetupNewUserPage} />
                    <Route path='/reset-password' component={ResetPasswordPage} />
                    <InitializationRequiredRoute path='/va' component={VerifyAccountPage} />
                    <InitializationRequiredRoute path='/vc' component={VerifyEmailChangePage} />

                    {/* XL Linking pages. THESE SHOULD NOT CHANGE, if they do
                        you MUST include a redirect for the original link! */}
                    <PrivateRoute path='/confirm-device' minRole={Roles.ADMIN} component={XLConnectPage} />

                    {/* Subscription pages */}
                    <PrivateRoute path='/subscriptions/alerts' component={AlertsSubscribePage} />
                    <PrivateRoute path='/subscriptions/reports' component={EmailsSubscribePage} />
                    <PrivateRoute path='/subscriptions/assets'>
                        {/* New email reports have their own mechanism for choosing assets,
                            so asset subs have been combined with alerts */}
                        <Redirect to='/subscriptions/alerts' />
                    </PrivateRoute>

                    {/* General user config pages */}
                    <PrivateRoute path='/config/user/profile' component={EditSessionUserPage} />
                    {/* 2.10 XL devices link to /devices, make sure that URL continues to work */}
                    <PrivateRoute path='/devices'>
                        <Redirect to='/config/devices/all-assets' />
                    </PrivateRoute>

                    {/* Admin config pages */}
                    <PrivateRoute path='/config/plant-floor/alert-definitions' minRole={Roles.ADMIN} component={EditAlertsPage} />
                    <PrivateRoute path='/config/plant-floor/email-templates/:pageId?' minRole={Roles.ADMIN} component={EmailTemplatesPage} />
                    <PrivateRoute path='/config/plant-floor/metric-thresholds' minRole={Roles.ADMIN} component={MetricThresholdsPage} />
                    <PrivateRoute path='/config/organization/users' exact minRole={Roles.ADMIN} component={UsersPage} />
                    <PrivateRoute path='/config/organization/users/:user' minRole={Roles.ADMIN} component={EditUserPage} />
                    <PrivateRoute path='/config/organization/add-user' minRole={Roles.ADMIN} component={AddUserPage} />
                    <PrivateRoute path='/config/organization/settings' minRole={Roles.ADMIN} component={OrganizationPage} />
                    <PrivateRoute path='/config/devices/all-assets' minRole={Roles.MEMBER} component={DevicesPage} />
                    {/*<PrivateRoute path='/config/devices/software-updates' minRole={Roles.MEMBER} component={SoftwareUpdatesPage} />*/}

                    {/* Private help pages */}
                    <PrivateRoute path='/help/mobile-gateways' component={MobileGatewaysHelpPage} />

                    <SupportRoute path='/support/all-users' minRole={Roles.MEMBER} component={SupportUsersPage} />

                    {/* 404 */}
                    <Route path='*' component={InvalidUrlPage} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = {
    loginReset: loginReset,
};

// connect the component to Redux
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Main));
