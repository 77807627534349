export const TABLE_MIN_WIDTH = 320;

export const COLUMN_PADDING = 8;
export const SORT_ARROW_WIDTH_ALLOWANCE = 20;
export const EXPANDABLE_COLUMN_INDENT_PER_DEPTH = 14;
export const EXPANDABLE_COLUMN_INDENT_PER_DEPTH_PRINT = 8;
export const COLUMN_LEFT_INDENT_PER_DEPTH = 0;
export const COLUMN_RIGHT_INDENT_PER_DEPTH = 0;
export const COLUMN_BORDER_WIDTH = 1;
export const MAX_METRIC_PADDING = 8;
export const MIN_COLUMN_WIDTH = 50;
export const DEFAULT_COLUMN_WIDTH = 150;
export const MAX_COLUMN_WIDTH = 400;

export const STANDARD_HEADER_ROW_HEIGHT = 32;
export const SECONDARY_HEADER_ROW_HEIGHT = 24;
export const MINIMUM_ROW_HEIGHT = 24;
export const ADDITIONAL_ROW_HEIGHT_UNIT = 19;
export const STANDARD_FOOTER_HEIGHT = 12;

// This is the proportion of the container width at which the table attempts to force
// itself to be full width by expanding columns beyond their preferred width.
export const FORCE_FULL_WIDTH_PROPORTION = 0.8;

// These are the offsets of the containerWidth to prevent overflow when resizing columns
export const CONTAINER_FULL_WIDTH_ADJUSTMENT = 0.96;
export const CONTAINER_FORCE_FULL_WIDTH_PROPORTION_ADJUSTMENT = 0.97;
