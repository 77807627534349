import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.span`
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;

    & > svg {
        width: 1em;
        height: 1em;
        fill: currentColor;
    }
`;

function SvgIcon(props) {
    const svg = React.Children.only(props.children);

    return (
        <Root {...props}>
            {svg}
        </Root>
    );
}

SvgIcon.propTypes = {
    children: PropTypes.node,
};

export default SvgIcon;
