import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { setAtPath } from 'Common/util/FormUtils';
import cancelablePromise from 'Components/util/cancelablePromise';

export class FormController extends React.Component {
    static propTypes = {
        initialValue: PropTypes.object.isRequired,
        initialTouched: PropTypes.object,
        validate: PropTypes.func,
        detectChanges: PropTypes.func,
        // If needed, use this function to make any extra changes to the value
        // on each update. It is useful if you need to make changes to one field
        // based on the value of another.
        transformValue: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        onReset: PropTypes.func,
        render: PropTypes.func.isRequired,
        // If true, updates to the initialValue prop will be carried over
        // to the modified value when there are no pending changes. Useful for
        // periodically refreshing data when a user is not editing.
        updateValueIfNoChanges: PropTypes.bool,
    };

    static defaultProps = {
        initialTouched: {},
        validate: () => null,
        detectChanges: (value, initialValue) => !isEqual(value, initialValue),
        transformValue: (value) => value,
        onSubmit: () => {},
        onReset: () => {},
        updateValueIfNoChanges: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            initialValue: props.initialValue,
            value: props.initialValue,
            prevValue: props.initialValue,
            touched: props.initialTouched,
            hasChanges: false,
            isSubmitting: false,
            submitError: null,
        };

        this._pendingSubmit = null;
    }

    static getDerivedStateFromProps(props, state) {
        const derivedState = {};

        // When the value changes, update `hasChanges`
        if (state.value !== state.prevValue) {
            derivedState.prevValue = state.value;
            derivedState.hasChanges = props.detectChanges(state.value, state.initialValue);
        }

        const hasChanges = derivedState.hasChanges !== undefined ?
            derivedState.hasChanges : state.hasChanges;

        // If there are no changes and `updateValueIfNoChanges` is set,
        // carry over changes from `initialValue` into `value`.
        if (
            !hasChanges &&
            props.updateValueIfNoChanges &&
            props.initialValue !== state.initialValue // `initialValue` changed
        ) {
            derivedState.initialValue = props.initialValue;
            derivedState.value = props.initialValue;
        }

        if (Object.keys(derivedState).length) {
            return derivedState;
        }
        else {
            return null;
        }
    }

    componentWillUnmount() {
        // Cancel any pending submit
        if (this._pendingSubmit) {
            this._pendingSubmit.cancel();
            this._pendingSubmit = null;
        }
    }

    _getInterface() {
        const {
            initialValue,
            value,
            touched,
            hasChanges,
            isSubmitting,
            submitError,
        } = this.state;

        return {
            // state
            initialValue: initialValue,
            value: value,
            touched: touched,
            hasChanges: hasChanges,
            isSubmitting: isSubmitting,
            submitError: submitError,
            // setters
            resetValue: this._resetValue,
            setTouched: this._setTouched,
            setIsSubmitting: this._setIsSubmitting,
            setSubmitError: this._setSubmitError,
            // handlers
            handleSubmit: this._handleSubmit,
            handleReset: this._handleReset,
            handleChange: this._handleChange,
            handleBlur: this._handleBlur,
        };
    }

    _resetValue = () => {
        const { initialValue } = this.props;

        this.setState({
            initialValue: initialValue,
            value: initialValue,
        });
    };

    _resetTouched = () => {
        const { initialTouched } = this.props;

        this.setState({
            touched: initialTouched,
        });
    };

    _setTouched = (touched) => {
        this.setState({ touched });
    };

    _setIsSubmitting = (isSubmitting) => {
        this.setState({ isSubmitting });
    };

    _setSubmitError = (submitError) => {
        this.setState({ submitError });
    };

    _handleSubmit = async (event) => {
        // block double submits
        if (this._pendingSubmit) {
            return;
        }

        const { value } = this.state;

        this._setIsSubmitting(true);
        this._setSubmitError(null);

        this._pendingSubmit = cancelablePromise(this.props.onSubmit(value, this._getInterface()));

        try {
            await this._pendingSubmit.promise;
            this._pendingSubmit = null;
            this._setIsSubmitting(false);
        }
        catch (err) {
            this._pendingSubmit = null;

            if (!err.isCanceled) {
                this._setIsSubmitting(false);
            }
        }
    };

    _handleReset = (event) => {
        this._resetValue();
        this._resetTouched();
        this._setSubmitError(null);
        this.props.onReset();
    };

    _handleChange = (event) => {
        const { transformValue } = this.props;
        const { name, value } = event.target;

        this.setState((state) => {
            return {
                value: transformValue(setAtPath(state.value, name, value)),
                touched: setAtPath(state.touched, name, true),
            };
        });
    };

    _handleBlur = (event) => {
        const { name } = event.target;

        this.setState((state) => {
            return {
                touched: setAtPath(state.touched, name, true),
            };
        });
    };

    render() {
        const { render, validate } = this.props;
        const { value } = this.state;

        return render({
            ...this._getInterface(),
            validation: validate(value),
        });
    }
}
