import React from 'react';
import styled from 'styled-components';

import { elementsOfType } from 'Components/util/propTypesUtils';
import TableHeaderColumn from 'Components/table/TableHeaderColumn';

const ThemedTableHeaderRow = styled.tr`

`;

function TableHeaderRow({ children, ...otherProps }) {
    return (
        <ThemedTableHeaderRow {...otherProps} >
            {children}
        </ThemedTableHeaderRow>
    );
}

TableHeaderRow.propTypes = {
    children: elementsOfType([
        TableHeaderColumn
    ]),
};

export default TableHeaderRow;
