import validateMetricAlert from './MetricAlertValidation';
import validateProcessStateDurationAlert from './ProcessStateDurationAlertValidation';
import validateProcessStateTargetTimeAlert from './ProcessStateTargetTimeAlertValidation';

export default function validate(alerts) {
    let validation = {
        metric: alerts.metric.map(validateMetricAlert),
        process_state_duration: alerts.process_state_duration.map(validateProcessStateDurationAlert),
        process_state_target_time: alerts.process_state_target_time.map(validateProcessStateTargetTimeAlert),
    };

    return validation;
}
