import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TextInput from 'Components/text-input/TextInput';
import ContainedButton from 'Components/button/ContainedButton';
import { Spacing } from 'Components/common/common';

const Container = styled.div`
    display: flex;
`;

const StyledTextInput = styled(TextInput)`
    display: inline-block;
    width: 100%;
`;

const ToggleMaskingButton = styled(ContainedButton)`
    display: inline-block;
    word-break: keep-all;
    vertical-align: top;
    height: 30px;
    margin-top: 24px;
    margin-left: ${Spacing.inlineButtonMargin};
`;

export default class UnmaskablePasswordInput extends React.Component {
    constructor() {
        super();
        this._toggleMasking = this._toggleMasking.bind(this);

        this._showText = 'Show';
        this._hideText = 'Hide';

        this.state = {
            inputType: 'password',
        };
    }

    _toggleMasking(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState(prevState => ({
            inputType: prevState.inputType === 'input' ? 'password' : 'input'
        }));
    }

    render() {
        const {
            autoComplete,
            disabled,
            error,
            helperText,
            id,
            label,
            name,
            onChange,
            onBlur,
            required,
            value,
        } = this.props;
        const { inputType } = this.state;
        return (
            <Container>
                <StyledTextInput
                    id={id}
                    label={label}
                    name={name}
                    type={inputType}
                    disabled={disabled}
                    required={required}
                    error={!!error}
                    helperText={helperText}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    autoComplete={autoComplete}
                />
                <ToggleMaskingButton
                    color='secondary'
                    disabled={disabled}
                    onClick={this._toggleMasking}
                    type='button'
                >
                    {inputType === 'input' ? this._hideText : this._showText}
                </ToggleMaskingButton>
            </Container>
        );
    }
}

UnmaskablePasswordInput.defaultProps = {
    autoComplete: '',
    disabled: false,
    error: false,
    helperText: undefined,
    id: 'password',
    label: 'Password',
    name: 'password',
    required: false,
    value: '',
};

UnmaskablePasswordInput.propTypes = {
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string,
};
