/**
 * @typedef {Object} CancelablePromise
 * @property {Promise} promise - The X Coordinate
 * @property {Function} cancel - Cancel the promise. Immediately rejects the
 *      promise with `{ isCanceled: true }`
 */

/**
 * Wrap a Promise so that it can be canceled later. It returns an object
 * containing the wrapped promise and a cancel function. If cancel is called
 * before the promise is completed, it is immediately rejected with an object
 * of `{ isCanceled: true }`.
 *
 * It is important to note that you can't really "cancel" a promise. The best
 * you can do is ignore the results. Essentially, that is what this cancelable
 * promise does.
 *
 * @param {Promise} promise
 * @returns {CancelablePromise}
 */
export default function cancelablePromise(promise) {
    let isDone = false;
    let resolvePromise;
    let rejectPromise;

    const wrappedPromise = new Promise((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
    });

    Promise.resolve(promise)
        .then((result) => {
            if (!isDone) {
                isDone = true;
                resolvePromise(result);
            }
        })
        .catch((err) => {
            if (!isDone) {
                isDone = true;
                rejectPromise(err);
            }
        });

    return {
        promise: wrappedPromise,
        cancel: () => {
            if (!isDone) {
                isDone = true;
                rejectPromise({ isCanceled: true });
            }
        },
    };
}
