import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chip from './Chip';
import Icon from 'Common/components/Icon';
import { Body5 } from 'Common/components/typography';
import { dragDropCursor } from 'Common/util/cssSnippets';

const DragHandleContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    ${dragDropCursor}
    user-select: none;
`;

const TextContainer = styled.div`
    flex: 1 1 auto;
    box-sizing: border-box;
    min-width: 0; /* Allows text to elide */
    user-select: none;
`;

const TextContent = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default function DraggableChip(props) {
    const { text, ...otherProps } = props;

    return (
        <Chip
            {...otherProps}
            leftContent={(
                <DragHandleContainer>
                    <Icon type="vorne" iconName="drag-handles-square" />
                </DragHandleContainer>
            )}
        >
            <TextContainer>
                <TextContent>
                    <Body5 as="span">{text}</Body5>
                </TextContent>
            </TextContainer>
        </Chip>
    );
}

DraggableChip.propTypes = {
    text: PropTypes.node.isRequired,
};
