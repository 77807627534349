import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import styled from 'styled-components';
import theme from 'Common/theme/default';

const SparkLineContainer = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
`;

const defaultChartOptions = {
    chart: {
        backgroundColor: null,
        borderWidth: 0,
        type: 'area',
        margin: [0, 0, 0, 0],
        width: null,
        height: null,
        // small optimization for sparklines
        skipClone: true,
    },
    title: {
        text: ''
    },
    credits: {
        enabled: false
    },
    xAxis: {
        visible: false
    },
    yAxis: {
        visible: false
    },
    legend: {
        enabled: false
    },
    tooltip: {
        enabled: false,
    },
    plotOptions: {
        series: {
            animation: false,
            states: {
                hover: {
                    enabled: false,
                }
            },
            marker: {
                enabled: false,
            },
            fillOpacity: 1,
        }
    }
};

function createSparkline({ renderTo, data, lineWidth, fillColor, lineColor }) {
    let chartOptions = {
        chart: {
            renderTo,
        },
        series: [{
            data,
        }],
        plotOptions: {
            series: {
                lineWidth,
                fillColor,
                lineColor,
            },
        },
    };

    chartOptions = Highcharts.merge(defaultChartOptions, chartOptions);

    return new Highcharts.Chart(chartOptions);
}

function SparkLine({ data, lineWidth, fillColor, lineColor, ...otherProps }) {
    const sparkLineContainerEl = useRef(null);

    // Called on any prop and lifecycle changes
    useEffect(() => {
        const el = sparkLineContainerEl.current;

        if (el) {
            createSparkline({
                renderTo: el,
                data,
                lineWidth,
                fillColor,
                lineColor,
            });
        }
    });

    return (
        <SparkLineContainer {...otherProps} ref={sparkLineContainerEl} />
    );
}

// Use memo to prevent re-renders when the props haven't changed.
//
export default React.memo(SparkLine);

SparkLine.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.number.isRequired
        )
    ),
    lineWidth: PropTypes.number,
    fillColor: PropTypes.string,
    lineColor: PropTypes.string,
};

SparkLine.defaultProps = {
    data: [],
    lineWidth: 1,
    fillColor: theme.colors.palette.blue.cruise,
    lineColor: theme.colors.palette.blue.cruise,
};
