import { sendGetRequest } from 'Http/request';
import { getResources, resetResourcesRequest } from 'Common/util/ResourceCollection';

export function getUsers() {
    return getResources({
        resourceType: 'users',
        requestId: 'getUsers',
        sendRequest: () => sendGetRequest('/api/v1/accounts/organization/users'),
    });
}

export function getUsersReset() {
    return resetResourcesRequest({
        resourceType: 'users',
        requestId: 'getUsers',
    });
}

export default getUsers;
