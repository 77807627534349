import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import VorneIcon from 'Components/vorne-icon/VorneIcon';
import { DisplayMode } from 'Common/util/DisplayMode';

const BigLogo = styled(VorneIcon).attrs({ i: 'xl-vorne-logo' })``;
const SmallLogo = styled(VorneIcon).attrs({ i: 'xl-logo' })``;

const Root = styled.span`
    display: inline-flex;
    font-size: 28px;
    color: ${props => props.theme.colors.palette.blue.awesome};

    ${({ $displayMode }) => $displayMode === DisplayMode.MOBILE && css`
        padding: 0 10px;

        ${BigLogo} {
            display: none;
        }

        ${SmallLogo} {
            display: inline;
        }
    `}

    ${({ $displayMode }) => $displayMode !== DisplayMode.MOBILE && css`
        padding: 0 16px;

        ${BigLogo} {
            display: inline;
        }

        ${SmallLogo} {
            display: none;
        }
    `}
`;

export default function HeaderLogoIcon({ displayMode, ...otherProps }) {
    return (
        <Root
            {...otherProps}
            $displayMode={displayMode}
        >
            <BigLogo />
            <SmallLogo />
        </Root>
    );
}

HeaderLogoIcon.propTypes = {
    displayMode: PropTypes.string.isRequired,
};
