import { connect } from 'react-redux';
import { login, logout } from 'Redux/actions';
import LoginLogoutForm from './LoginLogoutForm';

const mapStateToProps = state => {
    const { session } = state;
    return { session };
};

const mapDispatchToProps = {
    onLoginSubmit: login,
    onLogoutSubmit: logout,
};

// connect the component to Redux
const LoginFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginLogoutForm);

export default LoginFormContainer;
