import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getHierarchy, putHierarchy } from 'Redux/actions';

import Page from 'Components/page/Page';
import { Roles } from 'Components/app/roles';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import PageContent from 'Components/page/PageContentStandard';
import PageDescription from 'Components/page/PageDescription';
import { selectHierarchyRequest } from 'Redux/reducers/hierarchy';
import { EnterpriseHierarchyForm } from './EnterpriseHierarchyForm';
import { selectSessionUser } from 'Redux/reducers/session';
import { bindActionCreators } from 'redux';
import LoadingWrapper from 'Components/loading-indicator/LoadingWrapper';
import { REQUEST_FAILURE } from 'Common/util/ResourceCollection/actions';

export class DevicesPagePresentation extends React.Component {
    static propTypes = {
        requests: PropTypes.shape({
            getHierarchy: PropTypes.object.isRequired,
            putHierarchy: PropTypes.object.isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            getHierarchy: PropTypes.func.isRequired,
            putHierarchy: PropTypes.func.isRequired,
            setHierarchyReset: PropTypes.func.isRequired,
        }).isRequired,
        hierarchyStore: PropTypes.object,
        sessionRole: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            initialRequestFinished: false,
            initialRequestErrorMessage: null,
        };
    }

    componentDidMount() {
        this._loadData();
        // start pinging the devices endpoint to check for device updates
        this.interval = setInterval(this._loadData.bind(this), 10e3);
    }

    componentWillUnmount() {
        // stop the device pinging
        clearInterval(this.interval);
    }

    async _loadData() {
        const responseAction = await this.props.actions.getHierarchy();

        let errorMessage = null;

        if (responseAction.type === REQUEST_FAILURE) {
            errorMessage = responseAction.payload?.error?.message;
        }

        this.setState((state) => {
            const updates = {};

            if (!state.initialRequestFinished) {
                updates.initialRequestErrorMessage = errorMessage;
                updates.initialRequestFinished = true;
            }

            return updates;
        });
    }

    render() {
        const { requests, actions, hierarchyStore, sessionRole } = this.props;
        const { initialRequestFinished, initialRequestErrorMessage } = this.state;

        const readOnly = sessionRole !== Roles.ADMIN;

        return (
            <Page>
                <ConfigPageTitle>Enterprise Hierarchy</ConfigPageTitle>
                <PageDescription>
                    Use this page to view and manage all XL devices that are currently linked to XL Enterprise.
                    Linking is initiated by logging into a device and navigating to
                    Settings&nbsp;&gt;&nbsp;Connections&nbsp;&gt;&nbsp;XL&nbsp;Enterprise.
                    Devices can only be unlinked by an XL Enterprise Administrator.
                </PageDescription>
                <PageDescription>
                    Hierarchy Visibility (below) for each XL device determines which work centers
                    are visible from the associated XL device.
                    Since each XL device has its own IP address and web interface, to restrict
                    access to an XL device on your local network, your IT department will need to
                    control access to those IP addresses.
                </PageDescription>
                <PageContent>
                    <LoadingWrapper
                        loading={!initialRequestFinished}
                        error={initialRequestErrorMessage}
                        render={() => {
                            return (
                                <EnterpriseHierarchyForm
                                    hierarchyStore={hierarchyStore}
                                    readOnly={readOnly}
                                    requests={{
                                        putHierarchy: requests.putHierarchy,
                                    }}
                                    actions={{
                                        setHierarchyReset: actions.setHierarchyReset,
                                        putHierarchy: actions.putHierarchy,
                                    }}
                                />
                            );
                        }}
                    />
                </PageContent>
            </Page>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { session, hierarchy } = state;

    return {
        sessionRole: selectSessionUser(session)?.role,
        hierarchyStore: hierarchy,
        requests: {
            getHierarchy: selectHierarchyRequest(hierarchy, 'getHierarchy'),
            putHierarchy: selectHierarchyRequest(hierarchy, 'putHierarchy'),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getHierarchy: getHierarchy,
            putHierarchy: putHierarchy,
            setHierarchyReset: () => { return { type: '____NOTHING' }; },
        }, dispatch),
    };
};

// connect the component to Redux
const DevicesPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DevicesPagePresentation);

export default DevicesPageContainer;
