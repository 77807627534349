import React from 'react';
import PropTypes from 'prop-types';
import { anyOf, elementsOfType } from 'Components/util/propTypesUtils';
import styled from 'styled-components';
import ButtonLike from './ButtonLike';
import ButtonText from './ButtonText';

const Root = styled(ButtonLike)`
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;

    border: none;
    border-radius: 3px;
    padding: 0 16px;
    min-height: 30px;
    height: 30px;

    &[disabled] {
        opacity: 0.2;
    }
`;

export default function Button(props) {
    const {
        children: childrenProp,
        ...otherProps
    } = props;

    let children = React.Children.toArray(childrenProp);

    // if the only child is just a string, wrap it in the proper element
    if (children.length === 1 && typeof children[0] === 'string') {
        children = <ButtonText>{children}</ButtonText>;
    }

    return (
        <Root {...otherProps}>{children}</Root>
    );
}

Button.defaultProps = {
    children: undefined,
    type: 'button',
};

Button.propTypes = {
    children: anyOf([
        PropTypes.string,
        elementsOfType([ ButtonText ]),
    ]),
    type: PropTypes.string,
};
