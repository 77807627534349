import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import useBounds from 'Common/hooks/useBounds';

import Table from './Table';

const Container = styled.div`
    display: flex;
    width: 100%;
`;

/**
 * A wrapper around <Table> that watches for changes in the available width and
 * passes it to the Table.
 *
 * This wapper also blocks rerenders of the Table during column resizing.
 *
 * @see Table
 */
function WidthAdjustableTable(props) {
    const [container, setContainer] = useState(null);

    const bounds = useBounds(container);

    const [ isResizing, setIsResizing ] = useState(false);

    const handleColumnResizeStart = useCallback(() => {
        setIsResizing(true);
    }, []);

    const handleColumnResizeEnd = useCallback(() => {
        setIsResizing(false);
    }, []);

    const lastTablePropsRef = useRef(props);
    const lastTableBounds = useRef(bounds);

    // Only allow the props to change if a column is not being resized.
    // Otherwise, keep reusing the same props until the resize is done.
    if (!isResizing) {
        lastTablePropsRef.current = props;
        lastTableBounds.current = bounds;
    }

    return (
        <Container ref={setContainer}>
            <Table
                {...lastTablePropsRef.current}
                containerWidth={lastTableBounds.current?.width}
                onColumnResizeStart={handleColumnResizeStart}
                onColumnResizeEnd={handleColumnResizeEnd}
            />
        </Container>
    );
}

export default WidthAdjustableTable;
