import { combineReducers } from 'redux';
import actionFilter from 'Common/util/reducerUtils/actionFilter';
import actionMap from 'Common/util/reducerUtils/actionMap';

import { requestReducer } from 'Common/util/ResourceCollection';
import { REQUEST_PENDING, REQUEST_FAILURE, REQUEST_SUCCESS } from 'Common/util/ResourceCollection/actions';

function multiCompare(compareResults) {
    for (let i = 0; i < compareResults.length; i++) {
        if (compareResults[i] !== 0) {
            return compareResults[i];
        }
    }

    return 0;
}

const sortAlerts = alertType => (alertA, alertB) => {
    switch (alertType) {
        case 'metric': {
            const comparisonOrder = ['less_than', 'greater_than'];

            return multiCompare([
                alertA.metric.localeCompare(alertB.metric),
                comparisonOrder.indexOf(alertA.comparison) - comparisonOrder.indexOf(alertB.comparison),
                alertA.threshold - alertB.threshold
            ]);
        }
        case 'process_state_duration': {
            return multiCompare([
                alertA.process_state.localeCompare(alertB.process_state),
                alertA.threshold - alertB.threshold
            ]);
        }
        case 'process_state_target_time': {
            const comparisonOrder = ['before_target', 'over_target'];

            return multiCompare([
                comparisonOrder.indexOf(alertA.comparison) - comparisonOrder.indexOf(alertB.comparison),
                alertA.threshold - alertB.threshold
            ]);
        }
        default:
            throw new Error(`Unable to sort unkown alert type "${alertType}"`);
    }
};

const initialDataState = {
    metric: [],
    process_state_duration: [],
    process_state_target_time: [],
};

const dataReducer = (state = initialDataState, action) => {
    const {
        type,
        requestMethod,
        payload,
    } = action;

    if (requestMethod === 'GET') {
        switch (type) {
            case REQUEST_PENDING:
                return initialDataState;
            case REQUEST_FAILURE:
                return initialDataState;
            case REQUEST_SUCCESS: {
                const sorted = {};

                Object.keys(payload).forEach((alertType) => {
                    sorted[alertType] = [ ...(payload[alertType]) ].sort(sortAlerts(alertType));
                });

                return sorted;
            }
            default:
                return state;
        }
    }
    else if (requestMethod === 'PATCH') {
        switch (type) {
            case REQUEST_SUCCESS: {
                const sorted = {};

                Object.keys(payload).forEach((alertType) => {
                    sorted[alertType] = [ ...(payload[alertType]) ].sort(sortAlerts(alertType));
                });

                return sorted;
            }
            default:
                return state;
        }
    }
    else {
        return state;
    }
};

const alertsReducer = actionFilter(
    (action) => action?.resourceType === 'alerts',
    combineReducers({
        data: dataReducer,
        requests: actionMap(
            (action) => action?.requestId,
            {
                getAlerts: requestReducer,
                patchAlerts: requestReducer,
            }
        ),
    })
);

export default alertsReducer;

export function selectAlerts(state) {
    return state.data;
}

export function selectAlertsRequest(state, requestId) {
    const request = state.requests[requestId];

    if (!request) {
        throw new Error(`Unknown alerts request with requestId "${requestId}"`);
    }

    return request;
}
