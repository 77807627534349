import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ResendWelcomeButton from './ResendWelcomeButton';
import RoleSelect from 'Components/role-select/RoleSelect';
import TextInput from 'Components/text-input/TextInput';
import PageFrame from 'Components/page/PageFrame';
import CheckboxFormElement from 'Components/checkbox/CheckboxFormElement';
import WarningNotice from 'Components/notice/WarningNotice';
import { createPath, setAtPath } from 'Common/util/FormUtils';
import EnterpriseHierarchySelect from 'Components/enterprise-hierarchy-select/EnterpriseHierarchySelect';
import { Roles } from 'Components/app/roles';
import ThemedTable from 'Components/table/Table';
import ThemedTableBody from 'Components/table/TableBody';
import TableRow from 'Components/table/TableRow';
import TableColumn from 'Components/table/TableColumn';
import TableHeaderColumn from 'Components/table/TableHeaderColumn';

const Root = styled.div`
    word-break: break-word;
`;

const StyledTextInput = styled(TextInput)`
    display: block;
`;

const StyledResendWelcomeButton = styled(ResendWelcomeButton)`
    margin-top: 16px;
`;

function getDateString(dateTime) {
    if (!dateTime) {
        return '--';
    }

    return (new Date(dateTime)).toDateString();
}

export default class UserEditor extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.shape({
            id: PropTypes.number.isRequired,
            email_address: PropTypes.shape({
                email: PropTypes.string.isRequired,
                verified: PropTypes.bool.isRequired,
            }).isRequired,
            // sms_email_address can be null
            sms_email_address: PropTypes.shape({
                email: PropTypes.string.isRequired,
            }),
            name: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            hierarchy_visibility: PropTypes.number,
            active: PropTypes.bool.isRequired,
            created: PropTypes.string.isRequired,
            last_login: PropTypes.string,
        }).isRequired,
        validation: PropTypes.object,
        disabled: PropTypes.bool,
        allowDeactivate: PropTypes.bool,
        enterpriseHierarchy: PropTypes.shape({
            rootId: PropTypes.string.isRequired,
        }).isRequired,

        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
    };

    static defaultProps = {
        name: '',
        validation: null,
        disabled: false,
        allowDeactivate: true,
    };

    constructor() {
        super();
    }

    _handleChange = (event) => {
        this.props.onChange(event);
    };

    _handleRoleChange = (event) => {
        const {
            name: nameProp,
            value: currentValue,
            enterpriseHierarchy,
        } = this.props;

        const { value: newRoleValue } = event.target;

        // If the role is changed to ADMIN, we also need to update the
        // visibility at the same time.
        if (newRoleValue === Roles.ADMIN) {
            let newValue = currentValue;
            // Update role
            newValue = setAtPath(newValue, 'role', newRoleValue);
            // Update visibility
            newValue = setAtPath(newValue, 'hierarchy_visibility', Number(enterpriseHierarchy.rootId));

            this._handleChange({
                target: {
                    name: nameProp,
                    value: newValue,
                },
            });
        }
        else {
            // Normal onChange behavior
            this._handleChange(event);
        }
    };

    _handleHierarchyVisibilityChange = (event) => {
        // The hierarchy visibility editor uses strings for node IDs. So we
        // need to convert them back to numbers on change.
        const { name, value } = event.target;

        this._handleChange({
            target: {
                name,
                value: value === null ? null : Number(value),
            },
        });
    };

    render() {
        const {
            value,
            validation,
            disabled,
            allowDeactivate,
            name: nameProp,
            enterpriseHierarchy,
            onChange,
            onBlur,
            ...otherProps
        } = this.props;

        const {
            id,
            email_address,
            sms_email_address,
            name,
            role,
            hierarchy_visibility,
            active,
            created,
            last_login,
        } = value;

        const roleElementId = `UserEditor_${id}_role`;

        return (
            <Root {...otherProps}>
                <PageFrame title="Name and Access">
                    {!email_address.verified && (
                        <React.Fragment>
                            <WarningNotice>
                                This user has not finished setting up their account. The account will
                                not be active until they complete the instructions in the setup email.
                            </WarningNotice>
                            <StyledResendWelcomeButton
                                userId={id}
                                email={email_address.email}
                            />
                        </React.Fragment>
                    )}
                    <StyledTextInput
                        label="Name"
                        value={name}
                        disabled={disabled}
                        name={createPath(nameProp, 'name')}
                        error={!!validation?.name}
                        helperText={validation?.name || ''}
                        onChange={this._handleChange}
                        onBlur={onBlur}
                    />
                    <RoleSelect
                        id={roleElementId}
                        label="Role"
                        value={role}
                        disabled={disabled}
                        name={createPath(nameProp, 'role')}
                        error={!!validation?.role}
                        helperText={validation?.role || ''}
                        onChange={this._handleRoleChange}
                        onBlur={onBlur}
                    />
                    <EnterpriseHierarchySelect
                        label="Hierarchy Visibility"
                        value={hierarchy_visibility === null ?
                            null : String(hierarchy_visibility)}
                        disabled={disabled || role === Roles.ADMIN}
                        name={createPath(nameProp, 'hierarchy_visibility')}
                        error={validation?.hierarchy_visibility}
                        onChange={this._handleHierarchyVisibilityChange}
                        enterpriseHierarchy={enterpriseHierarchy}
                        helperText=""
                    />
                    <CheckboxFormElement
                        name={createPath(nameProp, 'active')}
                        label="Enabled"
                        value={active}
                        onChange={this._handleChange}
                        onBlur={onBlur}
                        disabled={disabled || !allowDeactivate}
                    />
                </PageFrame>

                <PageFrame title="Reference Information">
                    <ThemedTable noFooter={true} >
                        <ThemedTableBody>
                            <TableRow>
                                <TableHeaderColumn>Primary Email</TableHeaderColumn>
                                <TableColumn>{email_address?.email}</TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableHeaderColumn>Text-Only Email</TableHeaderColumn>
                                <TableColumn>{sms_email_address?.email || '--'}</TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableHeaderColumn>User Creation</TableHeaderColumn>
                                <TableColumn>{getDateString(created)}</TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableHeaderColumn>Last Login</TableHeaderColumn>
                                <TableColumn>{getDateString(last_login)}</TableColumn>
                            </TableRow>
                        </ThemedTableBody>
                    </ThemedTable>
                </PageFrame>
            </Root>
        );
    }
}
