import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import FieldBuilderSelect from 'Common/components/FieldBuilder/FieldBuilderSelect';
import FieldSelectControl from 'Common/components/LiveControls/FieldSelectControl';
import DurationField from 'Common/components/DurationField';
import NumberField from 'Common/components/NumberField';
import BasicSelectField from 'Common/components/BasicSelectField/BasicSelectField';
import ButtonBase from 'Common/components/Button/ButtonBase';
import Dropdown from 'Common/components/Dropdown/Dropdown';
import SelectDropdownButton from 'Common/components/SelectList/SelectDropdownButton';
import Icon from 'Common/components/Icon';
import { useTranslator } from 'Common/hooks/useTranslation';

const RowContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 3px 0;
    @media (hover: none) and (pointer: coarse) {
        padding: 10px 0;
    }
`;

export const ExpressionInfoContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    min-width: 1px;
`;

export const FieldContainer = styled.div`
    flex: 0 1 39%;
    display: flex;
    flex-direction: column;
    min-width: 44px;
    padding: 0px 3px;
`;

export const OperatorContainer = styled.div`
    flex: 0 .5 22%;
    display: flex;
    flex-direction: column;
    min-width: 44px;
    padding: 0px 3px;
`;

const DeleteButtonWidthStyle = css`
    width: 24px;
    height: 24px;
    @media (hover: none) and (pointer: coarse) {
        width: 44px;
    }
`;

export const DeleteButtonContainer = styled.div`
    flex: 0 0 auto;
    ${DeleteButtonWidthStyle}
`;

const DeleteButton = styled(ButtonBase)`
    flex: 0 0 auto;
    ${DeleteButtonWidthStyle}
    display: flex;
    justify-content: center;
    align-items: center;

    /* Touch Target */
    :before {
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 300ms;
    }

    :hover {
        :before {
            background-color: rgba(0,0,0,0.05);
        }
    }

    :active {
        :before {
            background-color: rgba(0,0,0,0.1);
        }
    }

    :not(:hover):focus {
        :before {
            background-color: rgba(0,0,0,0.05);
        }
    }
`;

const DeleteButtonIcon = styled(Icon).attrs({
    type: 'vorne',
    iconName: 'chip-delete',
})`
    font-size: 12px;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

// check if the user has selected a metric and "is 0" or "is not 0", users are only allowed to
// use metric equality operators to compare to 0
function didUserSelectMetricComparedToZero(opValue, fieldType) {
    return fieldType === 'metric' && (opValue  === 'eq' || opValue === 'ne');
}

function isDuration(type) {
    return type === 'time_span';
}

export function ExpressionRow({
    name,
    lhs,
    op,
    rhs,
    onChange,
    onDeleteRow,
    categoryOptions,
    metricOptions,
    categoryValueOptions,
    allowDelete,
    error,
}) {
    const translator = useTranslator();

    const categoryOperators = [
        {value:'eq', displayName:'='},
        {value:'ne', displayName:'≠'},
    ];

    const metricOperators = [
        {value:'eq', displayName: translator('filter.is_zero')},
        {value:'ne', displayName: translator('filter.is_not_zero')},
        {value:'gt', displayName:'>'},
        {value:'ge', displayName:'≥'},
        {value:'lt', displayName:'<'},
        {value:'le', displayName:'≤'}
    ];
    const handleDeleteRow = useCallback(() => { onDeleteRow(name); }, [onDeleteRow, name]);

    // state for lhsPicker
    const [ isLHSPickerOpen, setIsLHSPickerOpen ] = useState(false);

    // state for rhsPicker
    const [ isRHSPickerOpen, setIsRHSPickerOpen ] = useState(false);

    const onRowChange = (e, { isCategoryValue = false } = {}) => {
        const { target } = e;
        let newValue = { lhs, op, rhs };

        if (target.name === 'op') {
            newValue[target.name] = target.value.value;
            // force rhs if comparing a metric using an equality operator
            if (didUserSelectMetricComparedToZero(target.value.value, lhs?.fieldType)) {
                newValue.rhs = 0;
            }
            // force rhs if changing from using equality operator
            else if (didUserSelectMetricComparedToZero(op, lhs?.fieldType)) {
                newValue.rhs = isDuration(lhs?.type)
                    ? { h: null, m: null, s: null}
                    : null;
            }
        }
        else if (isCategoryValue) {
            newValue[target.name] = target.value.map((val) => val.name);
        }
        else {
            newValue[target.name] = target.value;
        }

        onChange({
            target: {
                name: name,
                value: newValue,
            }
        });
    };

    const onPickerChangeClosePicker = (e) => {
        const { target } = e;
        if (target.name === 'lhs') {
            setIsLHSPickerOpen(false);
        }
        else if (target.name === 'rhs') {
            setIsRHSPickerOpen(false);
        }
        onRowChange(e);
    };

    const selectedFieldDisplayName = lhs
        ? lhs.displayName
        : translator("editors.filter.dropdown_placeholder", {value: translator('field_literal').toLowerCase()});

    // determine which collection to search to find selected operator
    let operatorList = categoryOperators;
    if (lhs?.fieldType === 'metric') {
        operatorList = metricOperators;
    }

    const selectedOp = operatorList.find((operator) => {
        return operator.value === op;
    });

    // only show value field if not comparing metric using equality operators
    let showValueField = ! didUserSelectMetricComparedToZero(selectedOp.value, lhs?.fieldType);

    // if not showing the value field force rhs to be zero
    if (! showValueField) {
        rhs = 0;
    }

    let valueField = null;

    if (showValueField && lhs && lhs.fieldType === 'category_value') {
        const valueOptions = categoryValueOptions[lhs.name] || [];

        // Create a lookup table of display names for use in the dropdown to
        // prevent us from looping through the options list over and over
        // again to generate the list of display values.
        //
        const displayNameMap = valueOptions.reduce((acc, valueOption) => {
            return valueOption.entries.reduce((displayMap, entry) => {
                displayMap[entry.name] = entry.displayName;
                return displayMap;
            }, acc);
        }, {});

        const selectedValue = rhs;
        let selectedValueDisplayName = selectedValue.reduce((result, x) => {
            if (result) {
                result += ', ';
            }
            result += (displayNameMap[x] || translator('unknown_field_literal') );
            return result;
        }, '');

        if (selectedValue.length > 1) {
            selectedValueDisplayName = `(${selectedValue.length}) ${selectedValueDisplayName}`;
        }
        else if (selectedValue.length === 0) {
            selectedValueDisplayName = translator("editors.filter.dropdown_placeholder", {value: translator('value_literal').toLowerCase()});
        }

        const fieldSelectListValue = rhs.reduce((acc, rhsValue) => {
            // filter out any values that no longer exist
            if (displayNameMap[rhsValue]) {
                acc.push({
                    name: rhsValue,
                });
            }

            return acc;
        }, []);

        valueField = (
            <Dropdown
                isOpen={isRHSPickerOpen}
                onMaskClick={() => { setIsRHSPickerOpen(false); }}
                renderContent={() =>{
                    return (<FieldSelectControl
                        name='rhs'
                        options={valueOptions}
                        value={fieldSelectListValue}
                        onChange={(e) => onRowChange(e, { isCategoryValue: true })}
                    />);
                }}
            >
                <SelectDropdownButton
                    style={{width: '100%'}}
                    onClick={() => { setIsRHSPickerOpen(true); }}
                    isPlaceholderText={selectedValue.length === 0}
                >
                    {selectedValueDisplayName}
                </SelectDropdownButton>
            </Dropdown>
        );
    }
    else if (showValueField && isDuration(lhs?.type)) {
        valueField = (
            <DurationField
                name='rhs'
                precision={lhs.subType === 'cycle_time' ? 2 : 0}
                value={rhs}
                onChange={onRowChange}
                labels={{
                    h: translator('hours_abbreviation'),
                    m: translator('minutes_abbreviation'),
                    s: translator('seconds_abbreviation'),
                }}
                error={error?.rhs}
                placeholderText={translator("editors.filter.dropdown_placeholder", {value: translator('value_literal').toLowerCase()})}
            />
        );
    }
    else if (showValueField && lhs && (lhs.type === 'double' || lhs.type == 'uint64' || lhs.type == 'id')) {
        let precision;

        if (lhs.type == 'uint64' || lhs.type == 'id' || lhs.subType === 'count') {
            precision = 0;
        }
        else if (lhs.subType == 'cycle' || lhs.subType == 'rate' || lhs.subType == 'rate_per_hour') {
            precision = 2;
        }
        else {
            precision = 1;
        }

        valueField = (
            <NumberField
                name='rhs'
                value={rhs}
                minFractionDigits={precision}
                percent={lhs.subType === 'percent'}
                onChange={onRowChange}
                error={error?.rhs}
            />
        );
    }
    else if (showValueField && lhs) {
        console.error(`Metric with mysterious type and subtype combo: type:${lhs.type} subType:${lhs.subType}`);
        valueField = 'Error: No Field Found';
    }

    const opName = selectedOp.displayKey ? translator(selectedOp.displayKey) : selectedOp.displayName;

    const renderDeleteButton =  () => {
        if (allowDelete) {
            return (
                <DeleteButtonContainer>
                    <DeleteButton onClick={handleDeleteRow}>
                        <DeleteButtonIcon />
                    </DeleteButton>
                </DeleteButtonContainer>
            );
        }
    };

    return (
        <RowContainer>
            <ExpressionInfoContainer>
                <FieldContainer>
                    <Dropdown
                        isOpen={isLHSPickerOpen}
                        onMaskClick={() => { setIsLHSPickerOpen(false); }}
                        renderContent={() => {
                            return (<FieldBuilderSelect
                                name='lhs'
                                metricFields={metricOptions}
                                categoryFields={categoryOptions}
                                value={lhs}
                                onChange={onPickerChangeClosePicker}
                            />);
                        }}
                    >
                        <SelectDropdownButton
                            style={{width: '100%'}}
                            onClick={() => { setIsLHSPickerOpen(true); }}
                        >
                            {selectedFieldDisplayName}
                        </SelectDropdownButton>
                    </Dropdown>
                </FieldContainer>
                <OperatorContainer>
                    <BasicSelectField
                        selectedDisplayName={opName}
                        selectedValue={selectedOp.value}
                        onChange={onRowChange}
                        options={lhs && lhs.fieldType === 'metric' ? metricOperators : categoryOperators}
                        name='op'
                    />
                </OperatorContainer>
                <FieldContainer>
                    {valueField}
                </FieldContainer>
            </ExpressionInfoContainer>
            {renderDeleteButton()}
        </RowContainer>
    );
}

ExpressionRow.propTypes = {
    name: PropTypes.string.isRequired,
    lhs: PropTypes.shape({
        name: PropTypes.string.isRequired,
        channel: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        fieldType: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        subType: PropTypes.string,
    }).isRequired,
    op: PropTypes.string.isRequired,
    rhs: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.array,
    ]),
    onDeleteRow: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    categoryOptions: PropTypes.array,
    categoryValueOptions: PropTypes.object.isRequired,
    metricOptions: PropTypes.array,
    allowDelete: PropTypes.bool.isRequired,
    error: PropTypes.shape({
        rhs: PropTypes.any,
    }),
};

ExpressionRow.defaultProps = {
    categoryOptions: [],
    metricOptions: [],
    allowDelete: true,
};
