import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip, { TOOLTIP_LARGE } from './Tooltip';

const TargetContainer = styled.div`
    display: inline-flex;

    min-height: 24px;
    min-width: 24px;

    align-items: center;
    justify-content: center;

    cursor: pointer;
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        /* Give mobile users a nice big touch target */
        @media (hover: none) and (pointer: coarse) {
            min-width: 44px;
            min-height: 44px;
        }
        background-color: transparent;
    }
`;

/**
 * A utility component for wrapping a tooltip target in something with a
 * mobile-friendly touch target and mobile-friendly behavior such as
 * staying open on tap.
 *
 * @param {Node} children The tooltip target to wrap.
 * @param {Node} content The content of the tooltip.
 *
 * All other props are forwarded to Tooltip.
 *
 * @see Tooltip
 */
function TouchableTooltipTarget({
    children,
    content,
    ...tooltipProps
}) {
    return (
        <Tooltip
            content={content}
            maxWidth={TOOLTIP_LARGE}
            stickyOnClick
            style={{ display: 'inline-block' }}
            {...tooltipProps}
        >
            <TargetContainer>
                {children}
            </TargetContainer>
        </Tooltip>
    );
}

TouchableTooltipTarget.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    delay: PropTypes.number,
};

TouchableTooltipTarget.defaultProps = {
    delay: 200,
};

export default React.memo(TouchableTooltipTarget);
