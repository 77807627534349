import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'Common/components/IconButton';
import IconSizeProp from 'Common/components/IconButton/IconSizeProp';
import LiveControlOverflowMenuItem from './internal/LiveControlOverflowMenuItem';
import Tooltip from 'Common/components/Tooltip';

/**
  * This component is a button where its children are the button's contents.
  */
function LiveControl({
    displayName,
    showTooltip,
    buttonRef,
    icon,
    contentRender,
    controlRender,
    handleContentLayout,
    disabled,
    selected,
    badge,
    onClick,
    action,
    inOverflow,
    iconSize,
    ...otherProps
}) {
    let liveControlButton;

    if (inOverflow) {
        liveControlButton = (
            <LiveControlOverflowMenuItem
                badge={badge}
                ref={buttonRef}
                disabled={disabled}
                selected={selected}
                onClick={onClick}
                {...otherProps}
            >
                {contentRender()}
            </LiveControlOverflowMenuItem>
        );
    }
    else {
        liveControlButton = (
            <IconButton
                badge={badge}
                ref={buttonRef}
                disabled={disabled}
                selected={selected}
                onClick={onClick}
                size={iconSize}
                {...otherProps}
            >
                {contentRender()}
            </IconButton>
        );
    }

    if (showTooltip && displayName) {
        return (
            <Tooltip
                content={displayName}
                placement="top"
                style={{
                    display: 'inline-block',
                }}
            >
                {liveControlButton}
            </Tooltip>
        );
    }
    else {
        return liveControlButton;
    }
}

LiveControl.propTypes = {
    displayName: PropTypes.string,
    showTooltip: PropTypes.bool,
    badge: PropTypes.number,
    buttonRef: PropTypes.object,
    action: PropTypes.func,
    controlRender: PropTypes.func,
    contentRender: PropTypes.func,
    handleContentLayout: PropTypes.bool,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    inOverflow: PropTypes.bool,
    iconSize: IconSizeProp,
};

LiveControl.defaultProps = {
    showTooltip: false,
    badge: null,
    action: null,
    controlRender: ()=>{},
    contentRender: ()=>{},
    handleContentLayout: false,
    disabled: false,
    onClick: ()=>{},
    selected: false,
    inOverflow: false,
};

export default React.memo(LiveControl);
