import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropdownContentBox from './internal/DropdownContentBox';
import { withDropdownContext } from './internal/DropdownContext';

// Give a margin for scrollbars on the edge of the screen, and it looks a
// little nicer.
const windowEdgeMargin = 24;

const Root = styled(DropdownContentBox)`
    position: absolute;
    z-index: 100;
    transition: opacity 300ms;
    min-width: 120px;
    max-width: calc(100vw - ${2 * windowEdgeMargin}px);
    max-height: calc(100vh - ${2 * windowEdgeMargin}px);
`;

class FixedDropdownContent extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,

        // Provided by `withDropdownContext`
        dropdownContext: PropTypes.shape({
            isDefault: PropTypes.bool.isRequired,
            getAnchorNode: PropTypes.func.isRequired,
            contentRef: PropTypes.shape({
                current: PropTypes.any,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        if (props.dropdownContext.isDefault) {
            throw new Error('FixedDropdownContent must be rendered as a descendant of Dropdown');
        }

        this.state = {
            mounted: false,
            anchorRect: null,
            windowSize: null,
        };
    }

    get _rootRef() {
        return this.props.dropdownContext.contentRef;
    }

    componentDidMount() {
        this.setState({
            mounted: true,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mounted && !prevState.mounted) {
            this._rootRef.current.focus();
        }
    }

    render() {
        const { className, children } = this.props;
        const { mounted } = this.state;

        let style = {
            opacity: '0',
        };

        if (mounted) {
            style = {
                opacity: '1',
            };
        }

        return (
            <Root
                ref={this._rootRef}
                className={className}
                style={style}
                tabIndex={0} // This tabIndex is important, it allows for focus
            >
                {children}
            </Root>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
function BlockedRender(props) {
    if (props.dropdownContext.open) {
        return (
            <FixedDropdownContent {...props} />
        );
    }
    else {
        return null;
    }
}

BlockedRender.propTypes = {
    dropdownContext: PropTypes.shape({
        open: PropTypes.bool
    }),
};

export default withDropdownContext(BlockedRender);
