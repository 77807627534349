import Button from 'Common/components/Button';
import ModalWindow from 'Common/components/ModalWindow/ModalWindow';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'Common/hooks/useTranslation';

export default function InvalidLiveControlDialog({
    title,
    content,
    onContinue,
    onCancel,
}) {
    const translator = useTranslator();
    const cancelText = translator('control.common.invalid_changes_popup.cancel');
    const continueText = translator('control.common.invalid_changes_popup.continue');

    return (
        <ModalWindow
            title={title}
            bodyWidth={550}
            buttons={[
                <Button
                    key="cancel"
                    type="secondary"
                    onClick={onCancel}
                >
                    {cancelText}
                </Button>,
                <Button
                    key="continue"
                    type="primary"
                    onClick={onContinue}
                >
                    {continueText}
                </Button>
            ]}
        >
            <div>
                {content}
            </div>
        </ModalWindow>
    );
}

InvalidLiveControlDialog.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
