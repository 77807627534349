import { sendGetRequest } from 'Http/request';
import { getResources } from 'Common/util/ResourceCollection';

export function getEmailSubscriptions() {
    return getResources({
        resourceType: 'emailSubscriptions',
        requestId: 'getEmailSubscriptions',
        sendRequest: async () => {
            return sendGetRequest('/api/v1/accounts/user/report-subscriptions');
        },
    });
}

export default getEmailSubscriptions;
