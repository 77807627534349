import React from 'react';
import PropTypes from 'prop-types';
import { useDroppable } from '@dnd-kit/core';
import { useTheme } from 'styled-components';

export default function DroppableSpot(props) {
    const {
        droppableId,
        sectionIndex,
        columnIndex,
        widgetIndex,
        widgetId,
        outerHeight,
    } = props;

    const { isOver, setNodeRef } = useDroppable({
        id: droppableId,
        data: {
            sectionIndex: sectionIndex,
            columnIndex: columnIndex,
            widgetIndex: widgetIndex,
            widgetId: widgetId,
        },
    });

    const placeholderHeight = 4;

    const placeholderTop = Math.round(
        outerHeight / 2 - placeholderHeight / 2,
    );

    const theme = useTheme();

    return (
        <div ref={setNodeRef}>
            {
                <div
                    style={{
                        position: 'relative',
                        height: `${outerHeight}px`,
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: `${placeholderTop}px`,
                            left: '4px',
                            right: '4px',
                            borderRadius: '2px',
                            height: `${placeholderHeight}px`,
                            backgroundColor: isOver
                                ? theme.colors.palette.blue.awesome
                                : 'transparent',
                            transition: 'background-color 0.3s',
                        }}
                    />
                </div>
            }
        </div>
    );
}

DroppableSpot.propTypes = {
    droppableId: PropTypes.string.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    widgetIndex: PropTypes.number.isRequired,
    widgetId: PropTypes.string,
    outerHeight: PropTypes.number.isRequired,
};
