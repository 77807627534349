import ParamTypes from 'Common/util/ParamTypes';
import Filters from 'Common/types/Filters/Filters';
import fieldSetParamType from './fieldSetParamType';

/**
 * The configuration for the ExplorationPath
 */
const explorationPathParamType = ParamTypes.shape({
    asset_control: ParamTypes.shape({
        layout: ParamTypes.oneOf([
            'hierarchy',
            'list',
        ]),
        level: ParamTypes.oneOfType([
            ParamTypes.number,
            ParamTypes.string,
        ]),
        root: ParamTypes.string,
    }),
    field_set: fieldSetParamType,
    slice_filter: ParamTypes.shape({
        combine_type: ParamTypes.oneOf(['and', 'or']),
        expressions: ParamTypes.arrayOf(Filters.sliceFilterExpressionParamType),
    }),
    group_filter: ParamTypes.shape({
        combine_type: ParamTypes.oneOf(['and', 'or']),
        expressions: ParamTypes.arrayOf(Filters.groupFilterExpressionParamType),
    }),
});

export default explorationPathParamType;
