const cssValueRegex = (() => {
    const cssUnitRegexString = [
        'px',   'em', 'rem', '%',
        'pt',   'vw', 'vh',  'vmin',
        'vmax', 'in', 'cm',  'mm',
        'lh',   'pc', 'ex',  'ch',
        'Q',
    ].map((unit) => `(?:${unit})`).join('|');

    // A css value
    const regexString = `^(-?\\d+\\.?\\d*)(?:${cssUnitRegexString})?$`;

    return RegExp(regexString);
})();

/**
 * Helper to parse css unit strings such as "123px" into the numeric representation such as 123
 *
 * @param {Number|String} cssValue A number of units or css value such as "123px", "15em", or "0"
 *
 * @returns {Number} Numeric representation of the unit value.
 */
export function cssValueToNumber(cssValue) {
    const parseFail = (error = 'unknown') => {
        throw new Error(`Cannot parse cssValue value of "${cssValue}". Error: ${error}`);
    };

    if (typeof cssValue === 'number') {
        return cssValue;
    }
    else if (typeof cssValue === 'string') {
        cssValue = cssValue.trim();

        const unitValueMatches = cssValue.match(cssValueRegex);

        if (unitValueMatches) {
            const numericValue = Number(unitValueMatches[1]);

            if (Number.isNaN(numericValue)) {
                parseFail('parsed value is NaN');
            }
            else {
                return numericValue;
            }
        }
        else {
            parseFail('unsupported unit or format');
        }
    }
    else {
        parseFail('unsupported type');
    }
}
