import useKpiWidgetData from './useKpiWidgetData';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import TimeRange from 'Common/types/TimeRange/TimeRange';
import explorationPathParamType from 'Common/types/stf/explorationPathParamType';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslator } from 'Common/hooks/useTranslation';
import useKpiWidgetInitialization from './useKpiWidgetInitialization';
import getPrimaryDescription from "Common/components/TimeRangeSelector/helpers/getPrimaryDescription";
import getSecondaryDescription from "Common/components/TimeRangeSelector/helpers/getSecondaryDescription";
import useKpiWidgetPrintStatus from './useKpiWidgetPrintStatus';
import { getDataUpdateInterval } from 'Common/hooks/requests/useRepeatingRequest';
import useTimeRangeData, { TIME_RANGE_PRIMARY_DETAILS, TIME_RANGE_SECONDARY_DETAILS } from 'Common/components/TimeRangeSelector/hooks/useTimeRangeData';
import useFormattedFilters from 'Common/hooks/useFormattedFilters';

const extractFiltersFromExplorationPath = (explorationPath) => {
    let filters = {};

    if (explorationPath?.group_filter) {
        filters.group = explorationPath.group_filter;
    }

    if (explorationPath?.slice_filter) {
        filters.slice = explorationPath.slice_filter;
    }

    if (Object.keys(filters) === 0) {
        // There were either no filters, or no exploration path. Return undefined.
        return undefined;
    }
    else {
        return filters;
    }
};

const paramTypes = ParamTypes.shape({
    widgetStf: ParamTypes.shape({
        exploration_path: explorationPathParamType,
        rangeSelection: TimeRange.timeRangeParamType,
        hideLiveControls: ParamTypes.bool,
        showLiveControlsOnImmutablePages: ParamTypes.bool,
    }),
    setWidgetStf: ParamTypes.func.isRequired,
    setWidgetPrintStatus: ParamTypes.func.isRequired,
    setWidgetLiveControlsVisibility: ParamTypes.func.isRequired,
    dataService: ParamTypes.shape({
        getKpiWidgetData: ParamTypes.func.isRequired,
        getColumnConfig: ParamTypes.func.isRequired,
        getColumnHelpText: ParamTypes.func.isRequired,
        getTimeRangePrimaryDetails: ParamTypes.func.isRequired,
        getTimeRangeSecondaryDetails: ParamTypes.func.isRequired,
    }).isRequired,
    pageTimeRange: TimeRange.timeRangeParamType,
    pageExplorationPath: explorationPathParamType,
    reportHierarchyNode: ParamTypes.shape({
        name: ParamTypes.string,
        displayName: ParamTypes.string
    }),
    isPrint: ParamTypes.bool.isRequired,
    mutable: ParamTypes.bool.isRequired,
    areLiveControlsVisible: ParamTypes.bool.isRequired,
});

const useKpiController = (parameters) => {
    paramTest(parameters, paramTypes, 'useKpiController');

    const {
        widgetStf,
        setWidgetStf,
        setWidgetPrintStatus,
        setWidgetLiveControlsVisibility,
        areLiveControlsVisible,
        dataService,
        pageTimeRange,
        pageExplorationPath,
        reportHierarchyNode,
        isPrint,
        isLayoutModeActive,
        duplicateWidget,
        mutable
    } = parameters;

    const {
        isInitialized,
        liveControlOptions,
    } = useKpiWidgetInitialization({ dataService });

    const translator = useTranslator();

    const [columnConfig, setColumnConfig] = useState({});

    // livecontrol goodies
    const supportsLiveControls = useMemo(() => {
        return !widgetStf.hideLiveControls 
            && (!!widgetStf.showLiveControlsOnImmutablePages || mutable);
    }, [widgetStf, mutable]);

    const toggleShowLiveControls = useCallback(() => {
        setWidgetLiveControlsVisibility(!areLiveControlsVisible);
    }, [ areLiveControlsVisible, setWidgetLiveControlsVisibility ]);

    const rawSliceFilters = widgetStf.exploration_path.slice_filter;

    const filters = useMemo(() => {
        if (!rawSliceFilters) {
            return {
                slice: {
                    combine_type: 'and',
                    expressions: [],
                }
            };
        }
        else {
            return {
                slice: rawSliceFilters
            };
        }
    }, [rawSliceFilters]);

    const setSliceFilters = (newFilters) => {
        setWidgetStf((stf) => ({
            ...stf,
            exploration_path: {
                ...stf.exploration_path,
                slice_filter: newFilters,
            },
        }));
    };

    const formattedFilters = useFormattedFilters({
        sliceFilters: filters.slice,
        dataService,
    });

    const followPageTimeRange = !widgetStf.rangeSelection;
    const setTimeRange = (rs) => {
        setWidgetStf((stf) => ({
            ...stf,
            rangeSelection: rs,
        }));
    };

    const widgetHierarchyNode = widgetStf?.exploration_path?.asset_control?.root;
    const followAppBarHierarchyNode = !widgetHierarchyNode;
    const mergedHierarchyNode = widgetHierarchyNode || reportHierarchyNode?.name;

    const setHierarchyNode = useCallback((newHierarchyNode) => {
        setWidgetStf((stf) => {
            let newExplorationPath = {
                ...stf.exploration_path
            };
            if (newHierarchyNode) {
                newExplorationPath.asset_control = {
                    root: newHierarchyNode,
                };
            }
            else {
                delete newExplorationPath.asset_control;
            }

            return {
                ...stf,
                exploration_path: newExplorationPath,
            };
        });
    }, [setWidgetStf]);

    const widgetExplorationPath = widgetStf.exploration_path;

    const widgetField = widgetStf?.exploration_path?.field_set?.fields?.[0];
    const field = widgetField || null;

    useEffect(() => {
        let isMounted = true;

        async function getColumnConfig() {
            const config = await dataService.getColumnConfig(field.name, field.channel);
            const helpText = await dataService.getColumnHelpText(field.name, field.channel);

            // Just tack on the help text to the config
            // Additionally, if the KPIWidget has been unmounted in the time it took to await those
            // previous functions then don't set the state here since it will be setting the state
            // on an unmounted component and flagged as a memory leak
            if (isMounted) {
                // Just tack on the help text to the config
                setColumnConfig({
                    ...config,
                    helpText,
                });
            }
        }

        getColumnConfig();

        // useEffect cleanup function to track if we're still mounted after async awaits
        return () => { isMounted = false; };
    }, [field.name, field.channel, dataService]);

    const pageFilters = useMemo(() => {
        return extractFiltersFromExplorationPath(pageExplorationPath);
    }, [ pageExplorationPath ]);

    const widgetFilters = useMemo(() => {
        return extractFiltersFromExplorationPath(widgetExplorationPath);
    }, [ widgetExplorationPath ]);

    const setMetric = (metric) => {
        paramTest(metric, ParamTypes.shape({
            name: ParamTypes.string.isRequired,
            channel: ParamTypes.string.isRequired,
        }).isRequired, 'setMetric.metric');

        setWidgetStf((stf) => {
            return {
                ...stf,
                exploration_path: {
                    ...stf.exploration_path,
                    field_set: {
                        ...stf.exploration_path.field_set,
                        fields: [{
                            name: metric.name,
                            channel: metric.channel,
                        }],
                    },
                },
            };
        });
    };

    // Disable the update interval if the widget is in print view
    const widgetUpdateTimeInterval = isPrint ? null : widgetStf.updateTimeInterval;
    
    const dataRequest = useKpiWidgetData({
        hierarchyNode: mergedHierarchyNode,
        field,
        widgetFilters,
        pageFilters,
        timeRange: widgetStf.rangeSelection || pageTimeRange,
        getKpiWidgetData: dataService.getKpiWidgetData,
        updateInterval: getDataUpdateInterval(widgetUpdateTimeInterval),
    });

    const defaultTitle = columnConfig.displayName || '';
    let title = widgetStf.title;
    if (!title && widgetStf.titleKey) {
        title = translator(widgetStf.titleKey);
    }

    const setTitle = (newTitle) => {
        setWidgetStf((stf) => ({
            ...stf,
            title: newTitle,
        }));
    };

    const tooltipContent = useMemo(() => {
        return {
            displayName: columnConfig.displayName || '',
            description: columnConfig.helpText || '',
            trendDescription: translator('widget.kpi.trend_tooltip'),
        };
    }, [columnConfig, translator]);

    const timeRangeRequests = useTimeRangeData({
        getTimeRangePrimaryDetails: dataService.getTimeRangePrimaryDetails,
        getTimeRangeSecondaryDetails: dataService.getTimeRangeSecondaryDetails,
        hierarchyNode: mergedHierarchyNode,
        timeRange: widgetStf.rangeSelection,
        updateInterval: getDataUpdateInterval(widgetUpdateTimeInterval),
    });

    const timeRangeDescription = useMemo(() => {
        const primaryDetails = timeRangeRequests[TIME_RANGE_PRIMARY_DETAILS]?.response;
        const secondaryDetails = timeRangeRequests[TIME_RANGE_SECONDARY_DETAILS]?.response;

        if (primaryDetails && translator) {
            const primary = getPrimaryDescription({
                dimensionDetailsDisplayName: primaryDetails?.dimensionDetails.displayName,
                dimensionDetailsIsMixed: primaryDetails?.dimensionDetails.isMixed || false,
                timeRangeStart: primaryDetails?.timeRange.start,
                timeRangeEnd: primaryDetails?.timeRange.end,
                timeRangeSameDimensionValue: primaryDetails?.timeRange.sameDimensionValue || false,
                timeRangeUnits: primaryDetails?.timeRange.units,
                timeRangeProductionDayOffset: primaryDetails?.timeRange.productionDayOffset,
                atEndOfData: !!secondaryDetails?.atEndOfData,
                locale: primaryDetails.locale,
                translator: translator,
            });

            const secondary = getSecondaryDescription({
                range: secondaryDetails?.range,
                timeRangeStart: primaryDetails?.timeRange.start,
                timeRangeEnd: primaryDetails?.timeRange.end,
                timeRangeSameDimensionValue: primaryDetails?.timeRange.sameDimensionValue || null,
                timeRangeUnits: primaryDetails?.timeRange.units || '',
                originDay: primaryDetails?.originDay || '',
                locale: primaryDetails?.locale,
                translator: translator,
            });

            return {
                primary,
                secondary
            };
        }
        else {
            return null;
        }

    }, [timeRangeRequests, translator]);

    // Keeps the print status updated
    useKpiWidgetPrintStatus({
        // Do not update print status when we're already in print view.
        disabled: isPrint,
        setWidgetPrintStatus,
        kpiWidgetDataRequest: dataRequest,
        timeRangePrimaryRequest: timeRangeRequests[TIME_RANGE_PRIMARY_DETAILS],
        timeRangeSecondaryRequest: timeRangeRequests[TIME_RANGE_SECONDARY_DETAILS],
    });

    return {
        isInitialized,
        metric: field.name,
        setMetric,
        filters,
        setSliceFilters,
        formattedFilters,
        timeRange: widgetStf.rangeSelection,
        setTimeRange,
        followPageTimeRange,
        followAppBarHierarchyNode,
        hierarchyNode: widgetHierarchyNode,
        setHierarchyNode,
        defaultTitle,
        title,
        setTitle,
        tooltipContent,
        metricOptions: liveControlOptions?.metricOptions,
        hierarchyNodeOptions: liveControlOptions?.hierarchyNodeOptions || [],
        filterOptions: liveControlOptions?.filterOptions,
        dataRequest,
        timeRangeDescription,
        shouldShowFilterText: widgetStf.shouldShowFilterText,
        showLiveControls: areLiveControlsVisible,
        supportsLiveControls,
        toggleShowLiveControls,
        isLayoutModeActive,
        duplicateWidget,
    };
};

export default useKpiController;
