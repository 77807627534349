import forEach from 'lodash/forEach';
import { parseTime } from 'Components/util/timeUtils';
import timeInputValidation from 'Components/time-input/timeInputValidation';

function startBeforeEnd(start, end) {
    const startTime = parseTime(start);
    const endTime = parseTime(end);

    // Midnight is ambiguous. Therefore, if both equal midnight, then we can
    // assume `end` is referring to the next day
    if (startTime.isSame(endTime)) {
        return startTime.isSame(parseTime('00:00'));
    }

    return startTime.isBefore(endTime);
}

export default function validate(value = {}) {
    const errors = {};

    forEach(value, (dayValue = {}, key) => {
        let startError, endError;

        if (dayValue.enabled) {
            const { start, end } = dayValue;

            startError = timeInputValidation(start);
            endError = timeInputValidation(end);

            if (!startError && !endError) {
                if (!startBeforeEnd(start, end)) {
                    startError = 'Start time must occur before end time';
                }
            }
        }

        // Only set key if there are errors
        if (startError || endError) {
            errors[key] = {
                start: startError,
                end: endError,
            };
        }
    });

    return errors;
}
