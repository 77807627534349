import styled from 'styled-components';
import { Body7 } from 'Common/components/typography';

const InlineError = styled(Body7)`
    display: inline-block;

    color: ${({theme}) => theme.colors.palette.error.red};
`;

export default InlineError;
