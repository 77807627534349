import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from 'Components/text-input/TextInput';
import OutlinedButton from 'Components/button/OutlinedButton';
import PopupWindow from 'Components/popup/PopupWindow';
import ContainedButton from 'Components/button/ContainedButton';
import ErrorNotice from 'Components/notice/ErrorNotice';
import VerificationNotice from './VerificationNotice';
import { Body1 } from 'Components/typography/typography';
import { smsEmailShortDescription } from './sectionDescriptions';

const StyledTextInput = styled(TextInput)`
    display: block;
`;

const PopupWindowDescription = styled(Body1)`
    display: block;
    margin-bottom: 16px;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
`;

export default class SmsEmailEditor extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.shape({
            id: PropTypes.number, // Can be missing if a new email is being added
            email: PropTypes.string.isRequired,
            verified: PropTypes.bool.isRequired,
        }), // Can be null if no email is set
        onChange: PropTypes.func.isRequired,
        validation: PropTypes.shape({
            email: PropTypes.string,
        }),
        disabled: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
        onDeleteCurrentClick: PropTypes.func.isRequired,
        onCancelVerificationClick: PropTypes.func.isRequired,
        onResendVerificationClick: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired,
        error: PropTypes.node,
        success: PropTypes.node,
    };

    static defaultProps = {
        disabled: false,
        autoFocus: false,
    };

    constructor(props) {
        super(props);
    }

    _emitChange = (value) => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: value,
            },
        });
    };

    _handleChange = (event) => {
        this._emitChange({
            ...(this.props.value || {}),
            email: event.target.value,
        });
    };

    _handleAddEmailClick = () => {
        this._emitChange({
            email: '',
            verified: false,
        });
    };

    _handleAddConfirmClick = () => {
        this.props.onSubmit(this.props.value);
    };

    _handleAddCancelClick = () => {
        this.props.onReset();
    };

    render() {
        const {
            name,
            value,
            validation,
            disabled,
            autoFocus,
            onDeleteCurrentClick,
            onCancelVerificationClick,
            onResendVerificationClick,
            error,
            success,
        } = this.props;

        const hasValue = value !== null;
        const hasVerifiedEmail = hasValue && value?.verified;
        const hasPendingEmail = hasValue && !value?.verified;
        const isAddingEmail = hasValue && typeof value?.id !== 'number';

        return (
            <React.Fragment>
                {hasVerifiedEmail && (
                    <StyledTextInput
                        label="Text-Only Email"
                        value={value.email}
                        type="email"
                        name={`${name}.email`}
                        disabled
                        helperText={''}
                        autoComplete="email"
                        autoFocus={autoFocus}
                        rightButton={
                            <OutlinedButton
                                color="secondary"
                                onClick={onDeleteCurrentClick}
                            >
                                Delete
                            </OutlinedButton>
                        }
                    />
                )}
                {hasPendingEmail && !isAddingEmail && (
                    <VerificationNotice
                        disabled={disabled}
                        error={error}
                        success={success}
                        onCancelVerificationClick={onCancelVerificationClick}
                        onResendVerificationClick={onResendVerificationClick}
                    >
                        A verification link has been sent to <b>{value.email}</b>.
                        You must confirm this email address before it will become
                        active.
                    </VerificationNotice>
                )}
                {(!hasValue || isAddingEmail) && (
                    <OutlinedButton
                        color="primary"
                        onClick={this._handleAddEmailClick}
                    >
                        Add Text-Only Email
                    </OutlinedButton>
                )}
                {isAddingEmail && (
                    <PopupWindow
                        title="Add Text-Only Email"
                        width={500}
                        buttons={[
                            <ContainedButton
                                key="confirm"
                                color="primary"
                                onClick={this._handleAddConfirmClick}
                                disabled={disabled || !!validation?.email}
                            >
                                Add Email
                            </ContainedButton>,
                            <OutlinedButton
                                key="cancel"
                                color="primary"
                                onClick={this._handleAddCancelClick}
                                disabled={disabled}
                            >
                                Cancel
                            </OutlinedButton>
                        ]}
                    >
                        <PopupWindowDescription>
                            {smsEmailShortDescription}
                        </PopupWindowDescription>
                        <StyledTextInput
                            label="Text-Only Email"
                            placeholder="##########@example.com"
                            value={value.email}
                            type="email"
                            name={`${name}.email`}
                            error={!!validation?.email}
                            disabled={disabled}
                            helperText={validation?.email || ''}
                            onChange={this._handleChange}
                            autoComplete="email"
                            autoFocus
                        />
                        {error && (
                            <ErrorNotice>
                                {error}
                            </ErrorNotice>
                        )}
                    </PopupWindow>
                )}
            </React.Fragment>
        );
    }
}
