import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * ButtonBase
 *
 * The purpose of ButtonBase is simple:
 *
 *  1. Clear default browser button styles (border, outline, padding, etc.).
 *  2. Provide a touch target that does not interfere with the size and layout of the button.
 *  3. Show the pointer cursor when the button is not disabled.
 *
 * ButtonBase should NOT specify fonts, colors, background colors, or anything
 * else.
 *
 */

const Root = styled.button`
    position: relative; /* Needed for touch target in :before */
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;

    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    color: inherit;

    cursor: default;

    &:not(:disabled) {
        cursor: pointer;
    }

    /* Touch Target */
    :before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        /* Give mobile users a nice big touch target */
        @media (hover: none) and (pointer: coarse) {
            min-width: 44px;
            min-height: 44px;
        }
    }
`;

const ButtonBase = React.forwardRef((props, ref) => {
    const { Component } = props;

    return (
        <Root
            ref={ref}
            {...props}
            as={Component}
        />
    );
});

ButtonBase.displayName = 'ButtonBase';

ButtonBase.propTypes = {
    Component: PropTypes.elementType,
};

ButtonBase.defaultProps = {
    Component: 'button',
};

export default ButtonBase;
