import { sendPostRequest } from 'Http/request';
import { requestActionCreator } from 'Common/util/ResourceCollection/requestActionCreator';
import {
    REQUEST_RESET,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from 'Common/util/ResourceCollection/actions';

export function loginReset() {
    return {
        type: REQUEST_RESET,
        requestId: 'login',
    };
}

export const login = ({ email, password }) => requestActionCreator(
    {
        request: () => ({
            type: REQUEST_PENDING,
            requestId: 'login',
        }),
        success: (payload) => ({
            type: REQUEST_SUCCESS,
            requestId: 'login',
            payload,
        }),
        failure: (error) => ({
            type: REQUEST_FAILURE,
            requestId: 'login',
            payload: { error },
        }),
    },
    () => sendPostRequest('/api/v1/accounts/login', {
        body: { email, password },
    })
);

export default login;
