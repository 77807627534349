import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ButtonBase from 'Common/components/Button/ButtonBase';
import { Heading5 } from 'Common/components/typography';

const StyledButton = styled(Heading5).attrs({ as: ButtonBase })`
    position: relative; /* Needed for touch target in :before */
    box-sizing: border-box;
    margin: 0;
    padding: 0 10px;
    min-width: 90px; /* From lookbook */
    height: ${({ size }) => size === 'short' ? css`18px` : css`22px`};
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;

    /* Gives the button a slightly raised look */
    box-shadow: 1px 1.5px 1px rgba(0, 0, 0, 0.16);

    ${({ type, theme }) => type === 'primary'
        ? css`
            color: ${theme.colors.palette.white};
            background-color: ${theme.colors.palette.blue.awesome};
            border: 1px solid ${theme.colors.palette.blue.awesome};
        `
        : css`
            color: ${theme.colors.palette.blue.awesome};
            background-color: ${theme.colors.palette.white};
            border: 1px solid ${theme.colors.palette.blue.awesome};
        `
    };

    transition: color 0.25s, background-color 0.25s, border-color 0.25s, opacity 0.25s, box-shadow 0.25s;

    &:not(:disabled) {
        cursor: pointer;

        &:hover, &:focus {
            ${({ type, theme }) => type === 'primary'
                ? css`
                    color: ${theme.colors.palette.white};
                    background-color: ${theme.colors.palette.blue.deep};
                    border-color: ${theme.colors.palette.blue.deep};
                `
                : css`
                    color: ${theme.colors.palette.blue.awesome};
                    background-color: ${theme.colors.palette.grey.cloud};
                    border-color: ${theme.colors.palette.blue.awesome};
                `
            };
        }

        &:active { /* When the button is being pressed. */
            ${({ type, theme }) => type === 'primary'
                ? css`
                    color: ${theme.colors.palette.white};
                    background-color: ${theme.colors.palette.blue.awesome};
                    border-color: ${theme.colors.palette.blue.deep};
                `
                : css`
                    color: ${theme.colors.palette.blue.awesome};
                    background-color: ${theme.colors.palette.blue.ice};
                    border-color: ${theme.colors.palette.blue.awesome};
                `
            };
        }
    }

    &:disabled {
        box-shadow: none;

        ${({ type, theme }) => type === 'primary'
            ? css`
                color: ${theme.colors.palette.white};
                background-color: ${theme.colors.palette.blue.cruise};
                border-color: ${theme.colors.palette.blue.cruise};
            `
            : css`
                color: ${theme.colors.palette.blue.cruise};
                background-color: ${theme.colors.palette.white};
                border-color: ${theme.colors.palette.blue.cruise};
            `
        };
    }
`;

const Button = React.forwardRef(({
    type,
    size,
    ...otherProps
}, ref) => (
    <StyledButton
        ref={ref}
        type={type}
        size={size}
        {...otherProps}
    />
));

Button.displayName = 'Button';

Button.propTypes = {
    type: PropTypes.oneOf([
        'primary',
        'secondary',
    ]),
    size: PropTypes.oneOf([
        'short',
        'medium',
    ]),
};

Button.defaultProps = {
    type: 'primary',
    size: 'medium',
};

export default Button;
