import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { filterGroupedFields } from 'Common/components/FieldBuilder/FieldBuilder';
import { useTranslator } from 'Common/hooks/useTranslation';
import styled from 'styled-components';
import { Body5 } from 'Common/components/typography';
import Button from 'Common/components/Button';
import BasicSelectField from 'Common/components/BasicSelectField/BasicSelectField';
import NumberField from 'Common/components/NumberField';
import MetricSelectField from 'Common/components/MetricSelectField';
import CategorySelectField from 'Common/components/CategorySelectList/CategorySelectField';
import InlineError from 'Common/components/Error/InlineError';
import validateNumber from 'Common/data/validateNumber';
import {
    BAR_VISUALIZATION_TYPE,
    CHRONOGRAM_VISUALIZATION_TYPE, 
    COLUMN_VISUALIZATION_TYPE, 
    DIMENSION_ORDER, 
    DIMENSION_VISUALIZATION_TYPES, 
    DURATION_METRIC, 
    TEXT_VISUALIZATION_TYPE, 
    TIME_ORDER, 
    VALUE_ORDER
} from 'Common/components/SparkVisualization/SparkDimensionConstants';

const REJECT_REASON_DIMENSION = 'reject_reason';
const REJECT_COUNT_METRIC = 'reject_count';
const NO_METRIC = 'none';

function getVisualizationOptions(translator) {
    return [
        {
            value: DIMENSION_VISUALIZATION_TYPES.TEXT_VISUALIZATION_TYPE,
            displayName: translator('widget.spark_visualization.dimension_visualization_options.visualization.text'),
        },
        {
            value: DIMENSION_VISUALIZATION_TYPES.BAR_VISUALIZATION_TYPE,
            displayName: translator('widget.spark_visualization.dimension_visualization_options.visualization.bar'),
        },
        {
            value: DIMENSION_VISUALIZATION_TYPES.COLUMN_VISUALIZATION_TYPE,
            displayName: translator('widget.spark_visualization.dimension_visualization_options.visualization.column'),
        },
        {
            value: DIMENSION_VISUALIZATION_TYPES.CHRONOGRAM_VISUALIZATION_TYPE,
            displayName: translator('widget.spark_visualization.dimension_visualization_options.visualization.chronogram'),
        },
    ];
}

function getOrderOptions(translator) {
    return [
        {
            value: DIMENSION_ORDER,
            displayName: translator('widget.spark_visualization.dimension_visualization_options.order_by.by_dimension'),
        },
        {
            value: VALUE_ORDER,
            displayName: translator('widget.spark_visualization.dimension_visualization_options.order_by.by_value'),
        },
    ];
}

function getMetricOptions(fieldOptions, visualization) {
    return fieldOptions.category_value_metrics[visualization];
}

/**
 * A helper function to find the first option in a hierarchical set of options.
 *
 * Similar to Array.find().
 * 
 * @param {Array} options Array of options.
 * @param {Function} func Supplied function that should return true if the option matches.
 * @returns {Object|undefined} The found option or undefined.
 */
const findOptionRecursive = (options, func) => {
    for (const opt of options) {
        if (opt.entries) {
            const foundEntry = findOptionRecursive(opt.entries, func);
            if (foundEntry !== undefined) {
                return foundEntry;
            }
        }
        else {
            if (func(opt)) {
                return opt;
            }
        }
    }

    return undefined;
};

const findMetricOption = (metricOptions, metric) => {
    const foundOption = findOptionRecursive(metricOptions, (opt) => {
        return opt.name === metric;
    });

    return foundOption || null;
};

function hasChanges(newConfig, initialConfig) {
    return newConfig.dimension !== initialConfig?.name
        || newConfig.visualization !== initialConfig?.dimensionVisualization?.visualization
        || newConfig.metric !== initialConfig?.dimensionVisualization?.metric
        || newConfig.order !== initialConfig?.dimensionVisualization?.orderBy
        || newConfig.limit !== initialConfig?.dimensionVisualization?.maxElementsShown;
}

const verticalPadding = 5;
const ListDropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 14px ${verticalPadding}px 14px;
`;

const LimitContainer = styled.div`
    padding: 0 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LimitErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 14px ${verticalPadding}px 14px;
`;

const SparkColumnLabel = styled.div`
    width: 38%;
    flex: 0 0 auto;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SparkColumnContainer = styled.div`
    min-width: 1px;
    flex: 1 1 auto;
`;

const HelperTextContainer = styled(Body5).attrs({ as: 'div' })`
    color: ${({ theme }) => theme.colors.darkText.mediumEmphasis };
    padding: 0 0 10px 14px;
`;

const ValidationText = styled(Body5)`
    color: ${({theme}) => theme.colors.palette.error.red };
`;

const ValidationContainer = styled.div`
    justify-content: flex-end;
    flex: 0 0 auto;
    padding: 0 10px;
`;

const ApplyContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 14px 14px 14px;
`;

const NumberFieldContainer = styled.div`
    width: 30px;
`;

/**
 * Use this in a history stack style column control for when the user is editing/adding a category.
 *
 */
const SparkDimensionColumnSelector = ({
    fieldOptions,
    selectedColumnIndex,
    selectedColumns,
    onChange,
    startingValue
}) => {
    const translator = useTranslator();

    // initial column
    const initialCategoryColumn = Number.isFinite(selectedColumnIndex) ? selectedColumns[selectedColumnIndex] : null;
    const initalDimensionVisualization = initialCategoryColumn?.dimensionVisualization || null;

    const [dimension, setDimension] = useState(initialCategoryColumn?.name || startingValue?.name || '');
    const [visualization, setVisualization] = useState(initalDimensionVisualization?.visualization || BAR_VISUALIZATION_TYPE);
    const [metric, setMetric] = useState(initalDimensionVisualization?.metric || startingValue?.metric || null);
    const [order, setOrder] = useState(initalDimensionVisualization?.orderBy || DIMENSION_ORDER);
    const [limit, setLimit] = useState(Number.isFinite(initalDimensionVisualization?.maxElementsShown) ? initalDimensionVisualization?.maxElementsShown : 10);

    const usesLimit = (visualization !== CHRONOGRAM_VISUALIZATION_TYPE && visualization !== TEXT_VISUALIZATION_TYPE);

    const limitNumberError = useMemo(() => {
        return usesLimit ?
            validateNumber(limit, {
                integral: true,
                min: 1,
                max: 15,
                translator: translator,
            })
            : '';
    }, [limit, translator, usesLimit]);

    const configurationError = useMemo(() => {
        if (visualization === CHRONOGRAM_VISUALIZATION_TYPE && (['enterprise', 'plant', 'area', 'asset', REJECT_REASON_DIMENSION].includes(dimension))) {
            return translator('editors.spark_dimension.validation.chronogram_with_only_primary_dimensions');
        }
        else if (!metric && visualization !== DIMENSION_VISUALIZATION_TYPES.TEXT_VISUALIZATION_TYPE) {
            return translator('editors.spark_dimension.validation.need_metric');
        }
        else if (metric && metric !== REJECT_COUNT_METRIC && dimension === REJECT_REASON_DIMENSION) {
            return translator('editors.spark_dimension.validation.reject_reason_needs_reject_count');
        }
        else {
            return '';
        }
    }, [dimension, metric, visualization, translator]);

    const dimensionList = useMemo(() => {
        const unselectableFields = initialCategoryColumn
            ? selectedColumns.filter(col => col.name !== initialCategoryColumn.name)
            : selectedColumns;
        const filteredFields = filterGroupedFields(fieldOptions.category_value || [], unselectableFields);

        return filteredFields.map((option) => {
            return {
                ...option,
                isDummyGroup: false,
            };
        });
    }, [fieldOptions, selectedColumns, initialCategoryColumn]);

    const selectedDimension = useMemo(() => {
        const foundOption = findOptionRecursive(dimensionList, (opt) => {
            return opt.name === dimension;
        });

        return foundOption || null;
    }, [ dimensionList, dimension ]);

    const handleDimensionChange = useCallback((e) => {
        const column = e.target.value.name;
        setDimension(column);
        if (column === REJECT_REASON_DIMENSION && metric) {
            setMetric(REJECT_COUNT_METRIC);
        }
    }, [metric]);

    const handleVisualizationChange = useCallback((e) => {
        const value = e.target.value.value;

        if (value === CHRONOGRAM_VISUALIZATION_TYPE) {
            setOrder(TIME_ORDER);
            setMetric(DURATION_METRIC);
            setLimit(null);
        }
        else {
            if (value === TEXT_VISUALIZATION_TYPE) {
                setLimit(null);
            }
            else if (!limit) {
                setLimit(10);
            }

            if (dimension === REJECT_REASON_DIMENSION && metric) {
                setMetric(REJECT_COUNT_METRIC);
            }
            else {
                setMetric((currentMetric) => {
                    // Check that the current metric is valid for this
                    // visualization type, otherwise set it to null.
                    const newMetricOptions = getMetricOptions(fieldOptions, value);
                    if (!findMetricOption(newMetricOptions, currentMetric)) {
                        // Metric not available as an option anymore.
                        return null;
                    }
                    else {
                        // Metric is still valid; no changes.
                        return currentMetric;
                    }
                });
            }

            setOrder(DIMENSION_ORDER);
        }
        setVisualization(value);
    }, [fieldOptions, dimension, limit, metric]);

    const handleMetricChange = useCallback((e) => {
        const value = e.target.value.name;
        if (typeof value !== 'string') {
            throw new Error('handleMetricChange: Bad value for metric');
        }
        setMetric(value === NO_METRIC ? null : value);
    }, []);

    const handleOrderChange = useCallback((e) => {
        const value = e.target.value.value;
        setOrder(value);
    }, []);

    const handleLimitChange = useCallback((e) => {
        const value = e.target.value;
        setLimit(value);
    }, []);

    const handleOnChange = useCallback(() => {
        const sparkDimension = {
            ...selectedDimension,
            name: selectedDimension.name,
            channel: selectedDimension.channel,
            displayName: selectedDimension.displayName,
            dimensionVisualization: {
                visualization,
                metric: metric,
                orderBy: order,
                maxElementsShown: limit,
            },
        };

        let newSelectedColumns = selectedColumns;

        if (!Number.isFinite(selectedColumnIndex)) {
            newSelectedColumns = selectedColumns.concat([sparkDimension]);
        }
        else {
            if (selectedColumns.length < selectedColumnIndex) {
                console.warn(`Can not update column at index ${selectedColumnIndex} there are only ${selectedColumns.length} columns.`);
            }
            else {
                // Array.splice is in-place, so copy the array before.
                newSelectedColumns = [ ...selectedColumns ];
                newSelectedColumns.splice(selectedColumnIndex, 1, sparkDimension);
            }
        }

        onChange({
            target: {
                value: newSelectedColumns,
            }
        });
    }, [selectedColumns, onChange, selectedColumnIndex, limit, metric, order, visualization, selectedDimension]);

    const visualizationOptions = useMemo(() => getVisualizationOptions(translator), [ translator ]);
    const selectedVisualizationOption = useMemo(() => {
        return visualizationOptions.find((opt) => {
            return opt.value === visualization;
        });
    }, [ visualizationOptions, visualization ]);

    const metricOptions = useMemo(() => {
        return [
            {
                name: NO_METRIC,
                displayName: translator('none_literal'),
                isDummyGroup: true,
                entries: [{
                    name: NO_METRIC,
                    displayName: translator('bracket_none_literal'),
                }]
            }
        ].concat(getMetricOptions(fieldOptions, visualization));
    }, [fieldOptions, visualization, translator]);

    const selectedMetric = useMemo(() => {
        return findMetricOption(metricOptions, metric);
    }, [ metricOptions, metric ]);

    const orderOptions = useMemo(() => getOrderOptions(translator), [ translator ]);
    const selectedOrderOption = useMemo(() => {
        if (order === TIME_ORDER) {
            return {
                value: TIME_ORDER,
                displayName: translator('widget.spark_visualization.dimension_visualization_options.order_by.by_time'),
            };
        }
        else {
            return orderOptions.find((opt) => {
                return opt.value === order;
            });
        }
    }, [ orderOptions, order, translator ]);

    const categoryPlaceholderText = translator('editors.filter.dropdown_placeholder', {value: translator('value_literal').toLowerCase()});
    const metricPlaceholderText = translator('editors.filter.dropdown_placeholder', {value: translator('value_literal').toLowerCase()});

    return (
        <React.Fragment>
            <HelperTextContainer>
                {translator('widget.spark_visualization.add_dimension_helper_text')}
            </HelperTextContainer>
            <ListDropdownContainer>
                <SparkColumnLabel>{translator('widget.spark_visualization.dimension')}</SparkColumnLabel>
                <SparkColumnContainer>
                    <CategorySelectField
                        name={'dimension'}
                        selectedItem={selectedDimension || {name: '', displayName: ''}}
                        options={dimensionList}
                        showFlattenCheckbox={true}
                        collapsibleGroups={false}
                        onChange={handleDimensionChange}
                        placeholderText={categoryPlaceholderText}
                    />
                </SparkColumnContainer>
            </ListDropdownContainer>
            <ListDropdownContainer>
                <SparkColumnLabel>{translator('widget.spark_visualization.visualization')}</SparkColumnLabel>
                <SparkColumnContainer>
                    <BasicSelectField
                        name='visualization'
                        onChange={handleVisualizationChange}
                        options={visualizationOptions}
                        selectedValue={visualization}
                        selectedDisplayName={selectedVisualizationOption.displayName}
                    />
                </SparkColumnContainer>
            </ListDropdownContainer>
            <ListDropdownContainer>
                <SparkColumnLabel>{translator('widget.spark_visualization.metric')}</SparkColumnLabel>
                <SparkColumnContainer>
                    <MetricSelectField
                        name={'metric'}
                        selectedItem={selectedMetric || {name: '', displayName: ''}}
                        options={metricOptions}
                        showFlattenCheckbox={true}
                        collapsibleGroups={true}
                        onChange={handleMetricChange}
                        disabled={visualization === CHRONOGRAM_VISUALIZATION_TYPE}
                        placeholderText={metricPlaceholderText}
                    />
                </SparkColumnContainer>
            </ListDropdownContainer>
            {metric && (<ListDropdownContainer>
                    <SparkColumnLabel>{translator('widget.spark_visualization.order')}</SparkColumnLabel>
                    <SparkColumnContainer>
                        <BasicSelectField
                            name={'order'}
                            onChange={handleOrderChange}
                            options={orderOptions}
                            selectedValue={order}
                            selectedDisplayName={selectedOrderOption.displayName}
                            disabled={visualization === CHRONOGRAM_VISUALIZATION_TYPE}
                        />
                    </SparkColumnContainer>
                </ListDropdownContainer>
            )}
            {usesLimit && (
                <LimitContainer>
                    <SparkColumnLabel>{translator('widget.spark_visualization.max_elements')}</SparkColumnLabel>
                    <NumberFieldContainer>
                        <NumberField
                            name='max_elements'
                            onChange={handleLimitChange}
                            placeholder={visualization !== CHRONOGRAM_VISUALIZATION_TYPE ? limit?.toString() || '': ''}
                            value={limit}
                            error={limitNumberError}
                            showErrorInline={false}
                        />
                    </NumberFieldContainer>
                </LimitContainer>
            )}
            <LimitErrorContainer>
                <SparkColumnLabel/>
                <InlineError>
                    {limitNumberError}
                </InlineError>
            </LimitErrorContainer>
            {configurationError && (
                <ValidationContainer>
                    <ValidationText>
                        {configurationError}
                    </ValidationText>
                </ValidationContainer>
            )}
            <ApplyContainer>
                <Button
                    onClick={handleOnChange}
                    disabled={
                        !dimension
                        || limitNumberError
                        || configurationError
                        || !hasChanges({dimension, visualization, metric, order, limit }, initialCategoryColumn)
                    }
                >
                    {translator('apply_literal')}
                </Button>
            </ApplyContainer>
        </React.Fragment>
    );
};

const categoryValueMetricOptionsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        name: PropTypes.string.isRequired
    })
);

SparkDimensionColumnSelector.propTypes = {
    fieldOptions: PropTypes.shape({
        category_value: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
        })),
        category_value_metrics: PropTypes.exact({
            [BAR_VISUALIZATION_TYPE]: categoryValueMetricOptionsPropType.isRequired,
            [CHRONOGRAM_VISUALIZATION_TYPE]: categoryValueMetricOptionsPropType.isRequired,
            [COLUMN_VISUALIZATION_TYPE]: categoryValueMetricOptionsPropType.isRequired,
            [TEXT_VISUALIZATION_TYPE]: categoryValueMetricOptionsPropType.isRequired,
        }),
    }).isRequired,
    selectedColumnIndex: PropTypes.number,
    selectedColumns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired
    })),
    startingValue: PropTypes.shape({
        name: PropTypes.string,
        metric: PropTypes.string
    }),

    onChange: PropTypes.func.isRequired,
};

export default SparkDimensionColumnSelector;
