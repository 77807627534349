import { fromISODateString } from 'Common/util/format';
import { exformat } from 'Common/util/language/string';
import {
    EPOCH_JULIAN_DAY,
    fromJulianDay, toJulianDay, addToJulianDay, getJulianDayOfWeek,
    clearTime,
    getWeekNumber as getWeekNumberFromDate
} from 'Common/util/language/date';
import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';

const YEARS = 'years',
    QUARTERS = 'quarters',
    MONTHS = 'months',
    WEEKS = 'weeks',
    DAYS = 'days';

/**
 * Get a production day number from a julian day.
 * @param  {Number} julianDay
 * @return {Number}
 */
function getNumberFromJulianDay(julianDay) {
    return julianDay - EPOCH_JULIAN_DAY;
}

/**
 * Get a production day number from a year, month, day tuple
 * @param  {Number} year
 * @param  {Number} month
 * @param  {Number} day
 * @return {Number}
 */
function getNumberFromYMD(year, month, day) {
    return getNumberFromJulianDay(toJulianDay(year, month, day));
}

/**
 * Get a production day number from an ISO string
 * @param  {String} str
 * @return {Number}
 */
function getNumberFromISOString(str) {
    var matcher = RegExp(/(\d+)-(\d+)-(\d+)/);

    if (!matcher.test(str)) {
        throw new Error(`Expected YYYY-MM-DD, but got "${str}"`);
    }

    var m = matcher.exec(str),
        year = parseInt(m[1], 10),
        month = parseInt(m[2], 10),
        date = parseInt(m[3], 10);

    return getNumberFromYMD(year, month, date);
}

/**
 * @class ProductionDay
 *
 * Represents a day of production that is linked with (but not necessarily entirely coincident with)
 * a particular calendar day.
 */
export class ProductionDay {
    /**
     * Create a production day from one of a number of sets of arguments:
     *
     * * 1-argument form can be:
     *    * {ProductionDay} - copy constructor
     *    * {Number[]} - treats the items of the array as year, month, and day
     *    * {Date} - selects the production day associated with this JS Date
     *    * {String} - an ISO 8601 date string
     *    * {Number} - the number of the production day
     *
     * * 2-argument form:
     *    * {Number}, {String} - if the second argument is "julian", the first argument is treated
     *    as a julian day, otherwise it is treated as a production day number (as the 1-argument)
     *
     * * 3-argument form:
     *    * {Number}, {Number}, {Number} - treats the arguments as year, month, and day
     */
    constructor(arg1, arg2, arg3) {
        /* eslint-disable no-use-before-define */
        if (arg1 instanceof CalendarDay) {
            this._number = getNumberFromISOString(arg1.toDate().toISOString());
        }
        /* eslint-enable no-use-before-define */
        else if (arg1 instanceof ProductionDay) {
            this._number = arg1.getProductionDayNumber();
        }
        else if (isArray(arg1)) {
            this._number = getNumberFromYMD(arg1[0], arg1[1], arg1[2]);
        }
        else if (arguments.length === 3) {
            this._number = getNumberFromYMD(arg1, arg2, arg3);
        }
        else if (isDate(arg1)) {
            this._number = getNumberFromISOString(arg1.toISOString());
        }
        else if (isString(arg1)) {
            this._number = getNumberFromISOString(arg1);
        }
        else if (isNumber(arg1)) {
            if (arg2 === 'julian') {
                this._number = getNumberFromJulianDay(arg1);
            }
            else {
                this._number = arg1;
            }
        }
        else {
            throw new Error(`Unsupported constructor argument "${String(arg1)}"`);
        }
    }
    /**
     * Convert to a year, month, day tuple
     * @return {Number[]}
     */
    toYearMonthDay() {
        return fromJulianDay(this.toJulianDay());
    }
    /**
     * Convert to an ISO 8601 date string.
     * @return {String}
     */
    toString() {
        var ymd = this.toYearMonthDay();

        return exformat('{Y}-{Mz}{M}-{Dz}{D}', {
            Y: ymd[0],
            Mz: ymd[1] < 10 ? '0' : '',
            M: ymd[1],
            Dz: ymd[2] < 10 ? '0' : '',
            D: ymd[2]
        });
    }
    /**
     * Convert to a JS Date
     * @return {Date}
     */
    toDate() {
        return fromISODateString(this.toString());
    }
    /**
     * Convert to a julian day
     * @return {Number}
     */
    toJulianDay() {
        return this._number + EPOCH_JULIAN_DAY;
    }
    /**
     * Get the year associated with this day.
     * @return {Number}
     */
    getYear() {
        return this.toYearMonthDay()[0];
    }
    /**
     * Get the quarter (1-4) associated with this day.
     * @return {Number}
     */
    getQuarter() {
        return Math.floor((this.getMonth() - 1) / 3) + 1;
    }
    /**
     * Get the month (1-12) associated with this day.
     * @return {Number}
     */
    getMonth() {
        return this.toYearMonthDay()[1];
    }
    /**
     * Get the day of month (1-31) associated with this day
     * @return {Number}
     */
    getDate() {
        return this.toYearMonthDay()[2];
    }
    /**
     * Get the production day number associated with this day
     * @return {Number}
     */
    getProductionDayNumber() {
        return this._number;
    }
    /**
     * Get the day of week associated wtih this day.
     *
     * @param {Number} [productionWeekStartsOn=1] JS day of week for when the production week
     *   starts.
     * @return {Number} JS day of week
     */
    getDayOfWeek(productionWeekStartsOn) {
        productionWeekStartsOn = isNumber(productionWeekStartsOn) ? productionWeekStartsOn : 1;

        var dow = getJulianDayOfWeek(this.toJulianDay());

        if (productionWeekStartsOn > 0) {
            dow = (dow - productionWeekStartsOn + 7) % 7;
        }

        return dow;
    }
    /**
     * gets the week number associated with this day
     * @param Number startDOW the day of week to use as week start 0=Sunday
     */
    getWeekNumber() {
        var dt = new Date(),
            ymd = this.toYearMonthDay();

        clearTime(dt);
        dt.setHours(12);
        // setMonth() does something weird if the date is the 31st and you try to change to a month
        // without 31 days, so set the date to something innocuous before we try that.
        dt.setDate(2);

        dt.setFullYear(ymd[0]);
        dt.setMonth(ymd[1] - 1);
        dt.setDate(ymd[2]);

        return getWeekNumberFromDate(dt);
    }
    /**
     * @private
     * Set the year, month, and day of this production day.
     * Zero or a negative number means "leave it alone"
     *
     * @param {Number} year
     * @param {Number} month
     * @param {Number} day
     */
    _setYearMonthDay(year, month, day) {
        var ymd = this.toYearMonthDay();
        if (year > 0) { ymd[0] = year; }
        if (month > 0) { ymd[1] = month; }
        if (day > 0) { ymd[2] = day; }

        this._number = getNumberFromYMD(ymd[0], ymd[1], ymd[2]);
    }
    /**
     * Set the day of month (1-31) associated with this day.
     * @param {Number} newDay
     * @return {ProductionDay} this; allows chaining
     */
    setDate(newDay) {
        this._setYearMonthDay(-1, -1, newDay);
        return this;
    }
    /**
     * Set the month (1-12) associated with this day.
     * @param {Number} newMonth
     * @return {ProductionDay} this; allows chaining
     */
    setMonth(newMonth) {
        this._setYearMonthDay(-1, newMonth, -1);
        return this;
    }
    /**
     * Adds a value to the production day. This returns a new production day, and does not
     * mutate `this`.
     *
     * @param {String} portion the name of the portion (e.g., 'years', 'weeks', 'days')
     * @param {Number} value how many of portion to add
     * @return {ProductionDay} A new production day with the appropriate amount added
     */
    add(portion, value) {
        var jd = this.toJulianDay(),
            newJd = addToJulianDay(jd, portion, value);

        return new ProductionDay(newJd, 'julian');
    }
    /**
     * Subtracts a value from this production day. See `add` for details.
     */
    subtract(portion, value) {
        return this.add(portion, -value);
    }
    addDays(value) {
        return this.add('days', value);
    }
    subtractDays(value) {
        return this.add('days', -value);
    }
    addWeeks(value) {
        return this.add('weeks', value);
    }
    subtractWeeks(value) {
        return this.add('weeks', -value);
    }
    /**
     * Get the distance (in days) between `this` and `that`
     * @param  {Variant} that 1-argument constructible to a production day
     * @return {Number} > 0 if `this` is before `that`
     */
    getDistanceTo(that) {
        that = new ProductionDay(that);
        return that.getProductionDayNumber() - this.getProductionDayNumber();
    }
    /**
     * Compare two production days
     * @param  {Variant} that 1-argument constructible to a production day
     * @return {Number} negative number: this < that; 0: this === that; positive number: this > that
     */
    compare(that) {
        // The sign here is reversed from `getDistanceTo`. That's the only difference.
        //
        return -this.getDistanceTo(that);
    }
    /**
     * Are these two things equal?
     *
     * @param  {Variant} that Anything constructible (with one argument) to a ProductionDay
     * @return {Boolean}
     */
    equals(that) {
        return this.compare(that) === 0;
    }
    /**
     * Are these two things unequal?
     *
     * @param  {Variant} that Anything constructible (with one argument) to a ProductionDay
     * @return {Boolean}
     */
    notEquals(that) {
        return this.compare(that) !== 0;
    }
    /**
     * Does this occur after that?
     *
     * @param  {Variant} that Anything constructible (with one argument) to a ProductionDay
     * @return {Boolean}
     */
    isAfter(that) {
        return this.compare(that) > 0;
    }

    /**
     * Does this occur on or after that?
     */
    isOnOrAfter(that) {
        return this.compare(that) >= 0;
    }

    /**
     * Does this occur before that?
     *
     * @param  {Variant} that Anything constructible (with one argument) to a ProductionDay
     * @return {Boolean}
     */
    isBefore(that) {
        return this.compare(that) < 0;
    }

    /**
     * Does this occur on or before that?
     *
     * @param  {Variant} that Anything constructible (with one argument) to a ProductionDay
     * @return {Boolean}
     */
    isOnOrBefore(that) {
        return this.compare(that) <= 0;
    }
}

/**
 * @class CalendarDay
 *
 * In addition to ProductionDay (which needn't necessarily transition at midnight) we also
 * support the concept of CalendarDay (which does). It can also be represented as a number,
 * and supports the same methods, but exists just for reasons of type safety.
 */
export class CalendarDay extends ProductionDay {
    getProductionDayNumber() {
        // We're trying to avoid confusion, so don't allow getProductionDayNumber.
        throw new Error('CalendarDay does not support getProductionDayNumber.');
    }

    /**
     * Get the calendar day number associated with this day
     * @return {Number}
     */
    getCalendarDayNumber() {
        return this._number;
    }
}

/**
 * Given a production day, return an array indicating the production days of the
 * various time-ish boundaries
 *
 * @param {ProductionDay|Number} pd
 * @param {String} category
 *
 * @return {Object}
 * @return {Array}[0] start epoch day of category bound
 * @return {Array}[1] end epoch day of category bound
 *
 */
 export function getCategoryBounds(pd, category) {
    let rv, end, portion;

    let start = new ProductionDay(pd);

    if (category === 'day' || category === 'hour' || category === 'shift_hour') {
        // Don't do anything -- since no portion is set, start will === end.
    }
    else if (category === 'week') {
        start = start.add(DAYS, -start.getDayOfWeek());
        portion = WEEKS;
    }
    else if (category === 'month') {
        start.setDate(1);
        portion = MONTHS;
    }
    else if (category === 'quarter') {
        start.setMonth(start.getQuarter() * 3 - 2);
        start.setDate(1);

        portion = QUARTERS;
    }
    else if (category === 'year') {
        start.setMonth(1);
        start.setDate(1);

        portion = YEARS;
    }

    // We subtract a day here so that if `start` is at the beginning of a period (e.g.
    // (e.g., 2015-1-1), `end` is at the end of that period (e.g., 2015-12-31) instead of
    // at the beginning of the next period (e.g., 2016-1-1).
    //
    end = portion
        ? start.add(portion, 1).add(DAYS, -1)
        : start;

    rv = [
        start.getProductionDayNumber(),
        end.getProductionDayNumber()
    ];

    return rv;
}

/**
 * takes a numeric production day number and converts it to a production week number each week starts on
 * Monday.
 *
 * @param  {Number} production day number
 * @return {Number} a production week number
 */
export function productionDayToProductionWeek(pd) {
    return Math.trunc((pd + 3) / 7).toString();
}

/**
 * takes a numeric production week number and creates a ProductionDay object representing the first
 * day of that production week (hard coded as Monday for now).
 * each week starts on Monday
 *
 * @param {Number} a production week number
 * @return  {Number} production day number representing the first day of the sent production week
 */
export function productionWeekToProductionDay(week) {
    return new ProductionDay(week * 7 - 3);
}

/**
 * takes a numeric production day and converts it to a production month of form Year,Month.
 *
 * @param  {Number} production day number
 * @return {string} production month of the form "YYYY,MM"
 */
export function productionDayToProductionMonth(pd) {
    const pdObj = new ProductionDay(pd);
    let month = pdObj.getMonth();

    // pad with leading zeros so string sorting of numbers will work
    month = month.toString().padStart(2, '0');

    return `${pdObj.getYear()},${month}`;
}

/**
 * takes a numeric production day and converts it to a production Quarter of form Year,Quarter.
 *
 * @param  {Number} production day number
 * @return {string} production Quarter of the form "YYYY,Q"
 */
export function productionDayToProductionQuarter(pd) {
    const pdObj = new ProductionDay(pd);
    return `${pdObj.getYear()},${pdObj.getQuarter()}`;
}
