import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FrameTitle from 'Common/components/WidgetFrame/FrameTitle';
import SectionFrameTitle from 'Common/components/WidgetFrame/SectionFrameTitle';
import TimeRangeDisplaySmall from 'Common/components/TimeRangeSelector/TimeRangeDisplaySmall';
import FilterBuilderText from 'Common/components/FilterBuilderText/FilterBuilderText';
import TimeRangeDisplay from 'Common/components/TimeRangeSelector/TimeRangeDisplay';

import useBounds from 'Common/hooks/useBounds';
import { useTranslator } from 'Common/hooks/useTranslation';

const Container = styled.div`
    position: relative;
    display: block;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
`;

const FilterBuilderTextContainer = styled.div`
    padding: 0 ${({theme}) => theme.layout.widget.horizontalPadding };
    min-height: 19px;
`;

/**
 * Container for FrameTitle, TimeRangeSmall, and FilterBuilderTextContainer. Added to manage time range visibility on resizing of widgets 
 */
export default function FrameHeader({
    hierarchyNodes,
    hierarchyNodeValue,
    defaultTitleText,
    isPrint,
    showAsset,
    showDelete,
    shouldShowLiveControls,
    canEditTitle,
    showLiveControlsOnClick,
    showLiveControlsButton,
    titleValue,
    titleOnChange,
    infoTooltipContent,
    deleteOnClick,
    isSectionFrameTitle,
    isCollapsed,
    sectionOnClick,
    formattedFilters,
    isDragEnabled,
    dragHandleProps,
    timeRangeDescription,
})
{
    const [container, setContainer] = useState(null);
    const containerBounds = useBounds(container);
    const translator = useTranslator();

    const timeRangeBreakpointWidth = 500;
    const doesTimeRangeFit = containerBounds?.width > timeRangeBreakpointWidth;

    const { assetValue, assetError } = useMemo(() => {
        const fullHierarchyNode = hierarchyNodes.find(option => (option.name === hierarchyNodeValue));

        if (fullHierarchyNode) {
            return {
                assetValue: fullHierarchyNode?.displayName,
                assetError: '',
            };
        }
        else if (hierarchyNodes.length) {
            // We've loaded the hierarchy options but can't find it; show that
            // it's not available.
            return {
                assetValue: translator('na_symbol'),
                assetError: 'Missing Node',
            };
        }
        else {
            return {
                assetValue: '',
                assetError: '',
            };
        }
    }, [ hierarchyNodeValue, hierarchyNodes, translator ]);

    const renderFilterText = useCallback(() => {
        let finalFiltersText = null;
        if (formattedFilters?.length && (!shouldShowLiveControls || isPrint)) {
            finalFiltersText = (
                <FilterBuilderTextContainer>
                    <FilterBuilderText formattedFilters={formattedFilters} />
                </FilterBuilderTextContainer>
            );
        }

        return finalFiltersText;
    }, [formattedFilters, shouldShowLiveControls, isPrint]);

    const timeRangeDisplay = !isSectionFrameTitle && doesTimeRangeFit && timeRangeDescription ?
        (
        <TimeRangeDisplay
            onClick={undefined}
            description={timeRangeDescription}
            isDropdownTrigger={false}
        />
        )
        : null;

    const frame = isSectionFrameTitle
        ? (<SectionFrameTitle
            isCollapsed={isCollapsed}
            isPrint={isPrint}
            titleValue={titleValue}

            sectionOnClick={sectionOnClick}
        />)
        : (<FrameTitle
            assetValue={assetValue}
            assetError={assetError}
            defaultTitleText={defaultTitleText}
            isPrint={isPrint}
            showLiveControlsButton={showLiveControlsButton}
            shouldShowLiveControls={shouldShowLiveControls}
            canEditTitle={canEditTitle}
            showAsset={showAsset}
            showDelete={showDelete}
            titleValue={titleValue}
            infoTooltipContent={infoTooltipContent}
            showLiveControlsOnClick={showLiveControlsOnClick}
            titleOnChange={titleOnChange}
            deleteOnClick={deleteOnClick}
            timeRangeDisplay={timeRangeDisplay}
            isDragEnabled={isDragEnabled}
            dragHandleProps={dragHandleProps}
        />);
    let timeRangeDetail = '';

    if (timeRangeDescription?.primary.detail) {
        timeRangeDetail = `(${timeRangeDescription.primary.detail})`;
    }
    return (
        <Container ref={setContainer}>
            {frame}
            {!isSectionFrameTitle && !doesTimeRangeFit && timeRangeDescription &&
                (<TimeRangeDisplaySmall
                    title={timeRangeDescription.primary.title}
                    detail={timeRangeDetail}
                />)
            }
            {renderFilterText()}
        </Container>
    );
}

FrameHeader.propTypes = {
    hierarchyNodes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    hierarchyNodeValue: PropTypes.string,
    defaultTitleText: PropTypes.string,
    isPrint: PropTypes.bool.isRequired,
    shouldShowLiveControls: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    showAsset: PropTypes.bool,
    showDelete: PropTypes.bool,
    showLiveControlsButton: PropTypes.bool,
    titleValue: PropTypes.string,
    infoTooltipContent: PropTypes.node,

    showLiveControlsOnClick: PropTypes.func,
    titleOnChange: PropTypes.func,
    deleteOnClick: PropTypes.func,

    isSectionFrameTitle: PropTypes.bool,
    formattedFilters: PropTypes.array,
    isCollapsed: PropTypes.bool,
    timeRangeDescription: PropTypes.object,
    sectionOnClick: PropTypes.func,
    isDragEnabled: PropTypes.bool,
    dragHandleProps: PropTypes.object,
};

FrameHeader.defaultProps = {
    defaultTitleText: '',
    showAsset: false,
    showDelete: false,
    infoTooltipContent: '',
    isDragEnabled: false,
    dragHandleProps: {},

    deleteOnClick: () => {},
    showLiveControlsOnClick: () => {},
    titleOnChange: () => {},
};
