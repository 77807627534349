import React, {
    useState,
    useCallback,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Primary5, Secondary5 } from 'Common/components/typography';
import { RadioButton, RadioButtonGroup } from 'Common/components/RadioButton';
import Button from 'Common/components/Button';
import InlineError from 'Common/components/Error/InlineError';
import { useTranslator } from 'Common/hooks/useTranslation';
import Filters from 'Common/types/Filters/Filters';

const Root = styled.div`
    padding: 5px 14px 5px 14px;
`;

const Instructions = styled(Secondary5).attrs({ as: 'div' })`
    padding-bottom: 8px;
`;

const IncludeLabel = styled(Primary5).attrs({ as: 'div' })`
    margin-bottom: 5px;
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
    padding-left: 10px;
`;

const ApplyContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledInlineError = styled(InlineError)`
    max-width: 275px;
`;

const INCLUDE_ALL_COMMENTS = 'includeAllComments';
const INCLUDE_EVENT_COMMENTS = 'includeEventComments';

function getRadioValue(includeAllComments) {
    return includeAllComments ? INCLUDE_ALL_COMMENTS : INCLUDE_EVENT_COMMENTS;
}

function getIncludeAllCommentsValue(radioValue) {
    if (radioValue !== INCLUDE_ALL_COMMENTS && radioValue !== INCLUDE_EVENT_COMMENTS) {
        throw new Error(`Invalid radioValue: "${radioValue}"`);
    }

    // includeAllComments is a boolean parameter in the CommentListColumn config
    return radioValue === INCLUDE_ALL_COMMENTS;
}

function hasChanges(initialValue, radioValue) {
    if (initialValue) {
        const initialRadioValue = getRadioValue(initialValue.commentList.includeAllComments);

        return initialRadioValue !== radioValue;
    }
    else {
        // No initial value? Well anything is change then
        return true;
    }

}

function validateCommentList(translator, value, filters) {
    let error = null;

    const includeAllComments = value?.commentList?.includeAllComments;

    if (includeAllComments && Filters.hasAnySliceFilters(filters)) {
        if (filters.slice.expressions.some((expression) => expression.rhs !== null)) {
            error = translator('control.column.comment_list.invalid_with_filters');
        }
    }

    return error;
}

/**
 * A component containing all that is needed to add a Comment Column
 * as a column on a table (the column and what types of comments it shows).
 *
 */
function CommentListColumnSelector({
    fieldOptions,
    selectedColumnIndex,
    selectedColumns,
    onChange,
    startingValue,
    filterValue
}) {
    const translator = useTranslator();
    const initialValue = useMemo(() => {
        // There is only one option for CommentLists, and that serves as our default
        return Number.isFinite(selectedColumnIndex) ? selectedColumns[selectedColumnIndex] : startingValue || { ...fieldOptions.comment_list[0] };
    }, [selectedColumnIndex, selectedColumns, startingValue, fieldOptions]);

    const [value, setValue] = useState(initialValue);

    const onRadioGroupChange = useCallback((event) => {
        const newRadioValue = event.target.value;

        if (hasChanges(value, newRadioValue)) {
            const newValue = {
                ...value,
                commentList: {
                    includeAllComments: getIncludeAllCommentsValue(newRadioValue),
                },
            };

            setValue(newValue);
        }
    }, [value]);

    const error = validateCommentList(translator, value, filterValue);

    const onApply = useCallback(() => {
        let newSelectedColumns = selectedColumns;
        if (Number.isFinite(selectedColumnIndex)) {
            if (selectedColumns.length < selectedColumnIndex) {
                console.warn(`Can not update column at index ${selectedColumnIndex} there are only ${selectedColumns.length} columns.`);
            }
            else {
                newSelectedColumns = [].concat(selectedColumns);
                newSelectedColumns.splice(selectedColumnIndex, 1, value);
            }
            
        }
        else {
            newSelectedColumns = selectedColumns.concat([value]);
        }
        onChange({
            target: {
                value: newSelectedColumns
            }
        });
    }, [value, selectedColumns, onChange, selectedColumnIndex]);

    const canApplyChanges = useMemo(() => {
        return initialValue?.commentList?.includeAllComments !== value?.commentList?.includeAllComments || !Number.isFinite(selectedColumnIndex);
    }, [initialValue, value, selectedColumnIndex]);

    return (
        <Root>
            <Instructions>
                {translator('control.column.comment_list.instructions')}
            </Instructions>
            <IncludeLabel>
                {translator('control.column.comment_list.include_label')}
            </IncludeLabel>
            <StyledRadioButtonGroup
                value={getRadioValue(value?.commentList?.includeAllComments)}
                onChange={onRadioGroupChange}
            >
                <RadioButton value={INCLUDE_ALL_COMMENTS}>
                    {translator('control.column.comment_list.include_all_comments')}
                </RadioButton>
                <RadioButton value={INCLUDE_EVENT_COMMENTS}>
                    {translator('control.column.comment_list.include_event_comments')}
                </RadioButton>
            </StyledRadioButtonGroup>
            {error && (<StyledInlineError>{error}</StyledInlineError>)}
            <ApplyContainer>
                <Button
                    onClick={onApply}
                    disabled={!canApplyChanges || error}
                >
                    {translator('apply_literal')}
                </Button>
            </ApplyContainer>
        </Root>
    );
}

const commentListPropType = PropTypes.shape({
    includeAllComments: PropTypes.bool.isRequired,
});

const commentListColumnPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    commentList: commentListPropType.isRequired,
});

CommentListColumnSelector.propTypes = {
    fieldOptions: PropTypes.shape({
        comment_list: PropTypes.arrayOf(commentListColumnPropType),
    }).isRequired,
    selectedColumnIndex: PropTypes.number,
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            commentList: commentListPropType,
        }),
    ).isRequired,
    startingValue: commentListColumnPropType,
    filterValue: Filters.propType,
    onChange: PropTypes.func.isRequired,
};

export default CommentListColumnSelector;
