import styled from 'styled-components';

export const MASK_Z_INDEX = 80;

const Mask = styled.div`
    cursor: default;
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${MASK_Z_INDEX};
`;

export default Mask;
