import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicSelectOption from './BasicSelectOption';
import SelectList from 'Common/components/SelectList';
import SelectSeparator from './SelectSeparator';

/**
 * A simple SelectList that takes a list of basic options and provides
 * some default behavior.
 *
 */
function BasicSelectList({
    name,
    value: selectedOption,
    options,
    onChange,
    verticalPadding,
    searchValue,
}) {
    const handleChange = (event) => {
        const { value } = event.target;

        onChange({
            target: {
                name,
                value,
            },
        });
    };

    const getOptionName = (opt) => {
        return opt.displayName;
    };

    const optionElements = useMemo(() => {
        // Insert a separator between all options only if there is a description
        // in at least one of the options.
        const separateAll = Boolean(options.find((opt) => {
            return Boolean(opt.description);
        }));

        return options.reduce((acc, option, index) => {
            const optionKey = option.value;

            if (separateAll && index !== 0) {
                acc.push(
                    <SelectSeparator
                        // If the options' keys are unique, then the keys with a
                        // prefix will also be unique.
                        key={`separator-${optionKey}`}
                    />
                );
            }

            acc.push(
                <BasicSelectOption
                    key={optionKey}
                    value={option.value}
                    searchValue={getOptionName(option)}
                    icon={option.icon}
                    description={option.description}
                >
                    {getOptionName(option)}
                </BasicSelectOption>
            );

            return acc;
        }, []);
    }, [ options ]);

    return (
        <SelectList
            onChange={handleChange}
            value={selectedOption}
            verticalPadding={verticalPadding}
            searchValue={searchValue}
        >
            {optionElements}
        </SelectList>
    );
}

BasicSelectList.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape( {
        value: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        description: PropTypes.string,
        icon: PropTypes.node,
    })),
    onChange: PropTypes.func,
    verticalPadding: PropTypes.number,
    searchValue: PropTypes.string,
};

BasicSelectList.defaultProps = {
    name: 'basicSelectList',
    value: '',
    options: [],
    onChange: () => { },
};

export default React.memo(BasicSelectList);
