export const TEXT_VISUALIZATION_TYPE = 'text';
export const BAR_VISUALIZATION_TYPE = 'bar';
export const COLUMN_VISUALIZATION_TYPE = 'column';
export const CHRONOGRAM_VISUALIZATION_TYPE = 'chronogram';

export const DIMENSION_ORDER = 'dimension';
export const VALUE_ORDER = 'value';
export const TIME_ORDER = 'time';

export const TEXT_FORMAT_TYPE = 'spark_dimension_text';
export const BAR_FORMAT_TYPE = 'spark_dimension_bar';
export const COLUMN_FORMAT_TYPE = 'spark_dimension_column';
export const CHRONOGRAM_FORMAT_TYPE = 'spark_dimension_chronogram';

export const DIMENSION_VISUALIZATION_TYPES = {
    TEXT_VISUALIZATION_TYPE,
    BAR_VISUALIZATION_TYPE,
    COLUMN_VISUALIZATION_TYPE,
    CHRONOGRAM_VISUALIZATION_TYPE
};

export const DIMENSION_VISUALIZATION_ORDER_BY_TYPES = {
    DIMENSION_ORDER,
    TIME_ORDER,
    VALUE_ORDER
};

export const DIMENSION_VISUALIZATION_FORMAT_TYPES = {
    TEXT_FORMAT_TYPE,
    BAR_FORMAT_TYPE,
    COLUMN_FORMAT_TYPE,
    CHRONOGRAM_FORMAT_TYPE,
};

export const DIMENSION_VISUALIZATION_TO_TYPE = {
    'bar' : BAR_FORMAT_TYPE,
    'column': COLUMN_FORMAT_TYPE,
    'chronogram': CHRONOGRAM_FORMAT_TYPE,
    'text': TEXT_FORMAT_TYPE
};

export const DURATION_METRIC = 'duration';
