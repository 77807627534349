import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import { FontSize } from 'Common/components/typography';

/**
 * MenuButton
 *
 * Styled button for use in a Menu Toolbar. Shows the button's icon, text, and dropdownicon
 * if supplied.
 *
 */


const StyledMenuItem = styled(MenuItem)`
    border: 1px solid transparent;
    font-size: ${FontSize.size4}px;

    &:active {
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.4) inset;
    }
`;

export default function MenuButton(props) {
    return (
        <StyledMenuItem
            {...props}
        />
    );
}

MenuButton.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    dropdownIcon: PropTypes.node,
    onClick: PropTypes.func.isRequired,
};

MenuButton.defaultProps = {
    disabled: false,
    dropdownIcon: null,
    icon: null,
};
