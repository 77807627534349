
import {
    channel_schema
} from './ChannelSchema';

import {
    filterOptions,
    hierarchyNodeOptions,
    hierarchyNodeDisplayName,
    getKpiData,
    getKpiGroupData,
    metricOptions,
    timeRangePrimaryDetails,
    timeRangeSecondaryDetails,
    emailWidgetFieldOptions,
} from './MockWidgetData';

export default function getDataService(translator, metricThresholds) {
    return {
        getKPIGroupWidgetFieldOptions: async () => {
            return emailWidgetFieldOptions;
        },
        getKPIGroupWidgetData: async (widgetData) => {
            return getKpiGroupData(widgetData, translator, metricThresholds);
        },
        getKpiWidgetData: async (widgetData, a, b) => {
            return getKpiData(widgetData.field.name, translator, metricThresholds);
        },
        getWorkCenterCount: () => {
            return 'Work Center Count';
        },
        getColumnConfig: async (columnName) => {
            const columnConfig = channel_schema.production_metric.metrics[columnName];
            return {
                displayName: translator(columnConfig.display_name),
                formatType: columnConfig.subtype,
                name: columnName,
            };
        },
        getColumnHelpText: (columnName) => {
            const columnConfig = channel_schema.production_metric.metrics[columnName];
            return translator(columnConfig.help_text);
        },
        getTimeRangePrimaryDetails: async () => {
            return timeRangePrimaryDetails;
        },
        getTimeRangeSecondaryDetails: async () => {
            return timeRangeSecondaryDetails;
        },
        getHierarchyNodeDisplayName: async () => {
            return hierarchyNodeDisplayName;
        },
        getHierarchyOptions: () => {
            return hierarchyNodeOptions;
        },
        getAllMetricOptions: () => {
            return metricOptions;
        },
        getAllFilterOptions: () => {
            return filterOptions;
        },
        getMetricOptions: () => {
            return metricOptions;
        },
        getSliceFilterOptions: () => {
            return filterOptions;
        },
        getMultipleColumnConfigs: () => {
            return {
                shift: {
                    name: 'shift',
                    formatType: 'category_value',
                    displayName: 'Shift'
                },
                part: {
                    name: 'part',
                    formatType: 'category_value',
                    displayName: 'Part'
                },
            };
        },
        getMultipleCategoryValueConfigs: ()  => {
            return {};
        },
    };
}
