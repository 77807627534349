import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Caption } from 'Components/typography/typography';
import * as RequestStatus from 'Common/data/RequestStatus';
import Form from 'Components/form/Form';

const LogoutFormBody = styled(Form)`
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
`;

const StatusMessage = styled(Caption)`
    display: block;
`;

class LogoutForm extends React.Component {
    static propTypes = {
        sessionData: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        request: PropTypes.shape({
            status: PropTypes.string.isRequired,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    _handleSubmit = (event) => {
        this.props.onSubmit();
    };

    render() {
        const { sessionData, request } = this.props;
        const isLoading = request.status === RequestStatus.PENDING;

        return (
            <LogoutFormBody
                onSubmit={this._handleSubmit}
                submitButtonText="Log Out"
                allowSubmit={!isLoading}
                showCancel={false}
            >
                <StatusMessage>You are logged in as {sessionData.name}</StatusMessage>
            </LogoutFormBody>
        );
    }
}

export default LogoutForm;
