import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import Page from 'Components/page/Page';
import { Body1 } from 'Components/typography/typography';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentStandard';
import PageContentFull from 'Components/page/PageContentFull';
import ErrorNotice from 'Components/notice/ErrorNotice';
import { login } from 'Redux/actions';
import LoginForm from 'Components/login-form/LoginForm';
import { selectSessionRequest, selectIsLoggedIn } from 'Redux/reducers/session';
import { REQUEST_SUCCESS } from 'Common/util/ResourceCollection/actions';
import verifyEmailChange from 'Redux/actions/verifyEmailChange';
import PageDescription from 'Components/page/PageDescription';
import FaIcon from 'Components/icon/FaIcon';
import ContainedButton from 'Components/button/ContainedButton';

const STEPS = {
    LOGIN: 'LOGIN',
    VERIFY_TOKEN: 'VERIFY_TOKEN',
    COMPLETE: 'COMPLETE',
};

function getTokenFromProps(props) {
    const urlParams = queryString.parse(props.location.search);
    const token = urlParams.t;
    return token;
}

const SuccessPage = styled(Page)`
    max-width: 100%;
    padding: 32px;
`;

const SuccessContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CheckIcon = styled(props => (<span {...props}><FaIcon i="check-circle" /></span>))`
    color: ${props => props.theme.colors.success};
    font-size: 120px;
`;

const PrimarySuccessButton = styled(ContainedButton)`
    text-decoration: none;
`;

export class VerifyEmailChangePagePresentation extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string
        }).isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        requests: PropTypes.shape({
            login: PropTypes.object.isRequired,
            verifyEmailChange: PropTypes.object.isRequired,
        }),
        actions: PropTypes.shape({
            login: PropTypes.func.isRequired,
            verifyEmailChange: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            // If the user is already logged in, just go straight to verifying the token
            currentStep: props.isLoggedIn ? STEPS.VERIFY_TOKEN : STEPS.LOGIN,
        };
    }

    _handleLoginSubmit = async ({ email, password }) => {
        const { actions } = this.props;

        const response = await actions.login({ email, password });

        // If the login request was successful, move to the next step
        if (response.type === REQUEST_SUCCESS) {
            this.setState({
                currentStep: STEPS.VERIFY_TOKEN,
            });
        }
    };

    _submitToken = async (token) => {
        const { actions } = this.props;

        const response = await actions.verifyEmailChange({
            token: token,
        });

        // If the change email request was successful, move to the next step
        if (response.type === REQUEST_SUCCESS) {
            this.setState((state) => {
                return {
                    currentStep: STEPS.COMPLETE,
                    email: response.payload?.email || '',
                };
            });
        }
    };

    _renderLogin() {
        const { requests } = this.props;

        return (
            <Page>
                <PageTitle>Verify Email Update - Log In</PageTitle>
                <PageDescription>
                    Please log in with your current primary email to confirm your email update.
                </PageDescription>
                <PageContent>
                    <LoginForm
                        request={requests.login}
                        onSubmit={this._handleLoginSubmit}
                    />
                </PageContent>
            </Page>
        );
    }

    _renderVerifyToken() {
        const token = getTokenFromProps(this.props);

        return (
            <Page>
                <PageTitle>Verify Email Update</PageTitle>
                <PageContent>
                    {token && (
                        <ContainedButton
                            onClick={() => { this._submitToken(token); }}
                        >
                            Verify Email Address
                        </ContainedButton>
                    )}
                    {!token && (
                        <ErrorNotice>
                            <Body1 as="div">
                                The link used to come to this page is invalid.
                            </Body1>
                        </ErrorNotice>
                    )}
                </PageContent>
            </Page>
        );
    }

    _renderComplete() {
        return (
            <SuccessPage>
                <PageContentFull>
                    <SuccessContent>
                        <CheckIcon />
                        <PageTitle>Success!</PageTitle>
                        <PageDescription>
                            You have successfully updated your email address.
                        </PageDescription>
                        <PrimarySuccessButton
                            color="primary"
                            Component={Link}
                            to="/config/user/profile"
                        >
                            Continue
                        </PrimarySuccessButton>
                    </SuccessContent>
                </PageContentFull>
            </SuccessPage>
        );
    }

    render() {
        const { currentStep } = this.state;

        switch (currentStep) {
            case STEPS.VERIFY_TOKEN:
                return this._renderVerifyToken();
            case STEPS.LOGIN:
                return this._renderLogin();
            case STEPS.COMPLETE:
                return this._renderComplete();
            default:
                throw new Error(`Unknown step: ${currentStep}`);
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: selectIsLoggedIn(state.session),
        requests: {
            login: selectSessionRequest(state.session, 'login'),
            verifyEmailChange: selectSessionRequest(state.session, 'verifyEmailChange'),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            login: login,
            verifyEmailChange: verifyEmailChange,
        }, dispatch),
    };
};

// connect the component to Redux
const VerifyEmailChangePage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyEmailChangePagePresentation));

export default VerifyEmailChangePage;
