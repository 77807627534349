import { useEffect } from 'react';

const DEFAULT_UPDATE_INTERVAL_SECONDS = 10;

/**
 * Determine the data update interval time.
 * @param {String|Number} updateTimeInterval is either the string default or the number of seconds
 * @param {*} defaultInterval default number of seconds
 * @returns intervalTime in milliseconds
 */
export const getDataUpdateInterval = (updateTimeInterval, defaultInterval = DEFAULT_UPDATE_INTERVAL_SECONDS) => {
    let rv = null;

    // updateTimeInterval could be zero, which is falsey
    if (updateTimeInterval) {
        if (typeof updateTimeInterval === 'string') {
            if (updateTimeInterval === 'default') {
                rv = defaultInterval * 1e3;
            }
            else {
                throw new Error('Invalid time interval type: ' + updateTimeInterval);
            }
        }
        else {
            if (Number.isFinite(updateTimeInterval) && updateTimeInterval > 0) {
                // Convert the update time interval to milliseconds
                rv = updateTimeInterval * 1e3;
            }
        }
    }

    return rv;
};

/**
 * Get a good max age (i.e., expiration) for any cache that might be attached to
 * a request sent through `useRepeatingRequest`.
 *
 * Generally, this value will be slightly less than the `updateInterval` so that
 * when the request is resent the cached value will have expired.
 *
 * @param {boolean} disableRepeat
 * @param {number} updateInterval In milliseconds.
 * @returns {number}
 */
export function getMaxAgeForRepeatingRequest(disableRepeat, updateInterval) {
    let maxAge;

    if (disableRepeat) {
        // We want to always use cached values when the request is not
        // repeating.
        maxAge = Infinity;
    }
    else {
        // If there's an update interval let the value be
        // cached for just less than the interval.
        maxAge = Math.max(0, updateInterval - 100);
    }

    return maxAge;
}

/**
 * A promise that resolves after a certain time, with support for aborting using
 * an AbortSignal.
 *
 * If aborted, the promise will resolve.
 *
 * @param {Number} delayMs 
 * @param {AbortSignal} abortSignal 
 * @returns {Promise}
 */
 async function abortableAsyncDelay(delayMs, abortSignal) {
    if (abortSignal.aborted) {
        // Already aborted before we've even started
        return Promise.resolve();
    }

    return new Promise((resolve) => {
        let timeout = null;

        /* eslint-disable no-use-before-define */
        const cleanup = () => {
            // Clear the current timeout, if needed
            if (timeout !== null) {
                clearTimeout(timeout);
                timeout = null;
            }
            // Remove from listeners
            abortSignal.removeEventListener('abort', handleAbort);
        };
        /* eslint-enable no-use-before-define */

        const handleAbort = () => {
            // Cleanup and resolve the promise
            cleanup();
            resolve();
        };

        abortSignal.addEventListener('abort', handleAbort);

        // Setup the timout to resolve the promise
        timeout = setTimeout(() => {
            // Finished, timer does not need to be cleared
            timeout = null;
            // Cleanup and resolve the promise
            cleanup();
            resolve();
        }, delayMs);
    });
}

/**
 * A hook to periodically send a request at a specified interval.
 *
 * IMPORTANT: Be sure to memoize (i.e., useCallback) the `sendRequest` function
 * because the loop will be restarted (and any pending requests aborted)
 * each time the reference to the function changes.
 *
 * @param {Object} opts
 * @param {function({ isInitialRequest: boolean, abortSignal: AbortSignal }): Promise} opts.sendRequest
 *      Async function to send a request. `isInitialRequest` will be true only
 *      for the first time the request is sent.
 * @param {number} opts.delayMs How often (in milliseconds) should the request repeat?
 * @param {boolean} opts.disableRepeat If true, opts.delayMs is ignored and the
 *      request will not repeat.
 */
export default function useRepeatingRequest({
    sendRequest,
    delayMs,
    disableRepeat,
}) {
    // Validate the arguments, because bad values in here (especially a too
    // short of a delay) can result in a flurry of requests, possibly causing
    // someone's computer to explode.
    //
    if (typeof sendRequest !== 'function') {
        throw new Error(`useRepeatingRequest: sendRequest is required and must be a function.`);
    }

    if (!disableRepeat) {
        if (!Number.isFinite(delayMs)) {
            throw new Error(`useRepeatingRequest: delayMs must be a finite number.`);
        }
        else if (delayMs < 3e3) {
            throw new Error(`useRepeatingRequest: delayMs must be at least 3000.`);
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        const abortSignal = abortController.signal;
        let isInitialRequest = true;
        let isRunning = false;

        async function runLoop() {
            isRunning = true;

            // Keep this loop going until the abort signal indicates that it
            // should stop.
            while (!abortSignal.aborted) {
                try {
                    await sendRequest({
                        isInitialRequest,
                        abortSignal,
                    });
                }
                catch (error) {
                    console.error(error);
                }

                // If this request should be sent only once, break after the
                // first time the request is sent.
                if (disableRepeat) {
                    break;
                }

                isInitialRequest = false;

                if (abortSignal.aborted) {
                    return;
                }

                await abortableAsyncDelay(delayMs, abortSignal);
            }

            isRunning = false;
        }

        runLoop();

        // Cleanup
        return () => {
            if (isRunning) {
                abortController.abort();
            }
        };
    }, [ sendRequest, delayMs, disableRepeat ]);
}
