import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UnmaskablePasswordInput from 'Components/password/UnmaskablePasswordInput';
import { createPath } from 'Common/util/FormUtils';

const Root = styled.div``;

class ChangePasswordControl extends React.Component {
    constructor() {
        super();
    }

    render() {
        const {
            name,
            value,
            validation,
            disabled,
            onChange,
        } = this.props;

        const newPasswordDisabled = !(value.current_password && value.current_password !== '');

        return (
            <Root>
                <UnmaskablePasswordInput
                    name={createPath(name, 'current_password')}
                    id='current_password'
                    label='Current Password'
                    value={value.current_password}
                    disabled={disabled}
                    error={!!validation?.current_password}
                    helperText={validation?.current_password || ''}
                    onChange={onChange}
                    autoComplete="current-password"
                />
                <UnmaskablePasswordInput
                    name={createPath(name, 'new_password')}
                    id='new_password'
                    label='New Password'
                    value={value.new_password}
                    disabled={disabled || newPasswordDisabled}
                    required={!newPasswordDisabled}
                    error={(!newPasswordDisabled) && (!!validation?.new_password)}
                    helperText={newPasswordDisabled ? '' : validation?.new_password || ''}
                    onChange={onChange}
                    autoComplete="new-password"
                />
            </Root>
        );
    }
}

ChangePasswordControl.defaultProps = {
    disabled: false,
};

ChangePasswordControl.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        current_password: PropTypes.string.isRequired,
        new_password: PropTypes.string.isRequired,
    }).isRequired,

    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    validation: PropTypes.objectOf(PropTypes.string),
};

export default ChangePasswordControl;
