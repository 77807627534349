import { formatDate } from 'Common/util/format';
import { toJulianDay } from 'Common/util/language/date';
import formatDuration from 'Common/util/formatDuration';
import { ProductionDay } from "Common/data/date/ProductionDay";
import TimeRange from "Common/types/TimeRange/TimeRange";
import moment from "moment-timezone";

/**
 * @typedef {Object} SecondaryDescription
 * @property {string} title Primary text of the secondary description
 *
 * @typedef {Object} Range
 * @property {Object} start
 * @property {String} start.error
 * @property {String} start.errorType
 * @property {OffsetDate} start.startTime
 * @property {Number} start.productionDayNumber
 * @property {Object} end
 * @property {String} end.error
 * @property {String} end.errorType
 * @property {OffsetDate} end.endTime
 * @property {Number} end.productionDayNumber
 * @property {Number} duration
 * 
 */

/**
 *
 *  Gets the number of days between the start and end date.
 *
 * @param {Object} startDate
 * @param {Object} endDate
 *
 * @returns {number}
 *
 */
const getDaysBetween = (startDate, endDate) => {
    const julianStart = toJulianDay(startDate),
        julianEnd = toJulianDay(endDate);

    return julianEnd - julianStart;
};

/**
 *
 *  Gets duration in number of days.
 *
 * @param {number} dayCount
 * @param {Function} translator
 *
 * @returns {string}
 *
 */
const getDayDuration = (dayCount, translator) => {
    return dayCount === 1
        ? translator("timerange.one_day")
        : translator("timerange.n_days", { x: dayCount });
};

/**
 *
 * Gets formatted start date.
 *
 * @param {Object} offset
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {SecondaryDescription}
 *
 */
const getFormattedStartDate = (offset, locale, translator) => {
    const today = new Date();
    const startOfWeekToday = moment(today).startOf("isoWeek");
    const startOfWeekOffset = moment(offset).startOf("isoWeek");
    const weeksBetween = startOfWeekToday.diff(startOfWeekOffset, "week");
    const daysBetween = getDaysBetween(offset, today);
    const dayOfWeek = [
        "timerange.day_of_week.sunday",
        "timerange.day_of_week.monday",
        "timerange.day_of_week.tuesday",
        "timerange.day_of_week.wednesday",
        "timerange.day_of_week.thursday",
        "timerange.day_of_week.friday",
        "timerange.day_of_week.saturday",
    ];

    if (daysBetween === -1) {
        return translator("timerange.day_of_week.tomorrow");
    } else if (daysBetween === 0) {
        return translator("timerange.day_of_week.today");
    } else if (daysBetween === 1) {
        return translator("timerange.day_of_week.yesterday");
    } else if (weeksBetween === 0) {
        return translator(dayOfWeek[offset.getDay()]);
    } else if (weeksBetween === 1) {
        return translator("timerange.day_of_week.last_secondary", {
            x: translator(dayOfWeek[offset.getDay()]),
        });
    } else {
        return formatDate(offset, {
            selector: "date",
            formatLength: "long",
            locale,
        });
    }
};

/**
 *
 * Gets secondary description for dimensions.
 *
 * @param {Range} range
 * @param {Number} timeRangeEnd
 * @param {Object} timeRangeSameDimensionValue
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {SecondaryDescription}
 *
 */
const handleDimensions = (range, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, locale, translator) => {
    if (range === undefined || range.start.errorType === "nodata" || !range.start.startTime) {
        return {
            title: translator("timerange.no_data"),
        };
    }

    const offset = range.start.startTime.getOffsetDate();
    const startTime = formatDate(offset, { selector: "time", locale });
    const startDate = getFormattedStartDate(offset, locale, translator);
    let duration;
    let startIntervalLiteral = "timerange.start_interval";

    if (timeRangeEnd === 0 && !timeRangeSameDimensionValue) {
        duration = translator("timerange.open");
    }
    else if (["ordinal_shift", "shift"].includes(timeRangeUnits)) {
        duration = "";
        startIntervalLiteral = "timerange.start_interval_no_duration";
    }
    else {
        const numberOfDays = Math.floor(range.duration / (24 * 60 * 60));
        if (numberOfDays >= 1) {
            duration = getDayDuration(numberOfDays, translator);
        }
        else {
            duration = formatDuration(Number(range.duration).toFixed(4), {
                translator,
                pattern: 'h"h" mm"m"',
            });
        }
    }

    return {
        title: translator(startIntervalLiteral, {
            x: `${startDate} ${startTime}`,
            y: duration,
        }),
    };
};

/**
 *
 * Gets secondary description for time.
 *
 * @param {Range} range
 * @param {Number} timeRangeStart
 * @param {Number} timeRangeEnd
 * @param {Object} timeRangeSameDimensionValue
 * @param {String} timeRangeUnits
 * @param {ProductionDay} originDay
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {SecondaryDescription}
 *
 */
const handleTime = (range, timeRangeStart, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, originDay, locale, translator) => {

    if (timeRangeUnits === "shift_hour") {
        return handleDimensions(range, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, locale, translator);
    }
    else {
        let title;

        const hasNoData = range === undefined || range.start.errorType === 'nodata' || range.start.error;

        if (hasNoData || !originDay) {
            title = translator("timerange.no_data");
        }
        else {
            // Get the production day range using the relative offset starts and
            // ends of the time range
            const productionDayRange = TimeRange.computeProductionDayRangeFromToday(
                {
                    units: timeRangeUnits,
                    start: timeRangeStart,
                    end: timeRangeEnd,
                },
                originDay
            );

            // The start of the actual data in the time range
            const dataStart = range?.start.startTime?.getOffsetDate();

            let duration;

            if (timeRangeEnd === 0 && !timeRangeSameDimensionValue) {
                duration = translator("timerange.open");
            }
            else {
                const dayCount = productionDayRange.end - productionDayRange.start + 1;
                duration = getDayDuration(dayCount, translator);
            }

            title = translator("timerange.start_interval", {
                x: getFormattedStartDate(dataStart, locale, translator),
                y: duration,
            });
        }

        return {
            title: title,
        };
    }
};

/**
 *
 * Gets secondary description for all time.
 *
 * @param {Range} range
 * @param {Number} timeRangeEnd
 * @param {Object} timeRangeSameDimensionValue
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {SecondaryDescription}
 *
 */
const handleAllTime = (range, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, locale, translator) => {
    return handleDimensions(range, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, locale, translator);
};

/**
 *
 * Gets secondary description for time range.
 *
 * @param {Range} range
 * @param {Number} timeRangeStart
 * @param {Number} timeRangeEnd
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {SecondaryDescription}
 *
 */

const handleTimeRange = (range, timeRangeStart, timeRangeEnd, locale, translator) => {
    const today = new ProductionDay(new Date());

    let title;

    const hasNoData = range === undefined || range.start.errorType === "nodata" || range.start.errorType === "deviceunresponsive";

    if (hasNoData) {
        title = translator("timerange.no_data");
    }
    else {
        const startAsDay = new ProductionDay(timeRangeStart);
        const endAsDay = new ProductionDay(timeRangeEnd);
        // The start of the actual data in the time range
        const dataStartDate = range?.start.startTime?.getOffsetDate();

        const isOpen = (
            endAsDay.isOnOrAfter(today) &&
            startAsDay.isOnOrBefore(today)
        );

        let duration;

        if (isOpen) {
            duration = translator("timerange.open");
        }
        else {
            const timeRangeEndDate = new ProductionDay(timeRangeEnd).toDate();

            const daysBetween = getDaysBetween(
                dataStartDate,
                timeRangeEndDate
            );

            duration = getDayDuration(daysBetween + 1, translator);
        }

        title = translator("timerange.start_interval", {
            x: formatDate(dataStartDate, {
                selector: "date",
                formatLength: "short",
                locale
            }),
            y: duration,
        });
    }

    return {
        title: title,
    };
};

/**
 *
 * Gets secondary description.
 *
 * @param {Range} range
 * @param {Number} timeRangeStart
 * @param {Number} timeRangeEnd
 * @param {Object} timeRangeSameDimensionValue
 * @param {String} timeRangeUnits
 * @param {ProductionDay} originDay
 * @param {String} locale
 * @param {Function} translator
 *
 * @returns {SecondaryDescription}
 *
 */
const getSecondaryDescription = ({
    range,
    timeRangeStart,
    timeRangeEnd,
    timeRangeSameDimensionValue,
    timeRangeUnits,
    originDay,
    locale,
    translator
}) => {
    const supportedDimensions = ["part", "shift", "ordinal_shift"];
    const supportedTimes = [
        "shift_hour",
        "production_day",
        "week",
        "month",
        "quarter",
        "year",
    ];

    if (range === undefined) {
        return {
            title: translator("timerange.loading"),
        };
    }

    if (supportedDimensions.includes(timeRangeUnits)) {
        return handleDimensions(range, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, locale, translator);
    } else if (supportedTimes.includes(timeRangeUnits)) {
        return handleTime(range, timeRangeStart, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, originDay, locale, translator);
    } else if (timeRangeUnits === "all") {
        return handleAllTime(range, timeRangeEnd, timeRangeSameDimensionValue, timeRangeUnits, locale, translator);
    } else if (timeRangeUnits === "time") {
        return handleTimeRange(range, timeRangeStart, timeRangeEnd, locale, translator);
    } else {
        throw `${timeRangeUnits} is not supported`;
    }
};

export default getSecondaryDescription;
