import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Form from 'Components/form/Form';
import validate from 'Components/pages/alerts-subscribe/AlertWindowValidation';
import * as RequestStatus from 'Common/data/RequestStatus';
import DndSelect from './DndSelect';
import AlertWindowEditor from './AlertWindowEditor';
import every from 'lodash/every';
import mapValues from 'lodash/mapValues';
import { format24HourTime } from 'Components/util/timeUtils';
import { isValidationValid } from 'Components/util/validationUtils';

const defaultDayWindow = {
    enabled: true,
    start: '07:00',
    end: '15:00',
};

function getDndSelectValueFromWindow(alertWindow) {
    // If all disabled => never
    const allDisabled = every(alertWindow, (day) => day.enabled === false);

    if (allDisabled) {
        return 'never';
    }

    const allFullDay = every(alertWindow, (day) => (
        day.enabled &&
        day.start === '00:00' &&
        day.end === '00:00'
    ));

    if (allFullDay) {
        return 'always';
    }

    // Must be a window
    return 'window';
}

function getInitialDefaultWindowValue(dndSelection, data) {
    switch (dndSelection) {
        case 'window': {
            return data;
        }
        case 'always': {
            // Default week
            return mapValues(data, (curr, day) => {
                if (day === 'saturday' || day === 'sunday') {
                    return {
                        ...defaultDayWindow,
                        enabled: false,
                    };
                }
                else {
                    return {
                        ...defaultDayWindow,
                        enabled: true,
                    };
                }
            });
        }
        case 'never': {
            // Keep the existing window values and disable each day
            return mapValues(data, (curr) => {
                return {
                    ...curr,
                    enabled: false,
                };
            });
        }
        default: {
            console.error(`Unknown dndSelection value '${dndSelection}'`);
            // Just default the days in this case
            return mapValues(data, (curr, day) => {
                if (day === 'saturday' || day === 'sunday') {
                    return {
                        ...defaultDayWindow,
                        enabled: false,
                    };
                }
                else {
                    return {
                        ...defaultDayWindow,
                        enabled: true,
                    };
                }
            });
        }
    }
}

function transformIncomingData(alertWindow) {
    return mapValues(alertWindow, (day) => {
        return {
            enabled: day.enabled,
            start: format24HourTime(day.start),
            end: format24HourTime(day.end),
        };
    });
}

function transformOutgoingData(alertWindow) {
    return mapValues(alertWindow, (day) => {
        return {
            enabled: day.enabled,
            start: format24HourTime(day.start) + ':00',
            end: format24HourTime(day.end) + ':00',
        };
    });
}

const StyledAlertWindowEditor = styled(AlertWindowEditor)`
    margin-top: 10px;
`;

export default class AlertWindowForm extends React.Component {
    constructor(props) {
        super(props);

        const initialData = transformIncomingData(props.data);
        const dndSelection = getDndSelectValueFromWindow(initialData);

        this.state = {
            dndSelection: dndSelection,
            initialData: initialData,
            modifiedData: initialData,
            defaultWindow: getInitialDefaultWindowValue(dndSelection, initialData),
            lastPatchStatus: props?.requests?.patchAlertWindow?.status,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const lastPatchStatus = state.lastPatchStatus;
        const nextPatchStatus = props?.requests?.patchAlertWindow?.status;

        if (lastPatchStatus !== nextPatchStatus) {
            let nextInitialData = state.initialData;
            let nextModifiedData = state.modifiedData;
            let nextDefaultWindow = state.defaultWindow;

            // Watch for when patchAlertWindow changes from PENDING to SUCCESS and
            // reset the data
            if (lastPatchStatus === RequestStatus.PENDING &&
                nextPatchStatus === RequestStatus.SUCCESS
            ) {
                nextInitialData = transformIncomingData(props.data);
                nextModifiedData = nextInitialData;
                nextDefaultWindow = getInitialDefaultWindowValue(state.dndSelection, nextInitialData);
            }

            return {
                initialData: nextInitialData,
                modifiedData: nextModifiedData,
                defaultWindow: nextDefaultWindow,
                lastPatchStatus: nextPatchStatus,
            };
        }

        return null; // No changes
    }

    _handleSubmit = (event) => {
        // Perform any data cleanup here
        const cleanedUpData = transformOutgoingData(this.state.modifiedData);

        this.props.onSubmit({
            target: {
                name: this.props.name,
                value: cleanedUpData,
            },
        });
    };

    _handleChange = (event) => {
        const { dndSelection, defaultWindow } = this.state;
        const { value } = event.target;

        this.setState({
            modifiedData: value,
            defaultWindow: dndSelection === 'window' ? value : defaultWindow,
        });
    };

    _handleReset = () => {
        this.setState((state) => {
            const dndSelection = getDndSelectValueFromWindow(state.initialData);

            return {
                dndSelection: dndSelection,
                modifiedData: state.initialData,
                defaultWindow: getInitialDefaultWindowValue(dndSelection, state.initialData),
            };
        });
    };

    _handleDndSelectChange = (event) => {
        const { initialData, modifiedData, defaultWindow } = this.state;
        const newDndSelection = event.target.value;

        let newData = { ...modifiedData };

        switch (newDndSelection) {
            case 'never': {
                // When set to never, use the initial data (that we know is
                // valid) and set every day to disabled
                newData = mapValues(initialData, (curr) => {
                    return {
                        ...curr,
                        enabled: false,
                    };
                });
                break;
            }
            case 'always': {
                newData = mapValues(modifiedData, (curr) => {
                    return {
                        enabled: true,
                        start: '00:00',
                        end: '00:00',
                    };
                });
                break;
            }
            case 'window': {
                newData = defaultWindow;
                break;
            }
        }

        this.setState({
            dndSelection: newDndSelection,
            modifiedData: newData,
        });
    };

    render() {
        const { requests } = this.props;
        const { dndSelection, initialData, modifiedData } = this.state;

        const hasChanges = modifiedData !== initialData;
        const validation = validate(modifiedData);
        const isValid = isValidationValid(validation);
        const isLoading = requests.patchAlertWindow.status === RequestStatus.PENDING;

        const allowEdit = !isLoading;
        const allowSubmit = !isLoading && isValid && hasChanges;
        const allowCancel = !isLoading && hasChanges;

        const showReceiveWindow = dndSelection === 'window';

        return (
            <Form
                error={requests?.patchAlertWindow?.error}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <React.Fragment>
                    <DndSelect
                        id="dnd-selector"
                        name="dndSelection"
                        value={dndSelection}
                        onChange={this._handleDndSelectChange}
                        disabled={!allowEdit}
                    />
                    {showReceiveWindow ? (
                        <StyledAlertWindowEditor
                            name="alertWindow"
                            value={modifiedData}
                            validation={validation}
                            onChange={this._handleChange}
                            allowEdit={allowEdit}
                        />
                    ) : null}
                </React.Fragment>
            </Form>
        );
    }
}

AlertWindowForm.propTypes = {
    name: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    requests: PropTypes.shape({
        patchAlertWindow: PropTypes.shape({
            status: PropTypes.string.isRequired,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
};

AlertWindowForm.defaultProps = {
    name: '',
};
