import styled from 'styled-components';
import { Subtitle1 } from 'Components/typography/typography';

const PageDescription = styled(Subtitle1)`
    display: block;
    color: ${props => props.theme.colors.darkText.mediumEmphasis};
    margin-top: 10px;
    margin-bottom: 24px;
    max-width: 1280px; /* Matches PageContentWide */

    > p:first-of-type {
        margin-block-start: 0;
    }
    > p:last-of-type {
        margin-block-end: 0;
    }
`;

export default PageDescription;
