import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FieldBuilder from 'Common/components/FieldBuilder/FieldBuilder';
import ListButton from 'Common/components/ListButton';
import KPIGroupFieldsOption from './KPIGroupFieldsOption';
import MetricAndMetricSetOption from 'Common/components/ColumnControl/MetricAndMetricSetOption';
import KPIGroupHeadingOption from './KPIGroupHeadingOption';
import { KPI_GROUP_COLUMNS_PROPTYPE } from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidgetConstants';
import KPIGroupFieldChipText from './KPIGroupFieldChipText';
import Button from 'Common/components/Button';
import { useTranslator } from 'Common/hooks/useTranslation';
import SparkDimensionColumnSelector from './SparkDimensionColumnSelector';

const AddButtonContainer = styled.div`
    padding: 0 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
    margin-bottom: -1px;
`;

const InstructionLabel = styled.div`
    color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
    padding: 1px 11px 4px 11px;
`;

const FieldBuilderContainer = styled.div`
    overflow-y: auto;
    flex: 1 1 auto;
`;

const ButtonContainer = styled.div`
    box-sizing: border-box;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding-right: 11px;
    border-top: 1px solid ${({theme}) => theme.colors.palette.grey.silver };
`;

const ApplyContainer = styled(ButtonContainer)`
    justify-content: flex-end;
    flex: 0 0 auto;
`;

const renderFieldText = (field) => {
    return (
        <KPIGroupFieldChipText type={field.type}>
            {field.displayName}
        </KPIGroupFieldChipText>
    );
};

/**
 * This component is one of the options traveled to by KPIGroupColumnControl.
 * Its the start allowing the user to add/update/remove fields.
 */

const KPIGroupSelectedFieldsOption = ({
    selectedColumns,
    onChange,
    transitionForward,
    onApply,
    isDirty
}) => {

    const translator = useTranslator();
    const handleAddField = useCallback((e) => {
        transitionForward(
            { control: KPIGroupFieldsOption, props: {}, title: translator('add_row_literal') }
        );
    }, [transitionForward, translator]);

    const handleUpdateFields = useCallback((e) => {
        const columnIndex = selectedColumns.findIndex((col) => (col.name === e.target.value));

        if (columnIndex < 0) {
            console.warn(`Can not find column ${e.target.value}`);
            return;
        }

        const columnType = selectedColumns[columnIndex].type;

        if (columnType === 'field') {
            const columnFieldType = selectedColumns[columnIndex].fieldType;
            if (columnFieldType === 'metric') {
                transitionForward(
                    { control: MetricAndMetricSetOption, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('metric_literal')}) }
                );
            }
            else if (columnFieldType === 'category_value') {
                transitionForward(
                    { control: SparkDimensionColumnSelector, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('category_literal')}) }
                );
            }
        }
        else if (columnType === 'heading') {
            transitionForward(
                { control: KPIGroupHeadingOption, props: { selectedColumnIndex: columnIndex }, title: translator('edit_field_template', { field: translator('widget.kpi_group.heading')}) }
            );
        }

    }, [transitionForward, selectedColumns, translator]);

    return (
        <Fragment>
            <AddButtonContainer>
                <ListButton
                    onClick={handleAddField}
                >
                    {translator('add_row_literal')}
                </ListButton>
            </AddButtonContainer>
            {selectedColumns.length > 0 ? <InstructionLabel>{translator('widget.table.column_control.instructions')}</InstructionLabel> : null}
            <FieldBuilderContainer>
                <FieldBuilder
                    value={selectedColumns}
                    onChange={onChange}
                    onFieldDoubleClick={handleUpdateFields}
                    renderFieldText={renderFieldText}
                />
            </FieldBuilderContainer>
            <ApplyContainer>
                <Button
                    onClick={onApply}
                    disabled={!isDirty}
                >
                    {translator('apply_literal')}
                </Button>
            </ApplyContainer>
        </Fragment>
    );
};

KPIGroupSelectedFieldsOption.propTypes = {
    selectedColumns: KPI_GROUP_COLUMNS_PROPTYPE.isRequired,
    isDirty: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    transitionForward: PropTypes.func.isRequired,
};

export default KPIGroupSelectedFieldsOption;
