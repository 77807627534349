import { useEffect, useState } from 'react';
import { useTranslator } from 'Common/hooks/useTranslation';
import useLocale from 'Common/hooks/useLocale';
import Filters from 'Common/types/Filters/Filters';
import formatFiltersForRequest from 'Common/util/formatFiltersForRequest';
import { filterActiveFilters } from 'Common/components/FilterBuilderText/FilterBuilderUtils';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';

const paramTypes = ParamTypes.shape({
    sliceFilters: Filters.sliceFiltersParamType,
    groupFilters: Filters.groupFiltersParamType,
    dataService: ParamTypes.shape({
        getMultipleColumnConfigs: ParamTypes.func.isRequired,
        getMultipleCategoryValueConfigs: ParamTypes.func.isRequired,
    }).isRequired,
});

export default function useFormattedFilters(parameters) {
    paramTest(parameters, paramTypes, 'useFormattedFilters');

    const {
        sliceFilters,
        groupFilters,
        dataService,
    } = parameters;

    const translator = useTranslator();
    const locale = useLocale();

    const [ formattedFilters, setFormattedFilter ] = useState([]);

    useEffect(() => {
        const abortSignal = { aborted: false };

        async function run() {
            if (!sliceFilters && !groupFilters) {
                // No filters; skip the rest.
                return;
            }

            const filters = { slice: sliceFilters, group: groupFilters };
            const filterColumnNames = Filters.getColumnsFromFilters(filters);
            const columnConfigs = await dataService.getMultipleColumnConfigs(filterColumnNames);
            const filterCategoryValueNames = Filters.getCategoryValuesFromFilters(filters);
            const categoryValueConfigs = await dataService.getMultipleCategoryValueConfigs(filterCategoryValueNames); 
            const filtersWithDisplayValues = Filters.convertToDisplayValues(
                filters,
                columnConfigs,
                categoryValueConfigs,
                translator,
                locale
            );

            if (abortSignal.aborted) {
                return;
            }

            setFormattedFilter(filterActiveFilters(formatFiltersForRequest(filtersWithDisplayValues)));
        }

        run();

        // Cleanup
        return () => {
            abortSignal.aborted = true;
        };
    }, [ sliceFilters, groupFilters, dataService, translator, locale ]);

    return formattedFilters;
}
