import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'Common/components/IconButton';
import Tooltip from 'Common/components/Tooltip';

/**
  * A button on a SectionToolbar
  */
function SectionToolbarButton({
    disabled,
    displayName,
    name,
    icon,
    onClick,
    selected,
    buttonRef,
}) {
    return (
        <Tooltip
            delay={500}
            placement="top"
            content={displayName}
            style={{ display: 'inline-block' }}
        >
            <IconButton
                disabled={disabled}
                name={name}
                onClick={onClick}
                selected={selected}
                ref={buttonRef}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
}

SectionToolbarButton.propTypes = {
    disabled: PropTypes.bool,
    displayName: PropTypes.string.isRequired,
    name: PropTypes.string,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    buttonRef: PropTypes.func,
};

SectionToolbarButton.defaultProps = {
    disabled: false,
    onClick: () => {},
    selected: false,
};

export default React.memo(SectionToolbarButton);
