import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from 'Components/text-input/TextInput';

const Root = styled.div`
    word-break: break-word;
`;

const StyledTextInput = styled(TextInput)`
    display: block;
`;

function getDateString(dateTime) {
    if (!dateTime) {
        return '--';
    }

    return (new Date(dateTime)).toDateString();
}

class OrganizationEditor extends React.Component {
    constructor() {
        super();
        this._handleChange = this._handleChange.bind(this);
    }

    _handleChange(event) {
        const newValue = {
            ...this.props.value,
            [event.target.name]: event.target.value,
        };

        event.target = {
            name: this.props.name,
            value: newValue,
        };

        this.props.onChange(event);
    }

    render() {
        const {
            value,
            validation,
            disabled,
            name: nameProp,
            onChange,
            ...otherProps
        } = this.props;

        return (
            <Root {...otherProps}>
                <StyledTextInput
                    label="Name"
                    value={value.name}
                    name="name"
                    error={!!validation.name}
                    disabled={disabled}
                    helperText={validation.name || ''}
                    onChange={this._handleChange}
                />
                <StyledTextInput
                    label="Created"
                    value={getDateString(value.created)}
                    name="created"
                    disabled
                    helperText=""
                />
            </Root>
        );
    }
}

OrganizationEditor.defaultProps = {
    name: '',
    validation: {},
    disabled: false,
};

OrganizationEditor.propTypes = {
    name: PropTypes.string,
    value: PropTypes.shape({
        name: PropTypes.string.isRequired,
        created: PropTypes.string.isRequired,
    }).isRequired,
    validation: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
};

export default OrganizationEditor;
