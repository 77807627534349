import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCheckboxInput from './internal/BaseCheckboxInput';
import uniqueId from 'Common/util/uniqueId';

const Root = styled.div`
    /* Much of the below checkbox trickery follows the example of: */
    /*   http://www.templatemonster.com/blog/style-checkboxes-radio-buttons-css */
    /*   http://codepen.io/matthewcain/pen/KMgBxp */
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;

    /* We hide the normal checkbox and add our own pretty one. */
    input[type="checkbox"] {
        opacity: 0.001;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;

        /* When it's checked, we show a white checkbox on a blue background. */
        &:checked + label span.checkbox::after {
            color: ${props => props.theme.colors.palette.white};
            background-color: ${props => props.theme.colors.palette.blue.awesome};
            border: 1px solid ${props => props.theme.colors.palette.blue.awesome};
            text-align: center;
            content: "${props => props.tick}";
        }
    }

    label {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
    }

    span.checkbox {
        /* &::after is for the checkmark itself. */
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 14px;
            height: 14px;
            content: "${props => props.tick}";
            font-family: 'FontAwesome';
            font-size: 12px;
            color: transparent;
            transition: background-color .3s;
            border: 1px solid rgba(0, 0, 0, 0.25);
            line-height: 14px;
            cursor: pointer;
        }
    }

    input[type="checkbox"]:focus + label > span.checkbox::after {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    input[type="checkbox"][disabled] + label > span.checkbox::after {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export default class ThemedCheckboxInput extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        tick: PropTypes.string.isRequired,
    };

    static defaultProps = {
        tick: '\f00c',
    };

    constructor(props) {
        super(props);
        this._uniqueId = uniqueId('CheckboxInput_');
    }

    _handleLabelClick = (event) => {
        // By default the label click will trigger another click on the
        // real input. The prevents a double click event.
        event.stopPropagation();
    };

    render() {
        const {
            id: idProp,
            className,
            tick,
            ...otherProps
        } = this.props;

        const id = idProp || this._uniqueId;

        return (
            <Root className={className} tick={tick} >
                <BaseCheckboxInput {...otherProps} id={id} />
                <label htmlFor={id} onClick={this._handleLabelClick}>
                    <span className="checkbox" />
                </label>
            </Root>
        );
    }
}
