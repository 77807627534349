import React from 'react';
import styled from 'styled-components';

import { elementsOfType } from 'Components/util/propTypesUtils';
import TableHeaderRow from 'Components/table/TableHeaderRow';

const ThemedTableHeader = styled.thead`
    background-color: ${({ theme }) => theme.colors.palette.grey.manatee};
    color: ${({ theme }) => theme.colors.palette.white};
`;

function TableHeader({ children, ...otherProps }) {
    return (
        <ThemedTableHeader {...otherProps} >
            {children}
        </ThemedTableHeader>
    );
}

TableHeader.propTypes = {
    children: elementsOfType([
        TableHeaderRow
    ]),
};

export default TableHeader;
