import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { childrenAreOneOf } from 'Common/util/propTypeHelpers';
import MenuItem from './MenuItem';
import Icon from 'Common/components/Icon';
import MenuSeparator from 'Common/components/MenuToolbar/MenuSeparator';

/**
 * MenuList
 *
 * A way to add a hierachy of lists in a MenuToolbar. It can have children
 * of either another MenuList or MenuItem.
 *
 */

const StyledMenuItem = styled(MenuItem)`
    ${({showChildren}) => showChildren && css`
        background-color: ${({theme}) => theme.colors.palette.blue.pool};
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const dropdownIconStyle = { fontSize: 10, alignSelf: 'center' };

export default function MenuList(props) {
    const {
        text,
        icon,
        children,
        level,
        onClose,
    } = props;

    const [showChildren, setShowChildren] = useState(false);

    const onToggleChildren = useCallback(() => {
        setShowChildren(!showChildren);
    }, [showChildren, setShowChildren]);

    const displayedChildren = useMemo(() => {
        return React.Children.map(children, (child) => {
            let childProps = { ...child.props, onClose, level: level + 1 };
            return React.cloneElement(child, childProps);
        });
    }, [children, onClose, level]);

    const dropdownIcon = showChildren
        ? (<Icon style={dropdownIconStyle} type="fontAwesome" iconName="chevron-up"/>)
        : (<Icon style={dropdownIconStyle} type="fontAwesome" iconName="chevron-down"/>);
    
    return (
        <Container>
            <StyledMenuItem
                onClick={onToggleChildren}
                icon={icon}
                text={text}
                dropdownIcon={dropdownIcon}
                showChildren={showChildren}
                level={level}
            />
            {
                showChildren ? displayedChildren : null
            }
        </Container>
    );
}

MenuList.propTypes = {
    children: childrenAreOneOf({
        elementTypes: [
            MenuSeparator
        ],
        propShapes: [
            // dropdown:
            [
                'text',
                'children',
            ],
            // item:
            [
                'onClick',
                'text',
            ],
            // info item:
            [
                'children'
            ]
        ],
    }),
    icon: PropTypes.node,
    level: PropTypes.number,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func,
};

MenuList.defaultProps = {
    icon: null,
    level: 0,
    onClose: () => {},
};
