import { css } from "styled-components";

/**
 * For use in a styled component to set all the properties needed to
 * make text elide in most situations.
 */
export const elideTextCss = css`
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const dragDropCursor = css`
    cursor: move;
`;
