import ParamTypes, { paramTest } from '../ParamTypes';
import paramTypeWrapper from './paramTypeWrapper';
import testType from './testType';

/**
 * ParamType checker which checks if all items in the array have the given type.
 *
 * @param {ParamType} type ParamType from ParamTypes object
 *
 * @returns {Function} ParamType checker which returns an array of failures.
 */
export default function arrayOf(type) {
    paramTest(type, ParamTypes.paramType.isRequired, 'ParamTypes.arrayOf type arg', { throwError: true, });
    
    return paramTypeWrapper((arg, key) => {
        let failures = [];

        failures = failures.concat(testType(ParamTypes.array, arg, key));

        // If the arrayOf type is marked 'isRequired' we will enforce the array must
        // be at least length 1 .
        //
        if (type._required && arg.length === 0) {
            failures.push(
                `array must have at least one item as arrayOf type is marked as 'isRequired'`
            );
        }

        if (failures.length === 0) {
            arg.forEach((value, i) => {
                failures = failures.concat(testType(type, value, `${key}[${i}]`));
            });
        }

        return failures;
    });
}
