import styled from 'styled-components';

const NavSeparator = styled.div`
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #d5d5d5;

    margin-top: 3px;
    margin-bottom: 3px;
`;

export default NavSeparator;
