import ParamTypes, { paramTest } from '../ParamTypes';
import paramTypeWrapper from './paramTypeWrapper';
import testType from './testType';

/**
 * ParamType checker which checks if the value is one of the given types.
 *
 * @param {ParamTypes[]} types The types to check against. If any type matches
 *     there are no failures.
 *
 * @returns {Function} ParamType checker which returns an array of failures.
 */
export default function oneOfType(types) {
    paramTest(types,
        ParamTypes.arrayOf(ParamTypes.paramType).isRequired,
        'ParamTypes.oneOfType types arg',
        { throwError: true, }
    );

    if (types.length === 0) {
        throw new Error(`ParamTypes.oneOfType must be given at least one type`);
    }

    return paramTypeWrapper((arg, key) => {
        let allFailures = [];

        const allFailed = types.every((type) => {
            const typeFailures = testType(type, arg, key);

            allFailures = allFailures.concat(typeFailures);

            return typeFailures.length > 0;
        });

        if (allFailed) {
            const failMessage = `${key} must pass one of these checks:\n  ${allFailures.join('\n  ')}`;

            return [failMessage];
        }
        else {
            return [];
        }
    });
}
