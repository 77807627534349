import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Icon from 'Common/components/Icon';
import { Primary5 } from 'Common/components/typography';

const LayoutWrapper = styled.div`
    box-sizing: border-box;
    height: ${({ size }) => size === 'large' ? css`26px` : css`22px`};
    padding: ${({ size }) => size === 'large' ? css`0 6px 0 7px` : css`0 4px 0 5px`};

    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left;
`;

const ChildWrapper = styled(Primary5)`
    display: block;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 4px;

    ${({isPlaceholderText}) => isPlaceholderText && css`
        color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
    `}

    ${({hasError}) => hasError && css`
        color: ${({theme}) => theme.colors.palette.error.red};
    `}

    ${({disabled}) => disabled && css`
        color: ${({theme}) => theme.colors.darkText.disabled};
    `}
`;

const StyledIcon = styled(Icon)`
    flex: 0 0 auto;
    background-color: white;
    border-radius: 6px;
    width: 12px;
    height: 12px;
    text-align: center;
    color: ${({theme}) => theme.colors.darkText.mediumEmphasis};
    line-height: 12px;
    font-size: 10px;

    ${({hasError}) => hasError && css`
        color: ${({theme}) => theme.colors.palette.error.red};
    `}

    ${({disabled}) => disabled && css`
        color: ${({theme}) => theme.colors.darkText.disabled};
    `}
`;

/**
 * In general this should not be used directly, prefer using
 * SelectDropdownButton instead.
 *
 * @see SelectDropdownButton
 */
function SelectDisplay({
    children,
    hasError,
    isPlaceholderText,
    disabled,
    size,
    ...otherProps
}) {
    return (
        <LayoutWrapper
            disabled={disabled}
            hasError={hasError}
            size={size}
            {...otherProps}
        >
            <ChildWrapper
                hasError={hasError}
                isPlaceholderText={isPlaceholderText}
                disabled={disabled}
            >
                {children}
            </ChildWrapper>
            <StyledIcon
                type='fontAwesome'
                iconName='angle-down'
                disabled={disabled}
                hasError={hasError}
            />
        </LayoutWrapper>
    );
}

SelectDisplay.propTypes = {
    children: PropTypes.node.isRequired,
    hasError: PropTypes.bool,
    isPlaceholderText: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf([
        'medium',
        'large',
    ]),
};

SelectDisplay.defaultProps = {
    hasError: false,
    isPlaceholderText: false,
    disabled: false,
    size: 'medium',
};

export default React.memo(SelectDisplay);
