import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BasicSelectField from 'Common/components/BasicSelectField/BasicSelectField.js';
import Button from 'Common/components/Button/Button.js';
import Dropdown from 'Common/components/Dropdown/Dropdown.js';
import FieldBuilderSelect from 'Common/components/FieldBuilder/FieldBuilderSelect';
import InfoCircle from 'Common/components/InfoCircle';
import NoDataMessage from 'Common/components/NoDataMessage/NoDataMessage.js';
import { useTranslator } from 'Common/hooks/useTranslation';

import {
    ExpressionInfoContainer, FieldContainer, OperatorContainer, DeleteButtonContainer, ExpressionRow
} from './internal/ExpressionRow';

import { RadioButton, RadioButtonGroup } from 'Common/components/RadioButton';
import { Body4, Heading4, FontWeight } from 'Common/components/typography';

const Root = styled.div`
    width: 375px;
    padding: 0;
    display:flex;
    flex-direction: column;
    min-height: 1px;
    /* The 'flex' below is required or else the ValidatedLiveControl will not
    handle overflow properly. */
    flex: 1 0 auto;
`;

const ButtonContainer = styled.div`
    box-sizing: border-box;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
`;

const TitleContainer = styled.div`
    display: flex;
    color: ${props => props.theme.colors.palette.blue.awesome};
    font-weight: ${FontWeight.medium};
    flex: 0 0 auto;

    align-items: center;
`;

const ExpressionsContainer = styled.div`
    overflow-y: auto;
    flex: 1 1 auto;
`;

const TextContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
`;

const EmptyExpressionsTextContainer = styled.div`
    padding: 5px 0 0px 5px;
`;

const CombinedFilterContainer = styled.div`
    padding-bottom: 10px;
    padding-right: 10px;
`;

const Separator = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.separator};
`;

const CommentsContainer = styled.div`
`;

const CommentsContainerTitle = styled.div`
    margin-bottom: 5px 0px;
    color: ${
        ({disabled,theme}) =>
            disabled ? theme.colors.darkText.disabled : theme.colors.darkText.highEmphasis
        };
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
    padding-left: 10px;
`;

function getDefaultRhsValue(selectedExpression) {
    const { fieldType } = selectedExpression;

    if (fieldType === 'category_value') {
        return [];
    }
    else if (fieldType === 'metric') {
        // create new metric expression with a default value of zero 
        return 0;
    }
}

/**
 * Control used to update the filter property of an ExplorationPath.
 * Intended to be used via the useFilterBuilder hook.
 *
 * @see useFilterBuilder
 */
export default function FilterBuilder(props) {
    const {
        categoryOptions,
        metricOptions,
        noMetricOptionsMsg,
        categoryValueOptions,
        value,
        onChange,
        error,
        allowDelete,
        combineTypes,
    } = props;

    const translator = useTranslator();
    const disableCommentsToInclude = (0 < value.slice.expressions.length);
    const withAllCommentsSelected = !!(value?.showEvents?.with_all_comments_within_time);
    const categoryOptionsInternal = useMemo(() => (
        withAllCommentsSelected ? [] : categoryOptions
    ), [withAllCommentsSelected, categoryOptions]);
    const noCategoryOptionsMsg = withAllCommentsSelected ? translator('control.filter.invalid_filters.category') : '';

    // state for Add Expression picker
    const [ isAddExpressionOpen, setIsAddExpressionOpen ] = useState(false);

    const openAddExpression = useCallback(() => {
        setIsAddExpressionOpen(true);
    }, [setIsAddExpressionOpen]);

    const closeAddExpression = useCallback(() => {
        setIsAddExpressionOpen(false);
    }, [setIsAddExpressionOpen]);

    const handleChange = useCallback((newValue) => {
        onChange({
            target: {
                value: newValue,
            },
        });
    }, [onChange]);

    const handleAddExpression = useCallback((event) => {
        closeAddExpression();

        const selectedField = event.target.value;
        let expressionType = 'group';

        // [FUTURE HACK]
        // production_day_number/calendar_day_number/record_id/event_id
        // are metrics that should get placed in slice filters
        // and are currently set to internal so user can not select them
        if (selectedField.fieldType === 'category_value'
            || selectedField.name === 'production_day_number'
            || selectedField.name === 'calendar_day_number'
            || selectedField.name === 'record_id'
            || selectedField.name.endsWith('event_id')) {
                expressionType = 'slice';
        }

        const newValue = {
            ...value,
            [expressionType]: {
                ...value[expressionType],
                expressions: [
                    {
                        lhs: selectedField,
                        op: 'eq',
                        rhs: getDefaultRhsValue(selectedField),
                    },
                    ...value[expressionType].expressions
                ]
            }
        };

        handleChange(newValue);
    }, [closeAddExpression, value, handleChange]);

    const renderAddExpressionContent = useCallback(() => (
        <FieldBuilderSelect
            categoryFields={categoryOptionsInternal}
            withAllCommentsSelected={withAllCommentsSelected}
            metricFields={metricOptions}
            value={null} // No current value; always selecting something new.
            onChange={handleAddExpression}
        />
    ), [categoryOptionsInternal, withAllCommentsSelected, handleAddExpression, metricOptions]);

    const handleExpressionChange = useCallback((event) => {
        const { target } = event;
        const targetDetails = target.name.split('.');
        const expressionType = targetDetails[0];
        const expressionIndex = targetDetails[2];

        if(!value[expressionType]?.expressions[expressionIndex]) {
            console.error(`Cannot update expression ${expressionType} at index ${expressionIndex}, no expression was found.`);
            return;
        }

        let nextExpressions =  [ ...value[expressionType].expressions ];
        let updatedExpression = { ...target.value };

        // clear rhs and set operator to equals if lhs changed
        if (target.value.lhs.name !== nextExpressions[expressionIndex].lhs.name) {
            updatedExpression.op = 'eq';
            updatedExpression.rhs = getDefaultRhsValue(target.value.lhs);
        }
        nextExpressions[expressionIndex] = updatedExpression;
        let updatedFilterSection = { ...value[expressionType], expressions: nextExpressions };

        handleChange({
            ...value,
            [expressionType]: updatedFilterSection
        });
    }, [value, handleChange]);

    const handleDeleteButtonClick = useCallback((expressionName) => {
        const targetDetails = expressionName.split('.');
        const expressionType = targetDetails[0];
        const expressionIndex = targetDetails[2];

        if(!value[expressionType]?.expressions[expressionIndex]) {
            console.error(`Cannot delete expression ${expressionType} at index ${expressionIndex}, no expression was found.`);
            return;
        }

        let nextExpressions = [ ...value[expressionType].expressions ];
        nextExpressions.splice(expressionIndex, 1);
        let updatedFilterSection = { ...value[expressionType], expressions: nextExpressions };

        handleChange({
            ...value,
            [expressionType]: updatedFilterSection
        });
    }, [value, handleChange]);

    const handleCombineTypeChange = (event) => {
        const expressionType = event.target.name;
        const newValue = event.target.value.value;
        const updatedFilterSection = { ...value[expressionType], combine_type: newValue };

        handleChange({
            ...value,
            [expressionType]: updatedFilterSection
        });
    };

    const addExpressionText = translator('add_expression_literal');

    const combineExpressionOptions = [
        {
            value: 'and',
            displayName: translator('editors.filter.combine_filter_options.all_true')
        },
        {
            value: 'or',
            displayName: translator('editors.filter.combine_filter_options.at_least_one_true')
        }
    ].filter((type) => {
        return combineTypes.includes(type.value);
    });

    const createFilterTitle = (type) => {
        let filterTitle = translator('dimensions_literal');
        let filterHelpText = translator('editors.filter.slice_filter_help_text');

        if (type === 'group') {
            filterTitle = translator('metrics_literal');
            filterHelpText = translator('editors.filter.group_filter_help_text');
        }

        return (
            <TitleContainer key={`${type}.title`}>
                <Heading4>{filterTitle}</Heading4>
                <InfoCircle>{filterHelpText}</InfoCircle>
            </TitleContainer>
        );
    };

    const createFilter = (type) => {
        const data = value[type];

        const expressions = data.expressions.map((item, index) => {
            const key = `${type}.expressions.${index}`;
            let expressionRowProps = {
                name: key,
                lhs: item.lhs,
                op: item.op,
                rhs: item.rhs,
                categoryValueOptions: (withAllCommentsSelected ? {} : categoryValueOptions),
                onChange: handleExpressionChange,
                onDeleteRow: handleDeleteButtonClick,
                allowDelete: allowDelete,
                error: error && error[type] && error[type][index] || null,
            };

            if (type === 'slice') {
                expressionRowProps.categoryOptions = categoryOptionsInternal;
            }
            else if (type === 'group') {
                expressionRowProps.metricOptions = metricOptions;
            }

            return (
                <ExpressionRow
                    key={key}
                    {...expressionRowProps}
                />
            );
        });

        const renderCombineFilterContainer = () => {
            if (expressions.length > 1) {
                const selectedItem = combineExpressionOptions.find((x) => (x.value === data.combine_type));
                const itemName = selectedItem.displayKey ? translator(selectedItem.displayKey) : selectedItem.displayName;

                if (combineExpressionOptions.length > 1) {
                    return (
                        <CombinedFilterContainer>
                            <BasicSelectField
                                name={type}
                                selectedDisplayName={itemName}
                                selectedValue={selectedItem.value}
                                onChange={handleCombineTypeChange}
                                options={combineExpressionOptions}
                                disabled={expressions.length < 2}
                            />
                        </CombinedFilterContainer>
                    );
                }
                return  (
                    <Body4>
                        { combineExpressionOptions[0].displayName }
                    </Body4>
                );
            }
        };

        return (
            <React.Fragment key={`${type}.expressions`}>
                {renderCombineFilterContainer()}
                <TitleContainer>
                    <ExpressionInfoContainer>
                        <FieldContainer>
                            <TextContainer>{translator('field_literal')}</TextContainer>
                        </FieldContainer>
                        <OperatorContainer>
                            <TextContainer>{translator('operator_copy')}</TextContainer>
                        </OperatorContainer>
                        <FieldContainer>
                            <TextContainer>{translator('value_literal')}</TextContainer>
                        </FieldContainer>
                    </ExpressionInfoContainer>
                    <DeleteButtonContainer/>
                </TitleContainer>
                <ExpressionsContainer>
                    {expressions.length > 0
                        ? expressions
                        : (
                            <EmptyExpressionsTextContainer key={'emptyMsg'} >
                                <NoDataMessage
                                    title={translator('empty_list', { items: translator('expressions_label').toLowerCase() })}
                                    message={translator('add_to_empty_list', { add_text: addExpressionText })}
                                />
                            </EmptyExpressionsTextContainer>
                        )
                    }
                </ExpressionsContainer>
            </React.Fragment>
        );
    };

    const createNoOptionsMsg = (msg, noMessageType) => {
        return (
            <EmptyExpressionsTextContainer key={`${noMessageType}.no_message.text` }>
                <NoDataMessage message={msg} />
            </EmptyExpressionsTextContainer>
        );
    };

    const INCLUDE_EVENTS_ALL = 'include_events.all';
    const INCLUDE_EVENTS_DIRECTLY_LINKED = 'include_events.directly_linked';
    const INCLUDE_EVENTS_NOT_DIRECTLY_LINKED = 'include_events.not_directly_linked';

    /**
     *                                      with_comments   without_comments
     *                                      -------------   ----------------
     *                 INCLUDE_EVENTS_ALL | true            true
     *     INCLUDE_EVENTS_DIRECTLY_LINKED | true            false
     * INCLUDE_EVENTS_NOT_DIRECTLY_LINKED | false           true
     */
    const onEventsRadioGroupChange = useCallback((event) => {
        let showEvents = { ...value.showEvents };

        switch (event.target.value) {
            case INCLUDE_EVENTS_ALL:
                showEvents.with_comments = true;
                showEvents.without_comments = true;
                break;
            case INCLUDE_EVENTS_DIRECTLY_LINKED:
                showEvents.with_comments = true;
                showEvents.without_comments = false;
                break;
            case INCLUDE_EVENTS_NOT_DIRECTLY_LINKED:
                showEvents.with_comments = false;
                showEvents.without_comments = true;
                break;
        }

        onChange({
            target: {
                value: {
                    ...value,
                    showEvents: showEvents,
                }
            }
        });
    }, [value, onChange]);

    let initialEventsValue = INCLUDE_EVENTS_ALL;

    // value.showEvents should only be present for EventListWidget
    if (value?.showEvents?.with_comments) {
        initialEventsValue =
            value?.showEvents?.without_comments ?
                INCLUDE_EVENTS_ALL : INCLUDE_EVENTS_DIRECTLY_LINKED;
    }
    else if (value?.showEvents?.without_comments) {
        initialEventsValue = INCLUDE_EVENTS_NOT_DIRECTLY_LINKED;
    }

    const INCLUDE_COMMENTS_ALL = 'include_comments.all';
    const INCLUDE_COMMENTS_DIRECTLY_LINKED = 'include_comments.directly_linked';

    /**
     *                                    with_all_comments_within_time
     *                                    -----------------------------
     *             INCLUDE_COMMENTS_ALL | true
     * INCLUDE_COMMENTS_DIRECTLY_LINKED | false
     */
    const onCommentsRadioGroupChange = useCallback((event) => {
        let showEvents = { ...value.showEvents };

        switch (event.target.value) {
            case INCLUDE_COMMENTS_ALL:
                showEvents.with_all_comments_within_time = true;
                break;
            case INCLUDE_COMMENTS_DIRECTLY_LINKED:
                showEvents.with_all_comments_within_time = false;
                break;
        }

        onChange({
            target: {
                value: {
                    ...value,
                    showEvents: showEvents,
                }
            }
        });
    }, [value, onChange]);

    // value.showEvents should only be present for EventListWidget
    const initialCommentsValue =
        value?.showEvents?.with_all_comments_within_time ?
            INCLUDE_COMMENTS_ALL : INCLUDE_COMMENTS_DIRECTLY_LINKED;

    const separatorSpacing = 4;

    const categoryFilters = (categoryOptionsInternal.length || noCategoryOptionsMsg)
        ? (
            <React.Fragment>
                {createFilterTitle('slice')}
                {categoryOptionsInternal.length ? createFilter('slice', value) : createNoOptionsMsg(noCategoryOptionsMsg)}
            </React.Fragment>
        ) : null;

    const metricFilters = (metricOptions.length || noMetricOptionsMsg)
        ? (
            <React.Fragment>
                {createFilterTitle('group')}
                {metricOptions.length ? createFilter('group', value) : createNoOptionsMsg(noMetricOptionsMsg)}
            </React.Fragment>
        ) : null;

    return (
        <Root>
            <ButtonContainer>
                <Dropdown
                    isOpen={isAddExpressionOpen}
                    onMaskClick={closeAddExpression}
                    renderContent={renderAddExpressionContent}
                >
                    <Button
                        onClick={openAddExpression}
                    >
                        {addExpressionText}
                    </Button>
                </Dropdown>
            </ButtonContainer>
            <Separator style={{marginBottom: separatorSpacing}}/>
            {categoryFilters}
            {metricOptions.length && categoryOptionsInternal.length ? <Separator style={{marginBottom: separatorSpacing, marginTop: separatorSpacing}}/> : null}
            {metricFilters}

            {/* value.showEvents should only be present for EventListWidget */}
            {value?.showEvents ?
                (
                <React.Fragment>
                    <Separator style={{marginBottom: separatorSpacing, marginTop: separatorSpacing}}/>
                    <TitleContainer>
                        <Heading4>{translator('comments')}</Heading4>
                        <InfoCircle>{translator('control.filter.comments.tooltip')}</InfoCircle>
                    </TitleContainer>
                    <CommentsContainer>
                        <CommentsContainerTitle>
                            {translator('control.filter.comments.include_events.title')}
                        </CommentsContainerTitle>
                        <StyledRadioButtonGroup
                            onChange={onEventsRadioGroupChange}
                            value={initialEventsValue}
                            >
                            <RadioButton value={INCLUDE_EVENTS_ALL}>
                                {translator(`control.filter.comments.${INCLUDE_EVENTS_ALL}`)}
                            </RadioButton>
                            <RadioButton value={INCLUDE_EVENTS_DIRECTLY_LINKED}>
                                {translator(`control.filter.comments.${INCLUDE_EVENTS_DIRECTLY_LINKED}`)}
                            </RadioButton>
                            <RadioButton value={INCLUDE_EVENTS_NOT_DIRECTLY_LINKED}>
                                {translator(`control.filter.comments.${INCLUDE_EVENTS_NOT_DIRECTLY_LINKED}`)}
                            </RadioButton>
                        </StyledRadioButtonGroup>
                        <CommentsContainerTitle disabled={disableCommentsToInclude}>
                            {translator('control.filter.comments.include_comments.title')}
                        </CommentsContainerTitle>
                        <StyledRadioButtonGroup
                            disabled={disableCommentsToInclude}
                            onChange={onCommentsRadioGroupChange}
                            value={initialCommentsValue}
                        >
                            <RadioButton value={INCLUDE_COMMENTS_ALL}>
                                {translator(`control.filter.comments.${INCLUDE_COMMENTS_ALL}`)}
                            </RadioButton>
                            <RadioButton value={INCLUDE_COMMENTS_DIRECTLY_LINKED}>
                                {translator(`control.filter.comments.${INCLUDE_COMMENTS_DIRECTLY_LINKED}`)}
                            </RadioButton>
                        </StyledRadioButtonGroup>
                        {disableCommentsToInclude ? createNoOptionsMsg(translator('control.filter.comments.invalid_selection')) : null}
                    </CommentsContainer>
                </React.Fragment>
                )
                :
                null
            }
        </Root>
    );
}

const filterProp = PropTypes.shape({
    expressions: PropTypes.arrayOf(
        PropTypes.shape({
            lhs: PropTypes.shape({
                name: PropTypes.string.isRequired,
                channel: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                fieldType: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                subType: PropTypes.string,
            }),
            op: PropTypes.string.isRequired,
            rhs: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.number,
                PropTypes.array,
            ]),
        }).isRequired,
    ).isRequired,
    combine_type: PropTypes.string,
});

FilterBuilder.propTypes = {
    categoryOptions: PropTypes.array.isRequired,
    metricOptions: PropTypes.array.isRequired,
    noMetricOptionsMsg: PropTypes.string,
    categoryValueOptions: PropTypes.object.isRequired,
    value: PropTypes.shape({
        slice: filterProp.isRequired,
        group: filterProp.isRequired,
        showEvents: PropTypes.shape({
            with_all_comments_within_time: PropTypes.bool.isRequired,
            with_comments: PropTypes.bool.isRequired,
            without_comments: PropTypes.bool.isRequired,
        }),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.shape({
        slice: PropTypes.arrayOf(PropTypes.shape({
            rhs: PropTypes.any,
        })),
        group: PropTypes.arrayOf(PropTypes.shape({
            rhs: PropTypes.any,
        })),
    }),
    allowDelete: PropTypes.bool.isRequired,
    combineTypes: PropTypes.array.isRequired,
};

FilterBuilder.defaultProps = {
    noMetricOptionsMsg: '',
    allowDelete: true,
    combineTypes: ['and', 'or'],
};
