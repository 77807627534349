import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropdownContextProvider } from './internal/DropdownContext';

function isInTree(rootNode, testNode) {
    if (rootNode && testNode) {
        return rootNode === testNode || rootNode.contains(testNode);
    }
    else {
        return false;
    }
}

function isWithinDropdown(anchorNode, contentNode, testNode) {
    return isInTree(anchorNode, testNode) || isInTree(contentNode, testNode);
}

const Root = styled.div`
    position: relative;
    display: inline-block;
    outline: none;
`;

export default class Dropdown extends React.Component {
    static propTypes = {
        Component: PropTypes.elementType,
        open: PropTypes.bool,
        onBlur: PropTypes.func,
        onBlurDeep: PropTypes.func,
    };

    static defaultProps = {
        Component: 'div',
        open: false,
        onBlurDeep: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
        };

        this._anchorRef = React.createRef();
        this._contentRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    _handleBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }

        const rootNode = this._anchorRef.current;
        const contentNode = this._contentRef.current;
        const newFocus = event.relatedTarget;

        const hasFocus = isWithinDropdown(rootNode, contentNode, newFocus);

        if (!hasFocus) {
            // The focus has moved away from the dropdown. This can also happen
            // when the user focuses outside the document, but we only want to
            // trigger blurDeep when the user focuses elsewhere within the document.
            // The way to check for this is to see that we are still the
            // activeElement. If we are not, then trigger the blurDeep.
            // Otherwise, we want to keep focus (preventDefault).

            const isActiveElement = isWithinDropdown(rootNode, contentNode, document.activeElement);

            if (!isActiveElement) {
                this.props.onBlurDeep();
            }
            else {
                event.preventDefault();
            }
        }
    };

    render() {
        const { open, onBlur, onBlurDeep, Component, ...otherProps } = this.props;
        const { mounted } = this.state;

        return (
            <DropdownContextProvider
                value={{
                    isDefault: false,
                    open: open,
                    getAnchorNode: () => this._anchorRef.current,
                    contentRef: this._contentRef,
                }}
            >
                {mounted && (
                    <Root
                        {...otherProps}
                        ref={this._anchorRef}
                        as={Component}
                        tabIndex="0"
                        onBlur={this._handleBlur}
                    />
                )}
            </DropdownContextProvider>
        );
    }
}
