import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Page from 'Components/page/Page';
import { Body1 } from 'Components/typography/typography';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentStandard';
import PageContentWide from 'Components/page/PageContentWide';
import PageDescription from 'Components/page/PageDescription';
import DatauseDescription from 'Components/data-use-description/DataUseDescription';
import ErrorNotice from 'Components/notice/ErrorNotice';
import SuccessNotice from 'Components/notice/SuccessNotice';
import { logout, updatePassword, login, updatePasswordReset } from 'Redux/actions';
import LoginForm from 'Components/login-form/LoginForm';
import { selectSessionRequest, selectIsLoggedIn } from 'Redux/reducers/session';
import SetPasswordForm from './SetPasswordForm';
import { PENDING } from 'Common/data/RequestStatus';
import { REQUEST_SUCCESS } from 'Common/util/ResourceCollection/actions';

const STEPS = {
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    LOGIN: 'LOGIN',
    SETTINGS: 'SETTINGS',
};

export class SetupNewUserPagePresentation extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string
        }).isRequired,
        login: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        updatePassword: PropTypes.func.isRequired,
        updatePasswordReset: PropTypes.func.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        loginRequest: PropTypes.object.isRequired,
        logoutRequest: PropTypes.object.isRequired,
        updatePasswordRequest: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentStep: STEPS.UPDATE_PASSWORD,
        };
    }

    componentDidMount() {
        this.props.updatePasswordReset();
    }

    _handlePasswordChange = (event) => {
        const password = event.target.value;

        this.setState((state) => {
            return {
                value: {
                    ...state.value,
                    password: password,
                },
                dirty: {
                    ...state.dirty,
                    password: true,
                },
            };
        });
    };

    _handleUpdatePasswordSubmit = async (event) => {
        const {
            isLoggedIn,
            logout: logoutAction,
            updatePassword: updatePasswordAction,
        } = this.props;

        if (isLoggedIn) {
            // We don't actually care if this succeeds, it's just a nice-to-have.
            await logoutAction();
        }

        const urlParams = queryString.parse(this.props.location.search);

        const response = await updatePasswordAction({
            new_password: event.target.value.password,
            token: urlParams.token,
        });

        // If the update password request was successful, move to the next step
        if (response.type === REQUEST_SUCCESS) {
            this.setState((state) => {
                return {
                    currentStep: STEPS.LOGIN,
                };
            });
        }
    };

    _handleLoginSubmit = async ({ email, password }) => {
        const { login: loginAction } = this.props;

        const response = await loginAction({ email, password });

        // If the login request was successful, move to the next step
        if (response.type === REQUEST_SUCCESS) {
            this.setState({
                currentStep: STEPS.SETTINGS,
            });
        }
    };

    _validUrl(params) {
        // linking token is the only required url parameter
        return !!params.token;
    }

    _renderInvalidUrl() {
        return (
            <Page>
                <PageTitle>Invalid New User Link</PageTitle>
                <PageContent>
                    <ErrorNotice>
                        <Body1 as="div">
                            The link used to come to this page is invalid
                        </Body1>
                    </ErrorNotice>
                </PageContent>
            </Page>
        );
    }

    _renderUpdatePassword() {
        const { logoutRequest, updatePasswordRequest } = this.props;

        let mergedRequest = { ...updatePasswordRequest };

        // Merge the requests together and treat them like one request.
        if (logoutRequest.status === PENDING) {
            mergedRequest.status = PENDING;
        }

        return (
            <Page>
                <PageTitle>New User Setup</PageTitle>
                <PageDescription>
                    Use this page to create a password for your new XL Enterprise account.
                </PageDescription>
                <PageContent>
                    <SetPasswordForm
                        request={mergedRequest}
                        onSubmit={this._handleUpdatePasswordSubmit}
                    />
                </PageContent>
                <PageContentWide>
                    <DatauseDescription/>
                </PageContentWide>
            </Page>
        );
    }

    _renderLogin() {
        const { loginRequest } = this.props;

        return (
            <Page>
                <PageTitle>Log In</PageTitle>
                <PageContent>
                    <SuccessNotice>
                        Congratulations, you have successfully set up a new account!
                        Please log in to continue.
                    </SuccessNotice>
                    <LoginForm
                        request={loginRequest}
                        onSubmit={this._handleLoginSubmit}
                    />
                </PageContent>
            </Page>
        );
    }

    _renderSettings() {
        return (
            <Redirect to={{
                pathname: '/config/user/profile',
            }}/>
        );
    }

    render() {
        const { currentStep } = this.state;

        const urlParams = queryString.parse(this.props.location.search);

        if (!this._validUrl(urlParams)) {
            return this._renderInvalidUrl();
        }

        switch (currentStep) {
            case STEPS.UPDATE_PASSWORD:
                return this._renderUpdatePassword();
            case STEPS.LOGIN:
                return this._renderLogin();
            case STEPS.SETTINGS:
                return this._renderSettings();
            default:
                throw new Error(`Unknown step: ${currentStep}`);
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: selectIsLoggedIn(state.session),
        loginRequest: selectSessionRequest(state.session, 'login'),
        logoutRequest: selectSessionRequest(state.session, 'logout'),
        updatePasswordRequest: selectSessionRequest(state.session, 'updatePassword'),
    };
};

const mapDispatchToProps = {
    login: login,
    logout: logout,
    updatePassword: updatePassword,
    updatePasswordReset: updatePasswordReset,
};

// connect the component to Redux
const SetupNewUserPage = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SetupNewUserPagePresentation));

export default SetupNewUserPage;
