import { sendGetRequest } from 'Http/request';
import { getResource } from 'Common/util/ResourceCollection';

export function getMetricThresholds() {
    return getResource({
        resourceType: 'metricThresholds',
        requestId: 'getMetricThresholds',
        sendRequest: async () => {
            return sendGetRequest('/api/v1/alerts/metric-thresholds');
        }
    });
}

export default getMetricThresholds;
