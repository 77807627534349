import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'Common/components/Tooltip';
import TooltipContent from './internal/TooltipContent';
import Icon from 'Common/components/Icon';
import { useTranslator } from 'Common/hooks/useTranslation';
import { Body5 } from 'Common/components/typography';
import { TOOLTIP_LARGE } from 'Common/components/Tooltip/Tooltip';
import { TEXT_FORMAT_TYPE } from 'Common/components/SparkVisualization/SparkDimensionConstants';
import { categoryValueFormatTypes } from '../FormattedDatum/CategoryValue/CategoryValue';

const emptyArray = [];

const SparkTextContainer = styled.div.attrs(({ $width, $height }) => {
    if (isFinite($width)) {
        return {
            style: {
                width: `${$width}px`,
                height: `${$height}px`,
            },
        };
    }
    else {
        return {
            style: {
                height: `${$height}px`,
            },
        };
    }
    
})`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

function TextSparkVisualization(props) {
    const translator = useTranslator();
    const {width, height, data} = props;
    const values = data?.values || emptyArray;

    let textContent = values.length === 0
        ? translator('null_metric_literal')
        : translator('multiple_category_value_template', {
            number: values.length,
        });

    let disableTooltip = false;

    if (values.length === 1){
        const displayName = values[0].dimension.rawValue.displayName;
        const color = values[0]?.dimension?.color;

        if (!displayName) {
            disableTooltip = true;
        }

        if(color) {
            textContent = (
                <span>
                    <Icon
                        type='fontAwesome'
                        iconName='circle'
                        className='fa-fw'
                        style={{
                            color: color,
                        }}
                    />
                    {displayName}
                </span>
            );
        }
        else {
            textContent = displayName;
        }
    }

    return (
        <Tooltip
            delay={500}
            placement="auto"
            content={<TooltipContent data={data} />}
            style={{ display: 'inline-block'}}
            maxWidth={TOOLTIP_LARGE}
            disabled={disableTooltip}
        >
            <SparkTextContainer
                $width={width}
                $height={height}
            >
                <Body5>{textContent}</Body5>
            </SparkTextContainer>
        </Tooltip>
    );}

TextSparkVisualization.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        formatType: PropTypes.oneOf([TEXT_FORMAT_TYPE]).isRequired,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                dimension: PropTypes.shape({
                    displayName: PropTypes.string,
                    name: PropTypes.string,
                    formatType: PropTypes.oneOf(categoryValueFormatTypes).isRequired,
                    rawValue: PropTypes.shape({
                        displayName: PropTypes.string,
                        name: PropTypes.string,
                        color: PropTypes.string
                    }),
                }).isRequired,
                metric: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    formatType: PropTypes.string.isRequired,
                    rawValue: PropTypes.number
                }),
            }),
        ).isRequired,
    }),
    width: PropTypes.number,
    height: PropTypes.number.isRequired,
    noColor: PropTypes.bool,
};

export default TextSparkVisualization;
