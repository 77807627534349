/**
 * The RadioButtonGroup component displays a group of RadioButton components. The RadioButton
 * matching {value} will initially be selected. Clicking on one selects it and deselects the
 * others. Specifying {disabled} disables all of the grouped RadioButton controls. Clicking on an
 * enabled button or its adjacent text will invoke the provided {onChange} callback and pass it the
 * following object:
 * 
 *  target {
 *      value: {value}      // String used to identify the button
 *  }
 * 
 * Sample usage:
 * 
 *  <RadioButtonGroup value="option_a" disabled onChange={() => {}}>
 *      <RadioButton value="option_a">Option A</RadioButton>
 *      <RadioButton value="option_b">Option B</RadioButton>
 *      <RadioButton value="option_c">Option C</RadioButton>
 *  </RadioButtonGroup>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'Common/components/RadioButton';

export default function RadioButtonGroup({
    children,   // List of RadioButton components
    className,
    disabled,   // Is the radio button group disabled?
    value,      // Key identifying the selected RadioButton component
    onChange    // Callback when control is clicked
}) {
    if (React.Children.count(children) <= 0) {
        return null;
    }

    const jsxChildren = React.Children.map(children, (child) => {
        if (child.type == RadioButton) {
            const childProps = {
                ...child.props,
                disabled: disabled,
                selected: (child.props.value === value),
                value: child.props.value,
                onChange: onChange
            };

            return React.cloneElement(child, childProps);
        }
        else {
            return child;
        }
    });

    return (
        <div className={className}>
            {jsxChildren}
        </div>
    );
}

RadioButtonGroup.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func
};

RadioButtonGroup.defaultProps = {
    disabled: false,
    value: '',
    onChange: () => {}
};
