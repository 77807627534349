
import React, {
    useEffect,
} from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { selectMetricThresholds } from 'Redux/reducers/metricThresholds';
import {
    getMetricThresholds,
    putMetricThresholds,
} from 'Redux/actions';
import Page from 'Components/page/Page';
import PageContent from 'Components/page/PageContentWide';
import ConfigPageTitle from 'Components/page/ConfigPageTitle';
import MetricThresholdsionForm from './MetricThresholdsForm';
import RequestLoadingWrapper from 'Components/loading-indicator/RequestLoadingWrapper';

function MetricThresholdsPage(props) {

    const {
        actions,
        metricThresholds,
        requests,
    } = props;

    useEffect(() => {
        actions.getMetricThresholds();
    }, [actions]);

    const handleSubmit = (evt) => {
        actions.putMetricThresholds({
            metrics: evt
        });
    };

    return (
        <Page>
            <ConfigPageTitle>Metric Thresholds</ConfigPageTitle>
            <PageContent>
                <RequestLoadingWrapper
                    request={requests.getMetricThresholds}
                    render={() => (
                        <MetricThresholdsionForm
                            metricThresholds={metricThresholds}
                            requests={requests}
                            onSubmit={handleSubmit}
                        />
                    )}
                />
            </PageContent>
        </Page>
    );
}

MetricThresholdsPage.propTypes = {
    actions: PropTypes.shape({
        getMetricThresholds: PropTypes.func.isRequired,
        putMetricThresholds: PropTypes.func.isRequired,
    }).isRequired,

    metricThresholds: PropTypes.array,
    requests: PropTypes.shape({
        getMetricThresholds: PropTypes.object.isRequired,
        putMetricThresholds: PropTypes.object.isRequired,
    }),
};

const mapStateToProps = (state, ownProps) => {
    const {
        metricThresholds,
    } = state;
    return {
        metricThresholds: selectMetricThresholds(metricThresholds),
        requests: (metricThresholds?.requests) || null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getMetricThresholds,
            putMetricThresholds,
        }, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MetricThresholdsPage);
