
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslator } from 'Common/hooks/useTranslation';

import Table from 'Components/table/Table';
import TableHeader from 'Components/table/TableHeader';
import TableHeaderRow from 'Components/table/TableHeaderRow';
import TableHeaderColumn from 'Components/table/TableHeaderColumn';
import TableBody from 'Components/table/TableBody';
import TableRow from 'Components/table/TableRow';
import TableColumn from 'Components/table/TableColumn';
import NumberField from 'Common/components/NumberField';
import './MetricThresholds.scss';

const StyledDiv = styled.div`
    min-width: 225px;
`;

const RangeDiv = styled.div`
    min-width: 125px;
    display: flex;
    gap: 6px;
    align-items: center;
`;

function convertToIntPercent(val) {
    // round to the nearest integer to avoid 54.999999999 and other floating point artifacts
    return Math.round(val * 100.0);
}

export default function MetricThresholdsTable(props) {
    const {
        errorList,
        metricThresholds,
        onChange,
    } = props;

    const translator = useTranslator();

    const sortFunc = (lhs, rhs) => {
        return translator(`term.${lhs.name}.name`).localeCompare(translator(`term.${rhs.name}.name`));
    };

    const renderRows = () => {
        return metricThresholds.sort(sortFunc).map((metricThreshold, idx) => {
            const errors = errorList[idx];
            const {
                name,
                directionality,
                ranges,
            } = metricThreshold;

            const directionInfo = directionality === 'higher_better' ? {
                crit: 'Below',
                good: 'above',
                highSide: 'max',
                lowSide: 'min',
                adjust: -0.1,
            } : {
                crit: 'Above',
                good: 'below',
                highSide: 'min',
                lowSide: 'max',
                adjust: 0.1,
            };

            const rangeMap = ranges.reduce((acc, field) => {
                acc[field.name] = field;
                return acc;
            }, {});

            const warningError = errors.includes('warning') ? 'Invalid Range' : undefined;
            const cautionError = errors.includes('caution') ? 'Invalid Range' : undefined;

            return (
                <TableRow key={name}>
                    <TableColumn>
                        <StyledDiv>
                            {translator(`term.${name}.name`)} {metricThreshold.error}
                        </StyledDiv>
                    </TableColumn>
                    <TableColumn>
                        <StyledDiv>
                            {translator(directionality)}
                        </StyledDiv>
                    </TableColumn>
                    <TableColumn>
                        <RangeDiv>
                            {directionInfo.crit} {convertToIntPercent(rangeMap.critical[directionInfo.highSide])}%
                        </RangeDiv>
                    </TableColumn>
                    <TableColumn>
                        <RangeDiv>
                            <span>
                                <NumberField
                                    className="number-field-overrides"
                                    name="threshold"
                                    onChange={(evt) => onChange(name, 'warning', 'critical', directionInfo.lowSide, directionInfo.highSide, evt.target.value)}
                                    percent={true}
                                    type="number"
                                    value={rangeMap.warning[directionInfo.lowSide]}
                                />
                            </span>
                            <span>
                                to {convertToIntPercent(rangeMap.warning[directionInfo.highSide]) + directionInfo.adjust}%
                            </span>
                        </RangeDiv>
                    </TableColumn>
                    <TableColumn>
                        <RangeDiv>
                            <span>
                                <NumberField
                                    className="number-field-overrides"
                                    name="threshold"
                                    onChange={(evt) => onChange(name, 'caution', 'warning', directionInfo.lowSide, directionInfo.highSide, evt.target.value)}
                                    percent={true}
                                    error={warningError}
                                    type="number"
                                    value={rangeMap.caution[directionInfo.lowSide]}
                                />
                            </span>
                            <span>
                                to {convertToIntPercent(rangeMap.caution[directionInfo.highSide]) + directionInfo.adjust}%
                            </span>
                        </RangeDiv>
                    </TableColumn>
                    <TableColumn>
                        <RangeDiv>
                            <span>
                                <NumberField
                                    className="number-field-overrides"
                                    name="threshold"
                                    onChange={(evt) => onChange(name, 'good', 'caution', directionInfo.lowSide, directionInfo.highSide, evt.target.value)}
                                    percent={true}
                                    error={cautionError}
                                    type="number"
                                    value={rangeMap.good[directionInfo.lowSide]}
                                />
                            </span>
                            <span>
                                and {directionInfo.good}
                            </span>
                        </RangeDiv>
                    </TableColumn>
                </TableRow>
            );
        });
    };

    return (
        <Table>
            <TableHeader>
                <TableHeaderRow>
                    <TableHeaderColumn>
                        Value
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                        Directionality
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                        Critical
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                        Warning
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                        Caution
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                        Good
                    </TableHeaderColumn>
                </TableHeaderRow>
            </TableHeader>
            <TableBody>
                {renderRows()}
            </TableBody>
        </Table>
    );
}

MetricThresholdsTable.propTypes = {
    errorList: PropTypes.array.isRequired,
    metricThresholds: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};
