import React, { useState, useCallback } from 'react';
import SearchField from 'Common/components/SearchField';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

export function doesValueMatchSearch(value, searchValue) {
    return (
        searchValue === '' ||
        (includes(toLower(value), toLower(searchValue)))
    );
}

/**
 * A hook to provide a search box for any component, returns both the value of the search
 * box and a render function for the search box so it can be rendered anywhere in the
 * component.
 *
 * @param {Boolean} [autoFocus=true] If true the search box will be focused when it is first rendered.
 * @param {Boolean} [disabled=false] If true it will disable the search box.
 * @param {String} [placeholder=''] The placeholder text for the search input.
 *
 * @returns {Object} With the following fields:
 *     - searchValue {String} The value of the search box.
 *     - renderSearchBox {Function} React render function to display the search box.
 */
export default function useSearchBox({
    autoFocus = true,
    disabled = false,
    placeholder = '',
}) {
    const [searchValue, setSearchValue] = useState('');

    const onSearchChange = useCallback((event) => {
        setSearchValue(event.target.value);
    }, []);

    const renderSearchBox = useCallback(() => {
        return (
            <SearchField
                disabled={disabled}
                autoFocus={autoFocus}
                value={searchValue}
                onChange={onSearchChange}
                placeholder={placeholder}
            />
        );
    }, [autoFocus, disabled, placeholder, onSearchChange, searchValue]);

    return {
        searchValue,
        renderSearchBox,
    };
}
