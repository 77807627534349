import React from 'react';

/**
 * A composite `PropTypes` validator that takes in multiple validators and passes
 * if at least one of them passes.
 *
 * @param {function[]} validators An array of PropTypes validators
 */
export function anyOf(validators) {
    return function validate(props, propName, componentName, ...rest) {
        let passed = false;

        let errors = validators
            .map((validator) => {
                // optimize to stop validating if one passed
                if (passed) {
                    return;
                }

                if (typeof validator !== 'function') {
                    throw new TypeError(`anyOf: expected valildator of type \`function\`, got \`${typeof validator}\``);
                }

                const error = validator(props, propName, componentName, ...rest);
                if (!error) {
                    passed = true;
                }
                return error;
            })
            .filter((error) => !!error);

        if (!passed) {
            return new Error(`${componentName} prop ${propName} must satisfy at least one of the following errors:\n`
                + `${errors.map((error, i) => `\t\t${i + 1}. ${error.message}`).join('\n')}`);
        }
    };
}

function _assertChildrenPropName(propName, componentName) {
    if (propName !== 'children') {
        throw new Error(`\`${componentName}.propTypes.${propName}\` has invalid use of \`elementsOfType\`.`
            + 'This validator should only be used for the prop `children`.');
    }
}

function _validateRequiredProps({ el, requiredProps, componentName }) {
    let error;

    requiredProps.some((propName) => {
        if (!el.props[propName]) {
            error = new Error(
                `${componentName} requires child to have prop ${propName}`
            );

            return true;
        }
    });

    return error;
}

function _validateElementType({ el, allowedTypes, componentName, propName }) {
    let error;

    let elemDisplayType = typeof el;

    if (el === null) {
        return; // nulls can be ignored
    }
    else if (typeof el === 'object') {
        if (allowedTypes.indexOf(el.type) !== -1) {
            return;
        }
        else {
            elemDisplayType = String((el.type && el.type.displayName) || el.type);
        }
    }

    error = new Error(`${componentName} has invalid ${propName} element type of "${elemDisplayType}".`
        + ` Elements must be of type (${allowedTypes.map(c=>c.displayName).join('|')})`);

    return error;
}

/**
 * Accepts an array of types and required props (in the form of an array)
 * that the children must be or have. Each child only needs to match
 * one of these requirements. NOTE: Should only be used on the
 * `children` prop.
 *
 * @param {Type[]} elementTypes
 * @param {String[][]} elementTypes
 */
export function childrenAreOneOf({ elementTypes, propShapes }) {
    elementTypes = Array.isArray(elementTypes) ? elementTypes : [elementTypes];
    propShapes = Array.isArray(propShapes) ? propShapes : [propShapes];

    return (props, propName, componentName) => {
        _assertChildrenPropName(propName, componentName);

        const children = props[propName];
        let error;

        React.Children.forEach(children, (el) => {
            if (error) {
                return ;
            }

            // Ignore falsy children
            if (!el) {
                return ;
            }

            const typeError = _validateElementType({
                el,
                allowedTypes: elementTypes,
                componentName,
                propName,
            });

            let propError;

            propShapes.some((propShape) => {
                propError = _validateRequiredProps({
                    el,
                    requiredProps: propShape,
                    componentName,
                });

                return !propError;
            });

            error = (typeError && propError) || null;
        });

        return error;
    };
}

/**
 * A `PropTypes` validator that checks that all the elements are one of the provided
 * element types. NOTE: Should only be used on the `children` prop.
 *
 * @param {*[]} allowedTypes An array of element types
 */
export function elementsOfType(allowedTypes) {
    return (props, propName, componentName) => {
        _assertChildrenPropName(propName, componentName);

        const children = props[propName];
        let error;

        // Children must be of the proper type
        React.Children.forEach(children, (el) => {
            if (error) {
                return ;
            }

            error = _validateElementType({
                el,
                allowedTypes,
                componentName,
                propName,
            });
        });

        return error;
    };
}

/**
 * A `PropTypes` validator that checks that there is one child and the element is one
 * of the provided element types. NOTE: Should only be used on the `children` prop.
 *
 * @param {*[]} allowedTypes An array of element types
 */
export function elementOfType(allowedTypes) {
    return (props, propName, componentName) => {
        _assertChildrenPropName(propName, componentName);

        const children = props[propName];
        let error;

        try {
            const childEl = React.Children.only(children);

            error = _validateElementType({
                el: childEl,
                allowedTypes,
                componentName,
                propName,
            });
        }
        catch (e) {
            error = new Error(`${componentName} has more than one child element.`
                + ' If multiple children are expected use `elementsOfType` instead.');
        }

        return error;
    };
}

export const objectOrNull = function(props, propName) {
    const data = props[propName];

    if (data === undefined) {
        return new Error(`Undefined ${propName} is not allowed`);
    }

    if (data === null) {
        return ;
    }

    if (!(data instanceof Object)) {
        return new Error(`${propName} must be an instance of an Object`);
    }
};
