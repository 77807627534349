import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import EventDataGroup from 'Common/components/EventData/EventDataGroup';

const Root = styled.div``;

const WrapperRow = styled.div`
    height: 12px;
    background-color: ${({theme}) => theme.colors.palette.grey.manatee};
    border-radius: ${(props) => props.position === 'header'
        ? css`4px 4px 0px 0px`
        : css`0px 0px 4px 4px`
    };
`;

/**
 * A simple wrapper around EventDataGroup that adds a header and footer and default column
 * configs for use in KPIGroupWidget. All children are forwarded to EventDataGroup.
 *
 * @see EventDataGroup
 */
export default function KPIGroupContent({
    children,
    isPrint,
}) {
    const columnOrder = ['label', 'value'];
    const columnConfigs = {
        label: {
            name: 'label',
            size: 1
        },
        value: {
            name: 'value',
            size: 1
        }
    };

    return (
        <Root>
            {!isPrint && <WrapperRow position='header'/>}
            <EventDataGroup columnOrder={columnOrder} columnConfigs={columnConfigs}>
                {children}
            </EventDataGroup>
            {!isPrint && <WrapperRow position='footer'/>}
        </Root>
    );
}

KPIGroupContent.propTypes = {
    children: PropTypes.node,
    isPrint: PropTypes.bool,
};

KPIGroupContent.defaultProps = {
    isPrint: false,
};
