import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DraggableListItem from './DraggableListItem';
import TrashCanDrop from './TrashCanDrop';
import TrashCan, { TRASH_CAN_HEIGHT } from './TrashCan';
import { 
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
    rectIntersection,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

const TRASH_ID = 'TRASH_ID';
const HALF_TRASH_CAN_HEIGHT = TRASH_CAN_HEIGHT / 2;

const Root = styled.div`
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    min-height: 1px;
`;

const SortableContainer = styled.div`
    overflow-y: auto;
    flex: 1 1 auto;
`;

function customCollisionDetectionStrategy(rects, rect) {
    let trashRect = null;
    const otherRects = rects.filter((currentRect) => {
        const currentRectId = currentRect[0];

        if (currentRectId === TRASH_ID) {
            trashRect = currentRect;
            return false;
        }
        else {
            return true;
        }
    });

    // [FUTUREHACK]:
    // The droppable trash is below the sortable list of items.
    // This causes an issue of the trash's offset 
    // being within with the sortable list items offsets.
    // The simplest solution was to
    // pretend the trash is actually at the end of the list

    if (trashRect) {
        trashRect = [
            TRASH_ID,
            { ...trashRect[1], height: TRASH_CAN_HEIGHT, offsetTop: ( trashRect[1].offsetTop + HALF_TRASH_CAN_HEIGHT) }
        ];
        const updatedRects = [ ...otherRects, trashRect ];
        return rectIntersection(updatedRects, rect);
    }
    else {
        return rectIntersection(rects, rect);
    }
}

/**
 * DraggableListWithTrashCan is just like DraggableList, displaying a vertical list of provided nodes, 
 * allowing them to be reordered via drag/drop, keyboard, and touch.
 * Difference here is that the list tries to stay within its container and always show a trash can
 * below that the nodes can be dragged into to trigger onDelete.
 */

export default function DraggableListWithTrashCan({
    className,
    children,
    onDragEnd,
    onDelete,
    deleteHelperText
}) {
    const [ activeId, setActiveId ] = useState(null);
    const [ overTrash, setOverTrash ] = useState(false);

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragOver = useCallback((event) => {
        // Because we have a fake trash drop target
        // this handles styling the fake trash drop target
        // when we are 'suppose' to be over it
        if (event?.over?.id === TRASH_ID && overTrash !== true) {
            setOverTrash(true);
        }
        else if (event?.over?.id !== TRASH_ID && overTrash === true) {
            setOverTrash(false);
        }
    }, [overTrash]);

    const handleDragEnd = useCallback((event) => {
        setActiveId(null);
        setOverTrash(false);
        const { active, over } = event;

        if (over?.id === TRASH_ID) {
            onDelete({ id: active.id, index: active.data.current.index });
        }
        // if no over we dropped the item oustide the list
        else if (over) {
            onDragEnd({
                source: {
                    id: active.id,
                    index: active.data.current.index,
                },
                destination: {
                    id: over.id,
                    index: over.data.current.index,
                },
            });
        }

    }, [ onDragEnd, onDelete ]);

    const handleDragCancel = useCallback((event) => {
        setActiveId(null);
    }, []);

    const childrenArray = useMemo(() => (
        React.Children.toArray(children)
    ), [ children ]);

    const childNames = useMemo(() => (
        childrenArray.map(child => child.props.name)
    ), [ childrenArray ]);

    const childrenByName = useMemo(() => (
        childrenArray.reduce((acc, child) => {
            acc[child.props.name] = child;
            return acc;
        }, {})
    ), [ childrenArray ]);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
              distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Require a delay so a long press it what triggers drag
            activationConstraint: {
              delay: 1000,
              tolerance: 10,
            },
        }),
        useSensor(KeyboardSensor),
    );

    return (
        <Root
            className={className}
        >
            <DndContext
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
                sensors={sensors}
                collisionDetection={customCollisionDetectionStrategy}
                autoScroll={!overTrash}
            >
                <SortableContainer>
                    <SortableContext
                        strategy={verticalListSortingStrategy}
                        items={childNames}
                    >
                        {childrenArray.map((child, i) => {
                            const childName = child.props.name;

                            return (
                                <DraggableListItem
                                    key={childName}
                                    index={i}
                                    name={childName}
                                    highlightDropZone={true}
                                    onDelete={onDelete}
                                >
                                    {child}
                                </DraggableListItem>
                            );
                        })}
                    </SortableContext>
                    <TrashCanDrop
                        name={TRASH_ID}
                    />
                </SortableContainer>
                <DragOverlay>
                    {activeId ? React.cloneElement(childrenByName[activeId], { isDragging: true, overTrash }) : null}
                </DragOverlay>
                <TrashCan
                    deleteHelperText={deleteHelperText}
                    isOver={overTrash}
                />
            </DndContext>
        </Root>
    );
}

DraggableListWithTrashCan.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onDragEnd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    deleteHelperText: PropTypes.string,
};

DraggableListWithTrashCan.defaultProps = {
};
