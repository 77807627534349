import React from 'react';
import { Body1 } from 'Components/typography/typography';
import { Link } from 'react-router-dom';
import Page from 'Components/page/Page';
import PageTitle from 'Components/page/PageTitle';
import PageContent from 'Components/page/PageContentStandard';
import PageDescription from 'Components/page/PageDescription';
import PropTypes from 'prop-types';
import { getRoleDisplayText } from 'Components/app/roles';

export default class ForbiddenPage extends React.Component {
    static propTypes = {
        minRole: PropTypes.string.isRequired,
    };

    render() {
        const { minRole } = this.props;

        return (
            <Page>
                <PageTitle>Forbidden</PageTitle>
                <PageDescription>
                    You do not have permission to access this page.
                    This action requires a minimum role of &quot;{getRoleDisplayText(minRole)}&quot;.
                </PageDescription>
                <PageContent>
                    <Body1>Return to the <Link to="/">home page</Link>.</Body1>
                </PageContent>
            </Page>
        );
    }
}
