import React from 'react';
import PropTypes from 'prop-types';
import SearchableSelectList from '../SelectList/SearchableSelectList';
import HierarchyListOption from './internal/HierarchyListOption';

function EnterpriseHierarchySelectList(props) {
    return (
        <SearchableSelectList
            disabled={props.disabled}
            onChange={props.onChange}
            searchBoxPlaceholder={props.searchBoxPlaceholder}
            noSelectionText={props.noSelectionText}
            value={props.value}
        >
            {props.hierarchyList && props.hierarchyList.map((asset) => {
                return (
                    <HierarchyListOption
                        key={asset.name}
                        value={asset.name}
                        searchValue={asset.displayName}
                        asset={asset}
                        disabled={props.onlyAssets && !asset.isAsset}
                        showSelectedIcon={props.showIconForSelected  && props.value === asset.name}
                    />
                );
            })}
        </SearchableSelectList>
    );
}

EnterpriseHierarchySelectList.propTypes = {
    disabled: PropTypes.bool,
    hierarchyList: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            iconClass: PropTypes.string.isRequired,
            level: PropTypes.number.isRequired,
        })
    ),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    noSelectionText: PropTypes.string.isRequired,
    searchBoxPlaceholder: PropTypes.string.isRequired,
    onlyAssets: PropTypes.bool,
    showIconForSelected: PropTypes.bool,
};

EnterpriseHierarchySelectList.defaultProps = {
    disabled: false,
    onlyAssets: false,
    showIconForSelected: false,
};

export default React.memo(EnterpriseHierarchySelectList);
