import React from 'react';
import PropTypes from 'prop-types';
import { useDroppable } from '@dnd-kit/core';

/**
 * This is used in DraggableListWithTrashCan. It's the actual droppable target. It is
 * not shown, it is used to keep the trash can in the correct location compared to the
 * sortable list.
 */

function TrashCanDrop(props) {
    const { name } = props;

    const {
        setNodeRef
    } = useDroppable({
        id: name,
    });

    return (
        <div ref={setNodeRef} />
    );
}

TrashCanDrop.propTypes = {
    name: PropTypes.string.isRequired,
};

export default React.memo(TrashCanDrop);
