import { sendGetRequest } from 'Http/request';
import { getResource } from 'Common/util/ResourceCollection';

export function getUser(userId) {
    return getResource({
        resourceType: 'users',
        requestId: 'getUser',
        resourceId: userId,
        sendRequest: () => sendGetRequest(`/api/v1/accounts/organization/users/${userId}`),
    });
}

export default getUser;
