import React from 'react';
import PropTypes from 'prop-types';
import ParamTypes from 'Common/util/ParamTypes';
import { paramTest } from 'Common/util/ParamTypes/ParamTypes';
import styled from 'styled-components';
import useTranslation from 'Common/hooks/useTranslation';
import Tooltip from 'Common/components/Tooltip';
import Icon from 'Common/components/Icon';
import ErrorIndicator from '../ErrorIndicator';
import { Body5 } from 'Common/components/typography';

export const assetFormatTypes = [
    'asset',
    'area',
    'plant',
    'enterprise',
];

export const categoryValueFormatTypes = [
    'category_value',
    ...assetFormatTypes,
];

const Root = styled.div`
    display: inline-block;
`;

const MultipleCategoriesListItem = styled(Body5).attrs({ as: 'div' })`
    padding: 4px 5px;
`;

const MultipleCategoriesList = styled.div`
    line-height: 16px;

    ${MultipleCategoriesListItem}:nth-child(2n) {
        border-radius: 3px;
        background-color: ${({theme}) => theme.colors.palette.grey.cloud};
    }
`;

const ErrorContainer = styled.div`
    display: inline-block;
    margin-right: 4px;
`;


const ValueText = styled.span`
    .fa-circle::before {
        display: table-cell;
    };
`;


/**
 * Gets the text value for this category value.
 * @param {Object} rawValue The rawValue that makes up the category value.
 * @param {Function} translator Translates keys to correct text
 * 
 * @return {String}
 */
export function getCategoryValueText(parameters) {
    paramTest(parameters, ParamTypes.shape({
        rawValue: ParamTypes.any,
        translator: ParamTypes.func.isRequired,
    }), 'getCategoryValueText');

    const {
        rawValue,
        translator,
    } = parameters;

    let value = rawValue;

    if (!Array.isArray(value)) {
        value = [value];
    }

    if (value.length > 1) {
        return translator('multiple_category_value_template', {
            number: value.length,
        });
    }
    else {
        if(!value[0]) {
            return translator('null_metric_literal');
        }
        else {
            return value[0].displayName;
        }
    }
}

/**
 * A React component that renders a visual representation of a category
 * value. Takes in at minimum a format type and a rawValue which will be
 * formatted and rendered out.
 *
 * @param {Object} data The data that makes up the category value.
 * @param {String[]} [error] List of any errors to be displayed in
 *     place of the metric.
 * @param {boolean} [noColorIndicatorOnText=false] When true, don't render the
 *      color indicator with the text. This does not affect the color
 *      indicator's visibility in the tooltip.
 * @param {boolean} [noColor=false] When true, don't render the metric
 *     with any non-grey-scale colors. Mostly used for printing.
 * @param {boolean} [noTooltip=false] When true, disable the tooltip.
 */
function CategoryValue({
    data,
    error,
    noColorIndicatorOnText,
    noColor,
    noTooltip,

    className,
    style,
}) {
    const nullValue = useTranslation('null_metric_literal');
    const {
        rawValue,
        hasColorIndicator,
    } = (data || {});

    let value = rawValue;

    if (value === null || value === undefined) {
        value = [{
            name: 'null',
            displayName: nullValue,
            assetLink: ''
        }];
    }

    if (!Array.isArray(value)) {
        value = [value];
    }

    let content = nullValue;

    const renderValueText = ({ displayName, color }, { noColorIndicator } = { }) => {
        // If it isn't a string treat it as a null value
        displayName = (typeof displayName === 'string') ? displayName : nullValue;
        const showColorIndicator = !noColor && !noColorIndicator && hasColorIndicator;

        return (
            <ValueText>
                {showColorIndicator && (
                    <Icon
                        type='fontAwesome'
                        iconName='circle'
                        className='fa-fw'
                        style={{ color, fontSize: '10px', paddingRight: '3px' }}
                    />
                )}
                {displayName}
            </ValueText>
        );
    };

    const renderValue = ({ displayName, color, assetLink }) => {
        // If it isn't a string treat it as a null value
        displayName = (typeof displayName === 'string') ? displayName : nullValue;

        let categoryJsx = renderValueText({ displayName, color }, { noColorIndicator: noColorIndicatorOnText });

        // If this has a tooltip, wrap it in a tooltip.
        if (!noTooltip) {
            let tooltipContent;

            if (assetLink) {
                tooltipContent = `${displayName} (${assetLink})`;
            }
            else {
                tooltipContent = renderValueText({ displayName, color }, { noColorIndicator: false });
            }

            categoryJsx = (
                <Tooltip
                    style={{display: 'inline-block'}}
                    content={tooltipContent}
                    stickyOnClick
                >
                    {categoryJsx}
                </Tooltip>
            );
        }

        // If this is an asset link, wrap it in a link.
        if (assetLink) {
            categoryJsx = (
                <a href={assetLink}>
                    {categoryJsx}
                </a>
            );
        }

        return categoryJsx;
    };

    const multipleCategoriesLabel = useTranslation('multiple_category_value_template', {
        number: value.length,
    });

    if (value.length === 1) {
        content = renderValue(value[0].dimension || value[0]);
    }
    else if (value.length > 1) {
        const tooltipContent = (
            <MultipleCategoriesList>
                {
                    value.map((val, index) => (
                        <MultipleCategoriesListItem key={index}>
                            {renderValueText(val, { noColorIndicator: false })}
                        </MultipleCategoriesListItem>
                    ))
                }
            </MultipleCategoriesList>
        );

        const innerContent = (
            <span>
                {multipleCategoriesLabel}
            </span>
        );

        content = !noTooltip
            ? (
                <Tooltip
                    style={{display: 'inline-block'}}
                    content={tooltipContent}
                    stickyOnClick
                >
                    {innerContent}
                </Tooltip>)
            : innerContent;
    }

    return (
        <Root className={className} style={style}>
            {error && <ErrorContainer><ErrorIndicator error={error} /></ErrorContainer>}
            {content}
        </Root>
    );
}

const tooltipDataPropType = PropTypes.arrayOf(
    PropTypes.shape({
        dimension: PropTypes.shape({
            displayName: PropTypes.string,
            name: PropTypes.string,
            color: PropTypes.string,
        }).isRequired,
        metric: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            formatType: PropTypes.string.isRequired,
            rawValue: PropTypes.number
        }).isRequired,
    }),
).isRequired;

const categoryValuePropType = PropTypes.shape({    
    displayName: PropTypes.string,
    color: PropTypes.string,
    assetLink: PropTypes.string,
});

CategoryValue.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        formatType: PropTypes.oneOf(categoryValueFormatTypes).isRequired,
        rawValue: PropTypes.oneOfType([
            PropTypes.arrayOf(categoryValuePropType),
            categoryValuePropType,
            tooltipDataPropType,
        ]),
        hasColorIndicator: PropTypes.bool,
    }),
    error: PropTypes.arrayOf(
        PropTypes.string,
    ),
    noColorIndicatorOnText: PropTypes.bool,
    noColor: PropTypes.bool,
    noTooltip: PropTypes.bool,

    className: PropTypes.string,
    style: PropTypes.object,
};

CategoryValue.defaultProps = {
    noColorIndicatorOnText: false,
    noColor: false,
    noTooltip: false,
};

export default CategoryValue;
