import React from 'react';
import PropTypes from 'prop-types';

import LoadingWrapper from 'Components/loading-indicator/LoadingWrapper';

import * as RequestStatus from 'Common/data/RequestStatus';

function RequestLoadingWrapper({ children, request, render, ...otherProps }) {
    const { status, error } = request;

    let isLoading = true;
    let errorMessage;

    if (status === RequestStatus.SUCCESS) {
        isLoading = false;
    }
    else if (status === RequestStatus.FAILURE) {
        isLoading = false;
        errorMessage = error.message;
    }

    return (
        <LoadingWrapper
            loading={isLoading}
            error={errorMessage}
            render={render}
            {...otherProps}
        >
            {children}
        </LoadingWrapper>
    );
}

RequestLoadingWrapper.propTypes = {
    children: PropTypes.node,
    render: PropTypes.func,
    request: PropTypes.shape({
        status: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string,
        }),
    }).isRequired,
};

export default RequestLoadingWrapper;
