import { sendPatchRequest } from 'Http/request';
import { patchResources } from 'Common/util/ResourceCollection';

function generateAlertSubscriptionsPatchSection(initialData, modifiedData) {
    const generateMap = ids => ids.reduce((map, id) => {
        map[id] = true;
        return map;
    }, {});

    const initialDataMap = generateMap(initialData);
    const modifiedDataMap = generateMap(modifiedData);

    let patch = { add: [], remove: [] };

    // IDs in initial but not modified have been removed
    Object.keys(initialDataMap).forEach((id) => {
        if (!modifiedDataMap[id]) {
            patch.remove.push(Number(id));
        }
    });

    // IDs in modified but not initial have been added
    Object.keys(modifiedDataMap).forEach((id) => {
        if (!initialDataMap[id]) {
            patch.add.push(Number(id));
        }
    });

    return patch;
}

export function generateAlertSubscriptionsPatch(initialData, modifiedData) {
    const patch = {
        add: {},
        remove: {},
    };

    Object.keys(initialData).forEach((alertType) => {
        const subPatch = generateAlertSubscriptionsPatchSection(initialData[alertType], modifiedData[alertType]);
        patch.add[alertType] = subPatch.add;
        patch.remove[alertType] = subPatch.remove;
    });

    return patch;
}

export function patchAlertSubscriptions(data) {
    return patchResources({
        resourceType: 'alertSubscriptions',
        requestId: 'patchAlertSubscriptions',
        requestData: data,
        sendRequest: async () => {
            return sendPatchRequest('/api/v1/accounts/user/alert-subscriptions', {
                body: { ...data },
            });
        },
    });
}
