
import { resourceCollectionReducer, resourceReducer } from 'Common/util/ResourceCollection';
import { selectResources, } from 'Common/util/ResourceCollection/resourceCollectionReducer';

export default resourceCollectionReducer('activeSoftwareUpdates', {
    getResourceId: (resource) => resource.id,
    requests: [
        'getActiveSoftwareUpdates',
        'patchActiveSoftwareUpdates',
    ],
    resourceReducer: resourceReducer({
        requests: [
            'getActiveSoftwareUpdates',
            'patchActiveSoftwareUpdates'
        ],
    }),
});

export function getActiveSoftwareUpdatesData(state) {
    return selectResources(state);
}
