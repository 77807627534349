
import React, {
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'Components/form/Form';
import * as RequestStatus from 'Common/data/RequestStatus';
import SelectInput from 'Components/select/SelectInput';
import SelectOption from 'Components/select/SelectOption';
import SelectOptionContent from 'Components/select/SelectOptionContent';
import FormElement from 'Components/form-element/FormElement';
import styled from 'styled-components';

const StyledSelectInput = styled(SelectInput)`
    min-width: 225px;
`;

const OptionContent = styled(SelectOptionContent)`
    min-width: 200px;
    color: inherit;
`;

const day_of_week = [ {
    displayName: 'Monday',
    value: 'monday',
}, {
    displayName: 'Tuesday',
    value: 'tuesday',
}, {
    displayName: 'Wednesday',
    value: 'wednesday',
}, {
    displayName: 'Thursday',
    value: 'thursday',
}, {
    displayName: 'Friday',
    value: 'friday',
}, {
    displayName: 'Saturday',
    value: 'saturday',
}, {
    displayName: 'Sunday',
    value: 'sunday',
}];

export default function DayOfWeekForm(props) {
    const {
        requests,
        user,
        onSubmit,
    } = props;

    const [ isDirty, setIsDirty ] = useState(false);
    const [ selectedDOW, setSelectedDOW ] = useState(user.first_day_of_week);
    const [ lastPatchStatus, setLastPatchStatus ] = useState(requests?.patchSessionUser?.status);

    useEffect(() => {
        const nextPatchStatus = requests?.patchSessionUser?.status;

        if (lastPatchStatus === RequestStatus.PENDING &&
            nextPatchStatus === RequestStatus.SUCCESS
        ) {
            setIsDirty(false);
        }

        if (lastPatchStatus !== nextPatchStatus) {
            setLastPatchStatus(nextPatchStatus);
        }
    }, [lastPatchStatus, requests?.patchSessionUser?.status]);

    const handleReset = () => {
        setSelectedDOW(user.first_day_of_week);
        setIsDirty(false);
    };

    const handleSubmit = (a) => {
        onSubmit(selectedDOW);
    };

    const handleChange = (evt) => {
        setSelectedDOW(evt.target.value);
        setIsDirty(true);
    };

    const renderOptions = () => {
        return day_of_week.map((dow) => {
            return (
                <SelectOption value={dow.value} key={dow.value} >
                    <OptionContent>{dow.displayName}</OptionContent>
                </SelectOption>
            );
        });
    };

    const isLoading = requests.patchSessionUser.status === RequestStatus.PENDING;

    const allowSubmit = !isLoading && isDirty;
    const allowCancel = !isLoading && isDirty;

    return (
        <React.Fragment>
            <Form
                error={requests?.patchSessionUser?.error}
                onSubmit={handleSubmit}
                onReset={handleReset}
                allowSubmit={allowSubmit}
                allowCancel={allowCancel}
            >
                <FormElement error={false} disabled={!true}>
                    <StyledSelectInput
                        name={name}
                        value={selectedDOW}
                        onChange={handleChange}
                        optionsWidth={350}
                        maxOptionsHeight={500}
                    >
                        {renderOptions()}
                    </StyledSelectInput>
                </FormElement>
            </Form>
        </React.Fragment>
    );
}

DayOfWeekForm.propTypes = {
    user: PropTypes.object.isRequired,
    requests: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};
