import React, { useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import LiveControlBar from 'Common/components/LiveControlBar';
import LiveControl from 'Common/components/LiveControlBar/LiveControl';
import LiveControlIcon from 'Common/components/LiveControlBar/LiveControlIcon';
import LiveControlSeparator from 'Common/components/LiveControlBar/LiveControlSeparator';
import Filters from 'Common/types/Filters/Filters';

import { useTranslator } from 'Common/hooks/useTranslation';

import EnterpriseHierarchyControl from 'Common/components/LiveControls/EnterpriseHierarchyControl';
import FilterControl from 'Common/components/LiveControls/FilterControl';
import TimeRangeControl from 'Common/components/LiveControls/TimeRangeControl';
import KPIGroupColumnControl from './KPIGroupColumnControl/KPIGroupColumnControl';
import { FIELD_TYPE, HEADING_TYPE, BLANK_ROW_TYPE } from 'Common/components/widgets/KPIGroupWidget/KPIGroupWidgetConstants';
import renderDuplicateLiveControl from 'Common/components/LiveControls/DuplicateLiveControl/renderDuplicateLiveControl';

const emptyArray = [];

export default function KPIGroupWidgetLiveControls ({
    filters,
    setSliceFilters,
    filterOptions,

    followPageTimeRange,
    timeRange,
    setTimeRange,

    followAppBarHierarchyNode,
    hierarchyNode,
    setHierarchyNode,
    hierarchyNodeOptions,

    columns: selectedColumns,
    setColumns,
    columnOptions,

    isLayoutModeActive,
    duplicateWidget,

    suppressedControls,
}) {
    const translator = useTranslator();
    
    const [selectedControl, setSelectedControl] = useState(null);
    const closeSelectedControl = useCallback(() => {
        setSelectedControl(null);
    }, []);

    const hideControl = (controlName) => {
        // hide control there is a suppressed object and the control name is truthy within that
        // object
        return Boolean(suppressedControls && suppressedControls[controlName]);
    };


    // Filters
    //
    const filterLiteral = translator('control.common.filter');

    const activeFilterCount = useMemo(() => {
        return Filters.countActiveExpressions(filters);
    }, [filters]);

    const transformedFilterValue = useMemo(() => {
        // Enforce that ONLY slice filters are ever used.
        return {
            slice: filters.slice,
            group: { expressions: [] },
        };
    }, [ filters ]);

    const filterControlRef = useRef(null);

    const onFilterClose = useCallback(() => {
        return filterControlRef.current.onClose();
    }, []);

    const handleFilterApply = useCallback((event) => {
        // Close the control when the filters are applied
        closeSelectedControl();

        // We only care about slice filters
        const newSliceFilters = event.target.value.slice;
        setSliceFilters(newSliceFilters);
    }, [closeSelectedControl, setSliceFilters]);


    // Hierarchy Node
    //
    const assetHierarchyLiteral = translator('control.common.asset_hierarchy');

    const handleHierarchyNodeChange = useCallback((event) => {
        // Close the control after picking a node
        closeSelectedControl();
        setHierarchyNode(event.target.value);
    }, [closeSelectedControl, setHierarchyNode]);

    const handleFollowAppBarHierarchyNodeChange = useCallback((event) => {
        const newFollowAppBarHierarchyNode = event.target.value;

        let newHierarchyNode;

        if (newFollowAppBarHierarchyNode) {
            // Clear the hierarchy node
            newHierarchyNode = null;
        }
        else {
            // Set hierarchy node to default
            newHierarchyNode = 'self';
        }

        setHierarchyNode(newHierarchyNode);
    }, [setHierarchyNode]);


    // Time Range
    //
    const timeRangeLiteral = translator('control.common.time_range');

    const handleTimeRangeChange = useCallback((event) => {
        // Close the control after picking a time range
        closeSelectedControl();
        setTimeRange(event.target.value);
    }, [closeSelectedControl, setTimeRange]);

    const handleFollowPageTimeRangeChange = useCallback((event) => {
        const newFollowPageTimeRange = event.target.value;

        let newTimeRange;

        if (newFollowPageTimeRange) {
            // Clear the time range
            newTimeRange = null;
        }
        else {
            // Set time range to default
            newTimeRange = { units: 'shift', start: 0, end: 0 };
        }

        setTimeRange(newTimeRange);
    }, [setTimeRange]);


    // KPIGroupColumn
    const rowLiteral = translator('control.common.row');
    const rowControlIcon = (<LiveControlIcon type="vorne" iconName="row-control" />);
    const columnControlRef = useRef(null);

    const onColumnClose = useCallback(() => {
        return columnControlRef.current.onClose();
    }, [columnControlRef]);

    const handleColumnChange = useCallback((event) => {
        // Close the control after picking a node
        closeSelectedControl();

        // convert format of columns to stf friendly
        const modifiedColumns = event.target.value.reduce((contents, field) => {
            if (field.type === BLANK_ROW_TYPE) {
                contents.push({
                    type: field.type,
                });
            }
            else if (field.type === HEADING_TYPE) {
                contents.push({
                    heading: { displayName: field.displayName },
                    type: field.type,
                });
            }
            else if (field.type === FIELD_TYPE) {
                const fieldStf = {
                    type: field.type,
                    field: {
                        name: field.name,
                        channel: field.channel,
                    },
                };

                // Add in dimension visualization config, if given.
                if (field.dimensionVisualization) {
                    const dv = field.dimensionVisualization;

                    fieldStf.field.dimensionVisualization = {
                        visualization: dv.visualization,
                        metric: dv.metric,
                        orderBy: dv.orderBy,
                        maxElementsShown: dv.maxElementsShown,
                    };
                }

                contents.push(fieldStf);
            }
            // if somehow its a type we dont know ignore it

            return contents;
        }, []);

        setColumns(modifiedColumns);
    }, [closeSelectedControl, setColumns]);

    const fullSelectedColumns = useMemo(() => {
        const columnsByName = Object.keys(columnOptions).reduce((acc, fieldType) => {
            // The chips only show "top-level" selections of category values
            // or metrics.
            if (fieldType === 'category_value' || fieldType === 'metric') {
                columnOptions[fieldType].forEach(group => {
                    group.entries.forEach(col => {
                        acc[col.name] = col;
                    });
                });
            }

            return acc;
        }, {});

        return selectedColumns.reduce((fields, field, index) => {
            if (field.type === BLANK_ROW_TYPE) {
                fields.push({
                    name: `separator_${index}`,
                    displayName: translator('widget.kpi_group.blank_row'),
                    type: field.type,
                });
            }
            else if (field.type === HEADING_TYPE) {
                let displayName = field.heading?.displayName
                    ? field.heading?.displayName
                    : field.heading?.displayKey ? translator(field.heading.displayKey) : translator('widget.kpi_group.heading');

                fields.push({
                    name: `heading_${index}`,
                    displayName,
                    type: field.type,
                });
            }
            else if (field.type === FIELD_TYPE) {
                const channel = field.field.channel || 'production_metric';
                const column = columnsByName[field.field.name];

                const fullField = {
                    type: field.type,
                    name: field.field.name,
                    channel: channel,
                    displayName: column?.displayName || translator('na_symbol'),
                    fieldType: column?.fieldType || 'invalid',
                };

                if (field.field.dimensionVisualization) {
                    fullField.dimensionVisualization = field.field.dimensionVisualization;
                }
    
                fields.push(fullField);
            }
            // if somehow its a type we dont know ignore it

            return fields;
        }, []);
    }, [selectedColumns, translator, columnOptions]);


    const handleSelectionChange = useCallback((newSelection) => {
        let blockSelectionChange = false;

        if (selectedControl === 'filter' && newSelection !== 'filter') {
            blockSelectionChange = !onFilterClose();
        }

        if (selectedControl === 'columns' && newSelection !== 'columns') {
            onColumnClose();
        }

        if (!blockSelectionChange) {
            setSelectedControl(newSelection);
        }
    }, [selectedControl, onFilterClose, onColumnClose]);

    const showDuplicateControl = () => {
        return isLayoutModeActive && ! hideControl('duplicateWidget');
    };

    return (
        <LiveControlBar
            selection={selectedControl}
            onSelectionChange={handleSelectionChange}
        >
            { hideControl('hierarchyNode') || 
                <LiveControl
                    name="hierarchyNode"
                    displayName={assetHierarchyLiteral}
                    controlRender={() => {
                        return (
                            <EnterpriseHierarchyControl
                                hierarchySelection={hierarchyNode}
                                onHierarchySelectionChange={handleHierarchyNodeChange}
                                hierarchyList={hierarchyNodeOptions}
                                followAppBar={followAppBarHierarchyNode}
                                showFollowAppBarCheckbox={true}
                                onFollowAppBar={handleFollowAppBarHierarchyNodeChange}
                            />
                        );
                    }}
                    icon={(
                        <LiveControlIcon type="vorne" iconName="globe" />
                    )}
                />
            }
            { hideControl('timeRange') || 
                <LiveControl
                    name="timeRange"
                    displayName={timeRangeLiteral}
                    suppressControlHeader={true}
                    controlRender={() => {
                        return (
                            <TimeRangeControl
                                showFollowPageCheckbox
                                selectionValue={timeRange}
                                onSelectionChange={handleTimeRangeChange}
                                onFollowPageChange={handleFollowPageTimeRangeChange}
                                followPageValue={followPageTimeRange}
                            />
                        );
                    }}
                    icon={(
                        <LiveControlIcon type="vorne" iconName="time" />
                    )}
                />
            }
            <LiveControlSeparator />
            <LiveControl
                name='columns'
                displayName={rowLiteral}
                controlRender={(columnProps) => {
                    return (
                        <KPIGroupColumnControl
                            ref={columnControlRef}
                            defaultTitle={rowLiteral}
                            icon={rowControlIcon}
                            selectedColumns={fullSelectedColumns}
                            onChange={handleColumnChange}
                            goBack={columnProps?.headerGoBack || null}
                            fieldOptions={columnOptions}
                        />
                    );
                }}
                handleContentLayout={true}
                icon={rowControlIcon}
            />
            { hideControl('filter') || 
                <LiveControl
                    name="filter"
                    displayName={filterLiteral}
                    badge={activeFilterCount}
                    icon={(
                        <LiveControlIcon type="vorne" iconName="filter" />
                    )}
                    controlRender={() => {
                        return (
                            <FilterControl
                                ref={filterControlRef}
                                initialValue={transformedFilterValue}
                                onApply={handleFilterApply}
                                onCancel={closeSelectedControl}
                                categoryValueOptions={filterOptions.values}
                                categoryOptions={filterOptions.categories}
                                metricOptions={emptyArray}
                            />
                        );
                    }}
                />
            }
            {showDuplicateControl() && <LiveControlSeparator />}
            {showDuplicateControl() && (
                renderDuplicateLiveControl({
                    name: 'duplicateWidget',
                    key: 'duplicateWidget',
                    translator: translator,
                    action: duplicateWidget,
                })
            )}
        </LiveControlBar>
    );
}

KPIGroupWidgetLiveControls.propTypes = {
    name: PropTypes.string,

    filters: PropTypes.object.isRequired,
    setSliceFilters: PropTypes.func.isRequired,
    filterOptions: PropTypes.shape({
        categories: PropTypes.arrayOf(
            PropTypes.object
        ).isRequired,
        values: PropTypes.object.isRequired,
    }).isRequired,

    followPageTimeRange: PropTypes.bool.isRequired,
    timeRange: PropTypes.object,
    setTimeRange: PropTypes.func.isRequired,

    followAppBarHierarchyNode: PropTypes.bool.isRequired,
    hierarchyNode: PropTypes.string,
    setHierarchyNode: PropTypes.func.isRequired,
    hierarchyNodeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            iconClass: PropTypes.string.isRequired,
            level: PropTypes.number.isRequired,
        })
    ).isRequired,

    columns: PropTypes.arrayOf(PropTypes.object.isRequired),
    setColumns: PropTypes.func.isRequired,
    columnOptions: PropTypes.shape({
        metric: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                    })
                ),
            }).isRequired
        ),
        metric_set: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                    })
                ),
            }).isRequired
        ),
        category_value: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                entries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        channel: PropTypes.string.isRequired,
                        displayName: PropTypes.string.isRequired,
                    })
                ),
            }).isRequired
        ),
    }).isRequired,

    isLayoutModeActive: PropTypes.bool.isRequired,
    duplicateWidget: PropTypes.func.isRequired,
    suppressedControls: PropTypes.object,
};

KPIGroupWidgetLiveControls.defaultProps = {
    suppressedControls: {},
};
